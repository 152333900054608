import datafeedGridTpl from './datafeedGrid.tpl.html';
import datafeedGridCtrl from './datafeedGrid.controller';

export default {
    templateUrl: datafeedGridTpl,
    controller: datafeedGridCtrl,
    controllerAs: 'vm',
    bindings: {
        codeName: '@',
        isHierarchy: '<',
        isFull: '<',
        contactID: '<contactId',
        gridSetup: '<',
        gridID: '@gridId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<',
        settingsSlot: '@',
    }
};
