import submissionsSummaryGridTpl from './submissionsSummaryGrid.tpl.html';
import submissionsSummaryGridCtrl from './submissionsSummaryGrid.controller';

export default {
    templateUrl: submissionsSummaryGridTpl,
    controller: submissionsSummaryGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        updateUrlParams: '<'
    }
};
