loginAsConfirmActionModalCtrl.$inject = ['Session', '$translate'];

export default function loginAsConfirmActionModalCtrl(Session, $translate) {
    const vm = this;
    vm.spectator = Session.getSpectator();
    vm.user = Session.getUser();

    vm.spectatorName = `${vm.spectator.firstName} ${vm.spectator.lastName}`;
    vm.userName = `${vm.user.firstName} ${vm.user.lastName}`;

    vm.confirmMessageText = $translate.instant('app_LOGIN_AS_CONFIRM_ACTION_MESSAGE', {
        contactName: vm.userName,
        spectatorName: vm.spectatorName
    });

    vm.hasConfirmMessageText = vm.confirmMessageText !== 'app_LOGIN_AS_CONFIRM_ACTION_MESSAGE';
}
