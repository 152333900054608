import changePasswordFormTpl from './changePasswordForm.tpl.html';
import changePasswordFormCtrl from './changePasswordForm.controller';

export default {
    templateUrl: changePasswordFormTpl,
    controller: changePasswordFormCtrl,
    controllerAs: 'vm',
    bindings: {
        passwordPolicy: '<',
        submitButtonOptions: '<',
        hasToken: '<',
        onSuccess: '<',
        onFail: '<'
    }
};
