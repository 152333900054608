import angular from 'angular';
import reportModalModule from './reportModal/reportModal.module';
import reportFilterModule from './reportFilter/reportFilter.module';
import reportColumnChooser from './reportColumnChooser/reportColumnChooser.module';

import reportConfig from './report.config';
import reportComponent from './report.component';
import reportService from './reportService.factory';

export default angular.module('app.dashboard.report', [
    reportModalModule.name,
    reportFilterModule.name,
    reportColumnChooser.name,
])
.config(reportConfig)
.component('reportComponent', reportComponent)
    .factory('ReportService', reportService);
