import _ from 'lodash';

gaugeWidgetCtrl.$inject = ['GaugeWidgetService'];

export default function gaugeWidgetCtrl(GaugeWidgetService) {
    this.$onInit = () => {
        const vm = this;
        // Passed passed from component bindings:
        // vm.gaugeDetails

        if (!vm.gaugeDetails || !vm.gaugeDetails.pointTargets) {
            throw new Error('gaugeDetails is undefined');
        }

        vm.gaugeConfig = _.merge(GaugeWidgetService.createGaugeConfig(vm.gaugeDetails),
            vm.gaugeDetails.overrideConfig || {});
    };
}
