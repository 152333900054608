import SharedContactModel from '../../../../../shared/contact.model';

Contact.$inject = [];

export default function Contact() {
    return class Contact extends SharedContactModel {
        /**
         * @param {Company} company - Company the contact belongs to
         * @param {Object} contactData - JSON from Contacts_Get or Contacts_GetOther SP
         */
        constructor(company, contactData) {
            super(company, contactData);
        }
    };
}
