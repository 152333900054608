import browserSupportTpl from './browserSupport.tpl.html';
import browserSupportCtrl from './browserSupport.controller';

export default {
    templateUrl: browserSupportTpl,
    controller: browserSupportCtrl,
    controllerAs: 'vm',
    bindings: {
        isInvalidBrowser: '<',
        isSunsetBrowser: '<',
        useThisBrowser: '<',
        learnMoreAboutBrowser: '<',
    }
};
