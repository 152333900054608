import moment from 'moment';

PromoRibbon.$inject = ['$translate', 'Session'];

export default function PromoRibbon($translate, Session) {
    const user = Session.getUser();
    const viewedPromoList = user.viewedPromos; // array of viewed promoIDs

    const RIBBON_TYPES = {
        NEW: 'new',
        ENDED: 'ended',
        COMING_SOON: 'coming-soon',
        DAYS_REMAINING: 'days-remaining'
    };

    const currentDate = moment().format('YYYY-MM-DD');

    async function getRibbon(promo) {
        const endDate = moment(promo.endsOn).format('YYYY-MM-DD');
        const startDate = moment(promo.startsOn).format('YYYY-MM-DD');
        const currentIncentiveLength = moment(currentDate).diff(moment(startDate), 'days');
        const remainingDaysLeft = moment(endDate).diff(moment(currentDate), 'days');
        const incentiveLength = moment(endDate).diff(moment(startDate), 'days');
        const tenthIncentiveLength = Math.floor((10 / 100) * (incentiveLength));

        let showNewRibbon = false;
        let showDaysRemaining = false;

        if ((currentDate >= startDate) && (currentDate <= endDate)) {
            if (incentiveLength < 30) {
                if (currentIncentiveLength <= 3) {
                    showNewRibbon = !viewedPromoList.includes(promo.ID);
                }
            } else if (incentiveLength > 210) {
                if (currentIncentiveLength <= 21) {
                    showNewRibbon = !viewedPromoList.includes(promo.ID);
                }
            } else if (currentIncentiveLength <= tenthIncentiveLength) {
                showNewRibbon = !viewedPromoList.includes(promo.ID);
            }
        }

        if (remainingDaysLeft < 0) {
            showDaysRemaining = false;
        } else if ((remainingDaysLeft <= tenthIncentiveLength) || (remainingDaysLeft <= 7)) {
            showDaysRemaining = true;
        }

        const ribbons = [{
            type: RIBBON_TYPES.NEW,
            show: showNewRibbon,
            phrase: $translate.instant('app_PROMO_CARD_RIBBON_NEW')
        }, {
            type: RIBBON_TYPES.DAYS_REMAINING,
            show: showDaysRemaining,
            phrase: $translate.instant('app_PROMO_CARD_RIBBON_DAYS_REMAINING', {
                number: remainingDaysLeft
            }, 'messageformat')
        }, {
            type: RIBBON_TYPES.COMING_SOON,
            show: currentDate < startDate,
            phrase: $translate.instant('app_PROMO_CARD_RIBBON_COMING_SOON')
        }, {
            type: RIBBON_TYPES.ENDED,
            show: currentDate > endDate,
            phrase: $translate.instant('app_PROMO_CARD_RIBBON_ENDED')
        }];

        return ribbons;
    }
    return {
        getRibbon
    };
}
