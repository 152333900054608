import angular from 'angular';

import 'angular-formly';

import customRadioInputRun from './customRadioInput.run';

export default angular.module('common.formly.customRadioInput', [
    'formly'
])
.run(customRadioInputRun);
