pointCalculatorCtrl.$inject = ['$filter', 'Session'];

export default function pointCalculatorCtrl($filter, Session) {
    this.$onInit = () => {
        const vm = this;

        const formatNumber = $filter('number');
        const formatToCurrency = $filter('currency');

        vm.company = Session.getCompany();
        vm.pointConversionRate = vm.company.pointBuyConversion;

        // Start off by showing the value of 1 dollar.
        vm.dollars = formatToCurrency(1);
        vm.points = formatNumber(1 / vm.pointConversionRate);

        vm.onDollarsChange = () => {
            const dollarsNumber = convertToNumber(vm.dollars);
            vm.dollars = formatToCurrency(dollarsNumber);
            vm.points = formatNumber(dollarsNumber / vm.pointConversionRate);
        };

        vm.onPointsChange = () => {
            const pointsNumber = convertToNumber(vm.points);
            vm.dollars = formatToCurrency(pointsNumber * vm.pointConversionRate);
            vm.points = formatNumber(pointsNumber);
        };

        function convertToNumber(string) {
            return Number(string.replace(/[^0-9-.]/g, ''));
        }
    };
}
