notificationManagementCtrl.$inject = ['$state'];

export default function notificationManagementCtrl($state) {
    const vm = this;

    vm.path = '/Notifications.asp';

    if ($state.params.highlightMessageTypeID) {
        vm.path += '?highlightMessageTypeID=' + $state.params.highlightMessageTypeID;
    }
}
