import _ from 'lodash';

import statusPopoverTemplateUrl from './statusPopover.tpl.html';

contactPanelCtrl.$inject = ['Session', 'ContactService', 'LoginAsService', 'ContactStatuses', 'SuggestsTypes'];

export default function contactPanelCtrl(Session, ContactService, LoginAsService, ContactStatuses, SuggestsTypes) {
    this.$onInit = () => {
        const vm = this;
        // Passed from parent template via component bindings:
        // vm.contact
        // vm.hideManager
        // vm.hideTools
        // vm.showLoginAsNav

        _.defaults(vm, {
            hideManager: false,
            showTools: true,
            showLoginAsNav: false
        });

        const CONTACT_STATUS_CSS_CLASSES = {
            [ContactStatuses.INVITED]: 'invited',
            [ContactStatuses.ACTIVE]: 'active',
            [ContactStatuses.PASSIVE]: 'passive',
            [ContactStatuses.DELETED]: 'deleted'
        };

        vm.user = Session.getUser();
        vm.spectator = Session.getSpectator();
        vm.company = Session.getCompany();

        if (vm.user.previousContactID === undefined) {
            vm.showTools = vm.user.userID !== vm.contact.userID;
        }

        vm.showPoints = vm.contact.points > 0 && ContactService.canUserViewPoints(vm.contact);

        const { contactLimiters, managerLimiters } = ContactService.createContactLimiters(vm.contact);
        vm.contactLimiters = contactLimiters;
        vm.managerLimiters = managerLimiters;

        vm.SuggestsTypes = SuggestsTypes;

        vm.statusPopoverTemplateUrl = statusPopoverTemplateUrl;
        vm.statusCssClass = `contact-status contact-status-${CONTACT_STATUS_CSS_CLASSES[vm.contact.status.ID]}`;

        if (vm.spectator) {
            vm.logoutOfContact = function() {
                return LoginAsService.logoutOfContact();
            };
            vm.onSuggestSelect = function (model) {
                const contactID = model.ID;
                if (contactID === vm.user.userID) {
                    return; // Already logged in as the selected contact
                }
                return LoginAsService.loginAsContact({ contactID });
            };
        }
    };
}
