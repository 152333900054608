import promoQuizAddNoteTpl from './addNote.tpl.html';
import promoQuizAddNoteCtrl from './addNote.controller';

export default {
    templateUrl: promoQuizAddNoteTpl,
    controller: promoQuizAddNoteCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        section: '<',
        isApprover: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<'
    }
};
