import angular from 'angular';

import '../../core/utils/coreUtils.module';

import gamesFramePageConfig from './gamesFramePage.config';
import gamesFrame from './gamesFrame.component';
import gamesFramePageCtrl from './gamesFramePage.controller';

export default angular.module('app.gamesFrame', [
    'app.core'
])
.config(gamesFramePageConfig)
.component('gamesFrame', gamesFrame)
.controller('gamesFramePageCtrl', gamesFramePageCtrl);
