import angular from 'angular';

import ChartWidgetService from './chartWidgetService.factory';
import chartWidget from './chartWidget.directive';

export default angular.module('app.core.widgets.chartWidget', [

])
.factory('ChartWidgetService', ChartWidgetService)
.directive('chartWidget', chartWidget);
