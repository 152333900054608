import _ from 'lodash';
import contentTpl from './content.tpl.html';

contentConfig.$inject = ['$stateProvider'];

export default function contentConfig($stateProvider) {

    const commonStateConfig = {
        views: {
            'main@': {
                controller: 'contentCtrl as vm',
                templateUrl: contentTpl
            }
        },
        data: {
            pageTitle: 'Content'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('content');
            }]
        }
    };

    $stateProvider.state('content', _.merge({}, commonStateConfig, {
        parent: 'nav',
        url: '/content/:code?perm',
        data: {
            isPublicPage: true
        },
        resolve: {
            content: ['$stateParams', 'ContentService', function($stateParams, ContentService) {
                const contentCode = $stateParams.code;
                const permissionGroup = $stateParams.perm;
                return ContentService.getContentByCode(contentCode, permissionGroup)
                    .catch(function redirectIfFoundButNotPublic(err) {
                        if (err.data.returnCode === 6031) {
                            // When a content page exists but isn't public and the user isn't logged in,
                            // we want to redirect them to the login page, then try going to the page again
                            // after they've logged in.
                            err.redirectTo = 'login';
                            err.redirectParams = {
                                redirectTo: 'content',
                                redirectParams: $stateParams
                            };
                            err.disableDefaultHandling = true;
                        }
                        throw err;
                    });
            }]
        }
    }));

    $stateProvider.state('contentID', _.merge({}, commonStateConfig, ({
        parent: 'auth',
        url: '/content/id/:contentID',
        resolve: {
            content: ['$stateParams', 'ContentService', function($stateParams, ContentService) {
                const contentID = $stateParams.contentID;
                return ContentService.getContentByID(contentID);
            }]
        }
    })));
}
