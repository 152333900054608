import angular from 'angular';

promoTargetsTableCtrl.$inject = [];

export default function promoTargetsTableCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed passed from component bindings:
        // vm.promoDetails

        // angular copy is used to clone the promoDetails object, without it,
        // we're overwriting the values of promoDetails for other components that
        // are passed the same object
        vm.promoDetails = angular.copy(vm.promoDetails);

        const targets = vm.promoDetails.widgetPromoTargets.pointTargets;
        const standingValue = vm.promoDetails.widgetPromoTargets.standingValue || 0;

        vm.standingValueName = vm.promoDetails.widgetPromoTargets.standingValueName;
        vm.targetsBeforeStandingValue = targets.filter((target) => target.targetValue <= standingValue);
        vm.targetsAfterStandingValue = targets.filter((target) => target.targetValue > standingValue);

        vm.standingValue = formatNumberToLocale(standingValue);

        vm.targetsBeforeStandingValue.forEach((target) => {
            target.targetValue = formatNumberToLocale(target.targetValue);
        });

        vm.targetsAfterStandingValue.forEach((target) => {
            target.targetValue = formatNumberToLocale(target.targetValue);
        });


        function formatNumberToLocale(target) {
            return target.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            });
        }
    };
}
