AuthService.$inject = ['$http', '$rootScope', 'Session', 'AUTH_EVENTS', 'UrlUtils', 'AuthStatus'];

export default function AuthService($http, $rootScope, Session, AUTH_EVENTS, UrlUtils, AuthStatus) {

    async function login({ username, password }) {
        try {
            const res = await $http.post('/api/login', {
                username,
                password
            }, {
                disableLoginAsConfirmModal: true
            });
            AuthStatus.setIsAuthenticated(true);
            Session.destroySessions();
            const company = Session.setCompany(res.data.company);
            const user = Session.setUser(res.data.user);
            $rootScope.$broadcast(AUTH_EVENTS.LOGIN_SUCCESS, { company, user });
        } catch (err) {
            $rootScope.$broadcast(AUTH_EVENTS.LOGIN_FAILED);
            throw err;
        }
    }

    async function logout() {
        try {
            await $http.post('/api/logout', {}, { disableLoginAsConfirmModal: true });
        } catch (err) {
            // If err.status === 400, user probably logged out in other tab/window
            if (err.status !== 400) {
                throw err;
            }
        }
        await legacyLogout();
        AuthStatus.setIsAuthenticated(false);
        Session.destroy();
        $rootScope.$broadcast(AUTH_EVENTS.LOGOUT_SUCCESS);
    }

    function legacyLogout() {
        return UrlUtils.loadLegacyPage('/logOff.asp');
    }

    function resetTimeout() {
        return $http.get('/api/reset-timeout');
        // AUTH_EVENTS.RESET_TIMEOUT will be broadcast on success, see authInterceptor.factory.js
    }

    return {
        login,
        logout,
        resetTimeout
    };
}
