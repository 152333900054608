import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class UpdateAddressTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.PointTrackingService = $injector.get('PointTrackingService');

        this.redemptionID = taskObject.redemptionID;
        this.pointTrackingID = taskObject.pointTrackingID;

        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_UPDATE_ADDRESS_TITLE', // 'Address update required'
            successMessage: 'app_SIGN_ON_TASKS_UPDATE_ADDRESS_SUCCESS_MESSAGE', // 'Address updated'
            taskModalOptions: {
                showFooterButtons: false,
                successMessageTimeout: 3000
            }
        });
    }

    get extraModalOptions() {
        return {
            size: 'lg'
        };
    }

    getAdditionalData() {
        return this.PointTrackingService.getPointTrackingDetails({
            pointTrackingID: this.pointTrackingID
        })
        .then(redemptionDetails => {
            this.redemption = redemptionDetails;
        });
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;

        vm.redemption = this.redemption;
    }

    get componentHtml() {
        return `
            <redemption-details redemption="::vm.redemption" 
                                show-address="true" 
                                on-address-update-success="::vm.onSuccess" 
                                on-address-update-fail="::vm.onFail">
            </redemption-details>`;
    }
}
