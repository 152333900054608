import angular from 'angular';

// TODO: figure out how to get angular-masonry dependencies to load from npm AND work with webpack and webpack-dev-server
import '../../../../../vendor/angular-masonry/angular-masonry-deps';
import 'angular-masonry';

import '../../../core/core.module';

import recognitionLandingTab from './recognitionTab.component';

export default angular.module('app.landing.recognitionLandingTab', [
    'wu.masonry', // angular-masonry
    'app.core'
])
.component('recognitionLandingTab', recognitionLandingTab);
