import rewardCtrl from "./reward.controller";
import rewardTpl from "./reward.tpl.html";

export default {
    templateUrl: rewardTpl,
    controller: rewardCtrl,
    controllerAs: 'vm',
    bindings: {
        storeList: '<'
    }
};
