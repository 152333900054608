import $ from 'jquery';
import bytes from 'bytes';

savedReportsGridCtrl.$inject = [
    '$scope', '$translate', '$window', 'GridColumnTypes', 'GridColumnFilterTypes', 'GridFilterOperators',
    'ReportsService', 'GridReportFileStatuses', 'Prompt', '$timeout'
];

export default function savedReportsGridCtrl(
    $scope, $translate, $window, GridColumnTypes, GridColumnFilterTypes, GridFilterOperators,
    ReportsService, GridReportFileStatuses, Prompt, $timeout
) {
    this.$onInit = () => {
        const vm = this;

        let gridInstance;

        vm.gridID = vm.gridID || 'grid-reports-saved';

        vm.columns = [{
            nonDevExOptions: {
                isOptionsColumn: true,
                captionTranslateKey: 'reports_OPTIONS_COLUMN_HEADING', // 'Options'
                cellTemplate: `
                    <i ng-if="::vm.isReportGenerating(cell.data)"
                    uib-tooltip="${$translate.instant('reports_GENERATING_ICON_TITLE')}"
                    tooltip-append-to-body="true"
                    class="fa fa-spinner fa-pulse generating-icon">
                    </i>
                    <grid-option-button ng-if="::!vm.isReportGenerating(cell.data)"
                                        on-click="vm.download(cell)"
                                        text-translate-key="reports_DOWNLOAD_ICON_TITLE"
                                        button-class="download-button"
                                        icon-class="fa fa-download">
                    </grid-option-button>
                    <grid-option-button ng-if="::!vm.isReportGenerating(cell.data)"
                                        on-click="vm.delete(cell)"
                                        text-translate-key="reports_DELETE_ICON_TITLE"
                                        button-class="delete-button"
                                        icon-class="fa fa-trash">
                    </grid-option-button>`
            }
        }, {
            dataField: 'name',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'reports_NAME_COLUMN_HEADING' // 'Name'
            }
        }, {
            dataField: 'date',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'reports_DATE_COLUMN_HEADING' // 'Date'
            }
        }, {
            dataField: 'isCurrent',
            nonDevExOptions: {
                type: GridColumnTypes.BOOLEAN,
                filterType: GridColumnFilterTypes.BOOLEAN,
                captionTranslateKey: 'reports_IS_CURRENT_COLUMN_HEADING', // 'Recency'
                trueTranslateKey: 'reports_IS_CURRENT_TRUE', // 'Current'
                falseTranslateKey: 'reports_IS_CURRENT_FALSE', // 'Replaced'
                hideWhenFalse: false
            }
        }, {
            dataField: 'size',
            customizeText(cellInfo) {
                return !cellInfo.value ? '' : bytes(cellInfo.value, { decimalPlaces: 0, unitSeparator: ' ' });
            },
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER, // TODO: consider more user-friendly FILE_SIZE filter type?
                captionTranslateKey: 'reports_SIZE_COLUMN_HEADING' // 'Size'
            }
        }, {
            dataField: 'rows',
            customizeText(cellInfo) {
                return !cellInfo.value ? '' : cellInfo.valueText;
            },
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'reports_ROWS_COLUMN_HEADING' // 'Rows'
            }
        }];

        vm.extraDataGridOptions = {
            onInitialized(e) {
                gridInstance = e.component;
            }
        };

        const defaultIsCurrentFilter = { field: 'isCurrent', operator: GridFilterOperators.EQUALS, value: true };
        if (!vm.initialFilters) {
            vm.initialFilters = [defaultIsCurrentFilter];
        } else if (vm.initialFilters.every(filter => filter.field !== 'isCurrent')) {
            vm.initialFilters.unshift(defaultIsCurrentFilter);
        }

        $scope.$on(ReportsService.Events.CUSTOM_REPORT_REQUESTED, () => {
            gridInstance.refresh();
        });

        vm.isReportGenerating = ({ statusID }) => {
            const isReportGenerating = statusID !== GridReportFileStatuses.COMPLETE;
            if (isReportGenerating && !vm.refreshTimeout) {
                vm.refreshTimeout = $timeout(() => {
                    vm.refreshTimeout = null;
                    gridInstance.refresh();
                }, 60000);
            }
            return isReportGenerating;
        };

        vm.download = async (cell) => {
            const downloadLink = await ReportsService.getReportFileCloudLink(cell.data);
            const $link = $(`<a href="${downloadLink}" style="display: none"></a>`);
            $('body').append($link);
            $link[0].click();
            $link.remove();
        };

        vm.delete = async (cell) => {
            await Prompt.open({ type: 'warning' }).result;
            await ReportsService.deleteReportFile(cell.data);
            return gridInstance.refresh();
        };
    };
}
