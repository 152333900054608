import $ from 'jquery';

contentCtrl.$inject = ['content', '$scope', '$timeout', 'AuthStatus', 'Session', 'PageTitle', 'SuggestsTypes'];

export default function contentCtrl(content, $scope, $timeout, AuthStatus, Session, PageTitle, SuggestsTypes) {
    const vm = this;
    vm.user = Session.getUser();
    vm.isArticle = isArticle(content);
    vm.SuggestsTypes = SuggestsTypes;

    if (AuthStatus.isAuthenticated()) {
        vm.editPermission = vm.user.hasPermission('EditCustomContent');
    }

    if (!content) {
        vm.error = true;
        return;
    }

    PageTitle.set(content.name);

    vm.content = content;
    vm.iframeUrl = content.url;

    if (vm.content.developerContent) {
        const $developerContent = $('<div />').append(vm.content.developerContent);
        // Script tags need to be handled separately (added to document head instead of the ng-bind-html div).
        const $scripts = $developerContent.find('script');
        $timeout(() => loadScripts($scripts));
        $scripts.remove();
        vm.developerContent = $developerContent.html();
    }

    function loadScripts($scripts) {
        Array.from($scripts).forEach(loadScript);
    }

    /**
     * Loads and executes a developer content <script> tag.
     */
    async function loadScript({ src, innerHTML }) {
        const script = document.createElement('script');
        script.async = false; // Important - this is what makes dynamically added scripts execute in order.
        if (src) {
            script.src = src;
        }
        if (innerHTML) {
            // Prevent scripts from creating or overriding global variables.
            script.innerHTML = `(function() { ${innerHTML} })();`;
        }
        document.head.appendChild(script);
        $scope.$on('$destroy', () => {
            document.head.removeChild(script);
        });
        await $timeout(0); // Let the script execute
    }

    function isArticle(content) {
        return !!content && !!content.topicCrumbtrail;
    }
}
