import gamesFrameTpl from './gamesFrame.tpl.html';
import gamesFrameCtrl from './gamesFrame.controller';

export default {
    templateUrl: gamesFrameTpl,
    controller: gamesFrameCtrl,
    controllerAs: 'vm',
    bindings: {
        gamesUrl: '@'
    }
};
