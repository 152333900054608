import contactPanelLinksTpl from './contactPanelLinks.tpl.html';
import contactPanelLinksCtrl from './contactPanelLinks.controller';

export default {
    templateUrl: contactPanelLinksTpl,
    controller: contactPanelLinksCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<'
    }
};
