

const _ = require('lodash');

/**
 * @param {Object} req
 * @param {string} req.method
 * @param {string} req.url
 * @returns {boolean} True if the request is considered to be "editing" - the user is trying to modify something.
 */
const isEditingRequest = ({ method, url }) => ['POST', 'PUT', 'DELETE', 'PATCH'].includes(method) && _.startsWith(url, '/api');

module.exports = isEditingRequest;
