import isEditingRequest from '../../../../../shared/isEditingRequest';

LoginAsConfirmActionInterceptor.$inject = ['$injector'];

export default function LoginAsConfirmActionInterceptor($injector) {
    return {
        request(config) {
            const Session = $injector.get('Session');
            const spectator = Session.getSpectator();

            // disableLoginAsConfirmModal is passed to $http when the confirm action modal should not appear.
            // Ex. login, logout, login-as, recording errors
            if (!spectator || (spectator && !isEditingRequest(config)) || config.disableLoginAsConfirmModal) {
                return config;
            }

            const LoginAsConfirmActionModalService = $injector.get('LoginAsConfirmActionModalService');
            return LoginAsConfirmActionModalService.open()
                .result
                .then(function() {
                    return config;
                })
                .catch(function() {
                    // From https://stackoverflow.com/questions/19711550/angularjs-how-to-prevent-a-request
                    const $q = $injector.get('$q');
                    const canceler = $q.defer();
                    config.timeout = canceler.promise;
                    canceler.resolve();

                    // The above seems to only prevent the browser from processing/parsing the http response.
                    // The request will still be sent however - Node will still receive it and execute SPs and etc.
                    // So, to prevent that, change the URL to a bogus one instead.
                    // TODO: find a way to prevent Angular from sending a request at all
                    config.url = '//login-as-confirm-action-cancelled';

                    return config;
                });
        },
    };
}
