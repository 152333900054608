import _ from 'lodash';

applauseDetailsCtrl.$inject = ['Session', 'LangUtils', 'StrengthsService', 'SkinUtils'];

export default function applauseDetailsCtrl(Session, LangUtils, StrengthsService, SkinUtils) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            showSpotlightButtons: true,
            showCommentSection: true,
        });

        vm.showCommentForm = true;

        const company = Session.getCompany();
        const langCode = LangUtils.getLang();
        const skinCode = SkinUtils.getSkinCode();
        vm.logoImageSrc = `/assets/clients/${company.subDomain}/${skinCode}/images/${langCode}/Logo_Med.gif`;

        if (vm.applause.recipients && vm.applause.recipients.length === 1) {
            vm.toContact = vm.applause.recipients[0];
        }

        if (vm.applause.strengths) {
            vm.applause.strengths.forEach(strength => {
                strength.badgeImageSrc = StrengthsService.getStrengthBadgeImageSrc(strength.ID);
            });
        }

        vm.isTeam = Boolean(vm.applause.teamName);
    };
}
