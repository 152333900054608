SharedConstantsService.$inject = ['$translate'];

export default function SharedConstantsService($translate) {

    /**
     * Adapts a constants file from the shared folder to be used on the front end.
     * @param {Object} constants - From Shared folder
     * @param {Object} [options]
     * @param {string} [options.translateKeyName]
     * @param {string} [options.phraseKeyName]
     * @returns {Object}
     */
    function create(constants, { translateKeyName, phraseKeyName } = {}) {
        setPhrases(constants, { translateKeyName, phraseKeyName });
        return constants;
    }

    /**
     * @param {Object} constants
     * @param {Object} [options]
     * @param {string} [options.translateKeyName]
     * @param {string} [options.phraseKeyName]
     */
    function setPhrases(constants, { translateKeyName = 'translateKey', phraseKeyName = 'phrase' } = {}) {
        Object.values(constants.properties)
            .filter(obj => obj[translateKeyName])
            .forEach(obj => obj[phraseKeyName] = $translate.instant(obj[translateKeyName]));
    }

    return {
        create
    };
}
