import angular from 'angular';

import '../../core/core.module';
import './noAccessModal/noAccessModal.module';

import noAccess from './noAccess.component';

export default angular.module('app.noAccess', [
    'app.core',

    'app.noAccess.noAccessModal'
])
.component('noAccess', noAccess);
