

const constantUtils = require('./constantUtils');

const QuizResultStatuses = {
    PENDING_APPROVAL: 1,
    DENIED: 2,
    WINNER: 3,
    WINNER_PENDING_APPROVAL: 4,
    WINNER_APPROVAL_DENIED: 5,
    REQUIRES_ACTION: 7,
    COMPLETED: 8,
    USER_ABANDONED: 9
};

const properties = {
    [QuizResultStatuses.PENDING_APPROVAL]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_PENDING_APPROVAL'
    },
    [QuizResultStatuses.DENIED]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_DENIED'
    },
    [QuizResultStatuses.WINNER]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_WINNER'
    },
    [QuizResultStatuses.WINNER_PENDING_APPROVAL]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_WINNER_PENDING_APPROVAL'
    },
    [QuizResultStatuses.WINNER_APPROVAL_DENIED]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_WINNER_APPROVAL_DENIED'
    },
    [QuizResultStatuses.REQUIRES_ACTION]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_REQUIRES_ACTION'
    },
    [QuizResultStatuses.COMPLETED]: {
        translateKey: 'app_QUIZ_RESULT_STATUS_COMPLETED'
    }
};

constantUtils.configure({ constants: QuizResultStatuses, properties });

module.exports = QuizResultStatuses;
