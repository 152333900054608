import promoFilterTpl from './promoFilter.tpl.html';
import promoFilterCtrl from './promoFilter.controller';

export default {
    templateUrl: promoFilterTpl,
    controller: promoFilterCtrl,
    controllerAs: 'vm',
    bindings: {
        promoList: '<',
        promoGroups: '<',
        isDefaultView: '=',
        promoGroupIndex: '=',
        isPromoExist: '=',
        activeGroupId: '=',
        isAward: '<'
    }
};
