
incentiveCardService.$inject = ['$http'];

export default function incentiveCardService($http) {

    const incentiveLibrarySave = (params, data) => {
        const req = {
            method: 'put',
            url: `/api/incentive-library/${params.promoLibraryID}?code=${params.code}`,
            data: data
        };
        return $http(req);
    };

    async function incentiveClone({ incentiveID }) {
        const res = await $http.get(`/api/incentive-library/clone/${incentiveID}`);
        return res.data || [];
    }

    async function incentiveLibraryDelete({ code, promoLibraryID }) {
        const res = await $http.delete(`/api/incentive-library/${code}/${promoLibraryID}`);
        return res.data || [];
    }

    return {
        incentiveLibrarySave,
        incentiveClone,
        incentiveLibraryDelete
    };
}
