sessionRun.$inject = ['$rootScope', '$window', 'AUTH_EVENTS', 'AuthStatus', 'Session'];

export default function sessionRun($rootScope, $window, AUTH_EVENTS, AuthStatus, Session) {

    Session.setCompany($window.rnSession.company);

    if (AuthStatus.isAuthenticated()) {
        Session.setUser($window.rnSession.user);
    }
}
