import angular from 'angular';

import '../../core/core.module';

import datafeedConfig from './datafeed.config';
import datafeedUrlConfig from './datafeedUrl.config';
import datafeedRun from './datafeed.run';
import DatafeedService from './datafeedService.factory';
import datafeedCtrl from './datafeed.controller';
import datafeedGrid from './datafeedGrid/datafeedGrid.component';

export default angular.module('app.datafeed', [
    'app.core',
])
.config(datafeedConfig)
.config(datafeedUrlConfig)
.run(datafeedRun)
.factory('DatafeedService', DatafeedService)
.controller('datafeedCtrl', datafeedCtrl)
.component('datafeedGrid', datafeedGrid);
