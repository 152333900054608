import _ from 'lodash';

ClaimService.$inject = ['$http'];

export default function ClaimService($http) {
    return {
        getClaimDetails,
        saveClaimSale
    };

    async function getClaimDetails(companyMetricCode, reference) {
        const res = await $http.get(`/api/claim/${companyMetricCode}/${reference}`);
        return res.data;
    }

    async function saveClaimSale(companyMetricCode, reference, proof) {
        const res = await $http.post(`/api/claim/proof/${companyMetricCode}/${reference}`, { proof });
        return res;
    }
}
