import anniversaryWidgetTpl from './anniversaryWidget.tpl.html';
import anniversaryWidgetCtrl from './anniversaryWidget.controller';

export default {
    templateUrl: anniversaryWidgetTpl,
    controller: anniversaryWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
