import angular from 'angular';

import onCarouselChange from './onCarouselChange.directive';
import datePickerDynamicLocaleConfig from './datePickerDynamicLocale.config';

export default angular.module('app.core.uibExtra', [
    'ui.bootstrap'
])
.config(datePickerDynamicLocaleConfig)
.directive('onCarouselChange', onCarouselChange);
