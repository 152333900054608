

const _ = require('lodash');
const isUrlAbsolute = require('./isUrlAbsolute');

const ANGULAR_URL_PREFIXES = ['#/', '/#/', '/']; // order matters because of _.startWith

/**
 * Sometimes you might have a bunch of URLs and you don't know if they start with anchors or slashes or not
 * (Ex. nav links). Use this so that all the URLs are formatted the same way.
 * @example
 * formatAngularUrl({ url: '/#/faq' })                      // '/faq'
 * formatAngularUrl({ url: '#/faq' })                       // '/faq'
 * formatAngularUrl({ url: '/faq' })                        // '/faq'
 * formatAngularUrl({ url: 'faq' })                         // '/faq'
 * formatAngularUrl({ url: '/faq', desiredPrefix: '#/' })    // '#/faq'
 * formatAngularUrl({ url: 'http://www.google.ca' })        // 'http://www.google.ca'
 * @param {Object} params
 * @param {string} params.url
 * @param {string} [params.desiredPrefix = '/'] - What the prefix of the returned URL should be.
 * @returns {string}
 */
function formatAngularUrl({ url = '', desiredPrefix = '/' }) {
    if (isUrlAbsolute(url)) {
        return url;
    }
    const matchingPrefix = ANGULAR_URL_PREFIXES.find(prefix => _.startsWith(url, prefix));
    if (matchingPrefix) {
        const noPrefixUrl = url.substring(matchingPrefix.length);
        return desiredPrefix + noPrefixUrl;
    } else {
        return desiredPrefix + url;
    }
}

module.exports = formatAngularUrl;
