import reactivateUserTpl from './reactivateUser.tpl.html';
import reactivateUserCtrl from './reactivateUser.controller';

export default {
    templateUrl: reactivateUserTpl,
    controller: reactivateUserCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        onCancel: '<',
        onSuccess: '<'
    }
};
