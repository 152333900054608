import taxInfoTpl from './taxInfo.tpl.html';

taxInfoConfig.$inject = ['$stateProvider'];

export default function taxInfoConfig($stateProvider) {
    $stateProvider.state('taxInfo', {
        parent: 'auth',
        url: '/tax-info',
        views: {
            'main@': {
                controller: 'taxInfoCtrl as vm',
                templateUrl: taxInfoTpl
            }
        },
        data: {
            pageTitle: 'Tax Info'
        }
    });
}
