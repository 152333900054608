import accountDeclinedGridTpl from './accountDeclinedGrid.tpl.html';
import accountDeclinedGridCtrl from './accountDeclinedGrid.controller';

export default {
    templateUrl: accountDeclinedGridTpl,
    controller: accountDeclinedGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        contactID: '<contactId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<',
    }
};
