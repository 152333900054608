import angular from 'angular';

import '../../core/utils/coreUtils.module';

import legacyFramePageConfig from './legacyFramePage.config';
import legacyFrame from './legacyFrame.component';
import legacyFramePageCtrl from './legacyFramePage.controller';

export default angular.module('app.legacyFrame', [
    'app.core'
])
.config(legacyFramePageConfig)
.component('legacyFrame', legacyFrame)
.controller('legacyFramePageCtrl', legacyFramePageCtrl);
