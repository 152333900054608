toolsCtrl.$inject = ['$scope', '$http', '$q', '$translate', '$timeout', 'Communication', 'Upload', 'LoginAsService'];

export default function toolsCtrl($scope, $http, $q, $translate, $timeout, Communication, Upload, LoginAsService) {
    const vm = this;

    // Auditing
    // -------------------------------------------------------------------------

    vm.testBrowserError = function() {
        vm.error = broken.error[0];
    };

    vm.testRequestError = function() {
        return $http.post('/api/tools/test-request-error');
    };

    vm.testSpError = function() {
        return $http.post('/api/tools/test-sp-error');
    };

    vm.testSpReturnCode = function() {
        return $http.post('/api/tools/test-sp-return-code');
    };

    vm.testGridSqlError = function() {
        return $http.post('/api/tools/test-grid-sql-error');
    };

    // Login As
    // -------------------------------------------------------------------------

    vm.loginAsContactID = '';

    vm.loginAs = function(contactID) {
        contactID = Number(contactID.toString().replace(/\s/g, ''));
        if (!contactID) {
            return;
        }
        return LoginAsService.loginAsContact({ contactID });
    };

    vm.logoutOfContact = function() {
        return LoginAsService.logoutOfContact();
    };

    // File upload
    // -------------------------------------------------------------------------

    vm.onFileChange = function($files, $file, $newFiles, $duplicateFiles, $invalidFiles, $event) {
        console.log($file);
    };

    vm.upload = function() {
        return Upload.upload({
            url: '/api/tools/file',
            method: 'POST',
            data: {
                file: vm.file,
                foo: 'bar',
                nestedFoo: {
                    nestedBar: ['bar']
                }
            }
        });
    };

    // Formly
    // -------------------------------------------------------------------------

    vm.isUploadFile = true;

    vm.formModel = {};

    vm.formFields = [
        {
            key: 'myInput',
            type: 'customInput',
            model: vm.formModel.myInput,
            templateOptions: {
                type: 'text',
                label: 'Type something in',
                placeholder: 'Placeholder'
            }
        }, {
            key: 'myFile',
            type: 'customFileUpload',
            templateOptions: {
                required: true,
                label: 'Upload a file',
                validExtensions: ['jpg', 'png', 'pdf'],
                maxNumberOfFiles: 1,
                xhrUploadOptions: {
                    endpoint: '/api/tools/file'
                },
            }
        }, {
            key: 'myOptionalFile',
            type: 'customFileUpload',
            templateOptions: {
                label: 'Upload a file - OPTIONAL',
                maxFileSize: 1000,
                maxNumberOfFiles: 3,
                xhrUploadOptions: {
                    endpoint: '/api/tools/file'
                },
            }
        }, {
            key: 'validationTester',
            type: 'customTextarea',
            templateOptions: {
                labelTranslateKey: 'Validation tester',
                minlength: 5,
                maxlength: 100
            }
        }
    ];

    vm.submit = function() {
        console.log('vm.formModel');
        console.log(vm.formModel);
    };
}