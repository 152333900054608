import reportFilterTpl from './reportFilter.tpl.html';
import reportFilterCtrl from './reportFilter.controller';
import _ from 'lodash';

ReportFilterService.$inject = ['$uibModal', 'GridColumnFilterTypes', 'GridFilterOperators', '$translate'];

export default function ReportFilterService($uibModal, GridColumnFilterTypes, GridFilterOperators, $translate) {
    // basic properties of encode/decode functions
    // given that:
    //     filterObject === decode(filterStr)
    //     filterStr === encode(filterObject)
    // test: filterStr === encode(decode(filterStr)) has to be true for all cases
    // test: pass .is(filterObject)

    const FILTER_SEPARATOR = '__';
    return {
        openFilterModal,
        encode,
        decode,
        is,
        equal,
        encodeFilterSet,
        getFilterDisplayText,
    };

    function openFilterModal(data) {
        return $uibModal.open({
            templateUrl: reportFilterTpl,
            controller: reportFilterCtrl,
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                data: () => data
            }
        });
    }

    function encode(data) {
        if (_.isString(data)) {
            return data;
        }

        if (!_.isArray(data)) {
            return null;
        }

        // if _.isArray === true
        return data.map((filter = {}) => {
            if (filter.operator === 'in') {
                return `${filter.field}--${filter.operator}--${filter.value.join('..')}`;
            }
            if (filter.value) {
                return `${filter.field}--${filter.operator}--${filter.value}`;
            }
            return `${filter.field}--${filter.operator}`;
        }).join(FILTER_SEPARATOR);
    }

    function decode(str) {
        if (!_.isString(str)) {
            return [];
        }
        const result = str.split(FILTER_SEPARATOR)
            .filter(filterStr => !!filterStr)
            .map(filter => filter.split('--'))
            .filter(filter => [2, 3].includes(filter.length))
            .map(filter => {
                if (filter[1] === 'in') {
                    return {
                        field: filter[0],
                        operator: filter[1],
                        value: filter[2].split('..'),
                    };
                }

                if (filter.length === 3) {
                    return {
                        field: filter[0],
                        operator: filter[1],
                        value: filter[2],
                    }
                }
                if (filter.length === 2) {
                    return {
                        field: filter[0],
                        operator: filter[1],
                    }
                }
            });
        return result;
    }

    function is(data) {
        if (!_.isArray(data)) {
            return false;
        }

        if (_.isArray(data)) {
            return data.every(filter => _.isObject(filter) && _.isString(filter.field && filter.operator));
        }
    }

    function equal(array1, array2) {
        return array1.length === array2.length &&
        array1.reduce( (acc, item, idx) => {
            const item2 = array2[idx];
            return acc &&
                item.field === item2.field && item.operator === item2.operator && _.isEqual(item.value, item2.value);
        }, true);
    }

    function encodeFilterSet({filter, sort, column}) {
        const filterStr = filter ? `filter=${filter}`: '';
        const sortStr = sort ? `sort=${sort}`: '';
        const columnStr = column ? `column=${column}`: '';

        const filterSet = [];
        if (filterStr !== '') {
            filterSet.push(filterStr);
        }
        if (sortStr !== '') {
            filterSet.push(sortStr);
        }
        if (columnStr !== '') {
            filterSet.push(columnStr);
        }
        return filterSet.length ? `?${filterSet.join('&')}` : '';
    }

    function getFilterDisplayText(columns, filter) {
        const column = columns
            .filter(column => !!column.dataField)
            .find(column => column.dataField.toLowerCase() === filter.field.toLowerCase());
        const operator = _.values(GridFilterOperators)
            .find(operator => operator.toLowerCase() === filter.operator.toLowerCase());
        const operatorProperties = GridFilterOperators.properties[operator];
        const displaySymbol = operatorProperties.displaySymbol || operatorProperties.phrase.toLowerCase();
        const value = filter.value;
        switch (column.filterType) {
            case GridColumnFilterTypes.TEXT: {
                return `${column.caption} ${displaySymbol} "${value}"`;
            }
            case GridColumnFilterTypes.NUMBER: {
                return `${column.caption} ${displaySymbol} ${value}`;
            }
            case GridColumnFilterTypes.DATE: {
                switch (operator) {
                    case GridFilterOperators.EQUALS:
                        return `${column.caption}: ${value}`;
                    case GridFilterOperators.BEFORE:
                    case GridFilterOperators.AFTER:
                        return `${column.caption}: ${displaySymbol} ${value}`;
                    default:
                        return `${column.caption}: ${displaySymbol}`;
                }
            }
            // TODO: not working, didn't not plan to get this to work for phase 1
            case GridColumnFilterTypes.BOOLEAN: { 
                const { truePhrase, falsePhrase, trueTranslateKey, falseTranslateKey } = column.nonDevExOptions;
                let valuePhrase;
                if (value) {
                    valuePhrase = trueTranslateKey ? $translate.instant(trueTranslateKey) : truePhrase;
                } else {
                    valuePhrase = falseTranslateKey ? $translate.instant(falseTranslateKey) : falsePhrase;
                }
                return `${column.caption}: ${valuePhrase}`;
            }
            // TODO: not working, didn't not plan to get this to work for phase 1
            case GridColumnFilterTypes.RADIO: {
                const option = column.reportListOptions.find(option => option.value === value);
                const optionName = option.translateKey ? $translate.instant(option.translateKey) : option.name;
                return `${column.caption}: ${optionName}`;
            }
            case GridColumnFilterTypes.CHECKBOX: {
                const listOptionNames = column.reportListOptions
                    .filter(option => value.some(
                        value => String(value) === String(option.value) || _.isEqual(value, option.value))
                    )
                    .map(option => option.translateKey ? $translate.instant(option.translateKey) : option.name)
                    .join(', ');
                return `${column.caption}: ${listOptionNames}`;
            }
        }
    }
}
