import promoQuizAssignBackTpl from './assignBack.tpl.html';
import promoQuizAssignBackCtrl from './assignBack.controller';

export default {
    templateUrl: promoQuizAssignBackTpl,
    controller: promoQuizAssignBackCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        section: '<',
        hideSubmit: '<',
        isApprover: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<',
        noteModel: '=',
    }
};
