import moment from 'moment';
import _ from 'lodash';

answerDetailsCtrl.$inject = ['PromoService', 'GameStatuses', '$timeout', '$interval', 'QuizQuestionTypes'];

export default function answerDetailsCtrl(PromoService, GameStatuses, $timeout, $interval, QuizQuestionTypes) {
    const vm = this;
    vm.$onInit = async () => {
        // Passed from component bindings:
        // vm.results

        const resultDetails = vm.results;
        vm.QuizQuestionTypes = QuizQuestionTypes;
        vm.progressColors = ["#E1F0F9", "#BBDAEC","#E1F0F9", "#BBDAEC"];
        vm.gameTitle = resultDetails.gameName;
        vm.earnedScore = resultDetails.earnedScore;
        vm.totalScore = resultDetails.totalScore;
        vm.isSubmitted = ![null, undefined].includes(resultDetails.submittedOn);
        vm.submittedOn = vm.isSubmitted ? moment(resultDetails.submittedOn).format('LLL') : undefined;
        vm.isPointPaid = resultDetails.pointPaid || vm.isSubmitted;
        vm.pointPaid = resultDetails.pointPaid;
        vm.getBackgroundStyle = getBackgroundStyle;

        switch (resultDetails.gameplayStatusID) {
            case GameStatuses.NEW_GAME:
                vm.isNewGame = true;
                break;
            case GameStatuses.DELIVERED:
                vm.isGameDelivered = true;
                break;
            case GameStatuses.IN_PROGRESS:
                vm.isInProgress = true;
                break;
            case GameStatuses.PLAYED:
                vm.isPlayed = true;
                break;
            case GameStatuses.CANCELLED:
                vm.isCancelled = true;
                break;
            default:
                break;
        }

        const resultQuestions = _.get(resultDetails, 'questions');
        if (_.isEmpty(resultQuestions)) {
            throw new Error('Invalid quiz questions');
        }

        vm.isPassed = resultDetails.passingScore < resultDetails.earnedScore || vm.isSubmitted;
        vm.quizResultID = null;

        vm.questionsWithResults = resultQuestions.map(question => {
            if(!_.isArray(question.questionAnswers)) {
                return _.merge({}, question, {
                    displayTime,
                    displayAverageTime,
                    totalAnswered,
                    noAnswers: true
                });
            }
            const correctAnswer = question.questionAnswers.find(answer => [1, true].includes(answer.isCorrectAnswer));
            const selectedAnswer = question.questionAnswers.find(answer => answer.isSelected === 1);
            const isAnswerSelected = ![null, undefined].includes(selectedAnswer);
            const time = question.time ? _.round(question.time) : null;
            const averageTime = question.averageTime? _.round(question.averageTime) : null;
            const displayTime = time > 60 ? `${ Math.floor(time / 60) }m ${time % 60}s` : `${ time }s`
            const displayAverageTime = averageTime > 60 ?
                                        `${ Math.floor(averageTime/60)}m ${averageTime % 60}s` : `${averageTime}s`
            let totalAnswered = 0;
            question.questionAnswers.forEach(answer => {
                if (_.isNumber(answer.totalAnswered)) {
                    totalAnswered += answer.totalAnswered;
                }
            });
            return _.merge({}, question, {
                isAnswerCorrect: isAnswerSelected ? selectedAnswer.isCorrectAnswer : null,
                displayTime,
                displayAverageTime,
                isAnswerSelected,
                isOpen: ( vm.isAll && 
                        ( resultDetails.selectedQuestionID ? 
                            question.questionID === resultDetails.selectedQuestionID : true)) || 
                        (_.isObject(selectedAnswer) ? 
                        selectedAnswer.quizQuestionAnswerID !== correctAnswer.quizQuestionAnswerID :false ),
                candidateAnswer: {
                    selectAnswerId: isAnswerSelected ? selectedAnswer.quizQuestionAnswerID : null
                },
                explanation: isAnswerSelected ? selectedAnswer.explanation : null,
                correctAnswerID: correctAnswer ? correctAnswer.quizQuestionAnswerID : null,
                totalAnswered,
            });
        }).filter(question => question.isAnswerSelected === true || vm.isAll);

        $timeout(()=> {
            vm.questionsWithResults.forEach((question, questionIndex) => {
                if(_.isArray(question.questionAnswers)) {
                    question.questionAnswers.forEach( (questionAnswer, answerIndex) => {
                        if(_.isNumber(question.totalAnswered) && _.isNumber(questionAnswer.totalAnswered)) {
                            const percent = questionAnswer.totalAnswered / question.totalAnswered;
                            
                            // handling rising bar css
                            questionAnswer.backgroundStyle = getBackgroundStyle({ questionIndex, answerIndex, percent });
    
                            //handling count up on the number
                            // 15 ms per tick for 100 ticks = 1.5 seconds
                            questionAnswer.displayPercentValue = '0%';
                            const stopInterval = $interval((i)=> {
                                const displayValue = percent*i;
                                questionAnswer.displayPercentValue = `${displayValue.toFixed(0)}%`
                            }, 15, 100)
                        }
                    });
                }
            });
        }, 0);
    };

    function getBackgroundStyle({ questionIndex, answerIndex, percent }) {
        //percent * 50 because background-size 200% 200% will make the bars twice as big
        return { 
            animation: `percent_question_${questionIndex}_answer_${answerIndex} 1.5s ease-out`,
            background: `linear-gradient(to right, ${vm.progressColors[answerIndex % 4]} ${percent*50}%, transparent ${percent*50}%) 0% 0%/200% 200%`
        };
    }
}
