import _ from 'lodash';

import SignOnTask from './SignOnTask';

// LinkTask hasn't been needed by any companies yet, so this class isn't fully implemented.
// May need some tweaking before using in live.

export default class LinkTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$state = $injector.get('$state');
        this.StateUtils = $injector.get('StateUtils');

        // TODO: consider renaming to this.href to this.url for consistency with NavBarLinks
        this.href = taskObject.href;

        this.state = this.StateUtils.getStateFromUrl(this.href);

        _.merge(this, {
            taskModalOptions: {
                animateFooter: false
            }
        });
    }

    onSubmit() {
        if (!this.state) {
            throw new Error(`signOnTask has invalid url ${this.href} - no corresponding state`);
        }
        return this.$state.go(this.state.name, this.state.params);
    }
}
