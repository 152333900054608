import _ from 'lodash';

spotlightBannerCtrl.$inject = [
    '$interval', '$timeout', '$translate', '$state', 'Session', 'ChooseRecognitionModal', 'SpotlightFilterTypes'
];

export default function spotlightBannerCtrl(
    $interval, $timeout, $translate, $state, Session, ChooseRecognitionModal, SpotlightFilterTypes
) {
    this.$onInit = () => {
        const vm = this;

        vm.user = Session.getUser();
        vm.openChooseRecognition = ChooseRecognitionModal.open;

        const BUTTON_CONSTANTS = {
            applause: {
                key: 1,
                translateKey: 'spotlight_BANNER_APPLAUSE_BUTTON',
                onClick: () => $state.go('applauseForm'),
                cssClasses: 'btn btn-primary applause-button',
                hasPermission: vm.user.canApplaud()
            },
            cultureStory: {
                key: 2,
                translateKey: 'spotlight_BANNER_CULTURE_STORY_BUTTON',
                onClick: function() {
                    return $state.go('cultureStoryForm');
                },
                cssClasses: 'btn btn-primary culture-story-button',
                hasPermission: vm.user.canApplaud()
            },
            nominate: {
                key: 5,
                translateKey: 'spotlight_BANNER_NOMINATE_BUTTON',
                onClick: () => $state.go('award'),
                cssClasses: 'btn btn-primary nominate-button',
                hasPermission: vm.user.hasPermission('AwardDetails')
            },
            shareMedia: {
                key: 6,
                translateKey: 'spotlight_BANNER_SHARE_POST_BUTTON',
                onClick: () => $state.go('socialPostForm'),
                cssClasses: 'btn btn-primary social-post-button',
                hasPermission: vm.user.hasPermission('SocialStreamPost')
            },
            recognize: {
                key: 7,
                translateKey: 'spotlight_BANNER_SEND_APPLAUSE_BUTTON',
                onClick: () => vm.openChooseRecognition(),
                cssClasses: 'btn btn-primary recognition-button',
                hasPermission: vm.user.canApplaud()
            },
        };

        const buttonsArray = [BUTTON_CONSTANTS.recognize, BUTTON_CONSTANTS.shareMedia, BUTTON_CONSTANTS.nominate,
            BUTTON_CONSTANTS.applause, BUTTON_CONSTANTS.cultureStory];

        const isRecognitionSingle = vm.selectedRecognitionFilter && !vm.selectedRecognitionFilter.includes('|');
        const isRecognitionMultiple = vm.selectedRecognitionFilter && vm.selectedRecognitionFilter.includes('|');
        const recognitionClassHead = 'category-type';

        if (isRecognitionSingle) {
            switch (vm.selectedRecognitionFilter) {
                case SpotlightFilterTypes.RECOGNITION.applause.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.applause.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.applause.queryText)}`;
                    break;
                case SpotlightFilterTypes.RECOGNITION.cultureStory.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.cultureStory.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.cultureStory.queryText)}`;
                    break;
                case SpotlightFilterTypes.RECOGNITION.certificate.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.certificate.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.certificate.queryText)}`;
                    break;
                case SpotlightFilterTypes.RECOGNITION.birthday.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.birthday.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.birthday.queryText)}`;
                    break;
                case SpotlightFilterTypes.RECOGNITION.anniversary.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.anniversary.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.anniversary.queryText)}`;
                    break;
                case SpotlightFilterTypes.RECOGNITION.socialPost.queryText:
                    vm.recognitionDisplayName = SpotlightFilterTypes.RECOGNITION.socialPost.displayName;
                    vm.recogitionClass = `${recognitionClassHead}-
                        ${_.kebabCase(SpotlightFilterTypes.RECOGNITION.socialPost.queryText)}`;
                    break;
            }
        }
        if (isRecognitionMultiple && vm.selectedRecognitionFilter) {
            const recognitionType = vm.selectedRecognitionFilter.split('|');
            let recognitionClass;
            recognitionType.forEach((type) => {
                recognitionClass = recognitionClass ? `${recognitionClass} ${recognitionClassHead}-${type}`
                    : `${recognitionClassHead}-${type}`;
            });
            vm.recogitionClass = recognitionClass;
        } else if (isRecognitionMultiple || !vm.selectedRecognitionFilter) { // when isHideMarquee=1 default
            vm.recognitionDisplayName = $translate.instant('spotlight_BANNER_RECOGNITION_DROPDOWN_MULTIPLE_SELECTION');
            vm.recogitionClass = `${recognitionClassHead}-spotlight`;
        }

        vm.showButtonsArray = [];

        if (vm.showBannerButtons) {
            const bannerButtonsQueryStringValues = vm.showBannerButtons.split(',').map(Number);
            buttonsArray.forEach(button => {
                if (bannerButtonsQueryStringValues.includes(button.key) && button.hasPermission) {
                    vm.showButtonsArray.push(button);
                }
            });
        }

        if (vm.marqueeItems.length >= 2) {
            (async () => {
                // Added vm.startMarquee so that when more items are loaded, a new interval isn't started.
                if (vm.startMarquee) {
                    return;
                }
                vm.startMarquee = true;
                await updateActiveItem();
                await $interval(updateActiveItem, 4000);
            })();
        } else {
            vm.showText = false;
        }

        async function updateActiveItem() {
            vm.showText = false;
            await ($timeout(() => {
                if (!vm.activeItem) {
                    vm.itemIndex = 0;
                } else if (vm.marqueeItems[vm.itemIndex + 1]) {
                    vm.itemIndex += 1;
                } else {
                    vm.itemIndex = 0;
                }
                vm.activeItem = vm.marqueeItems[vm.itemIndex];
            }, 10));
            vm.showText = true;
        }
    };
}
