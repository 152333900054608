import _ from 'lodash';

ContactService.$inject = ['$translate', '$http', 'Session', 'Contact'];

export default function ContactService($translate, $http, Session, Contact) {

    // TODO: some of these functions would probably fit better in the Contact class

    return {
        getContact,
        createContactLimiters,
        canUserRedeemFor,
        canUserViewPoints,
        reactivateContact,
        resendContactInvite,
        sendResetPasswordEmail,
        generateTempPassword,
        deactivateContact,
        unlockAccount
    };

    /**
     * Gets the data of a contact.
     * If the contactID is the same as the user currently logged in, Session.getUser() is returned without making any requests.
     * @param {int} contactID
     * @returns {Promise.<Object>}
     */
    async function getContact(contactID) {
        const user = Session.getUser();
        if (user.userID === Number(contactID)) {
            return user;
        }
        const res = await $http.get(`/api/contacts/${contactID}`);
        const company = Session.getCompany();
        return new Contact(company, res.data);
    }

    /**
     * Parses the contact object to create easily displayable limiter objects.
     * Returns two arrays of limiter objects.
     * {
     *      contactLimiters: [ {preface, value}, {preface, value}, ... ],
     *      managerLimiters: [ {preface, value}, {preface, value}, ... ]
     * }
     * @param contact {Object} - JSON received after requesting contact data from API
     */
    function createContactLimiters(contact) {
        const company = Session.getCompany();
        const contactPermissionGroup = company.permissionGroups[contact.permissionGroupCode];

        let contactLimiters = [];

        // Assume profileLimiters are already sorted by displayOrder from the db
        // Assume company limiters are already sorted by num from the db

        if (contactPermissionGroup.profileLimiters) {
            contactLimiters = contactPermissionGroup.profileLimiters.map(profileLimiter => ({
                preface: profileLimiter.isPreface ? profileLimiter.name : '',
                value: contact[`limiterValue${profileLimiter.limiterNum}`]
            }));
        } else {
            // Seems like the profileLimiters table isn't fully populated yet, so fall back to company limiters if so
            contactLimiters = company.limiters.map(limiter => ({
                preface: limiter.name,
                value: contact[`limiterValue${limiter.num}`]
            }));
        }

        const manager = contact.manager;
        if (_.isEmpty(manager)) {
            return { contactLimiters };
        }

        const managerLimiters = [{
            preface: contactPermissionGroup.managerName || $translate.instant('app_CONTACT_LIMITER_PANEL_MANAGER_LIMITER_PREFACE_MANAGER'), // 'Manager'
            value: manager.firstName + ' ' + manager.lastName
        }, {
            preface: $translate.instant('app_CONTACT_LIMITER_PANEL_MANAGER_LIMITER_PREFACE_PHONE'), // 'Phone'
            value: manager.phone
        }, {
            preface: $translate.instant('app_CONTACT_LIMITER_PANEL_MANAGER_LIMITER_PREFACE_EMAIL'), // 'Email'
            value: manager.email
        }]
        .filter(limiter => limiter.value != null); // filter out any limiters with undefined value

        return { contactLimiters, managerLimiters };
    }

    /**
     * Returns true when the user can redeem for the contact
     * @param {Object} contact - JSON received after requesting contact data from API
     * @returns {boolean}
     */
    function canUserRedeemFor(contact) {
        const user = Session.getUser();
        if (contact.isEntity) {
            return (contact.isDownstream || user.entityID === contact.userID) && user.hasPermission('RedeemForEntities');
        }
        return contact.isDownstream && user.hasPermission('RedeemForUsers');
    }

    function canUserViewPoints(contact) {
        const user = Session.getUser();
        if (contact.isEntity) {
            return (contact.isDownstream || user.entityID === contact.userID) && user.hasPermission('ViewAccountsEntities');
        }
        return contact.isDownstream && user.hasPermission('ViewAccountsUsers');
    }

    function reactivateContact(contactID, permissionGroupID) {
        return $http.post(`/api/contacts/${contactID}/reactivate/${permissionGroupID}`);
    }

    function resendContactInvite(contactID) {
        return $http.post(`/api/contacts/${contactID}/invitation`);
    }

    function sendResetPasswordEmail(contactID) {
        return $http.post(`/api/contacts/${contactID}/password-reset`);
    }

    function generateTempPassword(contactID) {
        return $http.post(`/api/contacts/${contactID}/temp-password`)
            .then(function(res) {
                return res.data;
            });
    }

    function deactivateContact({ contactID, replacementManagerID, deletedGraceDate }) {
        return $http.post(`/api/contacts/${contactID}/deactivate`, {
            replacementManagerID,
            deletedGraceDate
        });
    }

    function unlockAccount(contactID) {
        return $http.post(`/api/contacts/${contactID}/unlock-account`);
    }

}
