export default {
    pointsGraph: {
        palette: 'Harmony Light',
        commonSeriesSettings: {
            argumentField: 'date'
        },
        margin: {
            right: 10,
            left: 10,
            top: 10,
            bottom: 10
        },
        argumentAxis: {
            valueMarginsEnabled: false,
            discreteAxisDivisionMode: 'crossLabels',
            grid: {
                visible: true
            }
        },
        legend: {
            visible: false
        }
    }
};
