import randomColor from 'randomcolor';

hashBackgroundColor.$inject = [];

export default function hashBackgroundColor() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            const color = randomColor({
                seed: attrs.hashBackgroundColor,
                luminosity: attrs.luminosity || 'light'
            });
            element.css('background-color', color);
        }
    };
}
