import _ from 'lodash';

postSubmitCtrl.$inject = ['$state', 'StateUtils'];

export default function postSubmitCtrl($state, StateUtils) {
    const vm = this;

    vm.postAgainLinkSref = $state.params.from;
    StateUtils.updateUrlParams({ from: $state.params.from });

    vm.randomImageNumber = _.random(1, 5);
    vm.randomMainMessageNumber = _.random(1, 6);
    vm.randomSubMessageNumber = _.random(1, 6);
}
