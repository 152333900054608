import angular from 'angular';

import '../../../../core/core.module';

import spotlightFilter from './spotlightFilter.component';

export default angular.module('app.spotlight.spotlightComponents.spotlightFilter', [
    'app.core',

])
.component('spotlightFilter', spotlightFilter);
