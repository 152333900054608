import ApiRequestError from './ApiRequestError';

ErrorInterceptor.$inject = ['$injector'];

export default function ErrorInterceptor($injector) {
    return {
        responseError(res) {
            const $q = $injector.get('$q');
            const UrlUtils = $injector.get('UrlUtils');
            const isApiRequest = UrlUtils.isApiRequest(res);
            if (isApiRequest) {
                // 503 Service Unavailable - when user's permission group is deleted or when maintenance-server is on.
                if (res.status === 503) {
                    const TabRefreshService = $injector.get('TabRefreshService');
                    return TabRefreshService.refresh('/');
                }
                const err = new ApiRequestError(res);
                return $q.reject(err);
            } else {
                return $q.reject(res);
            }
        }
    };
}
