import angular from 'angular';

import '../../../core/core.module';
import './contactPanelLinks/contactPanelLinks.module';

import contactPanel from './contactPanel.component';

export default angular.module('app.contacts.contactPanel', [
    'app.core',
    'app.contacts.contactPanel.contactPanelLinks'
])
.component('contactPanel', contactPanel);
