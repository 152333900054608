socialPostDetailsCtrl.$inject = ['Session', 'LangUtils', 'FileUtils', 'SkinUtils'];

export default function socialPostDetailsCtrl(Session, LangUtils, FileUtils, SkinUtils) {
    this.$onInit = () => {
        const vm = this;

        vm.user = Session.getUser();
        vm.company = Session.getCompany();

        const langCode = LangUtils.getLang();
        const skinCode = SkinUtils.getSkinCode();

        if (vm.socialPost.socialPostFileURL) {
            vm.isImage = FileUtils.isValidImage(vm.socialPost.socialPostFileURL);
            vm.isVideo = FileUtils.isValidVideo(vm.socialPost.socialPostFileURL);
        } else if (vm.mediaFile) {
            vm.isImage = FileUtils.isValidImage(vm.mediaFile.name);
            vm.isVideo = FileUtils.isValidVideo(vm.mediaFile.name);
        } else {
            vm.isImage = false;
            vm.isVideo = false;
        }

        vm.showCommentForm = true;

        // TODO: this is duplicated in applauseDetails.controller.js, make a function in CommunicationService to share
        vm.logoImageSrc = `/assets/clients/${vm.company.subDomain}/${skinCode}/images/${langCode}/Logo_Med.gif`;
    };
}
