import _ from 'lodash';
import moment from 'moment';
import * as angular from 'angular';

promoQuizSectionCtrl.$inject = [
    '$scope', '$q', '$timeout', 'Session', 'PromoQuizService', 'PromoQuizSectionService', 'QuizNoteTypes',
    'PostNominationModal', 'SimpleModal', 'PromoTypes', 'QuizQuestionTypes'
];

export default function promoQuizSectionCtrl(
    $scope, $q, $timeout, Session, PromoQuizService, PromoQuizSectionService, QuizNoteTypes,
    PostNominationModal, SimpleModal, PromoTypes, QuizQuestionTypes
) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            formSubmitSuccessCallback: _.noop,
            formSubmitFailCallback(err) {
                if (err.status === 400 && !(err.data && err.data.returnCode)) {
                    if(err.data.question) {
                        vm.formly.options.formState.serverError = err.data.message;
                        vm.rnFormCtrl.fields
                            .find(field => field.key === err.data.question.ID).formControl
                            .$setValidity('serverSide', false);
                        return;
                    }
                    SimpleModal.open({
                        type: 'error',
                        title: 'promo_INVALID_FIELDS_MODAL_TITLE', // 'Error'
                        body: 'promo_INVALID_FIELDS_MODAL_BODY', // 'Your form has invalid fields'
                        error: err.data,
                    });
                } else {
                    throw err;
                }
            },
            onSnoozeToggled: _.noop
        });

        vm.isAward = vm.promoDetails.promoTypeID === PromoTypes.AWARD;

        vm.user = Session.getUser();

        const promoID = vm.promoDetails.ID;
        const quizID = vm.promoDetails.quiz.ID;
        const sectionID = vm.section.sectionID;

        const quizResults = vm.promoDetails.results;
        if (quizResults) {
            vm.quizResultID = quizResults.quizResultID;
            vm.quizTakerID = quizResults.quizTakerID;
        }

        vm.sectionNumber = PromoQuizService.getSectionNumber(vm.promoDetails, vm.section);
        vm.sectionResults = PromoQuizService.getSectionResults(vm.promoDetails, vm.section);

        if (vm.sectionResults) {
            vm.sectionStatusID = vm.sectionResults.sectionStatusID;
            setupNotes(vm.sectionResults);
        }

        vm.showSubmitButton = PromoQuizService.canSubmitSection(vm.promoDetails, vm.section);
        vm.showEditButton = PromoQuizService.canEditSection(vm.promoDetails, vm.section);

        vm.isApprover = PromoQuizService.isUserApprover(vm.promoDetails);
        vm.canShowOcrValidation = PromoQuizService.canShowOcrValidation(vm.promoDetails, vm.section);
        vm.isCandidate = PromoQuizService.isUserCandidate(vm.promoDetails);

        vm.showSnoozeButton = PromoQuizService.canSnoozeSection(vm.promoDetails, vm.section);
        vm.isSnoozed = PromoQuizService.isSectionSnoozed(vm.promoDetails, vm.section);
        vm.showSnoozeForm = vm.showSnoozeButton && vm.isSnoozed;
        if (vm.showSnoozeForm) {
            setupSnoozeForm({ snoozeUntilDate: vm.sectionResults.snoozeUntilDate });
        }

        vm.isCollapsable = PromoQuizService.canCollapseSection(vm.promoDetails, vm.section);
        vm.isCollapsed = PromoQuizService.isSectionCollapsed(vm.promoDetails, vm.section);

        // creates formly object
        vm.formly = PromoQuizService.convertQuizSectionToFormly(vm.promoDetails, vm.section);

        // gets a list of keys that are customFileUpload type questions
        vm.uploadFieldKeys = vm.formly.fields.filter(field => field.type === 'customFileUpload').map(field => field.key);

        vm.formModel = {
            candidates: []
        };

        vm.submitButtonOptions = {
            settings: {
                success: {
                    isDisabled: true,
                    noReset: true
                }
            }
        };

        vm.enableEditing = function() {
            vm.formly.fields.forEach(field => {
                field.templateOptions.disabled = false;
            });
            vm.showSubmitButton = true;
            vm.showEditButton = false;
        };

        vm.toggleCollapse = function(isCollapsed = !vm.isCollapsed) {
            vm.isCollapsed = isCollapsed;
            $timeout(() => $scope.$broadcast('RESIZE_SUBMITTED_FILES_LIST'));
        };

        vm.toggleNoteFlag = function(note) {
            return PromoQuizService.toggleNoteFlag({
                quizResultNoteID: note.ID,
                isFlagged: !note.isCommentFlag
            })
            .then(function() {
                note.isCommentFlag = !note.isCommentFlag;
            });
        };

        vm.toggleSnooze = function() {
            vm.snoozePromise = vm.isSnoozed ? deleteSnooze() : snoozeSubmission();
            vm.snoozePromise.then(({ snoozeUntilDate } = {}) => {
                vm.isSnoozed = !vm.isSnoozed;
                vm.showSnoozeForm = vm.isSnoozed;
                if (vm.showSnoozeForm) {
                    setupSnoozeForm({ snoozeUntilDate });
                }
                vm.toggleCollapse(vm.isSnoozed);
                reloadNotes();
                vm.onSnoozeToggled(vm.isSnoozed);
            });
        };

        vm.submit = () => submitAnswers({ isRequestApproval: true });
        vm.scrollToMostRecentNote = scrollToMostRecentNote;

        vm.onFormSubmitSuccess = (res) => {
            const isApplauseMessageNeeded = checkApplauseMessageNeeded();
            if (vm.isAward && isApplauseMessageNeeded) {
                openPostNominationModal();
            }
            return vm.formSubmitSuccessCallback(res, vm.model);
        };

        vm.onFormSubmitFail = (err) => vm.formSubmitFailCallback(err);

        // TODO: future 'Save' button will this
        // vm.save = () => submitAnswers({ isRequestApproval: false });

        function scrollToMostRecentNote(sectionID) {
            angular.element(`#section-${sectionID}-most-recent-note`)[0].scrollIntoView({
                behavior: 'smooth'
            });
        }

        function submitAnswers({ isRequestApproval }) {
            const answers = Object.assign({}, vm.formModel);

            if (!answers.candidate) {
                delete answers.candidate;
            } else {
                answers.candidate = answers.candidate.ID;
            }
            if (!answers.candidates.length) {
                delete answers.candidates;
            } else {
                answers.candidates = answers.candidates.map(candidate => candidate.ID);
            }
            return PromoQuizSectionService.submitQuizSection({
                promoID,
                quizID,
                sectionID,
                quizResultID: vm.quizResultID,
                quizAnswers: answers,
                isRequestApproval,
            });
        }


        function snoozeSubmission(snoozeUntilDate) {
            return PromoQuizService.snoozeSubmission({
                quizResultID: vm.quizResultID,
                quizSectionID: sectionID,
                snoozeUntilDate
            });
        }

        function deleteSnooze() {
            return PromoQuizService.deleteSnooze({
                quizResultID: vm.quizResultID,
                quizSectionID: sectionID
            });
        }

        /**
         * @param {Object} params
         * @param {string} params.snoozeUntilDate - Initial value of the date selector
         */
        function setupSnoozeForm({ snoozeUntilDate }) {
            vm.snoozeModel = {};
            vm.snoozeFields = [{
                key: 'snoozeUntilDate',
                type: 'customCalendar',
                defaultValue: moment(snoozeUntilDate),
                templateOptions: {
                    labelTranslateKey: 'app_PROMO_QUIZ_SECTION_SNOOZE_DATE_SELECTOR_LABEL', // 'Snoozing until'
                    hideResetButton: true,
                    uibDatepickerOptions: {
                        minDate: moment(),
                    },
                    onChange: onSnoozeDateChanged
                }
            }];
        }

        async function onSnoozeDateChanged(snoozeUntilDate) {
            await snoozeSubmission(snoozeUntilDate);
            flashSnoozeSuccess();
            return reloadNotes();
        }

        function flashSnoozeSuccess() {
            vm.showSnoozeSuccess = true;
            $timeout(() => {
                vm.showSnoozeSuccess = false;
            }, 1500);
        }

        async function reloadNotes() {
            // TODO: getting all sections just to reload the current section's notes is not optimal
            const promoDetails = await PromoQuizService.getQuizResults({ quizResultID: vm.quizResultID });
            const sectionResults = PromoQuizService.getSectionResults(promoDetails, vm.section);
            if (sectionResults) {
                setupNotes(sectionResults);
            }
        }

        function setupNotes(sectionResults) {
            vm.notes = sectionResults.comments || [];
            vm.notes.forEach(note => {
                note.title = PromoQuizService.getNoteTitle(vm.promoDetails, note);
                const typeProperties = QuizNoteTypes.properties[note.typeID];
                if (typeProperties) {
                    note.cssClass = typeProperties.cssClass;
                }
            });
        }

        function openPostNominationModal() {
            const isTeamNomination = vm.promoDetails.quiz.isMultipleRecipients;
            const canApplaud = isTeamNomination ? vm.user.canApplaudTeam() : vm.user.canApplaudIndividual();
            if (!canApplaud) {
                return PostNominationModal.open();
            }
            const applauseForm = {
                message: getApplauseMessage()
            };
            if (isTeamNomination) {
                if (vm.formModel.teamName) {
                    applauseForm.teamName = vm.formModel.teamName;
                }
                const groupWasNominated = vm.formly.options.formState.promoSuggestType.value === 'group';
                if (groupWasNominated) {
                    applauseForm.group = vm.formModel.candidate;
                } else {
                    applauseForm.recipients = vm.formModel.candidates;
                }
            } else {
                applauseForm.recipient = vm.formModel.candidate;
            }
            return PostNominationModal.open({ applauseForm });
        }

        function getApplauseMessage() {
            return vm.section.questions
                .filter(question => question.isForwardToApplause && vm.formModel[question.ID] != null && [
                    QuizQuestionTypes.SHORT_ANSWER,
                    QuizQuestionTypes.LONG_ANSWER,
                    QuizQuestionTypes.CERTIFICATE_DESCRIPTION,
                    QuizQuestionTypes.CERTIFICATE_EXTRA_INFO
                ].includes(question.quizQuestionTypeID))
                .map(question => `<p>${vm.formModel[question.ID].replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>`)
                .join('\n');
        }

        function checkApplauseMessageNeeded() {
            const forwardToApplauseQuestions = vm.section.questions.filter(question => question.isForwardToApplause);
            if (forwardToApplauseQuestions.length) {
                return true;
            }
            return false;
        }
    };
}
