import contactUsTpl from './contactUs.tpl.html';

contactUsConfig.$inject = ['$stateProvider'];

export default function contactUsConfig($stateProvider) {
    $stateProvider.state('contactUs', {
        parent: 'nav',
        url: '/contact-us',
        views: {
            'main@': {
                controller: 'contactUsCtrl as vm',
                templateUrl: contactUsTpl
            }
        },
        data: {
            pageTitle: 'Contact Us',
            isPublicPage: true
        },
        resolve: {
            contactUsSetup: ['ContactUsService', function(ContactUsService) {
                return ContactUsService.getContactUsSetup();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                // TODO: a separate phrase prefix for contactUs, Ex. faq_CONTACT_US_NAME_PLACEHOLDER --> contactUs_NAME_PLACEHOLDER
                return RnTranslationService.loadPhrases('faq');
            }]
        }
    });
}
