

const constantUtils = require('./constantUtils');

const Periods = {
    AD_HOC: 1,
    DAILY: 2,
    WEEKLY: 3,
    MONTHLY: 4,
    YEARLY: 5,
    QUARTERLY: 6
};

const properties = {
    [Periods.AD_HOC]: {
        translateKey: 'app_PERIODS_AD_HOC'// 'Ad Hoc'
    },
    [Periods.DAILY]: {
        translateKey: 'app_PERIODS_DAILY' // 'Daily'
    },
    [Periods.WEEKLY]: {
        translateKey: 'app_PERIODS_WEEKLY' // 'Weekly'
    },
    [Periods.MONTHLY]: {
        translateKey: 'app_PERIODS_MONTHLY' // 'Monthly'
    },
    [Periods.YEARLY]: {
        translateKey: 'app_PERIODS_YEARLY' // 'Yearly'
    },
    [Periods.QUARTERLY]: {
        translateKey: 'app_PERIODS_QUARTERLY' // 'Quarterly'
    }
};

constantUtils.configure({ constants: Periods, properties });

module.exports = Periods;
