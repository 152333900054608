

const constantUtils = require('./constantUtils');

const MilestoneTypes = {
    ANNIVERSARY: 1,
    BIRTHDAY: 2
};

const properties = {};

constantUtils.configure({ constants: MilestoneTypes, properties });

module.exports = MilestoneTypes;
