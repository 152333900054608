import metricSnapshotWidgetTpl from './metricSnapshotWidget.tpl.html';
import metricSnapshotWidgetCtrl from './metricSnapshotWidget.controller';

export default {
    templateUrl: metricSnapshotWidgetTpl,
    controller: metricSnapshotWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
