import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class UpdatePasswordTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$q = $injector.get('$q');
        this.ChangePasswordService = $injector.get('ChangePasswordService');
        this.RnTranslationService = $injector.get('RnTranslationService');

        // If the UpdatePassword task is required, then that means the user recently forgot their password and
        // are logging in with a token they received in their email
        this.hasToken = this.isRequired;

        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_UPDATE_PASSWORD_TITLE', // 'Update your password'
            successMessage: 'app_SIGN_ON_TASKS_UPDATE_PASSWORD_SUCCESS_MESSAGE', // 'Password updated'
            taskModalOptions: {
                showFooterButtons: false,
                successMessageTimeout: 2000
            }
        });
    }

    getAdditionalData() {
        return this.$q.all({
            passwordPolicy: this.ChangePasswordService.getPasswordPolicy(),
            translation: this.RnTranslationService.loadPhrases('changePassword'),
        })
        .then(({ passwordPolicy }) => {
            this.passwordPolicy = passwordPolicy;
        });
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;

        vm.passwordPolicy = this.passwordPolicy;
        vm.hasToken = this.hasToken;

        vm.submitButtonOptions = {
            onSuccess() {
                this.hidden = true;
            }
        };
    }

    get componentHtml() {
        return `
            <change-password-form password-policy="::vm.passwordPolicy" 
                                  submit-button-options="vm.submitButtonOptions"
                                  has-token="::vm.hasToken"
                                  on-success="::vm.onSuccess" 
                                  on-fail="::vm.onFail">
            </change-password-form>`;
    }
}
