import angular from 'angular';

import '../../../../core/utils/coreUtils.module';
import '../../../../blocks/storage/storage.module';

import RedemptionService from './redemptionService.factory';
import redemptionDetailsCtrl from './redemptionDetails.controller';
import redemptionDetails from './redemptionDetails.component';

export default angular.module('app.communication.redemption.redemptionDetails', [
    'app.core.utils',
    'app.blocks.storage'
])
.factory('RedemptionService', RedemptionService)
.controller('redemptionDetailsCtrl', redemptionDetailsCtrl)
.component('redemptionDetails', redemptionDetails);
