import spotlightTpl from './spotlight.tpl.html';
import spotlightItemsContainerTpl from './spotlightItemsContainer/spotlightItemsContainer.tpl.html';

spotlightConfig.$inject = ['$stateProvider'];

export default function spotlightConfig($stateProvider) {
    $stateProvider.state('spotlight', {
        parent: 'auth',
        url: '/spotlight?isShowAnniversary&isShowBirthdays&isHideMarquee&buttons&messageRecipientID',
        params: {
            messageRecipientID: {
                dynamic: true
            }
        },
        views: {
            'main@': {
                controller: 'spotlightCtrl as vm',
                templateUrl: spotlightTpl
            }
        },
        data: {
            pageTitle: 'Spotlight',
            permission: 'ViewSpotlight'
        },
        resolve: {
            marqueeItems: ['$stateParams', 'SpotlightService', 'SpotlightFilterTypes',
                function($stateParams, SpotlightService, SpotlightFilterTypes) {
                    let defaultParticipant;
                    Object.keys(SpotlightFilterTypes.PARTICIPANT).forEach(key => {
                        const participant = SpotlightFilterTypes.PARTICIPANT[key];
                        Object.keys(participant).forEach(participantKey => {
                            if (participantKey === 'default' && participant[participantKey]) {
                                defaultParticipant = participant;
                            }
                        });
                    });
                    const participant = $stateParams.participant || defaultParticipant.queryText;
                    const recognition = $stateParams.recognition;
                    const sent = $stateParams.sent;
                    return !parseInt($stateParams.isHideMarquee) ? SpotlightService.getSpotlightMarqueeItems({
                        participant,
                        recognition,
                        sent
                    }) : [];
                }],
            contact: ['$stateParams', 'ContactService', function($stateParams, ContactService) {
                return $stateParams.contactID ? ContactService.getContact($stateParams.contactID) : null;
            }],
            anniversary: ['AnniversaryService', function(AnniversaryService) {
                return AnniversaryService.getAnniversary();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('spotlight');
            }]
        }
    });

    $stateProvider.state('spotlightItems', {
        url: '/:code?contactID&participant&recognition&sent&recentDays&startDate',
        parent: 'spotlight',
        views: {
            'main.spotlightItems@': {
                controller: 'spotlightItemsContainerCtrl as vm',
                templateUrl: spotlightItemsContainerTpl
            }
        },
        resolve: {
            spotlightItems: ['$stateParams', 'SpotlightService', function ($stateParams, SpotlightService) {
                const participant = $stateParams.participant;
                const recognition = $stateParams.recognition;
                const sent = $stateParams.sent;
                const recentDays = $stateParams.recentDays;
                const startDate = $stateParams.startDate;
                const contactID = $stateParams.contactID;
                if (!contactID) {
                    return SpotlightService.getSpotlightItems({ participant, recognition, sent, recentDays, startDate });
                } else {
                    return SpotlightService.getSpotlightItems({
                        participant,
                        recognition,
                        sent,
                        recentDays,
                        startDate,
                        contactID
                    });
                }
            }],
            contact: ['$stateParams', 'ContactService', function($stateParams, ContactService) {
                return $stateParams.contactID ? ContactService.getContact($stateParams.contactID) : null;
            }]
        }
    });
}
