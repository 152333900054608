import angular from 'angular';

import '../../core/core.module';
import './login/login.module';
import './timeout/authTimeout.module';

import authConfig from './auth.config';
import authRun from './auth.run';
import authCtrl from './auth.controller';
import AuthService from './authService.factory';
import AuthStatus from './authStatus.factory';
import AuthStateService from './authStateService.factory';

export default angular.module('app.auth', [
    'app.core',
    'app.auth.login',
    'app.auth.timeout',
])
.config(authConfig)
.run(authRun)
.controller('authCtrl', authCtrl)
.factory('AuthService', AuthService)
.factory('AuthStatus', AuthStatus)
.factory('AuthStateService', AuthStateService);
