submissionsGridCtrl.$inject = [
    '$scope', 'GridColumnTypes', 'GridColumnFilterTypes', 'SubmissionsGridService', 'Session', 'PromoQuizService',
    'Communication', 'RnDxGridService', 'GridFilterOperators'
];

export default function submissionsGridCtrl(
    $scope, GridColumnTypes, GridColumnFilterTypes, SubmissionsGridService, Session, PromoQuizService,
    Communication, RnDxGridService
) {
    this.$onInit = () => {
        const vm = this;

        vm.company = Session.getCompany();

        vm.gridID = vm.gridID || (vm.isAdminView ? 'grid-submissions-admin' : 'grid-submissions');

        vm.columns = [{
            dataField: 'incentive',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'submissions_INCENTIVE_NAME_COLUMN_HEADING'
            }
        }, {
            dataField: 'step',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'submissions_SECTION_NAME_COLUMN_HEADING'
            }
        },
        SubmissionsGridService.commonColumns.quizResultStatusColumn,
        {
            dataField: 'reference',
            width: 200, // set to 200px because dx-grid doesn't have a max-width property
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'submissions_REFERENCE_COLUMN_HEADING'
            }
        }, {
            dataField: 'lastUpdated',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'submissions_LAST_UPDATED_COLUMN_HEADING'
            }
        }, {
            dataField: 'points',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'app_POINTS'
            }
        },
        SubmissionsGridService.commonColumns.isSnoozeColumn,
        {
            nonDevExOptions: {
                isOptionsColumn: true,
                captionTranslateKey: 'submissions_OPTIONS_COLUMN_HEADING',
                cellTemplate: `
                    <grid-option-button on-click="vm.openDetailsModal(cell)"
                                        text-translate-key="submissions_OPEN_DETAILS_ICON_TITLE"
                                        button-class="details-button"
                                        icon-class="fa fa-search-plus">
                    </grid-option-button>`
            }
        }];

        if (vm.isAdminView) {
            const participantsColumnIndex = 5;
            const participantsColumn = {
                dataField: 'participant',
                nonDevExOptions: {
                    type: GridColumnTypes.TEXT,
                    filterType: GridColumnFilterTypes.TEXT,
                    captionTranslateKey: 'submissions_PARTICIPANT_NAME_COLUMN_HEADING'
                }
            };
            vm.columns.splice(participantsColumnIndex, 0, participantsColumn);

            const newNoteColumnIndex = 6;
            const newNoteColumn = {
                dataField: 'isNewNote',
                nonDevExOptions: {
                    type: GridColumnTypes.BOOLEAN,
                    filterType: GridColumnFilterTypes.BOOLEAN,
                    captionTranslateKey: 'submissions_NEW_NOTE_COLUMN_HEADING',
                    cellTemplate: `
                        <i ng-if="cell.data.isNewNote"
                        class="fa fa-flag new-note-icon"
                        translate-attr="{
                                'title': 'submissions_NEW_NOTE_ICON_TITLE',
                                'aria-label': 'submissions_NEW_NOTE_ICON_TITLE'
                        }">
                        </i>`
                }
            };
            vm.columns.splice(newNoteColumnIndex, 0, newNoteColumn);

            const limiterColumns = vm.company.limiters.map((limiter) => ({
                dataField: `limiter${limiter.num}`,
                caption: limiter.name,
                visible: false,
                nonDevExOptions: {
                    type: GridColumnTypes.TEXT,
                    filterType: GridColumnFilterTypes.TEXT,
                }
            }));
            vm.columns.splice(vm.columns.length - 1, 0, ...limiterColumns); // Insert before "options" column
        }

        // Columns that aren't shown but are filterable
        // TODO: see how to display user-friendlier filter display text when the filter is programmatically applied to hidden columns
        // Ex. $state.go('submissionsHierarchy', { filters: [{ field: 'incentiveID', value: 1234, ... }] })
        //      --> filter should show "Incentive: The Incentive Name" rather than "Incentive ID: 1234"

        const hiddenColumns = [{
            dataField: 'incentiveID',
            visible: false,
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'submissions_INCENTIVE_ID_COLUMN_HEADING'
            }
        }, {
            dataField: 'sectionID',
            visible: false,
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'submissions_SECTION_ID_COLUMN_HEADING'
            }
        }];

        vm.columns = vm.columns.concat(hiddenColumns);

        vm.openDetailsModal = async function(cell) {
            const { quizResultID } = cell.data;
            const promoDetails = await PromoQuizService.getQuizResults({ quizResultID });
            Communication.openIncentive(promoDetails)
                .result
                .finally(() => reloadQuizResultRows(cell.data));
        };

        const reloadQuizResultRows = ({ quizResultID }) => RnDxGridService.reloadRows({
            gridID: vm.gridID,
            dataField: 'quizResultID',
            dataFieldValue: quizResultID,
            findUpdatedRowData: (row, updatedRowsData) => updatedRowsData.find(updatedRow => updatedRow.sectionID === row.data.sectionID)
        });
    };
}
