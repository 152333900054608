import registrationPageTpl from './registrationPage.tpl.html';

registrationConfig.$inject = ['$stateProvider'];

export default function registrationConfig($stateProvider) {
    $stateProvider.state('registration', {
        parent: 'auth',
        url: '/register',
        views: {
            'main@': {
                controller: 'registrationPageCtrl as vm',
                templateUrl: registrationPageTpl
            }
        },
        data: {
            pageTitle: 'Registration',
            isPublicPage: true
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('registration');
            }]
        }
    });
}
