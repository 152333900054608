import angular from 'angular';

import '../../../core/core.module';
import '../promoHeader/promoHeader.module';
import '../promoWidgets/promoGaugeWidget/promoGaugeWidget.module';
import '../promoWidgets/promoTargetsTable/promoTargetsTable.module';
import '../promoWidgets/promoLeaderboardWidget/promoLeaderboardWidget.module';
import '../promoQuiz/promoQuiz.module';

import promoViewConfig from './promoView.config';
import promoViewCtrl from './promoView.controller';

export default angular.module('app.promo.view', [
    'app.core',
    'app.promo.promoHeader',
    'app.promo.promoGaugeWidget',
    'app.promo.promoTargetsTable',
    'app.promo.promoLeaderboardWidget',
    'app.promo.promoQuiz'
])
.config(promoViewConfig)
.controller('promoViewCtrl', promoViewCtrl);
