import gamesFramePageTpl from './gamesFramePage.tpl.html';

gamesFramePageConfig.$inject = ['$stateProvider'];

export default function gamesFramePageConfig($stateProvider) {
    $stateProvider.state('gamesFramePage', {
        parent: 'auth',
        url: '/games-frame/:gameCode?guid&displayCode',
        views: {
            'main@': {
                controller: 'gamesFramePageCtrl as vm',
                templateUrl: gamesFramePageTpl
            }
        },
        data: {
            pageTitle: 'Games'
        }
    });
}
