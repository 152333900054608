RewardService.$inject = ['$http'];
export default function RewardService($http) {
    return {
        getStoreList,
        requestSINSkip
    }

    async function getStoreList() {
        const res = await $http.get('/api/reward/storeList');
        console.log(res.data);
        return res.data;
    }

    function requestSINSkip() {
        return $http.post('/api/reward/SINSkip');
    }
}
