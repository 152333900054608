alertMessage.$inject = [];

export default function alertMessage() {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.type
        // vm.hideIcon
    };
}
