import profilePictureDetailsTpl from './profilePictureDetails.tpl.html';
import profilePictureDetailsCtrl from './profilePictureDetails.controller';

export default {
    templateUrl: profilePictureDetailsTpl,
    controller: profilePictureDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        contactID: '<contactId',
        hasPicture: '<',
        pictureUrl: '@',
        isEditable: '<',
        onUpdateSuccess: '<',
        onUpdateFail: '<',
        onDeleteSuccess: '<',
        onDeleteFail: '<'
    }
};
