import _ from 'lodash';

formlyRun.$inject = ['$translate', 'RnTranslationService', 'formlyValidationMessages', 'formlyConfig'];

export default function formlyRun($translate, RnTranslationService, formlyValidationMessages, formlyConfig) {
    formlyConfig.extras.removeChromeAutoComplete = true;
    formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = '(fc.$touched && fc.$invalid) || form.$submitted';

    const validationMessages = {
        required: 'app_FORM_VALIDATION_MESSAGES_REQUIRED', // 'This field is required'
        minlength: 'app_FORM_VALIDATION_MESSAGES_MINLENGTH', // 'This field is too short'
        maxlength: 'app_FORM_VALIDATION_MESSAGES_MAXLENGTH', // 'This field is too long'
        typeAhead: 'app_FORM_VALIDATION_MESSAGES_TYPEAHEAD' // 'Please pick a field in the drop-down'
    };

    RnTranslationService.loadPhrases('app')
        .then(function() {
            _.forEach(validationMessages, function(translateKey, validatorName) {
                const validatorMessage = $translate.instant(translateKey);
                formlyValidationMessages.addStringMessage(validatorName, validatorMessage);
            });
        });
}
