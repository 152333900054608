import angular from 'angular';

import customInputRun from './customInput.run';
import customIntInputRun from './customIntInput.run';

export default angular.module('common.formly.customInput', [
    'formly'
])
.run(customInputRun)
.run(customIntInputRun);
