QuizResultStatusLabelService.$inject = ['QuizResultStatuses', 'Session'];

export default function QuizResultStatusLabelService(QuizResultStatuses, Session) {

    /**
     * @param {Object} params
     * @param {int} params.quizResultStatusID
     * @param {boolean} params.isApprover
     * @param {int} params.quizTakerID
     * @returns {{ type, hideAlertIcon }}
     */
    function getAlertMessageSettings({ quizResultStatusID, isApprover, quizTakerID }) {
        const type = determineAlertMessageType(quizResultStatusID);
        const hideIcon = determineIfHideIcon({ quizResultStatusID, isApprover, quizTakerID });
        return { type, hideIcon };
    }

    /**
     * @param {int} quizResultStatusID
     * @returns {string}
     */
    function determineAlertMessageType(quizResultStatusID) {
        switch (quizResultStatusID) {
            case QuizResultStatuses.PENDING_APPROVAL:
            case QuizResultStatuses.WINNER_PENDING_APPROVAL:
            case QuizResultStatuses.REQUIRES_ACTION:
                return 'warning';
            case QuizResultStatuses.DENIED:
            case QuizResultStatuses.WINNER_APPROVAL_DENIED:
                return 'error';
            case QuizResultStatuses.COMPLETED:
            case QuizResultStatuses.WINNER:
                return 'success';
        }
    }

    /**
     * @param {Object} params
     * @param {int} params.quizResultStatusID
     * @param {boolean} params.isApprover
     * @param {int} params.quizTakerID
     * @returns {boolean} - True if the default icon should be hidden and and hourglass icon should be shown instead
     */
    function determineIfHideIcon({ quizResultStatusID, isApprover, quizTakerID }) {
        const user = Session.getUser();
        const isSelf = user.userID === quizTakerID;

        switch (quizResultStatusID) {
            case QuizResultStatuses.PENDING_APPROVAL:
            case QuizResultStatuses.WINNER_PENDING_APPROVAL:
                return !isApprover;
            case QuizResultStatuses.REQUIRES_ACTION:
                return !isSelf;
            default:
                return false;
        }
    }

    return {
        getAlertMessageSettings
    };
}
