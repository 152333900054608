// import io from 'socket.io-client';
Socket.$inject = ['$rootScope'];

export default function Socket($rootScope) {
    // TODO: future socket implementation
    /* const socket = io.connect();
    return {
        on: (eventName, callback) => {
            socket.on(eventName, (...args) => {
                $rootScope.$apply(function() {
                    callback.apply(socket, args);
                })
            })
        },
        emit: (eventName, data, callback) => {
            socket.emit(eventName, data, (...args) => {
                $rootScope.$apply(() => {
                    if(callback) {
                        callback.apply(socket, args);
                    }
                })
            })
        }
    } */

}
