stateUtilsRun.$inject = ['$rootScope', '$window', 'Session', 'AUTH_EVENTS', 'StateUtils', 'AuthStatus'];

export default function stateUtilsRun($rootScope, $window, Session, AUTH_EVENTS, StateUtils, AuthStatus) {
    if (AuthStatus.isAuthenticated()) {
        const user = Session.getUser();
        StateUtils.setDefaultState(user.permissionGroup.defaultPage);
    }

    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, function(event, { user }) {
        StateUtils.setDefaultState(user.permissionGroup.defaultPage);
    });
}
