dashboardConfig.$inject = ['$stateProvider'];

export default function dashboardConfig($stateProvider) {
    $stateProvider.state('dashboard', {
        parent: 'auth',
        url: '/dashboard/:dashboardCode/:dashboardID',
        views: {
            'main@': 'dashboardComponent',
        },
        data: {
            permission: 'UserReports',
        },
        resolve: {
            dashboard: ['DashboardService', '$stateParams', function (DashboardService, $stateParams) {
                return DashboardService.getDashboard($stateParams);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('snapshot');
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('dashboard');
            }],
        }
    });
}
