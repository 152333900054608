import angular from 'angular';

import '../legacyFrame/legacyFrame.module';

import notificationManagementConfig from './notificationManagement.config';
import notificationManagementCtrl from './notificationManagement.controller';

export default angular.module('app.notificationManagement', [
    'app.legacyFrame'
])
.config(notificationManagementConfig)
.controller('notificationManagementCtrl', notificationManagementCtrl);
