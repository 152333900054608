import angular from 'angular';

import '../../core/core.module';
import './incentiveLibrary.module';
import './incentiveLibraryList/incentiveLibraryList.module';

import incentiveLibraryConfig from './incentiveLibrary.config';
import incentiveLibraryCtrl from './incentiveLibrary.controller';
import incentiveLibraryService from './incentiveLibraryService.factory';

export default angular.module('app.incentiveLibrary', [
    'app.core',
    'app.incentiveLibrary.incentiveLibraryList',
])
.config(incentiveLibraryConfig)
.controller('incentiveLibraryCtrl', incentiveLibraryCtrl)
.factory('incentiveLibraryService', incentiveLibraryService);
