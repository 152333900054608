import angular from 'angular';

import '../legacyFrame/legacyFrame.module';

import rewardConfig from './reward.config';
import RewardService from './rewardService.factory';
import rewardComponent from './reward.component';

export default angular.module('app.reward', [
    'app.legacyFrame'
])
.config(rewardConfig)
.factory('RewardService', RewardService)
.component('rewardComponent', rewardComponent);
