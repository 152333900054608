

const constantUtils = require('./constantUtils');

const ContactStatuses = {
    INVITED: 1,
    ACTIVE: 2,
    PASSIVE: 3,
    DELETED: 4
};

const properties = {
    [ContactStatuses.INVITED]: {
        translateKey: 'app_CONTACT_STATUS_INVITED'
    },
    [ContactStatuses.ACTIVE]: {
        translateKey: 'app_CONTACT_STATUS_ACTIVE'
    },
    [ContactStatuses.PASSIVE]: {
        translateKey: 'app_CONTACT_STATUS_PASSIVE'
    },
    [ContactStatuses.DELETED]: {
        translateKey: 'app_CONTACT_STATUS_DELETED'
    }
};

constantUtils.configure({ constants: ContactStatuses, properties });

module.exports = ContactStatuses;
