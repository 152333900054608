import expectedPerformanceWithTargetsWidgetTpl from './expectedPerformanceWithTargetsWidget.tpl.html';
import expectedPerformanceWithTargetsWidgetCtrl from './expectedPerformanceWithTargetsWidget.controller';

export default {
    templateUrl: expectedPerformanceWithTargetsWidgetTpl,
    controller: expectedPerformanceWithTargetsWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
