import reportTpl from './report.tpl.html';
import reportCtrl from './report.controller';

export default {
    templateUrl: reportTpl,
    controller: reportCtrl,
    controllerAs: 'vm',
    bindings: {
        report: '<',
        nonReport: '<',
        gameID: '<',
        defaultReportName: '<',
    }
};
