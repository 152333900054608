import _ from 'lodash';

TabRefreshService.$inject = [
    '$state', '$window', '$timeout', 'UrlUtils', 'AuthStatus', 'StateUtils'
];

export default function TabRefreshService(
    $state, $window, $timeout, UrlUtils, AuthStatus, StateUtils
) {
    /**
     * @param {String} [url]
     *      The browser URL will be changed to this before the page refresh takes place.
     *      If '/' or similar is passed, the user's default state will be used.
     */
    async function refresh(url) {
        if (!url) {
            return $timeout(() => $window.location.reload());
        }
        if (UrlUtils.isUrlAbsolute(url)) {
            return $window.location.href = url;
        }
        url = UrlUtils.formatAngularUrl({ url, desiredPrefix: '/#/' });
        let state;
        if (url === '/#/') {
            state = AuthStatus.isAuthenticated() ? StateUtils.getDefaultState() : { name: 'login', params: {} };
        } else {
            state = StateUtils.getStateFromUrl(url);
        }
        if (!state) {
            $window.location.href = url;
            return $timeout(() => $window.location.reload());
        }
        // Using `$window.location.href = url` wouldn't work well because ui-router would first do an unnecessary
        // state change before the page refreshes. So, `$state.go(..., { notify: false })` is used instead to change
        // the URL without changing the state, and then the refresh takes place.
        await $state.go(state.name, state.params, { notify: false });
        $timeout(() => $window.location.reload());
    }

    return {
        refresh,
    };
}
