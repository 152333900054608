reportsRun.$inject = ['$transitions', '$state'];

export default function reportsRun($transitions, $state) {
    $transitions.onBefore({ to: 'reports' }, function(transition) {
        const params = Object.assign({}, transition.params());
        if (!params.tab) {
            params.tab = 'all';
            return $state.target('reports', params);
        }
    });
}
