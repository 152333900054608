import _ from 'lodash';

/**
 * @param {MultiGridPageOptions} options
 * @returns {string}
 */
export default ({
    tabs,
    state: {
        tabUrlParam
    },
    template: {
        header = '',
        translateHeader = true,
        containerClass = '',
        headerTransclude = '',
        templateAboveTabs = '',
        gridComponentBindings = '',
    }
}) => `<grid-page-container 
        header="${header}"
        translate-header="${translateHeader}"
        class="${containerClass}">

        ${headerTransclude && `<header-transclude>${headerTransclude}</header-transclude>`}

        ${templateAboveTabs}
    
        <ul class="subpage-nav-tabs"
            role="tablist">
            <li ng-repeat="tab in vm.tabs"
                ng-class="{ 'subpage-nav-tab-active': tab === vm.activeTab }"
                class="subpage-nav-tab"
                role="presentation">
                <a ng-click="::vm.changeActiveTab(tab)"
                   translate="{{ ::tab.tabLabelTranslateKey }}"
                   aria-selected="{{ tab === vm.activeTab }}"
                   aria-controls="{{ ::tab.code }}-tab-view"
                   id="{{ ::tab.code }}-tab"
                   class="subpage-nav-tab-link"
                   role="tab">
                </a>
            </li>
        </ul>

    ${tabs.map(tab => {
        if (tab.grid) {
            return `
                <${tab.grid.componentName}
                    ng-if="vm.tabs.${tab.code}.wasSelected"
                    ng-show="vm.activeTab === vm.tabs.${tab.code}"
                    initial-filters="vm.tabs.${tab.code}.grid.initialFilters"
                    initial-columns="vm.tabs.${tab.code}.grid.initialColumns"
                    initial-sort="vm.tabs.${tab.code}.grid.initialSort"
                    update-url-params="{
                        filters: 'filters',
                        columns: 'columns',
                        sort: 'sort'
                    }"
                    ${_.isFunction(gridComponentBindings) ? gridComponentBindings(tab.grid) : gridComponentBindings}
                    aria-labelledby="${tab.code}-tab"
                    id="${tab.code}-tab-view">
                </${tab.grid.componentName}>`;
        }
        return `
            <div ng-if="vm.tabs.${tab.code}.wasSelected"
                 ng-show="vm.activeTab === vm.tabs.${tab.code}"
                 aria-labelledby="${tab.code}-tab"
                 id="${tab.code}-tab-view">
                ${tab.template}
            </div>`;
    }).join('\n')}

    </grid-page-container>`;
