import promoConfirmationToolTpl from './promoConfirmationTool.tpl.html';
import promoConfirmationToolCtrl from './promoConfirmationTool.controller';

export default {
    templateUrl: promoConfirmationToolTpl,
    controller: promoConfirmationToolCtrl,
    controllerAs: 'vm',
    bindings: {
        promo: '<',
        action: '<',
        onCancel: '<',
    }
};
