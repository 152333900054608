import slideshowWidgetTpl from './slideshowWidget.tpl.html';
import slideshowWidgetCtrl from './slideshowWidget.controller';

export default {
    templateUrl: slideshowWidgetTpl,
    controller: slideshowWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
