promoGameCtrl.$inject = ['Session', 'promoDetails', '$window', '$translate'];

export default function promoGameCtrl(Session, promoDetails, $window, $translate) {
    const vm = this;
    const { subDomain, environmentInstance } = Session.getCompany();
    let adminUrl;

    if (!promoDetails) {
        return;
    }

    vm.screen = 'game';
    vm.pageTitle = $translate.instant('promo_GAME_TITLE');
    vm.promoDetails = promoDetails;
    vm.adminFrameClass = 'game-results-rn2';
    if (environmentInstance && ['live', 'uat', 'dr'].includes(environmentInstance.toLowerCase())) {
        adminUrl = `${subDomain}-admin.iziigame.com`;
    } else {
        adminUrl = `${subDomain}-admin.vibe.smg`;
    }
    if (promoDetails.gameID) {
        vm.adminFrameUrl = `https://${adminUrl}/games/${promoDetails.gameID}/results?subview=${true}`;
    } else {
        vm.adminFrameUrl = `https://${adminUrl}/games?subview=${true}`;
    }
    $window.location.href = vm.adminFrameUrl;
}
