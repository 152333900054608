import redemptionDetailsTpl from './redemptionDetails.tpl.html';
import redemptionDetailsCtrl from './redemptionDetails.controller';

export default {
    templateUrl: redemptionDetailsTpl,
    controller: redemptionDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        redemption: '<',
        showAddress: '<',
        onAddressUpdateSuccess: '<',
        onAddressUpdateFail: '<'
    }
};
