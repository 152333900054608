import _ from 'lodash';
import DatafeedGridSetupOptionTypes from '../DatafeedGridSetupOptionTypes';
import formatForCode from '../../../../../../shared/formatForCode';

datafeedGridCtrl.$inject = [
    '$translate', 'DatafeedService', 'PromoQuizService', 'Communication', 'RnDxGridService'
];

export default function datafeedGridCtrl(
    $translate, DatafeedService, PromoQuizService, Communication, RnDxGridService
) {
    this.$onInit = () => {
        const vm = this;

        if (!vm.gridID) {
            vm.gridID = [
                `datafeed-grid-${formatForCode(vm.codeName)}`,
                vm.isHierarchy ? 'hierarchy' : 'contact',
                vm.isFull ? 'full' : 'public'
            ].join('-');
        }

        convertGridSetup(vm.gridSetup);

        vm.apiUrl = DatafeedService.getGridQueryUrl({
            codeName: vm.codeName,
            isHierarchy: vm.isHierarchy,
            isFull: vm.isFull,
            contactID: vm.contactID
        });

        vm.formatQueryResponse = (res) => {
            const { data = [], totalCount = 0 } = res.data || {};
            return {
                // Lowercase every rows' keys to allow for case-insensitive look-ups (for link columns, options columns, etc).
                data: data.map(row => _.mapKeys(row, (value, key) => key.toLowerCase())),
                totalCount
            };
        };

        // -----------------------------------------------------------------------------------------------------------------
        // Converting Grid Setup
        // -----------------------------------------------------------------------------------------------------------------

        /*
            Naming convention to differentiate objects coming from SP and objects created for rn-dx-grid:

            grid_____       =     to be passed to rn-dx-grid
            _____Config     =     from SP JSON

            Ex. gridColumn vs. columnConfig

            (Excluding "gridSetup" which is the whole JSON from the SP)
        */

        /**
         * Uses the gridSetup JSON from the SP to set vm variables to pass to rn-dx-grid.
         * @param {Object} gridSetup
         */
        function convertGridSetup(gridSetup) {
            gridSetup = gridSetup || {};
            setDefaultGridSetupObjects(gridSetup);
            normalizeColumnNames(gridSetup);

            const gridColumns = gridSetup.columns.map((columnConfig, columnConfigIndex) => {
                if (columnConfig.isOptionsColumn) {
                    return convertOptionsColumnConfig(columnConfig, columnConfigIndex);
                }
                const gridColumn = convertDataColumnConfig(columnConfig);
                const linkColumnConfig = gridSetup.linkColumns
                    .find(linkColumnConfig => linkColumnConfig.column === columnConfig.dataField);
                if (linkColumnConfig) {
                    applyLinkColumnConfig(gridColumn, linkColumnConfig);
                }
                return gridColumn;
            });

            const extraDataGridOptions = {};

            // For organization, create the objects first, then set to vm at the end.
            vm.columns = gridColumns;
            vm.extraDataGridOptions = extraDataGridOptions;
        }

        /**
         * Sets default empty objects and arrays to help in preventing undefined errors.
         * @param {Object} gridSetup
         */
        function setDefaultGridSetupObjects(gridSetup) {
            _.defaults(gridSetup, {
                columns: [],
                linkColumns: []
            });
            gridSetup.columns
                .filter(columnConfig => columnConfig.isOptionsColumn)
                .forEach(optionsColumnConfig => {
                    _.defaults(optionsColumnConfig, {
                        options: []
                    });
                    optionsColumnConfig.options.forEach(optionConfig => {
                        _.defaults(optionConfig, {
                            valueColumns: {}
                        });
                    });
                });
            gridSetup.linkColumns.forEach(linkColumnConfig => {
                _.defaults(linkColumnConfig, {
                    valueColumns: {}
                });
            });
        }

        /**
         * Lowercases all properties that reference column dataFields.
         * @param {Object} gridSetup
         */
        function normalizeColumnNames(gridSetup) {
            gridSetup.columns
                .forEach(columnConfig => Object.assign(columnConfig, {
                    dataField: columnConfig.dataField ? columnConfig.dataField.toLowerCase() : undefined,
                    showValueOf: columnConfig.showValueOf ? columnConfig.showValueOf.toLowerCase() : undefined
                }));
            gridSetup.columns
                .filter(columnConfig => columnConfig.isOptionsColumn)
                .forEach(optionsColumnConfig => {
                    optionsColumnConfig.options.forEach(optionConfig => {
                        optionConfig.valueColumns = _.mapValues(optionConfig.valueColumns, (value) => value.toLowerCase());
                    });
                });
            gridSetup.linkColumns
                .forEach(linkColumnConfig => Object.assign(linkColumnConfig, {
                    column: linkColumnConfig.column.toLowerCase(),
                    valueColumns: _.mapValues(linkColumnConfig.valueColumns, (value) => value.toLowerCase())
                }));
        }

        /**
         * For columns that show a cell of data.
         * @param {Object} columnConfig - Column config JSON from grid setup SP
         */
        function convertDataColumnConfig(columnConfig) {
            return {
                dataField: columnConfig.dataField,
                caption: columnConfig.caption,
                visible: (columnConfig.visible || columnConfig.visible == null) && !columnConfig.isHiddenColumn,
                showInColumnChooser: !columnConfig.isHiddenColumn,
                nonDevExOptions: {
                    type: columnConfig.type, // Should match GridColumnTypes
                    filterType: columnConfig.filterType || columnConfig.type, // Should match GridColumnFilterTypes
                    showValueOf: columnConfig.showValueOf,
                    showValueOfListOption: Number(columnConfig.showValueOfListOption) === 1,
                    listOptions: _.isArray(columnConfig.listOptions) ? columnConfig.listOptions.map(listOption => ({
                        name: listOption.name,
                        translateKey: listOption.translateKey,
                        value: listOption.value
                    })) : undefined,
                    trueTranslateKey: columnConfig.trueTranslateKey,
                    falseTranslateKey: columnConfig.falseTranslateKey,
                    truePhrase: columnConfig.truePhrase,
                    falsePhrase: columnConfig.falsePhrase,
                    hideWhenTrue: Number(columnConfig.hideWhenTrue) === 1,
                    hideWhenFalse: columnConfig.hideWhenFalse == null || Number(columnConfig.hideWhenFalse) === 1,
                }
            };
        }

        // Link columns
        // -----------------------------------------------------------------------------------------------------------------

        /**
         * Applies settings for columns that are supposed to be "link" columns.
         * @param {Object} gridColumn
         * @param {DatafeedGridSetupLinkColumnConfig} linkColumnConfig
         */
        function applyLinkColumnConfig(gridColumn, linkColumnConfig) {
            gridColumn.nonDevExOptions.cellTemplate = createLinkTemplate(linkColumnConfig);
        }

        /**
         * @param {DatafeedGridSetupLinkColumnConfig} linkColumnConfig
         * @returns {string}
         */
        function createLinkTemplate(linkColumnConfig) {
            // This function should just delegate to type-specific creator functions.
            switch (linkColumnConfig.optionType) {
                case DatafeedGridSetupOptionTypes.ACCOUNT: {
                    return createLinkTemplateForAccount(linkColumnConfig);
                }
                case DatafeedGridSetupOptionTypes.SNAPSHOT: {
                    return createLinkTemplateForSnapshot(linkColumnConfig);
                }
            }
        }

        /**
         * @param {DatafeedGridSetupLinkColumnConfig} linkColumnConfig
         * @returns {string}
         */
        function createLinkTemplateForAccount(linkColumnConfig) {
            const contactIdColumn = linkColumnConfig.valueColumns.contactID;
            return `
                <a ng-if="cell.data['${contactIdColumn}']"
                ui-sref="account({ contactID: {{ cell.data['${contactIdColumn}'] }} })"
                ng-bind="cell.data['${linkColumnConfig.column}']">
                </a>
                <span ng-if="!cell.data['${contactIdColumn}']"
                    ng-bind="cell.data['${linkColumnConfig.column}']">
                </span>`;
        }

        /**
         * @param {DatafeedGridSetupLinkColumnConfig} linkColumnConfig
         * @returns {string}
         */
        function createLinkTemplateForSnapshot(linkColumnConfig) {
            const contactIdColumn = linkColumnConfig.valueColumns.contactID;
            return `
                <a ng-if="cell.data['${contactIdColumn}']"
                ui-sref="snapshot({ contactID: {{ cell.data['${contactIdColumn}'] }} })"
                ng-bind="cell.data['${linkColumnConfig.column}']">
                </a>
                <span ng-if="!cell.data['${contactIdColumn}']"
                    ng-bind="cell.data['${linkColumnConfig.column}']">
                </span>`;
        }

        // Option buttons
        // -----------------------------------------------------------------------------------------------------------------

        /**
         * @param {Object} columnConfig - Column config JSON from grid setup SP
         * @param {int} columnConfigIndex
         */
        function convertOptionsColumnConfig(columnConfig, columnConfigIndex) {
            const optionsConfigs = columnConfig.options;
            const optionTemplates = [];

            optionsConfigs.forEach((optionConfig, optionConfigIndex) => {
                const optionTemplate = createOptionButtonTemplate(optionConfig, optionConfigIndex, columnConfigIndex);
                if (optionTemplate) {
                    optionTemplates.push(optionTemplate);
                }
            });

            const cellTemplate = optionTemplates.join('');

            const numberOfOptions = columnConfig.options.length;
            // To fit all the icons, add more width for every option past the 2nd one.
            const width = 100 + (40 * (numberOfOptions <= 2 ? 0 : (numberOfOptions - 2)));

            return {
                caption: columnConfig.caption,
                width,
                nonDevExOptions: {
                    isOptionsColumn: true,
                    cellTemplate
                }
            };
        }

        /**
         * @param {DatafeedGridSetupOptionConfig} optionConfig
         * @param {int} optionConfigIndex
         * @param {int} columnConfigIndex
         * @returns {string}
         */
        function createOptionButtonTemplate(optionConfig, optionConfigIndex, columnConfigIndex) {
            // This function should just delegate to type-specific creator functions.
            switch (optionConfig.optionType) {
                case DatafeedGridSetupOptionTypes.ACCOUNT: {
                    return createOptionButtonTemplateForAccount(optionConfig);
                }
                case DatafeedGridSetupOptionTypes.SNAPSHOT: {
                    return createOptionButtonTemplateForSnapshot(optionConfig);
                }
                case DatafeedGridSetupOptionTypes.QUIZ_RESULT_POPUP: {
                    return createOptionButtonTemplateForQuizResultPopup(optionConfig, optionConfigIndex, columnConfigIndex);
                }
            }
        }

        /**
         * @param {DatafeedGridSetupOptionConfig} optionConfig
         * @returns {string}
         */
        function createOptionButtonTemplateForAccount(optionConfig) {
            const contactIdColumn = optionConfig.valueColumns.contactID;
            return `
                <grid-option-button ng-if="cell.data['${contactIdColumn}']"
                                    sref="account({ contactID: {{ cell.data['${contactIdColumn}'] }} })"
                                    text-translate-key="datafeed_OPTION_BUTTON_ACCOUNT"
                                    icon-class="fa fa-briefcase"
                                    button-class="account-button">
                </grid-option-button>`;
        }

        /**
         * @param {DatafeedGridSetupOptionConfig} optionConfig
         * @returns {string}
         */
        function createOptionButtonTemplateForSnapshot(optionConfig) {
            const contactIdColumn = optionConfig.valueColumns.contactID;
            return `
                <grid-option-button ng-if="cell.data['${contactIdColumn}']"
                                    sref="snapshot({ contactID: {{ cell.data['${contactIdColumn}'] }} })"
                                    text-translate-key="datafeed_OPTION_BUTTON_SNAPSHOT"
                                    icon-class="fa fa-user-o"
                                    button-class="account-button">
                </grid-option-button>`;
        }


        /**
         * @param {DatafeedGridSetupOptionConfig} optionConfig
         * @param {int} optionConfigIndex
         * @param {int} columnConfigIndex
         * @returns {string}
         */
        function createOptionButtonTemplateForQuizResultPopup(optionConfig, optionConfigIndex, columnConfigIndex) {
            const incentiveIdColumn = optionConfig.valueColumns.incentiveID;
            const quizResultIdColumn = optionConfig.valueColumns.quizResultID;
            const openPopup = async (cell) => {
                const quizResultID = cell.data[quizResultIdColumn];
                const promoDetails = await PromoQuizService.getQuizResults({ quizResultID });
                Communication.openIncentive(promoDetails)
                    .result
                    .finally(() => RnDxGridService.reloadRows({
                        gridID: vm.gridID,
                        dataField: quizResultIdColumn,
                        dataFieldValue: quizResultID
                    }));
            };
            const openPopupExpression = setOptionColumnFunction(openPopup, columnConfigIndex, optionConfigIndex);
            return `
                <grid-option-button ng-if="cell.data['${incentiveIdColumn}'] && cell.data['${quizResultIdColumn}']"
                                    on-click="${openPopupExpression}(cell)"
                                    text-translate-key="datafeed_OPTION_BUTTON_QUIZ_RESULT_POPUP"
                                    icon-class="fa fa-file-text"
                                    button-class="quiz-result-popup-button">
                </grid-option-button>`;
        }

        /**
         * When a new vm function needs to be set in order to be called by an option column (Ex. a click handler
         * for an option button) pass the function here instead of manually setting it on vm yourself.
         *
         * This way we can centralize in one place where/how the functions are set onto vm.
         *
         * @param {Function} func
         * @param {int} columnConfigIndex - Index of the options column in the columns array
         * @param {int} optionConfigIndex - Index of the one option in the array of all options for the column
         * @returns {string} - An Angular expression string you can use to call your new function from an html template
         */
        function setOptionColumnFunction(func, columnConfigIndex, optionConfigIndex) {
            _.defaultsDeep(vm, {
                optionsColumnsFunctions: {
                    [columnConfigIndex]: {}
                }
            });
            vm.optionsColumnsFunctions[columnConfigIndex][optionConfigIndex] = func;
            return `vm.optionsColumnsFunctions[${columnConfigIndex}][${optionConfigIndex}]`;
        }

        /**
         * @typedef {Object} DatafeedGridSetupOptionConfig
         * @property {string} optionType - See DatafeedGridSetupOptionTypes.js
         * @property {Object} valueColumns - Tells which column data to use in creating a link
         */

        /**
         * @typedef {Object} DatafeedGridSetupLinkColumnConfig
         * @property {string} column - Name of the column to where the rows should be links
         * @property {string} optionType - See DatafeedGridSetupOptionTypes.js
         * @property {Object} valueColumns - Tells which column data to use in creating a link
         */
    };
}
