

const constantUtils = require('./constantUtils');

const PointExpirationNotificationPeriods = {
    MONTHLY: 'Monthly',
    SEVEN_DAYS: '7Days',
    SIXTY_DAYS: '60Days'
};
const properties = {};

constantUtils.configure({ constants: PointExpirationNotificationPeriods, properties });

module.exports = PointExpirationNotificationPeriods;
