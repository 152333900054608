socialPostPreviewCtrl.$inject = ['$uibModalInstance', 'Session', 'socialPostModel', 'mediaFile', 'submitSocialPost'];

export default function socialPostPreviewCtrl($uibModalInstance, Session, socialPostModel, mediaFile, submitSocialPost) {
    const vm = this;

    vm.user = Session.getUser();

    vm.socialPostModel = socialPostModel;
    vm.mediaFile = mediaFile;

    vm.previewSocialPost = {
        fromContact: {
            userID: vm.user.userID,
            name: `${vm.user.firstName} ${vm.user.lastName}`
        },
        category: vm.socialPostModel.subject,
        messageHTML: vm.socialPostModel.messageBody
    };

    vm.submit = function() {
        // data is passed to the SP by the parent (socialPostForm.controller)
        vm.disableSubmit = true;
        return submitSocialPost()
            .then(function() {
                return $uibModalInstance.close();
            })
            .catch(function(err) {
                vm.disableSubmit = false;
                throw err;
            });
    };

    vm.cancel = function() {
        return $uibModalInstance.dismiss();
    };
}
