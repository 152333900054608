import angular from 'angular';

import '../../../../blocks/storage/storage.module'; // Session

import certificateDetailsCtrl from './certificateDetails.controller';
import certificateDetails from './certificateDetails.component';

export default angular.module('app.communication.certificate.certificateDetails', [
    'app.blocks.storage'
])
.controller('certificateDetailsCtrl', certificateDetailsCtrl)
.component('certificateDetails', certificateDetails);
