import applausesSentWidgetTpl from './applausesSentWidget.tpl.html';
import applausesSentWidgetCtrl from './applausesSentWidget.controller';

export default {
    templateUrl: applausesSentWidgetTpl,
    controller: applausesSentWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
