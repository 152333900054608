

const constantUtils = require('./constantUtils');

const GameStatuses = {
    NEW_GAME: 1,
    DELIVERED: 2,
    IN_PROGRESS: 3,
    PLAYED: 4,
    CANCELLED: 5,
    EXPIRED: 6,
};

const properties = {
};

constantUtils.configure({ constants: GameStatuses, properties });

module.exports = GameStatuses;
