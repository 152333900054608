import _ from 'lodash';

legacyFramePageCtrl.$inject = ['$state', 'StateUtils', 'UrlUtils'];

export default function legacyFramePageCtrl($state, StateUtils, UrlUtils) {
    const vm = this;

    const { urlPath, paramPath } = $state.params;
    const path = urlPath ? UrlUtils.getEverythingAfterPath('/legacy-frame') : paramPath;
    if (!path) {
        console.error('No path provided to legacy-frame page');
        const defaultState = StateUtils.getDefaultState();
        return $state.go(defaultState.name, defaultState.params);
    }

    const pathWithSlash = _.startsWith(path, '/') ? path : ('/' + path);
    const pathWithoutSlash = _.startsWith(path, '/') ? path.substring(1) : path;

    vm.path = pathWithSlash;

    if (paramPath) {
        // This updates the browser URL to put paramPath after /#/legacy-frame.
        // This is needed because paramPath isn't part of the URL.
        // Use $state.go instead of $location.url because $location.url will trigger a transition
        // which we don't want.
        $state.go('.', { urlPath: pathWithoutSlash }, {
            notify: false,
            reload: false,
            location: 'replace',
            inherit: true
        });
    }
}

/*

$state.params.urlPath is used:

    When user was given a URL to go to or a link to click on.
    Ex. http://incentives.rewardsnation.com/#/legacy-frame/MyApplauses.asp?IsFrame=1&ContactID=150124

    Also when using ui-sref.
    Ex. ui-sref="legacyFramePage({ urlPath: 'MyApplauses.asp?IsFrame=1&ContactID=' + vm.contact.userID })"

$state.params.paramPath is used:

    When programmatically going to the legacyFramePage state.
    Ex. $state.go('legacyFramePage', { paramPath: 'Rewards.asp?foo=bar' })

 */
