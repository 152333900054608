import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class AcceptTermsTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$http = $injector.get('$http');

        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_ACCEPT_TERMS_TITLE', // 'Accept Terms and Conditions'
            acceptButtonText: 'app_SIGN_ON_TASKS_ACCEPT_TERMS_ACCEPT_BUTTON_TEXT', // 'I accept'
            cancelButtonText: 'app_SIGN_ON_TASKS_ACCEPT_TERMS_CANCEL_BUTTON_TEXT', // 'Decline'
            successMessage: 'app_SIGN_ON_TASKS_ACCEPT_TERMS_SUCCESS_MESSAGE', // 'Thank you!'
            taskModalOptions: {
                successMessageTimeout: 1400
            }
        });
    }

    onSubmit() {
        return this.$http.put('/api/user/terms-accepted', {
            accept: true
        });
    }
}
