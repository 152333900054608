import _ from "lodash";

targetProgressWidgetCtrl.$inject = ["StringInterpolationService"];

export default function targetProgressWidgetCtrl(StringInterpolationService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        _.defaults(vm, {
            stats: vm.widget.data.stats || [],
            subStats: vm.widget.data.subStats || []
        });

        const widgetTargets = _.get(vm.widget.data, 'widgetTargets');
        const lastYearTargetName = _.get(vm.widget.data, 'lastYearTargetName');
        const progressText = _.get(vm.widget.data, 'progressText');
        const lowerThanLastYearProgressText = _.get(vm.widget.data, 'lowerThanLastYearProgressText');
        const noProgressText = _.get(vm.widget.data, 'noProgressText');
        vm.pointTargets = _.get(vm.widget.data, 'widgetTargets.pointTargets');
        vm.highestPointTarget = 0;
        

        vm.hasData = !_.isEmpty(vm.pointTargets) && _.isArray(vm.pointTargets);

        if(vm.hasData) {
            let lastYearTargetValue;
            let currentTarget;

            if (lastYearTargetName) {
                const lastYearTarget = vm.pointTargets
                    .find(pointTarget => pointTarget.targetName === lastYearTargetName );
                if(lastYearTarget) {
                    lastYearTargetValue = lastYearTarget.targetValue;
                }
            }

            const pointTargetsMet = vm.pointTargets
                                        .filter(pointTarget => widgetTargets.standingValue >= pointTarget.targetValue);
            if (_.isArray(pointTargetsMet) && pointTargetsMet.length >= 1) {
                currentTarget = pointTargetsMet[pointTargetsMet.length - 1];
                vm.highestPointTarget = pointTargetsMet.length - 1;
                vm.pointTargets.filter(pointTarget => widgetTargets.standingValue >= pointTarget.targetValue)
                    .forEach(pointTarget => {
                    pointTarget.isActive = true;
                });
            } else {
                currentTarget = vm.pointTargets[0];
                vm.pointTargets[0].isActive = true;
            }
            

            if (!lastYearTargetValue && vm.pointTargets[0].targetValue > widgetTargets.standingValue) {
                vm.footerText = StringInterpolationService.interpolate(noProgressText, {
                    currentTargetName: currentTarget.targetName,
                    standingValue: widgetTargets.standingValue
                });
            } else if (!lastYearTargetValue || widgetTargets.standingValue >= lastYearTargetValue ) {
                vm.footerText = StringInterpolationService.interpolate(progressText, {
                    currentTargetName: currentTarget.targetName,
                    standingValue: widgetTargets.standingValue
                });
            }

            if (lastYearTargetValue && widgetTargets.standingValue < lastYearTargetValue) {
                vm.footerText = StringInterpolationService.interpolate(lowerThanLastYearProgressText, {
                    currentTargetName: currentTarget.targetName,
                    standingValue: widgetTargets.standingValue,
                    LastYearTargetName: lastYearTargetName,
                    pointsToLastYearTarget: lastYearTargetValue - widgetTargets.standingValue,
                });
            }
        }
    }
}
