import angular from 'angular';

import widgetHeaderModule from './widgetHeader/widgetHeader.module';
import widgetStatsModule from './widgetStats/widgetStats.module';
import widgetNoDataBodyModule from './widgetNoDataBody/widgetNoDataBody.module';
import widgetFooterModule from './widgetFooter/widgetFooter.module';
import widgetFootNoteModule from './widgetFootNote/widgetFootNote.module';
import widgetCacheFooterModule from './widgetCacheFooter/widgetCacheFooter.module';

export default angular.module('app.core.widgets.shared', [
    widgetHeaderModule.name,
    widgetStatsModule.name,
    widgetNoDataBodyModule.name,
    widgetFooterModule.name,
    widgetFootNoteModule.name,
    widgetCacheFooterModule.name,
]);
