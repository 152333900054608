import angular from 'angular';

import rnForm from './rnForm.directive';
import rnFormSubmitButton from './rnFormSubmitButton/rnFormSubmitButton.directive';

export default angular.module('app.core.directives.rnForm', [

])
.directive('rnForm', rnForm)
.directive('rnFormSubmitButton', rnFormSubmitButton);
