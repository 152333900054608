import angular from 'angular';

import '../submissionsGrid/submissionsGrid.module';

import submissionsHierarchyPageConfig from './submissionsHierarchyPage.config';

export default angular.module('app.submissions.hierarchyPage', [
    'app.submissions.submissionsGrid'
])
.config(submissionsHierarchyPageConfig);
