import angular from 'angular';

import '../../../../core/core.module';

import incentiveCard from './incentiveCard.component';
import incentiveCardService from './incentiveCardService.factory';

export default angular.module('app.incentiveLibraryList.incentiveCard', [
    'app.core'
])
.factory('incentiveCardService', incentiveCardService)
.component('incentiveCard', incentiveCard);
