legacyWidgetCtrl.$inject = ['$element'];

export default function legacyWidgetCtrl($element) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.iframeSrc = getLegacyWidgetIframeSrc();
        vm.loading = true;

        const $legacyWidgetContainer = $element;
        $legacyWidgetContainer.addClass('widget-loading');

        vm.onLoad = function() {
            vm.loading = false;
            $legacyWidgetContainer.removeClass('widget-loading');
        };

        function getLegacyWidgetIframeSrc() {
            return `/api/snapshot/${vm.contact.userID}/widgets/${vm.widget.code}/${vm.widget.widgetsAvailableID}?legacy=1`;
        }
    };
}
