ReportColumnService.$inject = [];

export default function ReportColumnService() {
    
    const DEFAULT_PARAM_NAME = 'column';
    const COLUMN_SEPARATOR = '__';
    const VISIBLE_SYMBOL = 'show';
    const HIDDEN_SYMBOL = 'hide';

    return {
        encode,
        decode,
    };

    function encode(columns, selectedColumns) {
        const columnChanges = [];
            columns.filter(column => column.showInColumnChooser !== false).forEach((column, index) => {
            if(column.visible !== selectedColumns[index].visible) {
                columnChanges.push(selectedColumns[index]);
            }
        });
        const columnStr = columnChanges
            .map(({dataField, visible}) => (visible ? VISIBLE_SYMBOL : HIDDEN_SYMBOL) + '--' + dataField)
            .join(COLUMN_SEPARATOR);
        return columnStr;
    }

    function decode(columns, columnStr) {
        if (!columnStr) {
            return columns;
        }
        columnStr.split(COLUMN_SEPARATOR)
            .map(filter => filter.split('--'))
            .filter(filter => filter.length === 2)
            .forEach(filter => {
                const targetedColumn = columns.filter(column => column.showInColumnChooser !== false)
                                                .find(column => column.dataField === filter[1]);
                if(filter[0] === VISIBLE_SYMBOL) {
                    targetedColumn.visible = true;
                }
                if(filter[0] === HIDDEN_SYMBOL) {
                    targetedColumn.visible = false;
                }
            });
            return columns;
    }
}
