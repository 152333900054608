

const constantUtils = require('./constantUtils');

const ModalTypes = {
    QUESTION_OVERVIEW: {
        Type: 'question-overview',
        rowKeyName: 'QuestionID',
        dataType: 'NUMBER',
    },
    GAMEPLAY_RESULTS: {
        Type: 'gameplay-results',
        rowKeyName: 'GamePlayGUID',
        dataType: 'STRING',
    },
    TRAINING_QUIZ_RESULTS: {
        Type: 'training-quiz-results',
        rowKeyName: 'QuizResultID',
        dataType: 'NUMBER',
    },
    CLAIM_QUIZ_RESULTS: {
        Type: 'claim-quiz-results',
        rowKeyName: 'QuizResultID',
        dataType: 'NUMBER',
    }
};

const properties = {
};

constantUtils.configure({ constants: ModalTypes, properties });

module.exports = ModalTypes;
