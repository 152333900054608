import $ from 'jquery';

ContentService.$inject = ['$http', '$filter'];

export default function ContentService($http, $filter) {

    const CSS_SELECTOR_REGEX = /(^[\w\s.#>,~:*-]+\s*{(?:.|\s)*?})/gm;

    async function getContentByCode(contentURL, permissionGroup) {
        try {
            const res = await $http.get(`/api/content/code/${contentURL}`, {
                params: {
                    perm: permissionGroup
                }
            });
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return;
            }
            throw err;
        }
    }

    async function getContentByID(contentID) {
        try {
            const res = await $http.get(`/api/content/id/${contentID}`);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return;
            }
            throw err;
        }
    }

    /**
     * Formats user-created content html into a more desirable form (for rendering to DOM, in a popup, in a state, etc).
     * Puts all user html into a div with a unique CSS class name.
     * Then prepends all user CSS selectors with the unique CSS class name, preventing collisions with application CSS
     * as well as with other custom content shown at the same time. Ex. sign on task popup on home page.
     *
     * @param {String} contentRawHtml
     * @param {String} contentUrl
     */
    function formatContentHtmlForDisplay(contentRawHtml, contentUrl = Math.floor(Math.random() * 10000).toString()) {
        // Convert to jquery-usable object: http://stackoverflow.com/a/12808939
        const parsedContent = $('<div />').append(contentRawHtml);

        // Suffix the class name with the contentUrl
        const customCssClassName = 'custom-content-' + $filter('formatForCode')(contentUrl);

        // Combine all user-written CSS in any style tags into one string and add custom css class to all selectors
        const formattedContentCss = parsedContent.find('style').toArray()
            .map(styleTag => styleTag.innerHTML.replace('<!--', '').replace('-->', ''))
            .reduce((allCss, tagCss) => allCss + tagCss, '')
            .replace(CSS_SELECTOR_REGEX, function(selector) {
                // Ex. "h1, h2, p" --> ".custom-content-splash1 h1, .custom-content-splash1 h2, .custom-content-splash1 p"
                return selector
                    .split(', ')
                    .map(subSelector => `.${customCssClassName} ${subSelector}`)
                    .join(', ');
            });

        // Remove all <style> tags from content html
        parsedContent.find('style').remove();

        const formattedContentHtml = parsedContent.html();
        return `
            <!-- CUSTOM CONTENT FORMATTED FOR DISPLAY -->
            ${formattedContentCss && `<style>${formattedContentCss}</style>`}
            <div class="flex-fill-height content-html-container ${customCssClassName}">
                ${formattedContentHtml}
            </div>
        `;
    }

    return {
        getContentByCode,
        getContentByID,
        formatContentHtmlForDisplay,
    };
}
