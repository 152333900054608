import trainingQuizResultsTpl from './trainingQuizResults.tpl.html';
import trainingQuizResultCtrl from './trainingQuizResults.controller';

export default {
    templateUrl: trainingQuizResultsTpl,
    controller: trainingQuizResultCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
    }
};
