import pointExpiryTpl from './pointExpiry.tpl.html';
import pointExpiryCtrl from './pointExpiry.controller';

export default {
    templateUrl: pointExpiryTpl,
    controller: pointExpiryCtrl,
    controllerAs: 'vm',
    bindings: {
        pointExpirationDetails: '<',
        contactId: '<'
    }
};
