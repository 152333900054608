leaderboardWidgetCtrl.$inject = [];

export default function leaderboardWidgetCtrl() {
    const controller = this;

    controller.$onInit = () => {
        // Passed passed from component bindings:
        // controller.leaderboard
        // controller.contact
        // controller.rankings
        // controller.size - optional

        if (controller.rankings.length > 1) {
            controller.rankings = controller.rankings.map((ranking, index) => {
                if (ranking.numberSharingRank > 1 && index >= 1 && controller.rankings[index - 1].rank === ranking.rank) {
                    ranking.isOtherTied = true;
                    ranking.numberSharingRank -= 1;
                }
                return ranking;
            });
        }
    };
}
