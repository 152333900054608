import angular from 'angular';

import '../../core/core.module';
import '../communication/communication.module';
import './preview/preview.module';
import './postSubmit/postSubmit.module';
import './formly/formly.module';

import applauseFormPageConfig from './applauseFormPage.config';
import applauseCtrl from './applause.controller';
import ApplauseService from './applauseService.factory';
import carouselResponsiveHeight from './carouselResponsiveHeight.directive';

export default angular.module('app.applause', [
    'app.core',
    'app.communication',
    'app.applause.formly',
    'app.applause.preview',
    'app.applause.postSubmit',
])
.config(applauseFormPageConfig)
.controller('applauseCtrl', applauseCtrl)
.directive('carouselResponsiveHeight', carouselResponsiveHeight)
.factory('ApplauseService', ApplauseService);
