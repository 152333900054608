import _ from 'lodash';

trainingQuizResultsCtrl.$inject = [];

export default function trainingQuizResultsCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.promoDetails

        const firstSectionQuestions = _.get(vm.promoDetails, 'quiz.sections[0].questions');
        if (_.isEmpty(firstSectionQuestions)) {
            throw new Error('Invalid quiz questions');
        }

        const firstSectionAnswers = _.get(vm.promoDetails, 'results.sections[0].answers');
        if (_.isEmpty(firstSectionAnswers)) {
            throw new Error('Invalid quiz answers');
        }

        const quizResults = vm.promoDetails.results;

        vm.isPassed = quizResults.isPassed;
        vm.score = quizResults.score;
        vm.maxScore = quizResults.maxScore;
        vm.quizResultID = quizResults.quizResultID;

        vm.questionsWithResults = firstSectionQuestions.map(question => {
            const answer = firstSectionAnswers.find(answer => answer.quizQuestionID === question.ID);
            const correctAnswer = answer.multiAnswer.find(multiAnswer => multiAnswer.isCorrectAnswer);
            return _.merge({}, question, {
                isAnswerCorrect: answer.isCorrect,
                isOpen: vm.isPassed && !answer.isCorrect,
                candidateAnswer: answer.candidateAnswer,
                explanation: answer.explanation,
                correctAnswerID: vm.isPassed && correctAnswer ? correctAnswer.quizQuestionMultiAnswerID : null
            });
        });
    };
}
