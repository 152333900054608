import articleTpl from './article.tpl.html';
import articleCtrl from './article.controller';

export default {
    templateUrl: articleTpl,
    controller: articleCtrl,
    controllerAs: 'vm',
    bindings: {
        content: '<'
    }
};
