import quizResultStatusLabelTpl from './quizResultStatusLabel.tpl.html';
import quizResultStatusLabelCtrl from './quizResultStatusLabel.controller';

export default {
    templateUrl: quizResultStatusLabelTpl,
    controller: quizResultStatusLabelCtrl,
    controllerAs: 'vm',
    bindings: {
        quizResultStatusID: '<quizResultStatusId',
        isApprover: '<',
        quizTakerID: '<quizTakerId',
        points: '<'
    }
};
