import legacyWidgetTpl from './legacyWidget.tpl.html';

export default {
    templateUrl: legacyWidgetTpl,
    controller: 'legacyWidgetCtrl as vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
