promoQuizDeclineCtrl.$inject = ['PromoQuizService'];

export default function promoQuizDeclineCtrl(PromoQuizService) {
    this.$onInit = () => {
        const vm = this;

        // TODO: find way to un-duplicate the code below with promoQuizSection.controller.js

        const sectionID = vm.section.sectionID;
        const quizResultID = vm.promoDetails.results.quizResultID;

        vm.sectionResults = PromoQuizService.getSectionResults(vm.promoDetails, vm.section);

        vm.noteModel = {};

        vm.noteFields = [{
            key: 'note',
            type: 'customTextarea',
            templateOptions: {
                required: true,
                maxlength: 500,
                placeholderTranslateKey: 'app_PROMO_QUIZ_SECTION_DECLINE_ADD_NOTE_PLACEHOLDER', // 'Add a note'
                controlClass: 'promo-submission-note-field',
                resetAfterSubmit: true
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_DECLINE', // Decline
                    class: 'btn btn-primary',
                    iconClass: '',
                    isDisabled: false
                },
                send: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_DECLINE', // Decline
                    iconClass: 'fa-spinner fa-pulse',
                    isDisabled: true,
                    debounce: 300
                },
                success: {
                    noReset: true,
                },
            },
        };

        vm.submit = function() {
            return PromoQuizService.declineSubmission({
                sectionID,
                quizResultID,
                note: vm.noteModel.note
            });
        };
    };
}
