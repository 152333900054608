import unsuppressEmailTpl from '../dashboardModal/unsuppressEmail/unsuppressEmail.tpl.html'; 

import CustomStore from 'devextreme/data/custom_store';
import _ from 'lodash';
import moment from 'moment';

reportCtrl.$inject = [
    '$stateParams', '$state', '$http', '$q', 'LangUtils', '$translate', 'GridFilterOperators', 'Session',
    'ReportColumnChooserService', 'ReportService', 'ReportFilterService', 'ReportColumnService', 'ReportSortService',
    'ReportFormModal', '$uibModal', '$scope','$window'
];

export default function reportCtrl(
    $stateParams, $state, $http, $q, LangUtils, $translate, GridFilterOperators, Session,
    ReportColumnChooserService, ReportService, ReportFilterService, ReportColumnService, ReportSortService,
    ReportFormModal, $uibModal, $scope, $window
) {
    const vm = this;

       
    $window.unsuppressEmail = unsuppressEmail; // make function available outside current scope

    this.$onInit = () => {
        const singleSortContextMenu = [
            { text: 'Filter', icon: 'filter' },
            { text: 'Sort Ascending', icon: 'sortup' },
            { text: 'Sort Descending', icon: 'sortdown' }
        ];

        const multiSortContextMenu = [
            { text: 'Filter', icon: 'filter' },
            { text: 'Sort Ascending', icon: 'sortup' },
            { text: 'Sort Descending', icon: 'sortdown' },
            { template: 'multi-sort-asc' },
            { template: 'multi-sort-desc' }
        ];

        vm.filters = [];
        vm.sortSettings = [];
        vm.selectedColumns = [];
        vm.saveCustomFilterVisible = false;
        vm.user = Session.getUser();

        vm.popupOptions = {
            width: 300,
            height: 75,
            contentTemplate: 'saveCustomFilterSet',
            showTitle: false,
            dragEnabled: false,
            closeOnOutsideClick: true,
            shading: false,
            position: {
                my: 'left',
                at: 'bottom',
                of: '.save-custom-filter-set-button',
            },
            bindingOptions: {
                visible: 'vm.saveCustomFilterVisible',
            }
        };

        vm.contextMenuOptions = {
            dataSource: singleSortContextMenu,
            width: 200,
            target: '.dx-header-row',
            showEvent: 'dxclick',
            onInitialized: function(e) {
                vm.contextMenuInstance = e.component;
            },
            onShowing: function(e) {
                if (!vm.columnOnClickByContextMenu || vm.columnOnClickByContextMenu.hideFromResponse) {
                    e.cancel = true;
                }
            },
            onItemClick: function(e) {
                if (!vm.columnOnClickByContextMenu || vm.columnOnClickByContextMenu.hideFromResponse) {
                    return;
                }
                // open filter
                if (e.itemIndex === 0) {
                    const filterIndex = vm.filters.findIndex(filter => filter.field === vm.columnOnClickByContextMenu.dataField);
                    if (filterIndex === -1) {
                        openEditFilterModal({
                            field: vm.columnOnClickByContextMenu.dataField
                        }, vm.filters.length);
                    } else {
                        const filter = vm.filters.find(filter => filter.field === vm.columnOnClickByContextMenu.dataField);
                        openEditFilterModal(filter, filterIndex);
                    }
                    return;
                }

                let isDesc;
                const sortIndex = vm.sortSettings.findIndex(sortSetting => sortSetting.field === vm.columnOnClickByContextMenu.dataField);
                switch (e.itemIndex) {
                    case 1:
                    case 3:
                        isDesc = false;
                        break;
                    case 2:
                    case 4:
                        isDesc = true;
                        break;
                }
                let newSortSettings = [];
                switch (e.itemIndex) {
                    case 1:
                    case 2:
                        newSortSettings = [{
                            field: vm.columnOnClickByContextMenu.dataField,
                            desc: isDesc,
                        }];
                        break;
                    case 3:
                    case 4:
                        newSortSettings = _.cloneDeep(vm.sortSettings);

                        if (sortIndex === -1) {
                            newSortSettings.push({
                                field: vm.columnOnClickByContextMenu.dataField,
                                desc: isDesc,
                            });
                        } else {
                            const currentSortSetting = newSortSettings.find(sortSetting => sortSetting.field === vm.columnOnClickByContextMenu.dataField);

                            if (currentSortSetting.desc !== isDesc) {
                                newSortSettings[sortIndex].desc = isDesc;
                            }
                        }
                        break;
                }

                $state.go($state.current, {
                    filter: $stateParams.filter,
                    column: $stateParams.column,
                    sort: ReportSortService.encode(newSortSettings),
                });
            }
        };

        if (vm.report && vm.report.legacyURL) {
            vm.report.legacyURL = vm.report.legacyURL.replace(/\{\{(.*?)\}\}/g, (test) => {
                const arg = test.replace(/[\{\}\s]/g, '');
                const data = _.merge({}, $stateParams);
                return data[arg] ? data[arg] : '';
            });
            return;
        }

        // vm.report.filters is the list of default filter sets
        // vm.user.savedFilterSets is the list of user's own custom filter sets
        if (_.isArray(vm.user.savedFilterSets)) {
            const userFilterSets = vm.user.savedFilterSets.find(reportFilterSet => reportFilterSet.reportCode === vm.report.code);
            if (userFilterSets && _.isArray(userFilterSets.filterSets)) {
                vm.filterSets = vm.report.filters.concat(userFilterSets.filterSets);
            } else {
                vm.filterSets = vm.report.filters;
            }

            if (userFilterSets && userFilterSets.pinnedCode) {
                vm.pinnedFilterSetCode = userFilterSets.pinnedCode;
                const filterSet = vm.filterSets.find(filterSet => filterSet.code === userFilterSets.pinnedCode);
                if (filterSet) {
                    $state.go($state.current, {
                        filter: filterSet.filter,
                        column: filterSet.column,
                        sort: filterSet.sort,
                    });
                    determineFilterSet(filterSet);
                } else {
                    determineFilterSet($stateParams);
                }
            } else if (_.isArray(vm.filterSets)) {
                vm.pinnedFilterSetCode = vm.filterSets[0].code;
                determineFilterSet($stateParams);
            }
        } else {
            vm.filterSets = vm.report.filters;
            if (_.isArray(vm.filterSets)) {
                vm.pinnedFilterSetCode = vm.filterSets[0].code;
                determineFilterSet($stateParams);
            }
        }

        if (_.get(vm, 'report.setup')) {
            vm.columns = vm.report.setup.columns;
            vm.filterSets.forEach(filterSet => {
                if (filterSet.translateKey) {
                    filterSet.name = $translate.instant(filterSet.translateKey);
                }
            });

            if (vm.report.overrideSetup) {
                _.merge(vm.report.setup, vm.report.overrideSetup);
            }

            vm.report.setup.columns.forEach(column => {
                if (column.captionTranslateKey) {
                    column.caption = $translate.instant(column.captionTranslateKey);
                }
                if (column.customDateFormat) {
                    column.format = function (dateString) {
                        return moment(dateString).format(column.customDateFormat);
                    };
                }
            });

            const identifierFields = vm.report.setup.identifierFields;
            vm.defaultFilters = [];
            identifierFields.forEach(field => {
                const isGameField = vm.gameID && field === 'GameID';
                vm.defaultFilters.push({ field: field, operator: 'eq', value: isGameField ? vm.gameID : $stateParams.dashboardID });
            });
            vm.defaultFilters.push({ field: 'languageID', operator: 'eq', value: LangUtils.getLanguageID() });
            vm.report.setup.dataSource = new CustomStore({
                load: function(loadOptions) {
                    ReportService.setParams({ loadOptions });
                    updateSort($stateParams.sort);
                    updateFilter($stateParams.filter);
                    updateColumn($stateParams.column, vm.gridInstance);
                    return ReportService.getGridData({
                        stateParams: $stateParams,
                        stateData: $state.current.data,
                        loadOptions
                    }).then(function (response) {
                        return response.data;
                    }, function (response) {
                        console.error(response);
                        return $q.reject('Data Loading Error');
                    });
                }
            });

            vm.report.setup.onCellPrepared = (e) => {
                if (e.rowType === 'data' && e.column.modifier) {
                    let template = e.value;
                    const column = e.column;
                    const rowData = e.data;
                    const data = _.merge({}, rowData, $stateParams, {
                        filterSet: ReportFilterService.encodeFilterSet($stateParams)
                    });
                    switch (column.modifier.name) {
                        case 'template':
                            template = column
                                .modifier
                                .template
                                .replace(/\{\{(.*?)\}\}/g, (test) => {
                                    const arg = test.replace(/[\{\}\s]/g, '');
                                    return data[arg] ? data[arg] : '';
                                });
                            break;
                        case 'replace':
                            break;
                        case 'positiveNegative':
                            if (column.modifier.positiveTemplate && column.modifier.negativeTemplate) {
                                if (e.value > column.modifier.zeroValue) {
                                    template = column.modifier.positiveTemplate.replace(/\{\{(.*?)\}\}/g, (test) => {
                                        const arg = test.replace(/[\{\}\s]/g, '');
                                        return data[arg] ? data[arg] : '';
                                    });
                                } else {
                                    template = column.modifier.negativeTemplate.replace(/\{\{(.*?)\}\}/g, (test) => {
                                        const arg = test.replace(/[\{\}\s]/g, '');
                                        return data[arg] ? data[arg] : '';
                                    });
                                }
                            }
                            break;
                        case 'checkbox':
                            const cellValue = e.value;
                            const cellOption = column.reportListOptions.find(option => option.value === cellValue);
                            if (!cellOption) {
                                return '';
                            }
                            const phrase = $translate.instant(cellOption.translateKey);
                            template = cellOption.template.replace(/\{\{(.*?)\}\}/g, (test) => {
                                const arg = test.replace(/[\{\}\s]/g, '');
                                if (arg === 'phrase') {
                                    return phrase;
                                }
                                return data[arg] ? data[arg] : '';
                            });
                            break;
                    }
                    e.cellElement.html(template);
                }
            };

            vm.report.setup.onInitialized = (e) => {
                vm.gridInstance = e.component;
            };

            vm.report.setup.onContentReady = (e) => {
                vm.isLoaded = true;
            };

            // works with contextmenu to figure out which column is being worked on
            vm.report.setup.onCellClick = (e) => {
                if (e.rowType === 'header') {
                    vm.columnOnClickByContextMenu = e.column;
                    if (!$stateParams.sort) {
                        vm.contextMenuInstance.option('dataSource', singleSortContextMenu);
                    } else if ($stateParams.sort.includes(e.column.dataField)) { // TODO: allow a sorted column to show multisort menu
                        vm.contextMenuInstance.option('dataSource', singleSortContextMenu);
                    } else {
                        vm.contextMenuInstance.option('dataSource', multiSortContextMenu);
                    }
                }
            };
        }
    };


    vm.uiOnParamsChanged = uiOnParamsChanged;
    vm.reloadGrid = reloadGrid;
    vm.openColumnChooser = openColumnChooser;
    vm.openAddFilterModal = openAddFilterModal;
    vm.openEditFilterModal = openEditFilterModal;
    vm.removeFilter = removeFilter;
    vm.getFilterDisplayText = getFilterDisplayText;
    vm.selectFilterSet = selectFilterSet;
    vm.saveCustomFilterSet = saveCustomFilterSet;
    vm.deleteFilterSet = deleteFilterSet;
    vm.pinFilterSet = pinFilterSet;
    vm.createReport = createReport;

    async function uiOnParamsChanged(newParams) {
        if (vm.gridInstance) {
            const isFilterChanged = newParams.filter !== undefined
                && !_.isEqual(ReportFilterService.encode(vm.filters), newParams.filter);
            const isColumnChanged = newParams.column !== undefined
                && !_.isEqual(ReportColumnService.encode(vm.columns, vm.selectedColumns), newParams.column);
            const isSortChanged = newParams.sort !== undefined
                && !_.isEqual(ReportFilterService.encode(vm.sortSettings), newParams.sort);
            if (isFilterChanged) {
                updateFilter(newParams.filter);
                vm.gridInstance.refresh();
            }

            if (isColumnChanged) {
                updateColumn(newParams.column);
            }

            if (isSortChanged) {
                updateSort(newParams.sort);
                vm.gridInstance.refresh();
            }
        }
        determineFilterSet($stateParams);
    }


    function updateFilter(filterStr) {
        const newFilters = ReportFilterService.decode(filterStr);
        if (ReportFilterService.is(newFilters)) {
            vm.filters = newFilters.map(filter => ({
                displayText: getFilterDisplayText(filter),
                ...filter
            }));
            ReportService.setFilters(removeAngularHashKeys(vm.filters.concat(vm.defaultFilters)));
        }
    }

    function updateColumn(columnStr) {
        const columns = _.cloneDeep(vm.columns);
        vm.selectedColumns = ReportColumnService.decode(columns, columnStr);

        vm.gridInstance.beginUpdate();
        vm.selectedColumns.filter(column => column.showInColumnChooser !== false).forEach(column => {
            vm.gridInstance.columnOption(column.dataField, 'visible', column.visible);
        });
        vm.gridInstance.endUpdate();
    }

    function updateSort(sortStr) {
        vm.sortSettings = ReportSortService.decode(sortStr);

        vm.gridInstance.beginUpdate();
        vm.columns.forEach(column => {
            const sortIndex = vm.sortSettings.findIndex(sortSetting => sortSetting.field === column.dataField);
            vm.gridInstance.columnOption(column.dataField, 'isSorting', sortIndex !== -1);

            if (sortIndex !== -1) {
                vm.gridInstance.columnOption(column.dataField, 'isSortingDesc', vm.sortSettings[sortIndex].desc);
            }
            if (sortIndex !== -1 && vm.sortSettings.length > 1) {
                vm.gridInstance.columnOption(column.dataField, 'customSortIndex', sortIndex + 1);
            } else {
                vm.gridInstance.columnOption(column.dataField, 'customSortIndex', undefined);
            }

            vm.gridInstance.columnOption(column.dataField, 'visible', column.visible);
        });
        vm.gridInstance.endUpdate();

        ReportService.setSortSettings(vm.sortSettings);
    }


    async function createReport() {
        const defaultReportName = vm.defaultReportName;

        const loadOptions = vm.gridInstance.getDataSource().loadOptions();
        const gridQueryOptions = {
            filters: removeAngularHashKeys(vm.filters.concat(vm.defaultFilters)),
            sort: vm.sortSettings ? vm.sortSettings : [],
            skip: loadOptions.skip,
            take: loadOptions.take,
            requireTotalCount: loadOptions.requireTotalCount || false,
        };

        const filtersText = vm.filters
            .map(filter => getFilterDisplayText(filter))
            .join(', ');

        const columns = vm.gridInstance.getVisibleColumns()
            .filter(column => !column.hideFromResponse)
            .map(column => column.dataField);

        ReportFormModal.open({
            defaultReportName,
            gridQueryOptions,
            gridColumns: vm.columns,
            submit: function ({ periodID, startDate, reportName, isEmail }) {
                return $http.post(`/api/report/${vm.report.code}/create-reports`, {
                    gridQueryOptions,
                    filtersText,
                    columns,
                    periodID,
                    startDate,
                    reportName,
                    isEmail,
                    dashboardCode: $stateParams.dashboardCode || 'incentive',
                    dashboardID: $stateParams.dashboardID,
                });
            }
        });
    }

    async function pinFilterSet (filterSet) {
        try {
            let savedFilterSets = _.cloneDeep(vm.user.savedFilterSets);

            if (!_.isArray(savedFilterSets)) {
                savedFilterSets = [];
            }

            const reportFilterSet = savedFilterSets.find(reportFilterSet => reportFilterSet.reportCode === vm.report.code);

            if (!_.isObject(reportFilterSet)) {
                savedFilterSets.push({
                    report: vm.report.code,
                    pinnedCode: filterSet.code
                });
            } else {
                reportFilterSet.pinnedCode = filterSet.code;
            }

            await ReportService.saveCustomFilterSet(savedFilterSets);
            vm.user.savedFilterSets = savedFilterSets;
            vm.pinnedFilterSetCode = filterSet.code;
            Session.setUser(vm.user);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteFilterSet(customFilterSet) {
        try {
            const savedFilterSets = _.cloneDeep(vm.user.savedFilterSets);
            const reportFilterSet = savedFilterSets.find(reportFilterSet => reportFilterSet.reportCode === vm.report.code);
            const customFilterSetIndex = reportFilterSet.filterSets.findIndex(filterSet => filterSet.code === customFilterSet.code);
            reportFilterSet.filterSets.splice(customFilterSetIndex, 1);

            if (reportFilterSet.pinnedCode === customFilterSet.code) {
                reportFilterSet.pinnedCode = vm.filterSets[0].code;
            }

            await ReportService.saveCustomFilterSet(savedFilterSets);
            vm.user.savedFilterSets = savedFilterSets;
            Session.setUser(vm.user);
            vm.filterSets = vm.report.filters.concat(reportFilterSet.filterSets);

            if (customFilterSet.code === vm.currentFilterSet.code) {
                vm.pinnedFilterSetCode = vm.filterSets[0].code;
                $state.go($state.current, {
                    filter: vm.filterSets[0].filter,
                    column: vm.filterSets[0].column,
                    sort: vm.filterSets[0].sort,
                });
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function saveCustomFilterSet(customFilterSet, customFilterSetName) {
        try {
            const customFilterSetToSave = {
                name: customFilterSetName,
                code: Date.now(),
                filter: customFilterSet.filter,
                column: customFilterSet.column,
                sort: customFilterSet.sort,
                isUserFilter: true
            };

            let savedFilterSets = _.cloneDeep(vm.user.savedFilterSets);

            if (!_.isArray(savedFilterSets)) {
                savedFilterSets = [];
                await ReportService.saveCustomFilterSet([{
                    report: vm.report.code,
                    filterSets: [
                        customFilterSetToSave
                    ]
                }]);
            } else if (!savedFilterSets.find(filterSet => filterSet.reportCode === vm.report.code)) {
                savedFilterSets.push({
                    reportCode: vm.report.code,
                    filterSets: [
                        customFilterSetToSave
                    ]
                });
                await ReportService.saveCustomFilterSet(savedFilterSets);
            } else {
                savedFilterSets.find(filterSet => filterSet.reportCode === vm.report.code).filterSets.push(customFilterSetToSave);
                await ReportService.saveCustomFilterSet(savedFilterSets);
            }
            vm.user.savedFilterSets = savedFilterSets;
            Session.setUser(vm.user);
            vm.filterSets = vm.report.filters.concat(savedFilterSets.find(filterSet => filterSet.reportCode === vm.report.code).filterSets);
            vm.currentFilterSet = customFilterSetToSave;
            determineFilterSet(customFilterSetToSave);
            vm.saveCustomFilterVisible = false;
        } catch (err) {
            console.error(err);
        }
    }

    function determineFilterSet(params) {
        const matchedFilterSet = vm.filterSets.filter(filterSet => !filterSet.isCustom).find(filterSet => filterSet.filter === params.filter
                        && filterSet.column === params.column
                        && filterSet.sort === params.sort);

        if (vm.filterSets[0].isCustom) {
            vm.filterSets.shift();
        }
        if (!matchedFilterSet) {
            const newCustomFilterSet = {
                isCustom: true,
                name: 'Custom Filter Set',
                filter: params.filter,
                column: params.column,
                sort: params.sort,
            };

            vm.filterSets.unshift(newCustomFilterSet);
            vm.currentFilterSet = newCustomFilterSet;
        } else {
            vm.currentFilterSet = matchedFilterSet;
        }
        vm.filterSetDropdownIsOpen = false;
    }

    function selectFilterSet(filterSet) {
        $state.go($state.current, {
            filter: filterSet.filter,
            column: filterSet.column,
            sort: filterSet.sort,
        });
    }

    function removeAngularHashKeys(json) {
        return JSON.parse(angular.toJson(json));
    }

    async function reloadGrid(gridInstance = {}) {
        await gridInstance.refresh();
    }

    function openColumnChooser(columns, gridInstance) {
        if (vm.isLoaded) {
            ReportColumnChooserService.open(columns, gridInstance).result.then(({ selectedColumns }) => {
                $state.go($state.current, {
                    filter: $stateParams.filter,
                    column: ReportColumnService.encode(vm.columns, selectedColumns),
                    sort: $stateParams.sort,
                });
            });
        }
    }

    function openAddFilterModal() {
        ReportFilterService.openFilterModal({
            columns: vm.columns,
            isEditing: false,
        }).result.then(newFilter => {
            const filters = _.cloneDeep(vm.filters);
            filters.splice(vm.filters.length, 0, newFilter);

            $state.go($state.current, {
                filter: ReportFilterService.encode(filters),
                column: $stateParams.column,
                sort: $stateParams.sort,
            });
        });
    }

    function openEditFilterModal(filter, index) {
        ReportFilterService.openFilterModal({
            columns: vm.columns,
            isEditing: true,
            filter,
        }).result.then((newFilter) => {
            const filters = _.cloneDeep(vm.filters);
            if (_.isEqual(filter, newFilter)) {
                return;
            }

            if (GridFilterOperators.noValueOperators.includes(newFilter.operator)) {
                filters.splice(index, 1, newFilter);
            } else if (_.isNil(newFilter.value) || _.isArray(newFilter.value) && _.isEmpty(newFilter.value)) {
                filters.splice(index, 1);
            } else {
                filters.splice(index, 1, newFilter);
            }

            $state.go($state.current, {
                filter: ReportFilterService.encode(filters),
                column: $stateParams.column,
                sort: $stateParams.sort,
            });
        });

    }

    function removeFilter(filterToRemove) {
        const filters = vm.filters.filter(filter => !_.isEqual(filter, filterToRemove));

        $state.go($state.current, {
            filter: ReportFilterService.encode(filters),
            column: $stateParams.column,
            sort: $stateParams.sort,
        });
    }

    function getFilterDisplayText(filter) {
        try {
            return ReportFilterService.getFilterDisplayText(vm.columns, filter);
        } catch (err) {
            console.error(err);
            console.error('Invalid grid filter', filter);
            return 'Invalid filter';
        }
    }


    function unsuppressEmail(contactID){
        
        return $uibModal.open({
            templateUrl: unsuppressEmailTpl,
            size: 'md',
            controller: function() {
                const vm = this;
                vm.confirmUnsuppressEmail = confirmUnsuppressEmail;
                
                
                async function confirmUnsuppressEmail() {
                    const res = await $http.get('/api/unsuppressEmail/' + contactID);
                    if (res.status == 200)  {
                        // reload grid 
                        $scope.vm.gridInstance.refresh();
                    }

                };
            },
            controllerAs: 'vm'
        });
    }    
}
