import * as angular from 'angular';
import * as _ from 'lodash';

promoQuizValidationCtrl.$inject = [
    'OcrStatuses', '$window', '$timeout', 'QuizQuestionTypes', 'PromoQuizValidationService',
    '$rootScope', 'AUTH_EVENTS', '$translate', '$filter', '$scope'
];

export default function promoQuizValidationCtrl (
    OcrStatuses, $window, $timeout, QuizQuestionTypes, PromoQuizValidationService,
    $rootScope, AUTH_EVENTS, $translate, $filter, $scope
) {
    this.$onInit = () => {
        const vm = this;
        vm.OcrStatuses = OcrStatuses;
        vm.QuizQuestionTypes = QuizQuestionTypes;
        vm.showValidation = !!vm.sectionResults.ocrJobs;
        vm.assignBackNoteModel = {};
        vm.feedback = {
            aspects: [],
        };
        vm.feedbackAspectList = [];
        vm.feedbackCommentPlaceholder = $translate.instant('promo_QUIZ_TOOLS_FEEDBACK_COMMENT_PLACEHOLDER');
        vm.alerts = [];
        if (vm.showValidation) {
            vm.ocrResults = vm.sectionResults.ocrJobs.reduce((total, ocrJob) => {
                let results;
                if (!ocrJob.ocrResults) {
                    results = angular.copy(ocrJob.ocrRules);
                } else {
                    results = angular.copy(ocrJob.ocrResults);
                }
                results = results.map(result => {
                    result.running = ocrJob.ocrStatusID === OcrStatuses.RUNNING;
                    result.showError = result.isPass === false;
                    result.fileName = ocrJob.name;
                    result.file_quizQuestionID = ocrJob.file_quizQuestionID;
                    result.didNotRun = !result.running && (result.isPass === undefined || result.isPass === null);
                    result.answer = vm.sectionResults.answers.filter(answer => answer.quizQuestionID === result.quizQuestionID)[0];
                    return result;
                });
                return total.concat(results);
            }, []);
            vm.ocrErrorResults = vm.ocrResults.filter(ocrResult => ocrResult.showError);
            vm.ocrPassResults = vm.ocrResults.filter(ocrResult => ocrResult.isPass === true);
            vm.ocrDidNotRunResults = vm.ocrResults.filter(ocrResult => ocrResult.didNotRun === true);
            vm.ocrRunningResults = vm.ocrResults.filter(ocrResult => ocrResult.running === true);
            vm.ocrResolvedResults = vm.ocrResults.filter(ocrResult => ocrResult.isResolved === true);

            // TODO: future socket implementation
            /*
            vm.ocrJobs.forEach( (ocrJob, index) => {
                Socket.on(`ocrJob:${ocrJob.ocrJobID}`, (results) => {
                    if(!!results) {
                        console.log('ocr suceeded, applying...');
                        const newOcrResults = _.merge(ocrJob.ocrRules, results);
                        const ocrStatusID = newOcrResults.every(ocrResult => ocrResult.isPass === true) ?
                                                OcrStatuses.PASS : OcrStatuses.FAIL;
                        vm.ocrJobs[index] = getUpdatedOcrJob(_.merge(ocrJob, { ocrResults: newOcrResults, ocrStatusID }));
                        console.table(vm.ocrJobsp[index]);
                        console.table(vm.ocrJobs[index].ocrResults);
                    }
                    if(!results){
                        console.log('ocr failed, reloading...');
                        // $timeout(vm.onSubmitSuccess());
                    }
                });
            });
            */
        }
        vm.openWindow = openWindow;
        vm.updateResolutions = updateResolutions;
        vm.save = save;
        vm.toggleAssignBack = toggleAssignBack;
        vm.closeAlert = closeAlert;
        vm.formatDate = formatDate;
        vm.toggleFeedbackAspect = toggleFeedbackAspect;
        vm.resetFeedback = resetFeedback;
        vm.resetAspectList = resetAspectList;
        vm.submitFeedback = submitFeedback;


        $scope.$on('$destroy', () => {
            if ($window.reloadPromo) {
                delete $window.reloadPromo;
            }
        });


        async function submitFeedback() {
            try {
                await PromoQuizValidationService.submitFeedback(vm.feedback);
                vm.isFeedbackSubmitted = true;
            } catch (err) {
                console.error(err);
            }
        }

        function resetFeedback() {
            vm.isFeedbackCollapsed = !vm.isFeedbackCollapsed;
            vm.feedback.aspects = [];
            vm.feedbackAspectList = [];
            vm.feedback.ocrResultID = '-1';
            vm.feedback.comment = vm.isFeedbackSubmitted ? '' : vm.feedback.comment;
            vm.isFeedbackSubmitted = false;
        }

        function resetAspectList() {
            const ocrResult = vm.ocrResults.find(ocrResult => ocrResult.ocrResultID === Number(vm.feedback.ocrResultID));
            const aspectList = PromoQuizValidationService.getAspectList();
            vm.feedbackAspectList = ocrResult ? aspectList
                .filter(aspect => aspect.isPass === ocrResult.isPass)
                .filter(aspect => aspect.quizQuestionTypeID === ocrResult.quizQuestionTypeID
                                || !aspect.quizQuestionTypeID) : [];
            vm.feedbackAspectList.forEach(aspect => aspect.checked = false);
            vm.feedback.aspects = [];
            vm.feedback.comment = vm.isFeedbackSubmitted ? '' : vm.feedback.comment;
            vm.isFeedbackSubmitted = false;
        }

        function toggleFeedbackAspect(aspect) {
            const aspectIndex = vm.feedback.aspects.indexOf(aspect);
            if (aspectIndex > -1) {
                vm.feedback.aspects.splice(aspectIndex, 1);
            } else {
                vm.feedback.aspects.push(aspect);
            }
        }

        function formatDate(date) {
            return $filter('date')(date);
        }

        function closeAlert(index) {
            vm.alerts.splice(index, 1);
        }

        $rootScope.deregisterfileViewerListener = $rootScope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, () => {
            if ($rootScope.fileViewer) {
                $rootScope.fileViewer.close();
            }
            $rootScope.deregisterfileViewerListener();
        });

        function toggleAssignBack() {
            vm.showAssignBack = !vm.showAssignBack;
            vm.hideToolsByValidation = !vm.hideToolsByValidation;
        }

        function save(ocrResults) {
            vm.alerts = [];

            const quizAnswers = angular.copy(vm.formModel);
            const promoID = vm.promoDetails.ID;
            const quizID = vm.promoDetails.quiz.ID;
            const sectionID = vm.section.sectionID;

            const quizResultID = vm.promoDetails.results.quizResultID;
            const ocrResultsToIgnore = ocrResults.filter(ocrResult => ocrResult.resolution === 'ignore');
            const ocrResultsToFix = ocrResults.filter(ocrResult => ocrResult.resolution === 'fix');
            const ocrResultsToAssignBack = ocrResults.filter(ocrResult => ocrResult.resolution === 'assign-back');

            if (ocrResultsToFix.length) {
                ocrResultsToFix.forEach(result => {
                    quizAnswers[result.quizQuestionID] = result.newValue;
                });
            }
            PromoQuizValidationService.save({
                promoID,
                quizID,
                sectionID,
                quizResultID,
                quizAnswers,
                ocrResultsToIgnore,
                ocrResultsToFix,
                ocrResultsToAssignBack,
                assignBackNote: vm.assignBackNoteModel.note
            }).then(vm.onSubmitSuccess)
                .catch(() => {
                    if (!vm.alerts.length) {
                        vm.alerts.push({
                            msg: $translate.instant('promo_QUIZ_TOOLS_OCR_FIX_SYNTAX_ERROR_MESSAGE')
                        });
                    }
                });
        }

        function updateResolutions(choice, ocrResult, ocrErrorResults) {
            if (choice && ocrResult) {
                ocrResult.resolution = choice;
            }

            if (choice === 'fix') {
                $timeout(() => {
                    const type = ocrResult.quizQuestionTypeID === QuizQuestionTypes.DATE ? 'date' : 'answer';
                    $window.document.getElementById(`input-correct-${type}-${ocrResult.ocrResultID}`).focus();
                }, 1000);
            }


            vm.showEditAssignBack = ocrErrorResults.some(ocrResult => ocrResult.resolution === 'assign-back');
            const assignBackNote = ocrErrorResults
                .filter(ocrResult => ocrResult.resolution === 'assign-back')
                .map(ocrResult => ocrResult.name).join('\n');
            if (assignBackNote !== '') {
                vm.assignBackNoteModel = {
                    note: [
                        $translate.instant('promo_QUIZ_TOOLS_OCR_ASSIGN_BACK_MESSAGE_TITLE'),
                        assignBackNote,
                        $translate.instant('promo_QUIZ_TOOLS_OCR_ASSIGN_BACK_MESSAGE_ENDING')
                    ].join('\n\n'),
                };
            }
            vm.displaySaveButton = ocrErrorResults
                .some(ocrResult => ['fix', 'ignore', 'assign-back']
                .includes(ocrResult.resolution));
            vm.disableSaveButton = ocrErrorResults
                .some(ocrResult => ocrResult.resolution === 'fix'
                    && (ocrResult.newValue === undefined || ocrResult.newValue === ''));
        }
        function openWindow(ocrJobs) {
            const uploads = angular.copy(ocrJobs.map(ocrJob => _.merge(ocrJob, vm.sectionResults.answers
                    .find(answer => answer.quizQuestionID === ocrJob.file_quizQuestionID))));
            if ($rootScope.fileViewer) {
                $rootScope.fileViewer.uploads = uploads;
                if (!$rootScope.fileViewer.isLoaded) {
                    console.warn('file viewer is still loading, returning');
                    return;
                }
            }
            $window.reloadPromo = vm.onSubmitSuccess;
            $rootScope.fileViewer = $window.open(
                `/#/file-viewer?quizResultID=${vm.promoDetails.results.quizResultID}&timeStamp=${Date.now()}`,
                'fileViewer',
                `top=0, left=${$window.innerWidth}, height=${$window.innerHeight}, width=${$window.innerWidth}`);
            if (!$rootScope.fileViewer.uploads) {
                $rootScope.fileViewer.uploads = uploads;
            }
        }
    };
}
