import angular from 'angular';

import '../../../core/utils/coreUtils.module';

import legacyMessage from './legacyMessage.directive';

export default angular.module('app.communication.legacyMessage', [
    'app.core.utils'
])
.directive('legacyMessage', legacyMessage);
