import _ from 'lodash';

filterModalCtrl.$inject = [
    'filter', '$uibModalInstance', 'DropdownSelectionService', 'DatepickerService', 'GridColumnFilterTypes',
    'GridFilterOperators', 'Session'
];

export default function filterModalCtrl(
    filter, $uibModalInstance, DropdownSelectionService, DatepickerService, GridColumnFilterTypes,
    GridFilterOperators, Session
) {
    const vm = this;

    vm.company = Session.getCompany();

    if (filter) {
        vm.isEditing = true;
        vm.filterValueInput = filter.value;
        DropdownSelectionService.setEditing(true); // Indicates to dropdown to use same values as current filter
        DropdownSelectionService.setFilter(filter);
    } else {
        vm.isEditing = false;
        DropdownSelectionService.setEditing(false); // Indicates to dropdown to clear column and value
    }

    vm.ok = function() {
        const filter = DropdownSelectionService.getFilter();
        const { type, operator } = filter; // TODO: get the type from uib-modal resolve instead of DropdownSelectionService.getFilter()
        let filterValue;

        switch (type) {
            case GridColumnFilterTypes.NUMBER:
                filterValue = Number(vm.filterValueInput);
                break;
            case GridColumnFilterTypes.DATE:
                switch (operator) {
                    case GridFilterOperators.EQUALS:
                    case GridFilterOperators.BEFORE:
                    case GridFilterOperators.AFTER:
                        filterValue = DatepickerService.getValue();
                        break;
                }
                break;
            case GridColumnFilterTypes.BOOLEAN:
                filterValue = vm.filterValueInput ? 1 : 0;
                break;
            case GridColumnFilterTypes.CHECKBOX:
            case GridColumnFilterTypes.RADIO:
                filterValue = DropdownSelectionService.getListValues();
                if (_.isEmpty(filterValue) && !GridFilterOperators.properties[operator].noValueRequired) {
                    return vm.cancel();
                }
                break;
            default:
                filterValue = vm.filterValueInput;
        }

        if ((filterValue == null || filterValue === '') && !GridFilterOperators.properties[operator].noValueRequired) {
            return vm.cancel();
        }

        const filterToSend = {
            field: filter.field,
            operator: filter.operator,
            value: filterValue
        };

        return $uibModalInstance.close(filterToSend);
    };

    vm.cancel = function() {
        return $uibModalInstance.dismiss();
    };
}
