import angular from 'angular';

import './wrapper/wrapper.module';
import './applauseRTE/applauseRTE.module';
import './applauseMultiCheckbox/applauseMultiCheckbox.module';
import './applauseBudgetSuggest/applauseBudgetSuggest.module';
import './applausePointsInput/applausePointsInput.module';
import './applauseGuidelineTable/applauseGuidelineTable.module';
import './applauseCultureStory/applauseCultureStory.module';
import './applauseCultureStoryRTE/applauseCultureStoryRTE.module';

export default angular.module('app.applause.formly', [
    'app.applause.formly.wrapper',
    'app.applause.formly.applauseRTE',
    'app.applause.formly.applauseMultiCheckbox',
    'app.applause.formly.applauseBudgetSuggest',
    'app.applause.formly.applausePointsInput',
    'app.applause.formly.applauseGuidelineTable',
    'app.applause.formly.applauseCultureStory',
    'app.applause.formly.applauseCultureStoryRTE'
]);
