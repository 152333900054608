import _ from 'lodash';

tilesWidgetCtrl.$inject = [];

export default function tilesWidgetCtrl() {
    this.$onInit = () => {
        const vm = this;

        const dataSet = _.get(vm.widget.data, 'dataSets[0]');
        const tiles = dataSet && dataSet.dataPoints;

        vm.hasData = !_.isEmpty(tiles) && tiles.some(tile => tile.value > 0);
        if (!vm.hasData) {
            return;
        }

        vm.tiles = fillTiles(tiles);
        vm.tilesHeader = dataSet.translateKey;
    };
}

/**
 * If there are less than 3 tiles, "empty tiles" are added.
 */
function fillTiles(tiles) {
    switch (tiles.length) {
        case 2:
            return [...tiles, { isEmpty: true }];
        case 1:
            return [{ isEmpty: true }, tiles[0], { isEmpty: true }];
        default:
            return tiles;
    }
}
