forgotPasswordCtrl.$inject = ['$scope', 'ForgotPasswordService', 'AuthStatus', 'Session'];

export default function forgotPasswordCtrl($scope, ForgotPasswordService, AuthStatus, Session) {
    this.$onInit = () => {
        const vm = this;
        // Passed from parent template:
        // vm.username

        vm.company = Session.getCompany();

        vm.model = {
            userLookup: vm.username || ''
        };

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_PROBLEMS_SIGNING_IN_SUBMIT', // Submit
                },
                success: {
                    isDisabled: true,
                    noReset: true
                }
            }
        };

        vm.fields = [{
            key: 'userLookup',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                labelTranslateKey: 'app_PROBLEMS_SIGNING_IN_USER_LOOKUP_LABEL', // 'Username or Email'
                placeholderTranslateKey: 'app_PROBLEMS_SIGNING_IN_USER_LOOKUP_PLACEHOLDER', // Enter your username or email here
                controlClass: 'col-sm-12',
                labelSrOnly: true,
                required: true
            }
        }];

        if (!AuthStatus.isAuthenticated() && vm.company.hasOption('CaptchaPreLogin')) {
            vm.fields.push({
                key: 'captchaToken',
                type: 'customCaptcha',
                templateOptions: {
                    controlClass: 'col-sm-12'
                }
            });
        }

        $scope.$watch(() => vm.username, function(newUsername) {
            vm.model.userLookup = newUsername;
        });

        vm.submit = () => ForgotPasswordService.sendForgotPasswordForm(vm.model);

        vm.onSubmitSuccess = () => {
            vm.success = true;
            // An email with instructions on how to access the website has been sent.
            vm.message = 'app_FORGOT_PASSWORD_MESSAGE_SUCCESS';
        };

        vm.onSubmitFail = (err) => {
            vm.success = false;
            switch (err.data.returnCode) {
                case 1006:
                    // That username or email does not exist.
                    vm.message = 'app_FORGOT_PASSWORD_MESSAGE_USER_DOES_NOT_EXIST';
                    break;
                case 1007:
                    // The username that was entered does not have an associated email address.
                    vm.message = 'app_FORGOT_PASSWORD_MESSAGE_NO_ASSOCIATED_EMAIL';
                    break;
                case 6007:
                    // The account associated with this username or email should be using SSO login.
                    vm.message = 'app_FORGOT_PASSWORD_MESSAGE_SSO_EXPECTED';
                    break;
                default:
                    throw err;
            }
        };
    };
}
