promoGaugeWidgetCtrl.$inject = [];

export default function promoGaugeWidgetCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed passed from component bindings:
        // vm.promoDetails
        // vm.size

        // promoGaugeWidget is a container for the gaugeWidget as well as some other information such as header, stats. etc

        vm.standingValueName = vm.promoDetails.widgetPromoTargets.standingValueName;
        vm.standingValue = vm.promoDetails.widgetPromoTargets.standingValue;
        vm.updatedOn = vm.promoDetails.widgetPromoTargets.updatedOn;

        // Determine what to show when target reached
        const targets = vm.promoDetails.widgetPromoTargets.pointTargets;
        const targetsAchived = targets.filter((target) => target.targetValue <= vm.standingValue);
        vm.allTargetsAchieved = targets.length === targetsAchived.length;
    };
}
