import socialPostFormPageTpl from './socialPostFormPage.tpl.html';

socialPostConfig.$inject = ['$stateProvider'];

export default function socialPostConfig($stateProvider) {
    $stateProvider.state('socialPostForm', {
        parent: 'auth',
        url: '/social-post-form',
        views: {
            'main@': {
                controller: 'socialPostFormPageCtrl as vm',
                templateUrl: socialPostFormPageTpl
            }
        },
        data: {
            pageTitle: 'Social Post Form'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('socialPostForm');
            }]
        }
    });
}
