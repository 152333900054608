import angular from 'angular';

import toolsConfig from './tools.config';
import toolsCtrl from './tools.controller';

import '../../core/core.module';

export default angular.module('app.tools', [
    'app.core'
])
.config(toolsConfig)
.controller('toolsCtrl', toolsCtrl);
