landingCtrl.$inject = ['$stateParams', 'LandingSetup', 'Content'];

export default function landingCtrl($stateParams, LandingSetup, Content) {
    const vm = this;
    vm.landingSetup = LandingSetup;
    vm.programType = $stateParams.programType;
    vm.focus = $stateParams.focus;
    vm.code = $stateParams.code;
    vm.content = Content;
}
