

const constantUtils = require('./constantUtils');

const WidgetVisuals = {
    TABLE: 'Table',
    LINE_CHART_MONTH: 'LineChartMonth',
    LINE_CHART_DATE: 'LineChartDate',
    PIE_CHART: 'PieChart',
    MULTI_PIE_CHART: 'MultiPieChart',
    BAR_CHART: 'BarChart',
    FUNNEL: 'Funnel',
    MULTI_FUNNEL: 'MultiFunnel',
    EXPECTED_PERFORMANCE_WITH_TARGETS: 'ExpectedPerformanceWithTargets',
    DATAFEED_PERFORMANCE: 'DatafeedPerformance',
    METRIC_SNAPSHOT: 'MetricSnapshot',
    PARTICIPANT_ENGAGEMENT: 'ParticipantEngagement',
    TOP_THREE_STRENGTHS: 'TopThreeStrengths',
    APPLAUSES_RECEIVED: 'ApplausesReceived',
    APPLAUSES_SENT: 'ApplausesSent',
    INCENTIVE_PAYOUT_GAUGE: 'Gauge',
    DATAFEED_SUMMARY_COUNT: 'DataFeedSummaryCount',
    TILES: 'Tiles',
    SLIDESHOW: 'Slideshow',
    ANNIVERSARY: 'Anniversary',
    BIG_NUMBER: 'BigNumber',
    PROGRESS_BARS: 'ProgressBars',
    RANGE_SELECTOR: 'RangeSelector',
    PROMO_CALCULATIONS_INFO: 'PromoCalculationsInfo',
    LEADERBOARD: 'Leaderboard',
    TARGET_PROGRESS: 'TargetProgress',
};

const properties = {};

constantUtils.configure({ constants: WidgetVisuals, properties });

module.exports = WidgetVisuals;
