import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';

PromoQuizService.$inject = [
    '$http', '$q', '$translate', 'QuizResultStatuses', 'SuggestsTypes', 'Session', 'FileUtils', 'QuizNoteTypes',
    'QuizQuestionTypes', 'QuizQuestionValidations', 'PromoStatuses'
];

export default function PromoQuizService(
    $http, $q, $translate, QuizResultStatuses, SuggestsTypes, Session, FileUtils, QuizNoteTypes,
    QuizQuestionTypes, QuizQuestionValidations, PromoStatuses
) {

    const QuestionTypeValidators = {
        // no validate is needed for QuizQuestionValidations.ANYTHING
        // TODO: for Integer and Numeric short answers, use customIntInput instead of copying and pasting its validator functions
        [QuizQuestionValidations.INTEGER]: function() {
            return {
                expression: function(viewValue, modelValue, scope) {
                    const value = modelValue || viewValue;
                    if (!scope.to.required && !value) {
                        return true; // handled by required
                    }
                    return _.isInteger(Number(value));
                },
                // 'Please enter an integer'
                message: () => $translate.instant('app_PROMO_QUIZ_QUESTION_VALIDATE_INTEGER_MESSAGE')
            };
        },
        [QuizQuestionValidations.NUMERIC]: function() {
            return {
                expression: function(viewValue, modelValue, scope) {
                    const value = modelValue || viewValue;
                    if (!scope.to.required && !value) {
                        return true; // handled by required
                    }
                    return isFinite(Number(value));
                },
                // 'Please enter a number'
                message: () => $translate.instant('app_PROMO_QUIZ_QUESTION_VALIDATE_NUMBER_MESSAGE')
            };
        },
        [QuizQuestionValidations.REGULAR_EXPRESSION]: function(question) {
            return {
                expression: function(viewValue, modelValue) {
                    const value = modelValue || viewValue;
                    const regex = new RegExp(question.regularExpression);
                    return regex.test(value);
                },
                // 'This field doesn't match the required format'
                message: () => $translate.instant('app_PROMO_QUIZ_QUESTION_VALIDATE_REGEX_MESSAGE')
            };
        }
    };

    return {
        getQuizResults,
        approveSubmission,
        declineSubmission,
        closeSubmission,
        addNote,
        toggleNoteFlag,
        snoozeSubmission,
        deleteSnooze,
        canSubmitSection,
        canEditSection,
        canSnoozeSection,
        canCollapseSection,
        canShowOcrValidation,
        isUserCandidate,
        isUserApprover,
        isNonSectionQuiz,
        isSectionSnoozed,
        isSectionCollapsed,
        getSectionResults,
        getSectionNumber,
        getNoteTitle,
        determineApproverToolsSectionIndex,
        determineVisibleSectionIDs,
        convertQuizSectionToFormly
    };

    async function getQuizResults({ quizResultID }) {
        const res = await $http.get(`/api/quiz-results/${quizResultID}`);
        return res.data;
    }

    function approveSubmission({ sectionID, quizResultID, note, points, budgetID }) {
        return $http.post(`/api/quiz-results/${quizResultID}/approve`, {
            note,
            points,
            budgetID,
            sectionID,
        });
    }

    function declineSubmission({ sectionID, quizResultID, note }) {
        return $http.post(`/api/quiz-results/${quizResultID}/decline`, {
            sectionID,
            note
        });
    }

    function closeSubmission({ sectionID, quizResultID, note }) {
        return $http.post(`/quiz-results/${quizResultID}/close`, {
            sectionID,
            note
        });
    }

    function addNote({ sectionID, quizResultID, note, isBackToClient, isApprover }) {
        return $http.post(`/api/quiz-results/${quizResultID}/notes`, {
            note,
            isBackToClient,
            sectionID,
            isApprover
        });
    }

    function toggleNoteFlag({ quizResultNoteID, isFlagged }) {
        return $http.put(`/api/quiz-results/notes/${quizResultNoteID}/flag`, {
            isFlagged
        });
    }

    function snoozeSubmission({ quizResultID, quizSectionID, snoozeUntilDate }) {
        return $http.post(`/api/quiz-results/${quizResultID}/snooze`, {
            quizSectionID,
            snoozeUntilDate
        })
        .then(res => res.data);
    }

    function deleteSnooze({ quizResultID, quizSectionID }) {
        return $http.delete(`/api/quiz-results/${quizResultID}/snooze`, {
            data: {
                quizSectionID
            }
        });
    }

    /**
     * For checking if a Promo Quiz answer is unique
     * @param {String} uniqueType - One of 'company', 'user', or 'entity'
     * @param {int} promoID
     * @param {int} quizID
     * @param {int} quizQuestionID
     * @param {string | int} answer - User input answer
     * @param {int=} quizResultID
     */
    function checkAnswerIsUnique({ uniqueType = 'company', answer, promoID, quizID, quizQuestionID, quizResultID }) {
        // Note: formly asyncValidation doesn't seem to work if you try to return true/false in the .then callback yourself
        // So just rely on the API returning http statuses 2xx for unique and 4xx for not
        return $http.get(`/api/promos/${promoID}/quiz/${quizID}/questions/${quizQuestionID}/check-unique-answer`, {
            params: {
                answer,
                uniqueType,
                quizResultID
            }
        });
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function canSubmitSection(promoDetails, section) {
        const sectionResults = getSectionResults(promoDetails, section);
        if (!sectionResults) {
            return [PromoStatuses.ACTIVE, PromoStatuses.COMPLETE].includes(promoDetails.promoStatusID);
        }
        
        const isCandidate = isUserCandidate(promoDetails);
        const { sectionStatusID } = sectionResults;
        return isCandidate 
                && sectionStatusID === QuizResultStatuses.REQUIRES_ACTION
                && [PromoStatuses.ACTIVE, PromoStatuses.COMPLETE].includes(promoDetails.promoStatusID);
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function canEditSection(promoDetails, section) {
        const sectionResults = getSectionResults(promoDetails, section);
        if (!sectionResults) {
            return false;
        }
        const isApprover = isUserApprover(promoDetails);
        const isCandidate = isUserCandidate(promoDetails);
        const { sectionStatusID } = sectionResults;
        const canEditAsApprover = isApprover 
            && sectionStatusID !== QuizResultStatuses.USER_ABANDONED
            && [PromoStatuses.ACTIVE, PromoStatuses.COMPLETE, PromoStatuses.PAUSED].includes(promoDetails.promoStatusID);
        const canEditAsCandidate = isCandidate
            && [QuizResultStatuses.PENDING_APPROVAL, QuizResultStatuses.COMPLETED].includes(sectionStatusID)
            && [PromoStatuses.ACTIVE, PromoStatuses.COMPLETE].includes(promoDetails.promoStatusID);
        return canEditAsApprover || canEditAsCandidate;
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function canSnoozeSection(promoDetails, section) {
        if (isNonSectionQuiz(promoDetails)) {
            return false;
        }
        const sectionResults = getSectionResults(promoDetails, section);
        if (!sectionResults) {
            return false;
        }
        const isApprover = isUserApprover(promoDetails);
        const isCandidate = isUserCandidate(promoDetails);
        const { sectionStatusID } = sectionResults;
        const canSnoozeAsApprover = isApprover && sectionStatusID === QuizResultStatuses.PENDING_APPROVAL;
        const canSnoozeAsCandidate = isCandidate && sectionStatusID === QuizResultStatuses.REQUIRES_ACTION;
        return canSnoozeAsApprover || canSnoozeAsCandidate;
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function canCollapseSection(promoDetails, section) {
        return !isNonSectionQuiz(promoDetails) && getSectionResults(promoDetails, section);
    }

    function canShowOcrValidation(promoDetails, section) {
        const sectionResults = getSectionResults(promoDetails, section);
        return isUserApprover(promoDetails) && !!sectionResults && !!sectionResults.ocrJobs;
    }

    /**
     * @param {Object} promoDetails
     * @returns {boolean}
     */
    function isUserCandidate(promoDetails) {
        const quizResults = promoDetails.results;
        if (!quizResults) {
            return false;
        }
        const user = Session.getUser();
        return quizResults && user.userID === quizResults.quizTakerID;
    }

    /**
     * @param {Object} promoDetails
     * @returns {boolean}
     */
    function isUserApprover(promoDetails) {
        const quizResults = promoDetails.results;
        return quizResults && quizResults.isApprover;
    }

    /**
     * @param {Object} promoDetails
     * @returns {boolean}
     */
    function isNonSectionQuiz(promoDetails) {
        const sections = promoDetails.quiz.sections;
        return sections.length <= 1 && sections[0].sectionID == null;
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function isSectionSnoozed(promoDetails, section) {
        const sectionResults = getSectionResults(promoDetails, section);
        return sectionResults && sectionResults.snoozeUntilDate != null;
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean} - True if the section should be initially collapsed when viewing the quiz results.
     */
    function isSectionCollapsed(promoDetails, section) {
        if (!canCollapseSection(promoDetails, section)) {
            return false;
        }
        const sectionResults = getSectionResults(promoDetails, section);
        if (!sectionResults) {
            return false;
        }
        const isSnoozed = isSectionSnoozed(promoDetails, section);
        const isCandidate = isUserCandidate(promoDetails);
        const isApprover = isUserApprover(promoDetails);
        const sectionHasFlaggedNote = doesSectionHaveFlaggedNote(promoDetails, section);
        const { sectionStatusID } = sectionResults;
        return isSnoozed
            || !(isCandidate || isApprover)
            || (isCandidate && sectionStatusID !== QuizResultStatuses.REQUIRES_ACTION)
            || (isApprover && !isCandidate
                && (sectionStatusID !== QuizResultStatuses.PENDING_APPROVAL) && !sectionHasFlaggedNote);
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {boolean}
     */
    function doesSectionHaveFlaggedNote(promoDetails, section) {
        const sectionResults = getSectionResults(promoDetails, section);
        if (!sectionResults) {
            return false;
        }
        const notes = sectionResults.comments;
        if (!notes) {
            return false;
        }
        return notes.some(note => note.isCommentFlag);
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {Object}
     */
    function getSectionResults(promoDetails, section) {
        const quizResults = promoDetails.results;
        if (!quizResults) {
            return null;
        }
        return quizResults.sections.find(resultSection => resultSection.sectionID === section.sectionID);
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} section
     * @returns {int}
     */
    function getSectionNumber(promoDetails, section) {
        return promoDetails.quiz.sections.findIndex(s => s.sectionID === section.sectionID) + 1;
    }

    /**
     * @param {Object} promoDetails
     * @param {int} sectionID
     * @returns {int}
     */
    function getSectionIndexByID(promoDetails, sectionID) {
        return promoDetails.quiz.sections.findIndex(
            section => section.sectionID === sectionID
        );
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} note
     * @returns {string | undefined}
     */
    function getNoteTitle(promoDetails, note) {
        const translateKey = getNoteTitleTranslateKey(promoDetails, note);
        if (translateKey) {
            return $translate.instant(translateKey, Object.assign({}, note, {
                snoozeUntilDate: moment(note.snoozeUntilDate).format('MMMM D, YYYY')
            }));
        }
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} note
     * @returns {string | undefined}
     */
    function getNoteTitleTranslateKey(promoDetails, note) {
        switch (note.typeID) {
            case QuizNoteTypes.SNOOZE:
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_SNOOZED_UNTIL'; // 'Snoozed until {{ snoozeUntilDate }}'
            case QuizNoteTypes.MODIFY_BY_ADMIN:
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_MODIFY_BY_ADMIN'; // 'Edited By Approver'
        }
        const isCandidate = isUserCandidate(promoDetails);
        if (note.statusID === QuizResultStatuses.REQUIRES_ACTION && !isCandidate) {
            return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_ASSIGN_BACK'; // 'Assigned Back to User'
        }
        if ([QuizResultStatuses.PENDING_APPROVAL, QuizResultStatuses.REQUIRES_ACTION].includes(note.statusID)) {
            if (note.comment) {
                return;
            } else if (note.statusID === QuizResultStatuses.PENDING_APPROVAL) {
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_FORM_SUBMITTED'; // 'Form Submitted'
            } else {
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_ASSIGN_BACK'; // 'Assigned Back to User'
            }
        }
        switch (note.statusID) {
            case QuizResultStatuses.DENIED:
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_FORM_DECLINED'; // 'Form Declined'
            case QuizResultStatuses.WINNER:
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_FORM_APPROVED'; // 'Form Approved'
            case QuizResultStatuses.COMPLETED:
                return 'app_PROMO_QUIZ_SECTION_NOTE_TITLE_COMPLETE'; // 'Complete'
        }
    }

    /**
     * Determines which section of a quiz should show approver tools (if any).
     * (The tools are Add Note / Close / Decline / Approve)
     * @param {Object} promoDetails
     * @returns {int | undefined} - Index of the section. Undefined if no approver tools should show on any section.
     */
    function determineApproverToolsSectionIndex(promoDetails) {
        if (!isUserApprover(promoDetails)) {
            return;
        }
        const quizResults = promoDetails.results;
        if (!quizResults) {
            return;
        }
        const resultSections = quizResults.sections;
        const someSectionIsDeclined = resultSections.some(
            resultSection => resultSection.sectionStatusID === QuizResultStatuses.DENIED
        );
        if (someSectionIsDeclined) {
            return;
        }
        if (isNonSectionQuiz(promoDetails)) {
            if (resultSections[0].sectionStatusID === QuizResultStatuses.PENDING_APPROVAL) {
                return 0;
            }
        } else {
            for (let i = 0; i < resultSections.length; i++) {
                const resultSection = resultSections[i];
                if (resultSection.sectionStatusID === QuizResultStatuses.REQUIRES_ACTION) {
                    return;
                }
                if (resultSection.sectionStatusID === QuizResultStatuses.PENDING_APPROVAL) {
                    return getSectionIndexByID(promoDetails, resultSection.sectionID);
                }
            }
        }
    }

    /**
     * @param {Object} promoDetails
     * @returns {int[]} - Section IDs that should be visible
     */
    function determineVisibleSectionIDs(promoDetails) {
        const quizResults = promoDetails.results;
        if (isNonSectionQuiz(promoDetails) || !quizResults) {
            const firstSectionID = promoDetails.quiz.sections[0].sectionID;
            return [firstSectionID];
        }
        return quizResults.sections.map(resultSection => resultSection.sectionID);
    }

    /**
     * Returns a formly-compatible config object with fields, options, etc. determined from the quiz's data.
     * @param {Object} promoDetails - JSON From API
     * @param {Object} section - from promoDetails.quiz.sections
     */
    function convertQuizSectionToFormly(promoDetails, section) {
        // Prevent functions below from modifying the original objects passed
        promoDetails = angular.copy(promoDetails);
        section = angular.copy(section);

        const quizData = promoDetails.quiz;
        if (!quizData) {
            return [];
        }

        const quizResults = promoDetails.results;
        const sectionQuestions = section.questions;
        const sectionResults = getSectionResults(promoDetails, section);

        // TODO: try moving the getRecipientsFields line below answerData for organization
        const recipientsFields = getRecipientsFields(promoDetails);

        if (sectionResults) {
            addSectionCandidateAnswers(sectionQuestions, sectionResults, quizResults);
        }

        const questionsFields = getSectionQuestionsFields(sectionQuestions, promoDetails);

        const formly = {
            fields: recipientsFields.concat(questionsFields),
            options: {
                formState: {}
            }
        };
        if (quizData.isSelectCandidate && quizData.isMultipleRecipients) {
            formly.options.formState.promoSuggestType = {
                id: 1,
                name: 'Colleagues - ad hoc',
                value: 'recipients'
            };
        }
        return formly;
    }

    function getRecipientsFields(promoDetails) {
        const { isSelectCandidate, isMultipleRecipients } = promoDetails.quiz;
        if (!isSelectCandidate) {
            return [];
        }
        if (isMultipleRecipients) {
            return getMultipleRecipientsFields(promoDetails);
        }
        return getSingleRecipientFields(promoDetails);
    }

    function addSectionCandidateAnswers(sectionQuestions, sectionResults, quizResults) {
        // add candidate answers to sectionQuestions
        // set values for formly code:  isDisabled, isSelected, defaultValue, initDate; see customCalendar.run.js, customMultiCheckbox.run.js, etc

        // TODO: refactor - instead of setting values onto each "question" and then later getting the value to set a formly property,
        // call addSectionCandidateAnswers later in convertQuizDataToFormly such that the formly properties can be set directly
        // Ex.  in this function we set question.isDisabled = true
        //      then all the way in getFormlyTemplateOptions we set templateOptions.disabled = question.isDisabled
        //      Instead, this function should just set templateOptions.disabled directly

        const answers = sectionResults.answers || [];
        const user = Session.getUser();
        const userID = user.userID;
        const isSectionDisabled = sectionResults.sectionStatusID !== QuizResultStatuses.REQUIRES_ACTION || userID !== quizResults.quizTakerID;
        if (isSectionDisabled) {
            sectionQuestions.forEach(question => question.isDisabled = true);
        }

        answers.forEach(function setAnswerProperties(answer) {
            const question = sectionQuestions.find(question => Number(question.ID) === Number(answer.quizQuestionID));
            question.wasPrePopulated = true;
            switch (question.quizQuestionTypeID) {
                case QuizQuestionTypes.RADIO:
                case QuizQuestionTypes.CHECKBOX:
                case QuizQuestionTypes.RATING:
                case QuizQuestionTypes.TRANSITION_QUESTION:
                    answer.multiAnswer.forEach(function(multiAnswer) {
                        const answerOption = question.questionMultiAnswer.find(answerOption => Number(answerOption.ID) === Number(multiAnswer.quizQuestionMultiAnswerID)
                        );
                        answerOption.isSelected = answerOption && multiAnswer.candidateSelection;
                    });
                    break;
                case QuizQuestionTypes.DATE:
                    // another function below sets templateOptions.uibDatepickerOptions.initDate = question.defaultValue
                    question.defaultValue = answer.candidateAnswer;
                    break;
                case QuizQuestionTypes.ATTACH_FILE:
                    // used in getFormlyTemplateOptions
                    question.initialFiles = (answer.candidateFiles || []).map(({ fileCloudURL, fileGUID, fileName }) => ({
                        URL: fileCloudURL,
                        GUID: fileGUID,
                        filename: fileName
                    }));
                    break;
                default:
                    question.defaultValue = answer.candidateAnswer;
                    break;
            }
        });
    }

    function getMultipleRecipientsFields(promoDetails) {
        const quizResults = promoDetails.results;
        return [{
            key: 'candidate',
            type: 'customMultiSuggest',
            templateOptions: {
                multiRecipientsRequired: true,
                required: true,
                type: promoDetails.quiz.isSelectSelf ? SuggestsTypes.ANYONE : SuggestsTypes.ANY_COLLEAGUE,
                suggestTypeOptions: [{
                    id: 1,
                    name: 'Colleagues - ad hoc',
                    value: 'recipients'
                }, {
                    id: 2,
                    name: 'Colleagues - group',
                    value: 'group'
                }],
                // TODO: app_PROMO_QUIZ_QUESTION_MULTIPLE_RECIPIENT_LABEL should probably come from the db as promoDetails.quiz.candidateQuestionFormattedForDisplay
                label: promoDetails.quiz.candidateQuestionFormattedForDisplay || $translate.instant('app_PROMO_QUIZ_QUESTION_MULTIPLE_RECIPIENT_LABEL'),
                placeholderTranslateKey: 'app_PROMO_QUIZ_QUESTION_MULTIPLE_RECIPIENT_PLACEHOLDER',
                disabled: quizResults
            },
            data: {
                suggestTypeKey: 'promoSuggestType',
                multiKey: 'candidates'
            }
        }, {
            key: 'teamName',
            type: 'customTeamName',
            templateOptions: {
                label: promoDetails.quiz.teamNameQuestionFormattedForDisplay,
                placeholderTranslateKey: 'app_PROMO_QUIZ_QUESTION_TEAM_NAME_PLACEHOLDER', // 'Team Name'
                required: true,
                disabled: quizResults
            },
            data: {
                recipientKey: 'candidate',
                suggestTypeKey: 'promoSuggestType',
                multiKey: 'candidates'
            }
        }];
    }

    function getSingleRecipientFields(promoDetails) {
        const quizResults = promoDetails.results;
        return [{
            key: 'candidate',
            type: 'customSuggest',
            templateOptions: {
                type: promoDetails.quiz.isSelectSelf ? SuggestsTypes.ANYONE : SuggestsTypes.ANY_COLLEAGUE,
                label: promoDetails.quiz.candidateQuestionFormattedForDisplay,
                placeholderTranslateKey: 'app_PROMO_QUIZ_QUESTION_SINGLE_RECIPIENT_PLACEHOLDER', // Enter a candidate
                required: true,
                disabled: quizResults
            }
        }];
    }

    function getSectionQuestionsFields(sectionQuestions, promoDetails) {
        if (!(sectionQuestions && sectionQuestions.length)) {
            return [];
        }
        return sectionQuestions.map(question => ({
            key: question.ID,
            type: getFormlyFieldType(question, promoDetails),
            // This is what formly uses to initialize the form model (model[options.key] = options.defaultValue)
            defaultValue: question.defaultValue,
            templateOptions: getFormlyTemplateOptions(question, promoDetails),
            data: {
                promoDetails,
                wasPrePopulated: question.wasPrePopulated
            },
            validators: getValidators(question, promoDetails),
            asyncValidators: getAsyncValidators(question, promoDetails),
            modelOptions: getModelOptions(question, promoDetails)
        }));
    }

    function getFormlyFieldType(question) {
        switch (question.quizQuestionTypeID) {
            case QuizQuestionTypes.SHORT_ANSWER:
                return 'customInput';
            case QuizQuestionTypes.LONG_ANSWER:
                return 'customTextarea';
            case QuizQuestionTypes.RADIO: {
                const tooManyOptions = question.questionMultiAnswer.length > 7;
                return tooManyOptions ? 'customSelect' : 'customRadioInput';
            }
            case QuizQuestionTypes.CHECKBOX:
                return 'customMultiCheckbox';
            case QuizQuestionTypes.RATING:
                return 'customRating';
            case QuizQuestionTypes.PARTICIPANT:
                return 'customSuggest';
            case QuizQuestionTypes.DATE:
                return 'customCalendar';
            case QuizQuestionTypes.WINNER_SELECTOR:
                return 'customSuggest';
            case QuizQuestionTypes.RECOMMENDED_POINTS:
                return 'customIntInput';
            case QuizQuestionTypes.CERTIFICATE_DESCRIPTION:
                return 'customTextarea';
            case QuizQuestionTypes.CERTIFICATE_EXTRA_INFO:
                return 'customTextarea';
            case QuizQuestionTypes.ATTACH_FILE:
                return 'customFileUpload';
            case QuizQuestionTypes.TRANSITION_QUESTION:
                return 'customRadioInput';
            case QuizQuestionTypes.REFERENCE:
                return 'customInput';
        }
    }

    function getFormlyTemplateOptions(question, promoDetails) {
        const promoID = promoDetails.ID;
        const quizID = promoDetails.quiz.ID;
        const quizQuestionID = question.ID;

        const templateOptions = {
            label: question.questionFormattedForDisplay,
            cssClass: question.code && `question-code-${question.code}`,
            disabled: question.isDisabled,
            required: Boolean(question.isMandatory)
        };

        switch (question.quizQuestionTypeID) {
            case QuizQuestionTypes.SHORT_ANSWER:
            case QuizQuestionTypes.REFERENCE:
                return Object.assign(templateOptions, {
                    type: 'text',
                    minlength: question.minAnswer,
                    maxlength: question.maxAnswer
                });
            case QuizQuestionTypes.LONG_ANSWER:
            case QuizQuestionTypes.CERTIFICATE_DESCRIPTION:
            case QuizQuestionTypes.CERTIFICATE_EXTRA_INFO:
                return Object.assign(templateOptions, {
                    rows: 10,
                    minlength: question.minAnswer,
                    maxlength: question.maxAnswer
                });
            case QuizQuestionTypes.RADIO:
            case QuizQuestionTypes.CHECKBOX:
            case QuizQuestionTypes.RATING:
            case QuizQuestionTypes.TRANSITION_QUESTION:
                return Object.assign(templateOptions, {
                    options: question.questionMultiAnswer,
                    labelProp: 'answerOption',
                    valueProp: 'ID',
                    static: question.isDisabled
                });
            case QuizQuestionTypes.PARTICIPANT:
            case QuizQuestionTypes.WINNER_SELECTOR:
                return Object.assign(templateOptions, {
                    type: SuggestsTypes.ANYONE,
                });
            case QuizQuestionTypes.DATE:
                return Object.assign(templateOptions, {
                    format: 'mediumDate',
                    uibDatepickerOptions: {
                        minDate: question.minDate,
                        maxDate: question.maxDate,
                        initDate: question.initDate || question.defaultValue
                    }
                });
            case QuizQuestionTypes.RECOMMENDED_POINTS:
                return Object.assign(templateOptions, {
                    min: question.minAnswer,
                    max: question.maxAnswer,
                });
            case QuizQuestionTypes.ATTACH_FILE: {
                return Object.assign(templateOptions, {
                    initialFiles: question.initialFiles,
                    showSubmittedView: question.initialFiles != null,
                    validExtensions: FileUtils.getFileTypeGroupExtensions(question.fileTypeGroupID),
                    maxFileSize: question.maxAnswer,
                    xhrUploadOptions: {
                        endpoint: `/api/quiz-files`
                    }
                });
            }
            default:
                return Object.assign(templateOptions, {
                    type: null,
                });
        }
    }

    function getValidators(question) {
        const validators = {};
        if (QuestionTypeValidators.hasOwnProperty(question.quizQuestionValidationID)) {
            validators.typeValidator = QuestionTypeValidators[question.quizQuestionValidationID](question);
        }

        validators.serverSide = {
            expression: function(viewValue, modelValue, scope) {
                return true;
            },
            message: (viewValue, modelValue, scope) => scope.formState.serverError,
        }
        return validators;
    }

    function getAsyncValidators(question, promoDetails) {
        const asyncValidators = {};
        if (question.isUnique || question.isUniquePerUser || question.isUniquePerEntity) {
            const uniqueType = getUniqueType(question);
            const promoID = promoDetails.ID;
            const quizID = promoDetails.quiz.ID;
            const quizQuestionID = question.ID;
            const quizResults = promoDetails.results;
            const quizResultID = quizResults && quizResults.quizResultID;

            asyncValidators.isUnique = {
                expression(viewValue, modelValue, scope) {
                    const answer = modelValue || viewValue;
                    if (scope.to.disabled) {
                        return $q.resolve();
                    }
                    if (scope.options.data.wasPrePopulated && answer === scope.options.defaultValue) {
                        // If answer is unchanged from pre-populated value, allow pass
                        return $q.resolve();
                    }
                    return checkAnswerIsUnique({ uniqueType, answer, promoID, quizID, quizQuestionID, quizResultID });
                },
                message(viewValue, modelValue) {
                    const value = viewValue || modelValue;
                    switch (uniqueType) {
                        case 'user':
                            // 'You have already submitted {{ value }}'
                            return $translate.instant('app_PROMO_QUIZ_QUESTION_VALIDATE_UNIQUE_USER', { value });
                        case 'entity':
                        case 'company':
                        default:
                            // '{{ value }} has already been submitted'
                            return $translate.instant('app_PROMO_QUIZ_QUESTION_VALIDATE_UNIQUE_ENTITY_OR_COMPANY', { value });
                    }
                }
            };
        }
        return asyncValidators;
    }

    function getUniqueType(question) {
        if (question.isUniquePerUser) {
            return 'user';
        } else if (question.isUniquePerEntity) {
            return 'entity';
        } else {
            return 'company';
        }
    }

    function getModelOptions(question) {
        if (question.isUnique || question.isUniquePerUser || question.isUniquePerEntity || question.regularExpression) {
            return {
                debounce: {
                    default: 500,
                    blur: 0
                },
                updateOn: 'default blur'
            };
        }
        return {};
    }
}
