import angular from 'angular';

import '../../../../../core/core.module';

import deactivateUser from './deactivateUser.component';

export default angular.module('app.contacts.contactPanel.contactPanelTools.deactivateUser', [
    'app.core'
])
.component('deactivateUser', deactivateUser);
