import _ from 'lodash';

incentiveDetailsCtrl.$inject = ['ContactService', 'Session', 'QuizTypes', 'QuizResultStatuses'];

export default function incentiveDetailsCtrl(ContactService, Session, QuizTypes, QuizResultStatuses) {
    this.$onInit = () => {
        const vm = this;
        // vm.incentive - from component bindings

        vm.user = Session.getUser();

        vm.isTrainingQuiz = vm.incentive.quiz && vm.incentive.quiz.quizTypeID === QuizTypes.TRAINING;

        const quizResults = vm.incentive.results;
        const hasResults = !_.isEmpty(quizResults);

        if (hasResults) {
            vm.isSelf = vm.user.userID === quizResults.quizTakerID;

            if (!vm.isSelf) {
                ContactService.getContact(quizResults.quizTakerID)
                    .then(function(contact) {
                        vm.contact = contact;
                    });
            }

            if (vm.isTrainingQuiz) {
                vm.isIncompleteTrainingQuiz = quizResults.statusID === QuizResultStatuses.REQUIRES_ACTION;
            }
        }

        const datafeedReference = _.get(vm.incentive, 'datafeed.reference');
        if (datafeedReference) {
            // Reference, ID and GUID are all part of the View Metric Data link.
            // Reference is part of the Sherlock link.
            vm.showViewMetricDataLink = vm.incentive.datafeed.ID
                                        && vm.incentive.datafeed.GUID
                                        && vm.user.hasPermission('UploadMetrics');
            vm.showSherlockLink = vm.user.hasPermission('Sherlock');
        }
    };
}
