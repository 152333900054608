import angular from 'angular';

import '../../core/utils/coreUtils.module';

import adminFrameConfig from './adminFrame.config';
import adminFrame from './adminFrame.component';
import adminFrameCtrl from './adminFrame.controller';

export default angular.module('app.adminFrame', [
    'app.core'
])
.config(adminFrameConfig)
.component('adminFrame', adminFrame)
.controller('adminFrameCtrl', adminFrameCtrl);
