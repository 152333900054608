import angular from 'angular';

import reportColumnChooserService from './reportColumnChooserService.factory';
import reportColumnService from './reportColumnService.factory';

import 'angular-ui-bootstrap';

export default angular.module('app.dashboard.report.columnChooser', [
    'ui.bootstrap'
])
.factory('ReportColumnChooserService', reportColumnChooserService)
.factory('ReportColumnService', reportColumnService);
