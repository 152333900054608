import angular from 'angular';

import '../../../../core/core.module';

import forgotPasswordCtrl from './forgotPassword.controller';
import forgotPassword from './forgotPassword.directive';
import ForgotPasswordService from './forgotPasswordService.factory';

export default angular.module('app.nav.loginPanel.forgotPassword', [
    'app.core'
])
.controller('forgotPasswordCtrl', forgotPasswordCtrl)
.directive('forgotPassword', forgotPassword)
.factory('ForgotPasswordService', ForgotPasswordService);
