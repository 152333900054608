import _ from 'lodash';

import likedUsersModalTpl from '../../../core/directive/likedUsers/likedUsers.tpl.html';

likesModal.$inject = ['$uibModal'];

export default function likesModal($uibModal) {
    return {
        open
    };

    /**
     * @param {Object} options
     * @param {String} options.title - Should be a translate key
     * @param {String} options.body - Should be a translate key
     * @param {Object=} uibModalOptions
     */
    function open(options, uibModalOptions = {}) {
        
        return $uibModal.open(_.merge({
            templateUrl: likedUsersModalTpl,
            size: 'md',
            controller: function() {
                const vm = this;
                Object.assign(vm, options);
            },
            controllerAs: 'vm'
        }, uibModalOptions));
    }
}
