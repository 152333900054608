certificateDetailsCtrl.$inject = ['$scope', '$element', '$timeout', 'Session'];

export default function certificateDetailsCtrl($scope, $element, $timeout, Session) {
    this.$onInit = () => {
        const MAX_EXTRA_INFO_HEIGHT = 75;

        const vm = this;

        vm.user = Session.getUser();

        // TODO: un-nest SP outputJSON instead
        Object.assign(vm.certificate, vm.certificate.certificate);

        // To allow <legacy-message> to put a css class
        vm.certificate.messageTypeCode = vm.certificate.messageTypeCode || 'Certificate';

        vm.showCommentForm = true;

        if (vm.certificate.recipients && vm.certificate.recipients.length === 1) {
            vm.toContact = vm.certificate.recipients[0];
        }

        if (vm.certificate.extraInfo) {
            vm.certificate.extraInfo = decodeURIComponent(vm.certificate.extraInfo);
            $timeout(handleExtraInfo);
        }

        vm.isTeam = vm.certificate.teamName != null;

        /**
         * Determines if the extra info is too long. If so, clips the height and adds a "view more" button.
         */
        function handleExtraInfo() {
            const extraInfo = $element.find('.extra-info');
            const fullHeight = extraInfo.height();
            if (fullHeight <= MAX_EXTRA_INFO_HEIGHT) {
                return;
            }
            extraInfo.height(MAX_EXTRA_INFO_HEIGHT);
            vm.showExtraInfoButton = true;
            vm.expandExtraInfo = () => {
                extraInfo.height(fullHeight);
                vm.showExtraInfoButton = false;
            };
        }
    };
}
