import angular from 'angular';

import '../../core/utils/coreUtils.module';

import forumPageConfig from './forumPage.config';
import forum from './forum.component';
import forumPageCtrl from './forumPage.controller';

export default angular.module('app.forum', [
    'app.core'
])
    .config(forumPageConfig)
    .component('forum', forum)
    .controller('forumPageCtrl', forumPageCtrl);
