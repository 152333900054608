import _ from 'lodash';

datafeedSummaryCountWidgetCtrl.$inject = ['WidgetService', 'StringInterpolationService'];

export default function datafeedSummaryCountWidgetCtrl(WidgetService, StringInterpolationService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        const fields = vm.widget.data.fields;

        vm.hasData = !_.isEmpty(fields);
        if (!vm.hasData) {
            return;
        }

        vm.summaryTotal = _.sumBy(fields, field => field.value);
        vm.summaryLink = WidgetService.getSummaryLinkHref(vm.widget, vm.contact);

        const fieldLinks = _.get(vm.widget.data, 'extraJSON.fieldLinks');
        if (!_.isEmpty(fieldLinks)) {
            const interpolationVariables = {
                contactID: vm.contact.userID,
                branchID: vm.contact.userID
            };
            fields.forEach(field => {
                const fieldLink = fieldLinks.find(fieldLink => fieldLink.fieldName === field.fieldName);
                if (fieldLink) {
                    field.href = StringInterpolationService.interpolate(fieldLink.href, interpolationVariables);
                }
            });
        }
    };
}
