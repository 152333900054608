import angular from 'angular';

import '../../core/core.module';
import './recognitionTab/recognitionTab.module';

import landingConfig from './landing.config';
import LandingService from './landingService.factory';

export default angular.module('app.landing', [
    'app.core',
    'app.landing.recognitionLandingTab'
])
.config(landingConfig)
.factory('LandingService', LandingService);
