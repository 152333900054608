import angular from 'angular';

import '../../core/core.module';
import '../../../common/formly/formly.module';

import taxInfoForm from './taxInfoForm.component';
import taxInfoFormCtrl from './taxInfoForm.controller';
import taxInfoCtrl from './taxInfo.controller';
import TaxInfoService from './taxInfoService.factory';
import taxInfoConfig from './taxInfo.config';

export default angular.module('app.taxInfo', [
    'app.core',
    'common.formly'
])
.config(taxInfoConfig)
.component('taxInfoForm', taxInfoForm)
.controller('taxInfoFormCtrl', taxInfoFormCtrl)
.controller('taxInfoCtrl', taxInfoCtrl)
.factory('TaxInfoService', TaxInfoService);
