import reportModalTpl from './reportModal.tpl.html';
import reportModalCtrl from './reportModal.controller';

export default {
    templateUrl: reportModalTpl,
    controller: reportModalCtrl,
    controllerAs: 'vm',
    bindings: {
        model: "<"
    }
};
