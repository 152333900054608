// Main Styles
import '../less/main.less';

import 'jquery'; // jquery needs to be loaded at the top
import 'jquery-migrate/dist/jquery-migrate.min';
import '../../vendor/angular-masonry/angular-masonry-deps.js'; // TODO: figure out how to get angular-masonry dependencies to load from npm AND work with webpack

import angular from 'angular';
import 'angular-masonry';
import 'angular-animate';

// Add this from the github page: http://joshdmiller.github.io/angular-placeholders/
import '../../vendor/angular-placeholders/angular-placeholders.js';
import 'angular-loading-bar/build/loading-bar.min.js';
import 'angular-loading-bar/build/loading-bar.min.css';
import 'angular-sanitize';
import 'angular-touch';
import 'ngstorage';
import 'bootstrap';
import 'bootstrap-select';
import '@uirouter/angularjs';
import 'angular-ui-bootstrap';
import 'angular-cookies';
import 'messageformat/messageformat.js';
import 'messageformat/locale/fr.js';
import 'messageformat/locale/es.js';
import 'messageformat/locale/de.js';
import 'messageformat/locale/zh.js';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-handler-log';
import 'angular-ui-tinymce';
import 'angular-messages';
import 'api-check';
import 'angular-formly';
import 'angular-formly-templates-bootstrap';
import 'ng-infinite-scroll';
import 'angular-dynamic-locale';
import 'angular-pageslide-directive';
import 'babel-polyfill';
import 'randomcolor';
import 'ng-file-upload';
import 'lodash';
import 'deep-freeze';
import 'angular-filter';
import Promise from 'bluebird';
import 'moment';
import 'angular-moment';
import '../../vendor/angular-bluebird-promises/angular-bluebird-promises.js';
import 'angular-aria';
import '@iamadamjowett/angular-click-outside';
import '../../vendor/outline.js';
import 'bowser';
import 'angular-recaptcha';
import 'uppy';
import 'uppy/dist/uppy.css';
import 'bytes';
import 'eventemitter3';
import 'flat';
import 'slick-carousel';
import 'qs';
import 'globalize'; // needed for DevExpress; also needs to be loaded above it
import 'devextreme/integration/angular';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/data/custom_store';
import 'devextreme/ui/data_grid';
import 'devextreme/viz/chart';
import 'devextreme/viz/circular_gauge';
import 'devextreme/viz/bar_gauge';
import 'devextreme/viz/pie_chart';
import 'devextreme/viz/funnel';
import 'devextreme/viz/range_selector';


import 'modernizr';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
// TODO: figure out how to import this from customFileUpload.module.js but have customFileUpload.less override uppy


// shared modules
import coreModule from './core/core.module';
import blockModule from './blocks/blocks.module';
import commonModule from '../common/common.module';


// feature modules
import accountModule from './features/account/account.module';
import adminModule from './features/admin/admin.module';
import appcuesModule from './features/appcues/appcues.module';
import applauseModule from './features/applause/applause.module';
import authModule from './features/authentication/auth.module';
import changePasswordModule from './features/changePassword/changePassword.module';
import claimModule from './features/claim/claim.module';
import communicationModule from './features/communication/communication.module';
import contactsModule from './features/contacts/contacts.module';
import contactUsModule from './features/contactUs/contactUs.module';
import contentModule from './features/content/content.module';
import controlPanelModule from './features/controlPanel/controlPanel.module';
import datafeedModule from './features/datafeed/datafeed.module';
import debugModule from './features/debug/debug.module';
import faqModule from './features/faq/faq.module';
import forumModule from './features/forum/forum.module';
import gamesModule from './features/games/games.module';
import gamesFrameModule from './features/gamesFrame/gamesFrame.module';
import i18nModule from './features/i18n/i18n.module';
import inboxModule from './features/inbox/inbox.module';
import leaderboardModule from './features/leaderboard/leaderboard.module';
import legacyFrameModule from './features/legacyFrame/legacyFrame.module';
import loginAsModule from './features/loginAs/loginAs.module';
import milestonesModule from './features/milestones/milestones.module';
import navModule from './features/nav/nav.module';
import noAccessModule from './features/noAccess/noAccess.module';
import notificationManagementModule from './features/notificationManagement/notificationManagement.module';
import promoModule from './features/promo/promo.module';
import reloadableCardModule from './features/reloadableCard/reloadableCard.module';
import reportsModule from './features/reports/reports.module';
import rewardModule from './features/reward/reward.module';
import signOnTasksModule from './features/signOnTasks/signOnTasks.module';
import snapshotModule from './features/snapshot/snapshot.module';
import socialPostModule from './features/socialPost/socialPost.module';
import spotlightModule from './features/spotlight/spotlight.module';
import ssoModule from './features/sso/sso.module';
import submissionsModule from './features/submissions/submissions.module';
import suggestModule from './features/suggest/suggest.module';
import taxInfoModule from './features/taxInfo/taxInfo.module';
import toolsModule from './features/tools/tools.module';
import voucherClaimModule from './features/voucherClaim/voucherClaim.module';
import incentiveLibraryModule from './features/incentiveLibrary/incentiveLibrary.module';
import dashboardModule from './features/dashboard/dashboard.module';
import gameSSOModule from './features/gameSSO/gameSSO.module';
import adminFrameModule from './features/adminFrame/adminFrame.module';
import landingModule from './features/landing/landing.module';

// app.module imports
import appConfig from './app.config';
import appStateRun from './appState.run';
import cacheRun from './cache.run';
import fullStoryRun from './fullStory.run';
import appRun from './app.run';

import appCssClasses from './appCssClasses.directive';

import appCtrl from './app.controller';

global.Promise = Promise;

Promise.config({
    warnings: false,
    longStackTraces: process.env.NODE_ENV === 'development'
});

const dependencies = [
    // Angular modules
    'ngAnimate',
    'ngSanitize',
    'ngTouch',
    'ngCookies',
    'ngMessages',
    'ngAria',

    // 3rd-party modules
    'ngStorage',
    'infinite-scroll',
    'angular-loading-bar',
    'formly',
    'ui.router',
    'ui.bootstrap',
    'ui.tinymce',
    'ngFileUpload',
    'angular.filter',
    'angularMoment',
    'angular-click-outside',
    'vcRecaptcha',

    // From vendor folder
    'placeholders',
    'mwl.bluebird',

    // DevExpress
    'dx',

    // Shared modules
    coreModule.name,
    blockModule.name,
    commonModule.name,

    // Features
    accountModule.name,
    adminModule.name,
    appcuesModule.name,
    applauseModule.name,
    authModule.name,
    changePasswordModule.name,
    claimModule.name,
    communicationModule.name,
    contactsModule.name,
    contactUsModule.name,
    contentModule.name,
    controlPanelModule.name,
    datafeedModule.name,
    faqModule.name,
    debugModule.name,
    forumModule.name,
    gamesModule.name,
    gamesFrameModule.name,
    i18nModule.name,
    inboxModule.name,
    leaderboardModule.name,
    legacyFrameModule.name,
    loginAsModule.name,
    milestonesModule.name,
    navModule.name,
    noAccessModule.name,
    notificationManagementModule.name,
    promoModule.name,
    reloadableCardModule.name,
    reportsModule.name,
    rewardModule.name,
    signOnTasksModule.name,
    snapshotModule.name,
    socialPostModule.name,
    spotlightModule.name,
    ssoModule.name,
    submissionsModule.name,
    suggestModule.name,
    taxInfoModule.name,
    toolsModule.name,
    voucherClaimModule.name,
    incentiveLibraryModule.name,
    dashboardModule.name,
    gameSSOModule.name,
    adminFrameModule.name,
    landingModule.name,
];

export default angular.module('app', dependencies)
    .config(appConfig)
    .run(appStateRun)
    .run(cacheRun)
    .run(fullStoryRun)
    .run(appRun)
    .directive('appCssClasses', appCssClasses)
    .controller('appCtrl', appCtrl);
