import angular from 'angular';

import SocialPostPreviewService from './socialPostPreviewService.factory';
import socialPostPreviewCtrl from './socialPostPreview.controller';

// import dependencies
import '../../../core/core.module';

export default angular.module('app.socialPost.socialPostPreview', [
    'app.core',
])
.factory('SocialPostPreviewService', SocialPostPreviewService)
.controller('socialPostPreviewCtrl', socialPostPreviewCtrl);
