import angular from 'angular';

import '../../core/core.module';
import './leaderboardGrid/leaderboardGrid.module';

import leaderboardConfig from './leaderboard.config';
import LeaderboardService from './leaderboardService.factory';

export default angular.module('app.leaderboard', [
    'app.core',
    'app.leaderboard.leaderboardGrid'
])
.config(leaderboardConfig)
.factory('LeaderboardService', LeaderboardService);
