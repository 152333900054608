RnTranslationService.$inject = ['$q', '$translatePartialLoader', '$translate'];

export default function RnTranslationService($q, $translatePartialLoader, $translate) {
    return {
        loadPhrases
    };

    /**
     * Loads a set of Phrases from the API.
     * @param {string} cacheName - Name of the Cache to get phrases for (see Caches table in the DB)
     * @returns {Promise} - Resolves when Phrases are retrieved from server (or resolves immediately if already present)
     */
    async function loadPhrases(cacheName) {
        if ($translatePartialLoader.isPartAvailable(cacheName)) {
            return;
        }
        $translatePartialLoader.addPart(cacheName);
        return $translate.refresh();
    }
}
