import noAccessTpl from './noAccess.tpl.html';
import noAccessCtrl from './noAccess.controller';

export default {
    templateUrl: noAccessTpl,
    controller: noAccessCtrl,
    controllerAs: 'vm',
    bindings: {
        toState: '<'
    }
};
