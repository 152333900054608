import angular from 'angular';

import dropdownCtrl from './dropdown.controller';
import DropdownSelectionService from './dropdownSelectionService.factory';

export default angular.module('app.core.directives.rnDxGrid.filterModal.dropdown', [

])
.factory('DropdownSelectionService', DropdownSelectionService)
.controller('dropdownCtrl', dropdownCtrl);
