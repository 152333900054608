import leaderboardWidgetTpl from './leaderboardWidget.tpl.html';
import leaderboardWidgetCtrl from './leaderboardWidget.controller';


export default {
    templateUrl: leaderboardWidgetTpl,
    controller: leaderboardWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        leaderboard: '<',
        contact: '<',
        rankings: '<',
        size: '<'
    }
};
