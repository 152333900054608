// Separate config needed because of https://stackoverflow.com/questions/23658846/angular-ui-router-urlrouterprovider-when-not-working-anymore

snapshotUrlConfig.$inject = ['$urlRouterProvider'];

export default function snapshotUrlConfig($urlRouterProvider) {
    // TODO: create shared function for snapshotUrl.config.js, accountUrl.config.js, datafeedUrl.config.js, rewardUrl.config.js

    // When URL is just "/snapshot", go to own snapshot
    $urlRouterProvider.when(/^\/snapshot\/?$/i, ['$state', '$location', function($state, $location) {
        const params = $location.search();
        $state.go('snapshot', params);
        return true;
    }]);
}
