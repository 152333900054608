import * as angular from 'angular';
import pdfjsLib from 'pdfjs-dist/webpack';
import * as _ from 'lodash';

fileViewerCtrl.$inject = ['$window', '$sce', '$scope', 'Session', 'LangUtils', 'SkinUtils', '$timeout'];

export default function fileViewerCtrl($window, $sce, $scope, Session, LangUtils, SkinUtils, $timeout) {
    this.$onInit = () => {
        const vm = this;

        $window.isLoaded = false;
        vm.isLoaded = false;
        vm.uploads = $window.uploads;

        if (!(vm.uploads && vm.uploads.length)) {
            vm.noData = true;
            $window.isLoaded = true;
        } else {
            vm.scale = 1.5;
            vm.contents = [];
            vm.showUpload = '';
            vm.width = $window.innerWidth;
            vm.company = Session.getCompany();
            vm.subDomain = vm.company.subDomain;
            vm.langCode = LangUtils.getLang().toLowerCase();
            vm.skinCode = SkinUtils.getSkinCode();
            vm.viewport = {};
            vm.viewport.width = vm.width * 0.6;
            vm.viewport.height = vm.viewport.width * 1.5;
            vm.showUpload = vm.uploads[0].name;
            vm.isInit = true;
            vm.offsetArr = [];
            vm.uploads = vm.uploads.map(upload => {
                upload.candidateFiles = upload.candidateFiles.map((file) => {
                    file.fileExt = file.fileName.split('.').pop().toLowerCase();
                    if (['jpg', 'jpeg', 'png'].includes(file.fileExt)) {
                        file.imgSrc = $sce.trustAsResourceUrl(file.fileCloudURL);
                    }
                    file.isLoaded = true;
                    return file;
                });
                return upload;
            });
            vm.containsNoPDF = vm.uploads.every(upload => upload.candidateFiles.every((file) => file.fileExt !== 'pdf'));
            loadPdfFiles({ scale: vm.scale });
            vm.activeUpload = vm.uploads[0];
            vm.activeFile = vm.activeUpload.candidateFiles[0];

            if (vm.activeFile.fileExt === 'pdf') {
                vm.activePage = { pageIndex: 1, isDisplay: false, isLoaded: false };
            }

            if (vm.containsNoPDF) {
                $window.isLoaded = true;
                vm.isLoaded = true;
            }

            const onResize = _.debounce(() => {
                $scope.$applyAsync(() => {
                    if (vm.isLoaded) {
                        vm.width = $window.innerWidth;
                        alignTextLayer();
                        calculateOffsetArr();
                    }
                });
            }, 100);

            const onScroll = _.debounce(() => {
                $scope.$applyAsync(() => {
                    if (vm.isLoaded) {
                        findActiveFile($window.scrollY);
                    }
                });
            }, 50);

            angular.element($window).bind('resize', onResize);
            angular.element($window).bind('scroll', onScroll);
            $scope.$on('$destroy', () => {
                angular.element($window).off('resize', onResize);
                angular.element($window).off('scroll', onScroll);
            });

            vm.trustSrc = trustSrc;
            vm.scrollToUpload = scrollToUpload;
            vm.scrollToFile = scrollToFile;
            vm.scrollToPage = scrollToPage;
            vm.resizeFiles = resizeFiles;
            vm.reloadParentWindow = reloadParentWindow;
        }

        // changeInSize is either 1 or -1
        function resizeFiles (changeInSize) {
            if (vm.containsNoPDF) {
                if ((vm.viewport.width < 2500 && changeInSize > 0) || (vm.viewport.width > 500 && changeInSize < 0)) {
                    vm.viewport.width += 200 * changeInSize;
                }
            } else {
                loadPdfFiles({ scale: vm.scale + 0.3 * changeInSize });
            }
        }

        function reloadParentWindow () {
            $window.opener.reloadPromo();
            vm.hideReload = true;
        }

        function findActiveFile(scrollY) {
            const closestOffset = vm.offsetArr
                .reduce((prev, curr) => (Math.abs(curr.top - scrollY) < Math.abs(prev.top - scrollY) ? curr : prev));
            vm.activeUpload = vm.uploads[closestOffset.uploadIndex];
            vm.activeFile = vm.activeUpload.candidateFiles[closestOffset.fileIndex];
            if (closestOffset.type === 'page') {
                vm.activePage = vm.activeFile.pdfPages[closestOffset.pageIndex];
            }
        }

        function alignTextLayer() {
            vm.uploads.forEach(upload => {
                upload.candidateFiles.filter(file => file.fileExt === 'pdf').forEach(file => {
                    if (file.pdfPages && file.pdfPages.length) {
                        file.pdfPages.filter(page => page.isLoaded === true).forEach(page => {
                            const canvas = angular.element(`#canvas-${file.fileGUID}-page-${page.pageIndex}`);
                            if (canvas.offset() && canvas[0]) {
                                page.textLayerStyle = {
                                    left: `${canvas.offset().left}px`,
                                    top: `${canvas.offset().top}px`,
                                    width: `${canvas[0].width}px`,
                                    height: `${canvas[0].height}px`,
                                };
                            }
                        });
                    }
                });
            });
        }

        function calculateOffsetArr() {
            vm.offsetArr = [];
            vm.uploads.forEach((upload, uploadIndex) => {
                upload.candidateFiles.forEach((file, fileIndex) => {
                    if (file.fileExt === 'pdf') {
                        if (file.pdfPages && file.pdfPages.length) {
                            file.pdfPages.filter(page => page.isLoaded).forEach((page, pageIndex) => {
                                const canvas = angular.element(`#canvas-${file.fileGUID}-page-${page.pageIndex}`);
                                if (canvas.offset()) {
                                    vm.offsetArr.push({
                                        type: 'page',
                                        top: canvas.offset().top,
                                        uploadIndex: uploadIndex,
                                        fileIndex: fileIndex,
                                        pageIndex: pageIndex,
                                    });
                                }
                            });
                        }
                    } else {
                        const fileDiv = angular.element(`#file-${file.fileGUID}`);
                        if (fileDiv.offset()) {
                            vm.offsetArr.push({
                                type: 'file',
                                top: fileDiv.offset().top,
                                uploadIndex: uploadIndex,
                                fileIndex: fileIndex,
                            });
                        }
                    }
                });
            });
        }

        function scrollToUpload({ upload }) {
            vm.activeUpload = upload;
            vm.activeFile = vm.activeUpload.candidateFiles[0];
            if (vm.activeFile.fileExt === 'pdf') {
                vm.activePage = vm.activeFile.pdfPages[0];
            }
            const uploadDivOffset = angular.element(`#upload-${upload.ocrJobID}`).offset();
            if (uploadDivOffset) {
                $window.scrollTo({
                    top: uploadDivOffset.top - 100,
                    left: uploadDivOffset.left,
                    behavior: 'smooth',
                });
            }
        }

        function scrollToFile({ file }) {
            vm.activeFile = file;
            if (vm.activeFile.fileExt === 'pdf') {
                vm.activePage = vm.activeFile.pdfPages[0];
            }
            const fileDiv = angular.element(`#file-${file.fileGUID}`);
            $window.scrollTo({
                top: fileDiv.offset().top - 100,
                left: fileDiv.offset().left,
                behavior: 'smooth',
            });
        }

        function scrollToPage({ page }) {
            vm.activePage = page;
            const canvas = angular.element(`#canvas-${vm.activeFile.fileGUID}-page-${page.pageIndex}`);
            $window.scrollTo({
                top: canvas.offset().top - 100,
                left: canvas.offset().left,
                behavior: 'smooth',
            });
        }

        async function loadPdfFiles({ scale }) {
            scale = scale < 0.5 ? 0.5 : scale;
            scale = scale > 5 ? 5 : scale;
            vm.scale = scale;
            if (vm.isLoaded || vm.isInit) {
                vm.isLoaded = false;
                $window.isLoaded = false;
                const uploadPromises = vm.uploads.map(async (upload) => {
                    const filePromises = upload.candidateFiles.filter(file => file.fileExt === 'pdf').map(async (file) => {
                        if (!file.pdfDoc) {
                            try {
                                file.pdfDoc = await pdfjsLib.getDocument(file.fileCloudURL).promise;
                                file.isLoaded = true;
                            } catch (err) {
                                console.error(err);
                                file.isLoaded = true;
                                file.isFailedToLoad = true;
                                return;
                            }
                        }
                        if (!file.pdfPages) {
                            // if pdfPages don't exist, initiate pdfPages array for ng-repeat to populate empty canvases
                            file.pdfPages = [];
                            for (let pageIndex = 1; pageIndex <= file.pdfDoc.numPages; pageIndex++) {
                                file.pdfPages.push({ pageIndex, isDisplay: false, isLoaded: false });
                            }
                        } else {
                            // if pdfPages exist, just set isLoaded to false
                            file.pdfPages = file.pdfPages.map(page => {
                                page.isLoaded = false;
                                return page;
                            });
                        }
                        return displayPages(file, 1, 10);
                    });
                    return Promise.all(filePromises);
                });
                await Promise.all(uploadPromises);
                vm.isLoaded = true;
                $window.isLoaded = true;
                vm.isInit = false;
                await $timeout(() => {}, 200);
                alignTextLayer();
                calculateOffsetArr();
                findActiveFile($window.scrollY);
            }
        }

        async function displayPages(file, startIndex, endIndex) {
            const pagePromises = file.pdfPages
                .filter(page => page.pageIndex >= startIndex && page.pageIndex <= endIndex)
                .map(async (page) => {
                    page.isDisplay = true;
                    const pageDoc = await file.pdfDoc.getPage(page.pageIndex);
                    page.pageDoc = pageDoc;

                    const viewport = pageDoc.getViewport({ scale: vm.scale });
                    //
                    // Prepare canvas using PDF page dimensions
                    //
                    const canvas = angular.element(`#canvas-${file.fileGUID}-page-${page.pageIndex}`);
                    if (!canvas[0]) {
                        return;
                    }
                    const context = canvas[0].getContext('2d');

                    canvas[0].height = viewport.height;
                    canvas[0].width = viewport.width;
                    vm.viewport = viewport;

                    page.textLayerStyle = {
                        left: `${canvas.offset().left}px`,
                        top: `${canvas.offset().top}px`,
                        width: `${canvas[0].width}px`,
                        height: `${canvas[0].height}px`,
                    };

                    await pageDoc.render({
                        canvasContext: context,
                        viewport: viewport,
                    }).promise;
                    const textContent = await pageDoc.getTextContent();
                    const textLayer = angular.element(`#text-layer-${file.fileGUID}-page-${page.pageIndex}`);
                    // clean up any potential previous text layer with .empty();
                    textLayer.empty();
                    await pdfjsLib.renderTextLayer({
                        textContent: textContent,
                        container: textLayer[0],
                        viewport: viewport
                    });
                    page.isLoaded = true;
                });
            return Promise.all(pagePromises);
        }

        function trustSrc(src) {
            return $sce.trustAsResourceUrl(src);
        }
    };
}
