import _ from 'lodash';
import $ from 'jquery';
import FormAlreadySubmittingError from './FormAlreadySubmittingError';
import FormValidationError from './FormValidationError';

rnFormCtrl.$inject = ['$scope', '$timeout', '$q', '$exceptionHandler'];

export default function rnFormCtrl($scope, $timeout, $q, $exceptionHandler) {
    this.$onInit = () => {
        const vm = this;

        // This gives the parent controller access to rnFormCtrl.
        // It's done in the way below instead of specifying a shared scope (in rnForm.directive.js) to avoid conflicts
        // with variable names if there are multiple rn-form components under the same parent controller.
        vm.rnFormCtrl = vm;

        _.defaults(vm, {
            fields: [],
            submit: function() {
                return $q.resolve();
            },
            onValidating: _.noop,
            onValidationDone: _.noop,
            onSending: _.noop,
            onSubmitSuccess: function(res) {
                return res;
            },
            onSubmitFail: function(err) {
                throw err;
            },
            successDisplayTime: 2000,
            disableFieldsDuringSubmit: false,
            disableFieldsAfterSuccess: false,
        });

        let isSubmitting = false;

        vm.onSubmit = onSubmit;
        vm.checkFormValidity = checkFormValidity;
        vm.waitForFileUploads = waitForFileUploads;
        vm.addButton = addButton;

        initializeButtons();

        vm.fields = vm.fields.filter(_.isObject);

        vm.fields.forEach(function(field) {
            field.expressionProperties = field.expressionProperties || {};
            // We want to be able to disable/enable the fields of the form after it has rendered (via Edit button).
            // Need to add "disabled" to the expressionProperties of each field in order for it to be changed.
            // https://stackoverflow.com/a/32906647
            if (!field.expressionProperties['templateOptions.disabled']) {
                field.expressionProperties['templateOptions.disabled'] = function(viewValue, modelValue, scope) {
                    return scope.to.disabled || false;
                };
            }
        });

        // Disable submit/save buttons while async validators are running
        $scope.$watch(() => vm.form.$pending, function(pending) {
            if (!pending) {
                vm.onValidationDone();
                triggerButtonsReset(); // No async validators running, form should be submittable
                return;
            }
            const asyncValidatorsAreRunning = Object.keys(pending).length > 0;
            if (asyncValidatorsAreRunning) {
                vm.onValidating();
                triggerButtonsOnValidating();
            }
        }, true);

        // Formly doesn't seem to have a way to add a CSS class to the outer-most div of a field.
        $timeout(() => {
            vm.fields.forEach(field => {
                const $ngModelElement = $('#' + field.id);
                if (!$ngModelElement.length) {
                    return;
                }
                const $fieldContainer = $ngModelElement.closest('.formly-field');
                $fieldContainer.addClass(`formly-field-${field.key}`);
                if (field.templateOptions && field.templateOptions.cssClass) {
                    $fieldContainer.addClass(field.templateOptions.cssClass);
                }
            });
        });

        async function onSubmit() {
            if (isSubmitting) {
                throw new FormAlreadySubmittingError();
            }
            const formIsValid = checkFormValidity(); // Will highlight invalid fields
            if (!formIsValid) {
                throw new FormValidationError();
            }

            vm.onSending();
            triggerButtonsOnSend();
            isSubmitting = true;
            triggerFieldsOnSubmit();
            delete vm.form.success;

            if (vm.disableFieldsDuringSubmit) {
                disableFieldsDuringSubmit();
            }

            await waitForFileUploads();

            try {
                const res = await vm.submit(vm.model);
                vm.form.success = true;

                triggerButtonsOnSuccess();
                await $q.delay(vm.successDisplayTime);

                resetFieldsAfterSubmit();
                if (vm.disableFieldsDuringSubmit && !vm.disableFieldsAfterSuccess) {
                    enableFieldsAfterSubmit();
                } else if (!vm.disableFieldsDuringSubmit && vm.disableFieldsAfterSuccess) {
                    disableFields();
                }

                (async () => {
                    // Wrap in try/catch so that an error in the success callback doesn't trigger onSubmitFail.
                    try {
                        await vm.onSubmitSuccess(res, vm.model);
                    } catch (err) {
                        $exceptionHandler(err);
                    }
                })();

            } catch (err) {
                triggerButtonsOnFail();
                if (vm.disableFieldsDuringSubmit) {
                    enableFieldsAfterSubmit();
                }
                vm.onSubmitFail(err);

            } finally {
                isSubmitting = false;
                triggerFieldsOnSubmitFinish();
            }
        }

        /**
         * @returns {Promise}
         */
        function waitForFileUploads() {
            const fileQuestionsWithUploads = _.omitBy(vm.form.fileQuestions, fileQuestion => _.isEmpty(fileQuestion.uploads));
            const fileQuestionsUploads = _.mapValues(fileQuestionsWithUploads, fileQuestion => $q.all(fileQuestion.uploads));
            return $q.all(fileQuestionsUploads);
        }

        /**
         * @returns {boolean}
         */
        function checkFormValidity() {
            // Allows error/validation messages to show, see errorExistsAndShouldBeVisibleExpression in formly.run.js
            vm.form.$setSubmitted();

            return vm.fields.every(function(field) {
                if (!(field.formControl && field.formControl.$validate)) {
                    return true;
                }

                const noAsyncValidators = _.isEmpty(field.asyncValidators);
                if (noAsyncValidators) {
                    // Manually trigger all validator functions
                    field.formControl.$validate();
                }

                // (formly doesn't seem to expose anything that lets you run a function when the async validators finish)

                // If the field has async validators specified, formControl.$validate() would cause formControl.$valid to
                // become undefined while the async validators are running, so that's why it's only called when there are
                // no async validators.

                return field.formControl.$valid !== false;
            });
        }

        function resetFieldsAfterSubmit() {
            const resettableFields = vm.fields.filter(field => !field.hide && _.isFunction(field.resetModel));
            const fieldsToReset = resettableFields.filter(field => field.templateOptions && field.templateOptions.resetAfterSubmit);
            fieldsToReset.forEach(async field => {
                field.resetModel();
                if (!field.formControl) {
                    return;
                }
                // The below is a hack to prevent a bug where if a field is both required and resetAfterSubmit,
                // a validation message shows after submitting ("This field is required") when it really shouldn't.
                // For some reason after resetModel() is called, ~200ms later formly sets the field to $dirty again.
                let $dirty = field.formControl.$dirty;
                Object.defineProperty(field.formControl, '$dirty', {
                    get: () => $dirty,
                    set: () => $dirty = false
                });
                await $q.delay(200);
                delete field.formControl.$dirty;
                field.formControl.$dirty = $dirty;
            });
        }

        /**
         * Saves each field's "disabled" value so that it can be restored when enableFieldsAfterSubmit is called.
         */
        function disableFieldsDuringSubmit() {
            vm.fields
                .filter(field => !_.isEmpty(field.templateOptions))
                .forEach(field => {
                    field.preSubmitDisabled = field.templateOptions.disabled;
                    field.templateOptions.disabled = true;
                });
        }

        /**
         * Resets each field's "disabled" value to before disableFieldsDuringSubmit was called.
         */
        function enableFieldsAfterSubmit() {
            vm.fields
                .filter(field => !_.isEmpty(field.templateOptions))
                .forEach(field => {
                    field.templateOptions.disabled = field.preSubmitDisabled;
                    delete field.preSubmitDisabled;
                });
        }

        function disableFields() {
            vm.fields
                .filter(field => !_.isEmpty(field.templateOptions))
                .forEach(field => field.templateOptions.disabled = true);
        }

        function triggerFieldsOnSubmit() {
            vm.fields
                .filter(field => _.isFunction(field.onSubmit))
                .forEach(field => field.onSubmit());
        }

        function triggerFieldsOnSubmitFinish() {
            vm.fields
                .filter(field => _.isFunction(field.onSubmitFinish))
                .forEach(field => field.onSubmitFinish());
        }

        // Button functions
        // See rnFormSubmitButton for where the reset, onValidating, onSend, onSuccess, etc functions are coming from
        // ------------------------------------------------------------------------------------------------------------

        function initializeButtons() {
            vm.buttons = [];
        }

        function addButton(button) {
            button.reset();
            vm.buttons.push(button);
        }

        function triggerButtonsReset() {
            vm.buttons.forEach(button => button.reset());
        }

        function triggerButtonsOnValidating() {
            vm.buttons.forEach(button => button.onValidating());
        }

        function triggerButtonsOnSend() {
            vm.buttons.forEach(button => button.onSend());
        }

        function triggerButtonsOnSuccess() {
            vm.buttons.forEach(button => button.onSuccess());
        }

        function triggerButtonsOnFail() {
            vm.buttons.forEach(button => button.onFail());
        }
    };
}
