import { registerPalette, currentPalette } from 'devextreme/viz/palette';

widgetRun.$inject = ['$rootScope', 'AUTH_EVENTS', 'AuthStatus', 'Session'];

export default function widgetRun($rootScope, AUTH_EVENTS, AuthStatus, Session) {

    if (AuthStatus.isAuthenticated()) {
        const user = Session.getUser();
        const company = Session.getCompany();

        if (user.colorPalette || company.defaultColorPalette) {
            registerPalette('CustomPalette', user.colorPalette || company.defaultColorPalette);
            currentPalette('CustomPalette');
        } else {
            currentPalette('Soft Pastel');
        }
    }

    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, function(event, { company, user }) {
        if (user.colorPalette || company.defaultColorPalette) {
            registerPalette('CustomPalette', user.colorPalette || company.defaultColorPalette);
            currentPalette('CustomPalette');
        } else {
            currentPalette('Soft Pastel');
        }
    });
}
