import recipientsListTpl from './recipientsList.tpl.html';

customMultiSuggestRun.$inject = ['$translate', 'formlyConfig'];

export default function customMultiSuggestRun($translate, formlyConfig) {

    formlyConfig.setWrapper([{
        name: 'recipientsList',
        templateUrl: recipientsListTpl
    }]);
    formlyConfig.setType({
        name: 'customMultiSuggest',
        extends: 'customSuggest',
        wrapper: ['validation', 'recipientsList', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            expressionProperties: {
                'templateOptions.required': function(viewValue, modelValue, scope) {
                    const multiKey = scope.options.data.multiKey;
                    const multiKeyLength = scope.model[multiKey].length;
                    if (scope.fc) {
                        if (multiKeyLength >= 1) {
                            scope.fc.$pristine = false;
                            // set required to true because the user has already made a selection
                            return scope.fc.$setValidity('required', true);
                        } else if ((scope.fc.$touched || scope.fc.$pristine) && multiKeyLength < 1) {
                            return scope.fc.$setValidity('required', false);
                        } else {
                            scope.fc.$pristine = false;
                            return scope.fc.$setValidity('required', true);
                        }
                    }
                }
            },
            validators: {
                multiRecipientsRequired: {
                    expression: ($viewValue, $modelValue, scope) => {
                        const to = scope.to;
                        const multiKey = scope.options.data.multiKey;

                        if (to.multiRecipientsRequired) {
                            return scope.model[multiKey].length >= 2;
                        } else {
                            return true;
                        }
                    },
                    message: () => $translate.instant('app_CUSTOM_MULTI_SUGGEST_VALIDATION_MINIMUM_CANDIDATES')
                }
            },
            validation: {
                messages: {
                    // 'User already selected!'
                    duplicate: () => $translate.instant('app_CUSTOM_MULTI_SUGGEST_VALIDATION_DUPLICATE'),
                    garbageValue: () => $translate.instant('app_CUSTOM_MULTI_SUGGEST_VALIDATION_INVALID_SELECTION')
                }
            }
        },
        controller: ['$scope', function($scope) {
            const options = $scope.options;
            const multiKey = $scope.options.data.multiKey; // key where selected items will be pushed to (array)
            $scope.checkForDuplicateValueInMultiKey = function(id) {
                let isDuplicate = false;
                $scope.model[multiKey].forEach(function(entry) {
                    if (entry.ID === id) {
                        isDuplicate = true;
                    }
                });
                return isDuplicate;
            };

            $scope.$watch('model[options.key]', function(newValue) {
                // if scope.fc is not defined an error will be thrown
                if ($scope.fc) {
                    if (newValue && newValue.ID) {
                        $scope.fc.$setValidity('typeAhead', true);
                        const isDuplicate = $scope.checkForDuplicateValueInMultiKey(newValue.ID);

                        $scope.fc.$setValidity('duplicate', !isDuplicate);

                        // Adding recipients
                        if (!isDuplicate && newValue.ID !== -1  && newValue.ID !== '-1') {
                            $scope.model[multiKey].push($scope.model[options.key]);
                            $scope.model[options.key] = ''; // triggers another watch cycle where newValue === ""
                        }
                        if (newValue.ID === -1 || newValue.ID === '-1') {
                            $scope.fc.$setValidity('garbageValue', false);
                        } else {
                            $scope.fc.$setValidity('garbageValue', true);
                        }
                    } else {
                        const multiKeyLength = $scope.model[multiKey].length;
                        // won't be a duplicate because newValue is not an object
                        $scope.fc.$setValidity('duplicate', true);
                        $scope.fc.$setValidity('typeAhead', true);
                        $scope.fc.$setValidity('garbageValue', true);
                        if (multiKeyLength === 0 && newValue && $scope.fc.$dirty) {
                            $scope.fc.$setValidity('typeAhead', false);
                        }
                    }
                }
                // return to prevent infinite loop (cross tab issue when someone logs out in one tab)

            });

            $scope.removeRecipient = function(id) {
                for (let i = 0; i < $scope.model[multiKey].length; i++) {
                    if ($scope.model[multiKey][i].ID === id) {
                        $scope.model[multiKey].splice(i, 1);
                        break;
                    }
                }
            };
        }]
    });
}
