import $ from 'jquery';
import incentiveCardService from './incentiveCardService.factory';

incentiveCardCtrl.$inject = ['$scope', '$state', '$stateParams', 'UrlUtils', 'LangUtils', '$timeout', 'Session', 'incentiveCardService'];

export default function incentiveCardCtrl($scope, $state, $stateParams, UrlUtils, LangUtils, $timeout, Session, incentiveCardService) {
    this.$onInit = () => {
        const vm = this;
        vm.user = Session.getUser();
        vm.listCode = $stateParams.promoLibraryTypeCode;
        vm.isShowAdminLinks = vm.user.hasPermission('SavePublicIncentiveLibraryEntry') || vm.listCode === 'personal';


        vm.model = {
            editForm: vm.listItem.promoLibraryID === $stateParams.promoLibraryID,
            newItemName: '',
            newItemAnchor: '',
            newExamples: '',
            formSubmitted: false,
            listItem: vm.listItem,
            exampleShow: false,
            showDeletePopup: false,
            showAdminOptions: false
        };

        updateEditFormValues(vm.model);

        vm.examplesToggle = examplesToggle;

        vm.incentiveClone = incentiveClone;

        vm.adminOptionsToggle = adminOptionsToggle;

        vm.deletePopupShow = deletePopupShow;
        vm.deletePopupHide = deletePopupHide;
        vm.incentiveLibraryDelete = incentiveLibraryDelete;

        vm.editFormShow = editFormShow;
        vm.incentiveLibrarySave = incentiveLibrarySave;

        vm.promoCardImage = getPromoImageUrl(vm.listItem);


        // TODO: tinymcs should be a shared module
        vm.tinymceOptions = {
            contextmenu:'',
            plugins: 'code image hr imagetools fullscreen',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | fullscreen',
            valid_children: '+body[style],+a[h1|h2|h3|h4|h5|h6|p|span|div|img]',
            extended_valid_elements: '+div[*],+a[*],+span[*]',
            height: '180',
            content_css: '//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
        };

        if (vm.model.listItem.examples) {
            const $exampleContent = $('<div />').append(vm.model.listItem.examples);
            // Script tags need to be handled separately (added to document head instead of the ng-bind-html div).
            const $scripts = $exampleContent.find('script');
            $timeout(() => loadScripts($scripts));
            $scripts.remove();
            vm.model.listItem.examples = $exampleContent.html();
        }

        function loadScripts($scripts) {
            Array.from($scripts).forEach(loadScript);
        }

        /**
         * Loads and executes a developer content <script> tag.
         */
        async function loadScript({ src, innerHTML }) {
            const script = document.createElement('script');
            script.async = false; // Important - this is what makes dynamically added scripts execute in order.
            if (src) {
                script.src = src;
            }
            if (innerHTML) {
                // Prevent scripts from creating or overriding global variables.
                script.innerHTML = `(function() { ${innerHTML} })();`;
            }
            document.head.appendChild(script);
            $scope.$on('$destroy', () => {
                document.head.removeChild(script);
            });
            await $timeout(0); // Let the script execute
        }


        function examplesToggle(model) {
            model.exampleShow = !model.exampleShow;
        }

        function adminOptionsToggle(model) {
            model.showAdminOptions = !model.showAdminOptions;
            model.editForm = false;
            model.showDeletePopup = false;
        }

        function editFormShow(model) {
            model.editForm = true;
            model.showAdminOptions = false;
            updateEditFormValues(model);
        }

        function updateEditFormValues(model) {
            model.newItemName = model.listItem.name;
            model.newItemAnchor = model.listItem.anchor;
            model.newExamples = model.listItem.examples;

        }

        function deletePopupShow(model) {
            model.showDeletePopup = true;
            model.showAdminOptions = false;
        }

        function deletePopupHide(model) {
            model.showDeletePopup = false;
            model.showAdminOptions = false;
        }

        function getPromoImageUrl(promo) {
            const imagePath = getPromoImagePath();
            return `${imagePath}/${promo.imageName}`;
        }

        function getPromoImagePath() {
            const company = Session.getCompany();
            const cloudPublicURL = company.cloudRootURLs.public;
            const promoImagesCloudContainerType = 11;
            const languageCode = LangUtils.getLang().toUpperCase(); // company.defaultLanguageCode; //company.languageCode.toUpperCase()||
            const promoImagesCloudSubFolder = company.cloudContainers
                .find(cloudContainer => cloudContainer.typeID === promoImagesCloudContainerType);
            const promoImagesCloudSubFoldername = promoImagesCloudSubFolder.name;
            return `${cloudPublicURL}/${promoImagesCloudSubFoldername}/${languageCode}`;
        }

        function incentiveClone(incentiveID) {
            incentiveCardService.incentiveClone({
                incentiveID
            }).then(function(clonedIncentive) {
            $state.go('incentiveEdit', { id: clonedIncentive.promoID });
            }).error(function(err) {
                console.log(err);
            });
        }


        function incentiveLibrarySave() {
            const data = {
                NameText: vm.model.newItemName,
                AnchorText: vm.model.newItemAnchor,
                EXAMPLETEXT: vm.model.newExamples
            };

            const params = {
                promoLibraryID: vm.listItem.promoLibraryID,
                code: vm.listCode
            };

            return incentiveCardService.incentiveLibrarySave(params, data)
                .then(function () {
                    vm.model.formSubmitted = true;
                    vm.model.listItem.name = vm.model.newItemName;
                    vm.model.listItem.anchor = vm.model.newItemAnchor;
                    vm.model.listItem.examples = vm.model.newExamples;
                    vm.model.editForm = false;
                    vm.model.exampleShow = true;
                });
        }


        function incentiveLibraryDelete(code, promoLibraryID) {
            incentiveCardService.incentiveLibraryDelete({
                code,
                promoLibraryID
            }).then(function(data) {
                // remove library card entry
                vm.onDeleteSuccess({ promoLibraryID: promoLibraryID });

            }).error(function(err) {
                console.log(err);
            });
        }
    };
}
