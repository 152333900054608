import $ from 'jquery';

incentiveLibraryListCtrl.$inject = ['$state', '$timeout', 'LangUtils', 'Session', '$scope', '$stateParams', '$translate',
    'libraryPromoList'];

export default function incentiveLibraryListCtrl($state, $timeout, LangUtils, Session, $scope, $stateParams, $translate,
    libraryPromoList) {
    const vm = this;

    vm.user = Session.getUser();
    vm.promoLibraryTypeCode = $stateParams.promoLibraryTypeCode;
    vm.libraryPromoList = libraryPromoList.List;

    vm.onItemDeleteSuccess = function({ promoLibraryID }) {
        vm.libraryPromoList = vm.libraryPromoList.filter(libraryPromoCard => libraryPromoCard.promoLibraryID !== promoLibraryID);
    };
}
