validationConfig.$inject = ['$stateProvider'];

export default function validationConfig($stateProvider) {
    $stateProvider.state('fileViewer', {
        url: '/file-viewer?quizResultID&timeStamp',
        views: {
            'main@': {
                template: '<file-viewer></file-viewer>'
            }
        },
        data: {
            pageTitle: 'file-Viewer',
            isPublicPage: true,
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('fileViewer');
            }]
        }
    });
}
