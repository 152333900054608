datafeedCtrl.$inject = ['gridSetup', 'contact', '$state', 'DatafeedService', 'PageTitle', 'Session'];

export default function datafeedCtrl(gridSetup, contact, $state, DatafeedService, PageTitle, Session) {
    const vm = this;

    PageTitle.set(gridSetup.title);

    vm.gridSetup = gridSetup;
    vm.contact = contact;
    vm.user = Session.getUser();

    vm.codeName = $state.params.codeName;
    vm.isHierarchy = $state.current.data.isHierarchy;
    vm.isFull = vm.user.hasPermission('ViewFullDatafeed');
    vm.contactID = Number($state.params.contactID);

    vm.initialFilters = $state.params.filtersArray;
    vm.initialColumns = $state.params.columnsObject;
    vm.initialSort = $state.params.sortArray;
    vm.updateUrlParams = true;
    vm.settingsSlot = $state.params.settingsSlot;
}
