ForgotPasswordService.$inject = ['$http'];

export default function ForgotPasswordService($http) {
    return {
        sendForgotPasswordForm
    };

    /**
     * @param {Oject} fields
     * @param {string} fields.userLookup
     */
    function sendForgotPasswordForm(fields) {
        return $http.post('/api/reset-password', fields);
    }
}
