import _ from 'lodash';

import simpleModalTpl from './simpleModal.tpl.html';

SimpleModal.$inject = ['$uibModal'];

export default function SimpleModal($uibModal) {
    return {
        open
    };

    /**
     * @param {Object} options
     * @param {String} options.title - Should be a translate key
     * @param {String} options.body - Should be a translate key
     * @param {Object=} uibModalOptions
     */
    function open(options, uibModalOptions = {}) {
        return $uibModal.open(_.merge({
            templateUrl: simpleModalTpl,
            size: 'md',
            controller: function() {
                const vm = this;
                Object.assign(vm, options);
            },
            controllerAs: 'vm'
        }, uibModalOptions));
    }
}
