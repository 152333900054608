import $ from 'jquery';

appCtrl.$inject = ['$rootScope', '$transitions', '$window', '$translate', 'Upload', 'PageTitle'];

export default function appCtrl($rootScope, $transitions, $window, $translate, Upload, PageTitle) {
    const vm = this;

    $transitions.onStart({}, function(transition) {
        const toState = transition.to();
        // TODO: currently all hardcoded in English, will have to think about how to dynamically set according to language
        if (toState.data && toState.data.pageTitle) {
            PageTitle.set(toState.data.pageTitle);
        }
    });

    // TODO: this should be put in a more specific file related to uploading. Also copy and pasted from customFileUpload.run.js
    $($window).on('beforeunload', function confirmWindowExit(event) {
        if (!Upload.isUploadInProgress()) {
            return;
        }
        // Note - a lot of browsers prevent custom dialog text, different text may show.
        // 'File upload is in progress. Are you sure?'
        return event.returnValue = $translate.instant('app_UPLOAD_IN_PROGRESS_EXIT_WARNING');
    });
}
