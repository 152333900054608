import recognitionTabTpl from './recognitionTab.tpl.html';
import recognitionTabCtrl from './recognitionTab.controller';

export default {
    templateUrl: recognitionTabTpl,
    controller: recognitionTabCtrl,
    controllerAs: 'vm',
    bindings: {
        landingSetup: '<',
        content: '<',
        program: '<',
        role: '<',
        code: '<',
    }
};
