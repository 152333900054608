import customReportFormModalTpl from './customReportFormModal.tpl.html';

CustomReportFormModal.$inject = ['$uibModal', 'ReportsService'];

export default function CustomReportFormModal($uibModal, ReportsService) {

    function open({ report, onSubmitSuccess }) {
        return $uibModal.open({
            templateUrl: customReportFormModalTpl,
            controller: ['timePeriods', function(timePeriods) {
                const vm = this;
                vm.report = report;
                vm.timePeriods = timePeriods;
                vm.onSubmitSuccess = onSubmitSuccess;
            }],
            controllerAs: 'vm',
            resolve: {
                timePeriods: ReportsService.getCustomTimePeriods({ gridReportID: report.ID }),
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('reports');
                }]
            }
        });
    }

    return {
        open
    };
}
