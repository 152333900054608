PointExpiryService.$inject = ['$http'];

export default function PointExpiryService($http) {
    async function updateNotification(type, value) {
        return $http.put(`/api/point-tracking/expiring-forecast/toggle-notifications/${type}`, {
            notificationValue: value
        });
    }

    return {
        updateNotification
    };
}
