import angular from 'angular';

import '../legacyFrame/legacyFrame.module';

import controlPanelConfig from './controlPanel.config';
import controlPanelCtrl from './controlPanel.controller';

export default angular.module('app.controlPanel', [
    'app.legacyFrame'
])
.config(controlPanelConfig)
.controller('controlPanelCtrl', controlPanelCtrl);
