
PromoQuizSectionService.$inject = ['$http'];

export default function PromoQuizSectionService($http
) {
    return {
        submitQuizSection,
    };

    function submitQuizSection({ promoID, quizID, sectionID, quizResultID, quizAnswers, isRequestApproval }) {
        return $http.post(`/api/promos/${promoID}/quiz/${quizID}/submissions`, {
            quizAnswers,
            sectionID,
            quizResultID,
            isRequestApproval,
        });
    }
}
