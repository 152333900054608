import noAccessModalTpl from './noAccessModal.tpl.html';

NoAccessModalService.$inject = ['$uibModal'];

export default function NoAccessModalService($uibModal) {
    return {
        open
    };

    function open(toState) {
        return $uibModal.open({
            size: 'md',
            controller: 'noAccessModalCtrl',
            controllerAs: 'vm',
            templateUrl: noAccessModalTpl,
            resolve: {
                toState: () => toState
            }
        });
    }
}
