authConfig.$inject = ['$stateProvider'];

export default function authConfig($stateProvider) {
    $stateProvider.state('auth', {
        parent: 'nav',
        url: '',
        abstract: true,
        controller: 'authCtrl',
    });
}
