import _ from 'lodash';
import moment from 'moment';

metricSnapshotWidgetCtrl.$inject = ['ChartWidgetService', 'WidgetService'];

export default function metricSnapshotWidgetCtrl(ChartWidgetService, WidgetService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.hasData = ChartWidgetService.defaultHasDataCheck(vm.widget);
        if (!vm.hasData) {
            return;
        }

        const monthLineChartConfig = ChartWidgetService.createMonthLineChartConfig(vm.widget.data);

        const tooltipDisplayType = _.get(vm.widget.data, 'extraJSON.tooltip.displayType');
        const formatTooltipValue = (value) => WidgetService.formatTooltipValue({
            displayType: tooltipDisplayType,
            value
        });

        vm.chartConfig = _.merge(monthLineChartConfig, {
            adaptiveLayout: {
                // To prevent it from hiding the legend.
                width: 0,
                height: 0
            },
            tooltip: {
                customizeTooltip: (arg) => ({
                    text: `${moment(arg.argument).format('MMMM')} ${arg.seriesName}: ${formatTooltipValue(arg.value)}`
                })
            }
        });
    };
}
