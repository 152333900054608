import angular from 'angular';

// before adding a custom filter please check the angular documentation and the angular-filter library
// https://github.com/a8m/angular-filter

import trustUrl from './trustUrl.filter';
import trustAsHtml from './trustAsHtml';
import formatIfNumber from './formatIfNumber.filter';
import formatForCode from './formatForCode.filter';

export default angular.module('app.core.filters', [

])
.filter('trustUrl', trustUrl)
.filter('trustAsHtml', trustAsHtml)
.filter('formatIfNumber', formatIfNumber)
.filter('formatForCode', formatForCode);
