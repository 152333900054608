import moment from 'moment';

pointExpiryCtrl.$inject = ['Session', 'PointExpirationNotificationPeriods', 'PointExpiryService'];

export default function pointExpiryCtrl(Session, PointExpirationNotificationPeriods, PointExpiryService) {
    this.$onInit = () => {
        const vm = this;

        const user = Session.getUser();

        const expirationPointsLength = vm.pointExpirationDetails && vm.pointExpirationDetails.expirationPoints
            && vm.pointExpirationDetails.expirationPoints.length;

        vm.isPointExpirationNotify7Days = user.isPointExpirationNotify7Days;
        vm.isPointExpirationNotifyMonthly = user.isPointExpirationNotifyMonthly;
        vm.isPointExpirationNotify60Days = user.isPointExpirationNotify60Days;

        if (expirationPointsLength
                && (vm.pointExpirationDetails.expirationPoints[0].daysFrom
                && vm.pointExpirationDetails.expirationPoints[0].daysTo)) {
            vm.showPointExpiryTable = true;
        }

        vm.lastExpiryDay = getLastExpiryDay();
        if (expirationPointsLength && (vm.pointExpirationDetails.expirationPoints[0].expiresOn)) {
            vm.formattedOnDate = moment(vm.pointExpirationDetails.expirationPoints[0].expiresOn).format('MMMM D, YYYY');
        }

        if (vm.contactId === user.userID) {
            if (vm.showPointExpiryTable) {
                vm.show30DaysToggleButton = true;
            } else {
                vm.show7DaysToggleButton = true;
                vm.show60DaysToggleButton = true;
            }
        }

        vm.toggleMonthly = () => {
            vm.monthlyDaysPromise = updateNotification(
                PointExpirationNotificationPeriods.MONTHLY,
                !vm.isPointExpirationNotifyMonthly)
                .then(function() {
                    vm.isPointExpirationNotifyMonthly = !vm.isPointExpirationNotifyMonthly;
                    const isPointExpirationNotifyMonthly = vm.isPointExpirationNotifyMonthly;
                    const userWithUpdatedIsPointExpirationNotifyMonthly = Object.assign(user, {
                        isPointExpirationNotifyMonthly
                    });
                    Session.setUser(userWithUpdatedIsPointExpirationNotifyMonthly);
                });
        };

        vm.toggleSeven_Days = () => {
            vm.sevenDaysPromise = updateNotification(
                PointExpirationNotificationPeriods.SEVEN_DAYS,
                !vm.isPointExpirationNotify7Days
            ).then(function() {
                vm.isPointExpirationNotify7Days = !vm.isPointExpirationNotify7Days;
                const isPointExpirationNotify7Days = vm.isPointExpirationNotify7Days;
                const userWithUpdatedIsPointExpirationNotify7Days = Object.assign(user, {
                    isPointExpirationNotify7Days
                });
                Session.setUser(userWithUpdatedIsPointExpirationNotify7Days);
            });
        };

        vm.toggleSixty_Days = () => {
            vm.sixtyDaysPromise = updateNotification(
                PointExpirationNotificationPeriods.SIXTY_DAYS,
                !vm.isPointExpirationNotify60Days
            ).then(function() {
                vm.isPointExpirationNotify60Days = !vm.isPointExpirationNotify60Days;
                const isPointExpirationNotify60Days = vm.isPointExpirationNotify60Days;
                const userWithUpdatedIsPointExpirationNotify60Days = Object.assign(user, {
                    isPointExpirationNotify60Days
                });
                Session.setUser(userWithUpdatedIsPointExpirationNotify60Days);
            });
        };

        function updateNotification(type, value) {
            return PointExpiryService.updateNotification(type, value);
        }

        function getLastExpiryDay() {
            if (expirationPointsLength > 1) {
                return vm.pointExpirationDetails.expirationPoints[expirationPointsLength - 1].daysTo;
            }
        }
    };
}
