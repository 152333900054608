import angular from 'angular';

import '../../../core/core.module';

import submissionsGrid from './submissionsGrid.component';

export default angular.module('app.submissions.submissionsGrid', [
    'app.core'
])
.component('submissionsGrid', submissionsGrid);
