

/**
 * Determines which "range" number the value belongs to if total were to be divided into even divisions.
 *
 * Ex. total = 1000, divisions = 5, value = 250 --> 2
 * Ex. total = 1000, division = 5, value = 700 --> 4
 *
 * <= 199        = range number 1
 * 200 - 399     = range number 2
 * 400 - 599     = range number 3
 * 600 - 799     = range number 4
 * 800 - 999    = range number 5
 * >= 1000        = range number 6
 *
 * @param {number} total
 * @param {number} divisions
 * @param {number} value
 * @returns {number}
 */
function calculateRangeNumber(total, divisions, value) {
    if (value >= total) {
        return divisions + 1;
    }
    const rangeSize = total / divisions;
    for (let i = 1; i <= divisions; i++) {
        const rangeLimit = rangeSize * i;
        if (value <= rangeLimit) {
            return i;
        }
    }
}

module.exports = calculateRangeNumber;
