import angular from 'angular';

import NoAccessModalService from './noAccessModalService.factory';
import noAccessModalCtrl from './noAccessModal.controller';

import '../../../core/core.module';

export default angular.module('app.noAccess.noAccessModal', [
    'app.core',
])
.factory('NoAccessModalService', NoAccessModalService)
.controller('noAccessModalCtrl', noAccessModalCtrl);
