import angular from 'angular';

import trainingQuizRun from './trainingQuiz.run';
import TrainingQuizHttpService from './trainingQuizHttpService.factory';
import TrainingQuizService from './trainingQuizService.factory';
import TrainingQuizModal from './trainingQuizModal/trainingQuizModal.factory';
import trainingQuizQuestion from './trainingQuizQuestion/trainingQuizQuestion.component';
import trainingQuizResults from './trainingQuizResults/trainingQuizResults.component';

export default angular.module('app.promo.trainingQuiz', [

])
.run(trainingQuizRun)
.factory('TrainingQuizHttpService', TrainingQuizHttpService)
.factory('TrainingQuizService', TrainingQuizService)
.factory('TrainingQuizModal', TrainingQuizModal)
.component('trainingQuizQuestion', trainingQuizQuestion)
.component('trainingQuizResults', trainingQuizResults);
