import _ from 'lodash';

GridUrlColumns.$inject = ['$location', 'StateUtils'];

export default function GridUrlColumns($location, StateUtils) {

    /*
        This service is for hiding/showing columns of a grid using a special string format in the URL.
        The URL is also updated as the user toggles column visibilities with the column chooser tool.
     */

    const DEFAULT_PARAM_NAME = 'columns';

    const COLUMN_SEPARATOR = '__';
    const VISIBLE_SYMBOL = 'show--';
    const HIDDEN_SYMBOL = 'hide--';

    const columnStringRegex = new RegExp(`((?:${_.escapeRegExp(VISIBLE_SYMBOL)})|(?:${_.escapeRegExp(HIDDEN_SYMBOL)}))(\\w+)`);

    return {
        updateUrl,
        parseUrl
    };

    /**
     * @param {Object[]} columns
     * @param {string} [paramName = "columns"]
     * @param {Object[]} visibleColumns
     */
    function updateUrl(columns, paramName = DEFAULT_PARAM_NAME, visibleColumns) {
        const changedColumns = determineChangedColumns(columns, visibleColumns);
        const changedColumnsString = stringifyChangedColumns(changedColumns);
        StateUtils.updateUrlParams({
            [paramName]: changedColumnsString || null
        });
    }

    /**
     * @param {Object[]} columns
     * @param {Object[]} visibleColumns
     */
    function determineChangedColumns(columns, visibleColumns) {
        return columns.reduce((changedColumns, column) => {
            const initialVisibility = column.visible;
            const currentVisiblity = visibleColumns.some(visibleColumn => visibleColumn.dataField === column.dataField);
            if (currentVisiblity !== initialVisibility) {
                changedColumns[column.dataField] = currentVisiblity;
            }
            return changedColumns;
        }, {});
    }

    /**
     * @param {Object} changedColumns
     * @returns {String}
     */
    function stringifyChangedColumns(changedColumns) {
        return Object.entries(changedColumns)
            .map(([column, visible]) => (visible ? VISIBLE_SYMBOL : HIDDEN_SYMBOL) + column)
            .join(COLUMN_SEPARATOR);
    }

    /**
     * Calling this function should be wrapped in a try-catch, errors could occur with invalid URL.
     * @param {string} [paramName = "columns"]
     * @param {Object[]} columns
     * @return {Object} - Column visibilities that are different from the default specified in columns setup (2nd param)
     */
    function parseUrl(paramName = DEFAULT_PARAM_NAME, columns) {
        const urlParams = $location.search();
        const changedColumnsString = urlParams[paramName];
        if (!changedColumnsString) {
            return {};
        }
        return parseChangedColumns(columns, changedColumnsString);
    }

    /**
     * @param {Object[]} columns
     * @param {string} changedColumnsString
     * @returns {Object}
     */
    function parseChangedColumns(columns, changedColumnsString) {
        const changedColumns = {};
        const columnStrings = changedColumnsString.split(COLUMN_SEPARATOR);
        columnStrings.forEach(columnString => {
            const regExpResult = columnStringRegex.exec(columnString);
            const visibilitySymbol = regExpResult[1];
            const columnName = regExpResult[2];
            if (!visibilitySymbol || !columnName) {
                return;
            }
            const column = columns.find(column => column.dataField.toLowerCase() === columnName.toLowerCase());
            if (!column) {
                return;
            }
            const defaultVisibility = column.visible;
            const visibility = visibilitySymbol === VISIBLE_SYMBOL;
            if (defaultVisibility !== visibility) {
                changedColumns[column.dataField] = visibility;
            }
        });
        return changedColumns;
    }
}
