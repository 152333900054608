export default {
    LOGIN_SUCCESS: 'auth-login-success',
    LOGIN_FAILED: 'auth-login-failed',
    LOGOUT_SUCCESS: 'auth-logout-success',
    NOT_AUTHENTICATED: 'auth-not-authenticated',
    NO_PERMISSIONS: 'auth-no-permissions',
    NO_OPTIONS: 'auth-no-options',
    RESET_TIMEOUT: 'auth-reset-timeout',
    LOCK_APP: 'auth-lock-app', // "Locked" refers to when the user has timed out and cannot do anything until re-authentication
    UNLOCK_APP: 'auth-unlock-app',
    WARNING_OPENED: 'auth-warning-dialog-opened',
    LOGIN_AS_CONTACT: 'auth-login-as-contact',
    LOGOUT_OF_CONTACT: 'auth-logout-of-contact'
};
