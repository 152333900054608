import loginTpl from './login.tpl.html';

loginConfig.$inject = ['$stateProvider'];

export default function loginConfig($stateProvider) {
    $stateProvider.state('login', {
        parent: 'nav',
        url: '/login?screen&displayType',
        data: {
            pageTitle: 'Login',
            isPublicPage: true
        },
        params: {
            redirectTo: null,
            redirectParams: null
        },
        views: {
            'main@': {
                templateUrl: loginTpl
            }
        }
    });
}
