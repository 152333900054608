import angular from 'angular';

import '../../../core/core.module';

import leaderboardGrid from './leaderboardGrid.component';

export default angular.module('app.leaderboard.leaderboardGrid', [
    'app.core'
])
.component('leaderboardGrid', leaderboardGrid);
