import spotlightBannerTpl from './spotlightBanner.tpl.html';
import spotlightBannerCtrl from './spotlightBanner.controller';

export default {
    templateUrl: spotlightBannerTpl,
    controller: spotlightBannerCtrl,
    controllerAs: 'vm',
    bindings: {
        marqueeItems: '<',
        isHideMarquee: '<',
        selectedRecognitionFilter: '<',
        showBannerButtons: '<',
        selectedRecognition: '<'
    }
};
