import anniversaryBannerTpl from './anniversaryBanner.tpl.html';
import anniversaryBannerCtrl from './anniversaryBanner.controller';

export default {
    templateUrl: anniversaryBannerTpl,
    controller: anniversaryBannerCtrl,
    controllerAs: 'vm',
    bindings: {
        anniversary: '<',
        isShowBirthdays: '<',
        isShowAnniversary: '<'
    }
};
