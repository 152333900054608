import $ from 'jquery';

fullHeightIframe.$inject = ['$timeout', '$window', 'CoreDirectivesService'];

export default function fullHeightIframe($timeout, $window, CoreDirectivesService) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            // Messy solution but we need the navCtrl and taskbarCtrl to run first before getting the height of their divs
            // TODO: explore cleaner solutions. may be helpful: http://stackoverflow.com/questions/29093229/how-to-call-function-when-angular-watch-cycle-or-digest-cycle-is-completed
            $timeout(setIframeHeight);

            CoreDirectivesService.onWindowResize(scope, setIframeHeight);

            function setIframeHeight() {
                const newHeight = getNewIframeHeight();
                element.height(newHeight);
            }

            function getNewIframeHeight() {
                const windowHeight = $window.innerHeight;
                const headerHeight = $('.navbar-container').outerHeight();
                const taskbarHeight = $('.taskbar').outerHeight();
                return windowHeight - headerHeight - taskbarHeight + 'px';
            }
        }
    };
}
