import pointTrackingCorrectionDetailsTpl from './pointTrackingCorrectionDetails.tpl.html';
import pointTrackingCorrectionDetailsCtrl from './pointTrackingCorrectionDetails.controller';

export default {
    templateUrl: pointTrackingCorrectionDetailsTpl,
    controller: pointTrackingCorrectionDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        correctionList: '<'
    }
};
