promoQuizAssignBackCtrl.$inject = ['$translate', 'PromoQuizService'];

export default function promoQuizAssignBackCtrl($translate, PromoQuizService) {
    this.$onInit = () => {
        const vm = this;

        // TODO: find way to un-duplicate the code below with promoQuizSection.controller.js

        const sectionID = vm.section.sectionID;
        const quizResultID = vm.promoDetails.results.quizResultID;
        vm.noteModel = vm.noteModel ? vm.noteModel : {};

        vm.noteFields = [{
            key: 'note',
            type: 'customTextarea',
            templateOptions: {
                required: true,
                maxlength: 500,
                placeholderTranslateKey: vm.isApprover
                    ? 'app_PROMO_QUIZ_SECTION_ADD_NOTE_PLACEHOLDER_ADMIN' // 'Add a note for the participant regarding this submission'
                    : 'app_PROMO_QUIZ_SECTION_ADD_NOTE_PLACEHOLDER', // 'Add a note for a program administrator'
                controlClass: 'promo-submission-note-field',
                resetAfterSubmit: true
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_ADD_NOTE_ASSIGN_BACK', // Assign Back
                    class: 'btn btn-primary',
                    iconClass: '',
                    isDisabled: false
                },
                send: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_ADD_NOTE_ASSIGN_BACK', // Assign Back
                    iconClass: 'fa-spinner fa-pulse',
                    isDisabled: true,
                    debounce: 300
                },
                success: {
                    noReset: true,
                },
            },
        };


        vm.submitNote = function() {
            return PromoQuizService.addNote({
                sectionID,
                quizResultID,
                note: vm.noteModel.note,
                isBackToClient: true,
                isApprover: vm.isApprover
            });
        };
    };
}
