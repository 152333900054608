

class SharedCompanyModel {

    /**
     * @param {Object} companyData - JSON from Companies_getSession SP
     */
    constructor(companyData) {
        Object.assign(this, companyData);
    }

    /**
     * @param {string} option
     * @returns {boolean}
     */
    hasOption(option) {
        return this.options[option] != null;
    }

    /**
     * @param {string[]} options
     * @returns {boolean}
     */
    hasAnyOption(options) {
        return options.some(option => this.hasOption(option));
    }

    /**
     * @param {string[]} options
     * @returns {boolean}
     */
    hasAllOptions(options) {
        return options.every(option => this.hasOption(option));
    }

    /**
     * @param {string} languageCode
     * @returns {boolean}
     */
    hasLanguage(languageCode) {
        return this.languageOptions[languageCode] != null;
    }

    /**
     * @param {string} languageCode
     * @returns {int}
     */
    getLanguageIdFromCode(languageCode) {
        languageCode = languageCode.toUpperCase();
        return this.languageOptions[languageCode].ID;
    }

    /**
     * @param {string} ssoEndpointCode
     * @returns {Object}
     */
    findSsoEndpointByCode(ssoEndpointCode = '') {
        ssoEndpointCode = ssoEndpointCode.toLowerCase();
        return this.ssoEndpoints.find(ssoEndpoint => ssoEndpoint.code.toLowerCase() === ssoEndpointCode);
    }
}

module.exports = SharedCompanyModel;
