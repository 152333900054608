legacyFrameCtrl.$inject = ['$sce', 'UrlUtils'];

export default function legacyFrameCtrl($sce, UrlUtils) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.path

        vm.iframeSrc = getIframeSrc();

        function getIframeSrc() {
            const legacyUrl = UrlUtils.getLegacyUrl();
            return legacyUrl + vm.path;
        }
    };
}
