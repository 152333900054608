import topThreeStrengthsWidgetTpl from './topThreeStrengthsWidget.tpl.html';
import topThreeStrengthsWidgetCtrl from './topThreeStrengthsWidget.controller';

export default {
    templateUrl: topThreeStrengthsWidgetTpl,
    controller: topThreeStrengthsWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
