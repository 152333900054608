import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class FormTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$q = $injector.get('$q');

        _.merge(this, {
            taskModalOptions: {
                successMessageTimeout: 1400
            }
        });
    }

    get formFields() {
        return [];
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;

        if (this.formFields.length <= 0) {
            vm.taskForm = {}; // Prevents formly undefined errors
        }

        vm.taskFormModel = {};

        // Link vm form fields to vm model (so accessing values on submit is possible)
        vm.taskFormFields = this.formFields.map(field => Object.assign(field, {
            model: vm.taskFormModel[field.key]
        }));

        $scope.$watch(() => vm.taskForm && vm.taskForm.$invalid, (isInvalid) => {
            vm.disableSubmit = isInvalid;
        });
    }

    get componentHtml() {
        if (_.isEmpty(this.formFields)) {
            return '';
        }
        return `
            <rn-form model="vm.taskFormModel"
                     fields="vm.taskFormFields"
                     options="vm.taskFormOptions"
                     form="vm.taskForm"
                     rn-form-ctrl="vm.rnFormCtrl"
                     submit="vm.submitTaskCompletion"
                     class="${this.formCssClass || ''}">
            </rn-form>`;
    }

    onSubmit() {
        return this.$q.resolve();
    }
}
