import _ from 'lodash';
import angular from 'angular';

rnFormSubmitButtonCtrl.$inject = ['$scope', '$timeout'];

export default function rnFormSubmitButtonCtrl($scope, $timeout) {
    this.$onInit = () => {
        const vm = this;

        // Passed from bindings:
        // vm.options
        // vm.disableWhileInvalid

        // Set by directive link function:
        // vm.rnFormCtrl - the controller of the <rn-form> this button is within

        _.defaultsDeep(vm, {
            options: {
                settings: {}
            },
            disableWhileInvalid: false,
        });

        const defaults = {
            type: 'submit',
            hidden: false,
            settings: {
                initial: {
                    translateKey: 'app_RN_FORM_SUBMIT_BUTTON_RESET', // Submit
                    class: 'btn btn-primary',
                    iconClass: '',
                    isDisabled: false
                },
                send: {
                    translateKey: 'app_RN_FORM_SUBMIT_BUTTON_SEND', // Submitting...
                    iconClass: 'fa-spinner fa-pulse',
                    isDisabled: true,
                    debounce: 300
                },
                success: {
                    translateKey: 'app_RN_FORM_SUBMIT_BUTTON_SUCCESS', // Success!
                    class: 'btn btn-success',
                    iconClass: 'fa-check',
                    noReset: false
                },
                fail: {
                    // Same as settings.initial, done below
                },
                validating: {
                    translateKey: 'app_RN_FORM_SUBMIT_BUTTON_RESET_VALIDATING', // Checking...
                    isDisabled: true,
                    debounce: 300
                }
            },
            reset() {
                Object.assign(this, this.settings.initial);
                if (this.validatorsMinTimeout) {
                    $timeout.cancel(this.validatorsMinTimeout);
                    this.validatorsMinTimeout = null;
                }
                if (this.sendingMinTimeout) {
                    $timeout.cancel(this.sendingMinTimeout);
                    this.sendingMinTimeout = null;
                }
            },
            onSend() {
                // Show "Submitting..." only if request takes longer than 300ms to complete
                this.sendingMinTimeout = $timeout(() => {
                    Object.assign(this, this.settings.send);
                }, this.settings.send.debounce);
            },
            onSuccess() {
                if (this.sendingMinTimeout) {
                    $timeout.cancel(this.sendingMinTimeout);
                    this.sendingMinTimeout = null;
                }
                if (this.settings.success.noReset) {
                    Object.assign(this, this.settings.success);
                    return;
                }
                if (vm.rnFormCtrl.successDisplayTime) {
                    Object.assign(this, this.settings.success);
                    $timeout(() => {
                        this.reset();
                    }, vm.rnFormCtrl.successDisplayTime);
                } else {
                    this.reset();
                }
            },
            onFail() {
                if (this.sendingMinTimeout) {
                    $timeout.cancel(this.sendingMinTimeout);
                    this.sendingMinTimeout = null;
                }
                Object.assign(this, this.settings.fail);
            },
            onValidating() {
                // Show "Checking..." only if validation request takes longer than 300ms
                this.validatorsMinTimeout = $timeout(() => {
                    Object.assign(this, this.settings.validating);
                }, this.settings.validating.debounce);
            }
        };

        angular.merge(vm, defaults, vm.options);

        vm.onClick = () => vm.rnFormCtrl.onSubmit();

        vm.settings.fail = angular.merge({},
            defaults.settings.initial,
            vm.options.settings.fail || vm.options.settings.initial || {}
        );

        $scope.$watch(() => vm.options, (newOptions) => {
            angular.merge(vm, newOptions);
        }, true);

        if (vm.disableWhileInvalid) {
            $scope.$watch(() => vm.rnFormCtrl && vm.rnFormCtrl.form ? vm.rnFormCtrl.form.$invalid : true, (formIsInvalid) => {
                vm.isDisabled = formIsInvalid;
            });
        }
    };
}
