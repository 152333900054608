import { isThisQuarter } from 'date-fns';

milestonesCtrl.$inject = ['ApplauseCategoryTypes', 'MilestoneTypes'];

export default function milestonesCtrl(ApplauseCategoryTypes, MilestoneTypes) {
    this.$onInit = () => {
        const vm = this;

        vm.isAnniversary = vm.type === MilestoneTypes.ANNIVERSARY;

        vm.applauseCategoryType = vm.isAnniversary ? ApplauseCategoryTypes.ANNIVERSARY : ApplauseCategoryTypes.BIRTHDAY;

        vm.dates[0].isOpen = true;

        vm.toggleCollapse = function(day) {
            day.isOpen = !day.isOpen;
        };
    };
}
