import angular from 'angular';

import './reportsList/reportsList.module';
import './reportForm/reportForm.module';

import reportsUrlConfig from './reportsUrl.config';
import reportsConfig from './reports.config';
import ReportsService from './reportsService.factory';
import savedReportsGrid from './savedReportsGrid/savedReportsGrid.component';
import scheduledReportsGrid from './scheduledReportsGrid/scheduledReportsGrid.component';
import reportsRun from './reports.run';

export default angular.module('app.reports', [
    'app.reports.reportsList',
    'app.reports.reportForm'
])
.config(reportsUrlConfig)
.config(reportsConfig)
.factory('ReportsService', ReportsService)
.component('savedReportsGrid', savedReportsGrid)
.component('scheduledReportsGrid', scheduledReportsGrid)
.run(reportsRun);
