LoginAsService.$inject = [
    '$http', '$window', '$sessionStorage', '$rootScope', 'Session', 'UrlUtils', 'TabRefreshService',
    'Contact', 'AUTH_EVENTS'
];

export default function LoginAsService(
    $http, $window, $sessionStorage, $rootScope, Session, UrlUtils, TabRefreshService,
    Contact, AUTH_EVENTS
) {

    /**
     * @param {Object} params
     * @param {int} params.contactID
     */
    async function loginAsContact({ contactID }) {
        const company = Session.getCompany();
        const spectator = Session.getUser();
        const res = await $http.post('/api/login-as/login', { contactID }, { disableLoginAsConfirmModal: true });
        await legacyLoginAsContact(contactID);
        const user = new Contact(company, res.data.user);
        $rootScope.$broadcast(AUTH_EVENTS.LOGIN_AS_CONTACT, { user, spectator });
        return TabRefreshService.refresh(user.permissionGroup.defaultPage);
    }

    async function logoutOfContact() {
        const user = Session.getUser();
        const spectator = Session.getSpectator();
        await $http.post('/api/login-as/logout', {}, { disableLoginAsConfirmModal: true });
        await legacyLogoutOfContact();
        $rootScope.$broadcast(AUTH_EVENTS.LOGOUT_OF_CONTACT, { user, spectator });
        return TabRefreshService.refresh(spectator.permissionGroup.defaultPage);
    }

    /**
     * @param {Object} params
     * @param {int} params.contactID
     */
    async function previousUserLogin({ contactID }) {
        const company = Session.getCompany();
        const res = await $http.get(`/api/previousUser/${contactID}`);
        const user = new Contact(company, res.data.user);
        $rootScope.$broadcast(AUTH_EVENTS.LOGIN_AS_CONTACT, { user });
        return TabRefreshService.refresh(user.permissionGroup.defaultPage);
    }

    /**
     * Logs in as another contact on the legacy site.
     * @param contactID
     */
    function legacyLoginAsContact(contactID) {
        return UrlUtils.loadLegacyPage(`/Admin_UserLogon.asp?ContactID=${contactID}`);
    }

    function legacyLogoutOfContact() {
        // The way legacy logout works is to just load the same login-as page but with the original spectator ID.
        const spectator = Session.getSpectator();
        return legacyLoginAsContact(spectator.userID);
    }

    return {
        loginAsContact,
        logoutOfContact,
        previousUserLogin,
        legacyLoginAsContact,
    };
}
