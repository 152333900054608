authDialogCtrl.$inject = ['$scope', 'AuthService'];

export default function authDialogCtrl($scope, AuthService) {
    const vm = this;

    vm.resetTimeout = async function() {
        await AuthService.resetTimeout();
        $scope.$close();
    };
}
