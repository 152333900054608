import warningDialogTpl from './warningDialog.tpl.html';
import loginDialogTpl from './loginDialog.tpl.html';

AuthDialogHandler.$inject = ['$rootScope', 'AUTH_EVENTS', '$uibModal'];

export default function AuthDialogHandler($rootScope, AUTH_EVENTS, $uibModal) {
    let warningModal;
    let loginModal;

    $rootScope.$on(AUTH_EVENTS.RESET_TIMEOUT, function() {
        if (warningModal) {
            // For when user manually goes to a url when warning modal is open (Ex. type and enter in their browser)
            warningModal.close();
        }
    });

    $rootScope.$on(AUTH_EVENTS.NOT_AUTHENTICATED, function() {
        if (loginModal) {
            // For when user tries to manually go to a url while the app is locked.
            // They'll be taken to the login screen anyway, so might as well close the modal.
            // (This doesn't feel like the best way to do it; feels too tightly coupled to AuthStateService.checkStateAuthorization)
            loginModal.close();
        }
    });

    return {
        openWarning,
        openLogin
    };

    function openWarning() {
        warningModal = $uibModal.open({
            templateUrl: warningDialogTpl,
            controller: 'warningDialogCtrl as vm',
            backdrop: 'static',
            keyboard: false,
            size: 'sm'
        });

        warningModal.result.then(() => warningModal = false);

        return warningModal;
    }

    function openLogin() {
        if (warningModal) {
            warningModal.close();
        }

        loginModal = $uibModal.open({
            templateUrl: loginDialogTpl,
            controller: 'loginDialogCtrl as vm',
            backdrop: 'static',
            keyboard: false,
            size: 'md'
        });

        loginModal.result.then(() => loginModal = false);

        return loginModal;
    }
}
