import adminFrameTpl from './adminFrame.tpl.html';
import adminFrameCtrl from './adminFrame.controller';

export default {
    templateUrl: adminFrameTpl,
    controller: adminFrameCtrl,
    controllerAs: 'vm',
    bindings: {
        adminFrameUrl: '<',
        adminFrameClass: '<',
    }
};
