import promoGaugeWidgetTpl from './promoGaugeWidget.tpl.html';
import promoGaugeWidgetCtrl from './promoGaugeWidget.controller';


export default {
    templateUrl: promoGaugeWidgetTpl,
    controller: promoGaugeWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        percentToNextTarget: '<',
        valueToNextTarget: '<',
        size: '<'
    }
};
