import _ from 'lodash';

ReportService.$inject = ['$http', 'Session'];

export default function ReportService($http, Session) {
    let params = {};
    let data = [];

    return {
        setParams,
        setFilters,
        setSortSettings,
        getGridData,
        getRowData,
        getModalData,
        saveCustomFilterSet,
    };

    function setParams({ loadOptions }) {
        if (loadOptions) {
            params = {
                sort: loadOptions.sort ? loadOptions.sort.map(sort => ({
                    field: sort.selector,
                    desc: sort.desc
                })) : [],
                requireTotalCount: loadOptions.requireTotalCount || false,
            };
        }
    }

    function setFilters(filters) {
        params.filters = filters;
    }

    function setSortSettings(sortSettings) {
        params.sort = sortSettings;
    }

    function getGridData ({ stateParams, stateData, loadOptions }) {
        const dashboardCode = stateParams.dashboardCode || stateData.dashboardCode;
        return $http.get(`/api/dashboard-report/${dashboardCode}/${stateParams.dashboardID}/${stateParams.reportCode}`, { params: {
            sort: params.sort,
            filters: params.filters,
            requireTotalCount: params.requireTotalCount,
            skip: loadOptions.skip,
            take: loadOptions.take,
        } })
            .then(function(response) {
                data = _.concat(response.data.data, data);
                return response;
            }, function(error) {
                console.error(error);
            });
    }

    function getRowData(key, id) {
        if (_.isArray(data)) {
            return data.find(row => row[key] === id);
        }
        return {};
    }

    function getModalData(modalDetails, modalID) {
        if ([null, undefined].includes(modalID)) {
            return undefined;
        }
        if (modalDetails.dataType === 'NUMBER') {
            modalID = Number(modalID);
        }
        return getRowData(modalDetails.rowKeyName, modalID);
    }

    function saveCustomFilterSet(customFilterSet) {
        $http.post('/api/dashboard/report/custom-filter-set', customFilterSet);
    }
}
