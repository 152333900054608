import _ from 'lodash';
import $ from 'jquery';

spotlightItemCtrl.$inject = [
    '$state', '$stateParams', 'Communication', 'FileUtils', 'ApplauseService', 'SpotlightService', 'MessageService'
];

export default function spotlightItemCtrl(
    $state, $stateParams, Communication, FileUtils, ApplauseService, SpotlightService, MessageService
) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            onDeleteSuccess: _.noop,
            onModalOpened: _.noop,
        });

        if (vm.item.socialPostFileURL) {
            vm.isImage = FileUtils.isValidImage(vm.item.socialPostFileURL);
            vm.isVideo = !vm.isImage && FileUtils.isValidVideo(vm.item.socialPostFileURL);
        }

        vm.showCommentForm = false;

        vm.onDelete = async function() {
            await SpotlightService.deleteItem(vm.item);
            return vm.onDeleteSuccess(vm.item);
        };

        vm.openModal = async function(event) {
            const $target = $(event.target);
            const clickedLinkOrButton = $target.closest(
                'a:not([role="presentation"]), button:not([role="presentation"])'
            ).length;
            // We don't want to open the modal when clicking on the contact links.
            if (clickedLinkOrButton) {
                return;
            }
            let itemDetails;
            let open;
            const params = $stateParams;
            params.messageRecipientID = vm.item.messageRecipientID;
            
            if (vm.displayedOn) {
                $state.go(vm.displayedOn, params);
            } else {
                $state.go('spotlightItems', params);
            }

            if (vm.item.applauseID) {
                itemDetails = await ApplauseService.getApplause(vm.item.applauseID);
                open = Communication.openApplause;
            }
            if (vm.item.certificateID) {
                itemDetails = await MessageService.getMessageDetails(vm.item.messageRecipientID);
                open = Communication.openCertificate;
            }
            if (vm.item.socialPostID) {
                // For Social Posts, the spotlight item json has all the info needed to open the details.
                // The clone is because we only want to update the spotlight item view after the modal is closed.
                itemDetails = _.cloneDeep(vm.item);
                open = Communication.openSocialPost;
            }
            // Set the detail's comments to the item's comments because if user loaded more comments while on
            // the spotlight, we want the modal to include those too.
            itemDetails.socialComments = _.cloneDeep(vm.item.socialComments);
            const modalInstance = open(itemDetails);

            modalInstance.opened
                .then(() => vm.onModalOpened(itemDetails, modalInstance));

            modalInstance.result
                .then(result => onModalClosed(itemDetails, result))
                .catch(result => onModalClosed(itemDetails, result))
                .finally(() => {
                    const params = $stateParams;
                    params.messageRecipientID = null;
                    if (!$state.transition) {
                        if (vm.displayedOn) {
                            $state.go(vm.displayedOn, params);
                        } else {
                            $state.go('spotlightItems', params);
                        }
                    }
                });
        };

        function onModalClosed(itemDetails, result) {
            // Assume that Applause details JSON, social post JSON, and certificate JSON all have the same JSON format
            // for comments.
            // Slice because we want the same number of comments to show before and after the modal.
            vm.item.socialComments = itemDetails.socialComments.slice(0, vm.item.socialComments.length);
            vm.item.totalComments = itemDetails.totalComments;
            vm.item.totalLikes = itemDetails.totalLikes;
            vm.item.isLikedByUser = itemDetails.isLikedByUser;
            if (result === 'deleted') {
                return vm.onDelete();
            }
        }

        vm.onCommentAdded = () => {
            vm.showCommentForm = false;
        };
    };
}
