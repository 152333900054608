import angular from 'angular';

import '../../../../../core/core.module';

import resendInvite from './resendInvite.component';

export default angular.module('app.contacts.contactPanel.contactPanelTools.resendInvite', [
    'app.core'
])
.component('resendInvite', resendInvite);
