import _ from 'lodash';

PostNominationModal.$inject = ['$uibModal'];

export default function PostNominationModal($uibModal) {

    function open({
        applauseForm,
        onCancel = _.noop,
    } = {}) {
        return $uibModal.open({
            template: `
                <post-nomination applause-form="vm.applauseForm"
                                 on-cancel="vm.onCancel">
                </post-nomination>
            `,
            controller: ['$scope', function($scope) {
                const vm = this;
                vm.applauseForm = applauseForm;
                vm.onCancel = () => {
                    $scope.$dismiss();
                    onCancel();
                };
            }],
            controllerAs: 'vm'
        });
    }

    return {
        open
    };
}
