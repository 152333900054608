

const SharedModule = require('./SharedModule');

class SkinUtils extends SharedModule {

    /**
     * @returns {string}
     */
    getSkinCode() {
        return (this.user && this.user.skinCode) || this.company.defaultSkinCode;
    }
}

module.exports = SkinUtils;
