import _ from 'lodash';

PromoService.$inject = ['$http', 'UrlUtils', 'Session', 'LangUtils', 'QuizTypes', 'PromoTypes', 'PromoQuizService'];

export default function PromoService($http, UrlUtils, Session, LangUtils, QuizTypes, PromoTypes, PromoQuizService) {
    let promoDetails;

    return {
        getIncentivesList,
        getAwardsList,
        getIncentiveDisplay,
        getAwardDisplay,
        getPromoImageUrl,
        getPromoImagePath,
        getPromoDetailsSref,
        updatePromoStatus,
        addToQuickStartLibrary,
        isShowGauge,
        isShowPoints,
        isShowLeaderBoard,
        isMission,
        isTrainingQuiz,
        getGameID,
        getQuestionDetails,
        getAnswerDetails,
        getAllAnswerDetails,
        getPromoName,
        onAcceptTerms,
    };

    async function getIncentivesList() {
        const { data } = await $http.get(`/api/incentives`);
        return data;
    }

    async function getAwardsList() {
        const { data } = await $http.get(`/api/awards`);
        return data;
    }

    async function getIncentiveDisplay(promoID) {
        const { data } = await $http.get(`/api/incentives/${promoID}`);
        promoDetails = data;
        return data;
    }

    function getGameID() {
        if(promoDetails) {
            return promoDetails.gameID;
        }
        return;
    }

    function getPromoName() {
        if(promoDetails) {
            return promoDetails.name;
        }
        return;

    }

    async function getAwardDisplay(promoID) {
        const { data } = await $http.get(`/api/awards/${promoID}`);
        return data;
    }

    function getPromoImageUrl(promo) {
        const imagePath = getPromoImagePath();
        return `${imagePath}/${promo.imageName}`;
    }

    function getPromoImagePath() {
        const company = Session.getCompany();
        const cloudPublicURL = company.cloudRootURLs.public;
        const promoImagesCloudContainerType = 11;
        const languageCode = LangUtils.getLang().toUpperCase();
        const promoImagesCloudSubFolder = company.cloudContainers
            .find(cloudContainer => cloudContainer.typeID === promoImagesCloudContainerType);
        const promoImagesCloudSubFoldername = promoImagesCloudSubFolder.name;
        return `${cloudPublicURL}/${promoImagesCloudSubFoldername}/${languageCode}`;
    }

    function getPromoDetailsSref(promo, isAward) {
        const promoID = promo.ID;
        if (isAward) {
            return `awardNominate({ id: ${promoID} })`;
        } else {
            return `incentiveView({ id: ${promoID} })`;
        }
    }

    async function getQuestionDetails({ gameID, questionID }) {
        let res;
        if ([undefined, null].includes(questionID)) {
            res = await $http.get(`/api/quiz-results/${promoDetails.ID}/game/${gameID}/question/details`);
        } else {
            res = await $http.get(`/api/quiz-results/${promoDetails.ID}/game/${gameID}/question/${questionID}/details`);
        }
        return res.data;
    }

    async function getAnswerDetails({ contactID = null, gameID = null, gameplayGUID = null, quizResultID = null }) {
        switch(promoDetails.promoTypeID) {
            case PromoTypes.GAME_WITH_QUESTION:
                const res = await $http.get(`/api/quiz-results/guid/${gameplayGUID}/answer-details`);
                return res.data;
            case PromoTypes.CLAIM_BASED:
            case PromoTypes.TRAINING:
                return await PromoQuizService.getQuizResults({ quizResultID });
        }
        
    }

    async function getAllAnswerDetails() {
        const res = await $http.get(`/api/quiz-results/${promoDetails.ID}/promoType/${promoDetails.promoTypeID}/answer-details`);
        return res.data;
    }

    async function updatePromoStatus(promoID, action) {
        const res = await $http.post(`/api/promos/${promoID}/status`, { action });
        return res.data;
    }

    async function addToQuickStartLibrary(promoID) {
        const res = await $http.post(`/api/promos/${promoID}/add-to-library`);
        console.log(res.data);
        return res.data;
    }

    /**
     * For this function to work with both the JSON from the promo list SP and the promo details SP, they need to have
     * the same JSON structure and naming - i.e. promo.widgetPromoTargets.pointTargets is an array
     * @param {Object} promo
     * @returns {boolean}
     */
    function isShowGauge(promo) {
        const pointTargets = _.get(promo, 'widgetPromoTargets.pointTargets');
        const firstTargetValue = _.get(promo, 'widgetPromoTargets.pointTargets[0].targetValue');
        // We don't want to show gauge when there is only 1 target of 0
        // TODO: SP should return no targets instead
        return !_.isEmpty(pointTargets) && !(pointTargets.length === 1 && firstTargetValue === 0);
    }

    /**
     * For this function to work with both the JSON from the promo list SP and the promo details SP, they need to have
     * the same JSON structure and naming - i.e. promo.widgetPointsEarned.pointsEarned is total points earned on incentive.
     * @param {Object} promo
     * @param {Number} hasWidget
     * @returns {boolean}
     */
    function isShowPoints(promo, hasWidget) {
        const pointsEarned = _.get(promo, 'widgetPointsEarned.pointsEarned');
        // We don't want to show points when there is undefined or 0 without widget.
        // And always show points when we have widget.
        return (pointsEarned && pointsEarned !== 0) || hasWidget;
    }

    /**
     * @param {Object} promo
     * @returns {Object[]}
     */
    function isShowLeaderBoard(promo) {
        return _.get(promo, 'leaderboard.rankings');
    }

    function isMission(promo) {
        return !!_.get(promo, 'missionGameID') && _.isArray(promo.incentiveList);
    }

    function isTrainingQuiz(promo) {
        return _.get(promo, 'quiz.quizTypeID') === QuizTypes.TRAINING;
    }

    async function onAcceptTerms(promoID) {
        const { data } = await $http.post(`/api/promos/${promoID}/accept-terms`);
        return data;
    }
}
