import _ from 'lodash';

widgetStatsCtrl.$inject = ['WidgetDataDisplayTypes'];

export default function widgetStatsCtrl(WidgetDataDisplayTypes) {
    this.$onInit = () => {
        const vm = this;
        let overrideStats = [];
        // Passed from component bindings:
        // vm.stats
        // vm.isSubStats
        // vm.extraJson

        _.defaults(vm, {
            isSubStats: false
        });

        vm.WidgetDataDisplayTypes = WidgetDataDisplayTypes;

        if (vm.extraJson) {
            if (vm.extraJson.stats) {
                overrideStats = vm.extraJson.stats;
            } else if (vm.extraJson.subStats) {
                overrideStats = vm.extraJson.subStats;
            }
        }

        vm.stats.map((stat, index) => {
            if (overrideStats.length && overrideStats[index]) {
                if (overrideStats[index].displayType) {
                    stat.displayType = overrideStats[index].displayType;
                }
                if (overrideStats[index].header) {
                    stat.header = overrideStats[index].header;
                } else if (overrideStats[index].translateKey) {
                    stat.translateKey = overrideStats[index].translateKey;
                }
            }
            return stat;
        });
    };
}

/**
 * @typedef {Object} SnapshotWidgetStat
 * @property {number|string} value
 * @property {string} [header]
 * @property {string} [translateKey] - Phrase KeyName for the header
 * @property {string} [cssClass]
 * @property {string} [displayType] - One of the constant values from WidgetDataDisplayTypes
 */
