import angular from 'angular';

import '../../core/core.module';
import './promoHeader/promoHeader.module';
import './promoFilter/promoFilter.module';
import './promoList/promoList.module';
import './promoView/promoView.module';
import './promoEdit/promoEdit.module';
import './promoResults/promoResults.module';
import './promoGame/promoGame.module';
import './promoConfirmationTool/promoConfirmationTool.module';
import './promoWidgets/promoGaugeWidget/promoGaugeWidget.module';
import './trainingQuiz/trainingQuiz.module';
import './sisterPromoDropdown/sisterPromoDropdown.module';

import promoConfig from './promo.config';
import promoCtrl from './promo.controller';
import PromoService from './promoService.factory';
import PromoRibbon from './promoRibbon.factory';

import 'devextreme/viz/circular_gauge';

export default angular.module('app.promo', [
    'app.core',
    'app.promo.promoHeader',
    'app.promo.promoFilter',
    'app.promo.promoList',
    'app.promo.view',
    'app.promo.promoGaugeWidget',
    'app.promo.trainingQuiz',
    'app.promo.edit',
    'app.promo.results',
    'app.promo.confirmationTool',
    'app.promo.game',
    'app.promo.sisterPromoDropdown',
])
.config(promoConfig)
.controller('promoCtrl', promoCtrl)
.factory('PromoService', PromoService)
.factory('PromoRibbon', PromoRibbon);
