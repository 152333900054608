

const constantUtils = require('./constantUtils');

const NavBarTypes = {
    URL: 1,
    POINT_CALCULATOR: 2,
};

const properties = {};

constantUtils.configure({ constants: NavBarTypes, properties });

module.exports = NavBarTypes;
