import _ from 'lodash';

appStateRun.$inject = ['$transitions', '$rootScope', '$state', '$exceptionHandler', 'AuthStatus', 'StateUtils'];

export default function appStateRun($transitions, $rootScope, $state, $exceptionHandler, AuthStatus, StateUtils) {

    $transitions.onError({}, function(transition) {
        // $exceptionHandler will take care of logging the error if necessary and opening the error modal.
        // All this function should do is determine if we need to redirect to another state.

        const fromState = transition.from();
        const toState = transition.to();
        const err = transition.error();

        // TODO: this error occurs on our snapshot and account page because we're defaulting contactID when none is passed
        // it doesn't break anything because we just add the contactID param and the user is directed to the correct page
        const ignoreMessagesToIgnore = [
            // happens because we add contactID to snapshot and account
            'The transition has been superseded by a different transition',
            // happens when going to same state that you're already viewing
            'The transition was ignored'
        ];

        // Don't show error popup when user has required sign on tasks. It may be expected that they can't redirect
        // until the required tasks have been completed.
        const REQUIRED_SIGN_ON_TASK_REGEXP = /Must complete required tasks first/;
        const userHasRequiredSignOnTasks = err.detail && REQUIRED_SIGN_ON_TASK_REGEXP.test(err.detail.message);

        if (!ignoreMessagesToIgnore.includes(err.message) && !userHasRequiredSignOnTasks) {
            const errorMessage = `State change error trying to go from "${fromState.name}" to "${toState.name}"`;
            console.error(errorMessage);
            const error = {
                message: errorMessage,
                cause: err.detail.message,
                stack: `${fromState.name} to ${toState.name}`,
                element: 'state change'
            };
            if (err.detail && err.detail.redirectTo !== 'login') {
                $exceptionHandler(error);
            }
        }

        if (err.detail && err.detail.redirectTo) {
            return $state.go(err.detail.redirectTo, err.detail.redirectParams);
        }

        const nextState = getNextState(toState, fromState);

        if (nextState) {
            if (err.type === 2 ) {
                // ignore error type 2 transition superceded errors
                return;
            }
            return $state.go(nextState.name, nextState.params);
        }

        transition.abort();
    });

    /**
     * Determines the next state to go to after a $transitions.onError if no redirectTo is specified.
     * @param {Object} toState
     * @param {Object} fromState
     * @returns {{ name: string, params: Object } | undefined}
     */
    function getNextState(toState, fromState) {
        if ($rootScope.isRedirectingAfterLogin) {
            // Redirect after login handled by AuthStateService.goToNextStateAfterLogin()
            return;
        }
        // Public user
        if (!AuthStatus.isAuthenticated()) {
            return { name: 'login', params: {} };
        }
        const defaultState = StateUtils.getDefaultState();
        const fallbackState = StateUtils.getFallbackState();
        // Authenticated and error occurred in a non-viewable state or on login page.
        if (!StateUtils.isStateViewable(fromState) || fromState.name === 'login') {
            if (
                !_.isEqual(toState, defaultState)
                && !_.isEqual(toState, fallbackState)
                && !_.isEqual(defaultState, fallbackState)
            ) {
                // Go to default state if possible.
                return defaultState;
            } else if (_.isEqual(toState, defaultState) && !_.isEqual(defaultState, fallbackState)) {
                // If error going to default state, go to fallback state instead.
                // Ex. a permission group's default state is a custom content page that doesn't exist.
                return fallbackState;
            } else {
                console.error(`Error going to fallback state of ${fallbackState.name}`);
            }
        }
        // Else, stay at current state and error popup should show.
    }
}
