ChangePasswordService.$inject = ['$http', 'SignOnTasks'];

export default function ChangePasswordService($http, SignOnTasks) {
    return {
        submit,
        getPasswordPolicy
    };

    async function submit(oldPassword, newPassword) {
        const res = await $http.put('/api/user/password', {
            oldPassword,
            newPassword
        });
        await SignOnTasks.removeTask({ type: 'UpdatePassword' });
        return res.data;
    }

    async function getPasswordPolicy() {
        const res = await $http.get('/api/password-policy');
        return res.data;
    }
}
