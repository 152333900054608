import angular from 'angular';

import '../../../../core/core.module';

import spotlightBanner from './spotlightBanner.component';

export default angular.module('app.spotlight.spotlightComponents.spotlightBanner', [
    'app.core'
])
.component('spotlightBanner', spotlightBanner);
