import angular from 'angular';

import 'angular-formly';

import customCaptchaRun from './customCaptcha.run';

export default angular.module('common.formly.customCaptcha', [
    'formly'
])
.run(customCaptchaRun);
