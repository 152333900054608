import angular from 'angular';

import '../../../../core/core.module';
import '../../../registration/registration.module';

import RegistrationModalService from './registrationModalService.factory';
import registrationModalCtrl from './registrationModal.controller';

export default angular.module('app.nav.loginPanel.registrationModal', [
    'app.core',
    'app.registration',
])
.factory('RegistrationModalService', RegistrationModalService)
.controller('registrationModalCtrl', registrationModalCtrl);
