VoucherClaimService.$inject = ['$http'];

export default function VoucherClaimService($http) {
    return {
        sendVoucherCode
    };

    function sendVoucherCode({ voucherCode, skillTestingUserAnswer }) {
        return $http.post('/api/vouchers/claim', {
            voucherCode,
            skillTestingUserAnswer
        })
        .then(function(res) {
            return res.data;
        });
    }
}
