import angular from 'angular';

import '../../../../blocks/storage/storage.module'; // Session

import awardDetailsCtrl from './awardDetails.controller';
import awardDetails from './awardDetails.component';

export default angular.module('app.communication.award.awardDetails', [
    'app.blocks.storage'
])
.controller('awardDetailsCtrl', awardDetailsCtrl)
.component('awardDetails', awardDetails);
