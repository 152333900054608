import _ from 'lodash';

ApplauseService.$inject = ['$http', '$translate', 'Communication', 'ApplauseCategoryTypes'];

export default function ApplauseService($http, $translate, Communication, ApplauseCategoryTypes) {

    async function openApplauseModal(applauseID) {
        const applauseDetails = await getApplause(applauseID);
        return Communication.openApplause(applauseDetails);
    }

    async function getApplause(applauseID) {
        const res = await $http.get('/api/applause/modal', {
            params: {
                applauseID,
            }
        });
        return res.data;
    }

    async function getApplauseForm(categoryType = ApplauseCategoryTypes.APPLAUSE) {
        const res = await $http.get('/api/applause/form', {
            params: {
                categoryType
            }
        });
        if (res.data.applauseHeadersByCategories) {
            // remove categories that have no headers
            // TODO: this should be done in SP
            res.data.applauseHeadersByCategories = res.data.applauseHeadersByCategories
                .filter(category => !_.isEmpty(category.applauseHeaders));
        }
        return res.data;
    }

    async function sendApplause({
        MessageTemplateID,
        budgetID,
        group,
        recipients,
        points,
        selectedGuideline,
        strengths,
        teamName,
        tinymceModel,
        concatenatedContent,
        applauseType
    }) {
        const res = await $http.post('/api/applause/form', {
            MessageTemplateID,
            budgetID,
            group,
            recipients,
            points,
            selectedGuideline,
            strengths,
            teamName,
            tinymceModel,
            concatenatedContent,
            applauseType,
        });
        return res.data;
    }

    function getInitialApplauseRTEValue() {
        return $translate.instant('applauseForm_RTE_INITIAL_VALUE'); // 'Fill in your message here!'
    }

    return {
        openApplauseModal,
        getApplause,
        getApplauseForm,
        sendApplause,
        getInitialApplauseRTEValue
    };
}
