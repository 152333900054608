import _ from 'lodash';

const MAX_PROGRESS_BAR_WIDTH_PERCENT = 100;

datafeedPerformanceWidgetCtrl.$inject = ['StringInterpolationService'];

export default function datafeedPerformanceWidgetCtrl(StringInterpolationService) {
    this.$onInit = () => {
        const vm = this;

        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.hasData = !_.isEmpty(vm.widget.data) && !_.isEmpty(vm.widget.data.columnValues);
        if (!vm.hasData) {
            return;
        }

        vm.isDistributorView = vm.widget.data.isDistributorView;
        vm.expectedPerformanceRatio = vm.widget.data.expectedPerformanceRatio;
        vm.columnValues = vm.widget.data.columnValues;

        const highestValueColumn = _.maxBy(vm.columnValues, 'value');
        const highestValue = highestValueColumn.value;

        vm.columnValues.forEach(formatColumnValue);

        vm.cellTemplates = {
            contact: `
                <a ng-bind="columnValue.formattedName"
                ui-sref="snapshot({ contactID: columnValue.contact.ID })"
                uib-tooltip="{{ columnValue.contact.name }}" 
                tooltip-placement="top-left"
                class="contact-name">
                </a>`,
            column1: `
                <div ng-if="columnValue.showProgressBar"
                    ng-class="{
                        'predicted-performance-column-cell': true,
                        'is-distributor-view': vm.isDistributorView
                    }">
                    <div ng-style="{ 'width': columnValue.progressBarWidth + '%' }"
                        class="progress-bar-container">
                        <uib-progressbar value="columnValue.value"
                                        max="columnValue.value"
                                        ng-class="['predicted-performance-progress', 'green']">
                        </uib-progressbar>
                    </div>
                    <i ng-if="columnValue.isTargetExceeded"
                    ng-class="['fa', 'fa-arrow-right', 'target-exceeded-icon']"
                    aria-hidden="true">
                    </i>
                </div>`,
            column2: `
                <span ng-bind="columnValue.value | formatIfNumber" class="value"></span>`,
            column3: `<span ng-bind="columnValue.pendingApproval | formatIfNumber" class="pending"></span>`
        };

        /**
         * Sets properties onto the columnValue object regarding how it should be displayed.
         */
        function formatColumnValue(columnValue) {
            const formattedName = formatContactName(columnValue.contact.name);
            const showProgressBar = columnValue.value > 0;
            if (showProgressBar) {
                const progressBarWidth = determineProgressBarWidth(columnValue);
                Object.assign(columnValue, {
                    progressBarWidth
                });
            }
            Object.assign(columnValue, {
                formattedName,
                showProgressBar
            });
        }

        function formatContactName(name) {
            const splitName = name.split(' ');
            const firstInitial = splitName[0].charAt(0);
            const lastName = splitName[splitName.length - 1];
            return `${firstInitial}. ${lastName}`;
        }

        function determineProgressBarWidth(columnValue) {
            return columnValue.value / highestValue * MAX_PROGRESS_BAR_WIDTH_PERCENT;
        }
    };
}
