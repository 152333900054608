import voucherClaimTpl from './voucherClaim.tpl.html';

voucherClaimConfig.$inject = ['$stateProvider'];

export default function voucherClaimConfig($stateProvider) {
    $stateProvider.state('voucherClaim', {
        parent: 'auth',
        url: '/voucher-claim?code',
        views: {
            'main@': {
                controller: 'voucherClaimCtrl as vm',
                templateUrl: voucherClaimTpl
            }
        },
        data: {
            pageTitle: 'Voucher Claim'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('voucherClaim');
            }]
        }
    });
}
