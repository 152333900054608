import _ from 'lodash';

communicationModalCtrl.$inject = ['$scope', 'model', 'detailsTemplate'];

export default function communicationModalCtrl($scope, model, detailsTemplate) {
    const vm = this;
    vm.model = model;
    vm.template = detailsTemplate;

    const typeCode = model.messageTypeCode || model.pointTrackingTypeCode;

    if (typeCode) {
        vm.modalCssClass = _.kebabCase(typeCode + ' Modal');
    }

    vm.onDelete = () => $scope.$close('deleted');
}
