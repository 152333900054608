import _ from 'lodash';
import typeAheadTpl from './typeAhead.tpl.html';
import contentTypeAheadTpl from '../content/contentTypeAhead.tpl.html';

suggestCtrl.$inject = ['SuggestService', 'SuggestsTypes'];

export default function suggestCtrl(SuggestService, suggestsTypes) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            onSelect: _.noop,
            typeAheadTemplateUrl: typeAheadTpl,
            extraParameters: {}
        });
        vm.options = {
            otherContactID: vm.otherContactId,
            ...vm.extraParameters
        };

        vm.loading = false;
        if(vm.type === suggestsTypes.CONTENT) {
            vm.typeAheadTemplateUrl = contentTypeAheadTpl;
        }

        vm.getValue = SuggestService.getFunctionByType({
            type: vm.type,
            useSpectator: vm.useSpectator
        });

        vm.onSuggestSelect = function($item, $model, $label) {
            vm.$item = $item;
            vm.$model = $model;
            vm.$label = $label;
            if (Number($model.isFound) === 0) {
                return;
            }
            return vm.onSelect($model, $item);
        };
    };
}
