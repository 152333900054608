defaultPointTrackingDetailsCtrl.$inject = [];

export default function defaultPointTrackingDetailsCtrl() {
    this.$onInit = () => {
        const vm = this;
        vm.points = vm.details.points;
        vm.pointTrackingTypeCode = vm.details.pointTrackingTypeCode;
        vm.userNotes = vm.details.userNotes;
    };
}
