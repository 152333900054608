import loginAsConfirmActionModalTpl from './loginAsConfirmActionModal.tpl.html';

LoginAsConfirmActionModalService.$inject = ['$uibModal'];

export default function LoginAsConfirmActionModalService($uibModal) {
    return {
        open
    };

    function open() {
        return $uibModal.open({
            controller: 'loginAsConfirmActionModalCtrl',
            controllerAs: 'vm',
            templateUrl: loginAsConfirmActionModalTpl,
            size: 'md'
        });
    }

}
