import _ from 'lodash';

applauseLeaderboardWidgetCtrl.$inject = ['Session'];

export default function applauseLeaderboardWidgetCtrl(Session) {
    this.$onInit = () => {
        // Passed passed from component bindings:
        // vm.widget
        // vm.contact

        const vm = this;

        vm.leaderboard = _.get(vm.widget, 'data.leaderboard');
        vm.hasData = vm.leaderboard;
        if (!vm.hasData) {
            return;
        }

        if (!vm.rankings) {
            vm.rankings = vm.leaderboard.rankings;
        }
    };
}
