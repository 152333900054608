import angular from 'angular';

import '../navLink/navLink.module';

import taskbarCtrl from './taskbar.controller';

export default angular.module('app.nav.taskbar', [
    'app.nav.navLink'
])
.controller('taskbarCtrl', taskbarCtrl);
