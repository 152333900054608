import snapshotNavigateTpl from './snapshotNavigate.tpl.html';
import snapshotNavigateCtrl from './snapshotNavigate.controller';

export default {
    templateUrl: snapshotNavigateTpl,
    controller: snapshotNavigateCtrl,
    controllerAs: 'vm',
    bindings: {
        activePageIndex: '=',
        widgetPages: '<',
        updateActiveSet: '<'
    }
};
