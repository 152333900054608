scheduledReportsGridCtrl.$inject = [
    'GridColumnTypes', 'GridColumnFilterTypes', 'GridFilterOperators', 'Prompt', 'ReportsService', 'RnDxGridService',
    'Periods'
];

export default function scheduledReportsGridCtrl(
    GridColumnTypes, GridColumnFilterTypes, GridFilterOperators, Prompt, ReportsService, RnDxGridService,
    Periods
) {
    this.$onInit = () => {
        const vm = this;

        vm.gridID = vm.gridID || 'grid-reports-scheduled';

        let gridInstance;

        vm.columns = [{
            nonDevExOptions: {
                isOptionsColumn: true,
                captionTranslateKey: 'reports_OPTIONS_COLUMN_HEADING', // 'Options'
                cellTemplate: `
                    <grid-option-button on-click="vm.delete(cell)"
                                        text-translate-key="reports_DELETE_ICON_TITLE"
                                        button-class="delete-button"
                                        icon-class="fa fa-trash">
                    </grid-option-button>`
            }
        }, {
            dataField: 'name',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'reports_NAME_COLUMN_HEADING' // 'Name'
            }
        }, {
            dataField: 'filtersText',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'reports_CUSTOMIZATIONS_COLUMN_HEADING' // 'Customizations'
            }
        }, {
            dataField: 'frequency',
            customizeText(cellInfo) {
                if (!cellInfo.value) {
                    return;
                }
                const periodProperties = Periods.properties[cellInfo.value];
                return periodProperties.phrase;
            },
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.CHECKBOX,
                captionTranslateKey: 'reports_FREQUENCY_COLUMN_HEADING', // 'Frequency'
                listOptions: ReportsService.getScheduleListOptions(),
            }
        }, {
            dataField: 'nextReport',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'reports_NEXT_REPORT_COLUMN_HEADING' // 'Next Report'
            }
        }];

        vm.extraDataGridOptions = {
            onInitialized(e) {
                gridInstance = e.component;
            }
        };

        vm.delete = async (cell) => {
            await Prompt.open({ type: 'warning' }).result;
            await ReportsService.deleteReportRequest(cell.data);
            return gridInstance.refresh();
        };
    };
}
