// wrappers
import formControlTpl from './wrapper/formControl.tpl.html';
import validationTpl from './wrapper/validation.tpl.html';
import labelTpl from './wrapper/label.tpl.html';
import fieldsetTpl from './wrapper/fieldset.tpl.html';
import hasErrorTpl from './wrapper/hasError.tpl.html';
import nestedFormWrapperTpl from './wrapper/nestedFormWrapper.tpl.html';

// types
import checkboxTpl from './customTypes/checkbox.tpl.html';
import ratingTpl from './customTypes/rating.tpl.html';
import checkScopeTpl from './checkScope.tpl.html';

formlyConfig.$inject = ['formlyConfigProvider'];

export default function formlyConfig(formlyConfigProvider) {
    // Wrappers
    formlyConfigProvider.setWrapper([
        {
            name: 'validation',
            templateUrl: validationTpl
        }, {
            name: 'formControl',
            templateUrl: formControlTpl
        }, {
            name: 'label',
            templateUrl: labelTpl
        }, {
            name: 'fieldset',
            templateUrl: fieldsetTpl
        }, {
            name: 'hasError',
            templateUrl: hasErrorTpl
        }, {
            name: 'nestedFormWrapper',
            templateUrl: nestedFormWrapperTpl
        }
    ]);

    // Custom Types
    formlyConfigProvider.setType([
        {
            name: 'customCheckbox',
            templateUrl: checkboxTpl,
            wrapper: ['validation', 'formControl', 'hasError'],
            defaultOptions: {
                templateOptions: {
                    checkedCssClass: 'checked'
                }
            }
        }, {
            name: 'customRating',
            templateUrl: ratingTpl,
            wrapper: ['validation', 'formControl', 'label', 'hasError'],
            controller: ['$scope', function($scope) {
                $scope.rating = -1;
                // scope.rating is separate from scope.model because uib-rating can only set ints as values.
                // So we need to update scope.model for it to be sent on form submit.

                const defaultSelectedOption = $scope.to.options.find(option => option.isSelected);
                if (defaultSelectedOption) {
                    $scope.rating = defaultSelectedOption.displayOrder;
                    $scope.model[$scope.options.key] = defaultSelectedOption.answerOption;
                    $scope.overStar = defaultSelectedOption;
                }

                $scope.hoveringOver = function(value) {
                    if (!$scope.to.static) {
                        $scope.overStar = $scope.to.options[value - 1];
                    }
                };

                $scope.hoveringLeave = function() {
                    if (!$scope.to.static) {
                        $scope.overStar = null;
                    }
                };

                $scope.$watch('rating', function(newRating) {
                    if (newRating <= 0 || $scope.to.static) {
                        return;
                    }
                    const answerOptions = $scope.options.templateOptions.options;
                    const valueProp = $scope.options.templateOptions.valueProp;
                    // update the model with the valueProp when rating changes
                    if (answerOptions && valueProp) {
                        $scope.model[$scope.options.key] = answerOptions[newRating - 1][valueProp];
                    }
                });
            }]
        }, {
            name: 'checkScope',
            templateUrl: checkScopeTpl
        }
    ]);
}
