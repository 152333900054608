import angular from 'angular';

import '../../core/core.module';
import './submissionsGrid/submissionsGrid.module';
import './submissionsContactPage/submissionsContactPage.module';
import './submissionsHierarchyPage/submissionsHierarchyPage.module';
import './submissionsSummaryGrid/submissionsSummaryGrid.module';
import './submissionsSummaryPage/submissionsSummaryPage.module';

import submissionsUrlConfig from './submissionsUrl.config';
import SubmissionsGridService from './submissionsGridService.factory';

export default angular.module('app.submissions', [
    'app.core',
    'app.submissions.submissionsGrid',
    'app.submissions.contactPage',
    'app.submissions.hierarchyPage',
    'app.submissions.submissionsSummaryGrid',
    'app.submissions.submissionsSummaryPage'
])
.config(submissionsUrlConfig)
.factory('SubmissionsGridService', SubmissionsGridService);
