import angular from 'angular';

import '../../../core/core.module';

import deleteWarningCtrl from './deleteWarning/deleteWarning.controller';
import prompt from './prompt.factory';

export default angular.module('app.blocks.modal.prompt', [
    'app.core',
])
.factory('Prompt', prompt)
.controller('deleteWarningCtrl', deleteWarningCtrl);
