import toFromHeaderTpl from './toFromHeader.tpl.html';
import toFromHeaderCtrl from './toFromHeader.controller';

export default {
    templateUrl: toFromHeaderTpl,
    controller: toFromHeaderCtrl,
    controllerAs: 'vm',
    bindings: {
        toContact: '<',
        fromContact: '<',
        iconContact: '<',
        category: '@',
        teamName: '@',
        recipients: '<'
    }
};
