import _ from 'lodash';

/**
 * @param {GridPageOptions} options
 * @returns {string}
 */
export default ({
    grid,
    template: {
        header = '',
        translateHeader = true,
        containerClass = '',
        headerTransclude = '',
        gridComponentBindings = '',
    }
}) => `<grid-page-container
        header="${header}"
        translate-header="${translateHeader}"
        class="${containerClass}">

        ${headerTransclude && `<header-transclude>${headerTransclude}</header-transclude>`}

        <${grid.componentName}
                initial-filters="vm.initialFilters"
                initial-columns="vm.initialColumns"
                initial-sort="vm.initialSort"
                update-url-params="true"
                ${_.isFunction(gridComponentBindings) ? gridComponentBindings(grid) : gridComponentBindings}>
        </${grid.componentName}>

    </grid-page-container>`;
