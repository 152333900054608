StrengthsService.$inject = ['LangUtils', 'Session'];

export default function StrengthsService(LangUtils, Session) {

    function getStrengthBadgeImageSrc(strengthID) {
        const company = Session.getCompany();
        const cloudPublicURL = company.cloudRootURLs.public;
        const strengthCloudContainerType = 10;
        const strengthsCloudSubFolder = company.cloudContainers
            .find(cloudContainer => cloudContainer.typeID === strengthCloudContainerType);
        const strengthsCloudSubfolderName = strengthsCloudSubFolder.name;
        const langCode = LangUtils.getLang().toUpperCase();

        return `${cloudPublicURL}/${strengthsCloudSubfolderName}/${langCode}/${strengthID}.jpg`;
    }

    return {
        getStrengthBadgeImageSrc
    };
}
