import detailTpl from './detail.tpl.html';
import detailCtrl from './detail.controller';

export default {
    templateUrl: detailTpl,
    controller: detailCtrl,
    controllerAs: 'vm',
    bindings: {
        content: '<',
        roles: '<',
        contentTopicsList: '<',
    },
};
