import _ from 'lodash';

deactivateUserCtrl.$inject = ['$state', '$translate', 'ContactService', 'Session', 'SuggestsTypes'];

export default function deactivateUserCtrl($state, $translate, ContactService, Session, SuggestsTypes) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            onSuccess: () => $state.reload(),
            onCancel: _.noop
        });

        vm.user = Session.getUser();

        vm.model = {};

        vm.contactName = `${vm.contact.firstName} ${vm.contact.lastName}`;
        vm.contactManagerName = vm.contact.manager ? `${vm.contact.manager.firstName} ${vm.contact.manager.lastName}` : 'N/A';

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_USER_BUTTON_SUBMIT', // Deactivate
                    isDisabled: vm.user.userID === vm.contact.userID
                }
            }
        };

        vm.fields = [
            {
                noFormControl: true,
                template: `
                    <h4 class="deactivate-fields-header"
                        translate="app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_USER_MOVE_USERS">
                        1) Move Users
                    </h4>`
            }, {
                key: 'isReportToCurrentManager',
                type: 'customRadioInput',
                templateOptions: {
                    options: [{
                        // '{{ managerName }} ({{ contactName }}'s Current Manager)'
                        translateKey: 'app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_USER_RADIO_BUTTON_CURRENT_MANAGER',
                        translateValues: {
                            managerName: vm.contactManagerName,
                            contactName: vm.contactName
                        },
                        value: true,
                        isSelected: true // Set contact's current manager as the default choice
                    }, {
                        // 'Other (Search)'
                        name: $translate.instant('app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_USER_RADIO_BUTTON_OTHER_MANAGER'),
                        value: false
                    }],
                    // 'Employees currently reporting to {{ contactName }} should now report to: '
                    label: $translate.instant('app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_REPORT_TO_LABEL', {
                        contactName: vm.contactName
                    }),
                    labelClass: 'report-to-label'
                }
            }, {
                key: 'managerLookup',
                type: 'customSuggest',
                data: {
                    contactID: vm.contact.userID,
                },
                hideExpression: function() {
                    return vm.model.isReportToCurrentManager;
                },
                templateOptions: {
                    type: SuggestsTypes.NOT_DOWNSTREAM,
                    placeholderTranslateKey: 'app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_MANAGER_LOOKUP_PLACEHOLDER',
                    required: true
                }
            }, {
                noFormControl: true,
                template: `
                    <h4 class="deactivate-fields-header"
                        translate="app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_USER_OPTIONS">
                        2) Options
                    </h4>`
            }, {
                key: 'deactivateOn',
                type: 'customCalendar',
                templateOptions: {
                    // 'Should this user still be allowed to log in to redeem their points? If so, specify the cut off date'
                    labelTranslateKey: 'app_CONTACT_PANEL_ADMIN_TOOLS_DEACTIVATE_ON_DATE_LABEL',
                    labelClass: 'deactivate-on-label',
                    format: 'MMMM dd, yyyy'
                }
            },
        ];

        vm.submit = () => ContactService.deactivateContact({
            contactID: vm.contact.userID,
            replacementManagerID: vm.model.managerLookup ? vm.model.managerLookup.ID : vm.contact.manager.ID,
            deletedGraceDate: vm.model.deactivateOn
        });

        vm.onFail = (err) => {
            if (err.data && err.data.phrase) {
                vm.errorMessage = err.data.phrase;
            } else {
                throw err;
            }
        };
    };
}
