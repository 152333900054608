import contactPanelTpl from './contactPanel.tpl.html';
import contactPanelCtrl from './contactPanel.controller';

export default {
    templateUrl: contactPanelTpl,
    controller: contactPanelCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        hideManager: '<',
        showTools: '<',
        showLoginAsNav: '<'
    }
};
