import socialPostFormTpl from './socialPostForm.tpl.html';
import socialPostFormCtrl from './socialPostForm.controller';

export default {
    templateUrl: socialPostFormTpl,
    controller: socialPostFormCtrl,
    controllerAs: 'vm',
    bindings: {
        onSubmitSuccess: '<',
        onSubmitFail: '<'
    }
};
