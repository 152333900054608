// Separate config needed because of https://stackoverflow.com/questions/23658846/angular-ui-router-urlrouterprovider-when-not-working-anymore

accountUrlConfig.$inject = ['$urlRouterProvider'];

export default function accountUrlConfig($urlRouterProvider) {
    // TODO: create shared function for snapshotUrl.config.js, accountUrl.config.js, datafeedUrl.config.js, rewardUrl.config.js
    // TODO: find a way to include all cases in one $urlRouterProvider.when

    // When URL is just "/account" or "/account/someText", go to own account
    $urlRouterProvider.when(/^\/account\/*$/i, ['$state', '$location', function ($state, $location) {
        const params = $location.search();
        $state.go('account', params);
        return true;
    }]);

    $urlRouterProvider.when(/^\/account\/confirmed\/*$/i, ['$state', '$location', function ($state, $location) {
        const params = $location.search();
        params.tab = 'confirmed';
        $state.go('account', params);
        return true;
    }]);

    $urlRouterProvider.when(/^\/account\/pending\/*$/i, ['$state', '$location', function ($state, $location) {
        const params = $location.search();
        params.tab = 'pending';
        $state.go('account', params);
        return true;
    }]);

    $urlRouterProvider.when(/^\/account\/declined\/*$/i, ['$state', '$location', function ($state, $location) {
        const params = $location.search();
        params.tab = 'declined';
        $state.go('account', params);
        return true;
    }]);
}
