export default {
    geometry: {
        startAngle: 180,
        endAngle: 0
    },
    scale: {
        tickInterval: 1,
        tick: {
            color: '#9c9c9c'
        },
        minorTick: {
            color: '#9c9c9c',
            visible: true
        },
        label: {
            indentFromTick: 5,
        }
    },
    valueIndicator: {
        width: 8,
        offset: 30,
        type: 'triangleNeedle',
        color: 'orange'
    },
    subvalueIndicator: {
        type: 'triangleMarker',
        offset: 21,
        color: '#9e136e',
        width: 15
    },
    rangeContainer: {
        offset: 30,
        width: 14
    }
};
