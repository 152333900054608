import inputTpl from './input.tpl.html';

customInputRun.$inject = ['formlyConfig'];

export default function customInputRun(formlyConfig) {
    formlyConfig.setType({
        name: 'customInput',
        templateUrl: inputTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            templateOptions: {
                type: 'text'
            },
            modelOptions: {
                debounce: {
                    default: 200,
                    blur: 0
                },
                updateOn: 'default blur'
            }
        }
    });
}
