TrainingQuizHttpService.$inject = ['$http'];

export default function TrainingQuizHttpService($http) {

    /**
     * For starting a new training quiz submission.
     * @param {Object} params
     * @param {int} params.promoID
     */
    function createQuizResults({ promoID }) {
        return $http.post(`/api/promos/${promoID}/training`)
            .then(function(res) {
                return res.data;
            });
    }

    /**
     * Checks if the user has any past submissions for a certain training quiz.
     * @param {Object} params
     * @param {int} params.promoID
     */
    function checkExistingQuizResults({ promoID }) {
        return $http.get(`/api/promos/${promoID}/training`)
            .then(function(res) {
                return res.data;
            });
    }

    /**
     * For discarding a previously started but incomplete training quiz submission.
     * @param {Object} params
     * @param {int} params.promoID
     * @param {int} params.quizResultID
     */
    function cancelQuizResults({ promoID, quizResultID }) {
        return $http.delete(`/api/promos/${promoID}/training/${quizResultID}`)
            .then(function(res) {
                return res.data;
            });
    }

    /**
     * For saving answer(s) to a training quiz the user is currently doing.
     * @param {Object} params
     * @param {int} params.promoID
     * @param {int} params.quizResultID
     * @param {Object} params.quizAnswers
     */
    function saveAnswers({ promoID, quizResultID, quizAnswers }) {
        return $http.post(`/api/promos/${promoID}/training/${quizResultID}/answers`, {
            quizAnswers
        })
        .then(function(res) {
            return res.data;
        });
    }

    return {
        createQuizResults,
        checkExistingQuizResults,
        cancelQuizResults,
        saveAnswers,
    };
}
