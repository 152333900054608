import widgetCreatorTpl from './widgetCreator.tpl.html';
import widgetCreatorCtrl from './widgetCreator.controller';

export default {
    restrict: 'E',
    templateUrl: widgetCreatorTpl,
    controller: widgetCreatorCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '=',
        contact: '<',
        dropWidgetById: '<',
        defaultXml: "<",
        doNotLoad: '<',
    }
};
