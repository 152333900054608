import angular from 'angular';

import '../../../core/core.module';
import '../../../blocks/storage/storage.module';

import AuthDialogHandler from './authDialogHandler.factory';
import warningDialogCtrl from './warningDialog.controller';
import loginDialogCtrl from './loginDialog.controller';

export default angular.module('app.auth.dialog', [
    'app.core',
    'app.blocks.storage'
])
.factory('AuthDialogHandler', AuthDialogHandler)
.controller('warningDialogCtrl', warningDialogCtrl)
.controller('loginDialogCtrl', loginDialogCtrl);
