widgetFooterCtrl.$inject = ['WidgetDataDisplayTypes'];

export default function widgetFooterCtrl(WidgetDataDisplayTypes) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.footnotes

        vm.hasWidgetFootNote = vm.footnotes.length !== 0;
        vm.WidgetDataDisplayTypes = WidgetDataDisplayTypes;
    };
}
