dynamicTemplate.$inject = ['$compile'];

export default function dynamicTemplate($compile) {
    return {
        restrict: 'E',
        scope: {
            template: '@'
        },
        link: function(scope, element) {
            const template = scope.template;
            const compiled = $compile(template)(scope.$parent);
            element.replaceWith(compiled);
        },
    };
}
