export default function DropdownSelectionService() {

    // filter is the model for user's currently selected filter settings
    // { field, operator, type, value }
    let filter;

    // isEditing = true --> dropdown should use same column and value as current filter (for editing a filter)
    // isEditing = false --> dropdown should clear column and value (for adding a new filter)
    let isEditing = false;

    // The columns the user can choose to filter the table by (Transaction name, Points, Order Date, etc.)
    // Can vary across different grids
    let columns;

    let listValues;

    return {
        getFilter: () => filter,
        setFilter: (newFilter) => filter = newFilter,

        getColumns: () => columns,
        setColumns: (newColumns) => columns = newColumns,

        isEditing: () => isEditing,
        setEditing: (bool) => isEditing = bool,

        getListValues: () => listValues,
        setListValues: (newListValues) => listValues = newListValues,
    };
}
