import legacyFramePageTpl from './legacyFramePage.tpl.html';

legacyFramePageConfig.$inject = ['$stateProvider'];

export default function legacyFramePageConfig($stateProvider) {
    $stateProvider.state('legacyFramePage', {
        parent: 'auth',
        url: '/legacy-frame/{urlPath:any}',
        params: {
            paramPath: null
        },
        views: {
            'main@': {
                controller: 'legacyFramePageCtrl as vm',
                templateUrl: legacyFramePageTpl
            }
        },
        data: {
            // "data" needs to be an object or else things break, TODO: see if possible to default all state.data to {}
        }
    });
}
