import angular from 'angular';

import chooseRecognitionRun from './chooseRecognition.run';
import ChooseRecognitionModal from './chooseRecognitionModal.factory';
import chooseRecognition from './chooseRecognition.component';

export default angular.module('app.spotlight.spotlightComponents.chooseRecognition', [

])
.run(chooseRecognitionRun)
.factory('ChooseRecognitionModal', ChooseRecognitionModal)
.component('chooseRecognition', chooseRecognition);
