registrationModalCtrl.$inject = ['$state', '$scope', '$sce', '$translate', 'ContentService', 'permissionGroupOptions'];

export default function registrationModalCtrl($state, $scope, $sce, $translate, ContentService, permissionGroupOptions) {
    const vm = this;

    vm.showSuccessHtml = false;
    vm.permissionGroupOptions = permissionGroupOptions;

    const fallbackHTML = `<p class="fallback-registration-message">
                            ${$translate.instant('app_REGISTRATION_FALLBACK_MESSAGE')}
                         </p>`; // Thank you for registering

    // TODO: make this a shared function in registration service and use on the registration page as well
    vm.onSuccess = function() {
        return ContentService.getContentByCode('registercomplete')
            .then(function({ content } = {}) {
                vm.successHtml = content || fallbackHTML;
                vm.showSuccessHtml = true;
            });
    };
}
