import _ from 'lodash';

const StatCodes = {
    CURRENT_VALUE: 'CURRENT_VALUE',
    NEXT_TARGET: 'NEXT_TARGET',
    VALUE_TO_NEXT_TARGET: 'VALUE_TO_NEXT_TARGET',
    PERCENT_TO_NEXT_TARGET: 'PERCENT_TO_NEXT_TARGET'
};

incentivePayoutGaugeWidgetCtrl.$inject = ['$translate', 'GaugeWidgetService', 'WidgetService'];

export default function incentivePayoutGaugeWidgetCtrl($translate, GaugeWidgetService, WidgetService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        // TODO: Work on this after removing pointTargets
        vm.hasData = vm.widget.data && vm.widget.data.widgetTargets && !_.isEmpty(vm.widget.data.widgetTargets.pointTargets);
        if (!vm.hasData) {
            return;
        }

        // don't display footer value if 0 or negative number, change the footer message to reflect that submissions are closed
        // TODO: consider not sending footer.value from the DB when value is 0 or a negative number
        if (vm.widget.data.footer && vm.widget.data.footer.value <= 0) {
            vm.widget.data.footer.value = null;
            vm.widget.data.footer.message = $translate.instant('snapshot_INCENTIVE_PAYOUT_GAUGE_SUBMISSIONS_CLOSED_FOOTER'); // Submissions are closed
        }

        const { nextTarget, valueToNextTarget, percentToNextTarget } = GaugeWidgetService.calculateNextTargetDetails(vm.widget.data.widgetTargets);

        /*
            If a stat is sent from the DB without a value, it will include a code. The code is used to tell the frontend which
            calculation must be performed in order to assign the correct value
        */

        const statCodesToValues = {
            [StatCodes.CURRENT_VALUE]: vm.widget.data.widgetTargets.standingValue,
            // In the case that all targets were met, display a phrase saying targets were met.
            [StatCodes.NEXT_TARGET]: nextTarget || $translate.instant('app_GAUGE_WIDGET_ALL_TARGETS_MET'), // 'All Targets Met!'
            [StatCodes.VALUE_TO_NEXT_TARGET]: valueToNextTarget,
            [StatCodes.PERCENT_TO_NEXT_TARGET]: percentToNextTarget
        };

        if (vm.widget.data.stats) {
            vm.widget.data.stats
                .filter(stat => stat.code)
                .forEach(stat => {
                    stat.value = statCodesToValues[stat.code];
                });
        }

        vm.gaugeDetails = vm.widget.data.widgetTargets;
        vm.gaugeDetails.tooltipDisplayType = _.get(vm.widget.data, 'extraJSON.tooltip.displayType');
        vm.gaugeDetails.overrideConfig = WidgetService.getOverrideConfig(vm.widget.data);
    };
}
