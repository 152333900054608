import _ from 'lodash';

customInputRun.$inject = ['$translate', 'formlyConfig'];

export default function customInputRun($translate, formlyConfig) {
    formlyConfig.setType({
        name: 'customIntInput',
        extends: 'customInput',
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            templateOptions: {
                type: 'number'
            },
            validators: createValidators({
                isInteger: {
                    expression(viewValue, modelValue) {
                        const value = modelValue != null ? modelValue : viewValue;
                        return _.isInteger(value);
                    },
                    // 'Please enter a whole number (no decimals)'
                    message: () => $translate.instant('app_CUSTOM_INT_INPUT_VALIDATION_IS_INTEGER')
                },
                // formly already handles "min" and "max" validators, we just need to specify messages.
            }),
            validation: {
                messages: {
                    min: (viewValue, modelValue, scope) => {
                        if (scope.to.min === 0) {
                            // 'Can't be a negative value'
                            return $translate.instant('app_CUSTOM_INT_INPUT_VALIDATION_NEGATIVE');
                        }
                        // 'Minimum is {{ min }}'
                        return $translate.instant('app_CUSTOM_INT_INPUT_VALIDATION_MIN', { min: scope.to.min });
                    },
                    // 'Maximum is {{ max }}'
                    max: (viewValue, modelValue, scope) => $translate.instant('app_CUSTOM_INT_INPUT_VALIDATION_MAX', {
                        max: scope.to.max
                    })
                }
            }
        }
    });
}

/**
 * Helper so that each expression doesn't have to handle the "required" case and have to explicitly return a boolean.
 * TODO: share this function with other formly types
 * @param {Object} validators
 * @returns {Object}
 */
const createValidators = (validators) => _.mapValues(validators, ({ expression, message }) => ({
    expression: !_.isFunction(expression) ? expression : (viewValue, modelValue, scope) => {
        const value = modelValue || viewValue;
        if (!scope.to.required && (value == null || value === '')) {
            // Handled by "required" validator
            return true;
        }
        const result = expression(viewValue, modelValue, scope);
        return Boolean(result);
    },
    message
}));
