import angular from 'angular';

import suggestInput from './suggestInput.directive';
import SuggestService from './suggestService.factory';

export default angular.module('app.suggest', [
    'app.core'
])
.directive('suggestInput', suggestInput)
.factory('SuggestService', SuggestService);
