import gaugeWidgetTpl from './gaugeWidget.tpl.html';
import gaugeWidgetCtrl from './gaugeWidget.controller';

export default {
    templateUrl: gaugeWidgetTpl,
    controller: gaugeWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        gaugeDetails: '<'
    }
};
