import _ from 'lodash';
import applauseTpl from './applause.tpl.html';

applauseFormPageConfig.$inject = ['$stateProvider'];

export default function applauseFormPageConfig($stateProvider) {

    const commonConfig = {
        parent: 'auth',
        views: {
            'main@': {
                controller: 'applauseCtrl as vm',
                templateUrl: applauseTpl
            }
        },
        params: {
            teamName: null,
            message: null,
            recipients: null,
            group: null
        },
        data: {
            permission: 'Applaud'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('applauseForm');
            }],
            contact: ['$stateParams', 'ContactService', function($stateParams, ContactService) {
                return $stateParams.contactID ? ContactService.getContact($stateParams.contactID) : null;
            }]
        }
    };

    $stateProvider.state('applauseForm', _.merge({}, commonConfig, {
        url: '/applause-form?contactID?categoryType&messageCategoryID',
        data: {
            pageTitle: 'Applause Form',
        },
        resolve: {
            applause: ['$stateParams', 'ApplauseService', function ($stateParams, ApplauseService) {
                return ApplauseService.getApplauseForm($stateParams.categoryType);
            }]
        }
    }));
    $stateProvider.state('cultureStoryForm', _.merge({}, commonConfig, {
        url: '/culture-story-form?contactID&messageCategoryID',
        data: {
            isCultureStory: true,
            pageTitle: 'Culture Story Form',
        },
        resolve: {
            applause: ['ApplauseService', 'ApplauseCategoryTypes', function (ApplauseService, ApplauseCategoryTypes) {
                return ApplauseService.getApplauseForm(ApplauseCategoryTypes.CULTURE_STORY);
            }]
        }
    }));
}
