import _ from 'lodash';
import ApiRequestError from './ApiRequestError';

ExceptionDecorator.$inject = ['$provide'];

export default function ExceptionDecorator($provide) {
    // Handles opening modal and auditing when an uncaught exception occurs.
    // (Can be front end javascript errors or HTTP request errors)
    $provide.decorator('$exceptionHandler', exceptionHandler);
}

const exceptionHandler = ['$injector', ($injector) => async (err, cause) => {

    const url = err.config ? err.config.url : '';
    const isRequestError = Boolean(url);

    /* TODO: This ignore error statement should be removed.
     * Once we update the angular version and code in angular > 2.0
     * and update all forms by removing formly.
     */
    const isIgnoreError = err.message && (
        err.message.includes('Making copies of Window or Scope instances is not supported')
        || err.message.includes('[ng:cpws]')
        || err.message.includes('[$rootScope:infdig]')
    );

    if (isIgnoreError) {
        return;
    }
    if (isRequestError && err.status === -1) {
        // Cancelled http requests
        return;
    }

    if (err.disableDefaultHandling) {
        // Errors where nothing should happen by default (no popup, no recording to API).
        if (err.message) {
            console.log(err.message);
        }
        return;
    }

    const ErrorService = $injector.get('ErrorService');
    const ErrorModal = $injector.get('ErrorModal');
    const AuthTimeoutService = $injector.get('AuthTimeoutService');

    console.error(err);
    if (cause) {
        console.error(cause);
    }

    if (AuthTimeoutService.isAppLocked()) {
        console.warn('received error when app is locked, don\'t display pop-up');
        return;
    }


    if (err instanceof ApiRequestError) {
        // Auditing happened on the API server already and an errorID should be available.
        if (err.config && !err.config.disableErrorModal) {
            ErrorModal.open(err);
        }
        return;
    }

    const disableErrorModal = err.config && err.config.disableErrorModal;
    const openErrorModal = disableErrorModal ? _.noop : ErrorModal.open;

    try {
        const res = await ErrorService.recordBrowserError(err, cause);
        err.data = _.assign(err.data, res.data);
        openErrorModal(err);
    } catch (loggingError) {
        console.error('Failed to record browser error');
        console.error(loggingError);
        openErrorModal();
    }
}];
