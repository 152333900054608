import trainingQuizQuestionTpl from './trainingQuizQuestion.tpl.html';
import trainingQuizQuestionCtrl from './trainingQuizQuestion.controller';

export default {
    templateUrl: trainingQuizQuestionTpl,
    controller: trainingQuizQuestionCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        isGameQuiz: '<',
        quizResultID: '<quizResultId',
        question: '<',
        selectAnswerID: '<selectAnswerId',
        correctAnswerID: '<correctAnswerId',
        explanation: '@',
        isDisabled: '<',
        submitSuccessCallback: '<onSubmitSuccess',
        submitFailCallback: '<onSubmitFail'
    }
};
