import _ from 'lodash';

customTeamNameRun.$inject = ['$translate', 'formlyConfig'];

export default function customTeamNameRun($translate, formlyConfig) {

    const MAX_RECIPIENTS_WITHOUT_TEAM_NAME = 3;

    formlyConfig.setType({
        name: 'customTeamName',
        extends: 'customInput',
        defaultOptions: {
            ngModelElAttrs: {
                'select-on-click': 'to.selectOnClick'
            },
            expressionProperties: {
                'templateOptions.selectOnClick': function (viewValue, modelValue, $scope) {
                    return $scope.fc && !$scope.fc.$touched;
                },
                'data.autoFill': function(viewValue, modelValue, $scope) {
                    // Running updateTeamName in this expressionProperty is a workaround.
                    // (the name 'data.autoFill' doesn't matter, it's not a special property or anything)
                    // This is because if updateTeamName is set up as a $scope watch function in the controller instead,
                    // it won't run when hideExpression is true.
                    // (hideExpression seems to destroy the field completely (like ng-if)).
                    $scope.updateTeamName();
                },
            },
        },
        controller: ['$scope', '$timeout', function($scope) {
            const { options, model } = $scope;
            const { groupKey, multiKey, showGroupTeamNameKey } = options.data;

            $scope.updateTeamName = () => {
                const recipients = model[multiKey];
                const group = model[groupKey];
                const showGroupTeamName = model[showGroupTeamNameKey];
                if ($scope.fc && $scope.fc.$dirty) {
                    return;
                }
                if (showGroupTeamName && (group && group.ID !== -1)) {
                    model[options.key] = group && model[groupKey].phrase;
                } else if (!showGroupTeamName && recipients) {
                    model[options.key] = getDefaultRecipientsTeamName();
                }
            };

            function getDefaultRecipientsTeamName() {
                const recipients = model[multiKey];
                const noNameNeeded = _.isEmpty(recipients) || recipients.length === 1;
                const userMustProvideName = recipients.length > MAX_RECIPIENTS_WITHOUT_TEAM_NAME;
                const andPhrase = ` ${$translate.instant('app_CUSTOM_TEAM_NAME_AND')} `; // 'and'

                if (noNameNeeded || userMustProvideName) {
                    return;
                }
                const firstNames = recipients.map(recipient => recipient.phrase.trim().split(' ')[0]);
                if (recipients.length === 2) {
                    return firstNames.join(andPhrase);
                }
                return firstNames.slice(0, firstNames.length - 1).join(', ')
                    + andPhrase + firstNames[firstNames.length - 1];
            }
        }]
    });
}
