import angular from 'angular';

import './config/coreConfig.module';
import './utils/coreUtils.module';
import './directive/coreDirectives.module';
import './filter/coreFilters.module';
import './constant/coreConstants.module';


import './uibExtra/uibExtra.module';

export default angular.module('app.core', [
    'app.core.config',
    'app.core.utils',
    'app.core.directives',
    'app.core.filters',
    'app.core.constants',
    'app.core.uibExtra',
]);
