import $ from 'jquery';

MessageService.$inject = [
    '$http', '$sce', '$location', '$rootScope', '$state', '$stateParams', 'UrlUtils', 'LangUtils', 'PdfService',
    'Communication', 'StateUtils', 'SignOnTasks', 'ModalService'
];

export default function MessageService(
    $http, $sce, $location, $rootScope, $state, $stateParams, UrlUtils, LangUtils, PdfService,
    Communication, StateUtils, SignOnTasks, ModalService
) {

    const Events = {
        MESSAGE_OPENED: 'MESSAGE_OPENED'
    };

    async function getMessageDetails(messageRecipientID) {
        const res = await $http.get(`/api/messages/${messageRecipientID}`);
        return res.data;
    }

    async function getMessageLegacyHtml(messageRecipientID) {
        const res = await $http.get(`/api/messages/${messageRecipientID}/legacy-html`);
        return res.data;
    }

    function getMessageDetailsIframeSrc(messageRecipientID) {
        const legacyUrl = UrlUtils.getLegacyUrl();
        return $sce.trustAsResourceUrl(
            `${legacyUrl}/common/asp/MessageRecipient_Show.asp?MessageRecipientID=${messageRecipientID}`
        );
    }

    async function printMessage({ messageRecipientID, applauseID }) {
        if (applauseID) {
            return printApplause(applauseID);
        }
        return UrlUtils.loadLegacyPage(`/Messages_PrintMail.asp?MessageRecipientID=${messageRecipientID}`);
    }

    /**
     * Downloads an applause as a PDF file. PDF creation is completely browser-side.
     * Assumes that there's already an `<applause-details>` component in the DOM.
     * @param {int} applauseID
     * @returns {Promise}
     */
    async function printApplause(applauseID) {
        const $applauseDetails = $(`.applause-details.applause-id-${applauseID}`);
        if (!$applauseDetails.length) {
            return;
        }
        const $elementToPrint = $applauseDetails.clone();
        /* TODO: if we want to add contact icon back into applause, we need to work on the display - also need to remove .contact-icon from here */
        ['.spotlight-item-buttons', '.comment-section', '.contact-icon', 'points-alert-message'].forEach(selector => {
            $elementToPrint.find(selector).remove();
        });
        const languageID = LangUtils.getLanguageID();
        return PdfService.downloadElement($elementToPrint, {
            filename: `Applause_${applauseID}_${languageID}.pdf` // Match legacy server's filename format
        });
    }



    /**
     * Opens a message modal by looking for params in the URL.
     * @param {Object} params - Same params as `openModal`
     * @returns {Promise}
     */
    async function openFromUrl(params = {}) {
        const { messageRecipientIdParamName = 'messageRecipientID' } = params;
        const urlParams = $location.search();
        const messageRecipientID = Number(urlParams[messageRecipientIdParamName]);
        if (!messageRecipientID) {
            return;
        }
        if ($rootScope.isRedirectingAfterLogin) {
            SignOnTasks.cancelOpeningOptionalTasksAfterLogin();
        }
        return openModal({ ...params, messageRecipientID });
    }

    /**
     * @param {Object} params
     * @param {int} [params.messageRecipientID]
     * @param {Object} [params.messageDetails]
     * @param {string} [params.messageRecipientIdParamName]
     * @returns {Promise}
     */
    async function openModal({
        messageRecipientID, messageDetails, messageRecipientIdParamName = 'messageRecipientID'
    }) {
        if (!messageDetails) {
            messageDetails = await getMessageDetails(messageRecipientID);
        }
        const modalInstance = Communication.openByMessageType(messageDetails);
        ModalService.updateUrlParamsWithModal(modalInstance, { [messageRecipientIdParamName]: messageRecipientID });
        modalInstance.opened.then(() => {
            $rootScope.$broadcast(Events.MESSAGE_OPENED, { messageDetails, modalInstance });
        });
        return { messageDetails, modalInstance };
    }

    async function updateIsRead({ messageRecipientID, isRead }) {
        const res = await $http.put(`/api/messages/${messageRecipientID}/read`, { isRead });
        if (isRead) {
            await SignOnTasks.removeTask({ type: 'Message', messageRecipientID });
        }
        return res.data;
    }

    async function deleteMessage(messageRecipientID) {
        const res = await $http.delete(`/api/messages/${messageRecipientID}`);
        return res.data;
    }

    return {
        Events,
        getMessageDetails,
        getMessageLegacyHtml,
        getMessageDetailsIframeSrc,
        printMessage,
        openFromUrl,
        openModal,
        updateIsRead,
        deleteMessage,
    };
}
