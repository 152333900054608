import angular from 'angular';

import '../../promo/promoHeader/promoHeader.module';

import incentiveResults from './incentiveResults/incentiveResults.component';
import questionOverview from './questionOverview/questionOverview.component';
import answerDetails from './answerDetails/answerDetails.component';
import answerDetailsModal from './answerDetails/answerDetailsModal.component';
import percentKeyframeCss from './answerDetails/percentKeyframeCss.directive';

export default angular.module('app.communication.incentive', [
    'app.promo.promoHeader',
])
.component('incentiveResults', incentiveResults)
.component('questionOverview', questionOverview)
.component('answerDetails', answerDetails)
.component('answerDetailsModal', answerDetailsModal)
.directive('percentKeyframeCss', percentKeyframeCss);
