import participantEngagementWidgetTpl from './participantEngagementWidget.tpl.html';
import participantEngagementWidgetCtrl from './participantEngagementWidget.controller';

export default {
    templateUrl: participantEngagementWidgetTpl,
    controller: participantEngagementWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
