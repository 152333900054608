import reportFormModalTpl from './reportFormModal.tpl.html';

ReportFormModal.$inject = ['$uibModal'];

export default function ReportFormModal($uibModal) {

    function open({ defaultReportName, gridColumns, gridQueryOptions, submit, onSubmitSuccess }) {
        return $uibModal.open({
            templateUrl: reportFormModalTpl,
            controller: ['$scope', function($scope) {
                const vm = this;
                vm.defaultReportName = defaultReportName;
                vm.gridQueryOptions = gridQueryOptions;
                vm.gridColumns = gridColumns;
                vm.submit = submit;
                vm.onSubmitSuccess = onSubmitSuccess;
                vm.onEditFilters = $scope.$dismiss;
            }],
            controllerAs: 'vm',
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('reports');
                }]
            }
        });
    }

    return {
        open
    };
}
