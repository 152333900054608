import submittedFilesListTpl from './submittedFilesList.tpl.html';
import submittedFilesListCtrl from './submittedFilesList.controller';

export default function submittedFilesList() {
    return {
        restrict: 'E',
        templateUrl: submittedFilesListTpl,
        controller: submittedFilesListCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            files: '<',
            showRemoveButtons: '<',
            onRemoveFile: '<',
            parentClass: '@class'
        }
    };
}
