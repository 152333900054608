import angular from 'angular';

import '../../../../core/utils/coreUtils.module';
import '../../../../blocks/storage/storage.module'; // Session

import socialPostDetailsCtrl from './socialPostDetails.controller';
import socialPostDetails from './socialPostDetails.component';

export default angular.module('app.communication.socialPost.socialPostDetails', [
    'app.core.utils',
    'app.blocks.storage'
])
.controller('socialPostDetailsCtrl', socialPostDetailsCtrl)
.component('socialPostDetails', socialPostDetails);
