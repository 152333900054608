import angular from 'angular';

import gaugeWidget from './gaugeWidget.component';
import GaugeWidgetService from './gaugeWidgetService.factory';

export default angular.module('app.core.directives.gaugeWidget', [
    'app.core'
])
.component('gaugeWidget', gaugeWidget)
.factory('GaugeWidgetService', GaugeWidgetService);
