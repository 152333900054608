import filterModalTpl from './filterModal.tpl.html';

FilterModalService.$inject = ['$uibModal'];

export default function FilterModalService($uibModal) {
    return {
        openFilterModal
    };

    function openFilterModal(filter) {
        return $uibModal.open({
            templateUrl: filterModalTpl,
            controller: 'filterModalCtrl as vm',
            size: 'lg',
            resolve: {
                filter: () => filter
            }
        });
    }
}
