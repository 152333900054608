import angular from 'angular';
import articleModule from './article/article.module';

import contentConfig from './content.config';
import contentCtrl from './content.controller';

// import dependencies
import '../../core/core.module';

export default angular.module('app.content', [
    'app.core',

    articleModule.name
])
.config(contentConfig)
.controller('contentCtrl', contentCtrl);
