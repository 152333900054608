import angular from 'angular';

import '../../../core/core.module';

import quickLinksConfig from './quickLinks.config';
import quickLinksCtrl from './quickLinks.controller';

export default angular.module('app.nav.quickLinks', [
    'app.core',
])
.config(quickLinksConfig)
.controller('quickLinksCtrl', quickLinksCtrl);
