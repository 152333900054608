import SharedCompanyModel from '../../../../../shared/company.model';

Company.$inject = [];

export default function Company() {
    return class Company extends SharedCompanyModel {
        /**
         * @param {Object} companyData - JSON from Companies_getSession SP
         */
        constructor(companyData) {
            super(companyData);
        }
    };
}
