/**
 * @param {GridPageOptions} options
 * @returns {Array | Function}
 */
export default function createController({
    controller: customController,
}) {
    const customInjectNames = customController ? customController.$inject || customController.slice(0, -1) : [];

    gridPageCtrl.$inject = ['$state', 'StateUtils', '$injector', ...customInjectNames];

    function gridPageCtrl($state, StateUtils, $injector, ...customInjectFunctions) {
        const vm = this;

        if (customController) {
            const customInjects = customInjectNames.reduce((obj, name, i) => Object.assign(obj, {
                [name]: customInjectFunctions[i]
            }), {});
            $injector.invoke(customController, vm, customInjects);
        }

        vm.initialFilters = $state.params.filtersArray;
        vm.initialColumns = $state.params.columnsObject;
        vm.initialSort = $state.params.sortArray;
    }

    return gridPageCtrl;
}
