import accountPendingGridTpl from './accountPendingGrid.tpl.html';
import accountPendingGridCtrl from './accountPendingGrid.controller';

export default {
    templateUrl: accountPendingGridTpl,
    controller: accountPendingGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        contactID: '<contactId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<',
    }
};
