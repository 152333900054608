import datafeedSummaryCountWidgetTpl from './datafeedSummaryCountWidget.tpl.html';
import datafeedSummaryCountWidgetCtrl from './datafeedSummaryCountWidget.controller';

export default {
    templateUrl: datafeedSummaryCountWidgetTpl,
    controller: datafeedSummaryCountWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
