

class SharedModule {
    /**
     * @param {Object} params
     * @param {Company} params.company
     * @param {Contact} [params.user]
     */
    constructor({ company, user }) {
        this.company = company;
        this.user = user;
    }
}

module.exports = SharedModule;
