import angular from 'angular';

import './errorHandling/errorHandling.module';
import './modal/modal.module';
import './storage/storage.module';

export default angular.module('app.blocks', [
    'app.blocks.errorHandling',
    'app.blocks.modal',
    'app.blocks.storage',
]);
