import moment from 'moment';

previewCtrl.$inject = ['$uibModalInstance', '$rootScope', 'model', 'Session'];

export default function previewCtrl($uibModalInstance, $rootScope, model, Session) {
    const vm = this;
    vm.user = Session.getUser();

    // "model" is the formly model coming from the applause form controller.
    // So it has to be formatted to what applauseDetails expects.
    vm.model = model;

    // If not adding points to send applause then make budget and points information null.
    // So, It won't sendout points accidently.
    if (!vm.model.isAddingPoints) {
        vm.model.budget = null;
        vm.model.totalPoints = null;
        vm.model.selectedGuideline = null;
    }

    // If it's a team applause (there's a teamName), we don't have any data about the recipients to show.
    const applauseRecipients = vm.model.teamName ? [] : vm.model.recipients.map(recipient => ({
        ...recipient,
        name: recipient.phrase,
        userID: recipient.ID
    }));

    vm.previewApplause = {
        recipients: applauseRecipients,
        numRecipients: applauseRecipients.length,
        fromContact: {
            ...vm.user,
            name: vm.user.firstName + ' ' + vm.user.lastName,
            ID: vm.user.userID
        },
        category: vm.model.selectedCategory.name,
        teamName: vm.model.teamName,
        headerImageURL: model.selectedHeader.URL,
        messageHTML: model.tinymceModel || model.concatenatedContent,
        strengths: model.strengths && model.strengths.map(strengthID => ({ ID: strengthID })),
        sentDate: moment()
    };

    vm.send = function() {
        return $uibModalInstance.close('sending');
    };

    vm.cancel = function() {
        return $uibModalInstance.dismiss('cancel');
    };
}
