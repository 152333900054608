import angular from 'angular';

import '../../../core/core.module';

import promoGameCofig from './promoGame.config';
import promoGameCtrl from './promoGame.controller';

export default angular.module('app.promo.game', [
    'app.core',
])
.config(promoGameCofig)
.controller('promoGameCtrl', promoGameCtrl);
