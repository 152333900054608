

const constantUtils = require('./constantUtils');

const QuizQuestionValidations = {
    ANYTHING: 1,
    INTEGER: 2,
    NUMERIC: 3,
    POSTAL_CODE: 4,
    REGULAR_EXPRESSION: 5
};

const properties = {};

constantUtils.configure({ constants: QuizQuestionValidations, properties });

module.exports = QuizQuestionValidations;
