sisterPromoDropdownCtrl.$inject = ['PromoStatuses', '$timeout'];

export default function sisterPromoDropdownCtrl(PromoStatuses, $timeout) {
    this.$onInit = () => {
        const vm = this;

        // Passing in
        // vm.promo
        // vm.screen
        if (!vm.promo) {
            console.log('promo data is not supplied');
            return;
        }
        vm.open = true;
        vm.oneAtATime = true;
        vm.showResultsOptions = ![PromoStatuses.DELETED, PromoStatuses.DRAFT].includes(vm.promo.promoStatusID)
                                    && !vm.promo.isTemplate;


        $timeout(() => {
            vm.open = false;
        }, 5000);
    };
}
