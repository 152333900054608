import promoHeaderTpl from './promoHeader.tpl.html';
import promoHeaderCtrl from './promoHeader.controller';

export default {
    templateUrl: promoHeaderTpl,
    controller: promoHeaderCtrl,
    controllerAs: 'vm',
    bindings: {
        promo: '<',
        screen: '<',
        pageTitle: '<',
        showGauge: '<',
        showPoints: '<',
        showLeaderboard: '<',
    }
};
