import _ from 'lodash';

ReportsService.$inject = ['$http', '$rootScope', 'Periods'];

export default function ReportsService($http, $rootScope, Periods) {

    const Events = {
        CUSTOM_REPORT_REQUESTED: 'CUSTOM_REPORT_REQUESTED'
    };

    async function getCustomReportsList() {
        const res = await $http.get('/api/reports/custom');
        return res.data;
    }

    async function getCompanyMetricsList() {
        const res = await $http.get('/api/reports/company-metrics');
        return res.data;
    }

    async function getCustomTimePeriods({ gridReportID }) {
        const res = await $http.get(`/api/reports/time-periods`, {
            params: {
                gridReportID
            }
        });
        return res.data;
    }

    async function createCustomReportRequest({ gridReportID, timePeriodID, reportName, isEmail }) {
        const res = await $http.post(`/api/reports/custom/requests`, {
            gridReportID, timePeriodID, reportName, isEmail
        });
        $rootScope.$broadcast(Events.CUSTOM_REPORT_REQUESTED, { gridReportID, timePeriodID, reportName, isEmail });
        return res.data;
    }

    function deleteReportRequest({ gridReportRequestID }) {
        return $http.delete(`/api/reports/requests/${gridReportRequestID}`);
    }

    function deleteReportFile({ reportFileGUID }) {
        return $http.delete(`/api/reports/files/${reportFileGUID}`);
    }

    async function getReportFileCloudLink({ reportFileGUID }) {
        const res = await $http.get(`/api/reports/files/${reportFileGUID}/download-link`);
        return res.data && res.data.cloudPath;
    }

    function getScheduleListOptions() {
        return _(Periods.properties)
            .omit([Periods.AD_HOC, Periods.QUARTERLY])
            .toArray()
            .map(period => ({
                name: period.phrase,
                translateKey: period.translateKey,
                value: period.ID
            }))
            .value();
    }

    return {
        Events,
        getCustomReportsList,
        getCompanyMetricsList,
        getCustomTimePeriods,
        createCustomReportRequest,
        deleteReportFile,
        deleteReportRequest,
        getReportFileCloudLink,
        getScheduleListOptions,
    };
}
