import moment from 'moment';
import createMultiGridPageConfig from '../../core/directive/rnDxGrid/createMultiGridPageConfig';
import pointExpiringPopoverTemplateUrl from './pointExpiringPopover.tpl.html';
import { $IsStateFilter } from '@uirouter/angularjs/lib/stateFilters';

export default createMultiGridPageConfig({
    tabs: [{
        code: 'confirmed',
        tabLabelTranslateKey: 'account_CONFIRMED_GRID_TAB_LABEL', // 'Confirmed Transactions'
        grid: {
            componentName: 'account-confirmed-grid'
        }
    }, {
        code: 'pending',
        tabLabelTranslateKey: 'account_PENDING_GRID_TAB_LABEL', // 'Pending Transactions'
        grid: {
            componentName: 'account-pending-grid'
        }
    }, {
        code: 'declined',
        tabLabelTranslateKey: 'account_DECLINED_GRID_TAB_LABEL', // 'Declined Transactions'
        grid: {
            componentName: 'account-declined-grid'
        }
    }],

    state: {
        name: 'account',
        config: {
            parent: 'auth',
            url: '/account/:tab/:contactID?pointTrackingID&displayPointExpiration&pageType',
            data: {
                pageTitle: 'Account',
                permission: 'ViewAccounts',
            },
            params: {
                contactID: null,
                tab: 'confirmed',
                pointTrackingID: {
                    dynamic: true
                }
            },
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('account');
                }],
                promoTranslation: ['RnTranslationService',
                    (RnTranslationService) => RnTranslationService.loadPhrases('promo')],
                contact: ['$stateParams', 'ContactService', function($stateParams, ContactService) {
                    return ContactService.getContact($stateParams.contactID);
                }],
                pointExpirationDetails: ['$stateParams', 'Session', 'PointExpiryTableService',
                    function ($stateParams, Session, PointExpiryTableService) {
                        const company = Session.getCompany();
                        const hasPointExpiry = company.hasOption('PointsExpiration');
                        return hasPointExpiry
                            ? PointExpiryTableService.getExpiringPointsForecast($stateParams.contactID) : null;
                    }],
            }
        },
        tabUrlParam: 'tab'
    },

    controller: ['$stateParams', 'contact', 'Session', 'PointTrackingService', 'pointExpirationDetails', function accountCtrl(
        $stateParams, contact, Session, PointTrackingService, pointExpirationDetails
    ) {
        const vm = this;
        vm.user = Session.getUser();
        vm.contact = contact;
        vm.isSelf = vm.user.userID === vm.contact.userID;

        vm.pageType = 'account';
        if($stateParams.pageType && $stateParams.pageType.toLowerCase() === 'myorders') {
            vm.pageType = 'myOrders';
        }

        PointTrackingService.openFromUrl();
        vm.pointExpirationDetails = pointExpirationDetails;
        vm.pointExpiryPopoverTemplateUrl = pointExpiringPopoverTemplateUrl;
        vm.showPopover = $stateParams.displayPointExpiration || false;

        vm.hasPointsExpiring = vm.pointExpirationDetails && vm.pointExpirationDetails.expirationPoints
            && vm.pointExpirationDetails.expirationPoints.length;

        // Warning and Urgent Class for pointExpiration
        if (vm.hasPointsExpiring) {
            if (vm.pointExpirationDetails.expirationPoints[0].daysFrom) {
                vm.isPointExpirationUrgent = vm.pointExpirationDetails.expirationPoints.some(pointExpiration => pointExpiration.daysTo <= 7);
                if (!vm.isPointExpirationUrgent) {
                    vm.isPointExpirationWarning = vm.pointExpirationDetails.expirationPoints.some(pointExpiration => pointExpiration.daysTo <= 30);
                }
            } else if (vm.pointExpirationDetails.expirationPoints[0].expiresOn) {
                const endDate = moment(vm.pointExpirationDetails.expirationPoints[0].expiresOn).format('YYYY-MM-DD');
                const currentDate = moment().format('YYYY-MM-DD');
                const remainingDaysLeft = moment(endDate).diff(moment(currentDate), 'days');
                vm.isPointExpirationUrgent = remainingDaysLeft <= 7;
                if (!vm.isPointExpirationUrgent) {
                    vm.isPointExpirationWarning = remainingDaysLeft <= 30;
                }
            }
        }

        vm.toggleShowPopover = function() {
            vm.showPopover = !vm.showPopover;
        };
    }],

    template: {

        header: `{{ vm.pageType == 'myOrders' ? 'account_MY_ORDERS_HEADER' : (vm.isSelf ?  'account_MY_ACCOUNT_HEADER' : 'account_ACCOUNT_HEADER') }}`, // 'Account'
        containerClass: `account-container {{ vm.isSelf ? 'viewing-self' : 'viewing-other' }}`,
        templateAboveTabs: `
            <contact-panel ng-if="!vm.isSelf"
                           contact="vm.contact">
            </contact-panel>
            <button ng-if="vm.hasPointsExpiring" 
                    ng-click="vm.toggleShowPopover()"
                    ng-class="['btn btn-default point-expiry-popover', {
                        'point-expiry-warning': vm.isPointExpirationWarning,
                        'point-expiry-urgent': vm.isPointExpirationUrgent
                    }]"
                    uib-popover-template="'{{ ::vm.pointExpiryPopoverTemplateUrl }}'"
                    popover-is-open="vm.showPopover"
                    popover-trigger="outsideClick"
                    popover-placement="bottom-right">
                <span ng-if="vm.isPointExpirationWarning"
                      translate="app_POINT_EXPIRY_WARNING"
                      class="warning-text">
                      Warning:
                </span>
                <span ng-if="vm.isPointExpirationUrgent"
                      translate="app_POINT_EXPIRY_URGENT"
                      class="urgent-text">
                    Urgent:
                </span> 
                <span translate="app_POINT_EXPIRY_POINT_EXPIRATION" class="point-expiry-text">
                    Point Expiration
                </span>
                <i ng-if="vm.hasPointsExpiring" 
                   class="fa fa-caret-down help-text-icon caret-icon" 
                   aria-hidden="true">
                </i>
            </button>`,
        gridComponentBindings: `contact-id="vm.contact.userID"`
    }
});
