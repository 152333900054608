import _ from 'lodash';

TrainingQuizService.$inject = ['$q', '$translate', 'TrainingQuizHttpService', 'TrainingQuizModal'];

export default function TrainingQuizService($q, $translate, TrainingQuizHttpService, TrainingQuizModal) {

    /**
     * For when a user intends to take a training quiz (answer its questions).
     * This function will:
     *      - check if user has previously incomplete or completed submission for the quiz
     *      - if user completed already, remind user that they already completed, ask if they want to continue anyways
     *      - if user has incomplete submission, ask if they want to continue or restart
     *      - open questions for user to do
     *      - when done, show results view (pass/fail, explanations)
     *
     * @params {Object} promoDetails
     */
    function launchQuiz(promoDetails) {
        const firstQuizSectionQuestions = _.get(promoDetails, 'quiz.sections[0].questions');
        if (_.isEmpty(firstQuizSectionQuestions)) {
            throw new Error('Invalid quiz');
        }

        const { promoID } = getIDs(promoDetails);

        return TrainingQuizHttpService.checkExistingQuizResults({ promoID })
            .then(({ completedQuizResult, incompleteQuizResult } = {}) => {
                const remindCompleted = completedQuizResult
                    ? TrainingQuizModal.remindCompleted : () => $q.resolve({ isContinue: true });

                return remindCompleted()
                    .then(({ isContinue }) => {
                        if (!isContinue) {
                            return;
                        }
                        if (incompleteQuizResult) {
                            return handleIncompleteQuizFound(promoDetails, incompleteQuizResult);
                        }
                        return startNewQuiz(promoDetails);
                    });
            });
    }

    /**
     * @param {Object} promoDetails
     * @param {Object} incompleteQuizResult
     * @returns {Promise}
     */
    function handleIncompleteQuizFound(promoDetails, incompleteQuizResult) {
        const { promoID } = getIDs(promoDetails);

        return TrainingQuizModal.askContinueOrRestart()
            .then(({ isContinue }) => {
                if (isContinue) {
                    return TrainingQuizModal.openQuestions({
                        promoDetails,
                        quizResultID: incompleteQuizResult.quizResultID,
                        initialQuizQuestionID: incompleteQuizResult.nextQuizQuestionID,
                        currentScore: incompleteQuizResult.currentScore,
                    });
                }
                return TrainingQuizHttpService.cancelQuizResults({
                    promoID,
                    quizResultID: incompleteQuizResult.quizResultID
                })
                .then(() => startNewQuiz(promoDetails));
            });
    }

    /**
     * @param {Object} promoDetails
     * @returns {Promise}
     */
    function startNewQuiz(promoDetails) {
        const { promoID } = getIDs(promoDetails);

        return TrainingQuizHttpService.createQuizResults({ promoID })
            .then(({ quizResultID: newQuizResultID } = {}) => TrainingQuizModal.openQuestions({
                promoDetails,
                quizResultID: newQuizResultID,
            }));
    }

    /**
     * Helper to avoid copying and pasting.
     * @param {Object} promoDetails
     * @returns {{ promoID: int, quizID: int }}
     */
    function getIDs(promoDetails) {
        const promoID = promoDetails.ID;
        const quizID = promoDetails.quiz.ID;
        return { promoID, quizID };
    }

    /**
     * Converts a question JSON from SP to a formly field config object.
     * Assumptions:
     * - all questions are required
     * - questions will always be multiple choice radio buttons
     * @param {Object} params
     * @param {Object} params.question - From SP JSON
     * @param {int} [params.selectAnswerID] - ID of answer to select by default (i.e. pre-populate answer)
     * @params {int} [params.correctAnswerID] - ID of the correct answer to highlight
     * @param {boolean} [params.isDisabled = false]
     * @returns {Object}
     */
    function convertTrainingQuizQuestionToFormly({ question, selectAnswerID, correctAnswerID, isDisabled = false }) {
        const CAPITAL_A_CHAR_CODE = 65;
        return {
            key: question.ID,
            type: 'trainingQuizRadio',
            templateOptions: {
                label: question.questionFormattedForDisplay,
                controlClass: 'answers-container',
                required: true,
                disabled: isDisabled,
                options: _(question.questionMultiAnswer || [])
                    .sortBy(['displayOrder'])
                    .map((answer, i) => ({
                        name: `
                            ${answer.ID === selectAnswerID ? `
                                <span class="sr-only">
                                    ${$translate.instant('app_TRAINING_QUIZ_QUESTION_SELECTED_ANSWER')}
                                </span>`
                            : ''}
                            ${answer.ID === correctAnswerID ? `
                                <span class="sr-only">
                                    ${$translate.instant('app_TRAINING_QUIZ_QUESTION_CORRECT_ANSWER')}
                                </span>`
                            : ''}
                            <strong class="option-letter">${String.fromCharCode(CAPITAL_A_CHAR_CODE + i)}</strong>
                            <span class="option-text">${answer.answerOption}</span>`,
                        value: answer.ID,
                        isSelected: answer.ID === selectAnswerID,
                        cssClass: answer.ID === correctAnswerID ? 'correct-answer' : ''
                    }))
                    .value()
            }
        };
    }

    /**
     * Converts a question JSON from SP to a formly field config object.
     * Assumptions:
     * - all questions are required
     * - questions will always be multiple choice radio buttons
     * @param {Object} params
     * @param {Object} params.question - From SP JSON
     * @param {boolean} [params.isDisabled = false]
     * @returns {Object}
     */
    function convertGameQuizQuestionToFormly({ question, isDisabled = false }) {
        const CAPITAL_A_CHAR_CODE = 65;
        return {
            key: question.questionID,
            type: 'trainingQuizRadio',
            templateOptions: {
                label: question.questionText,
                controlClass: 'answers-container',
                required: true,
                disabled: isDisabled,
                options: _(question.questionAnswers || [])
                    .sortBy(['displayOrder'])
                    .map((answer, i) => ({
                        name: `
                            ${answer.isSelected ? `
                                <span class="sr-only">
                                    ${$translate.instant('app_TRAINING_QUIZ_QUESTION_SELECTED_ANSWER')}
                                </span>`
                            : ''}
                            ${answer.isCorrectAnswer ? `
                                <span class="sr-only">
                                    ${$translate.instant('app_TRAINING_QUIZ_QUESTION_CORRECT_ANSWER')}
                                </span>`
                            : ''}
                            <strong class="option-letter">${String.fromCharCode(CAPITAL_A_CHAR_CODE + i)}</strong>
                            <span class="option-text">${answer.answer}</span>`,
                        value: answer.quizQuestionAnswerID,
                        isSelected: answer.isSelected,
                        cssClass: answer.isCorrectAnswer ? 'correct-answer' : ''
                    }))
                    .value()
            }
        };
    }

    return {
        launchQuiz,
        convertTrainingQuizQuestionToFormly,
        convertGameQuizQuestionToFormly,
    };
}
