import angular from 'angular';

import '../../core/core.module';

import './contactIcon/contactIcon.module';
import './contactPanel/contactPanel.module';

import ContactService from './contactService.factory';

export default angular.module('app.contacts', [
    'app.core',
    'app.contacts.contactIcon',
    'app.contacts.contactPanel',
])
.factory('ContactService', ContactService);
