import _ from 'lodash';

contentManagementDetailCtrl.$inject = ['$location', 'Session', 'ContentManagementDetailService', '$state'];

export default function contentManagementDetailCtrl($location, Session, ContentManagementDetailService, $state) {
    this.$onInit = () => {
        const vm = this;

        vm.isTopic = !!vm.content.topicId;
        vm.user = Session.getUser();
        vm.showDeveloperContent = vm.user.hasPermission('CustomContentDeveloper');
        vm.url = `${$location.protocol()}://${$location.host()}/#/content/`;
        vm.isExternalPage = !!vm.content.url;
        vm.content.isAllPerms = !_.isArray(vm.content.roles) || vm.content.roles.length === 0;
        vm.tinymceOptions = {
            contextmenu:'',
            plugins: 'code image hr fullscreen',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | fullscreen',
            valid_children: '+body[style],+a[h1|h2|h3|h4|h5|h6|p|span|div|img]',
            extended_valid_elements: '+div[*],+a[*],+span[*],+p[*],+dotlottie-player[*]',
            height: '480',
            content_css: '//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
        };
        vm.urlMessage = `<p>
                This URL will be used to access the content you are adding.
                For example, if your URL is 'training' then you can access that content by 
                going to the link: /#/content/training. 
                The following URLs are reserved:
                <ul>
                    <li><b>terms</b> - can be 'Terms and Conditions' required pre-sign-in</li>
                    <li><b>privacy</b> - Privacy Policy</li>
                    <li><b>registercomplete</b> - Displayed after registration submission</li>
            </ul>
            You are able set up multiple pages with the same URL for different permission groups.
            </p>`;

        vm.isRoleChecked = isRoleChecked;
        vm.toggleRole = toggleRole;
        vm.resizeEditor = resizeEditor;
        vm.save = save;
        vm.selectAllRoles = selectAllRoles;

        function isRoleChecked(role) {
            return vm.content.roles.some(roleID => roleID === role.ID);
        }

        function toggleRole(role) {
            const roleIndex = vm.content.roles.indexOf(role);
            if (roleIndex > -1) {
                vm.content.roles.splice(roleIndex, 1);
            } else {
                vm.content.roles.push(role);
            }
        }

        function resizeEditor(size) {
            switch (size) {
                case 'tablet':
                    vm.editorWidth = 'col-sm-5 col-sm-offset-2 col-md-6 col-md-offset-3';
                    break;
                case 'mobile':
                    vm.editorWidth = 'col-sm-4 col-sm-offset-4';
                    break;
                default:
                    vm.editorWidth = '';
            }
        }

        function save() {
            if (!vm.isTopic) {
                delete vm.content.topicId;
            }
            if (!vm.isExternalPage) {
                delete vm.content.url;
            }
            // parse the htmlContent to clean up boolean attribute values for lottiefile options
            const htmlContent = vm.content.content;
            vm.content.content = htmlContent.replace(/loop="loop"/g, 'loop').replace(/autoplay="autoplay"/g, 'autoplay').replace(/controls="controls"/g, 'controls').replace(/hover="hover"/g, 'hover');
            ContentManagementDetailService.saveContent(vm.content).then(() => {
                if (!vm.content.contentID) {
                    $state.go('contentManagement', {}, { reload: true });
                }
            });
        }

        function selectAllRoles() {
            const allRoleIDs = vm.roles.map((role) => role.ID);
            if (vm.content.roles.length === allRoleIDs.length) {
                vm.content.roles = [];
                return;
            }
            vm.content.roles = allRoleIDs;
        }
    };
}
