NavService.$inject = ['AuthStatus', 'Session', 'NavBarTypes', 'PointCalculatorModalService', '$document', '$timeout'];

export default function NavService(AuthStatus, Session, NavBarTypes, PointCalculatorModalService, $document, $timeout) {
    let checkedOutstanding = false;

    const NavBarTypeFunctions = {
        [NavBarTypes.POINT_CALCULATOR]: PointCalculatorModalService.open
    };

    function getNavBarTypeFunction(navBarTypeID) {
        return NavBarTypeFunctions[navBarTypeID];
    }

    /**
     * Only used with getting authenticated header, footer, sidebar links.
     * TODO: separate function for un-authenticated nav links, separate function for login page links
     */
    function getNavLinks() {
        if (AuthStatus.isAuthenticated()) {
            return Session.getUser().permissionGroup.navBarLinks;
        } else {
            // Avoid undefined errors
            return {
                main: [],
                subRight: [],
                footer: [],
                menu: []
            };
        }
    }

    function checkGameIsOutstanding(playerToken) {
        const company = Session.getCompany();

        if (company.hasOption('GamesEnabled')) {
            console.log(`checkedOutstanding: ${checkedOutstanding}`);
            if(!checkedOutstanding && playerToken) {
                checkedOutstanding = true;
                if (window.iziigame) {
                    window.iziigame.isIncompleteGameplays(playerToken).then(function(result) {
                        console.log('nav isIncompleteGameplays success');
                        if(result) {
                            console.log('adding is-gameplay');
                            $document[0].body.classList.add('is-gameplay');
                        } else {
                            console.log('removing is-gameplay');
                            $document[0].body.classList.remove('is-gameplay');
                        }
                        $timeout(()=> {
                            checkedOutstanding = false;
                        }, 1000)
                    }, function(err) {
                        console.log('nav isIncompleteGameplays failed');
                        console.log(err);
                    });
                } else {
                    console.error('missing iziigame.js, cannot call isIncompleteGameplays.');
                    $document[0].body.classList.remove('is-gameplay');
                }
            }
        }
    }

    return {
        getNavLinks,
        getNavBarTypeFunction,
        checkGameIsOutstanding
    };
}
