rewardConfig.$inject = ['$stateProvider'];

export default function rewardConfig($stateProvider) {
    $stateProvider.state('rewards', {
        parent: 'auth',
        url: '/rewards/:storeID/:categoryID',
        views: {
            'main@': 'rewardComponent'
        },
        data: {
            pageTitle: 'Rewards',
            permission: 'Redeem'
        },
        params: {
            storeID: {
                value: null,
                dynamic: true,
                squash:true
            },
            categoryID: {
                value: null,
                dynamic: true,
                squash: true
            }
        },
        resolve: {
            storeList: ['RewardService', function(RewardService) {
                return RewardService.getStoreList();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('reward');
            }]
        }
    });
}
