import incentivePayoutGaugeWidgetTpl from './incentivePayoutGaugeWidget.tpl.html';
import incentivePayoutGaugeWidgetCtrl from './incentivePayoutGaugeWidget.controller';

export default {
    templateUrl: incentivePayoutGaugeWidgetTpl,
    controller: incentivePayoutGaugeWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
