import angular from 'angular';

import rnDxGridModule from './rnDxGrid/rnDxGrid.module';
import rnFormModule from './rnForm/rnForm.module';
import gaugeWidgetModule from './gaugeWidget/gaugeWidget.module';
import pointCalculatorModule from './pointCalculator/pointCalculator.module';
import quizResultStatusLabelModule from './quizResultStatusLabel/quizResultStatusLabel.module';
import commentSectionModule from './commentSection/commentSection.module';
import spotlightItemToolsModule from './spotlightItemTools/spotlightItemTools.module';
import likedUsersModule from './likedUsers/likedUsers.module';
import toFromHeaderModule from './toFromHeader/toFromHeader.module';
import pointExpiryTableModule from './pointExpiryTable/pointExpiryTable.module';
import fileViewerModule from './fileViewer/fileViewer.module';
import widgetsModule from './widgets/widgets.module';

import CoreDirectivesService from './coreDirectivesService.factory';

import ngEnter from './ngEnter.directive';
import selectOnClick from './selectOnClick.directive';
import hashBackgroundColor from './hashBackgroundColor.directive';
import iframeOnLoad from './iframeOnLoad.directive';
import dynamicTemplate from './dynamicTemplate.directive';
import onImgLoad from './onImgLoad.directive';
import onImgError from './onImgError.directive';
import onVideoLoadedData from './onVideoLoadedData.directive';
import hideIfNoChildren from './hideIfNoChildren.directive';
import alertMessage from './alertMessage/alertMessage.directive';
import customContent from './customContent.directive';
import promiseToggle from './promiseToggle/promiseToggle.directive';
import gridPageContainer from './gridPageContainer/gridPageContainer.directive';
import onDomChanges from './onDomChanges.directive';
import fullHeightIframe from './fullHeightIframe.directive';
import tabRefreshMask from './tabRefreshMask/tabRefreshMask.directive';

export default angular.module('app.core.directives', [
    rnDxGridModule.name,
    rnFormModule.name,
    gaugeWidgetModule.name,
    pointCalculatorModule.name,
    quizResultStatusLabelModule.name,
    commentSectionModule.name,
    spotlightItemToolsModule.name,
    likedUsersModule.name,
    toFromHeaderModule.name,
    pointExpiryTableModule.name,
    fileViewerModule.name,
    widgetsModule.name,
])
.factory('CoreDirectivesService', CoreDirectivesService)
.directive('ngEnter', ngEnter)
.directive('selectOnClick', selectOnClick)
.directive('hashBackgroundColor', hashBackgroundColor)
.directive('iframeOnLoad', iframeOnLoad)
.directive('dynamicTemplate', dynamicTemplate)
.directive('onImgLoad', onImgLoad)
.directive('onImgError', onImgError)
.directive('onVideoLoadedData', onVideoLoadedData)
.directive('hideIfNoChildren', hideIfNoChildren)
.directive('alertMessage', alertMessage)
.directive('customContent', customContent)
.directive('promiseToggle', promiseToggle)
.directive('gridPageContainer', gridPageContainer)
.directive('onDomChanges', onDomChanges)
.directive('fullHeightIframe', fullHeightIframe)
.directive('tabRefreshMask', tabRefreshMask);
