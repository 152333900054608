import createTemplate from './createTemplate';
import createController from './createController';
import createConfig from './createConfig';

/**
 * @param {MultiGridPageOptions} options
 * @returns {Array} Angular config for the multi-grid page
 */
export default function createMultiGridPageConfig(options) {
    const template = createTemplate(options);
    const controller = createController(options);
    return createConfig({
        ...options,
        template,
        controller
    });
}

/**
 * @typedef {Object} MultiGridPageOptions
 *
 * @property {Object[]} tabs
 * @property {string} tabs[].code
 *      Will be used as part of URL params, $state params, css classes, etc.
 * @property {string} tabs[].tabLabelTranslateKey
 *      The user can switch between grids on the page by clicking on tabs.
 *      This specifies the text of the tab for the grid.
 * @property {string} [tabs[].template]
 *      Template for the content of the tab.
 * @property {Array | Function} [tabs[].onFirstLoad]
 *      A function that will be called when the tab is first clicked or first loaded.
 *      The function will have any dependencies injected into it when it's called.
 *      Can set `vm` variables using `const vm = this`.
 * @property {Object} [tabs[].grid]
 *      If the tab will hold just a grid, you can specify the grid's options here.
 * @property {string} [tabs[].grid.componentName]
 *      Snake-cased component name for the grid.
 *
 * @property {Object} state
 * @property {string} state.name
 * @property {Object} state.config
 *      Custom ui-router `$stateProvider.state` options for the page.
 * @property {string} state.tabUrlParam
 *      When tabs are between switched, this is the name of the URL param that will be updated with a new value.
 *
 * @property {Array | Function} [controller]
 *      A hook for you to do extra things in the page's controller.
 *
 * @property {Object} template
 * @property {string | function(grid): string} [template.gridComponentBindings]
 *      If any or all of the grids have more component bindings that need to be set, specify them here.
 *      Needs to be a string or a function that returns a string of HTML attributes. Ex. `contact-id="vm.contactID"`.
 *      If a function is passed, it will be run after the custom `controller` is ran.
 * @property {string} [template.header]
 *      Directly passed to the `header` component binding of `<grid-page-container>`
 *      (can be an Angular expression).
 * @property {boolean} [template.translateHeader = true]
 *      Directly passed to the `translateHeader` component binding of `<grid-page-container>`
 *      (can be an Angular expression).
 * @property {string} [template.containerClass]
 *      CSS class to add to the `<grid-page-container>` component.
 * @property {string} [template.templateAboveTabs]
 *      HTML that will be put above the tabs. Can contain angular directives.
 * @property {string} [template.headerTransclude]
 *      For `<grid-page-container>`.
 */
