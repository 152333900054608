RegistrationService.$inject = ['$http'];

export default function RegistrationService($http) {
    return {
        sendRegistration,
        checkUserIDIsUnique,
        checkUsernameIsUnique,
    };

    function sendRegistration(fields) {
        return $http.post('/api/register/self', fields);
    }

    function checkUserIDIsUnique(userID) {
        // Note: formly asyncValidation doesn't seem to work if you try to return true/false in the .then callback yourself
        // So just rely on the API returning http statuses 2xx for unique and 4xx for not
        return $http.get(`/api/register/check-unique/user-id/${userID}`);
    }

    function checkUsernameIsUnique(username) {
        return $http.get(`/api/register/check-unique/username/${username}`);
    }
}
