import reportColumnChooserTpl from './reportColumnChooser.tpl.html';
import reportColumnChooserCtrl from './reportColumnChooser.controller';

ReportColumnChooserService.$inject = ['$uibModal'];

export default function ReportColumnChooserService($uibModal) {
    return {
        open
    };

    function open(columns, gridInstance) {
        return $uibModal.open({
            animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: reportColumnChooserTpl,
            controller: reportColumnChooserCtrl,
            controllerAs: 'vm',
            size: 'sm',
            bindToController: true,
            resolve: {
                columns: () => columns,
                gridInstance: () => gridInstance
            }
        });
    }
}
