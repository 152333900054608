import _ from 'lodash';

spotlightItemsContainerCtrl.$inject = [
    '$stateParams', '$rootScope', '$timeout', 'spotlightItems', 'contact', 'MessageService', 'SpotlightService'
];

export default function spotlightItemsContainerCtrl(
    $stateParams, $rootScope, $timeout, spotlightItems, contact, MessageService, SpotlightService
) {

    const vm = this;

    vm.items = spotlightItems;
    vm.contact = contact;
    vm.isGettingData = true;

    preventDoubleSpotlightItemsLoad();

    vm.lastSpotlightItemID = vm.items.length ? vm.items[vm.items.length - 1].spotlightItemID : undefined;
    vm.selectedParticipantFilter = $stateParams.participant;
    vm.selectedRecognitionFilter = $stateParams.recognition;
    vm.selectedSentFilter = $stateParams.sent;
    vm.selectedRecentDaysFilter = $stateParams.recentDays;
    vm.selectedStartDateFilter = $stateParams.startDate;

    vm.onMasonryDomChanges = () => {
        $rootScope.$broadcast('masonry.reload');
    };

    vm.getSpotlightItems = async () => {
        vm.isGettingData = true;

        const newItems = await SpotlightService.getSpotlightItems({
            participant: vm.selectedParticipantFilter,
            recognition: vm.selectedRecognitionFilter,
            sent: vm.selectedSentFilter,
            recentDays: vm.selectedRecentDaysFilter,
            startDate: vm.selectedStartDateFilter,
            contactID: vm.contact && vm.contact.userID,
            lastSpotlightItemID: vm.lastSpotlightItemID
        });

        if (_.isEmpty(newItems)) {
            vm.hideViewMoreButton = true;
            return;
        }
        vm.items = vm.items.concat(newItems);
        vm.lastSpotlightItemID = vm.items[vm.items.length - 1].spotlightItemID;
        preventDoubleSpotlightItemsLoad();
    };

    vm.onItemDeleteSuccess = (item) => {
        const itemIndex = vm.items.indexOf(item);
        vm.items.splice(itemIndex, 1);
    };

    // Only allow one set of spotlight items to load at a time. This was needed because the masonry was causing
    // the window to scroll and load another set of data.
    function preventDoubleSpotlightItemsLoad() {
        $timeout(() => {
            vm.isGettingData = false;
        }, 1500);
    }
}
