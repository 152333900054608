export default class ApiRequestError extends Error {

    constructor(res) {
        const message = formatErrorMessage(res);
        super(message);
        this.name = this.constructor.name;

        Object.assign(this, res);
    }
}

function formatErrorMessage(res) {
    let message;
    if (res.status === -1) {
        message = 'Cancelled request';
    } else if (!res.data) {
        message = 'API Request Error';
    } else {
        message = JSON.stringify(res.data.message || res.data);
    }
    const urlInfo = `@ ${res.config.method} ${res.config.url}`;
    return [message, urlInfo].join('\n');
}
