import widgetFootNoteTpl from './widgetFootNote.tpl.html';
import widgetFootNoteCtrl from './widgetFootNote.controller';

export default function widgetFootNote() {
    return {
        restrict: 'E',
        templateUrl: widgetFootNoteTpl,
        controller: widgetFootNoteCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            footnotes: '<',
        },
    };
}
