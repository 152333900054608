import _ from 'lodash';
import $ from 'jquery';

legacyMessageCtrl.$inject = [
    '$scope', '$element', '$filter', '$timeout', '$sce', '$window', '$uibModalStack', 'MessageService', 'CoreDirectivesService'
];

export default function legacyMessageCtrl(
    $scope, $element, $filter, $timeout, $sce, $window, $uibModalStack, MessageService, CoreDirectivesService
) {
    this.$onInit = () => {
        const vm = this;

        loadLegacyHtml();

        if (vm.messageDetails.messageTypeCode) {
            vm.messageTypeCssSuffix = $filter('formatForCode')(vm.messageDetails.messageTypeCode);
        } else {
            console.warn('Legacy message has no messageTypeCode - will not be able to add a CSS class for custom styling');
        }
        if (vm.isInModal) {
            $timeout(() => {
                if (vm.messageTypeCssSuffix === 'applauseprompts') {
                    $('.modal-dialog').removeClass('modal-lg');
                    $('.modal-dialog').addClass('modal-md');
                }
                $('.modal-dialog').addClass(`legacy-message-modal legacy-message-${vm.messageTypeCssSuffix}-modal`);
            });
        }

        $('.legacy-message').on('click', (event) => {
            const parentHref = $(event.target).parents('a').attr('href');
            if ([parentHref, event.target.href].includes($window.location.href)) {
                $uibModalStack.dismissAll();
            }
        });

        async function loadLegacyHtml() {
            vm.loading = true;
            const legacyHtml = await MessageService.getMessageLegacyHtml(vm.messageDetails.messageRecipientID);
            
            // This code checks if there is an iframe with a src attribute containing "cloudflarestream" within the element identified by "legacyHtml"
            const modalDialogs = $('.modal-dialog');
            if ($(legacyHtml).find('iframe[src*=cloudflarestream]').length) {
                modalDialogs.addClass('modal-video-cloudflare');
            } else {
                modalDialogs.removeClass('modal-video-cloudflare');
            }
            
            vm.messageLegacyHtml = $sce.trustAsHtml(legacyHtml);
            vm.loading = false;
            await $timeout(0); // Wait for legacy html to be put into DOM
            scaleLegacyContent();
            CoreDirectivesService.onWindowResize($scope, scaleLegacyContent);
        }

        function scaleLegacyContent() {
            const $divElement = $element.children();
            const $contents = $divElement.children('*:not(meta, style)');
            if (!$contents.length) {
                return;
            }
            $divElement.css('height', '');
            const scale = $divElement.width() / $contents.width();
            if (!_.isFinite(scale)) {
                return;
            } else if (scale >= 1) {
                $contents.css('transform', '');
                return;
            }
            const newContainerHeight = $contents.height() * scale;
            $contents.css('transform', `scale(${scale})`);
            $divElement.css('height', newContainerHeight);
        }
    };
}
