import _ from 'lodash';

rewardCtrl.$inject = ['$stateParams', '$state', 'RewardService', '$window'];

export default function rewardCtrl($stateParams, $state, RewardService, $window) {
    const vm = this;
    this.$onInit = () => {
        if (_.isArray(vm.storeList)) {
            vm.currentStoreIndex = vm.storeList.findIndex( store => store.storeID === Number($stateParams.storeID));
            if (vm.currentStoreIndex !== -1) {
                vm.store = vm.storeList[vm.currentStoreIndex];
            } else {
                vm.store = vm.storeList[0];
                vm.currentStoreIndex = 0;
            }
            if ($stateParams.categoryID !== null && !isNaN($stateParams.categoryID)) {
                vm.storeUrl = `${vm.store.URL ? `${vm.store.URL}&categoryID=${$stateParams.categoryID}` : ''}`;
            } else {
                vm.storeUrl = vm.store.URL;
            }
            vm.showStore = !vm.store.isSINRequired || (vm.store.isSkippable && vm.store.requestedSINSkip)
        }

        vm.submitButtonOptions = {
            onSuccess() {
                console.log('submit success seen from report Ctrl');
                $state.go($state.current, $stateParams, { reload: true });
            },
            onFail() {
                console.error('submit failed seen from report Ctrl');
            }
        };
    };

    vm.uiOnParamsChanged = uiOnParamsChanged;
    vm.requestSINSkip = requestSINSkip;
    vm.openStoreWindow = openStoreWindow;
    
    function uiOnParamsChanged(changes) {
        if (changes.storeID !== undefined) {        // check if there are changes to storeID 
            if(changes.storeID === null) {          // check if storeID is reset to null
                vm.store = vm.storeList[0];
                vm.currentStoreIndex = 0;
            } else if(!isNaN(changes.storeID)) {    // check if storeID is numeric
                const matchingStoreIndex = vm.storeList.findIndex( store => store.storeID === Number(changes.storeID));
                
                if (matchingStoreIndex !== -1 && matchingStoreIndex !== vm.currentStoreIndex) { // check if storeID matched a store and is not currently selected
                    vm.store = vm.storeList[matchingStoreIndex];
                    vm.currentStoreIndex = matchingStoreIndex;
                }
            }
        }

        // categoryID isNumeric then append category query at the end
        if(!isNaN($stateParams.categoryID) && $stateParams.categoryID !== null) { // check categoryID is numeric
            vm.storeUrl = `${vm.store.URL ? `${vm.store.URL}&categoryID=${$stateParams.categoryID}` : ''}`;
        } else {
            vm.storeUrl = vm.store.URL;
        }
        vm.showStore = !vm.store.isSINRequired || (vm.store.isSkippable && vm.store.requestedSINSkip);
    }

    async function requestSINSkip() {
        try {
            await RewardService.requestSINSkip();
            $state.go($state.current, $stateParams, { reload: true });
        } catch (err) {

        }
    }

    function openStoreWindow(){
        $window.open(vm.storeUrl, 'storeWindow');
    }
}
