import snapshotContentTpl from './snapshotContent.tpl.html';
import snapshotContentCtrl from './snapshotContent.controller';

export default {
    templateUrl: snapshotContentTpl,
    controller: snapshotContentCtrl,
    controllerAs: 'vm',
    bindings: {
        widgetPages: '<',
        contact: '<',
        activePageIndex: '<',
        activeSetIndex: '<'
    }
};
