import angular from 'angular';

import '../../../core/core.module';
import '../../communication/communication.module';

import inboxGrid from './inboxGrid.component';

export default angular.module('app.inbox.inboxGrid', [
    'app.core',
    'app.communication'
])
.component('inboxGrid', inboxGrid);
