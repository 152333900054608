import angular from 'angular';

import '../../../core/core.module';
import '../promoHeader/promoHeader.module';

import promoEditConfig from './promoEdit.config';
import promoEditCtrl from './promoEdit.controller';

export default angular.module('app.promo.edit', [
    'app.core',
    'app.promo.promoHeader',
])
.config(promoEditConfig)
.controller('promoEditCtrl', promoEditCtrl);
