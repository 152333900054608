reportConfig.$inject = ['$stateProvider'];

export default function reportConfig($stateProvider) {
    $stateProvider.state('reportModal', {
        parent: 'report',
        url: '/modal/:modalType/:modalID',
        data: {
            permission: 'UserReports',
        },
        params: {
            modalData: {},
            fallbackState: 'report'
        },
        resolve: {
            model: ["$stateParams","ModalTypes", "PromoService", async function($stateParams, ModalTypes, PromoService) {
                if (!$stateParams.modalType) {
                    return null;
                }
                let model;
        
                // TODO: Move getRowData into resolve function.
                switch ($stateParams.modalType) {
                    case ModalTypes.QUESTION_OVERVIEW.Type:
                        model = await PromoService.getAllAnswerDetails();
                        model.selectedQuestionID = ModalTypes.QUESTION_OVERVIEW.rowKeyName === 'QuestionID' ? 
                                                    Number($stateParams.modalID) : undefined;
                        return model;
                        break;
                    case ModalTypes.GAMEPLAY_RESULTS.Type:
                        return await PromoService.getAnswerDetails({ gameplayGUID: $stateParams.modalID });
                        break;
                    case ModalTypes.TRAINING_QUIZ_RESULTS.Type:
                    case ModalTypes.CLAIM_QUIZ_RESULTS.Type:
                        return await PromoService.getAnswerDetails({ quizResultID: $stateParams.modalID });
                }
            }]
        }
    });
}
