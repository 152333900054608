import _ from 'lodash';
import bowser from 'bowser';

appCssClasses.$inject = [
    '$rootScope', '$transitions', '$filter', 'LangUtils', 'Session', 'SignOnTasks', 'AuthStatus', 'AUTH_EVENTS', '$window'
];

export default function appCssClasses(
    $rootScope, $transitions, $filter, LangUtils, Session, SignOnTasks, AuthStatus, AUTH_EVENTS, $window
) {
    return {
        restrict: 'A',
        link: function(scope, element) {

            let classes = [];
            let authClasses = getAuthClasses();
            let userClasses = getUserClasses();
            let signOnTaskClasses = [];
            let stateClasses = [];
            let languageClasses = getLanguageClasses();
            const browserClasses = getBrowserClasses();
            const companyClasses = getCompanyClasses();
            const referrerClasses = getReferrerClasses();

            scope.$watchCollection(() => classes, function(newClasses, oldClasses) {
                element.removeClass(oldClasses.join(' '));
                element.addClass(newClasses.join(' '));
            });

            scope.$on(AUTH_EVENTS.LOGIN_SUCCESS, function() {
                userClasses = getUserClasses();
                signOnTaskClasses = getSignOnTaskClasses();
                updateClasses();
            });

            scope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, function() {
                userClasses = getUserClasses();
                updateClasses();
            });

            // For changing state after completing required tasks on loginPanel
            $transitions.onStart({}, function() {
                signOnTaskClasses = getSignOnTaskClasses();
                updateClasses();
            });

            $transitions.onSuccess({}, function(transition) {
                const toState = transition.to();
                authClasses = getAuthClasses(toState);
                stateClasses = getStateClasses(toState);
                updateClasses();
            });

            $rootScope.$on('$translateChangeSuccess', function() {
                languageClasses = getLanguageClasses();
                updateClasses();
            });

            function updateClasses() {
                classes = [
                    ...browserClasses,
                    ...authClasses,
                    ...companyClasses,
                    ...userClasses,
                    ...signOnTaskClasses,
                    ...stateClasses,
                    ...languageClasses,
                    ...referrerClasses,
                ];
            }

            function getAuthClasses(toState) {
                // Confusing: this is called only on $transitions.onSuccess, rather than LOGIN_SUCCESS and LOGOUT_SUCCESS.
                // This is because the .auth and .no-auth classes affect the whole layout of the site and it takes
                // relatively long for the browser to apply. So we want to apply the classes only after the state
                // has changed, otherwise the site will look weird for half a second when going from login page
                // to landing page.
                if (!AuthStatus.isAuthenticated()) {
                    return [
                        'no-auth'
                    ];
                }
                // We want the layout for the login page to look the same regardless if the user is logged in or not.
                // So instead of adding #root.auth.login to every CSS rule that has #root.no-auth, use the .no-auth class
                // here instead.
                return [
                    toState && toState.name === 'login' ? 'no-auth' : 'auth'
                ];
            }

            function getSignOnTaskClasses() {
                const signOnTaskClasses = [];
                if (SignOnTasks.getRequiredTasks().length > 0) {
                    signOnTaskClasses.push('has-required-tasks');
                }
                return signOnTaskClasses;
            }

            function getStateClasses(toState) {
                const stateClasses = [];
                const { rootClass, isPublicPage } = toState.data;
                if (isPublicPage) {
                    stateClasses.push('public-page');
                }
                if (rootClass) {
                    stateClasses.push(rootClass);
                } else {
                    stateClasses.push(_.kebabCase(_.split(toState.name, '.', 1)));
                    stateClasses.push(_.kebabCase(toState.name));
                }
                return stateClasses;
            }

            function getLanguageClasses() {
                const languageCode = LangUtils.getLang();
                return [
                    `language-code-${formatForCode(languageCode)}`
                ];
            }

            function getUserClasses() {
                if (!AuthStatus.isAuthenticated()) {
                    return [];
                }
                const user = Session.getUser();
                const limitersClasses = getLimitersClasses();
                const performanceClasses = getPerformanceClasses();
                return [
                    `permission-role-${formatForCode(user.permissionGroup.code)}`,
                    ...limitersClasses,
                    ...performanceClasses
                ];
            }

            function getLimitersClasses() {
                const user = Session.getUser();
                const company = Session.getCompany();
                const limitersClasses = [];
                for (let i = 1; i <= company.limiters.length; i++) {
                    const value = user['limiterValue' + i];
                    let formattedValue;
                    if (value == null) {
                        formattedValue = 'unspecified';
                    } else {
                        formattedValue = formatForCode(value);
                    }
                    limitersClasses[i] = `limiter${i}-${formattedValue}`;
                }
                return limitersClasses;
            }

            function getPerformanceClasses() {
                const user = Session.getUser();
                const performanceStatusClasses = [];
                if (user.performanceStatus) {
                    performanceStatusClasses.push(`performance-status-${formatForCode(user.performanceStatus)}`);
                }
                return performanceStatusClasses;
            }

            // get document.referrer classes from query params
            function getReferrerClasses() {
                const company = Session.getCompany();
                const referrerClasses = [];
                if (company.teamsReferrers && company.teamsReferrers.includes(document.referrer)) {
                    referrerClasses.push('is-teams-app');
                }
                return referrerClasses;
            }

            function getCompanyClasses() {
                const company = Session.getCompany();
                let environmentClass = 'environment-none';
                if (company && company.environmentInstance) {
                    environmentClass = `environment-${company.environmentInstance.toLowerCase()}`;
                }
                return [
                    environmentClass
                ];
            }

            function getBrowserClasses() {
                const classes = [];
                if (bowser.name) {
                    classes.push(`browser-name-${formatForCode(bowser.name)}`);
                }
                if (bowser.version) {
                    classes.push(`browser-version-${bowser.version.replace(/\./g, '_')}`);
                }
                if (bowser.mobile) {
                    classes.push(`browser-device-mobile`);
                } else if (bowser.tablet) {
                    classes.push(`browser-device-tablet`);
                }
                return classes;
            }

            function formatForCode(string) {
                return $filter('formatForCode')(string);
            }
        }
    };
}
