import _ from 'lodash';

chartWidgetCtrl.$inject = [
    '$scope', '$interval', '$timeout', '$translate', 'ChartWidgetService', 'SnapshotService', 'WidgetVisuals',
    '$window', 'WidgetService'
];

export default function chartWidgetCtrl(
    $scope, $interval, $timeout, $translate, ChartWidgetService, SnapshotService, WidgetVisuals,
    $window, WidgetService
) {
    this.$onInit = () => {
        const vm = this;

        if (vm.hasData == null) {
            vm.hasData = ChartWidgetService.defaultHasDataCheck(vm.widget);
        }

        if (!vm.hasData && !_.get(vm.widget, 'data.stats')) {
            return;
        }

        _.defaults(vm, {
            stats: vm.widget.data.stats || [],
            subStats: vm.widget.data.subStats || []
        });

        vm.WidgetVisuals = WidgetVisuals;

        vm.chartConfig = vm.chartConfig
            || ChartWidgetService.createChartConfigByVisual(vm.widget.widgetVisualCode, vm.widget.data);

        _.merge(vm.chartConfig, WidgetService.getOverrideConfig(vm.widget.data));

        vm.isDxChart = ![vm.WidgetVisuals.PIE_CHART, vm.WidgetVisuals.FUNNEL].includes(vm.widget.widgetVisualCode);
        vm.isPieChart = [vm.WidgetVisuals.PIE_CHART].includes(vm.widget.widgetVisualCode);
        vm.isFunnelChart = [vm.WidgetVisuals.FUNNEL].includes(vm.widget.widgetVisualCode);
        vm.chartCssID = ChartWidgetService.createChartCssID(vm.widget.widgetsAvailableID);

        if (vm.isFunnelChart) {
            // vm.hasData = _.get(vm.widget.data.dataSets[0], 'dataPoints[0].value');
            vm.hasFunnelData = _.get(vm.widget.data.dataSets[0], 'dataPoints[0].value');
        }

        if (!vm.chartTitle && vm.widget.widgetVisualCode === WidgetVisuals.PIE_CHART) {
            const titleName = _.get(vm.widget.data, 'dataSets[0].name');
            const titleTranslateKey = _.get(vm.widget.data, 'dataSets[0].translateKey');
            vm.chartTitle = titleName || (titleTranslateKey && $translate.instant(titleTranslateKey));
        }

        vm.hasStats = vm.stats.length && !_.isEmpty(vm.stats[0]);

        vm.hasSubStats = vm.subStats.length;
        vm.hasTitle = vm.chartTitle;
        vm.hasFooter = _.get(vm.widget.data, 'extraJSON.summaryLink.href');
        vm.hasFootNote = vm.widget.data.footers && vm.widget.data.footers.length;

        if (_.get(vm.chartConfig, 'dataSource.length') && _.get(vm.chartConfig, 'series.length')) {
            const mobileRangeSelectorWidth = _.get(vm.widget, 'data.extraJSON.chartOptions.RangeSelectorScreenMaxWidth')
                                                || 768;
            const hasMoreThan12DataPoints = vm.chartConfig.dataSource.length / vm.chartConfig.series.length > 12;
            vm.chartConfig.hasRangeSelector = _.get(vm.widget, 'data.extraJSON.chartOptions.hasRangeSelector')
                                    && hasMoreThan12DataPoints && $window.innerWidth < mobileRangeSelectorWidth;
        }

        vm.chartContainerHeight = vm.chartContainerHeight || ChartWidgetService.getChartContainerHeight({
            ...vm,
            widgetVisual: vm.widget.widgetVisualCode
        });

        const fixChartSize = () => ChartWidgetService.fixChartSize({
            chartCssID: vm.chartCssID,
            widgetVisualCode: vm.widget.widgetVisualCode
        });

        // Workaround for bug where the dx-chart overflows out of the container element on initial render.
        $timeout(fixChartSize);
    };
}
