import answerDetailsTpl from './answerDetails.tpl.html';
import answerDetailsCtrl from './answerDetails.controller';

export default {
    templateUrl: answerDetailsTpl,
    controller: answerDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        results: '<',
        isAll: '<',
    }
};
