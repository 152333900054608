LoginAsServerSyncInterceptor.$inject = ['$injector'];

export default function LoginAsServerSyncInterceptor($injector) {
    return {
        request(config) {
            const Session = $injector.get('Session');
            const spectator = Session.getSpectator();
            if (spectator) {
                const user = Session.getUser();
                config.headers['Logged-In-As-UserID'] = user.userID;
                // See comments in loginAs.middleware.js setSpectator
            }
            return config;
        }
    };
}
