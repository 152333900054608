import customReportFormTpl from './customReportForm.tpl.html';
import customReportFormCtrl from './customReportForm.controller';

export default {
    templateUrl: customReportFormTpl,
    controller: customReportFormCtrl,
    controllerAs: 'vm',
    bindings: {
        report: '<',
        timePeriods: '<',
        onSubmitSuccess: '<',
    }
};
