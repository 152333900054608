import angular from 'angular';

import 'angular-translate';

import AUTH_EVENTS from './AUTH_EVENTS.constant';
import DEV_EX_CONFIG from './DEV_EX_CONFIG.constant';
import LANGUAGE_EVENTS from './LANGUAGE_EVENTS.constant';

import SharedConstantsService from './sharedConstantsService.factory';
import SessionTimeout from './sessionTimeout.factory';
import PointTrackingTypes from './pointTrackingTypes.factory';
import ClaimStatuses from './claimStatuses.factory';
import ContactStatuses from './contactStatuses.factory';
import PromoStatuses from './promoStatuses.factory';
import SuggestsTypes from './suggestsTypes.factory';
import QuizTypes from './quizTypes.factory';
import QuizQuestionTypes from './quizQuestionTypes.factory';
import QuizQuestionValidations from './quizQuestionValidations.factory';
import QuizResultStatuses from './quizResultStatuses.factory';
import OcrStatuses from './ocrStatuses.factory';
import QuizNoteTypes from './quizNoteTypes.factory';
import WidgetTypes from './widgetTypes.factory';
import WidgetDataDisplayTypes from './widgetDataDisplayTypes.factory';
import WidgetVisuals from './widgetVisuals.factory';
import FileTypeGroups from './fileTypeGroups.factory';
import GridReportFileStatuses from './gridReportFileStatuses.factory';
import PromoTypes from './promoTypes.factory';
import PromoActions from './promoActions.factory';
import NavBarTypes from './navBarTypes.factory';
import ApplauseCategoryTypes from './applauseCategoryTypes.factory';
import MilestoneTypes from './milestoneTypes.factory';
import SsoTypes from './ssoTypes.factory';
import Periods from './periods.factory';
import PointExpirationNotificationPeriods from './PointExpirationNotificationPeriods.factory';
import SpotlightFilterTypes from './spotlightFilterTypes.factory';
import ModalTypes from './ModalTypes.factory';
import GameStatuses from './GameStatuses.factory';

export default angular.module('app.core.constants', [
    'pascalprecht.translate',
])
.constant('AUTH_EVENTS', AUTH_EVENTS)
.constant('DEV_EX_CONFIG', DEV_EX_CONFIG)
.constant('LANGUAGE_EVENTS', LANGUAGE_EVENTS)
.factory('SharedConstantsService', SharedConstantsService)
.factory('SessionTimeout', SessionTimeout)
.factory('PointTrackingTypes', PointTrackingTypes)
.factory('ClaimStatuses', ClaimStatuses)
.factory('ContactStatuses', ContactStatuses)
.factory('PromoStatuses', PromoStatuses)
.factory('SuggestsTypes', SuggestsTypes)
.factory('QuizTypes', QuizTypes)
.factory('QuizQuestionTypes', QuizQuestionTypes)
.factory('QuizQuestionValidations', QuizQuestionValidations)
.factory('QuizResultStatuses', QuizResultStatuses)
.factory('OcrStatuses', OcrStatuses)
.factory('QuizNoteTypes', QuizNoteTypes)
.factory('WidgetTypes', WidgetTypes)
.factory('WidgetDataDisplayTypes', WidgetDataDisplayTypes)
.factory('WidgetVisuals', WidgetVisuals)
.factory('FileTypeGroups', FileTypeGroups)
.factory('GridReportFileStatuses', GridReportFileStatuses)
.factory('PromoTypes', PromoTypes)
.factory('PromoActions', PromoActions)
.factory('NavBarTypes', NavBarTypes)
.factory('ApplauseCategoryTypes', ApplauseCategoryTypes)
.factory('MilestoneTypes', MilestoneTypes)
.factory('SsoTypes', SsoTypes)
.factory('Periods', Periods)
.factory('PointExpirationNotificationPeriods', PointExpirationNotificationPeriods)
.factory('SpotlightFilterTypes', SpotlightFilterTypes)
.factory('ModalTypes', ModalTypes)
.factory('GameStatuses', GameStatuses);
