import applauseLeaderboardWidgetTpl from './applauseLeaderboardWidget.tpl.html';
import applauseLeaderboardWidgetCtrl from './applauseLeaderboardWidget.controller';


export default {
    templateUrl: applauseLeaderboardWidgetTpl,
    controller: applauseLeaderboardWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
