import _ from 'lodash';
import $ from 'jquery';

gridOptionButton.$inject = ['$compile'];

export default function gridOptionButton($compile) {
    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            onClick: '&',
            sref: '@',
            href: '@',
            text: '@',
            textTranslateKey: '@',
            buttonClass: '@',
            iconClass: '@',
            oneTimeBinding: '<',
        },
        controllerAs: 'vm',
        controller: [function gridOptionButtonCtrl() {
            this.$onInit = () => {
                const vm = this;
                _.defaults(vm, {
                    // Default one time binding for performance reasons.
                    // There might be 200+ options buttons on a grid at once, and that will add too many scope watches,
                    // making the page slow.
                    // Most of the time a button will stay as-is after the first render - text and css classes won't change.
                    // So dynamic buttons must explicitly specify one-time-binding="false".
                    oneTimeBinding: true,
                });
            };
        }],
        link: function(scope, element, attrs, vm) {
            // Assume the following won't dynamically change after being initially set by the parent:
            // - sref/href vs. onClick (i.e. changing from link to button or vice versa)
            // - text vs. textTranslateKey
            // - has tooltip or not

            let template;

            const isLink = Boolean(vm.sref || vm.href);
            if (isLink) {
                template = $(`
                    <a class="no-link-styling"></a>`
                );
                if (vm.sref) {
                    template.attr('ui-sref', '{{ ::vm.sref }}');
                } else {
                    template.attr('ng-href', '{{ ::vm.href }}');
                }
            } else {
                template = $(`
                    <button ng-click="::vm.onClick()"
                            class="no-button-styling"
                            type="button">
                    </button>`
                );
            }

            template.addClass('grid-option-button {{ ::vm.buttonClass }}');

            const hasTooltip = Boolean(vm.textTranslateKey || vm.text);
            if (hasTooltip) {
                if (vm.textTranslateKey) {
                    template.attr('uib-tooltip', '{{ ::(vm.textTranslateKey | translate) }}');
                } else {
                    template.attr('uib-tooltip', '{{ ::vm.text }}');
                }
                template.attr('tooltip-append-to-body', 'true');
            }

            const icon = $(`<i class="{{ ::vm.iconClass }}" aria-hidden="true"></i>`);

            const srText = $('<span class="sr-only"></span>');
            if (vm.textTranslateKey) {
                srText.attr('translate', '{{ ::vm.textTranslateKey }}');
            } else {
                srText.attr('ng-bind', '::vm.text');
            }

            template.append(icon);
            template.append(srText);

            let templateHtml = template.prop('outerHTML');
            if (!vm.oneTimeBinding) {
                templateHtml = templateHtml.replace(/::/gm, '');
            }

            const compiledTemplate = $compile(templateHtml)(scope);
            element.append(compiledTemplate);
        }
    };
}
