taskModalCtrl.$inject = [
    'signOnTask', 'taskNumber', 'totalNumberOfTasks', 'previousTask', 'SignOnTasks', '$scope', '$state', '$q',
    '$timeout', 'Session'
];

export default function taskModalCtrl(
    signOnTask, taskNumber, totalNumberOfTasks, previousTask, SignOnTasks, $scope, $state, $q,
    $timeout, Session
) {
    const vm = this;

    vm.signOnTask = signOnTask;
    vm.taskNumber = taskNumber;
    vm.totalNumberOfTasks = totalNumberOfTasks;
    vm.company = Session.getCompany();
    vm.showTermsConsent = vm.company.hasOption('TermsAcceptConsent');
    vm.disableSubmit = vm.showTermsConsent && vm.signOnTask.type === 'AcceptTerms' ? true : false;
    vm.submitSuccess = false;
    vm.successMessage = '';
    vm.acceptButtonText = signOnTask.acceptButtonText;

    vm.taskModalOptions = signOnTask.taskModalOptions;
    vm.isConsentTermsChecked = false;

    vm.skip = signOnTask.isRequired ? $scope.$dismiss : $scope.$close;

    vm.skipAll = $scope.$dismiss;

    vm.openPreviousTask = function() {
        // This is actually closing the current modal SERIES, then launching a new series starting with the previousTask.
        if (previousTask) {
            SignOnTasks.openAllTasks(previousTask);
        }
        return $scope.$dismiss();
    };

    vm.submitTaskCompletion = function() {
        vm.disableSubmit = true;
        const submittingTextTimeout = $timeout(() => {
            vm.acceptButtonText = 'app_SIGN_ON_TASKS_ACCEPT_BUTTON_SUBMITTING'; // 'Submitting...'
        }, 500);

        return signOnTask.submit($scope)
            .then(vm.onSuccess)
            .catch(function(err) {
                $timeout.cancel(submittingTextTimeout);
                return vm.onFail(err);
            });
    };

    vm.checkConsentTerms = function() {
        vm.disableSubmit = !vm.isConsentTermsChecked;
    }

    vm.onSuccess = function() {
        vm.submitSuccess = true;
        vm.successMessage = signOnTask.successMessage;

        return $q.all([
            $timeout(vm.taskModalOptions.successMessageTimeout),
            signOnTask.onModalSubmitSuccess($scope)
        ])
        .then(() => $scope.$close());
    };

    vm.onFail = function(err) {
        vm.disableSubmit = false;
        vm.submitSuccess = false;
        vm.acceptButtonText = signOnTask.acceptButtonText;

        return signOnTask.onModalSubmitFail(err, $scope);
    };

    // Additional logic is delegated here to the specific SignOnTask class ---------------------------------------------

    signOnTask.onModalControllerLoaded($scope);

    // -----------------------------------------------------------------------------------------------------------------
}
