import _ from 'lodash';

trainingQuizQuestionCtrl.$inject = ['$timeout', 'TrainingQuizHttpService', 'TrainingQuizService'];

export default function trainingQuizQuestionCtrl($timeout, TrainingQuizHttpService, TrainingQuizService) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            isDisabled: false,
            isGameQuiz: false,
            hideSubmitButton: false,
            submitSuccessCallback: (resData) => resData,
            submitFailCallback: (err) => {
                throw err;
            },
        });

        vm.selectAnswerID = Number(vm.selectAnswerID);

        let questionField;

        if (vm.isGameQuiz) {
            questionField = TrainingQuizService.convertGameQuizQuestionToFormly({
                question: vm.question,
                isDisabled: vm.isDisabled,
            });
            if (vm.question.isAnswerCorrect) {
                vm.showCorrectMessage = true;
            } else {
                vm.showIncorrectMessage = true;
            }
        } else {
            questionField = TrainingQuizService.convertTrainingQuizQuestionToFormly({
                question: vm.question,
                selectAnswerID: vm.selectAnswerID,
                correctAnswerID: vm.correctAnswerID,
                isDisabled: vm.isDisabled,
            });
        }

        vm.fields = [questionField];
        vm.model = {};

        vm.submitButtonOptions = {
            onSuccess() {
                this.hidden = true;
            }
        };

        vm.submit = () => TrainingQuizHttpService.saveAnswers({
            promoID: vm.promoDetails.ID,
            quizID: vm.promoDetails.quiz.ID,
            quizResultID: vm.quizResultID,
            quizAnswers: vm.model,
        });

        vm.onSubmitSuccess = (resData) => {
            const { isAnswerCorrect } = resData;
            if (isAnswerCorrect) {
                vm.showCorrectMessage = true;
            } else {
                vm.showIncorrectMessage = true;
            }
            return vm.submitSuccessCallback(resData);
        };

        vm.onSubmitFail = (err) => vm.submitFailCallback(err);
    };
}
