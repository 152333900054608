import angular from 'angular';

import filterModalCtrl from './filterModal.controller';
import FilterModalService from './filterModalService.factory';

import 'angular-ui-bootstrap';
import './dropdown/dropdown.module';
import './datepicker/datepicker.module';

export default angular.module('app.core.directives.rnDxGrid.filterModal', [
    'ui.bootstrap',
    'app.core.directives.rnDxGrid.filterModal.dropdown',
    'app.core.directives.rnDxGrid.filterModal.datepicker',
])
.factory('FilterModalService', FilterModalService)
.controller('filterModalCtrl', filterModalCtrl);
