import submissionsGridTpl from './submissionsGrid.tpl.html';
import submissionsGridCtrl from './submissionsGrid.controller';

export default {
    templateUrl: submissionsGridTpl,
    controller: submissionsGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        updateUrlParams: '<',
        isAdminView: '<'
    }
};
