promoCofirmationToolCtrl.$inject = ['$state', 'PromoActions', 'PromoService', '$translate'];

export default function promoCofirmationToolCtrl($state, PromoActions, PromoService, $translate) {
    this.$onInit = () => {
        const vm = this;
        vm.actionInfo = {};
        switch (vm.action) {
            case PromoActions.CLONE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_CLONE'),
                };
                break;
            case PromoActions.DELETE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_DELETE'),
                };
                break;
            case PromoActions.PAUSE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_PAUSE'),
                };
                break;
            case PromoActions.UNPAUSE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_UNPAUSE'),
                };
                break;
            case PromoActions.ARCHIVE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_ARCHIVE'),
                };
                break;
            case PromoActions.UNARCHIVE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_UNARCHIVE'),
                };
                break;
            case PromoActions.ACTIVATE:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_ACTIVATE'),
                };
                break;
            case PromoActions.ADD_TO_LIBRARY:
                vm.actionInfo = {
                    text: $translate.instant('promo_ACTION_ADD_TO_LIBRARY'),
                };
                break;
        }

        vm.onConfirm = async () => {
            if (vm.action === PromoActions.ADD_TO_LIBRARY) {
                const { codeName, promoLibraryID } = await PromoService.addToQuickStartLibrary(vm.promo.ID);
                return $state.go('incentiveLibraryList', {
                    promoLibraryTypeCode: codeName,
                    promoLibraryID,
                });
            }
            const { statusID } = await PromoService.updatePromoStatus(vm.promo.ID, vm.action);
            vm.promo.promoStatusID = statusID;

            return $state.go('incentiveEdit', { id: vm.promo.ID }, { reload: true });
        };
    };
}
