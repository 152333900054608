import createTemplate from './createTemplate';
import createController from './createController';
import createConfig from './createConfig';

/**
 * @param {GridPageOptions} options
 * @returns {Array} Angular config for the grid page
 */
export default function createGridPageConfig(options) {
    const template = createTemplate(options);
    const controller = createController(options);
    return createConfig({
        ...options,
        template,
        controller
    });
}

/**
 * @typedef {Object} GridPageOptions
 *
 * @property {Object} grid
 * @property {string} grid.componentName
 *      Snake-cased component name for the grid.
 *
 * @property {Object} state
 * @property {string} state.name
 * @property {Object} state.config
 *      Custom ui-router `$stateProvider.state` options for the page.
 *
 * @property {Array | Function} [controller]
 *      A hook for you to do extra things in the page's controller.
 *
 * @property {Object} template
 * @property {string | function(grid): string} [template.gridComponentBindings]
 *      If the grid has more component bindings that need to be set, specify them here.
 *      Needs to be a string or a function that returns a string of HTML attributes. Ex. `contact-id="contactID"`.
 *      If a function is passed, it will be run after the custom `controller` is ran.
 * @property {string} [template.header]
 *      Directly passed to the `header` component binding of `<grid-page-container>`
 *      (can be an Angular expression).
 * @property {boolean} [template.translateHeader = true]
 *      Directly passed to the `translateHeader` component binding of `<grid-page-container>`
 *      (can be an Angular expression).
 * @property {string} [template.containerClass]
 *      CSS class to add to the `<grid-page-container>` component.
 * @property {string} [template.headerTransclude]
 *      For `<grid-page-container>`.
 */
