import FormTask from './FormTask';

// NewsletterSignUpTask hasn't been needed by any companies yet,
// so this class isn't fully implemented and more for just demonstrating how FormTask works.
// May need some tweaking before using in live.

export default class NewsletterSignUpTask extends FormTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        // TODO: phrases for title, acceptButtonText, cancelButtonText
    }

    get formFields() {
        return [
            {
                key: 'email',
                type: 'customInput',
                templateOptions: {
                    type: 'email',
                    label: 'Email',
                    placeholder: 'name@company.com',
                    labelClass: 'col-xs-3 col-sm-4',
                    controlClass: 'col-xs-9 col-sm-8',
                    required: true
                }
            }
        ];
    }
}
