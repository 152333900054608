adminFrameCtrl.$inject = ['$stateParams'];

export default function adminFrameCtrl($stateParams) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.adminFrameUrl
        // vm.adminFrameClass

        if ($stateParams.adminFrameUrl) {
            vm.adminFrameURL = $stateParams.adminFrameUrl;
        }
        if ($stateParams.adminFrameClass) {
            vm.adminFrameClass = $stateParams.adminFrameClass;
        }
    };
}
