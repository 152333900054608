

const constantUtils = require('./constantUtils');

const QuizTypes = {
    STANDARD: 1,
    TRAINING: 2,
};

const properties = {};

constantUtils.configure({ constants: QuizTypes, properties });

module.exports = QuizTypes;
