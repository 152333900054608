import angular from 'angular';

import '@uirouter/angularjs';
import 'ngstorage';

import debugConfig from './debug.config';
import urlConfig from './url.config';
import stateConfig from './state.config';
import httpConfig from './http.config';
import storageConfig from './storage.config';

import promiseRun from './promise.run';

export default angular.module('app.core.config', [
    'ui.router',
    'ngStorage',
])
.config(debugConfig)
.config(urlConfig)
.config(stateConfig)
.config(httpConfig)
.config(storageConfig)
.run(promiseRun);
