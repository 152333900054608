import _ from 'lodash'

contentManagementConfig.$inject = ['$stateProvider'];

export default function contentManagementConfig($stateProvider) {

    $stateProvider.state('contentManagement.detail', {
        url: '/:id',
        views: {
            'main@': 'contentManagementDetailComponent',
        },
        data: {
            pageTitle: 'Content Management Detail',
            permission: 'EditCustomContent'
        },
        resolve: {
            content: [
                'ContentManagementDetailService',
                '$stateParams',
                function(ContentManagementDetailService, $stateParams) {
                    if($stateParams.id === 'new') {
                        return {
                            content: 'new content',
                            roles:[]
                        };
                    }
                    return ContentManagementDetailService.getContent(_.toInteger($stateParams.id));
                }],
            contentTopicsList: ['ContentManagementService', function(ContentManagementService) {
                return ContentManagementService.getContentTopicsList();
            }],
            roles: ['ContentManagementService', function(ContentManagementService) {
                return ContentManagementService.getRoles();
            }],
        },
    });
}
