import angular from 'angular';

import '../../../../core/core.module';
import '../contactPanelTools/contactPanelTools.module';

import contactPanelLinks from './contactPanelLinks.component';

export default angular.module('app.contacts.contactPanel.contactPanelLinks', [
    'app.core',
    'app.contacts.contactPanel.contactPanelTools'
])
.component('contactPanelLinks', contactPanelLinks);
