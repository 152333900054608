import legacyFrameTpl from './legacyFrame.tpl.html';
import legacyFrameCtrl from './legacyFrame.controller';

export default {
    templateUrl: legacyFrameTpl,
    controller: legacyFrameCtrl,
    controllerAs: 'vm',
    bindings: {
        path: '@'
    }
};
