import rnDxGridTpl from './rnDxGrid.tpl.html';
import rnDxGridCtrl from './rnDxGrid.controller';

rnDxGrid.$inject = [];

export default function rnDxGrid() {
    return {
        templateUrl: rnDxGridTpl,
        controller: rnDxGridCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            gridID: '@gridId',
            apiUrl: '@',
            formatQueryResponse: '<',
            initialFilters: '<',
            initialColumns: '<',
            initialSort: '<',
            updateUrlParams: '<',
            filterHeaderTranslateKey: '@',
            noDataTranslateKey: '@',
            settingsSlot: '@',
            defaultReportName: '@',
            defaultReportNameTranslateKey: '@',

            // https://js.devexpress.com/Documentation/16_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
            columns: '<',

            // https://js.devexpress.com/Documentation/16_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/
            extraDataGridOptions: '<',

            // https://js.devexpress.com/Documentation/16_2/Guide/Widgets/DataGrid/Data_Binding/Custom_Sources/
            extraCustomStoreOptions: '<',
        }
    };
}
