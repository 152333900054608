const constantUtils = require('./constantUtils');


const SessionTimeout = {
    EOS: 1200000, // 20 minutes = 1200000
    warning: 1140000 // 19 minutes = 1140000
};

const properties = {};

constantUtils.configure({ constants: SessionTimeout, properties });

module.exports = SessionTimeout;
