import moment from 'moment';
import _ from 'lodash';

snapshotFilterCtrl.$inject = ['$rootScope', '$timeout', '$stateParams', 'SnapshotService', '$translate'];

export default function snapshotFilterCtrl($rootScope, $timeout, $stateParams, SnapshotService, $translate) {
    this.$onInit = () => {
        const vm = this;
        vm.contactID = $stateParams.contactID;

        vm.updateDateFilter = updateDateFilter;
        vm.updateShortListFilter = updateShortListFilter;
        vm.updateWidgets = updateWidgets;

        if (vm.widgetPages) {
            vm.widgetPages.forEach(page => {
                page.sets.forEach(set => {
                    if (set.filters) {
                        set.filters.forEach(filter => {
                            if (filter.filterType === 'customDate') {
                                filter.options = Object.assign([], SnapshotService.getDateFilterOptions());
                                const startYear = filter.startYear ? filter.startYear: moment().subtract(3, 'year').year();

                                for (let year = moment().year(); year >= startYear; year--) {
                                    filter.options.push({
                                        phrase: year,
                                        code: 'year-option'
                                    });

                                    for (let month = 1; moment([year, '-', month].join(), 'YYYY-M').isSameOrBefore(moment(), 'month'); month++) {
                                        if ((month + 2) % 3 === 0) {
                                            const quarter = year + ' Q'.concat((month + 2) / 3);
                                            filter.options.push({ phrase: quarter,
                                                quarterNumber: (month + 2) / 3,
                                                year: year,
                                                code: 'quarter-option'
                                            });
                                        }
                                        filter.options.push({
                                            phrase: moment(month, 'M').format('MMMM'),
                                            month: month,
                                            year: year,
                                            code: 'month-option'
                                        });
                                    }
                                }
                            }
                        });
                    }
                });
            });
        }

        function updateShortListFilter(filter, option) {
            if (!option) {
                if (filter.currentValue.name === filter.displayName) {
                    return;
                }
                filter.currentValue = { name: filter.displayName };
                updateWidgets();
                return;
            }
            if (filter.currentValue.parameterValue === option.parameterValue) {
                return;
            }
            filter.currentValue = option;
            updateWidgets();
        }

        function updateDateFilter(filter, option) {
            switch (option.code) {
                case 'confirmCustomDate':
                    filter.showDatePickers = false;
                    filter.displayValue = $translate.instant('snapshot_FILTER_DATE_OPTION_CUSTOM_DATE', {
                        startDate: moment(filter.currentValue[filter.parameters.startDate]).format('YYYY-MM-DD'),
                        endDate: moment(filter.currentValue[filter.parameters.endDate]).format('YYYY-MM-DD')
                    });
                    updateWidgets();
                    return;
                case 'clearFilter':
                    filter.showDatePickers = false;
                    filter.displayValue = filter.displayName;
                    if (_.isObject(filter.default) && filter.default.code === 'Custom') {
                        filter.currentValue[filter.parameters.startDate] = moment(filter.default.startDate).toDate();
                        filter.currentValue[filter.parameters.endDate] = moment(filter.default.endDate).toDate();
                    } else {
                        delete filter.currentValue[filter.parameters.startDate];
                        delete filter.currentValue[filter.parameters.endDate];
                    }
                    updateWidgets();
                    return;
                case 'Custom':
                    filter.showDatePickers = !filter.showDatePickers;
                    delete filter.currentValue[filter.parameters.startDate];
                    delete filter.currentValue[filter.parameters.endDate];
                    break;
                default:
                    filter.currentValue = SnapshotService.getDateFilterValueByCode({option, filter});
                    break;
            }

            if (filter.displayValue === option.phrase) {
                return;
            }
            filter.displayValue = option.phrase;
            if (_.isDate(filter.currentValue[filter.parameters.startDate])
            && _.isDate(filter.currentValue[filter.parameters.endDate])) {
                filter.showDatePickers = false;
                updateWidgets();
            }
        }

        function updateWidgets() {
            $timeout(() => {
                const filters = vm.widgetPages[vm.activePageIndex].sets[vm.activeSetIndex].filters;
                $rootScope.$emit('WIDGET_FILTER', filters);
            });
        }
    };
}
