accountConfirmedCtrl.$inject = [
    '$state', '$stateParams', 'GridColumnTypes', 'GridColumnFilterTypes', 'PointTrackingTypes', 'PointTrackingService'
];

export default function accountConfirmedCtrl(
    $state, $stateParams, GridColumnTypes, GridColumnFilterTypes, PointTrackingTypes, PointTrackingService
) {
    this.$onInit = () => {
        const vm = this;

        vm.gridID = vm.gridID || 'grid-account-confirmed';

        vm.pageType = 'account';
        vm.captionTranslateKey = 'account_OPTIONS_COLUMN_HEADING';
        if($stateParams.pageType && $stateParams.pageType.toLowerCase() === 'myorders') {
            vm.pageType = 'myOrders';
            vm.captionTranslateKey = 'account_MY_ORDERS_OPTIONS_COLUMN_HEADING';
        }        

        vm.columns = [{
            dataField: 'transactionName',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'account_TRANSACTION_NAME_COLUMN_HEADING',
            }
        }, {
            dataField: 'points',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'app_POINTS',
            }
        }, {
            dataField: 'reference',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'account_REFERENCE_COLUMN_HEADING',
            }
        }, {
            dataField: 'date',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'account_DATE_COLUMN_HEADING',
            }
        }, {
            dataField: 'pointTrackingTypeID',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.CHECKBOX,
                captionTranslateKey: 'account_TYPE_COLUMN_HEADING',
                showValueOf: 'pointTrackingTypeName',
                listOptions: PointTrackingService.getPointTrackingTypeListOptions(),
            }
        }, {
            nonDevExOptions: {
                isOptionsColumn: true,
                captionTranslateKey: vm.captionTranslateKey,
                cellTemplate: `
                    <grid-option-button ng-if="vm.isNotExpired(cell)"
                                        on-click="vm.openDetailsModal(cell)"
                                        text-translate-key="account_OPEN_DETAILS_ICON_TITLE"
                                        button-class="details-button"
                                        icon-class="fa fa-search-plus">
                    </grid-option-button>
                    <grid-option-button ng-if="cell.data.isCorrection"
                                        on-click="vm.openCorrectionModal(cell)"
                                        text-translate-key="account_OPEN_CORRECTION_DETAILS_ICON_TITLE"
                                        button-class="correction-button"
                                        icon-class="fa fa-sticky-note-o correction-note">
                    </grid-option-button>`
            }
        }];

        vm.openDetailsModal = (cell) => {
            const params = $stateParams;
            params.pointTrackingID = cell.data.pointTrackingID;
            $state.go('account', params);
            return PointTrackingService.openModal({ pointTrackingID: cell.data.pointTrackingID });
        };

        vm.openCorrectionModal = (cell) => {
            const contactID = $stateParams.contactID;
            return PointTrackingService.openCorrectionModal({
                pointTrackingID: cell.data.pointTrackingID,
                contactID
            });
        };

        vm.isNotExpired = (cell) => cell.data.pointTrackingTypeID !== PointTrackingTypes.EXPIRED;
    };
}
