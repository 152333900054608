import _ from 'lodash';

export default function onDomChanges() {
    return {
        restrict: 'A',
        scope: {
            callback: '&onDomChanges'
        },
        link($scope, $element) {
            const observer = new MutationObserver(_.debounce($scope.callback, 100));
            observer.observe($element[0], { attributes: true, childList: true, subtree: true });
            $scope.$on('$destroy', () => observer.disconnect());
        }
    };
}
