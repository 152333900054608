DashboardService.$inject = ['$http'];

export default function DashboardService($http) {
    let dashboard = {};
    return {
        getDashboard,
        getIncentiveDashboard,
        getReport,
    };

    async function getDashboard({ dashboardCode, dashboardID }) {
        const { data } = await $http.get(`/api/dashboard/${dashboardCode}/${dashboardID}`);
        dashboard = data;
        return data;
    }

    async function getIncentiveDashboard({ incentiveID }) {
        const { data } = await $http.get(`/api/incentive/dashboard/${incentiveID}`);
        dashboard = data;
        return data;
    }

    function getReport({ reportCode }) {
        if (dashboard && dashboard.reports) {
            return dashboard.reports.find(report => report.code === reportCode);
        }
    }
}
