import promoViewTpl from './promoView.tpl.html';

promoViewConfig.$inject = ['$stateProvider'];

export default function promoViewConfig($stateProvider) {

    $stateProvider.state('awardNominate', {
        parent: 'auth',
        url: '/award/nominate/:id',
        views: {
            'main@': {
                controller: 'promoViewCtrl as vm',
                templateUrl: promoViewTpl
            }
        },
        data: {
            pageTitle: 'Awards Nomination',
            permission: 'NominateAward',
            isAward: true
        },
        resolve: {
            promoDetails: ['PromoService', '$stateParams', function(PromoService, $stateParams) {
                return PromoService.getAwardDisplay($stateParams.id);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }]
        }
    });

    $stateProvider.state('incentiveView', {
        parent: 'auth',
        url: '/incentive/:id/view',
        views: {
            'main@': {
                controller: 'promoViewCtrl as vm',
                templateUrl: promoViewTpl
            }
        },
        data: {
            pageTitle: 'Incentive View',
            permission: 'ParticipateIncentive',
            isIncentive: true
        },
        resolve: {
            promoDetails: ['PromoService', '$transition$', function(PromoService, $transition$) {
                const params = $transition$.params();
                return PromoService.getIncentiveDisplay(params.id);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }]
        }
    });

    // Added redirect state for legacy reasons. 
    // the state that was mapped to: '/incentive/details/:id' is now mapped to '/incentive/:id/view'
    // legacy and other non ng part of the site are broken as a result
    $stateProvider.state('incentiveDetails', {
        parent: 'auth',
        url: '/incentive/details/:id',
        data: {
            pageTitle: 'Incentive View',
            permission: 'ParticipateIncentive',
            isIncentive: true
        },
        redirectTo: (trans) => {
            return { state: 'incentiveView', params: { id: trans.params().id } };
        }
    });
}
