import calculateInfoWidgetTpl from './calculateInfoWidget.tpl.html';
import calculateInfoWidgetCtrl from './calculateInfoWidget.controller';

export default {
    templateUrl: calculateInfoWidgetTpl,
    controller: calculateInfoWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
