import moment from 'moment';

anniversaryWidgetCtrl.$inject = ['Session'];

export default function anniversaryWidgetCtrl(Session) {
    this.$onInit = () => {
        const vm = this;

        vm.hasData = vm.widget.data.date != null;
        if (!vm.hasData) {
            return;
        }

        vm.user = Session.getUser();
        vm.isSelf = vm.contact.userID === vm.user.userID;

        const date = moment(vm.widget.data.date);
        vm.date = date.format('LL');
        vm.years = moment().diff(date, 'years', false);


        if (vm.years < 1) {
            const isBefore = moment(vm.date).isBefore(moment(), 'day');
            const firstDay = vm.date === moment().format('LL');
            vm.date = isBefore || firstDay ? moment(vm.date).add(1, 'years').format('LL') : vm.date;
        }
    };
}
