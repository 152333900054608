import _ from 'lodash';
import defaultGaugeConfig from './defaultGaugeConfig';

GaugeWidgetService.$inject = ['$translate', 'WidgetService', 'WidgetDataDisplayTypes'];

export default function GaugeWidgetService($translate, WidgetService, WidgetDataDisplayTypes) {

    // Colors/sections leading up to the first target.
    const PRE_TARGET_SECTION_COLOURS = ['#dd485e', '#f0ac49', '#f8d205'];

    // Colors/sections for percent type gauge without targets.
    const WITHOUT_TARGET_SECTION_COLOURS = ['#f8d205', '#5cc742', '#42ae28', '#2f9417', '#1f7d08'];

    // The last 4 colors are to differentiate targets from each other.
    // If there are more than 4 targets, everything after the 4th target will be the same color.
    const TARGET_SECTION_COLOURS = ['#5cc742', '#42ae28', '#2f9417', '#1f7d08'];

    // Used to divide the area before the first target of the gauge into sections (to look nice).
    let NUMBER_OF_PRE_GAUGE_SECTIONS = PRE_TARGET_SECTION_COLOURS.length;

    // Multiply the last target by this factor to get the endRange value.
    const LAST_TARGET_SCALE_FACTOR = 1.1;

    /**
     * @typedef {Object} GaugeDetails
     * @property {{ targetValue: number }[]} pointTargets - Targets for the gauge. Ex. 10 points, 20 points, 30 points
     * @property {number} standingValue - Current value for the user
     * @property {string} [tooltipDisplayType]
     * @property {number} [maxGaugeValue]
     * @property
     */

    /**
     * Creates a config object for dx-circular-gauge.
     * @param {GaugeDetails} gaugeDetails
     * @returns {Object}
     */
    function createGaugeConfig(gaugeDetails) {
        const isGaugePercent = gaugeDetails.gaugeType === WidgetDataDisplayTypes.PERCENT;
        if (isGaugePercent) {
            NUMBER_OF_PRE_GAUGE_SECTIONS = WITHOUT_TARGET_SECTION_COLOURS.length;
        }
        const { pointTargets = [], standingValue = 0 } = gaugeDetails;
        const gaugeTargetValues = pointTargets.map(target => target.targetValue);
        const preTargetSectionSize = pointTargets[0].targetValue / NUMBER_OF_PRE_GAUGE_SECTIONS;
        const preTargetRanges = createPreTargetRanges(preTargetSectionSize, isGaugePercent);
        let ranges;
        if (isGaugePercent) {
            ranges = preTargetRanges;
        } else {
            const targetRanges = createTargetRanges(gaugeDetails);
            ranges = preTargetRanges.concat(targetRanges);
        }

        const gaugeConfig = {
            scale: {
                startValue: ranges[0].startValue,
                endValue: ranges[ranges.length - 1].endValue,
                tickInterval: isGaugePercent ? 20 : null,
            },
            rangeContainer: {
                ranges
            },
            value: standingValue,
            subvalues: isGaugePercent ? null : gaugeTargetValues,
            tooltip: {
                enabled: true,
                customizeTooltip: function(arg) {
                    // If arg.index >= 0, the value is an array, making it a target. If it's not part of an array,
                    // it's the value of the needle pointer, which doesn't show a target name.
                    const valueLabel = arg.index >= 0
                        ? pointTargets[arg.index].targetName : $translate.instant('app_GAUGE_WIDGET_VALUE_TOOLTIP');
                    const value = WidgetService.formatTooltipValue({
                        displayType: gaugeDetails.tooltipDisplayType,
                        value: arg.value
                    });
                    let html = `${valueLabel}: <span class="incentive-sales-dollar-value">${value}</span>`;
                    if (isGaugePercent) {
                        html = `<span class="incentive-sales-dollar-value">${value}</span>`;
                    }
                    return {
                        // TODO: Rename
                        // This css class is specific to incentives but the gaugeWidget component is supposed to be
                        // used throughout the app.
                        html
                    };
                },
                font: {
                    color: 'black',
                    size: 20
                }
            },
            margin: {
                top: 0,
                bottom: 10,
                left: 10,
                right: 10
            }
        };

        return _.merge({}, defaultGaugeConfig, gaugeConfig);
    }

    /**
     * Creates an array of ranges for dx-circular-gauge for the section before the first target.
     * Ex. the first target of an incentive.
     * @param {number} sectionSize
     * @returns {Object[]} Ranges before the first target
     */
    function createPreTargetRanges(sectionSize, isGaugePercent) {
        const ranges = [];
        for (let i = 0; i < NUMBER_OF_PRE_GAUGE_SECTIONS; i++) {
            ranges[i] = {
                startValue: sectionSize * i,
                endValue: sectionSize * (i + 1),
                color: isGaugePercent ? WITHOUT_TARGET_SECTION_COLOURS[i] : PRE_TARGET_SECTION_COLOURS[i],
            };
        }
        return ranges;
    }

    /**
     * Creates an array of ranges for dx-circular-gauge with one section per target.
     * Ex. targets specified for an incentive.
     * @param {GaugeDetails} gaugeDetails
     * @returns {Object[]} Ranges
     */
    function createTargetRanges(gaugeDetails) {
        // TODO: refactor to use the calculateRangeNumber function (from shared folder)
        const { pointTargets = [], standingValue = 0, maxGaugeValue } = gaugeDetails;
        const ranges = [];
        for (let i = 0; i < pointTargets.length; i++) {
            ranges[i] = {
                startValue: pointTargets[i].targetValue,
                endValue: pointTargets[i + 1]
                    ? pointTargets[i + 1].targetValue : pointTargets[i].targetValue,
                color: TARGET_SECTION_COLOURS[i]
                    ? TARGET_SECTION_COLOURS[i] : TARGET_SECTION_COLOURS[TARGET_SECTION_COLOURS.length - 1]
            };
        }
        const lastTarget = pointTargets[pointTargets.length - 1].targetValue;
        const scaledLastTarget = Math.floor(lastTarget * LAST_TARGET_SCALE_FACTOR);
        if (maxGaugeValue) {
            // Sometimes the db wants to specify what the end of the gauge should be,
            // rather than just scaling the last target.
            ranges[ranges.length - 1].endValue = standingValue > maxGaugeValue ? standingValue : maxGaugeValue;
        } else if (standingValue > scaledLastTarget) {
            // DevEx doesn't allow the needle's value to be larger than the endValue.
            // Ex. if the endValue was set to 25 and the needle value was 30, the needle value would be displayed as 25.
            // To show the needle's actual value, if a user's standingValue exceeds the endValue,
            // set the endValue to standingValue.
            ranges[ranges.length - 1].endValue = standingValue;
        } else {
            // We don't want the last target to be at the very end of the gauge, we want to show that the last target
            // can be exceeded, so make the endValue a little bit beyond the last target.
            ranges[ranges.length - 1].endValue = scaledLastTarget;
        }
        return ranges;
    }

    /**
     * Used to find the percent and value to next target.
     * @param {GaugeDetails} gaugeDetails
     * @returns {{ percentToNextTarget: int, valueToNextTarget: number, [nextTarget]: int }}
     */
    function calculateNextTargetDetails(gaugeDetails) {
        const { pointTargets = [], standingValue = 0 } = gaugeDetails;
        const gaugeTargetValues = pointTargets.map(target => target.targetValue);
        const isLastTargetPassed = standingValue >= gaugeTargetValues[gaugeTargetValues.length - 1];
        if (isLastTargetPassed) {
            // no next target
            return {
                percentToNextTarget: 0,
                valueToNextTarget: 0
            };
        }
        const previousGaugeTargets = gaugeTargetValues.filter((target) => standingValue >= target);
        const previousTarget = previousGaugeTargets[previousGaugeTargets.length - 1] || 0;
        const nextTarget = gaugeTargetValues.find((target) => standingValue < target);
        const percentToNextTarget = Math.ceil(((standingValue - previousTarget) / (nextTarget - previousTarget)) * 100);
        const valueToNextTarget = nextTarget - standingValue;
        return {
            percentToNextTarget,
            valueToNextTarget,
            nextTarget
        };
    }

    return {
        createGaugeConfig,
        calculateNextTargetDetails
    };
}
