import _ from 'lodash';

promiseToggleCtrl.$inject = ['$q', '$timeout'];

export default function promiseToggleCtrl($q, $timeout) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            checked: false,
            delaySpinnerTime: 500,
            onToggle: () => {
                vm.promise = $q.resolve();
                vm.promise.then(() => vm.checked = !vm.checked);
            }
        });

        vm.pending = false;
        vm.showSpinner = false;

        vm.$onChanges = (changes) => {
            if (changes.promise && changes.promise.currentValue) {
                handleNewPromise(changes.promise.currentValue);
            }
        };

        function handleNewPromise(promise) {
            vm.pending = true;
            const spinnerTimeout = $timeout(() => vm.showSpinner = true, vm.delaySpinnerTime);
            return promise.finally(() => {
                $timeout.cancel(spinnerTimeout);
                vm.showSpinner = false;
                vm.pending = false;
            });
        }
    };
}
