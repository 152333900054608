import selectTpl from './select.tpl.html';

customSelectRun.$inject = ['formlyConfig'];

export default function customSelectRun(formlyConfig) {
    formlyConfig.setType({
        name: 'customSelect',
        templateUrl: selectTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions(options) {
            const ngOptions = options.templateOptions.ngOptions || `option[to.valueProp || 'value'] as option[to.labelProp || 'name'] group by option[to.groupProp || 'group'] for option in to.options`;
            const ngChange = options.templateOptions.ngChange;
            if (ngChange) {
                return {
                    ngModelAttrs: {
                        [ngOptions]: {
                            value: options.templateOptions.optionsAttr || 'ng-options'
                        },
                        [ngChange]: {
                            value: options.templateOptions.changesAttr || 'ng-change'
                        }
                    }
                };
            }
            return {
                ngModelAttrs: {
                    [ngOptions]: {
                        value: options.templateOptions.optionsAttr || 'ng-options'
                    }
                }
            };
        },
        controller: ['$scope', function($scope) {
            const to = $scope.to;
            const options = $scope.options;
            const defaultSelectedOption = to.options && to.options.find(option => option.isSelected);
            if (defaultSelectedOption) {
                $scope.model[options.key] = defaultSelectedOption[to.valueProp];
            }
        }]
    });
}
