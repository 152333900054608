questionOverviewCtrl.$inject = ['Session', 'PromoService'];

export default function questionOverviewCtrl(Session, PromoService) {
    const vm = this;
    // Passed passed from component bindings:
    // vm.questionData -> comes from modal data
    // vm.questions -> all questions related to the incentive
    vm.$onInit = async () => {
        // Get questions details and game ID information
        const user = Session.getUser();
        const company = Session.getCompany();
        const customColorPalette = user.colorPalette || company.defaultColorPalette;
        const questionDetails = vm.questions.details;
        vm.questionIndex = questionDetails
                            .findIndex(question => question.quizQuestionID === vm.questions.rowData.QuestionID);

        if (vm.questionIndex === -1) {
            return;
        }

        vm.allQuestions = questionDetails;
        vm.questionDetails = questionDetails[vm.questionIndex];

        updateQuestionDetails(vm.questionDetails);

        disableFlowButton(vm.questionIndex);

        // PieChart configuration
        vm.pieChartContainerHeight = 300;
        vm.pieChartConfig = {
            dataSource: vm.questionDetails.answers,
            series: {
                argumentField: 'tag',
                valueField: 'totalPercent',
            },
            type: 'doughnut',
            palette: customColorPalette || 'Soft Pastel',
            paletteExtensionMode: 'Blend',
            legend: {
                horizontalAlignment: 'right',
                margin: {
                    top: 10,
                    right: 15,
                    bottom: 0,
                    left: 10,
                },
                customizeText: function(data) {
                    return `${data.pointName} - ${vm.questionDetails.answers[data.pointIndex].totalPercent}%`;
                },
            },
            tooltip: {
                enabled: true,
                location: 'edge',
                zIndex: 99999,
                customizeTooltip: function(data) {
                    return {
                        text: `${data.valueText}%`,
                    };
                },
            },
            margin: {
                top: 10,
                bottom: 15,
                left: 10,
                right: 15
            },
            adaptiveLayout: {
                width: null,
                height: null,
            },
            innerRadius: 0.7,
            bindingOptions: {
                'dataSource': 'vm.questionDetails.answers'
            }
        };

        function disableFlowButton(questionIndex) {
            vm.disableNextButton = questionIndex === vm.allQuestions.length - 1;
            vm.disablePreviousButton = questionIndex === 0;
        }

        function updateQuestionDetails() {
            vm.questionDetails.answers.forEach((answer) => {
                if (answer.isCorrect) {
                    vm.correctTotal = answer.totalAnswered;
                }
            });
            vm.questionDetails.answers.map((answer, index) => {
                const abcArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
                answer.tag = abcArray[index];
                answer.totalPercent = ((answer.totalAnswered / vm.questionDetails.allAnswered) * 100).toFixed(2);
                return answer;
            });
            let secs = vm.questionDetails.avgTimeInSeconds;
            const minutes = Math.floor(vm.questionDetails.avgTimeInSeconds / 60);
            secs %= 60;
            vm.averageTimeInMinutes = minutes;
            vm.averageTimeInSeconds = Math.floor(secs);
        }

        vm.previousQuestion = () => {
            if (vm.questionIndex === 0) {
                return;
            }
            vm.questionIndex -= 1;
            vm.questionDetails = vm.allQuestions[vm.questionIndex];
            disableFlowButton(vm.questionIndex);
            updateQuestionDetails(vm.questionDetails);
        };

        vm.nextQuestion = () => {
            if (vm.questionIndex === vm.allQuestions.length - 1) {
                return;
            }
            vm.questionIndex += 1;
            vm.questionDetails = vm.allQuestions[vm.questionIndex];
            disableFlowButton(vm.questionIndex);
            updateQuestionDetails(vm.questionDetails);
        };
    };
}
