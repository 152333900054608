import _ from 'lodash';
import ReadTask from './ReadTask';

export default class PointExpirationTask extends ReadTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.Session = $injector.get('Session');
        this.user = this.Session.getUser();
        this.userID = this.user.userID;
        this.PointExpiryTableService = $injector.get('PointExpiryTableService');
        this.PointTrackingService = $injector.get('PointTrackingService');


        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_POINT_EXPIRY_EXPIRING_SOON',
            successMessage: 'app_SIGN_ON_TASKS_POINT_EXPIRY_TASK_READ',
            taskModalOptions: {
                showFooterButtons: false,
                successMessageTimeout: 3000
            }
        });
    }

    get extraModalOptions() {
        return {
            size: 'md'
        };
    }

    getAdditionalData() {
        return this.PointExpiryTableService.getExpiringPointsForecast(this.userID)
            .then(pointExpirationDetails => {
                this.pointExpirationDetails = pointExpirationDetails;
            });
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;
        vm.pointExpirationDetails = this.pointExpirationDetails;
        vm.userID = this.userID;
    }

    get componentHtml() {
        return `
                    <point-expiry  point-expiration-details="vm.pointExpirationDetails" contact-id="vm.userID">
                    </point-expiry>
                    <h5>
                        <a  ui-sref="rewards"
                            translate="app_SIGN_ON_TASKS_POINT_EXPIRY_SEE_REWARDS"
                            class="see-rewards">
                        </a>
                    </h5>
                    <h5>
                        <a  ui-sref="account({displayPointExpiration:1})"
                            translate="app_SIGN_ON_TASKS_POINT_EXPIRY_MANAGE_WARNINGS"
                            class="manage-warnings">
                        </a>
                    </h5>
               `;
    }

    onSubmit() {
        return this.PointTrackingService.updateIsPointExpirationWarning({ isPointExpirationWarning: 0 });
    }
}
