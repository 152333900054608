import _ from 'lodash';

GridUrlFilters.$inject = ['$location', 'StateUtils', 'GridFilterOperators', 'GridColumnTypes'];

export default function GridUrlFilters($location, StateUtils, GridFilterOperators, GridColumnTypes) {

    /*
        This service is for making the grid filters showing in the URL more user-friendly and easily modifiable.
        Using JSON.stringify() the array of filters and putting it in the URL causes all the special characters
        (brackets, commas, colons, etc) to be url-encoded and hard to read and modify.
        We want to avoid putting special characters in the URL, so the format we've decided on is:

        filters={{ column }}--{{ operator }}--{{ value }}__{{ column }}--{{ operator }}--{{ value }}

        Ex. filters array:
            [
                { field: 'transactionName', operator: 'contains', type: 'text', value: 'widget' },
                { field: 'points', operator: 'eq', type: 'number', value: '500' }
            ]
        URL params will be:
             filters=transactionName--contains--widget__points--eq--500

        For array values (checkbox types), the separator is ..
        Ex. statusID--in--23..42..53

        If it's a filter where a value isn't required, it can be left out. Just the operator alone is allowed.

        Ex. date last 90 days
            ?filters=date--l90d

        When filters are modified, removed, or added, we'll convert the array to the format and update the URL.
        When a user goes to a url with formatted filters in the params, we'll parse the url and create the array needed.

        Note: tildas (~) couldn't be used because for some reason ui-router doubles them when used in ui-srefs.
     */

    const FILTER_SEPARATOR = '__';
    const FILTER_PROPERTY_SEPARATOR = '--';
    const ARRAY_VALUE_SEPARATOR = '..';

    return {
        parseUrl,
        updateUrl,
        clearUrl,
        stringifyFilters,
        parseFiltersString
    };

    /**
     * Parse filters present in the current URL.
     * Calling this function should be wrapped in a try-catch; the url could be malformed and cause errors during parsing.
     * @param {string} paramName
     * @param {Object[]} columns - Columns of the grid setup
     * @returns {Object[]|undefined} - Filters to be applied to the rnDxGrid
     */
    function parseUrl(paramName, columns) {
        const urlParams = $location.search();
        const filtersString = urlParams[paramName];
        if (!filtersString) {
            return;
        }
        const parsedFilters = parseFiltersString(filtersString, columns);
        return getValidFilters(parsedFilters, columns);
    }

    /**
     * Parses a total set filter strings (multiple filter strings joined together).
     * @param {string} filtersString - transactionName~contains~widget__points~eq~500
     * @param {Object[]} columns - Columns of the grid setup
     * @returns {Array}
     */
    function parseFiltersString(filtersString, columns) {
        return filtersString
            .split(FILTER_SEPARATOR)
            .map(filterString => parseSingleFilterString(columns, filterString));
    }

    /**
     * Parses a single filter string.
     * @param {Object[]} columns - Columns of the grid setup
     * @param filterString - Ex. widget__points~eq~500
     * @returns {GridFilter}
     */
    function parseSingleFilterString(columns, filterString) {
        const [field, operator, valueString] = filterString.split(FILTER_PROPERTY_SEPARATOR);
        const column = columns.find(column => column.dataField.toLowerCase() === field.toLowerCase());
        const value = parseFilterValueString(column, operator, valueString);
        return { field, operator, value };
    }

    /**
     * @param {object} column - Corresponding column of the filter
     * @param {string} operator
     * @param {string} valueString
     * @returns {string|number|Array|boolean}
     */
    function parseFilterValueString(column, operator, valueString) {
        if (operator === GridFilterOperators.IN) {
            const array = valueString.split(ARRAY_VALUE_SEPARATOR);
            if (column.type === GridColumnTypes.NUMBER) {
                array.forEach((value, i) => {
                    if (_.isFinite(Number(value))) {
                        array[i] = Number(value);
                    }
                });
            }
            return array;
        } else if (column.type === GridColumnTypes.NUMBER) {
            return Number(valueString);
        } else if (column.type === GridColumnTypes.BOOLEAN) {
            return ['1', 'true'].includes(valueString);
        }
        return valueString;
    }

    /**
     * @param {Object[]} parsedFilters
     * @param {Object[]} columns
     */
    function getValidFilters(parsedFilters, columns) {
        return parsedFilters.filter((filter) => parsedFilterIsValid(filter, columns));
    }

    /**
     * @param {Object} parsedFilter
     * @param {Object[]} columns
     * @returns {boolean}
     */
    function parsedFilterIsValid(parsedFilter, columns) {
        return columns.some(column => column.dataField.toLowerCase() === parsedFilter.field.toLowerCase());
    }

    /**
     * @param {Object[]} columns
     * @param {string} paramName
     * @param {Object[]} [filters]
     */
    function updateUrl(columns, paramName, filters) {
        clearUrl(paramName);
        if (!filters || !filters.length) {
            return;
        }
        const filtersString = stringifyFilters(filters);
        StateUtils.updateUrlParams({
            [paramName]: filtersString
        });
    }

    /**
     * @param {string} paramName
     */
    function clearUrl(paramName) {
        StateUtils.updateUrlParams({ [paramName]: null });
    }

    /**
     * @param {GridFilter[]} filters
     * @returns {string}
     */
    function stringifyFilters(filters) {
        return filters.map(stringifySingleFilter).join(FILTER_SEPARATOR);
    }

    /**
     * @param {GridFilter} filter
     * @returns {string}
     */
    function stringifySingleFilter({ field, operator, value }) {
        let parts;
        if (GridFilterOperators.noValueOperators.includes(operator)) {
            parts = [field, operator];
        } else {
            const valueString = _.isArray(value) ? value.join(ARRAY_VALUE_SEPARATOR) : value;
            parts = [field, operator, valueString];
        }
        return parts.join(FILTER_PROPERTY_SEPARATOR);
    }
}
