import angular from 'angular';

import '../../../core/core.module';

import ProfilePictureService from './profilePictureService.factory';
import contactIcon from './contactIcon.component';
import profilePictureDetails from './profilePictureDetails/profilePictureDetails.component';

export default angular.module('app.contacts.contactIcon', [
    'app.core',
])
.factory('ProfilePictureService', ProfilePictureService)
.component('contactIcon', contactIcon)
.component('profilePictureDetails', profilePictureDetails);
