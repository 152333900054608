

const constantUtils = require('./constantUtils');

const SuggestsTypes = {
    ENTITY: 'Entity',
    ENTITY_ACCESSIBLE: 'EntityAccessible',
    ENTITY_ANY_COLLEAGUE: 'EntityAnyColleague',
    ENTITY_ACCESSIBLE_INCLUDING_DELETED: 'EntityAccessibleIncludingDeleted',
    ENTITY_NOT_DOWNSTREAM: 'EntityNotDownStream',
    ENTITY_ACCESSIBLE_EXCLUDING_ME: 'EntityAccessibleExcludingMe',
    COUNTRY: 'Country',
    INCENTIVES: 'Incentives',
    LIMITERS: 'Limiters',
    METRICS: 'Metrics',
    MY_BUDGETS: 'MyBudgets',
    MY_GROUPS: 'MyGroups',
    PROMO_GROUPS: 'PromoGroups',
    ANY_COLLEAGUE: 'AnyColleague',
    ANYONE: 'Anyone',
    USER_ACCESSIBLE: 'UserAccessible',
    USER_ACCESSIBLE_EXCLUDING_ME: 'UserAccessibleExcludingMe',
    USER_ACCESSIBLE_INCLUDING_DELETED: 'UserAccessibleIncludingDeleted',
    NOT_DOWNSTREAM: 'NotDownStream',

    // Not handled by RN2_Suggests_Main SP yet, are their own separate SPs
    USER_POTENTIAL_MANAGER: ' UserPotentialManager',
    LIMITERS_PRE_LOGIN: 'LimitersPreLogin',
    COMPANY: 'Company',
    CONTENT: 'Content',
    DATAFEED_COLUMN: 'DatafeedColumn',

    // Angular (frontend) only
    NAV: 'Nav'
};

const properties = {};

constantUtils.configure({ constants: SuggestsTypes, properties });

module.exports = SuggestsTypes;
