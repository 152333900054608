import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

ChartWidgetService.$inject = ['$translate', '$filter', 'WidgetVisuals', 'WidgetService', 'Session'];

export default function ChartWidgetService($translate, $filter, WidgetVisuals, WidgetService, Session) {

    // FUNNEL CHART will be defaulted to "Soft Pastel", keep this for reference if ben wants this as default
    // const FUNNEL_CHART_PALETTE = ['#81d16e', '#42c1f2', '#59dbd8', '#f3d942', '#eeba69', '#e9946c', '#c895d6'];
    const company = Session.getCompany();
    let lightSet;
    if (company.defaultColorPalette) {
        lightSet = company.defaultColorPalette.lightSet;
    }

    /**
     * Converts the x-axis values from strings to Date objects
     * @param {Object[]} [dataPoints = []]
     * @param {Object} options - Formatting options
     * @param {string} [options.argumentField = "date"] - Name of the property to look for the date of each dataPoint object
     * @param {string} [options.dateSourceFormat]
     * @returns {Object[]} New dataPoints
     */
    function formatDataPointsDates(dataPoints = [], {
        argumentField = 'date',
        dateSourceFormat
    } = {}) {
        return dataPoints
            .map(dataPoint => Object.assign({}, dataPoint, {
                [argumentField]: dataPoint[argumentField] && formatDateForDevEx(dataPoint[argumentField], dateSourceFormat)
            }));
    }

    /**
     * The date format currently sent from the DB isn't being displayed by dev ex. We use moment
     * to convert the date to a format that is displayed.
     * @param {string} date
     * @param {string} [sourceFormat]
     * @returns {string}
     */
    function formatDateForDevEx(date, sourceFormat) {
        return moment(date, sourceFormat);
    }

    /**
     * @param {int} widgetsAvailableID
     * @returns {string}
     */
    function createChartCssID(widgetsAvailableID) {
        return `widget-dx-chart-${widgetsAvailableID}`;
    }

    /**
     * Assumes that a CSS ID was put on the dx-chart element before calling this function.
     * @see createChartCssID
     * @param {Object} params
     * @param {int} [params.chartCssID] - Need to pass either this or widgetsAvailableID
     * @param {int} [params.widgetsAvailableID] - Need to pass either this or chartCssID
     * @param {string} params.widgetVisualCode
     * @returns {DevExpress.viz.dxChart}
     */
    function getChartInstance({ chartCssID, widgetsAvailableID, widgetVisualCode }) {
        if (!chartCssID) {
            chartCssID = createChartCssID(widgetsAvailableID);
        }
        if ([WidgetVisuals.PIE_CHART, WidgetVisuals.MULTI_PIE_CHART].includes(widgetVisualCode)) {
            return $('#' + chartCssID).dxPieChart('instance');
        } else if ([WidgetVisuals.FUNNEL, WidgetVisuals.MULTI_FUNNEL].includes(widgetVisualCode)) {
            return $('#' + chartCssID).dxFunnel('instance');
        } else if (widgetVisualCode === WidgetVisuals.RANGE_SELECTOR) {
            return $('#' + chartCssID).dxRangeSelector('instance');
        } else {
            return $('#' + chartCssID).dxChart('instance');
        }
    }

    /**
     * @param {string} widgetVisualCode
     * @param {*} widgetData
     * @param {Object} [widgetData.extraJSON]
     * @returns {Object}
     */
    function createChartConfigByVisual(widgetVisualCode, widgetData) {
        const extraJSON = widgetData.extraJSON ? _.clone(widgetData.extraJSON) : {};
        _.defaults(extraJSON, {
            tooltip: {}
        });
        const params = Object.assign({
            isHideLegend: extraJSON.isHideLegend,
            tooltipDisplayType: extraJSON.tooltip.displayType,
            chartOptions: extraJSON.chartOptions
        }, widgetData);
        switch (widgetVisualCode) {
            case WidgetVisuals.LINE_CHART_MONTH:
                return createMonthLineChartConfig(params);
            case WidgetVisuals.LINE_CHART_DATE:
                return createDateLineChartConfig(params);
            case WidgetVisuals.PIE_CHART:
            case WidgetVisuals.MULTI_PIE_CHART:
                return createPieChartConfig(params);
            case WidgetVisuals.BAR_CHART:
                return createBarChartConfig(params);
            case WidgetVisuals.FUNNEL:
            case WidgetVisuals.MULTI_FUNNEL:
                return createFunnelChartConfig(params);
        }
    }

    /**
     * Creates a dx-chart config object for a line chart where the X-axis values are dates (Ex. 2017-09-22).
     * @param {ChartWidgetConfigCreatorParams} params
     * @param {string} [params.argumentField = "date"]
     * @param {string} [params.dateSourceFormat]
     * @param {Object[]} [params.constantLines]
     * @returns {Object}
     */
    function createDateLineChartConfig(params) {
        params = _.merge({
            argumentField: 'date'
        }, params);

        const lineChartConfig = createLineChartConfig(params);
        const dataSource = formatDataPointsDates(lineChartConfig.dataSource, params);

        const formattedConstantLines = lineChartConfig.argumentAxis.constantLines
            .map(constantLine => Object.assign({}, constantLine, {
                value: formatDateForDevEx(constantLine.value, params.dateSourceFormat)
            }));

        return _.merge({}, lineChartConfig, {
            dataSource,
            commonSeriesSettings: {
                type: 'line',
                point: {
                    size: 6
                }
            },
            argumentAxis: {
                argumentType: 'datetime',
                valueMarginsEnabled: false,
                constantLines: formattedConstantLines,
                label: {
                    customizeText: (label) => {
                        if (params.chartOptions && params.chartOptions.argumentAxisDateFormat) {
                            return  moment(label.value).format(params.chartOptions.argumentAxisDateFormat)
                        }
                        return moment(label.value).format("MMMM D, YYYY");
                    }
                }
            },
            commonAxisSettings: {
                grid: {
                    visible: true
                }
            },
            legend: {
                verticalAlignment: 'bottom',
                horizontalAlignment: 'center',
                markerSize: 10,
                itemTextPosition: 'right'
            },
            tooltip: {
                enabled: true,
                customizeTooltip: (arg) => ({
                    text: `${moment(arg.argument).format('MMM DD')}: ${WidgetService.formatTooltipValue({
                        displayType: params.tooltipDisplayType,
                        value: arg.value
                    })}`
                }),
            },
            margin: {
                // Last point gets cut off without this (putting padding on the container of the dx-chart didn't work)
                top: 10,
                right: 15,
                bottom: 10,
                left: 10
            },
        });
    }

    /**
     * Creates a dx-chart config object for a line chart where the X-axis values are month numbers (1-12).
     * @param {ChartWidgetConfigCreatorParams} params
     * @param {string} [params.argumentField = "month"]
     * @param {string} [params.dateSourceFormat]
     * @param {Object[]} [params.constantLines]
     * @return {Object}
     */
    function createMonthLineChartConfig(params) {
        params = _.merge({
            argumentField: 'month',
            dateSourceFormat: 'M'
        }, params);
        const dateLineChartConfig = createDateLineChartConfig(params);
        return _.merge({}, dateLineChartConfig, {
            argumentAxis: {
                type: 'discrete',
                label: {
                    customizeText: (label) => moment(label.value).format('MMM')
                }
            },
            tooltip: {
                customizeTooltip: (arg) => ({
                    text: `${moment(arg.argument).format('MMM')}: ${WidgetService.formatTooltipValue({
                        displayType: params.tooltipDisplayType,
                        value: arg.value
                    })}`
                }),
            },
        });
    }

    /**
     * Creates a dx-chart config object for a line chart (one line per dataSet).
     * @param {ChartWidgetConfigCreatorParams} params
     * @param {Object[]} [params.constantLines]
     * @returns {Object}
     */
    function createLineChartConfig(params) {
        const {
            argumentField,
            constantLines = []
        } = params;
        const chartConfig = createChartConfig(params);
        if (params.chartOptions && params.chartOptions.isBarAndLine) {
            chartConfig.series[0].type = 'bar';
        }

        if (params.chartOptions && params.chartOptions.minBarSize) {
            chartConfig.series.filter(item => item.type === 'bar').forEach(item => {
                item.minBarSize = params.chartOptions.minBarSize;
            });
        }
        return _.merge({}, chartConfig, {
            argumentAxis: {
                constantLines: constantLines.map(constantLine => ({
                    value: constantLine[argumentField],
                    width: 3,
                    color: '#CCCCCC',
                    label: {
                        text: constantLine.header
                    }
                }))
            }
        });
    }

    /**
     * @param {ChartWidgetConfigCreatorParams} params
     * @param {ChartWidgetDataSet[]} params.dataSets
     * @param {string} [params.argumentField = "name"]
     * @param {boolean} [params.isHideLegend = false]
     * @returns {Object} dx-chart config object
     */
    function createPieChartConfig(params) {
        params = _.merge({
            argumentField: 'name',
            isHideLegend: false // For pie charts, by default we want to show the legend even if there's only 1 dataSet
        }, params);
        const chartConfig = createChartConfig(params);
        return _.merge({}, chartConfig, {
            commonSeriesSettings: {
                type: params.chartOptions && params.chartOptions.type || 'pie',
            },
            legend: {
                horizontalAlignment: 'left',
                margin: {
                    top: 10,
                    right: 15,
                    bottom: 0,
                    left: 10,
                }
            },
            tooltip: {
                enabled: true,
                location: 'edge',
                customizeTooltip: (arg) => {
                    const percentText = params.extraJSON.tooltip.isIncludePercent
                        ? `<div style="text-align: center;">${arg.percentText}</div>` : '';
                    return ({
                        html: arg.point.data.tooltip
                            || `${percentText}${arg.point.data.name || arg.seriesName}: ${WidgetService.formatTooltipValue({
                                displayType: params.tooltipDisplayType,
                                value: arg.value
                            })}`
                    });
                }
            },
            margin: {
                top: 10,
                bottom: 15,
                left: 10,
                right: 15
            },
            adaptiveLayout: {
                width: 0,
                height: 0
            },
            innerRadius: 0.7, // Changes the width of the donut, default is 0.5.
        });
    }

    /**
     * @param {ChartWidgetConfigCreatorParams} params
     * @param {string} [params.argumentField = "name"]
     * @returns {Object} dx-chart config object
     */
    function createBarChartConfig(params) {
        params = _.merge({
            argumentField: 'name'
        }, params);

        const chartConfig = createChartConfig(params);
        if (params.chartOptions && params.chartOptions.isBarAndLine) {
            chartConfig.series[1].type = 'line';
        }

        if (params.chartOptions && params.chartOptions.isDateChart) {
            chartConfig.argumentAxis = { type: 'discrete', 
                label: { 
                    wordWrap: "none", 
                    overlappingBehavior: "rotate", 
                    rotationAngle: -90, 
                    customizeText: (label) => {
                        return label.value.substr(0, 3);
                    }
                }
            };
        }

        if (params.chartOptions && params.chartOptions.minBarSize) {
            chartConfig.series.filter(item => item.type !== 'line').forEach(item => {
                item.minBarSize = params.chartOptions.minBarSize;
            });
        }
        return _.merge({}, chartConfig, {
            commonSeriesSettings: {
                type: 'bar',
                hoverMode: 'allArgumentPoints',
                selectionMode: 'allArgumentPoints'
            },
            legend: {
                verticalAlignment: 'bottom',
                horizontalAlignment: 'center'
            },
            tooltip: {
                enabled: true,
                location: 'edge',
                customizeTooltip: (arg) => {
                    if(arg.point.data.tooltip) {
                        return {
                            html: arg.point.data.tooltip
                        };
                    }
                    return {
                        text: `${arg.seriesName}: ${WidgetService.formatTooltipValue({
                            displayType: params.tooltipDisplayType,
                            value: arg.value
                        })}`
                    };
                }
            },
            margin: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            }
        });
    }

    /**
     * Converts a dataSets array to the format that dx-funnel expects.
     * @param {ChartWidgetConfigCreatorParams} params
     * @returns {Object} dx-funnel config object
     */
    function createFunnelChartConfig(params) {
        const funnelConfig = {
            color: '#ff0000',
            dataSource: params.dataSets[0].dataPoints, // use first index because dx-funnel only handles one dataSet
            argumentField: 'name',
            valueField: 'value',
            tooltip: {
                enabled: true,
                location: 'edge',
                customizeTooltip: (arg) => ({
                    html: arg.item.data.tooltip
                            || `<span>${arg.item.argument}: <b>${arg.item.data.value}</b> - ${arg.percentText}</span>`
                })
            },
            margin: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            },
            item: {
                border: {
                    visible: true
                },
            },
            legend: {
                visible: true,
                horizontalAlignment: 'right',
                verticalAlignment: 'top'
            },
            label: {
                visible: true,
                position: 'inside',
                backgroundColor: 'none',
                font: {
                    color: 'black'
                },
                customizeText: function(e) {
                    return `<span style='font-size: 16px'>${e.percentText}</span>`;
                }
            }
        };
        if (lightSet) {
            funnelConfig.palette = lightSet;
        }
        return funnelConfig;
    }

    /**
     * Converts a dataSets array to the format that dx-chart expects.
     * @param {ChartWidgetConfigCreatorParams}
     * @returns {Object} dx-chart config object
     */
    const createChartConfig = ({
        dataSets = [],
        argumentField,
        valueField = 'value',
        isHideLegend = dataSets.length === 1,
        chartOptions = {},
    }) => ({
        dataSource: _(dataSets)
            .map((dataSet, i) => (dataSet.dataPoints || []).map(dataPoint => ({
                arg: dataPoint[argumentField],
                [argumentField]: dataPoint[argumentField],
                ['series' + i]: dataPoint[valueField],
                tooltip: dataPoint.tooltip
            }))
            )
            .flatten()
            .value(),
        series: dataSets
            .map((dataSet, i) => Object.assign({
                valueField: 'series' + i,
                name: dataSet.translateKey ? $translate.instant(dataSet.translateKey) : dataSet.name
            }, seriesTypesToConfigs[dataSet.seriesType] || {})),
        commonSeriesSettings: {
            argumentField,
        },
        legend: {
            visible: !isHideLegend
        }
    });

    // When a dataSet object has a "seriesType" defined, extra configuration is applied to that dataSet's "series"
    // object passed to dx-chart.
    const seriesTypesToConfigs = {
        step: {
            type: 'stepline',
            color: 'orange',
            dashStyle: 'dash',
            point: {
                size: 0
            }
        }
    };

    /**
     * Checks the chart's container element to see if the chart (svg) is currently filling its width and height.
     * If not, will re-render the chart so that devEx can try and fill the container again.
     * @param {Object} params
     * @param {string} params.chartCssID
     * @param {string} params.widgetVisualCode
     */
    function fixChartSize({ chartCssID, widgetVisualCode }) {
        const chartInstance = getChartInstance({ chartCssID, widgetVisualCode });
        if (!chartInstance) {
            console.warn(`Chart instance not found for CSS ID #${chartCssID}`);
            return;
        }
        const chartContainer = chartInstance.element();
        const containerWidth = chartContainer.outerWidth();
        const containerHeight = chartContainer.outerHeight();
        const { width: chartWidth, height: chartHeight } = chartInstance.getSize();
        if (chartWidth !== containerWidth || chartHeight !== containerHeight) {
            chartInstance.render({ animate: true });
        }
    }

    /**
     * @param {Object} widget - vm.widget
     * @returns {boolean} - True if widget "has data" (i.e. some dataSet has non-empty dataPoints)
     */
    function defaultHasDataCheck(widget) {
        const dataSets = _.get(widget, 'data.dataSets');
        if (!_.isArray(dataSets) || _.isEmpty(dataSets)) {
            return false;
        }
        return dataSets.some(dataSet => !_.isEmpty(dataSet.dataPoints));
    }

    const Heights = {
        STATS: '20%',
        SUB_STATS: '10%',
        TITLE: '1em',
        FOOTER: '2em',
        RANGE_SELECTOR: '25%',
        FOOTNOTE: '10%',
    };

    function getChartContainerHeight({
        widgetVisual, hasStats, hasSubStats, hasTitle, hasFooter, hasFootNote, additionalSubtractions, chartConfig
    }) {
        const subtractions = [];
        if (hasStats) {
            subtractions.push(Heights.STATS);
        }
        if (hasSubStats) {
            subtractions.push(Heights.SUB_STATS);
        }
        if (hasTitle) {
            subtractions.push(Heights.TITLE);
        }
        if (hasFooter && ![WidgetVisuals.PIE_CHART, WidgetVisuals.MULTI_PIE_CHART].includes(widgetVisual)) {
            subtractions.push(Heights.FOOTER);
        }
        if (hasFootNote && [WidgetVisuals.FUNNEL, WidgetVisuals.MULTI_FUNNEL].includes(widgetVisual)) {
            subtractions.push(Heights.FOOTNOTE);
        }
        if (chartConfig && chartConfig.hasRangeSelector) {
            subtractions.push(Heights.RANGE_SELECTOR);
        }
        if (additionalSubtractions) {
            subtractions.push(...additionalSubtractions);
        }
        return `calc(100% - ${subtractions.join(' - ')})`;
    }

    return {
        formatDataPointsDates,
        formatDateForDevEx,
        createChartCssID,
        getChartInstance,
        fixChartSize,
        createChartConfigByVisual,
        createLineChartConfig,
        createDateLineChartConfig,
        createMonthLineChartConfig,
        createPieChartConfig,
        createBarChartConfig,
        createFunnelChartConfig,
        createChartConfig,
        defaultHasDataCheck,
        getChartContainerHeight
    };
}

/**
 * Parameters object to pass to a ChartWidgetService function for creating a dx-chart config object.
 * @typedef {Object} ChartWidgetConfigCreatorParams
 * @property {string} argumentField
 * @property {string} [valueField = "value"]
 * @property {ChartWidgetDataSet[]} dataSets
 * @property {boolean} [isHideLegend] - If not specified, legend will be hidden by default if there's only 1 dataSet
 * @property {string} [tooltipDisplayType] - From WidgetDataDisplayTypes. Determines how the tooltip of a dataPoint value will be formatted when displayed.
 */

/**
 * @typedef {Object} ChartWidgetDataSet
 * @property {string} [name]
 * @property {string} [translateKey]
 * @property {Object[]} dataPoints
 * @property {string} [seriesType]
 */
