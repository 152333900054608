import promoTargetsTableTpl from './promoTargetsTable.tpl.html';
import promoTargetsTableCtrl from './promoTargetsTable.controller';

export default {
    templateUrl: promoTargetsTableTpl,
    controller: promoTargetsTableCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<'
    }
};
