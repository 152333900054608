/**
 * Created by Jun on 2/12/2016.
 */

import angular from 'angular';

onCarouselChange.$inject = ['$parse'];

function onCarouselChange($parse) {
    return {
        require: 'uibCarousel',
        link: function(scope, element, attributes, uibCarouselCtrl) {
            const vm = scope;
            const fn = $parse(attributes.onCarouselChange);
            const origSelect = uibCarouselCtrl.select;

            vm.indexOfSlide = function(slide) {
                return angular.isDefined(slide.index) ? +slide.index : uibCarouselCtrl.slides.indexOf(slide);
            };

            uibCarouselCtrl.select = function(nextSlide, direction, nextIndex) {
                fn(scope, {
                    nextSlide: nextSlide,
                    direction: direction,
                    nextIndex: vm.indexOfSlide(nextSlide)
                });
                return origSelect.apply(this, arguments);
            };
        }
    };
}

export default onCarouselChange;
