

const SharedModule = require('./SharedModule');
const FileTypeGroups = require('./FileTypeGroups');

class FileUtils extends SharedModule {

    /**
     * @param {string} path - Ex. cats.jpg or http://images.com/pictures/cats.jpg
     * @returns {boolean}
     */
    isValidVideo(path) {
        return this.isValidExtension(FileTypeGroups.VIDEO, path);
    }

    /**
     * @param {string} path - Ex. cats.jpg or http://images.com/pictures/cats.jpg
     * @returns {boolean}
     */
    isValidImage(path) {
        return this.isValidExtension(FileTypeGroups.PICTURE, path);
    }

    /**
     * Checks if an extension belongs to the FileTypeGroup's list of extensions
     * @param {int} fileTypeGroupID
     * @param {string} path
     * @return {boolean}
     */
    isValidExtension(fileTypeGroupID, path) {
        const fileExtension = FileUtils.getFileExtension(path);
        const validExtensions = this.getFileTypeGroupExtensions(fileTypeGroupID);
        return validExtensions.includes(fileExtension);
    }

    /**
     * @param {int} fileTypeGroupID
     * @return {string[]}
     */
    getFileTypeGroupExtensions(fileTypeGroupID) {
        return this.company.fileTypeGroups
            .find(fileTypeGroup => fileTypeGroup.ID === fileTypeGroupID)
            .fileTypes
            .map(fileType => fileType.extension);
    }

    /**
     * @param {string} path
     * @returns {string}
     */
    static getFileExtension(path) {
        return path.split('.').pop().toLowerCase();
    }
}

module.exports = FileUtils;
