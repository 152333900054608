import registrationFormTpl from './registrationForm.tpl.html';

export default {
    templateUrl: registrationFormTpl,
    controller: 'registrationFormCtrl',
    controllerAs: 'vm',
    bindings: {
        onSuccess: '<',
        permissionGroupOptions: '<'
    }
};
