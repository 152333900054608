import _ from 'lodash';
import moment from 'moment';

AnniversaryService.$inject = ['$http'];

export default function AnniversaryService($http) {

    const getAnniversary = async function() {
        const res = await $http.get('/api/milestones');
        const milestones = res.data;
        if (!_.isEmpty(milestones.birthdays)) {
            milestones.birthdays = setIsToday(milestones.birthdays);
        }
        if (!_.isEmpty(milestones.anniversaries)) {
            milestones.anniversaries = setIsToday(milestones.anniversaries);
        }
        return milestones;
    };

    function setIsToday(milestones) {
        const today = moment();
        return milestones.map(milestone => ({
            ...milestone,
            isToday: today.isSame(milestone.date, 'day')
        }));
    }

    return {
        getAnniversary
    };
}
