import incentiveCardTpl from './incentiveCard.tpl.html';
import incentiveCardCtrl from './incentiveCard.controller';

export default {
    templateUrl: incentiveCardTpl,
    controller: incentiveCardCtrl,
    controllerAs: 'vm',
    bindings: {
        listItem: '<',
        onDeleteSuccess: '<'
    }
};
