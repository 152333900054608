
gamesCtrl.$inject = ['LangUtils', '$stateParams'];

export default function gamesCtrl(LangUtils, $stateParams) {
    this.$onInit = () => {
        const vm = this;
        const languageCode = LangUtils.getLang().toUpperCase();
        if (window.iziigame) {
            window.iziigame.engage(vm.playerToken, document.getElementById('mission-container'), languageCode, $stateParams.loadCode);
        } else {
            console.error('Missing iziigame.js, cannot call engage');
        }
    };
}
