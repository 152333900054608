import _ from 'lodash';
import * as FullStory from '@fullstory/browser';

fullStoryRun.$inject = [
    '$window', '$rootScope', '$localStorage', 'Session', 'LangUtils', 'AuthStatus', 'AUTH_EVENTS', 'SkinUtils'
];

export default function fullStoryRun(
    $window, $rootScope, $localStorage, Session, LangUtils, AuthStatus, AUTH_EVENTS, SkinUtils
) {
    // These CSS classes can be used to hide certain elements from FullStory recording:
    // fs-hide (will hide always)
    // fs-hide-without-consent
    const company = Session.getCompany();

    if (!company.options.FullStory) {
        return;
    }

    if (AuthStatus.isAuthenticated()) {
        init();
    }

    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, init);
    $rootScope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, reset);
    $rootScope.$on(AUTH_EVENTS.LOCK_APP, reset);

    // When logging in as another contact, we want to start a new session so that it's easier to filter.
    // Ex. search for all activity on the "Bobby Smith" account regardless of spectator or direct login.
    $rootScope.$on(AUTH_EVENTS.LOGIN_AS_CONTACT, reset);
    $rootScope.$on(AUTH_EVENTS.LOGOUT_OF_CONTACT, reset);

    function init() {
        FullStory.init({ orgId: 'CE2DQ' });
        identifyUser();
    }

    function identifyUser() {
        const company = Session.getCompany();
        const user = Session.getUser();
        const spectator = Session.getSpectator();
        let uid = `${company.subDomain}-${user.userID}`;
        let displayName = `${user.firstName} ${user.lastName}`;
        if (spectator) {
            uid = `${company.subDomain}-${spectator.userID}-${user.userID}`;
            displayName = `${spectator.firstName} ${spectator.lastName} as ${user.firstName} ${user.lastName}`;
        }
        const limiters = _.range(10)
            .map(i => i + 1)
            .reduce((obj, num) => Object.assign(obj, {
                [`limiter${num}_str`]: user[`limiterValue${num}`]
            }), {});

        FullStory.identify(uid, {
            displayName,
            email: user.email,
            userName_str: user.username,
            permissionRole_str: user.permissionGroupCode,
            skin_str: SkinUtils.getSkinCode(),
            language_str: LangUtils.getLang().toUpperCase(),
            company_str: company.subDomain,
            ...limiters
        });
    }

    function reset() {
        FullStory.identify(false);
    }
}
