import errorModalTpl from './errorModal.tpl.html';

ErrorModal.$inject = ['$uibModal'];

export default function ErrorModal($uibModal) {
    const modalTypes = {
        EXPECTED: 1,
        UNEXPECTED: 2,
        NO_PERMISSION: 3,
        UAL: 4,
    };
    const modalInstances = new Map();

    return {
        open
    };

    function open(error) {
        if (!error || !error.data || !error.data.errorID) {
            return $uibModal.open({
                templateUrl: errorModalTpl,
                size: 'md'
            });
        }
        const errorID = Number(error.data.errorID);
        if (modalInstances.has(errorID)) {
            console.warn(`Modal for error ID ${errorID} already opened, not opening another modal`);
            return modalInstances.get(error);
        }
        const modalInstance = $uibModal.open({
            templateUrl: errorModalTpl,
            size: 'md',
            controller: function() {
                const vm = this;
                vm.error = error;
                if (error.data.returnCode) {
                    if (error.data.typeID === modalTypes.EXPECTED) {
                        vm.errorTypeClass = 'expected';
                    } else if (error.data.typeID === modalTypes.UNEXPECTED) {
                        vm.errorTypeClass = 'unexpected';
                    } else if (error.data.typeID === modalTypes.NO_PERMISSION) {
                        vm.errorTypeClass = 'no-permission';
                    } else if (error.data.typeID === modalTypes.UAL) {
                        vm.errorTypeClass = 'ual';
                    } else {
                        vm.errorTypeClass = 'unknown-error';
                    }
                } else {
                    vm.errorTypeClass = 'system-error';
                }
            },
            controllerAs: 'vm'
        });
        modalInstances.set(errorID, modalInstance);
        return modalInstance;
    }
}
