import angular from 'angular';
import moment from 'moment';

redemptionDetailsCtrl.$inject = ['$translate', '$rootScope', 'RedemptionService', 'GeoService', 'Session'];

export default function redemptionDetailsCtrl($translate, $rootScope, RedemptionService, GeoService, Session) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.redemption
        // vm.showAddress

        vm.user = Session.getUser();

        // Weird workaround - Need to deep copy otherwise if parent controller has the redemption object saved to a variable, its notes can get modified by this controller
        vm.redemption = angular.copy(vm.redemption);

        vm.redemption.notes = vm.redemption.notes.map(formatNote);

        vm.noteModel = {};
        vm.addressModel = {};
        vm.disableButtons = false;
        vm.showNewNoteForm = false;

        if (vm.showAddress) {
            showUpdateAddress();
        } else {
            cancelUpdateAddress();
        }

        vm.noteFields = [{
            key: 'newNote',
            type: 'customTextarea',
            templateOptions: {
                label: '',
                placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADD_NOTE_PLACEHOLDER', // Type a note here.
                description: '',
                controlClass: 'new-note-textarea'
            }
        }];

        vm.changeAddressFields = [
            {
                key: 'companyName',
                type: 'customInput',
                defaultValue: vm.redemption.companyName,
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_COMPANY_LABEL', // Company Name
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_COMPANY_PLACEHOLDER', // Company Name (optional)
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'address1',
                type: 'customInput',
                defaultValue: vm.redemption.address1,
                templateOptions: {
                    required: true,
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_ADDRESS_1_LABEL', // Address 1
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_ADDRESS_1_PLACEHOLDER', // Address 1
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'address2',
                type: 'customInput',
                defaultValue: vm.redemption.address2,
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_ADDRESS_2_LABEL', // Address 2
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_ADDRESS_2_PLACEHOLDER', // Address 2
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'city',
                type: 'customInput',
                defaultValue: vm.redemption.city,
                templateOptions: {
                    required: true,
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_CITY_LABEL', // City
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_CITY_PLACEHOLDER', // City
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'province',
                type: 'customSelect',
                defaultValue: vm.redemption.state,
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_PROVINCE_LABEL', // Province/State
                    required: true,
                    options: [], // options populated by country dropdown
                    ngChange: 'stateUpdated(model[options.key])',
                    valueProp: 'code'
                },
                hide: true,
                controller: ['$scope', function($scope) {
                    // update text value
                    $scope.stateUpdated = function(stateCode) {
                        $scope.model.provincetextvalue = stateCode;
                    };
                }]
            }, {
                key: 'provincetextvalue',
                type: 'customInput',
                defaultValue: vm.redemption.state,
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_OPTIONAL_PROVINCE_LABEL' // Province/State (optional)
                },
                hide: true
            }, {
                key: 'postalcode',
                type: 'customInput',
                defaultValue: vm.redemption.zip,
                templateOptions: {
                    required: true,
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_POSTAL_CODE_LABEL', // Postal/Zip Code
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_POSTAL_CODE_PLACEHOLDER', // Postal/Zip Code
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'country',
                type: 'customSelect',
                defaultValue: vm.redemption.country,
                templateOptions: {
                    required: true,
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_COUNTRY_LABEL', // Country
                    options: [],
                    ngChange: 'changeCountry(model[options.key], true)',
                    valueProp: 'code'
                },
                controller: ['$scope', function($scope) {

                    // load list of provinces/states
                    $scope.changeCountry = function(countryCode, isResetProvinceInput) {
                        return GeoService.getProvinceList(countryCode)
                            .then(function(provinceList) {
                                const provinceDropdownField = $scope.fields.find(field => field.key === 'province');
                                const provinceTextField = $scope.fields.find(field => field.key === 'provincetextvalue');

                                if (isResetProvinceInput) {
                                    $scope.model.province = null;
                                    $scope.model.provincetextvalue = null;
                                }

                                if (provinceList && provinceList.length > 0) {
                                    provinceDropdownField.templateOptions.options = provinceList;
                                    provinceDropdownField.hide = false;
                                    provinceTextField.hide = true;
                                } else {
                                    provinceDropdownField.hide = true;
                                    provinceTextField.hide = false;
                                }
                            });
                    };

                    // load list of countries
                    GeoService.getCountryList()
                        .then(function(countryList) {
                            $scope.to.options = countryList;
                            if ($scope.model.country) {
                                $scope.changeCountry($scope.model.country, false);
                            }
                        });
                }]
            }, {
                key: 'phone',
                type: 'customInput',
                defaultValue: vm.redemption.phone,
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_PHONE_LABEL', // Phone
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_PHONE_PLACEHOLDER', // Phone
                    description: ''
                },
                hideExpression: '!model.country'
            }, {
                key: 'note',
                type: 'customTextarea',
                templateOptions: {
                    labelTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_NOTE_LABEL', // Note
                    placeholderTranslateKey: 'app_REDEMPTION_DETAILS_ADDRESS_FORM_NOTE_PLACEHOLDER', // Add Note here
                    description: 'new-note-textarea'
                },
                hideExpression: '!model.country'
            }
        ];

        vm.submitNewNote = function() {
            vm.noteModel.newNote.replace('\'', '\'\'');

            // TODO: determine public vs private note based on user
            const isPublic = 1;

            // statusid based on previous status
            const statusID = vm.redemption.statusID;
            const status = vm.redemption.status;

            return RedemptionService.addNote(
                vm.redemption.redemptionID,
                vm.noteModel.newNote,
                isPublic,
                statusID
            )
            .then(function(res) {
                const results = res.results[0];

                vm.redemption.notes.unshift(formatNote({
                    title: status,
                    author: vm.user.firstName,
                    date: moment(),
                    text: vm.noteModel.newNote,
                    type: isPublic === 1 ? 'public' : 'private'
                }));

                vm.cancelNewNote();
                vm.disableButtons = false;

                // toggle isAttention bit and update message, if necessary
                if (results.isAttention !== vm.redemption.isAttention) {
                    vm.redemption.isAttention = Boolean(results.isAttention);
                }
            });
        };

        vm.submitUpdateAddress = function() {
            const statusID = vm.redemption.statusID;
            const isPublic = 1;
            const status = vm.redemption.status;

            return RedemptionService.updateAddress({
                redemptionID: vm.redemption.redemptionID,
                companyName: vm.addressModel.companyName,
                address1: vm.addressModel.address1,
                address2: vm.addressModel.address2,
                city: vm.addressModel.city,
                province: vm.addressModel.provincetextvalue,
                country: vm.addressModel.country,
                postalcode: vm.addressModel.postalcode,
                phone: vm.addressModel.phone,
                statusID,
                isPublic,
                note: vm.addressModel.note
            })
            .then(function(result) {
                vm.redemption.notes.unshift(formatNote({
                    title: status,
                    author: vm.user.firstName,
                    date: moment(),
                    text: generatePostUpdateAddressNote(vm.addressModel),
                    type: isPublic === 1 ? 'public' : 'private'
                }));

                vm.cancelUpdateAddress();
                vm.disableButtons = false;

                // toggle isAttention bit and update message, if necessary
                if (result.isAttention !== vm.redemption.isAttention) {
                    vm.redemption.isAttention = Boolean(result.isAttention);
                }

                vm.redemption.statusID = result.statusID;
                vm.redemption.status = result.statusPhrase;

                if (vm.onAddressUpdateSuccess) {
                    return vm.onAddressUpdateSuccess(); // TODO: consider passing in the new address and/or res.data
                }
            })
            .catch(function(err) {
                if (vm.onAddressUpdateFail) {
                    return vm.onAddressUpdateFail(err);
                } else {
                    throw err;
                }
            });
        };

        vm.updateIsAttention = function(isAttention) {
            return RedemptionService.updateIsAttention(vm.redemption.redemptionID, isAttention)
                .then(function() {
                    vm.redemption.isAttention = isAttention;
                });
        };

        vm.showAddNote = function() {
            vm.disableButtons = true;
            vm.showNewNoteForm = true;
        };

        vm.cancelNewNote = function() {
            // TODO: reset new note form's text
            vm.showNewNoteForm = false;
            vm.disableButtons = false;
        };


        vm.showVendorInfo = function() {
            vm.disableButtons = true;
            vm.showVendorInfoForm = true;
        };

        vm.hideVendorInfo = function() {
            vm.disableButtons = false;
            vm.showVendorInfoForm = false;
        };

        vm.showUpdateAddress = showUpdateAddress;

        vm.cancelUpdateAddress = cancelUpdateAddress;

        function showUpdateAddress() {
            vm.showAddress = true;
            vm.disableButtons = true;
        }

        function cancelUpdateAddress() {
            vm.showAddress = false;
            vm.disableButtons = false;
        }

        function generatePostUpdateAddressNote(addressModel) {
            const { phone, companyName, address1, address2, city, provincetextvalue, postalcode, country, note } = addressModel;
            const updatedAddressPhrase = $translate.instant('app_REDEMPTION_DETAILS_UPDATED_ADDRESS'); // Updated address:

            return `
                ${updatedAddressPhrase} <br />
                ${phone ? phone + `<br />` : ''}
                ${companyName ? companyName + `<br />` : ''}
                ${address1 + `<br />`}
                ${address2 ? address2 + `<br />` : ''}
                ${city + (provincetextvalue ? ', ' + provincetextvalue : '') + `<br />`}
                ${postalcode + `<br />`}
                ${country + `<br />`}
                ${note || ''}
            `;
        }

        function formatNote(note) {
            return {
                title: formatTitle(note.title, note.type),
                author: note.author,
                date: note.date,
                text: note.text,
                type: note.type,
                serialNumber: formatSerialNumber(note.serialNumber, note.serialType),
                serialType: note.serialType,
                trackingReference: formatTrackingReference(note.trackingReference, note.trackingType),
                trackingType: note.trackingType,
                orderNumber: formatOrderNumber(note.orderNumber, note.vendorID),
                cardID: formatCardID(note.cardID, note.vendorID)
            };
        }

        function formatTitle(title, type) {
            if (type === 'public') {
                return $translate.instant('app_REDEMPTION_DETAILS_NOTE_TITLE_PUBLIC', { title }); //  'Status: {{ title}}'
            } else {
                return $translate.instant('app_REDEMPTION_DETAILS_NOTE_TITLE_PRIVATE', { title }); // 'Private Note: {{ title }}'
            }
        }

        function formatSerialNumber(serialNumber, serialType) {
            if (serialNumber) {
                return $translate.instant('app_REDEMPTION_DETAILS_NOTE_SERIAL_NUMBER', { serialNumber, serialType }); // 'Serial #('{{ serialType }}'): {{ serialNumber }}'
            }
        }

        function formatTrackingReference(trackingReference, trackingType) {
            if (trackingReference) {
                return $translate.instant('app_REDEMPTION_DETAILS_NOTE_TRACKING_REFERENCE', { trackingReference, trackingType }); // 'Tracking Reference ('{{ trackingType }}'): {{ trackingReference }}'
            }
        }

        function formatOrderNumber(orderNumber) {
            if (orderNumber) {
                return $translate.instant('app_REDEMPTION_DETAILS_NOTE_ORDER_NUMBER', { orderNumber }); // 'Order Number: {{ orderNumber }}'
            }
        }

        function formatCardID(cardID, vendorID) {
            if (cardID) {
                if (vendorID === 10) {
                    return $translate.instant('app_REDEMPTION_DETAILS_NOTE_CARD_ID_PID', { cardID }); // 'pid {{ cardID }}'
                } else if (vendorID === 35) {
                    return $translate.instant('app_REDEMPTION_DETAILS_NOTE_CARD_ID_LOAD_INFO', { cardID }); // 'Card/Load Info ID: {{ cardID }}'
                } else {
                    return $translate.instant('app_REDEMPTION_DETAILS_NOTE_CARD_ID', { cardID }); // 'Card ID: {{ cardID }}'
                }
            }
        }
    };
}

/*
    RedemptionStatus IDs
    1    Processing
    2   Ordered
    3   Cancelled
    4   Shipped
    5   Pending Shipping Confirmation
    6   Returned
    7   No Longer Available
    8   Pending Address Confirmation
    9   Pending Substitution
    10  Pending More Information
    11  Pending Order
 */
