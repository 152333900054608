import angular from 'angular';

import 'angular-formly';

import customFileUploadRun from './customFileUpload.run';
import submittedFilesList from './submittedFilesList/submittedFilesList.component';

export default angular.module('common.formly.customFileUpload', [
    'formly'
])
.run(customFileUploadRun)
.directive('submittedFilesList', submittedFilesList);
