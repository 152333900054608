import angular from 'angular';

import '../../../core/core.module';
import '../../communication/communication.module';
import '../socialPostPreview/socialPostPreview.module';


import socialPostFormCtrl from './socialPostForm.controller';
import SocialPostService from '../socialPostService.factory';
import socialPostForm from './socialPostForm.component';


export default angular.module('app.socialPost.socialPostForm', [
    'app.core',
    'app.communication',
    'app.socialPost.socialPostPreview'
])
.controller('socialPostFormCtrl', socialPostFormCtrl)
.factory('SocialPostService', SocialPostService)
.component('socialPostForm', socialPostForm);
