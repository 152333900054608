import angular from 'angular';

import '../../../../../core/core.module';

import resetPassword from './resetPassword.component';

export default angular.module('app.contacts.contactPanel.contactPanelTools.resetPassword', [
    'app.core'
])
.component('resetPassword', resetPassword);
