loginAsPageCtrl.$inject = ['$state', 'LoginAsService', 'Session', 'StateUtils'];

export default function loginAsPageCtrl($state, LoginAsService, Session, StateUtils) {
    const vm = this;

    const contactID = Number($state.params.contactID);
    if (!isFinite(contactID)) {
        vm.error = true;
        return;
    }

    const spectator = Session.getSpectator();
    const user = Session.getUser();
    if (spectator && user.userID === contactID) {
        // After going to this page for the first time, the page will refresh after LoginAsService.loginAsContact() succeeds.
        // So when refresh is done, this same state and this same controller will run again.
        // Use this to go to the default state of the new user.
        const defaultState = StateUtils.getDefaultState();
        return $state.go(defaultState.name, defaultState.params);
    }

    LoginAsService.loginAsContact({ contactID });
}
