import forumPageTpl from './forumPage.tpl.html';

forumPageConfig.$inject = ['$stateProvider'];

export default function forumPageConfig($stateProvider) {
    $stateProvider.state('forumPage', {
        parent: 'auth',
        url: '/forum',
        views: {
            'main@': {
                controller: 'forumPageCtrl as vm',
                templateUrl: forumPageTpl
            }
        },
        data: {
            pageTitle: 'forum',
            permission: 'ForumMember',
            options: ['ForumModule']
        }
    });
}
