MilestonesModal.$inject = ['$uibModal', 'MilestoneTypes'];

export default function MilestonesModal($uibModal, MilestoneTypes) {

    function openBirthdays(dates) {
        return open({ type: MilestoneTypes.BIRTHDAY, dates });
    }

    function openAnniversaries(dates) {
        return open({ type: MilestoneTypes.ANNIVERSARY, dates });
    }

    function open({ type, dates }) {
        return $uibModal.open({
            template: `
                <div class="milestones-modal">
                    <milestones type="${type}" dates="vm.dates"></milestones>
                </div>`,
            controller: [function() {
                const vm = this;
                vm.dates = dates;
            }],
            controllerAs: 'vm',
        });
    }

    return {
        openBirthdays,
        openAnniversaries,
    };
}
