contactIconCtrl.$inject = ['$scope', '$uibModal', 'Session', 'ProfilePictureService'];

export default function contactIconCtrl($scope, $uibModal, Session, ProfilePictureService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.contact
        // vm.loadingWidth
        // vm.loadingHeight
        // vm.isEditable
        // vm.isExpandable

        vm.user = Session.getUser();

        vm.contactID = vm.contact.userID || vm.contact.ID || vm.contact.contactID;
        vm.fullName = getFullName(vm.contact);
        vm.initials = getContactInitials(vm.fullName);

        vm.pictureUrl = ProfilePictureService.determinePictureUrl(vm.contactID);

        if (vm.pictureUrl) {
            vm.showPicture = true;
            vm.showFallbackIcon = false;
        } else {
            // Means company has no ProfilePics cloud container or picture was recently deleted on current browser.
            vm.showPicture = false;
            vm.showFallbackIcon = true;
            vm.isEditable = false;
        }

        if (vm.isEditable) {
            vm.isExpandable = true;
        }

        vm.onPictureLoad = function(event, imgElement) {
            vm.hasPicture = true;
            vm.showPicture = true;
            vm.showFallbackIcon = false;
            vm.canOpenDetails = vm.isExpandable;
            // Instead of using width and height attributes, use width and height of container to create centered circle
            imgElement.removeAttr('width');
            imgElement.removeAttr('height');
        };

        vm.onPictureError = function() {
            vm.hasPicture = false;
            vm.showPicture = false;
            vm.showFallbackIcon = true;
            vm.canOpenDetails = vm.isEditable;
        };

        vm.openDetailsModal = function() {
            if (!vm.canOpenDetails) {
                return;
            }
            return $uibModal.open({
                size: 'md',
                scope: $scope,
                template: `
                    <div class="profile-picture-details-modal">
                        <profile-picture-details contact-id="vm.contactID"
                                                has-picture="vm.hasPicture" 
                                                picture-url="{{ vm.pictureUrl }}" 
                                                is-editable="vm.isEditable" 
                                                on-update-success="vm.onUpdateSuccess"
                                                on-delete-success="vm.onDeleteSuccess">
                        </profile-picture-details>
                    </div>`
            });
        };

        vm.onUpdateSuccess = function() {
            vm.pictureUrl = ProfilePictureService.determinePictureUrl(vm.contactID);
        };

        vm.onDeleteSuccess = function() {
            vm.onPictureError(); // Do same things as when picture is unavailable
        };

        $scope.$on(ProfilePictureService.Events.USER_PROFILE_PICTURE_UPDATED, function() {
            if (vm.contactID === vm.user.userID) {
                vm.onUpdateSuccess();
            }
        });

        $scope.$on(ProfilePictureService.Events.USER_PROFILE_PICTURE_DELETED, function() {
            if (vm.contactID === vm.user.userID) {
                vm.onDeleteSuccess();
            }
        });

        function getFullName(contact) {
            let fullName = contact.name || contact.fullName;
            if (!fullName) {
                const firstName = contact.firstName || contact.FName;
                const lastName = contact.lastName || contact.LName;
                fullName = firstName + (lastName ? ' ' + lastName : '');
            }
            return fullName;
        }

        function getContactInitials(fullName) {
            const initials = fullName.split(' ').map(name => name.charAt(0));
            const firstInitial = initials[0];
            const lastInitial = initials.length > 1 ? initials[initials.length - 1] : '';
            return firstInitial + lastInitial;
        }
    };
}
