import remindCompletedModalTpl from './remindCompletedModal.tpl.html';
import askContinueOrRestartModalTpl from './askContinueOrRestartModal.tpl.html';
import trainingQuizModalTpl from './trainingQuizModal.tpl.html';
import trainingQuizModalCtrl from './trainingQuizModal.controller';

TrainingQuizModal.$inject = ['$uibModal'];

export default function TrainingQuizModal($uibModal) {

    /**
     * For when a user has completed a training quiz already.
     * @returns {Promise}
     *      Resolves to { isContinue: true } if user still wants to do the quiz again.
     *      Resolves to { isContinue: false } if user wants to cancel.
     */
    function remindCompleted() {
        return $uibModal.open({
            size: 'md',
            templateUrl: remindCompletedModalTpl,
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('promo');
                }]
            },
        })
        .result
        .then(() => ({ isContinue: true }))
        .catch(() => ({ isContinue: false }));
    }

    /**
     * For when a user has a previously started but incomplete quiz submission.
     * @returns {Promise.<{ isContinue: boolean }>}
     *      Resolves to { isContinue: true } if user wants to continue the previously started quiz.
     *      Resolves to { isContinue: false } if they want to discard and restart.
     */
    function askContinueOrRestart() {
        return $uibModal.open({
            size: 'md',
            templateUrl: askContinueOrRestartModalTpl,
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('promo');
                }]
            },
        })
        .result;
    }

    /**
     * Open the modal to start doing a training quiz's questions.
     * To view the quiz results, use Communication.openIncentive() instead.
     * @param {Object} params
     * @param {Object} params.promoDetails
     * @param {int} params.quizResultID
     * @param {int} [params.initialQuizQuestionID]
     * @param {number} [params.currentScore]
     */
    function openQuestions({ promoDetails, quizResultID, initialQuizQuestionID, currentScore }) {
        const firstQuestionID = promoDetails.quiz.sections[0].questions[0].ID;
        initialQuizQuestionID = initialQuizQuestionID || firstQuestionID;
        currentScore = currentScore || 0;

        return $uibModal.open({
            size: 'lg',
            templateUrl: trainingQuizModalTpl,
            controller: trainingQuizModalCtrl,
            controllerAs: 'vm',
            resolve: {
                promoDetails: () => promoDetails,
                quizResultID: () => quizResultID,
                initialQuizQuestionID: () => initialQuizQuestionID,
                currentScore: () => currentScore,
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('app');
                }]
            },
        });

        // TODO: return a Promise describing what happened?
        // 3 possibilities afterwards:
        // - closed modal, quiz incomplete
        // - closed modal, quiz completed, passed
        // - closed modal, quiz completed, failed
        // { isComplete, isPass }
    }

    return {
        remindCompleted,
        askContinueOrRestart,
        openQuestions,
    };
}
