PageTitle.$inject = ['$rootScope', 'Session'];

export default function PageTitle($rootScope, Session) {
    const company = Session.getCompany();
    const companyName = company.name || 'RewardsNation';

    set(companyName, true);

    return {
        get,
        set
    };

    function get() {
        return $rootScope.pageTitle;
    }

    function set(newPageTitle, noSuffix) {
        if (noSuffix) {
            return $rootScope.pageTitle = newPageTitle;
        }
        return $rootScope.pageTitle = `${newPageTitle} | ${companyName}`;
    }
}
