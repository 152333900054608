snapshotNavigateCtrl.$inject = ['StateUtils', '$state'];

export default function snapshotNavigateCtrl(StateUtils, $state) {
    this.$onInit = () => {
        const vm = this;
        // Paging
        // -----------------------------------------------------------------------------------------------------------------
        let initialPage;
        if ($state.params.page) {
            initialPage = findWidgetPageByCode($state.params.page) || getDefaultWidgetPage();
        } else {
            initialPage = getDefaultWidgetPage();
        }

        // selects initial page on state load success
        selectPage(initialPage);

        // function bindings to VM
        // -----------------------------------------------------------------------------------------------------------------
        vm.selectPage = selectPage;

        // function definitions
        // -----------------------------------------------------------------------------------------------------------------
        function selectPage(page) {
            // clicking on active tab should return without action
            if (page.index === vm.activePageIndex) {
                return;
            }

            // vm.activePage = page;
            vm.activePageIndex = page.index;

            vm.updateActiveSet(page);

            if (vm.widgetPages.length >= 2) {
                // No need to update window URL with page code if there's only 1 page
                StateUtils.updateUrlParams({ page: formatPageCode(page.code) });
            }
        }

        function formatPageCode(pageCode) {
            return pageCode.toLowerCase();
        }

        function findWidgetPageByCode(pageCode) {
            pageCode = formatPageCode(pageCode);
            return vm.widgetPages.find(page => formatPageCode(page.code) === pageCode);
        }

        function getDefaultWidgetPage() {
            return vm.widgetPages[0];
        }
    };
}
