import incentiveLibraryTpl from './incentiveLibrary.tpl.html';
import incentiveLibraryListTpl from './incentiveLibraryList/incentiveLibraryList.tpl.html';


incentiveLibraryConfig.$inject = ['$stateProvider'];

export default function incentiveLibraryConfig($stateProvider) {
    $stateProvider.state('incentiveLibrary', {
        parent: 'auth',
        url: '/incentive-library',
        views: {
            'main@': {
                controller: 'incentiveLibraryCtrl as vm',
                templateUrl: incentiveLibraryTpl
            }
        },
        data: {
            pageTitle: 'IncentiveLibrary',
            permission: 'ManagePromos',
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promolib');
            }]
        }
    });

    $stateProvider.state('incentiveLibraryList', {
        parent: 'incentiveLibrary',
        url: '/:promoLibraryTypeCode?promoLibraryID',
        views: {
            'main.incentiveLibraryList@': {
                controller: 'incentiveLibraryListCtrl as vm',
                templateUrl: incentiveLibraryListTpl
            }
        },
        data: {
            pageTitle: 'incentiveLibraryList',
            permission: 'ManagePromos',
        },
        resolve: {
            libraryPromoList: ['$stateParams', 'incentiveLibraryService', function ($stateParams, incentiveLibraryService) {
                const promoLibraryTypeCode = $stateParams.promoLibraryTypeCode;
                return incentiveLibraryService.getIncentiveLibraryList({
                    promoLibraryTypeCode
                });

            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promolib');
            }]
        }
    });

}
