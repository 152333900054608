import _ from 'lodash';

const ClickTypes = {
    MESSAGE_RECIPIENT: 'MESSAGE_RECIPIENT'
};

slideshowWidgetCtrl.$inject = ['$scope', '$timeout', '$element', 'MessageService'];

export default function slideshowWidgetCtrl($scope, $timeout, $element, MessageService) {
    this.$onInit = () => {
        const vm = this;

        vm.slides = vm.widget.data.slides;
        vm.hasData = !_.isEmpty(vm.slides);

        if (!vm.hasData) {
            return;
        }

        $timeout(initializeSlick);

        vm.onSlideClick = async (slide) => {
            switch (slide.clickType) {
                case ClickTypes.MESSAGE_RECIPIENT: {
                    if (!slide.messageRecipientID) {
                        console.warn('No messageRecipientID');
                        return;
                    }
                    return MessageService.openModal({ messageRecipientID: slide.messageRecipientID });
                }
            }
        };

        async function initializeSlick() {
            const $slideshowContainer = $element.find('.slideshow-container');
            $slideshowContainer.slick({
                autoplay: true,
                autoplaySpeed: 5000,
                dots: false,
                draggable: false, // Otherwise ng-click triggers on drag
                // TODO: translations for previous arrow, next arrow, dots
            });
            $scope.$on('$destroy', () => {
                $slideshowContainer.slick('unslick');
            });
        }
    };
}
