stateConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

export default function stateConfig($stateProvider, $urlRouterProvider) {

    $stateProvider.decorator('parent', function (internalStateObj, parentFn) {
        // This decorator is to allow our code to access the "internal state object" that ui-router creates.
        // Make sure that this function runs BEFORE all of the .configs in the features folder.
        // The feature configs all use $stateProvider, so this decorator needs to be ran beforehand.
        // This means you must register this config file in a core module that the main 'app' module depends on.
        // https://stackoverflow.com/questions/29892353/angular-ui-router-resolve-state-from-url/30926025#30926025
        internalStateObj.self.$$state = () => internalStateObj;
        return parentFn(internalStateObj);
    });

    $urlRouterProvider.when(/^\/?$/, ['$state', 'AuthStatus', 'StateUtils', function defaultRedirect(
        $state, AuthStatus, StateUtils
    ) {
        if (AuthStatus.isAuthenticated()) {
            const defaultState = StateUtils.getDefaultState();
            $state.go(defaultState.name, defaultState.params);
        } else {
            $state.go('login');
        }
    }]);

    $urlRouterProvider.otherwise(function stateNotFound($injector, $location) {
        console.log(`Redirecting - State not found for path ${$location.path()}`);
        const $state = $injector.get('$state');
        $state.go('content', { code: 404 });
    });
}
