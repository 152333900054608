import _ from 'lodash';

SubmissionsGridService.$inject = ['GridColumnTypes', 'GridColumnFilterTypes', 'QuizResultStatuses'];

export default function SubmissionsGridService(GridColumnTypes, GridColumnFilterTypes, QuizResultStatuses) {

    const quizResultStatusColumn = {
        dataField: 'statusID',
        nonDevExOptions: {
            type: GridColumnTypes.NUMBER,
            filterType: GridColumnFilterTypes.CHECKBOX,
            captionTranslateKey: 'submissions_STATUS_COLUMN_HEADING',
            showValueOf: 'status',
            listOptions: getQuizResultStatusListOptions(),
        }
    };

    const isSnoozeColumn = {
        dataField: 'isSnooze',
        visible: false,
        nonDevExOptions: {
            type: GridColumnTypes.BOOLEAN,
            filterType: GridColumnFilterTypes.BOOLEAN,
            captionTranslateKey: 'submissions_IS_SNOOZE_COLUMN_HEADING'
        }
    };

    return {
        commonColumns: {
            quizResultStatusColumn,
            isSnoozeColumn
        }
    };

    function getQuizResultStatusListOptions() {
        return _(QuizResultStatuses.properties)
            .omit([QuizResultStatuses.WINNER_PENDING_APPROVAL, QuizResultStatuses.WINNER_APPROVAL_DENIED]) // Not yet in RN2
            .toArray()
            .map(quizResultStatus => ({
                translateKey: quizResultStatus.translateKey,
                value: quizResultStatus.ID
            }))
            .value();
    }
}
