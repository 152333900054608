promoQuizCloseCtrl.$inject = ['PromoQuizService'];

export default function promoQuizCloseCtrl(PromoQuizService) {
    this.$onInit = () => {
        const vm = this;

        // TODO: find way to un-duplicate the code below with promoQuizSection.controller.js

        const sectionID = vm.section.sectionID;
        const quizResultID = vm.promoDetails.results.quizResultID;

        vm.sectionResults = PromoQuizService.getSectionResults(vm.promoDetails, vm.section);

        vm.noteModel = {};

        vm.noteFields = [{
            key: 'note',
            type: 'customTextarea',
            templateOptions: {
                maxlength: 500,
                placeholderTranslateKey: 'app_PROMO_QUIZ_SECTION_CLOSE_ADD_NOTE_PLACEHOLDER', // 'Optional: add a note regarding this submission closing'
                controlClass: 'promo-submission-note-field',
                resetAfterSubmit: true
            }
        }];

        vm.submit = function() {
            return PromoQuizService.closeSubmission({
                sectionID,
                quizResultID,
                note: vm.noteModel.note || null
            });
        };
    };
}
