noAccessCtrl.$inject = [];

export default function noAccessCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.toState

        const { permission, options } = vm.toState.data;

        vm.permission = permission;
        vm.options = options || [];
    };
}
