import _ from 'lodash';

formatIfNumber.$inject = ['$filter'];

export default function formatIfNumber($filter) {
    return function(value, fractionSize) {
        if (!_.isFinite(Number(value))) {
            return value;
        }
        return $filter('number')(value, fractionSize);
    };
}
