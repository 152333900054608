import angular from 'angular';

import '../../../core/core.module';
import './incentiveCard/incentiveCard.module';

import incentiveLibraryListCtrl from './incentiveLibraryList.controller';

export default angular.module('app.incentiveLibrary.incentiveLibraryList', [
    'app.core',
    'app.incentiveLibraryList.incentiveCard'
])
 .controller('incentiveLibraryListCtrl', incentiveLibraryListCtrl);
