import _ from 'lodash';

widgetHeaderCtrl.$inject = [];

export default function widgetHeaderCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.text
        // vm.helpText

        if (vm.widget) {
            _.defaults(vm, {
                text: vm.widget.headerText,
                helpText: vm.widget.helpText
            });
        }
    };
}
