const applauseMultiCheckboxWrapperTpl = require('./applauseMultiCheckboxWrapper.tpl.html');
const applauseMultiCheckboxTpl = require('./applauseMultiCheckbox.tpl.html');

applauseMultiCheckboxRun.$inject = ['$translate', 'formlyConfig'];

export default function applauseMultiCheckboxRun($translate, formlyConfig) {
    formlyConfig.setWrapper({
        name: 'applauseMultiCheckboxWrapper',
        templateUrl: applauseMultiCheckboxWrapperTpl
    });

    formlyConfig.setType({
        name: 'applauseMultiCheckbox',
        extends: 'customMultiCheckbox',
        templateUrl: applauseMultiCheckboxTpl,
        wrapper: ['applauseMultiCheckboxWrapper', 'applauseSectionBorder', 'validation'],
        defaultOptions: {
            templateOptions: {
                maxNumOfStr: 3
            },
            validation: {
                messages: {
                    maxNumOfStrengths: function() {
                        return $translate.instant('applauseForm_SELECT_STRENGTHS_VALIDATE_MAX_STRENGTHS'); // Choose at most three strengths
                    }
                }
            },
            data: {
                overflowHidden: true
            }
        }
    });
}
