ChooseRecognitionModal.$inject = ['$uibModal'];

export default function ChooseRecognitionModal($uibModal) {

    function open() {
        return $uibModal.open({
            template: `<choose-recognition></choose-recognition>`,
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('spotlight');
                }]
            }
        });
    }

    return {
        open
    };
}
