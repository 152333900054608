import _ from 'lodash';

httpConfig.$inject = ['$httpProvider'];

export default function httpConfig($httpProvider) {
    _.merge($httpProvider.defaults.headers, {
        get: {
            // Disable IE ajax request caching.
            // http://stackoverflow.com/questions/16098430/angular-ie-caching-issue-for-http
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        },
        delete: {
            // Allow request bodies with $http.delete().
            // https://stackoverflow.com/questions/37796227/body-is-empty-when-parsing-delete-request-with-express
            'Content-Type': 'application/json;charset=utf-8'
        }
    });

    $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike';
}
