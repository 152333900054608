import _ from 'lodash';
import moment from 'moment';

// Max number of comments to be returned when "View more comments" is clicked
const COMMENT_LIMIT = 10;

commentSectionCtrl.$inject = ['SpotlightService', 'Prompt', 'Session'];

export default function commentSectionCtrl(SpotlightService, Prompt, Session) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            comments: [],
            totalComments: 0,
            itemIDs: {},
            onViewMore: _.noop,
            onCommentAdded: _.noop,
            onCommentDeleted: _.noop
        });

        vm.user = Session.getUser();
        vm.model = {};
        vm.isSocialStreamDeleteOther  = vm.user.hasPermission('SocialStreamDeleteOther');


        vm.fields = [{
            type: 'customTextarea',
            key: 'comment',
            templateOptions: {
                required: true,
                labelTranslateKey: 'app_COMMENT_SECTION_ADD_COMMENT', // 'Add a comment'
                placeholderTranslateKey: 'app_COMMENT_SECTION_INPUT_PLACEHOLDER', // 'Write your comment here'
                labelSrOnly: true,
                maxlength: 500,
                resetAfterSubmit: true
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_COMMENT_SECTION_FORM_SUBMIT_BUTTON_TEXT' // 'Post'
                }
            }
        };

        vm.viewMore = async () => {
            const comments = await SpotlightService.getComments({
                ...vm.itemIDs,
                startIndex: vm.comments.length + 1,
                limit: COMMENT_LIMIT
            });
            if (_.isEmpty(comments)) {
                return;
            }
            vm.comments = [
                ...vm.comments,
                ...comments
            ];
            vm.onViewMore(vm.comments);
        };

        vm.submit = async () => {
            const { socialCommentID } = await SpotlightService.postComment(vm.itemIDs, vm.model.comment);
            const newComment = {
                socialCommentID,
                commentContact: {
                    userID: vm.user.userID,
                    name: `${vm.user.firstName} ${vm.user.lastName}`
                },
                comments: vm.model.comment,
                createdOn: moment()
            };
            vm.comments = [
                newComment,
                ...vm.comments
            ];
            vm.totalComments += 1;
            vm.onCommentAdded(vm.comments);
        };

        vm.deleteComment = async (comment) => {
            await Prompt.open({ type: 'warning' }).result;
            await SpotlightService.deleteComment(comment.socialCommentID);
            vm.comments.splice(vm.comments.indexOf(comment), 1);
            vm.totalComments -= 1;
            vm.onCommentDeleted(vm.comments);
        };
    };
}
