incentiveLibraryService.$inject = ['$http', 'Session'];

export default function incentiveLibraryService($http) {

    async function getIncentiveLibraryList({ promoLibraryTypeCode }) {
        const res = await $http.get(`/api/incentive-library/${promoLibraryTypeCode}`);
        return res.data || [];
    }

    async function getIncentiveLibraryEntry({ promoLibraryTypeCode }) {
        const res = await $http.get(`/api/incentive-library/getIncentive/${promoLibraryTypeCode}`);
        return res.data || 0;
    }
    return {
        getIncentiveLibraryList,
        getIncentiveLibraryEntry
    };
}
