import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';

RnDxGridService.$inject = [
    '$rootScope', '$compile', '$translate', 'GridColumnTypes', 'GridColumnFilterTypes', 'GridFilterOperators'
];

export default function RnDxGridService(
    $rootScope, $compile, $translate, GridColumnTypes, GridColumnFilterTypes, GridFilterOperators
) {

    const Events = {
        RELOAD_ROWS: 'RN_DX_GRID_RELOAD_ROWS'
    };

    // https://js.devexpress.com/Documentation/16_2/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/#dataType
    const gridColumnTypesToDevExDataTypes = {
        [GridColumnTypes.TEXT]: 'string',
        [GridColumnTypes.NUMBER]: 'number',
        [GridColumnTypes.DATE]: 'date',
        [GridColumnTypes.BOOLEAN]: 'boolean'
    };

    /**
     * @param gridID {String}
     */
    function getGridInstance(gridID) {
        return $('#' + gridID).dxDataGrid('instance');
    }

    /**
     * Formats a column config object for an rn-dx-grid instance to be used with dx-data-grid.
     * Sets defaults and etc.
     * @param $scope - angular scope of the grid instance
     * @param {Object} column - Passed from component binding vm.columns
     * @param {int} columnIndex - Index of the column in the columns array
     */
    function formatColumn($scope, column, columnIndex) {
        _.defaults(column, {
            visible: true,
            alignment: 'left',
            nonDevExOptions: {},
        });

        const type = column.nonDevExOptions.type || column.type;

        let listOptionsNames;

        // TODO: refactor so that this isn't needed - could cause conflicts with devEx properties in the future
        column.nonDevExOptions.type = column.type = type;

        if (type) {
            if (!GridColumnTypes.checkValidValue(type)) {
                throw new Error(
                    `Column "${column.dataField}" has an invalid "type" of "${type}". `
                    + `Must be one of the values from GridColumnTypes.`
                );
            }

            let filterType = column.nonDevExOptions.filterType || column.filterType;
            if (!filterType) {
                filterType = type;
            }
            if (!GridColumnFilterTypes.checkValidValue(filterType)) {
                filterType = GridColumnFilterTypes.TEXT;
                console.warn(`Column "${column.dataField}" has an invalid filterType, defaulted to ${filterType}.`);
            }

            // TODO: refactor so that this isn't needed - could cause conflicts with devEx properties in the future
            column.nonDevExOptions.filterType = column.filterType = filterType;

            // TODO: refactor so that this isn't needed - could cause conflicts with devEx properties in the future
            column.listOptions = column.nonDevExOptions.listOptions;

            if (!column.dataType) {
                column.dataType = gridColumnTypesToDevExDataTypes[type];
            }
        }

        if (type === GridColumnTypes.DATE && !column.format) {
            column.format = defaultDateColumnFormatter;
        }

        if (type === GridColumnTypes.BOOLEAN) {
            _.defaultsDeep(column, {
                customizeText(cellInfo) {
                    return cellInfo.valueText || false; // Default null values to false
                },
                nonDevExOptions: {
                    trueTranslateKey: 'app_GRID_FILTER_VALUE_BOOLEAN_TRUE', // 'Yes'
                    falseTranslateKey: 'app_GRID_FILTER_VALUE_BOOLEAN_FALSE', // 'No'
                    hideWhenTrue: false,
                    // Assume that most rows will be "false" rather than "true", so only show the cell when the value
                    // is "true" so that it stands out more in the grid.
                    hideWhenFalse: true
                }
            });
            if (column.nonDevExOptions.truePhrase) {
                delete column.nonDevExOptions.trueTranslateKey;
            }
            if (column.nonDevExOptions.falsePhrase) {
                delete column.nonDevExOptions.falseTranslateKey;
            }
            column.nonDevExOptions.cellTemplate = getBooleanCellTemplate(column);
        }

        if (column.nonDevExOptions.captionTranslateKey) {
            column.caption = $translate.instant(column.nonDevExOptions.captionTranslateKey);
        }

        if (!_.isEmpty(column.nonDevExOptions.listOptions)) {
            listOptionsNames = column.nonDevExOptions.listOptions
                .filter(listOption => listOption.value && (listOption.translateKey || listOption.name))
                .reduce((obj, listOption) => Object.assign(obj, {
                    [listOption.value]: listOption.translateKey
                        ? $translate.instant(listOption.translateKey) : listOption.name
                }), {});
        }

        if ((column.nonDevExOptions.showValueOfListOption || column.nonDevExOptions.showValueOf) && listOptionsNames !== undefined) {
            _.defaults(column, {
                customizeText(cellInfo) {
                    const value = cellInfo.value || cellInfo[column.dataField];
                    return listOptionsNames[value] || value || '';
                }
            });

            if (column.nonDevExOptions.showValueOf) {
                _.defaults(column, {
                    calculateSortValue: column.nonDevExOptions.showValueOf,
                });
            }
        }

        if (column.nonDevExOptions.isOptionsColumn) {
            _.defaultsDeep(column, {
                dataField: `options${columnIndex}`,
                visibleIndex: 1,
                fixed: true,
                fixedPosition: 'right',
                allowSorting: false,
                nonDevExOptions: {
                    type: null,
                    filterType: null,
                    isNotFilterable: true,
                    cellTemplate: ''
                }
            });
            column.nonDevExOptions.cellTemplate += `
                <i uib-tooltip="${$translate.instant('app_GRID_ROW_REFRESHING')}"
                   tooltip-append-to-body="true"
                   class="fa fa-spinner fa-pulse row-updating-spinner">
                </i>`;
        }

        if (column.nonDevExOptions.cellTemplate) {
            column.cellTemplate = function defaultCellTemplateCompiler(cellElement, cellInfo) {
                const cellScope = $scope.$parent.$new(false);
                cellScope.cell = cellInfo;
                const compiledItemTemplate = $compile(column.nonDevExOptions.cellTemplate)(cellScope);
                cellElement.append(compiledItemTemplate);
            };
        }

        column.cssClass = [
            type ? `column-type-${type}` : '',
            column.nonDevExOptions.isOptionsColumn ? 'options-column' : '',
            `${column.dataField}-column`,
            column.cssClass || ''
        ].join(' ');
    }

    /**
     * @param {Object} column
     * @returns {string}
     */
    function getBooleanCellTemplate(column) {
        if (column.nonDevExOptions.cellTemplate) {
            return column.nonDevExOptions.cellTemplate;
        }
        const {
            truePhrase, falsePhrase, trueTranslateKey, falseTranslateKey, hideWhenTrue, hideWhenFalse
        } = column.nonDevExOptions;
        let cellTemplate = '';
        if (!hideWhenTrue) {
            cellTemplate += trueTranslateKey
                ? `<span ng-if="cell.data['${column.dataField}']" translate="${trueTranslateKey}"></span>`
                : `<span ng-if="cell.data['${column.dataField}']">${truePhrase}</span>`;
        }
        if (!hideWhenFalse) {
            cellTemplate += falseTranslateKey
                ? `<span ng-if="!cell.data['${column.dataField}']" translate="${falseTranslateKey}"></span>`
                : `<span ng-if="!cell.data['${column.dataField}']">${falsePhrase}</span>`;
        }
        return cellTemplate;
    }

    /**
     * @param {String} dateString
     */
    function defaultDateColumnFormatter(dateString) {
        return moment(dateString).format('ll');
    }

    /**
     * Calculates the text to be displayed for a specific filter in the list of filters above the grid.
     * @param {Object[]} columns - Columns of the grid, assumed to have already gone through formatColumn()
     * @param {GridFilter} filter
     * @returns {string}
     */
    function getFilterDisplayText(columns, filter) {
        const column = columns
                .filter(column => !!column.dataField)
                .find(column => column.dataField.toLowerCase() === filter.field.toLowerCase());
        const operator = _.values(GridFilterOperators)
            .find(operator => operator.toLowerCase() === filter.operator.toLowerCase());
        const operatorProperties = GridFilterOperators.properties[operator];
        const displaySymbol = operatorProperties.displaySymbol || operatorProperties.phrase.toLowerCase();
        const value = filter.value;
        switch (column.filterType) {
            case GridColumnFilterTypes.TEXT: {
                return `${column.caption} ${displaySymbol} "${value}"`;
            }
            case GridColumnFilterTypes.NUMBER: {
                return `${column.caption} ${displaySymbol} ${value}`;
            }
            case GridColumnFilterTypes.DATE: {
                switch (operator) {
                    case GridFilterOperators.EQUALS:
                        return `${column.caption}: ${value}`;
                    case GridFilterOperators.BEFORE:
                    case GridFilterOperators.AFTER:
                        return `${column.caption}: ${displaySymbol} ${value}`;
                    default:
                        return `${column.caption}: ${displaySymbol}`;
                }
            }
            case GridColumnFilterTypes.BOOLEAN: {
                const { truePhrase, falsePhrase, trueTranslateKey, falseTranslateKey } = column.nonDevExOptions;
                let valuePhrase;
                if (value) {
                    valuePhrase = trueTranslateKey ? $translate.instant(trueTranslateKey) : truePhrase;
                } else {
                    valuePhrase = falseTranslateKey ? $translate.instant(falseTranslateKey) : falsePhrase;
                }
                return `${column.caption}: ${valuePhrase}`;
            }
            case GridColumnFilterTypes.RADIO: {
                const option = column.listOptions.find(option => option.value === value);
                const optionName = option.translateKey ? $translate.instant(option.translateKey) : option.name;
                return `${column.caption}: ${optionName}`;
            }
            case GridColumnFilterTypes.CHECKBOX: {
                const listOptionNames = column.listOptions
                    .filter(option => value.some(
                        value => String(value) === String(option.value) || angular.equals(value, option.value))
                    )
                    .map(option => option.translateKey ? $translate.instant(option.translateKey) : option.name)
                    .join(', ');
                return `${column.caption}: ${listOptionNames}`;
            }
        }
    }

    /**
     * Gets new data for a specific dataField and updates related rows in the grid.
     * Ex. after closing a quiz result modal you want to reload all rows related to a quizResultID.
     * @param {Object} params
     * @param {int} params.gridID
     * @param {string} params.dataField
     * @param {int} params.dataFieldValue
     * @param {Function} [params.findUpdatedRowData]
     *      When there could be multiple rows where `row.data[dataField] === dataFieldValue`, a function needs to be
     *      specified here for finding the updated data object for a single row.
     *      Takes `(row, updatedRowsData)` as params.
     *      - `row` = The row that is going to be updated (potentially). From `gridInstance.getVisibleRows()`.
     *      - `updatedRowsData` = Data from the query for the updated data where `dataField = dataFieldValue`.
     *      See the submissionsGrid for an example.
     *      There, we want to update multiple rows where `quizResultID = 1234` and there is 1 row per `sectionID`.
     */
    function reloadRows(params) {
        $rootScope.$broadcast(Events.RELOAD_ROWS, params);
    }

    return {
        Events,
        getGridInstance,
        formatColumn,
        getFilterDisplayText,
        reloadRows
    };
}
