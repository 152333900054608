import angular from 'angular';

import communicationModalTpl from './communicationModal.tpl.html';
import communicationModalCtrl from './communicationModal.controller';

Communication.$inject = ['$uibModal', 'PromoTypes', 'ModalTypes'];

export default function Communication($uibModal, PromoTypes, ModalTypes) {

    /**
     * @see openDetailsModal
     * @param {Object} params
     * @param {string} params.typeCode - Needed to add a CSS class to the modal
     * @param {string} params.detailsTemplate
     * @param {Object} [params.modalOptions]
     * @returns {Function}
     */
    const createModalOpener = ({ typeCode, detailsTemplate, modalOptions = {} }) => (model) => {
        Object.assign(model, {
            messageTypeCode: typeCode,
            pointTrackingTypeCode: typeCode
        });
        return openDetailsModal(model, detailsTemplate, modalOptions);
    };

    function open(model) {
        if (model.pointTrackingTypeCode) {
            return openByPointTrackingType(model);
        }
        if (model.messageTypeCode) {
            return openByMessageType(model);
        }
        throw new Error('JSON needs to have pointTrackingTypeCode or messageTypeCode');
    }

    function openByPointTrackingType(model) {
        switch (model.pointTrackingTypeCode) {
            case 'Applause':
                return openApplause(model);
            case 'Award':
                return openAward(model);
            case 'Certificate':
                return openCertificate(model);
            case 'Redemption':
                return openRedemption(model);
            case 'Incentive':
                return openIncentive(model);
            default:
                if (model.messageRecipientID) {
                    model.messageTypeCode = model.messageTypeCode || model.pointTrackingTypeCode;
                    return openLegacyMessage(model);
                } else {
                    // handle other pointtrackingtype cases (Initial etc)
                    return openDefaultPointTracking(model);
                }
        }
    }

    function openByMessageType(model) {
        switch (model.messageTypeCode) {
            case 'Applause':
                return openApplause(model);
            case 'Certificate': {
                if (model.promoTypeID === PromoTypes.AWARD) {
                    return openAward(model);
                }
                return openCertificate(model);
            }
            default:
                return openLegacyMessage(model);
        }
    }

    const openApplause = createModalOpener({
        typeCode: 'Applause',
        detailsTemplate: `<applause-details applause="::vm.model" on-delete="::vm.onDelete"></applause-details>`,
        modalOptions: { size: 'md' }
    });

    const openAward = createModalOpener({
        typeCode: 'Award',
        detailsTemplate: `<award-details award="::vm.model" on-delete="::vm.onDelete"></award-details>`
    });

    const openCertificate = createModalOpener({
        typeCode: 'Certificate',
        detailsTemplate: `<certificate-details certificate="::vm.model" on-delete="::vm.onDelete"></certificate-details>`
    });

    const openSocialPost = createModalOpener({
        type: 'SocialPost',
        detailsTemplate: `<social-post-details social-post="::vm.model" on-delete="::vm.onDelete"></social-post-details>`,
        modalOptions: { size: 'md' }
    });

    const openRedemption = createModalOpener({
        typeCode: 'Redemption',
        detailsTemplate: `<redemption-details redemption="::vm.model"></redemption-details>`
    });

    const openIncentive = createModalOpener({
        typeCode: 'Incentive',
        detailsTemplate: `<incentive-results incentive="::vm.model"></incentive-results>`
    });

    const openQuestionOverview = createModalOpener({
        typeCode: ModalTypes.QUESTION_OVERVIEW.Type,
        detailsTemplate: `<answer-details-modal results="::vm.model" is-all="true"></answer-details-modal>`
    });

    const openGameplayResults = createModalOpener({
        typeCode: ModalTypes.GAMEPLAY_RESULTS.Type,
        detailsTemplate: `<answer-details-modal results="::vm.model" is-all="false"></answer-details-modal>`
    });

    const openTrainingQuizResults = createModalOpener({
        typeCode: ModalTypes.TRAINING_QUIZ_RESULTS.Type,
        detailsTemplate: `<training-quiz-results promo-details="::vm.model"></training-quiz-results>`
    });

    const openByModalType = (model, type) => {
        switch (type) {
            case ModalTypes.QUESTION_OVERVIEW.Type:
                return openQuestionOverview(model);
            case ModalTypes.GAMEPLAY_RESULTS.Type:
                return openGameplayResults(model);
            case ModalTypes.TRAINING_QUIZ_RESULTS.Type:
                return openTrainingQuizResults(model);
            case ModalTypes.CLAIM_QUIZ_RESULTS.Type:
                return openIncentive(model);
        }
    };

    function openDefaultPointTracking(model) {
        Object.assign(model, {
            messageTypeCode: model.pointTrackingTypeCode
        });
        const detailsTemplate = `<default-point-tracking-details details="::vm.model"></default-point-tracking-details>`;
        return openDetailsModal(model, detailsTemplate);
    }

    function openPointTrackingCorrection(messageDetails) {
        const detailsTemplate = `
            <point-tracking-correction-details correction-list="::vm.model"></point-tracking-correction-details>
        `;
        return openDetailsModal(messageDetails, detailsTemplate);
    }

    function openLegacyMessage(messageDetails) {
        const detailsTemplate = `<legacy-message message-details="::vm.model" is-in-modal="true"></legacy-message>`;
        return openDetailsModal(messageDetails, detailsTemplate);
    }

    /**
     * @param model {Object} - applause, incentive, redemption or etc. from an SP
     *                          (Ex. Applause_Get, PointTracking_GetDetails, MessageRecipients_GetDetails, etc.)
     * @param detailsTemplate {String} - Html, will have access to vm of communicationModalCtrl
     * @param options {Object=} - ui-bootstrap modal options to override/add
     */
    function openDetailsModal(model, detailsTemplate, options = {}) {
        return $uibModal.open(angular.merge({
            templateUrl: communicationModalTpl,
            controller: communicationModalCtrl,
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                model: () => model,
                detailsTemplate: () => detailsTemplate
            }
        }, options));
    }

    return {
        open,
        openByPointTrackingType,
        openByMessageType,
        openApplause,
        openAward,
        openCertificate,
        openSocialPost,
        openIncentive,
        openByModalType,
        openRedemption,
        openPointTrackingCorrection,
        openLegacyMessage,
        openDefaultPointTracking,
    };
}
