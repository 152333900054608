promoLeaderboardWidgetCtrl.$inject = ['Session'];

export default function promoLeaderboardWidgetCtrl(Session) {
    this.$onInit = () => {
        // Passed passed from component bindings:
        // vm.promoDetails
        // vm.size

        const vm = this;

        const MAX_ROWS_SMALL = 5; // Max number of rows that should be shown when the widget is size "small" (Ex. promo card)

        vm.leaderboard = vm.promoDetails.leaderboard;

        if (vm.leaderboard && vm.promoDetails.leaderboardTypeID) {
            vm.leaderboard.leaderboardTypeID = vm.promoDetails.leaderboardTypeID;
        }

        vm.user = Session.getUser();

        switch (vm.size) {
            case 'small':
                vm.displayRankings = getRearrangedSmallDisplayRankings();
                break;
            case 'large':
            default:
                vm.displayRankings = vm.leaderboard.rankings;
        }

        function getRearrangedSmallDisplayRankings() {
            const originalRankings = vm.leaderboard.rankings;
            const userRanking = vm.leaderboard.userRanking;

            if (!userRanking) {
                return originalRankings;
            }

            const userIsNotInRankings = originalRankings.every(ranking => ranking.rank !== userRanking.rank);
            if (userIsNotInRankings) {
                return [
                    ...originalRankings.slice(0, MAX_ROWS_SMALL - 1),
                    Object.assign({}, userRanking, { forceSingleRow: true })
                ];
            }

            // At this point we know user is in the rankings
            if (userRanking.numberSharingRank === 1) {
                return originalRankings;
            }

            // At this point we know user is in the rankings and is tied
            const tiedIndex = originalRankings.findIndex(ranking => ranking.rank === userRanking.rank);
            return [
                ...originalRankings.slice(0, tiedIndex),
                Object.assign({}, userRanking, { forceSingleRow: true }),
                ...originalRankings.slice(tiedIndex, MAX_ROWS_SMALL - 1),
            ];
        }
    };
}

/*
Notes
--------------------------------------

If the numberSharingRank of a ranking is greater than 1, the row will display Ex. "4 people tied"
The name and contactID of that ranking object should not be used (it's from ONE of the tied users)


Test cases for small widget size:

 case: no data for user (no userRanking)
 expectation: user not displayed

 case: data for user exists (userRanking is in leaderboard json) but user is not in rankings
 expectation: add the user to the end of the array, make sure max 5 rows

 case: user is in the ranking array and numberSharingRank is 1
 expectation: display rankings, order by rank, make sure the user isn't displayed twice

 case: user is in the ranking array but is tied (numberSharingRank > 1)
 expectation: display 2 people tied and show userRanking, make sure to max 5 rows
 Ex. if there are 5 items in the rankings array and 2 people are tied for rank 5, if the user is rank 3, the "2 people tied" for rank 5 wouldn't show

 Ex. if current user is Carl Macdonald
    Leaderboard         quantity
 1      Stacy McLacy        4
 3      Carl Macdonald      3
 3      2 people tied       3

 note: the "2 people tied" object in leaderboard.rankings will be 1 object and will include ONE of the tied user's name and contactID
 need to search leaderboard.rankings for object with same rank as userRanking.rank

 */
