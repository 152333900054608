import angular from 'angular';
import '../../../../core/core.module';
import AnniversaryService from './anniversaryService.factory';

import anniversaryBanner from './anniversaryBanner.component';

export default angular.module('app.spotlight.spotlightComponents.anniversaryBanner', [
    'app.core',
])
.factory('AnniversaryService', AnniversaryService)
.component('anniversaryBanner', anniversaryBanner);
