import angular from 'angular';

import debugConfig from './debug.config';
import debugCtrl from './debug.controller';

import '../../core/core.module';

export default angular.module('app.debug', [
    'app.core'
])
.config(debugConfig)
.controller('debugCtrl', debugCtrl);
