import promoQuizSectionTpl from './promoQuizSection.tpl.html';
import promoQuizSectionCtrl from './promoQuizSection.controller';

export default {
    templateUrl: promoQuizSectionTpl,
    controller: promoQuizSectionCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        formModel: '<',
        section: '<',
        showApproverTools: '<',
        formSubmitSuccessCallback: '<onFormSubmitSuccess',
        formSubmitFailCallback: '<onFormSubmitFail',
        onToolSubmitSuccess: '<',
        onToolSubmitFail: '<',
        onSnoozeToggled: '<'
    }
};
