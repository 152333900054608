import angular from 'angular';

import '../../../core/core.module';

import loginConfig from './login.config';
import forgotPasswordConfig from './forgotPassword.config';

export default angular.module('app.auth.login', [
    'app.core',
])
.config(loginConfig)
.config(forgotPasswordConfig);
