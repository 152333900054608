import angular from 'angular';

import '../../core/core.module';
import '../signOnTasks/signOnTasks.module';

import './applause/applause.module';
import './award/award.module';
import './certificate/certificate.module';
import './incentive/incentive.module';
import './redemption/redemption.module';
import './legacy/legacyMessage.module';
import './pointTrackingCorrection/pointTrackingCorrection.module';
import './socialPost/socialPost.module';
import './defaultPointTracking/defaultPointTracking.module';

import Communication from './communication.factory';

export default angular.module('app.communication', [
    'app.core',
    'app.signOnTasks',

    'app.communication.applause',
    'app.communication.award',
    'app.communication.certificate',
    'app.communication.incentive',
    'app.communication.redemption',
    'app.communication.legacyMessage',
    'app.communication.pointTrackingCorrection',
    'app.communication.socialPost',
    'app.communication.defaultPointTracking'
])
.factory('Communication', Communication);
