import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class ReadTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);

        _.merge(this, {
            acceptButtonText: 'app_SIGN_ON_TASKS_READ_ACCEPT_BUTTON_TEXT', // 'Mark as read'
            successMessage: '',
            taskModalOptions: {
                showProgressHeaderSubmitButton: true,
                showCancelButton: false,
                successMessageTimeout: 600,
            }
        });
    }
}
