incentiveLibraryCtrl.$inject = ['$state', '$scope', '$stateParams', 'Session', 'incentiveLibraryService', 'incentiveCardService', '$translate'];

export default function incentiveLibraryCtrl($state, $scope, $stateParams, Session, incentiveLibraryService, incentiveCardService, $translate) {
    const vm = this;
    vm.user = Session.getUser();
    vm.hasCompanyMetrics = vm.user.isCompanyMetricsAvailable;
    vm.getLibraryIncentiveAndClone = getLibraryIncentiveAndClone;
    vm.ToggleExamples = ToggleExamples;
    const UploadExample = $translate.instant('promolib_INCENTIVE_LIBRARY_UPLOAD_EXAMPLE');
    const MarketingExample = $translate.instant('promolib_INCENTIVE_LIBRARY_MARKETING_EXAMPLE');

    vm.showMainPage = true;

    vm.incentiveLibraryPages = [
        { title: 'Data', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_DATA', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_DATA', promoLibraryTypeCode: 'data', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_VIEW_TEMPLATES', show: vm.hasCompanyMetrics },
        { title: 'Form', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_FORM', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_FORM', promoLibraryTypeCode: 'form', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_VIEW_TEMPLATES', show: true },
        { title: 'Upload', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_UPLOAD', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_UPLOAD', promoLibraryTypeCode: 'upload', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_START_NOW', exampleText: UploadExample, exampleShow: false, show: true },
        { title: 'Marketing', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_MARKETING', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_MARKETING', promoLibraryTypeCode: 'marketing', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_START_NOW', exampleText: MarketingExample, exampleShow: false, show: true },
        { title: 'My Library', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_MY_LIBRARY', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_MY_LIBRARY', promoLibraryTypeCode: 'personal', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_VIEW_TEMPLATES', show: true },
        { title: 'All Incentives', titleKeyName: 'promolib_INCENTIVE_LIBRARY_HEADER_ALL_INCENTIVES', descriptionKeyName: 'promolib_INCENTIVE_LIBRARY_DESCRIPTION_ALL_INCENTIVES', promoLibraryTypeCode: '', buttonTypeCode: 'promolib_INCENTIVE_LIBRARY_BUTTON_START_FROM_EXISTING', show: true }
    ];

    // helps when we switch states - make sure the correct page is being displayed
    $scope.$watch(function() {
        return $state.$current.name;
    }, function(newVal, oldVal) {

        if (newVal === 'incentiveLibraryList') {
            vm.showMainPage = false;

        } else if (newVal === 'incentiveLibrary') {
            vm.showMainPage = true;
        }
    });


    function ToggleExamples (page) {
        page.exampleShow = !page.exampleShow;
    }

    function getLibraryIncentiveAndClone(promoLibraryTypeCode) {
        // check for existing incentive tied to the selected library type
        incentiveLibraryService.getIncentiveLibraryEntry({
            promoLibraryTypeCode
        }).then(function(results) {
            // found incentive, clone it
            incentiveCardService.incentiveClone(
                results
            ).then(function(clonedIncentive) {
                $state.go('incentiveEdit', { id: clonedIncentive.promoID });
            });
        });
    }
}
