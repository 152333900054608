AuthStatus.$inject = ['$localStorage', '$rootScope', '$window'];

export default function AuthStatus($localStorage, $rootScope, $window) {

    function isAuthenticated() {
        return $localStorage.isAuthenticated;
    }

    /**
     * @param {boolean} isAuthenticated
     */
    function setIsAuthenticated(isAuthenticated) {
        [$rootScope, $localStorage, $window.rnSession].forEach(obj => obj.isAuthenticated = isAuthenticated);
    }

    return {
        isAuthenticated,
        setIsAuthenticated
    };
}
