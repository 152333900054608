import widgetHeaderTpl from './widgetHeader.tpl.html';
import widgetHeaderCtrl from './widgetHeader.controller';

widgetHeader.$inject = [];

export default function widgetHeader() {
    return {
        restrict: 'E',
        templateUrl: widgetHeaderTpl,
        controller: widgetHeaderCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            text: '@',
            helpText: '@'
        }
    };
}
