import _ from 'lodash';

changePasswordFormCtrl.$inject = ['$scope', '$translate', 'ChangePasswordService'];

export default function changePasswordFormCtrl($scope, $translate, ChangePasswordService) {
    this.$onInit = () => {
        const vm = this;

        // Passed from parent via component bindings:
        // vm.passwordPolicy
        // vm.submitButtonOptions
        // vm.hasToken
        // vm.onSuccess
        // vm.onFail

        vm.alerts = [];

        vm.passwordModel = {};

        const newPasswordValidators = {};

        if (vm.passwordPolicy) {
            vm.passwordRequirements = getPasswordRequirements(vm.passwordPolicy);
            Object.keys(vm.passwordRequirements).forEach(function(key) {
                // Set validator for formly field
                // The .bind is necessary for the references to 'this.isValid' to work for some reason. (refactoring welcome!)
                newPasswordValidators[key] = vm.passwordRequirements[key].validator.bind(vm.passwordRequirements[key]);
            });
        }

        vm.passwordFields = getPasswordFields(newPasswordValidators);

        vm.submitButtonOptions = _.merge({
            settings: {
                initial: {
                    translateKey: 'changePassword_BUTTON_SUBMIT'
                }
            }
        }, vm.submitButtonOptions || {});

        validateOnChange('oldPassword', 'newPassword');
        validateOnChange('newPassword', 'newPasswordConfirm');

        vm.submit = function() {
            const { oldPassword, newPassword } = vm.passwordModel;
            return ChangePasswordService.submit(oldPassword, newPassword);
        };

        function getPasswordRequirements(passwordPolicy = {}) {
            // Only the "validator" property is utilized by formly.
            // "labelTranslateKey" and "value" are just used by this controller to render the "requirements" part of the template.
            const requirements = {
                mustBeNew: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MUST_BE_NEW',
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const oldPassword = vm.passwordModel.oldPassword;
                        return this.isValid = (value !== oldPassword && value !== '' && oldPassword !== '');
                    }
                },
                minLength: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_LENGTH',
                    value: passwordPolicy.minLength,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        return this.isValid = (value.length >= passwordPolicy.minLength);
                    }
                },
                maxLength: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MAX_LENGTH',
                    value: passwordPolicy.maxLength,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        return this.isValid = (value.length <= passwordPolicy.maxLength && value.length > 0);
                    }
                },
                minAlphas: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_ALPHAS',
                    value: passwordPolicy.minAlphas,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const pattern = '[a-zA-Z]{' + passwordPolicy.minAlphas + ',}';
                        return this.isValid = new RegExp(pattern).test(value);
                    }
                },
                minAlphaSmall: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_ALPHA_SMALL',
                    value: passwordPolicy.minAlphaSmall,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const pattern = '[a-z]{' + passwordPolicy.minAlphaSmall + ',}';
                        return this.isValid = new RegExp(pattern).test(value);
                    }
                },
                minAlphaCaps: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_ALPHA_CAPS',
                    value: passwordPolicy.minAlphaCaps,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const pattern = '[A-Z]{' + passwordPolicy.minAlphaCaps + ',}';
                        return this.isValid = new RegExp(pattern).test(value);
                    }
                },
                minNumbers: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_NUMBERS',
                    value: passwordPolicy.minNumbers,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const pattern = '\\d{' + passwordPolicy.minNumbers + ',}';
                        return this.isValid = new RegExp(pattern).test(value);
                    }
                },
                minSymbols: {
                    labelTranslateKey: 'changePassword_REQUIREMENT_MIN_SYMBOLS',
                    value: passwordPolicy.minSymbols,
                    validator($viewValue, $modelValue) {
                        const value = $modelValue || $viewValue || '';
                        const pattern = '[^a-zA-Z0-9\\s]{' + passwordPolicy.minSymbols + ',}';
                        return this.isValid = new RegExp(pattern).test(value);
                    }
                }
            };

            if (vm.hasToken) {
                delete requirements.mustBeNew;
            } else {
                // Prevent the mustBeNew requirement from being filtered out below, since it's added by this code and not from db
                passwordPolicy.mustBeNew = true;
            }

            Object.keys(requirements).forEach(function(key) {
                if (!passwordPolicy[key]) {
                    // Delete from requirements if policy doesn't specify
                    delete requirements[key];
                }
            });

            return requirements;
        }

        function getPasswordFields(newPasswordValidators) {
            return [
                {
                    key: 'oldPassword',
                    type: 'customInput',
                    model: vm.passwordModel.oldPassword,
                    templateOptions: {
                        type: 'password',
                        labelTranslateKey: 'changePassword_OLD_PASSWORD',
                        labelClass: 'col-sm-4',
                        controlClass: 'col-sm-8',
                        required: true,
                        autocomplete: false
                    },
                    hideExpression: () => vm.hasToken
                }, {
                    key: 'newPassword',
                    type: 'customInput',
                    model: vm.passwordModel.newPassword,
                    templateOptions: {
                        type: 'password',
                        labelTranslateKey: 'changePassword_NEW_PASSWORD',
                        labelClass: 'col-sm-4',
                        controlClass: 'col-sm-8',
                        required: true.valueOf,
                        autocomplete: false
                    },
                    validators: newPasswordValidators
                }, {
                    key: 'newPasswordConfirm',
                    type: 'customInput',
                    model: vm.passwordModel.newPasswordConfirm,
                    templateOptions: {
                        type: 'password',
                        labelTranslateKey: 'changePassword_CONFIRM_NEW_PASSWORD',
                        labelClass: 'col-sm-4',
                        controlClass: 'col-sm-8',
                        required: true,
                        autocomplete: false
                    },
                    validators: {
                        matchesInput: {
                            expression: function($viewValue, $modelValue, scope) {
                                if(scope.$parent.fields[1].formControl.$invalid) {
                                    return true;
                                }
                                const value = $modelValue || $viewValue || '';
                                return value === vm.passwordModel.newPassword;
                            },
                            message: () => $translate.instant('changePassword_VALIDATOR_MESSAGE_NO_MATCH') // 'Does not match above'
                        }
                    }
                }
            ];
        }

        function validateOnChange(fieldKeyToWatch, fieldKeyToValidate) {
            const field = vm.passwordFields.find(field => field.key === fieldKeyToValidate);
            $scope.$watch(() => vm.passwordModel[fieldKeyToWatch], function(newValue) {
                if (!newValue || !field.formControl) {
                    return;
                }
                field.formControl.$validate();
            });
        }

        vm.onFail = (err) => {
            alertError(err);
        };

        vm.closeAlert = (index) => {
            vm.alerts.splice(index, 1);
        };

        // TODO: duplicated from loginPanel.controller.js, figure out way to share code
        function alertError(err) {
            const message = err.data.returnCode ? {
                text: err.data.phrase
            } : {
                translateKey: 'app_LOGIN_DEFAULT_ERROR_MESSAGE' // 'Sorry, an error occurred'
            };
            // We want to hide the error ID when can not re use last x password (1055) or
            // can not change password until x datetime (1056).
            const hideErrorID = [1055, 1056].includes(err.data.returnCode);
            vm.alerts.unshift({
                type: 'danger',
                errorID: !hideErrorID && err.data.errorID,
                ...message
            });
            if (vm.alerts.length >= 2) {
                vm.alerts.pop();
            }
        }
    };
}
