SpotlightService.$inject = ['$http', 'Session'];

export default function SpotlightService($http, Session) {

    async function getSpotlightItems({ participant, recognition, sent, recentDays, startDate, contactID, lastSpotlightItemID, spotlightItemsToShow }) {

        let selectedLimiterID;
        if (participant) {
            if (participant.includes('limiter')) {
                selectedLimiterID = getSelectedLimiterID(participant);
            }
        }
        const res = await $http.get('/api/spotlight', {
            params: {
                participant,
                recognition,
                sent,
                recentDays,
                startDate,
                contactID,
                lastSpotlightItemID,
                limiterID: selectedLimiterID,
                spotlightItemsToShow
            }
        });
        return res.data || [];
    }

    async function getSpotlightMarqueeItems({ participant, recognition, sent }) {

        let selectedLimiterID;
        if (participant) {
            if (participant.includes('limiter')) {
                selectedLimiterID = getSelectedLimiterID(participant);
            }
        }
        const res = await $http.get('/api/spotlight/marquee', {
            params: {
                participant,
                recognition,
                sent,
                limiterID: selectedLimiterID
            }
        });
        return res.data || [];
    }

    /**
     * An object from returned from the SpotlightItems_Get SP.
     * It can represent either an Applause, Certificate, or SocialPost.
     *
     * Something confusing is that we often pass all 3 IDs (applauseID, certificateID, and socialPostID) to the API / SPs
     * when it will only have 1 and the other 2 will be null.
     * TODO: consider refactoring in the future
     *
     * @typedef {Object} SpotlightItem
     *
     * @property {int} applauseID - If the item is an Applause, it will have this
     * @property {int} certificateID - If the item is a Certificate, it will have this
     * @property {int} socialPostID - If the item is a SocialPost, it will have this
     */

    /**
     * @param {SpotlightItem} spotlightItem
     */
    async function likeItem(spotlightItem) {
        return $http.post('/api/spotlight/like', {
            applauseID: spotlightItem.applauseID,
            certificateID: spotlightItem.certificateID,
            socialPostID: spotlightItem.socialPostID,
        });
    }

    /**
     * @param {SpotlightItem} spotlightItem
     */
    async function unlikeItem(spotlightItem) {
        return $http.post('/api/spotlight/unlike', {
            applauseID: spotlightItem.applauseID,
            certificateID: spotlightItem.certificateID,
            socialPostID: spotlightItem.socialPostID,
        });
    }

    /**
     * @param {SpotlightItem} spotlightItem
     */
    async function deleteItem(spotlightItem) {
        return $http.post('/api/spotlight/delete', {
            applauseID: spotlightItem.applauseID,
            certificateID: spotlightItem.certificateID,
            socialPostID: spotlightItem.socialPostID,
        });
    }

    /**
     * @param {SpotlightItem} params
     * @param {int} params.startIndex
     * @param {int} params.limit
     */
    async function getComments({ applauseID, certificateID, socialPostID, startIndex, limit }) {
        const res = await $http.get('/api/spotlight/comments', {
            params: {
                applauseID,
                certificateID,
                socialPostID,
                startIndex,
                limit
            }
        });
        return res.data;
    }

    /**
     * @param {SpotlightItem} spotlightItem
     * @param {String} comment
     */
    async function postComment(spotlightItem, comment) {
        const res = await $http.post('/api/spotlight/comments', {
            applauseID: spotlightItem.applauseID,
            certificateID: spotlightItem.certificateID,
            socialPostID: spotlightItem.socialPostID,
            comments: comment
        });
        return res.data;
    }

    /**
     * @param {SpotlightItem} spotlightItem
     */
    async function likeList(spotlightItem) {
        let res;
        if(spotlightItem.applauseID) {
            res = await $http.get(`/api/spotlight/likes/applause/${spotlightItem.applauseID}`);
        } else if(spotlightItem.certificateID) {
            res = await $http.get(`/api/spotlight/likes/certificate/${spotlightItem.certificateID}`);
        } else if(spotlightItem.socialPostID) {
            res = await $http.get(`/api/spotlight/likes/socialpost/${spotlightItem.socialPostID}`);
        }
        
        return res.data;
    }

    /**
     * @param {int} socialCommentID
     */
    async function deleteComment(socialCommentID) {
        return $http.delete(`/api/spotlight/comments/${socialCommentID}`);
    }

    function getSelectedLimiterID(limiterString) {
        const company = Session.getCompany();
        const limitersWithQueryStringName = company.limiters.map(limiter => Object.assign({}, limiter, {
            queryString: `limiter${limiter.num}`
        }));
        const selectedLimiter = limitersWithQueryStringName.find(limiter => limiter.queryString === limiterString);
        return selectedLimiter.ID;
    }

    return {
        getSpotlightItems,
        getSpotlightMarqueeItems,
        likeItem,
        unlikeItem,
        deleteItem,
        getComments,
        postComment,
        deleteComment,
        likeList
    };
}
