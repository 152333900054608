import angular from 'angular';

import '../../core/core.module';
import '../communication/communication.module';
import './accountConfirmedGrid/accountConfirmedGrid.module';
import './accountPendingGrid/accountPendingGrid.module';
import './accountDeclinedGrid/accountDeclinedGrid.module';
import './pointExpiry/pointExpiry.module';

import accountUrlConfig from './accountUrl.config';
import accountConfig from './account.config';
import accountRun from './account.run';

export default angular.module('app.account', [
    'app.core',
    'app.communication',
    'app.account.accountConfirmedGrid',
    'app.account.accountPendingGrid',
    'app.account.accountDeclinedGrid',
    'app.account.pointExpiry'
])
.config(accountUrlConfig)
.config(accountConfig)
.run(accountRun);
