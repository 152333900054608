import _ from 'lodash';

resetPasswordCtrl.$inject = ['ContactService'];

export default function resetPasswordCtrl(ContactService) {
    this.$onInit = () => {
        const vm = this;

        // Passed from parent template via component bindings:
        // vm.contact
        // vm.onCancel
        // vm.onSuccess - TODO: consider two separate bindings for generateTempPassword success and sendResetPasswordEmail success

        _.defaults(vm, {
            onSuccess: _.noop,
            onCancel: _.noop
        });

        vm.contactName = `${vm.contact.firstName} ${vm.contact.lastName}`;

        vm.generateTempPasswordButton = {
            class: 'primary',
            isDisabled: false,
            text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESET_PASSWORD_SUBMIT_BUTTON_NO_EMAIL' // Generate New Password
        };

        vm.unlockButton = {
            class: 'primary',
            isDisabled: false,
            text: 'app_UNLOCK_ACCOUNT_BUTTON' // Unlock
        };

        vm.generateTempPassword = function() {
            return ContactService.generateTempPassword(vm.contact.userID)
                .then(function({ password }) {
                    vm.generateTempPasswordButton = {
                        class: 'success',
                        isDisabled: true,
                        text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESET_PASSWORD_SUBMIT_BUTTON_SUCCESS_NO_EMAIL' // Submitted!
                    };
                    vm.newPassword = password;
                    return vm.onSuccess();
                })
                .catch(function(err) {
                    vm.errorMessage = err.data.phrase;
                });
        };

        if (vm.contact.email) {
            vm.resetPasswordButton = {
                class: 'primary',
                isDisabled: false,
                text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESET_PASSWORD_SUBMIT_BUTTON_WITH_EMAIL' // Send Reset Password Email
            };

            vm.sendResetPasswordEmail = function() {
                return ContactService.sendResetPasswordEmail(vm.contact.userID)
                    .then(function() {
                        vm.resetPasswordButton = {
                            class: 'success',
                            isDisabled: true,
                            text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESET_PASSWORD_SUBMIT_BUTTON_SUCCESS_WITH_EMAIL' // Email Sent!
                        };
                        return vm.onSuccess();
                    })
                    .catch(function(err) {
                        vm.errorMessage = err.data.phrase;
                    });
            };
        }

        if (vm.contact.isLockout) {
            vm.unlock = () => ContactService.unlockAccount(vm.contact.userID)
                .then(function() {
                    vm.unlockButton = {
                        class: 'success',
                        isDisabled: true,
                        text: 'app_UNLOCK_ACCOUNT_BUTTON_SUCCESS' // Unlocked!
                    };
                    return vm.onSuccess();
                })
                .catch(function(err) {
                    vm.errorMessage = err.data.phrase;
                });
        }
    };
}
