promoListCtrl.$inject = ['PromoService'];

export default function promoListCtrl(PromoService) {
    this.$onInit = () => {
        const vm = this;
        vm.getPromoDetailsSref = PromoService.getPromoDetailsSref;

        if (vm.promoGroups.length) {
            vm.promoList.map(promo => {
                const groupIndex = vm.promoGroups.findIndex(group => group.id === promo.promoGroupID);
                if (vm.promoGroups[groupIndex]) {
                    promo.promoGroupName = vm.promoGroups[groupIndex].name;
                }
                return promo;
            });
        }
    };
}
