import bowser from 'bowser';

gamesFrameCtrl.$inject = [];

export default function gamesFrameCtrl() {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.gamesURL

        vm.isBrowserSupported = !(bowser.name === 'Safari' && bowser.version === '12.1');
    };
}
