import angular from 'angular';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import slideshowWidget from './slideshowWidget.component';

export default angular.module('app.core.widgets.slideshowWidget', [

])
.component('slideshowWidget', slideshowWidget);
