import createGridPageConfig from '../../../core/directive/rnDxGrid/createGridPageConfig';

export default createGridPageConfig({
    grid: {
        componentName: 'submissions-summary-grid'
    },
    state: {
        name: 'submissionsSummary',
        config: {
            parent: 'auth',
            url: '/submissions/summary',
            data: {
                pageTitle: 'Submissions - Summary'
            },
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('submissions');
                }]
            }
        }
    },
    template: {
        header: 'submissions_PAGE_HEADER_SUMMARY',
        containerClass: 'submissions-summary-page-container'
    }
});
