import _ from 'lodash';
import qs from 'qs';

StateUtils.$inject = ['$rootScope', '$state', '$location', '$timeout', 'UrlUtils'];

export default function StateUtils($rootScope, $state, $location, $timeout, UrlUtils) {

    const allStates = $state.get();
    const concreteStates = allStates.filter(state => !state.abstract && state.url);
    const wildcardStates = concreteStates.filter(isWildcardState);

    const FALLBACK_STATE = { name: 'faq', params: {} };
    let defaultState;

    /**
     * Parses the defaultPage URL and saves it to an object that can be more easily used with $state.go.
     */
    function setDefaultState(defaultPage) {
        if (!defaultPage) {
            console.warn(`No default page was specified for this user's permission group, using ${FALLBACK_STATE.name} instead`);
            return defaultState = FALLBACK_STATE;
        }
        defaultState = getStateFromUrl(defaultPage);
        if (!defaultState) {
            console.warn(`Default page of "${defaultPage}" is invalid, using ${FALLBACK_STATE.name} instead. Consider double checking the URL.`);
            return defaultState = FALLBACK_STATE;
        }
    }

    /**
     * @returns {{ name: string, params: Object }}
     */
    function getDefaultState() {
        return defaultState;
    }

    /**
     * @returns {string}
     */
    function getDefaultStateUiSref() {
        return getUiSrefFromState(defaultState);
    }

    /**
     * For when there is some sort of error going to a user's default page (should be rare, usually a setup issue).
     * @returns {{ name: string, params: {} }}
     */
    function getFallbackState() {
        return FALLBACK_STATE;
    }

    /**
     * Parses a ui-router sref string and returns the state name and params as a valid object.
     * Makes it easier to pass to $state.go
     * Ex. content({ 'code': 'demo', 'foo': 5 }) --> { name: 'content', params: { code: 'demo', foo: 5 } }
     */
    function parseUiSref(sref) {
        const match = sref.match(/(\w+)\(({.+})\)/i);
        return {
            name: match[1],
            params: JSON.parse(match[2].replace(/'/g, '"')) // JSON.parse seems to only work with double quotes for strings
        };
    }

    /**
     * Parses a URL and returns a ui-sref so you can pass it to an a tag in an html template
     * Ex. /content/demo --> content({ code: 'demo' })
     * @param url
     * @returns {string}
     */
    function getUiSrefFromUrl(url) {
        const state = getStateFromUrl(url);
        if (!state) {
            console.warn(`State not found - URL = ${url}`);
            return '';
        }
        return getUiSrefFromState(state);
    }

    /**
     * Parses a normal URL and returns an object { name, params } that can be used with ui-router $state.go
     * @param {string} url
     * @returns {{ name: string, params: Object } | null}
     */
    function getStateFromUrl(url) {
        url = UrlUtils.formatAngularUrl({ url, desiredPrefix: '/' });

        // urlMatcher.exec returns null if the url does not match.
        // If it does match, it returns an object containing any state params the url contains.
        // See: https://ui-router.github.io/ng1/docs/0.3.2/#/api/ui.router.util.type:UrlMatcher

        // Cases we need to account for:
        // 1) If the state has a wildcard param
        // 2) If the URL is a legacy-frame page, then return the existing url
        // 3) If the URL we're trying has a querystring at the end, then `urlMatcher.exec` will return null.
        //    So we need to separate the querystring and pass it as an object to `urlMatcher.exec`.
        // 4) If the state has a param in the last path of its URL and the URL we're trying doesn't have a slash
        //    at the end, `urlMatcher.exec` will return null.
        //    Ex. if state has `/snapshot/:contactID`, then `/snapshot` would NOT match but `/snapshot/` would.

        for (const state of concreteStates) {
            const urlMatcher = getUrlMatcher(state);
            let params;
            if (wildcardStates.includes(state)) {
                // Case 1 - wildcard param state
                params = urlMatcher.exec(url);

            } else if (url.includes('legacy-frame')) {
                // Case 2 - legacy frame page
                params = urlMatcher.exec(url);

            } else if (url.includes('?')) {
                // Case 3 - if URL has querystring at the end
                const [path, query] = url.split('?');
                const queryObject = qs.parse(query);
                params = urlMatcher.exec(path, queryObject) || urlMatcher.exec(path + '/', queryObject);
            } else {
                // Case 4 - slash at the end
                params = urlMatcher.exec(url) || urlMatcher.exec(url + '/');
                // Try without the slash before trying with the slash because it might cause an issue if it's there
                // but isn't needed.
                // Ex. `/#/legacy-frame/Budgets.asp` will work but `/#/legacy-frame/Budgets.asp/` will 404.
            }
            if (params != null) {
                return { name: state.name, params };
            }
        }
        return null;
    }

    function getUiSrefFromState({ name, params }) {
        return name + (_.isEmpty(params) ? '' : `(${JSON.stringify(params)})`);
    }

    /**
     * Updates the query string in the url with new values.
     * To remove a param, set its value to null.
     * IMPORTANT: Need to set "reloadOnSearch: false" in the config of the state where this function is being called.
     * Otherwise, the state will reload after the URL params are changed.
     *
     * @param {Object} newParams
     */
    function updateUrlParams(newParams = {}) {
        const currentParams = $location.search();
        const mergedParams = Object.assign(currentParams, newParams);

        // Without the timeout, the browser history messes up for some reason
        // Ex. Spotlight --> Incentives --> My Account (calls updateUrlParams) --> Faq
        // If you hit back button twice, it will go from My Account to Spotlight, instead of Incentives

        return $timeout().then(() => {
            $location.search(mergedParams);
            $location.replace();
        });
    }

    /**
     * If a state is viewable that means a user can navigate to it and view see its stuff.
     * If not then it's an abstract state or one that immediately redirects to somewhere else and has no views.
     * @param {Object} state
     * @returns {boolean}
     */
    function isStateViewable(state) {
        return !state.abstract && state.views && state.name;
    }

    /**
     * Some states could have "wildcard" params, where everything in the URL gets captured.
     * Ex. `/legacy-frame/{urlPath:any}` - everything after `/legacy-frame/` gets captured into the `urlPath` param.
     * @see https://ui-router.github.io/ng1/docs/0.3.2/#/api/ui.router.util.type:UrlMatcher
     * @param {Object} state
     * @returns {boolean}
     */
    function isWildcardState(state) {
        const urlMatcher = getUrlMatcher(state);
        const stateParams = _.omit(urlMatcher.params, ['$$parent']);
        return Object.values(stateParams).some(param => param.location === 'path' && param.type.name === 'any');
    }

    function getUrlMatcher(state) {
        return state.$$state().url;
    }

    return {
        getFallbackState,
        setDefaultState,
        getDefaultState,
        getDefaultStateUiSref,
        parseUiSref,
        getUiSrefFromUrl,
        getStateFromUrl,
        getUiSrefFromState,
        updateUrlParams,
        isStateViewable
    };
}
