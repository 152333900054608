import _ from 'lodash';

bigNumberWidgetCtrl.$inject = ['WidgetDataDisplayTypes', '$translate', 'WidgetTypes'];

export default function bigNumberWidgetCtrl(WidgetDataDisplayTypes, $translate, WidgetTypes) {
    this.$onInit = () => {
        const vm = this;
        vm.bigNumber = _.get(vm.widget.data, 'stats[0]');
        vm.stats = _.get(vm.widget.data, 'stats');
        if (vm.widget.code === WidgetTypes.DATAFEED_NUMBER_AND_POINTS_EARNED) {
            vm.secondStat = _.get(vm.widget.data, 'stats[1]');
            vm.hasData = (vm.bigNumber && parseFloat(vm.bigNumber.value) > 0) || (vm.secondStat && parseFloat(vm.secondStat.value) > 0);

        } else if (vm.widget.code === WidgetTypes.APPLAUSE_TEAM_PERFORMANCE) {
            vm.hasData = vm.bigNumber;
        } else {
            vm.hasData = vm.bigNumber && parseInt(vm.bigNumber.value) > 0;
        }

        if (!vm.hasData) {
            return;
        }

        if (vm.stats.length > 1) {
            vm.bigNumber = vm.stats.filter(stat => stat.isPrimary === true)[0];
            vm.bigNumberStats = vm.stats.filter(stat => stat.isPrimary !== true);
        }

        if (!vm.bigNumber.value) {
            vm.bigNumber.value = 0;
        }

        if (vm.bigNumberStats) {
            vm.bigNumberStats.map((bigNumberStat) => {
                if (!bigNumberStat.value) {
                    bigNumberStat.value = 0;
                }
                return bigNumberStat;
            });
        }

        vm.WidgetDataDisplayTypes = WidgetDataDisplayTypes;
        vm.mod100BigNumber = parseInt(vm.bigNumber.value) % 100;
        vm.mod10BigNumber = parseInt(vm.bigNumber.value) % 10;
        if ((vm.mod100BigNumber > 10 && vm.mod100BigNumber < 20)) {
            vm.percentileSuffix = $translate.instant('snapshot_PERCENTILE_SUFFIX');
        } else if (vm.mod10BigNumber === 1 && parseInt(vm.bigNumber.value) !== 11) {
            vm.percentileSuffix = $translate.instant('snapshot_PERCENTILE_SUFFIX_ST');
        } else if (vm.mod10BigNumber === 2 && parseInt(vm.bigNumber.value) !== 12) {
            vm.percentileSuffix = $translate.instant('snapshot_PERCENTILE_SUFFIX_ND');
        } else if (vm.mod10BigNumber === 3 && parseInt(vm.bigNumber.value) !== 13) {
            vm.percentileSuffix = $translate.instant('snapshot_PERCENTILE_SUFFIX_RD');
        } else {
            vm.percentileSuffix = $translate.instant('snapshot_PERCENTILE_SUFFIX');
        }

        vm.list = _.get(vm.widget.data, 'dataSets[0]');
        vm.showList = vm.list && !_.isEmpty(vm.list.dataPoints);
    };
}
