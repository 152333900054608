import _ from 'lodash';

widgetCreatorCtrl.$inject = ['WidgetService', 'WidgetVisuals', '$rootScope', '$scope', 'SnapshotService'];

export default function widgetCreatorCtrl(WidgetService, WidgetVisuals, $rootScope, $scope, SnapshotService) {
    this.$onInit = () => {
        const vm = this;

        vm.isNotEmpty = !_.isEmpty(vm.widget.data);

        const filterListener = $rootScope.$on('WIDGET_FILTER', (event, filters) => {
            if (_.isArray(filters)) {
                const parameterValuesXML = SnapshotService.getParameterValuesXML(filters);
                return loadWidget(vm.widget, parameterValuesXML);
            }
            loadWidget(vm.widget, vm.defaultXml);
        });

        const refreshListener = $rootScope.$on('WIDGET_REFRESH', (event, widgetsAvailableID) => {
            if (vm.widget.widgetsAvailableID === widgetsAvailableID) {
                loadWidget(vm.widget, vm.defaultXml, true);
            }
        });

        // clean up listener
        $scope.$on('$destroy', () => {
            filterListener();
            refreshListener();
        });

        if (!vm.isNotEmpty && !vm.doNotLoad) {
            console.info('vm.widget.data is empty, get widget data now');
            loadWidget(vm.widget, vm.defaultXml);
        }

        async function loadWidget(widget, parameterValuesXML, isRefreshCache) {
            delete widget.error;
            delete widget.returnCode;
            widget.loaded = false;
            widget.loading = true;

            if (widget.isLegacy) {
                // <legacy-widget> handles loading display itself
                console.info("not calling node.");
                return;
            }
            try {
                widget.data = await WidgetService.getWidgetData({
                    contactID: vm.contact.userID,
                    widgetTypeCode: vm.widget.code,
                    widgetsAvailableID: vm.widget.widgetsAvailableID,
                    parameterValuesXML: parameterValuesXML,
                    isRefreshCache: isRefreshCache,
                });
            
                switch (widget.widgetVisualCode) {
                    case WidgetVisuals.TOP_THREE_STRENGTHS: {
                        if (!widget.data.length) {
                            return vm.dropWidgetById(widget.widgetsAvailableID, widget.widgetVisualCode);
                        } else {
                            vm.isNotEmpty = true;
                        }
                        break;
                    }
                    case WidgetVisuals.ANNIVERSARY: {
                        if (widget.data.date == null) {
                            vm.dropWidgetById(widget.widgetsAvailableID, widget.widgetVisualCode);
                        } else {
                            vm.isNotEmpty = true;
                        }
                        break;
                    }
                    default: {
                        vm.isNotEmpty = true;
                        break;
                    }
                }
            } catch (err) {
                widget.error = err;
                widget.returnCode = err.data && err.data.returnCode;
                throw err;
            } finally {
                widget.loaded = true;
                widget.loading = false;
            }
        }
    };
}
