import angular from 'angular';

import PointExpiryService from './pointExpiryService.factory';
import pointExpiry from './pointExpiry.component';

export default angular.module('app.account.pointExpiry', [

])
.factory('PointExpiryService', PointExpiryService)
.component('pointExpiry', pointExpiry);
