import _ from 'lodash';
import moment from 'moment';

const ONE_TIME_NOW = 'ONE_TIME_NOW';
const ONE_TIME_LATER = 'ONE_TIME_LATER';

reportFormCtrl.$inject = ['$translate', 'GridColumnTypes', 'ReportsService'];

export default function reportFormCtrl($translate, GridColumnTypes, ReportsService) {
    this.$onInit = () => {
        const vm = this;

        const filters = vm.gridQueryOptions.filters || [];

        vm.model = {
            schedule: ONE_TIME_NOW
        };

        vm.fields = [{
            key: 'report',
            type: 'customInput',
            defaultValue: vm.defaultReportName,
            templateOptions: {
                static: true,
                labelTranslateKey: 'reports_REPORT_FORM_REPORT', // 'Report'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8'
            }
        }, {
            key: 'customization',
            type: 'customInput',
            defaultValue: $translate.instant(
                // '{ number, plural, =0 {None} one {1 filter} other {# filters} }'
                'reports_REPORT_FORM_CUSTOMIZATION_FILTERS_NUMBER', { number: filters.length }, 'messageformat'
            ),
            templateOptions: {
                static: true,
                labelTranslateKey: 'reports_REPORT_FORM_CUSTOMIZATION', // 'Customization'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8'
            }
        }, {
            template: `
                <div class="edit-filters-container">
                    <button ng-click="vm.onEditFilters()"
                            translate="reports_REPORT_FORM_EDIT_FILTERS"
                            class="btn btn-link edit-filters-button"
                            type="button">
                        Edit filters
                    </button>
                </div>`,
            controller: ['$scope', function($scope) {
                $scope.vm = vm;
            }]
        }, {
            key: 'timePeriod',
            type: 'customInput',
            defaultValue: (() => {
                const hasDateFilter = filters.some(filter => {
                    const column = vm.gridColumns.find(column => column.dataField === filter.field);
                    if(!column) {
                        return false;
                    }
                    return column.type === GridColumnTypes.DATE;
                });
                if (hasDateFilter) {
                    return $translate.instant('reports_REPORT_FORM_TIME_PERIOD_CUSTOMIZED'); // 'Customized'
                } else {
                    return $translate.instant('reports_REPORT_FORM_TIME_PERIOD_SINCE_INCEPTION'); // 'Since Inception'
                }
            })(),
            templateOptions: {
                static: true,
                labelTranslateKey: 'reports_REPORT_FORM_TIME_PERIOD', // 'Time Period'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
            }
        }, {
            key: 'schedule', // periodID is set by onChange
            type: 'customSelect',
            defaultValue: ONE_TIME_NOW,
            templateOptions: {
                required: true,
                labelTranslateKey: 'reports_REPORT_FORM_SCHEDULE', // 'Schedule'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
                options: [{
                    name: $translate.instant('reports_REPORT_FORM_SCHEDULE_ONE_TIME_NOW'), // 'One Time - Now'
                    value: ONE_TIME_NOW,
                }, {
                    name: $translate.instant('reports_REPORT_FORM_SCHEDULE_ONE_TIME_LATER'), // 'One Time - Later'
                    value: ONE_TIME_LATER,
                },
                ...ReportsService.getScheduleListOptions(),
                ],
                onChange(value) {
                    if (value === ONE_TIME_NOW) {
                        delete vm.model.periodID;
                        delete vm.model.startDate;
                    } else if (value === ONE_TIME_LATER) {
                        delete vm.model.periodID;
                    } else {
                        vm.model.periodID = value;
                    }
                }
            }
        }, {
            key: 'startDate',
            type: 'customCalendar',
            defaultValue: moment(),
            hideExpression: () => vm.model.schedule === ONE_TIME_NOW,
            templateOptions: {
                required: true,
                labelTranslateKey: 'reports_REPORT_FORM_START_DATE', // 'First report generates on'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
                uibDatepickerOptions: {
                    minDate: moment(),
                },
            },
        }, {
            key: 'reportName',
            type: 'customInput',
            defaultValue: _.startCase(vm.defaultReportName).replace(/\s/g, '-'),
            templateOptions: {
                required: true,
                labelTranslateKey: 'reports_REPORT_FORM_NAME', // 'Name'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8'
            }
        }, {
            key: 'isEmail',
            type: 'customCheckbox',
            templateOptions: {
                labelTranslateKey: 'reports_REPORT_FORM_EMAIL_ONCE_GENERATED' // 'Email me once generated'
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'reports_REPORT_FORM_SUBMIT' // 'Generate Report'
                },
                success: {
                    hidden: true
                }
            }
        };
    };
}
