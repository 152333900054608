import angular from 'angular';

// File from https://gist.github.com/shershen08/b0dcb6b1938c414367a8afa7ff9be23c (Created at: 6-05-2016)
// Terrible for maintainability but angular-masonry's dependencies just refuse to play nice with webpack and webpack-dev-server
// TODO: figure out how to get angular-masonry dependencies to load from npm AND work with webpack and webpack-dev-server
import '../../../../../vendor/angular-masonry/angular-masonry-deps';
import 'angular-masonry';

import '../../../core/core.module';

import spotlightItemsContainerCtrl from './spotlightItemsContainer.controller';

export default angular.module('app.spotlight.spotlightItemsContainer', [
    'wu.masonry', // angular-masonry
    'app.core',
])
.controller('spotlightItemsContainerCtrl', spotlightItemsContainerCtrl);
