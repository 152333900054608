import angular from 'angular';

import '../signOnTasks/signOnTasks.module';
import './inboxGrid/inboxGrid.module';

import inboxConfig from './inbox.config';

export default angular.module('app.inbox', [
    'app.signOnTasks',
    'app.inbox.inboxGrid'
])
.config(inboxConfig);
