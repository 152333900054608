import _ from 'lodash';
import $ from 'jquery';

tableWidgetCtrl.$inject = ['$scope', '$window', 'WidgetDataDisplayTypes'];

export default function tableWidgetCtrl($scope, $window, WidgetDataDisplayTypes) {
    this.$onInit = () => {
        const vm = this;
        const ColumnTypes = {
            DATE: 'DATE'
        };
        // Passed from component bindings:
        // vm.widget
        // vm.contact
        // vm.hasData
        // vm.stats
        // vm.subStats
        // vm.columnHeadings
        // vm.columnValues
        // vm.cellTemplates
        // vm.showFooter

        if (vm.hasData == null) {
            vm.hasData = !_.isEmpty(vm.widget.data)
                && (vm.columnValues == null ? !_.isEmpty(vm.widget.data.columnValues) : !_.isEmpty(vm.columnValues));
        }

        if (!vm.hasData) {
            return;
        }

        _.defaults(vm, {
            stats: vm.widget.data.stats || [],
            subStats: vm.widget.data.subStats || [],
            footers: vm.widget.data.footers || [],
            columnHeadings: vm.widget.data.columnHeadings || [],
            columnValues: vm.widget.data.columnValues || [],
            cellTemplates: {},
            showFooter: true,
            isCardsOnMobile: _.get(vm.widget.data, 'extraJSON.isCardsOnMobile') || false,
            totalValue: vm.widget.data.totalValue || undefined,
            WidgetDataDisplayTypes: WidgetDataDisplayTypes,
            displayTypesArray: [],
        });

        // Watching window resizing changes to show table or cards
        vm.isMobile = $($window).width() < 768 && vm.columnHeadings.length > 3;
        vm.hideStats = vm.isCardsOnMobile && !vm.isMobile;
        vm.hasFootNote = vm.hideStats;
        angular.element($window).bind('resize', () => {
            vm.isMobile = $($window).width() < 768 && vm.columnHeadings.length > 3;
            vm.hideStats = vm.isCardsOnMobile && !vm.isMobile;
            vm.hasFootNote = vm.hideStats;
            $scope.$digest();
        });

        if (vm.isCardsOnMobile) {
            const titleIndex = vm.columnHeadings.findIndex(columnHeanding => columnHeanding.isCardTitle === true);
            const titleExtraIndex = vm.columnHeadings.findIndex(columnHeanding => columnHeanding.isCardTitleExtra === true);
            vm.columnValues.map(value => {
                value.isOpen = _.get(vm.widget.data, 'extraJSON.isCardsOpen') || false;
                value.cardTitle = value[`column${titleIndex + 1}`];
                value.cardTitleExtra = value[`column${titleExtraIndex + 1}`];
                return value;
            });
        }

        vm.ColumnTypes = ColumnTypes;

        $scope.$watch(() => vm.columnHeadings.length, (columnHeadingsLength) => {
            if (!columnHeadingsLength || columnHeadingsLength <= 0) {
                vm.maxColumnValues = 2; // Edge case (probably error)
            }
            vm.maxColumnValues = columnHeadingsLength - 1; // Minus "contact" column
        });
    };
}
