postSubmitRun.$inject = ['$transitions', '$state'];

export default function postSubmitRun($transitions, $state) {

    $transitions.onBefore({ to: 'spotlightPostSubmitPage' }, function(transition) {
        const params = Object.assign({}, transition.params());
        if (!params.from) {
            const fromState = transition.from();
            params.from = VALID_FROM_STATES.includes(fromState.name) ? fromState.name : 'applauseForm';
            return $state.target('spotlightPostSubmitPage', params);
        }
    });
}

const VALID_FROM_STATES = ['applauseForm', 'cultureStoryForm', 'socialPostForm'];
