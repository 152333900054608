import angular from 'angular';

import 'angular-formly';

import './customCalendar/customCalendar.module';
import './customFileUpload/customFileUpload.module';
import './customInput/customInput.module';
import './customRadioInput/customRadioInput.module';
import './customSelect/customSelect.module';
import './customSuggest/customSuggest.module';
import './customTeamName/customTeamName.module';
import './customTextarea/customTextarea.module';
import './customRTE/customRTE.module';
import './customCaptcha/customCaptcha.module';

import './customMultiCheckbox/customMultiCheckbox.module';
import './customMultiSuggest/customMultiSuggest.module';

import formlyConfig from './formly.config';
import formlyRun from './formly.run';

export default angular.module('common.formly', [
    'formly',

    'common.formly.customCalendar',
    'common.formly.customFileUpload',
    'common.formly.customInput',
    'common.formly.customRadioInput',
    'common.formly.customSelect',
    'common.formly.customSuggest',
    'common.formly.customTeamName',
    'common.formly.customTextarea',
    'common.formly.customRTE',
    'common.formly.customCaptcha',

    'common.formly.customMultiCheckbox',
    'common.formly.customMultiSuggest',
])
.config(formlyConfig)
.run(formlyRun);
