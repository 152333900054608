import angular from 'angular';

import '../../core/core.module';

import voucherClaimConfig from './voucherClaim.config';
import voucherClaimCtrl from './voucherClaim.controller';
import VoucherClaimService from './voucherClaimService.factory';

export default angular.module('app.voucherClaim', [
    'app.core'
])
.config(voucherClaimConfig)
.controller('voucherClaimCtrl', voucherClaimCtrl)
.factory('VoucherClaimService', VoucherClaimService);
