import moment from 'moment';

localeRun.$inject = ['LangUtils', 'tmhDynamicLocale', 'amMoment'];

export default function localeRun(LangUtils, tmhDynamicLocale, amMoment) {

    // TODO: wrong assumption - language codes from our DB and standard locale codes are the same
    // Ex. Mandarin (simplified Chinese) is language code zh, but moment has locales zh-hk, zh-tw, zh-cn
    let locale = LangUtils.getLang();

    // Our French-speaking clients want currency and dates in the French-Canadian format, but our db only has "fr".
    if (locale === 'fr') {
        locale = 'fr-ca';
    }

    tmhDynamicLocale.set(locale)
        .catch(function(wrongLocale) {
            logLocaleChangeError('tmhDynamicLocale', wrongLocale);
        });

    const momentLocale = moment.locale(locale);
    if (momentLocale !== locale) {
        logLocaleChangeError('moment', momentLocale);
    }

    const angularMomentLocale = amMoment.changeLocale(locale);
    if (angularMomentLocale !== locale) {
        logLocaleChangeError('angular-moment', angularMomentLocale);
    }

    function logLocaleChangeError(libraryName, wrongLocale) {
        console.warn([
            `Unable to set ${libraryName} locale`,
            `Language code = ${locale}`,
            `${libraryName} locale = ${wrongLocale}`
        ].join('\n'));
    }
}
