import angular from 'angular';

import QuizResultStatusLabelService from './quizResultStatusLabelService.factory';
import quizResultStatusLabel from './quizResultStatusLabel.component';

export default angular.module('app.core.directives.quizResultStatusLabel', [

])
.factory('QuizResultStatusLabelService', QuizResultStatusLabelService)
.component('quizResultStatusLabel', quizResultStatusLabel);
