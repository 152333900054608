import angular from 'angular';

/**
 * Created by Jun on 2016-03-17.
 */

datePickerDynamicLocaleConfig.$inject = ['$provide'];

function datePickerDynamicLocaleConfig($provide) {
    $provide.decorator('uibDatepickerPopupDirective', ['$delegate', function($delegate) {
        angular.forEach($delegate, function (directive) {
            const originalLink = directive.link;

            directive.compile = function () {
                return function (scope) {
                    scope.$on('$localeChangeSuccess', function () {
                        if (scope.date) {
                            scope.dateSelection(scope.date);
                        }
                    });
                    originalLink.apply(this, arguments);
                };
            };
        });
        return $delegate;
    }]);
}

export default datePickerDynamicLocaleConfig;
