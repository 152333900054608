import angular from 'angular';

import '../../../../core/utils/coreUtils.module';
import '../../../../blocks/storage/storage.module';

import applauseDetailsCtrl from './applauseDetails.controller';
import applauseDetails from './applauseDetails.component';

export default angular.module('app.communication.applause.applauseDetails', [
    'app.core.utils',
    'app.blocks.storage'
])
.controller('applauseDetailsCtrl', applauseDetailsCtrl)
.component('applauseDetails', applauseDetails);
