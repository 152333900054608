const constantUtils = require('./constantUtils');

const claimStatuses = {
    CLAIMED: 1,
    DISPUTED: 2,
    UNCLAIMED: 3,
    NOTFOUND: 4
};

const properties = {};

constantUtils.configure({ constants: claimStatuses, properties });

module.exports = claimStatuses;
