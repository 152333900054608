

const _ = require('lodash');

/**
 * Configures a constants object.
 * Freezes values, adds IDs to each properties object,  etc.
 * @param {Object} options
 * @param {Object} options.constants
 * @param {Object} [options.properties]
 * @param {boolean} [options.setPropertyIds]
 * @param {string} [options.propertiesIdKey]
 * @returns {Object} Modified constants
 */
function configure({
    constants,
    properties = {},
    setPropertyIds = true,
    propertiesIdKey = 'ID'
}) {

    const validValues = _.values(constants);

    if (setPropertyIds) {
        validValues.forEach(ID => {
            if (_.isObject(properties[ID])) {
                properties[ID][propertiesIdKey] = ID;
            }
        });
    }

    Object.keys(constants).forEach(key => Object.defineProperty(constants, key, {
        configurable: false
    }));

    setProperty('setProperty', setProperty);
    setProperty('properties', properties);
    setProperty('checkValidValue', (value) => validValues.includes(value));

    return constants;

    // Use this to prevent new properties from being looped over when looping over the constants.
    function setProperty(name, value) {
        Object.defineProperty(constants, name, {
            value,
            enumerable: false
        });
    }
}

module.exports = {
    configure
};
