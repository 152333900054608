socialPostFormPageCtrl.$inject = ['$state'];

export default function socialPostFormPageCtrl($state) {

    const vm = this;

    vm.onSubmitSuccess = function() {
        return $state.go('spotlightPostSubmitPage');
    };
}
