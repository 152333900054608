entityConfig.$inject = ['$stateProvider'];

export default function entityConfig($stateProvider) {
    $stateProvider.state('entity', {
        parent: 'auth',
        url: '/entity',
        data: {
            pageTitle: 'My Entity',
            permission: 'ViewMyEntitySnapshot'
        },
        onEnter: ['$timeout', '$state', 'Session', function($timeout, $state, Session) {
            // Timeout needed because calling $state.go() in an onEnter has problems because some stuff isn't set up yet.
            // https://github.com/angular-ui/ui-router/issues/326
            $timeout(() => {
                const user = Session.getUser();
                const entityID = user.entityID;
                $state.go('snapshot', { contactID: entityID });
            });
        }]
    });
    $stateProvider.state('entity-participant-documents', {
        parent: 'auth',
        url: '/entity/participant-documents',
        data: {
            pageTitle: 'My Entity Participant Documents',
            permission: 'ViewDocumentEntities'
        },
        onEnter: ['$timeout', '$state', 'Session', function($timeout, $state, Session) {
            // Timeout needed because calling $state.go() in an onEnter has problems because some stuff isn't set up yet.
            // https://github.com/angular-ui/ui-router/issues/326
            $timeout(() => {
                const user = Session.getUser();
                const entityID = user.entityID;
                console.log(user);
                $state.go('dashboard', {
                    dashboardCode: 'participant-documents',
                    dashboardID: entityID || 'no-id',
                    reportCode: 'participant-documents'
                });
            });
        }]
    });
}
