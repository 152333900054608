import angular from 'angular';

import '../../core/core.module';
import './navigate/snapshotNavigate.module';
import './set/snapshotSet.module';
import './content/snapshotContent.module';
import './filter/snapshotFilter.module';
import './preview/snapshotPreview.module';

import snapshotUrlConfig from './snapshotUrl.config';
import entityConfig from './entity.config';
import snapshotConfig from './snapshot.config';
import snapshotRun from './snapshot.run';
import snapshotCtrl from './snapshot.controller';
import SnapshotService from './snapshotService.factory';

export default angular.module('app.snapshot', [
    'app.core',
    'app.snapshot.navigate',
    'app.snapshot.set',
    'app.snapshot.content',
    'app.snapshot.filter',
    'app.snapshot.preview',
])
.config(snapshotUrlConfig)
.config(entityConfig)
.config(snapshotConfig)
.run(snapshotRun)
.controller('snapshotCtrl', snapshotCtrl)
.factory('SnapshotService', SnapshotService);
