deleteWarningCtrl.$inject = ['details', '$scope', '$rootScope'];

export default function deleteWarningCtrl(details, $scope, $rootScope) {
    const vm = this;

    vm.details = details;

    vm.ok = function() {
        // Broadcasting a $rootScope event is an awful way of conveying to code using this modal that OK was clicked.
        // ("confirmModal" is an awful event name as well).
        // TODO: refactor code using modal, then remove this event - do this instead: Prompt.open(...).result.then(...).catch(...)
        $rootScope.$broadcast('confirmModal');
        $scope.$close();
    };

    vm.cancel = function() {
        $scope.$dismiss();
    };
}
