// TODO: use only one instance of navCtrl for header, footer, and sidebar. See nav.config.js
// (since sidebar can have both header and footer items, it would prevent duplicate logic and be more flexible)

import $ from 'jquery';

navCtrl.$inject = [
    'navLinks', 'NavService', '$scope', '$transitions', '$state', '$translate', '$timeout', 'StateUtils', 'LangUtils',
    'AuthService', 'AuthStatus', 'AuthStateService', 'AUTH_EVENTS', 'Session', 'SuggestsTypes', 'SkinUtils'
];

export default function navCtrl(
    navLinks, NavService, $scope, $transitions, $state, $translate, $timeout, StateUtils, LangUtils,
    AuthService, AuthStatus, AuthStateService, AUTH_EVENTS, Session, SuggestsTypes, SkinUtils
) {
    const vm = this;

    vm.nav = navLinks;

    vm.mainNavKeys = Object.keys(navLinks).filter(key => key.startsWith('main'));
    vm.menuNavKeys = Object.keys(navLinks).filter(key => key.startsWith('menu'));
    vm.secondaryNavKeys = Object.keys(navLinks).filter(key => key.startsWith('secondary'));
    vm.footerNavKeys = Object.keys(navLinks).filter(key => key.startsWith('footer'));

    vm.SuggestsTypes = SuggestsTypes;
    vm.company = Session.getCompany();
    vm.subDomain = vm.company.subDomain;
    vm.langCode = LangUtils.getLang().toLowerCase();

    if (AuthStatus.isAuthenticated()) {
        onAuthenticated();
    } else {
        onNotAuthenticated();
    }

    $scope.$on(AUTH_EVENTS.LOGIN_SUCCESS, onAuthenticated);

    $scope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, onNotAuthenticated);

    vm.logout = async function() {
        await AuthService.logout();
        return AuthStateService.redirectAfterLogout();
    };

    vm.onSuggestSelect = function(model, item) {
        $state.go('snapshot', { contactID: item.ID });
    };

    // TODO: consider separate auth and no auth nav controllers if this many variables need to change

    function onAuthenticated() {
        vm.isAuthenticated = true;
        vm.nav = NavService.getNavLinks();
        vm.user = Session.getUser();
        vm.user.hasNegativePoints = Number(vm.user.points) < 0;
        vm.spectator = Session.getSpectator();
        vm.skinCode = SkinUtils.getSkinCode();
        vm.isSearchAllowed = vm.user.hasAnyPermission(['ViewSnapshot', 'ViewMyEntitySnapshot']) || vm.company.hasOption('IsGlobalRecognition');
        vm.defaultUiSref = StateUtils.getDefaultStateUiSref();
        NavService.checkGameIsOutstanding(vm.user.playerToken);
    }

    function onNotAuthenticated() {
        vm.isAuthenticated = false;
        vm.nav = NavService.getNavLinks();
        delete vm.user;
        vm.skinCode = SkinUtils.getSkinCode();
        vm.defaultUiSref = 'login';
    }

    // Header
    // ----------------------------------------------------------------------------------------------

    vm.sidebarIsShown = false;
    vm.toggleSidebar = function() {
        vm.sidebarIsShown = !vm.sidebarIsShown;
        if (vm.sidebarIsShown) {
            // Move keyboard focus to sidebar for accessibility
            $timeout(() => {
                const firstItem = $('#sidebar').find('a, button')[0];
                firstItem.focus();
                firstItem.blur();
            });
        }
    };

    vm.showContactPanel = false;
    vm.toggleShowContactPanel = function() {
        vm.showContactPanel = !vm.showContactPanel;
    };

    vm.isSearchInputShown = false;
    vm.toggleSearch = function() {
        vm.isSearchInputShown = !vm.isSearchInputShown;
    };

    vm.closeContactPanel = function() {
        vm.showContactPanel = false;
    };

    $transitions.onStart({}, function() {
        vm.sidebarIsShown = false;
    });

    // Footer
    // ----------------------------------------------------------------------------------------------

    vm.languageOptions = Object.keys(vm.company.languageOptions || {}).map(code => vm.company.languageOptions[code]);
    vm.isChangeLanguageAvailable = vm.company.hasOption('MultiLanguage');
    vm.currentLanguage = vm.langCode.toUpperCase();

    vm.changeLanguage = LangUtils.changeLanguage;

    vm.refreshTranslation = $translate.refresh;


    // Sidebar
    // ---------------------------------------------------------------------------------------------

    $scope.$on(AUTH_EVENTS.WARNING_OPENED, function() {
        vm.sidebarIsShown = false;
    });

    $scope.$on(AUTH_EVENTS.LOCK_APP, function() {
        vm.sidebarIsShown = false;
    });
}
