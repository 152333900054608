GeoService.$inject = ['$http'];

export default function GeoService($http) {
    return {
        getCountryList,
        getProvinceList
    };

    function getCountryList() {
        return $http.get('/api/geo/countries', {
            cache: true
        })
        .then(function(res) {
            return res.data;
        });

    }

    function getProvinceList(countryCode) {
        return $http.get(`/api/geo/countries/${countryCode}/provinces`, {
            cache: true
        })
        .then(function(res) {
            return res.data;
        });
    }
}
