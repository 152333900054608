import _ from 'lodash';

/**
 * @param {GridPageOptions} options
 * @returns {Array}
 */
export default ({
    state: {
        name: stateName,
        config: customStateConfig
    },
    controller,
    template,
}) => (['$stateProvider', function gridPageConfig($stateProvider) {

    // For use with ui-sref
    const urlParamsString = 'filters&columns&sort';

    // For use with $state.go
    const paramsObject = {
        filtersArray: null,
        columnsObject: null,
        sortArray: null,
        columns: {
            dynamic: true
        }
    };

    const finalStateConfig = _.merge({}, customStateConfig, {
        url: customStateConfig.url
            + (customStateConfig.url.includes('?') ? '&' : '?')
            + urlParamsString,
        params: paramsObject,
        views: {
            'main@': {
                template,
                controller,
                controllerAs: 'vm'
            }
        },
        data: {
            navLinkOptions: {
                inherit: false
            }
        },
    });

    $stateProvider.state(stateName, finalStateConfig);
}]);

/**
 * @typedef {GridPageOptions} GridPageConfigOptions
 * @property {string} template - The final template of the state
 * @property {Array | Function} controller - The final controller of the state
 */
