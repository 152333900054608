

const constantUtils = require('./constantUtils');

const PromoTypes = {
    AWARD: 1,
    GAME_WITH_QUESTION: 2,
    GAME_WITHOUT_QUESTION: 3,
    TRAINING: 4,
    CLAIM_BASED: 5,
    DATA_BASED: 6,
    BULK_UPLOAD: 7,
    MARKETING: 8,
    SURVEY: 9,
    SURVEY_GAME: 10
};

const properties = {};

constantUtils.configure({ constants: PromoTypes, properties });

module.exports = PromoTypes;
