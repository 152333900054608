import _ from 'lodash';
import moment from 'moment';
import calculateRangeNumber from '../../../../../../../shared/calculateRangeNumber';

// We want the progress bar to have a max width of 90% to make room for the goal exceeded icon.
// The row with the highest target will have a progress bar with a width of 90%.
// Rows with lower targets will have a progress bar width relative to the size of the largest.
// We want the minimum width of a progress bar to be 10%.

const MAX_PROGRESS_BAR_WIDTH_PERCENT = 90;

// The colour of the progress bar is based on a row's value compared to the Expected Performance value.
// if value is less than 1/3rd of the expected performance --> red
// if value is between 1/3rd and 2/3rds of the expected performance --> orange
// if value is between 2/3rds and below the expected performance --> yellow
// if value is equal or higher than expected performance --> green
// if viewing distributors and a distributor is not qualified --> red
// if viewing distributors and a distributor is qualified --> green

const BELOW_EXPECTED_SECTIONS = 3;

// Limit the margin of the "expected performance" marker so that it doesn't get displayed
// outside the progress bar and doesn't overlay the "target exceeded" icon.
// Use calc to subtract half the width of the icon.

const MAX_EXPECTED_PERFORMANCE_ICON_MARGIN_PERCENT = 100;

expectedPerformanceWithTargetsWidgetCtrl.$inject = ['StringInterpolationService'];

export default function expectedPerformanceWithTargetsWidgetCtrl(StringInterpolationService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.hasData = !_.isEmpty(vm.widget.data) && !_.isEmpty(vm.widget.data.columnValues);
        if (!vm.hasData) {
            return;
        }

        vm.isDistributorView = vm.widget.data.isDistributorView;
        vm.expectedPerformanceRatio = vm.widget.data.expectedPerformanceRatio;
        vm.columnValues = vm.widget.data.columnValues;

        const highestTargetColumnValue = _.maxBy(vm.columnValues, 'target');
        const highestTarget = highestTargetColumnValue.target;

        if (vm.widget.data.expectedPerformanceMessage) {
            vm.expectedPerformanceMessage = StringInterpolationService.interpolate(
                vm.widget.data.expectedPerformanceMessage, {
                    lastCalculatedOn: moment(vm.widget.data.lastCalculatedOn).format('MMM DD, YYYY')
                }
            );
        }

        vm.columnValueTargets = vm.columnValues.filter(columnValue => columnValue.target > 0);
        vm.columnValueTargetsMet = vm.columnValueTargets.filter(columnValue => columnValue.value >= columnValue.target);

        vm.columnValues.forEach(formatColumnValue);

        vm.expectedPerformanceIconMargin = _.clamp(vm.expectedPerformanceRatio * 100, MAX_EXPECTED_PERFORMANCE_ICON_MARGIN_PERCENT);

        vm.cellTemplates = {
            contact: `
                <a ng-bind="columnValue.formattedName"
                ui-sref="snapshot({ contactID: columnValue.contact.ID })"
                uib-tooltip="{{ columnValue.contact.name }}" 
                tooltip-placement="top-left"
                class="contact-name">
                </a>`,
            column1: `
                <div ng-if="columnValue.showProgressBar"
                     class="expected-performance-column-cell">
                    <div ng-style="{ 'width': columnValue.progressBarWidth + '%' }"
                        class="progress-bar-container">
                        <i ng-if="columnValue.target > 0"
                        ng-style="{ 'margin-left': 'calc({{ vm.expectedPerformanceIconMargin + '%' }} - 3px)' }"
                        class="fa fa-caret-down expected-performance-icon"
                        aria-hidden="true">
                        </i>
                        <uib-progressbar value="columnValue.value"
                                        max="columnValue.target"
                                        ng-class="['expected-performance-progress', columnValue.progressBarClass]">
                        </uib-progressbar>
                    </div>
                    <i ng-if="columnValue.isTargetExceeded"
                    ng-class="['fa', 'fa-arrow-right', 'target-exceeded-icon', columnValue.targetExceededIconClass]"
                    aria-hidden="true">
                    </i>
                </div>`,
            column2: `
                <span ng-bind="columnValue.value | formatIfNumber" class="value"></span>
                <span class="value-target-divider">/</span>
                <span ng-bind="columnValue.target | formatIfNumber" class="target"></span>`,
            column3: `<span ng-if="columnValue.pendingApproval" ng-bind="columnValue.pendingApproval | formatIfNumber" class="pending"></span>`
        };

        /**
         * Sets properties onto the columnValue object regarding how it should be displayed.
         */
        function formatColumnValue(columnValue) {
            const formattedName = formatContactName(columnValue.contact.name);
            const showProgressBar = columnValue.target > 0 || columnValue.value > 0;
            if (showProgressBar) {
                const progressBarWidth = determineProgressBarWidth(columnValue);
                const progressBarClass = determineProgressBarClass(columnValue);
                Object.assign(columnValue, {
                    progressBarWidth,
                    progressBarClass
                });
            }
            const isTargetExceeded = columnValue.target > 0 && columnValue.value > columnValue.target;
            if (isTargetExceeded) {
                const targetExceededIconClass = determineTargetExceededIconClass(columnValue);
                Object.assign(columnValue, {
                    targetExceededIconClass
                });
            }
            Object.assign(columnValue, {
                formattedName,
                showProgressBar,
                isTargetExceeded
            });
        }

        function formatContactName(name) {
            const splitName = name.split(' ');
            const firstInitial = splitName[0].charAt(0);
            const lastName = splitName[splitName.length - 1];
            return `${firstInitial}. ${lastName}`;
        }

        function determineProgressBarWidth(columnValue) {
            return columnValue.target / highestTarget * MAX_PROGRESS_BAR_WIDTH_PERCENT;
        }

        function determineProgressBarClass(columnValue) {
            const { value, target } = columnValue;
            if (vm.expectedPerformanceRatio) {
                const expectedPerformanceValue = target * vm.expectedPerformanceRatio;
                const rangeNumber = calculateRangeNumber(expectedPerformanceValue, BELOW_EXPECTED_SECTIONS, value);
                if (rangeNumber > BELOW_EXPECTED_SECTIONS) {
                    return 'hit-target';
                }
                switch (rangeNumber) {
                    case 1:
                        return 'low-target';
                    case 2:
                        return 'quarter-target';
                    case 3:
                        return 'half-target';
                }
            } else if (value >= target) {
                return 'hit-target';
            } else if (value > 0 && value < target) {
                if (value >= target / 2) {
                    return 'half-target';
                }
                return 'quarter-target';
            } else if (value <= 0) {
                return 'low-target';
            }
        }

        function determineTargetExceededIconClass(columnValue) {
            const { isQualified } = columnValue;
            if (isQualified || (columnValue.target > 0 && columnValue.value > columnValue.target)) {
                return 'target-exceeded-icon-green';
            }
            if ([false, 0].includes(isQualified)) {
                return 'target-exceeded-icon-red';
            }
        }
    };
}
