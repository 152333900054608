import angular from 'angular';

import '../../../../../core/core.module';

import reactivateUser from './reactivateUser.component';

export default angular.module('app.contacts.contactPanel.contactPanelTools.reactivateUser', [
    'app.core'
])
.component('reactivateUser', reactivateUser);
