import angular from 'angular';

import '../../../../core/core.module';

import promoGaugeWidget from './promoGaugeWidget.component';

export default angular.module('app.promo.promoGaugeWidget', [
    'app.core',
])
.component('promoGaugeWidget', promoGaugeWidget);
