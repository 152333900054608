import _ from 'lodash';
import $ from 'jquery';
import angular from 'angular';

CoreDirectivesService.$inject = ['$timeout', '$window'];

export default function CoreDirectivesService($timeout, $window) {

    /**
     * For creating a directive that simply executes a callback function on a DOM event
     * (use this to prevent duplicate code)
     * @param eventName {String} - https://developer.mozilla.org/en-US/docs/Web/Events
     * @param directiveName {String}
     */
    function getEventCallbackDirectiveConfig(eventName, directiveName) {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                callback: '<' + directiveName
            },
            link: function(scope, element, attrs, controller) {
                const callback = function(event) {
                    controller.callback(event, element);
                    // $timeout is to prevent "$digest already in progress error" - https://stackoverflow.com/a/18996042
                    $timeout(function() {
                        scope.$parent.$apply();
                    });
                };

                element.on(eventName, callback);

                scope.$on('$destroy', function() {
                    element.off(eventName, callback);
                });
            },
            controller: angular.noop,
            controllerAs: '$ctrl'
        };
    }

    /**
     * Replaces unneeded HTML tags that Angular adds when inserting transclude content into a directive.
     * The tags are replaced with the transclude content.
     * Ex. needed when the transclude contents need to be direct children of a parent due to flexbox layout.
     * Before:
     * ```
     * <div class="flex-container">
     *   <div ng-transclude="aboveChart">
     *      <above-chart>
     *        <p>Actual desired content</p>
     *      </above-chart>
     *   </div>
     * </div>
     * ```
     * After:
     * ```
     * <div class="flex-container">
     *   <p>Actual desired content</p>
     * </div>
     * ```
     * Need to call this inside the directive's link function.
     * @param {jQuery} element - From the directive's link function
     * @param {Function} transcludeFn - From the directive's link function
     * @param {string} [slotName] - camelCase name of the transclude slot, Ex. aboveChart
     * @param {string} [slotTag] - kebab-case name of the transclude tag, Ex. above-chart
     */
    const removeExtraTranscludeTags = (element, transcludeFn, slotName, slotTag) => $timeout(() => {
        // Need $timeout because element.find('[ng-transclude="..."]') doesn't return anything before the timeout
        if (slotName && !transcludeFn.isSlotFilled(slotName)) {
            return;
        }
        const transcludeSlotContainer = element.find(slotName ? `[ng-transclude="${slotName}"]` : '[ng-transclude]');
        const transcludeContentContainer = element.find(slotTag || '[ng-transclude]');
        const transcludeContent = transcludeContentContainer.children();
        transcludeSlotContainer.replaceWith(transcludeContent);
    });

    /**
     * Helper for debouncing a window resize callback and on unregistering it on $destroy.
     * @param {Object} scope
     * @param {Function} callback
     */
    function onWindowResize(scope, callback) {
        const debounced = _.debounce(() => scope.$apply(callback), 400);
        $($window).on('resize', debounced);
        scope.$on('$destroy', () => {
            $($window).off('resize', debounced);
        });
    }

    return {
        getEventCallbackDirectiveConfig,
        removeExtraTranscludeTags,
        onWindowResize,
    };
}
