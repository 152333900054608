import createGridPageConfig from '../../core/directive/rnDxGrid/createGridPageConfig';

export default createGridPageConfig({
    grid: {
        componentName: 'inbox-grid'
    },
    state: {
        name: 'inbox',
        config: {
            parent: 'auth',
            url: '/inbox?messageRecipientID',
            params: {
                messageRecipientID: {
                    dynamic: true
                }
            },
            data: {
                pageTitle: 'My Inbox'
            },
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('inbox');
                }]
            }
        }
    },
    controller: ['MessageService', function inboxPageCtrl(MessageService) {
        MessageService.openFromUrl();
    }],
    template: {
        header: 'inbox_HEADER',
        containerClass: 'inbox-page-container'
    }
});
