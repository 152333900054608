claimCtrl.$inject = ['ClaimDetails', 'ClaimStatuses', 'ClaimService', '$state', '$stateParams', '$translate'];

export default function claimCtrl(ClaimDetails, ClaimStatuses, ClaimService, $state, $stateParams, $translate) {
    const vm = this;
    const companyMetricCode = $stateParams.companyMetricCode;
    vm.reference = $stateParams.reference || null;
    vm.claimReference = vm.reference;

    vm.isClaimed = false;
    vm.isDisputed = false;
    vm.noClaimFound = false;
    vm.claimDetails = ClaimDetails;

    vm.getClaimDetails = getClaimDetails;
    vm.saveClaim = saveClaim;

    if (!vm.claimReference) {
        vm.noClaimFound = true;
        return;
    }

    switch (vm.claimDetails.status) {
        case ClaimStatuses.CLAIMED:
            vm.isClaimed = true;
            vm.claimedReference = vm.claimReference;
            vm.paidoutTxt = `${vm.claimDetails.saleInfo.payouts} ${$translate.instant('claim_SALE_PAIDOUT_TXT',
                { payoutCount: vm.claimDetails.saleInfo.payouts },
                'messageformat')}`;
            vm.pendingTxt = `${vm.claimDetails.saleInfo.pending} ${$translate.instant('claim_SALE_PENDING_TXT',
                { pendingCount: vm.claimDetails.saleInfo.pending },
                'messageformat')}`;
            break;
        case ClaimStatuses.UNCLAIMED:
            vm.isClaimed = false;
            break;
        case ClaimStatuses.DISPUTED:
            vm.isDisputed = true;
            break;
        case ClaimStatuses.NOTFOUND:
            vm.noClaimFound = true;
            break;
    }

    function getClaimDetails(reference) {
        return $state.go('claim', {
            companyMetricCode, reference
        });
    }

    function saveClaim(proof) {
        if (vm.isSuccessfullyClaimed) {
            return;
        }
        return ClaimService.saveClaimSale(companyMetricCode, vm.claimReference, proof).then((res) => {
            vm.isSuccessfullyClaimed = true;
            vm.isClaimSubmitted = true;
        }).catch((err) => {
            vm.isSuccessfullyClaimed = false;
            vm.isClaimSubmitted = true;
        });
    }
}
