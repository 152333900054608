FaqService.$inject = ['$http'];

export default function FaqService($http) {
    return {
        getFaqs
    };

    function getFaqs() {
        return $http.get('/api/faq')
            .then(function(res) {
                return res.data;
            });
    }
}
