import applausesReceivedWidgetTpl from './applausesReceivedWidget.tpl.html';
import applausesReceivedWidgetCtrl from './applausesReceivedWidget.controller';

export default {
    templateUrl: applausesReceivedWidgetTpl,
    controller: applausesReceivedWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
