import _ from 'lodash';

const applauseGuidelineTableTpl = require('./applauseGuidelineTable.tpl.html');

applauseGuidelineTableRun.$inject = ['$translate', 'formlyConfig'];

export default function applauseGuidelineTableRun($translate, formlyConfig) {
    formlyConfig.setType({
        name: 'applauseGuidelineTable',
        templateUrl: applauseGuidelineTableTpl,
        defaultOptions: {
            expressionProperties: {
                'data.isGroup': `model.showGroupField && model.group`,
                'data.isRecipients': `!model.showGroupField && model.recipients.length >= 1`,
                'data.numOfPayouts': function(viewValue, modelValue, scope) {
                    const model = scope.model;
                    if (!model.showGroupField && model.recipients.length) {
                        return model.recipients.length;
                    }

                    if (model.showGroupField && model.group) {
                        return model.group.totalCount;
                    }
                    return 0;
                },
                'data.totalPoints': function(viewValue, modelValue, scope) {
                    const model = scope.model;
                    const options = scope.options;
                    let numberOfPayouts = 0;

                    let pointValue = model.points; // default to points provided by user
                    if (model[options.key] && model[options.key].ID !== -1) {
                        // user has selected a point guideline, use corresponding point value
                        pointValue = model[options.key].points;
                    }
                    
                    if (!pointValue) {
                        // points have not been selected, default to 0
                        return 0;
                    }

                    if (!model.showGroupField && model.recipients.length) {
                        // custom individuals selected
                        numberOfPayouts = model.recipients.length;
                    }

                    if (model.showGroupField && model.group && model.group.totalCount) {
                        // group selected
                        numberOfPayouts = model.group.totalCount;
                    }

                    scope.model.totalPoints = pointValue * numberOfPayouts;
                    return scope.model.totalPoints;

                }
            },
            validation: {
                messages: {
                    overLimit: function() {
                        // Total Points are over the budget limit!
                        return $translate.instant('applauseForm_GUIDELINE_TABLE_VALIDATOR_POINTS_OVER_BUDGET');
                    }
                }
            }
        },
        controller: ['$scope', function($scope) {
            const model = $scope.model;
            const options = $scope.options;

            $scope.copyFields = copyFields;

            if (model.pointGuidelines.pointGuidelineOptions) { // Selecting the first level by default only if there are guidelines
                model[options.key] = model.pointGuidelines.pointGuidelineOptions[0];
            }

            // used to add the templateOptions.fields to the custom selected guideline
            // unsure of how this is working without passing something like to.fields to this function
            function copyFields(fields) {
                fields = _.cloneDeep(fields);
                return fields;
            }

            $scope.$watch('model.budget', function(budgetValue) {
                if ($scope.fc && budgetValue && budgetValue.extra && model[options.key]) {
                    
                    if (model[options.key].ID === -1) {
                        // means the user is adding custom amount, the custom option is added in applause controller
                        if (model.points) {
                            if (!model.showGroupField && model.recipients) {
                                model.totalPoints = model.recipients.length * model.points;
                            } else if (model.showGroupField && model.group) {
                                model.totalPoints = model.group.totalCount * model.points;
                            }
                        }
                        if (budgetValue.extra) {
                            $scope.fc.$setValidity('overLimit', !(model.totalPoints > budgetValue.extra));
                        }
                    } else {
                        if (!model.showGroupField && model.recipients) {
                            model.totalPoints = model.recipients.length * model[options.key].points;
                        } else if (model.showGroupField && model.group) {
                            model.totalPoints = model.group.totalCount * model[options.key].points;
                        }
                        if (budgetValue.extra) {
                            $scope.fc.$setValidity('overLimit', !(model.totalPoints > budgetValue.extra));
                        }
                    }
                }

            });

            $scope.$watch('model.totalPoints', function(totalPoints) {
                if ($scope.fc ){
                    if (model.totalPoints && model.budget && model.budget.extra) {

                        $scope.fc.$setValidity('overLimit', !(model.totalPoints > model.budget.extra));
                    }
                }
            });
   
        }]
    });
}
