import angular from 'angular';

import 'ngstorage';

import '../../core/utils/coreUtils.module';
import '../../features/authentication/auth.module';

import sessionRun from './session.run';
import Session from './session.factory';

export default angular.module('app.blocks.storage', [
    'ngStorage',

    'app.core.utils',
    'app.auth',
])
.run(sessionRun)
.factory('Session', Session);
