import angular from "angular";
import { authenticate, session } from "passport";
import languageModalTpl from './languageModal.tpl.html';
import userSpectatorModalTpl from './userSpectatorModal.tpl.html';

tabRefreshMask.$inject = [
    '$window', '$rootScope', '$translate', 'TabRefreshService', '$http', '$uibModal', 'AuthStatus', 'Session', 
    'LangUtils', '$q', 'LoginAsService', 'AUTH_EVENTS',
];


export default function tabRefreshMask (
    $window, $rootScope, $translate, TabRefreshService, $http, $uibModal, AuthStatus, Session,
    LangUtils, $q, LoginAsService, AUTH_EVENTS
    ) {
    return {
        restrict: 'E',
        template: `<div id="tab-refresh-mask" ng-click="openTabRefreshModal()"></div>`,
        link: async function (scope, element, attrs) {

            // To show tab refresh mask without modal:
            // angular.element('body')[0].style.overflow = 'hidden';
            // element[0].children[0].style.display = 'block';
            // To hide it:
            // angular.element('body')[0].style.overflow = 'auto';
            // element[0].children[0].style.display = 'none';

            function isSameUserSpectator(current, previous) {
                return _.get(current, 'user.userID') === _.get(previous, 'user.userID') 
                && _.get(current, 'spectator.userID') === _.get(previous, 'spectator.userID');
            }

            $window.onfocus = async function () {
                try {
                    if(!AuthStatus.isAuthenticated()) {
                        return;
                    }
                    if($rootScope.tabRefreshModal) {
                        $rootScope.tabRefreshModal.dismiss();
                        $rootScope.tabRefreshModal = false;
                    }
                    const user = Session.getUser();
                    const spectator = Session.getSpectator();
                    const previousUserSpectator = _.cloneDeep({
                        user: {
                            userID: user.userID,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            userName: user.userName,
                        },
                        spectator: spectator
                    });
                    const previousLanguageCode = $translate.use() || $translate.proposedLanguage();
                    const languageOptions = Session.getCompany().languageOptions;
                    const { data: languageCode } = await $http.get('/api/user/language');
                    const { data: userSpectator } = await $http.get('/api/login-as/user');


                    if (isSameUserSpectator(userSpectator, previousUserSpectator) && previousLanguageCode === languageCode) {
                        angular.element('body')[0].style.overflow = 'auto';
                        element[0].children[0].style.display = 'none';
                    }

                    if (!isSameUserSpectator(userSpectator, previousUserSpectator)) {
                        scope.openTabRefreshModal = function() {
                            $rootScope.tabRefreshModal = $uibModal.open({
                                templateUrl: userSpectatorModalTpl,
                                controller: ['$scope', function($scope) {
                                    const vm = this;
            
                                    vm.previousUserSpectator = previousUserSpectator;
                                    vm.currentUserSpectator = userSpectator;
                                }],
                                controllerAs: "vm",
                                backdrop: 'static',
                                keyboard: false,
                                size: 'md'
                            });
                    
                            $rootScope.tabRefreshModal.result.then((result) => {
                                $rootScope.tabRefreshModal = false;
                                TabRefreshService.refresh();
                            }, async (dismiss) => {
                                $rootScope.tabRefreshModal = false;

                                if(dismiss === 'continueAsCurrent') {
                                    try {
                                        if(!previousUserSpectator.spectator) {
                                            await $q.all([
                                                $http.post('/api/login-as/logout', {}, {
                                                    disableLoginAsConfirmModal: true,
                                                    headers:{
                                                        'Logged-In-As-UserID': userSpectator.user.userID
                                                    }
                                                }),
                                                LoginAsService.legacyLoginAsContact(previousUserSpectator.user.userID)
                                            ]);
                                        } else {
                                            await $q.all([
                                                $http.post('/api/login-as/login', {
                                                    contactID: previousUserSpectator.user.userID
                                                }, {
                                                    disableLoginAsConfirmModal: true,
                                                    headers:{
                                                        'Logged-In-As-UserID': userSpectator.user.userID
                                                    }
                                                }),
                                                LoginAsService.legacyLoginAsContact(previousUserSpectator.user.userID)
                                            ]);
                                        }
                                        
                                        $rootScope.userSpectator = _.cloneDeep(previousUserSpectator);
                                    } catch (err) {
                                        throw err;
                                    }
                                }
                            });
                        };
                        if(!$rootScope.tabRefreshModal) {
                            scope.openTabRefreshModal();
                        }
                    } else if (previousLanguageCode !== languageCode) {
                        scope.openTabRefreshModal = function() {
                            $rootScope.tabRefreshModal = $uibModal.open({
                                templateUrl: languageModalTpl,
                                controller: ['$scope', function($scope) {
                                    const vm = this;

                                    vm.languageOptions = languageOptions;
                                    vm.previousLanguageCode = previousLanguageCode;
                                    vm.currentLanguageCode = languageCode;
                                    vm.previousLanguage = languageOptions[previousLanguageCode.toUpperCase()].displayName;
                                    vm.currentLanguage = languageOptions[languageCode.toUpperCase()].displayName;
                                }],
                                controllerAs: "vm",
                                backdrop: 'static',
                                keyboard: false,
                                size: 'md'
                            });
                    
                            $rootScope.tabRefreshModal.result.then((result) => {
                                $rootScope.tabRefreshModal = false;
                                TabRefreshService.refresh();
                            }, async (dismiss) =>  {
                                $rootScope.tabRefreshModal = false;

                                if(dismiss === 'continueAsCurrent') {
                                    try {
                                        await $q.all([
                                            $http.put('/api/user/language', { languageCode: previousLanguageCode }),
                                            LangUtils.legacyChangeLanguage(previousLanguageCode)
                                        ]);
                                    } catch (err) {
                                        throw err;
                                    }
                                }

                            });
                        };
                        if(!$rootScope.tabRefreshModal) {
                            scope.openTabRefreshModal();
                        }
                    }
                } catch (err) {
                    return;
                }
            };
        }
    }
}
