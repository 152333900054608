import widgetCacheFooterTpl from './widgetCacheFooter.tpl.html';
import widgetCacheFooterCtrl from './widgetCacheFooter.controller';

export default function widgetCacheFooter() {
    return {
        restrict: 'E',
        templateUrl: widgetCacheFooterTpl,
        controller: widgetCacheFooterCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            contact: '<',
        }
    };
}
