// Separate config needed because of https://stackoverflow.com/questions/23658846/angular-ui-router-urlrouterprovider-when-not-working-anymore

datafeedUrlConfig.$inject = ['$urlRouterProvider'];

export default function datafeedUrlConfig($urlRouterProvider) {
    // TODO: create shared function for snapshotUrl.config.js, accountUrl.config.js, datafeedUrl.config.js, rewardUrl.config.js

    // When URL is "/datafeed/my-datafeed/contact", go to own contact datafeed
    $urlRouterProvider.when(/^\/datafeed\/(\w+)\/contact$/i, redirect('datafeedContact'));

    // When URL is "/datafeed/my-datafeed/hierarchy", go to own hierarchy datafeed
    $urlRouterProvider.when(/^\/datafeed\/(\w+)\/hierarchy$/i, redirect('datafeedHierarchy'));

    function redirect(stateName) {
        return ['$match', '$state', '$location', function($match, $state, $location) {
            const codeName = $match[1];
            const params = Object.assign($location.search(), { codeName });
            $state.go(stateName, params);
            return true;
        }];
    }
}
