import $ from 'jquery';
import _ from 'lodash';

spotlightFilterCtrl.$inject = [
    'Session', 'Contact', 'moment', 'SpotlightFilterTypes', '$state', '$scope', '$stateParams', '$timeout', '$translate'
];

export default function spotlightFilterCtrl(
    Session, Contact, moment, SpotlightFilterTypes, $state, $scope, $stateParams, $timeout, $translate
) {
    this.$onInit = () => {
        const vm = this;

        const user = Session.getUser();
        const company = Session.getCompany();
        const companyLimiters = Session.getCompany().limiters;

        vm.participantFilterTitle = 'spotlight_PARTICIPANT_DROPDOWN_TITLE';
        vm.recognitionFilterTitle = 'spotlight_RECOGNITION_DROPDOWN_TITLE';
        vm.sentAndReceivedFilterTitle = 'spotlight_SENT_DROPDOWN_TITLE';

        const limiterFilters = companyLimiters.filter(limiter => limiter.isSpotlightFilter)
            .map(limiter => Object.assign({
                displayName: $translate.instant('spotlight_PARTICIPANT_DROPDOWN_OPTION_LIMITERS', { name: limiter.name },
                    'messageformat'),
                queryText: `limiter${limiter.num}`
            }));

        const participantFilters = _.cloneDeep(SpotlightFilterTypes.PARTICIPANT);

        // Setting default model value to undefined for each filter, the model value is only used so that we have access to
        // ng-options. Model values are not being submitted/sent to any kind of api when the filters change, we only update
        // the url and use the querystrings to figure out the display text and selected options
        vm.model = {
            selectedParticipant: undefined,
            selectedRecognition: undefined,
            selectedTimePeriod: undefined,
            selectedSent: undefined
        };

        let participants = [
            participantFilters.allParticipant,
            participantFilters.personal,
            participantFilters.colleagues,
            participantFilters.team,
            participantFilters.extendedTeam,
            participantFilters.circle,
        ];

        participants.forEach(filter => {
            if (filter.queryText === participantFilters.team.queryText) {
                filter.show = user.isTeamLead;
            } else if (filter.queryText === participantFilters.extendedTeam.queryText) {
                filter.show = user.isExtendedTeamLead;
            } else if (filter.queryText === participantFilters.colleagues.queryText) {
                filter.show = false;
            } else {
                filter.show = true;
            }
            filter.displayName = $translate.instant(filter.displayName);
        });

        participants = participants.filter(filter => filter.show);
        participants = participants.concat(limiterFilters);

        vm.hasParticipantQueryString = participants.some(participant => vm.selectedParticipantFilter === participant.queryText);

        const currentParticipantFilter = participants.find(filter => vm.selectedParticipantFilter === filter.queryText);
        // If the default filter was selected manually we want to add a class so that the selected filter text is not bold.
        const isDefaultParticipantFilter = !currentParticipantFilter || currentParticipantFilter.default;
        const isViewAllParticipants = currentParticipantFilter
            && (currentParticipantFilter.queryText === participantFilters.allParticipant.queryText);

        if (!vm.contact) {
            participants = participants.map(filter => {
                if (![participantFilters.allParticipant.queryText, participantFilters.personal.queryText].includes(filter.queryText)
                ) {
                    filter.displayName = $translate.instant('spotlight_PARTICIPANT_DROPDOWN_OPTION_MINE',
                        { name: filter.displayName }, 'messageformat');
                }
                return filter;
            });
        }

        if (vm.contact) {
            const firstName = vm.contact.firstName;
            const lastName = vm.contact.lastName;
            const indexOfAllParticipants = participants.indexOf(participantFilters.allParticipant);
            participants.splice(indexOfAllParticipants, 1);
            participants.forEach(participant => {
                if (participant === participantFilters.circle) {
                    participant.default = false;
                }
                if (participant !== participantFilters.personal) {
                    const firstNameLastCharacter = firstName.charAt(firstName.length - 1);
                    const translatedFirstName = $translate.instant('spotlight_PARTICIPANT_DROPDOWN_OTHER_CONTACT_NAME',
                        { firstName, lastCharacter: firstNameLastCharacter },
                        'messageformat'
                    );
                    // `${translatedFirstName} ${participant.displayName}`;
                    participant.displayName = $translate.instant('spotlight_PARTICIPANT_DROPDOWN_OTHER_CONTACT_NAME_DISPLAY',
                        { translateName: translatedFirstName, filterName: participant.displayName }, 'messageformat');
                } else {
                    participant.displayName = `${firstName} ${lastName}`;
                    participant.default = true;
                }
            });
        }

        vm.participantFilterOptions = participants.map(filter => filter.displayName);

        let selectedParticipantText = '';

        if (vm.selectedParticipantFilter) {
            const selectedParticipant = participants.find(participant => vm.selectedParticipantFilter === participant.queryText);
            selectedParticipantText = selectedParticipant.displayName;
        }

        const recognitionFilters = _.cloneDeep(SpotlightFilterTypes.RECOGNITION);

        let recognition = [
            recognitionFilters.applause,
            recognitionFilters.cultureStory,
            recognitionFilters.certificate,
            recognitionFilters.birthday,
            recognitionFilters.anniversary,
            recognitionFilters.socialPost
        ];

        recognition.forEach(filter => {
            if (filter.queryText === recognitionFilters.applause.queryText) {
                filter.show = company.hasOption('ApplauseModule');
            } else if (filter.queryText === recognitionFilters.cultureStory.queryText) {
                filter.show = company.hasOption('CultureStoryModule');
            } else if (filter.queryText === recognitionFilters.certificate.queryText) {
                filter.show = company.hasOption('CertificatesModule');
            } else if (filter.queryText === recognitionFilters.birthday.queryText) {
                filter.show = company.hasOption('BirthdaysModule');
            } else if (filter.queryText === recognitionFilters.anniversary.queryText) {
                filter.show = company.hasOption('MilestonesModule');
            } else if (filter.queryText === recognitionFilters.socialPost.queryText) {
                filter.show = company.hasOption('SocialPostsModule');
            }
            filter.displayName = $translate.instant(filter.displayName);
        });

        recognition = recognition.filter(filter => filter.show);

        vm.hasRecognitionQueryString = !!vm.selectedRecognitionFilter;

        vm.recognitionFilterOptions = recognition.map(filter => filter.displayName);

        let selectedRecognitionText = [];
        let recognitionQueryStringValues = [];
        if (vm.selectedRecognitionFilter && vm.selectedRecognitionFilter !== true) {
            recognitionQueryStringValues = vm.selectedRecognitionFilter.split('|');
            const selectedRecognitionFilters = recognition.filter(recognition => recognitionQueryStringValues.includes(recognition.queryText));
            if (selectedRecognitionFilters) {
                selectedRecognitionText = selectedRecognitionFilters.map(filter => filter.displayName);
            }
        }

        const sentFilters = _.cloneDeep(SpotlightFilterTypes.SENT);

        const sent = [
            sentFilters.sent_received,
            sentFilters.sent,
            sentFilters.received,
        ];

        sent.forEach(filter => {
            filter.displayName = $translate.instant(filter.displayName);
        });

        vm.hasSentQueryString = sent.some(filter => vm.selectedSentFilter === filter.queryText);
        const currentSentFilter = sent.find(filter => vm.selectedSentFilter === filter.queryText);
        const isDefaultSentFilter = !currentSentFilter || currentSentFilter.default;

        vm.sentReceivedFilterOptions = sent.map(filter => filter.displayName);
        let selectedSentSelectText = '';

        if (vm.selectedSentFilter) {
            const selectedSent = sent.find(sent => vm.selectedSentFilter === sent.queryText);
            selectedSentSelectText = selectedSent.displayName;
        }

        vm.recentDaysOrStartDateInQueryString = vm.selectedRecentDaysFilter && Number(vm.selectedRecentDaysFilter) >= 0
            || vm.selectedStartDateFilter && moment(vm.selectedStartDateFilter).format('YYYY-MM-DD');

        if (vm.recentDaysOrStartDateInQueryString) {
            let daysToSubtract; let
                daysToSubtractDate;
            if (vm.selectedStartDateFilter) {
                const today = moment().format('YYYY-MM-DD');
                daysToSubtract = moment(today).diff(vm.selectedStartDateFilter, 'days');
                daysToSubtractDate = moment(today).subtract(daysToSubtract, 'days').format('MMMM D, YYYY');
            }
            if (Number(vm.selectedRecentDaysFilter) > 0 || daysToSubtract > 0) {
                if (vm.selectedRecentDaysFilter) {
                    vm.model.selectedTimePeriod = $translate.instant('spotlight_FILTER_RECENT_DAYS_QUERY', {
                        number: Number(vm.selectedRecentDaysFilter)
                    }, 'messageformat');
                }
                if (daysToSubtractDate) {
                    vm.model.selectedTimePeriod = $translate.instant('spotlight_FILTER_START_DATE_QUERY')
                        + ' ' + daysToSubtractDate;
                }
            } else if (Number(vm.selectedRecentDaysFilter) === 0 || daysToSubtract === 0) {
                vm.model.selectedTimePeriod = $translate.instant('spotlight_FILTER_RECENT_DAYS_QUERY', {
                    number: Number(vm.selectedRecentDaysFilter) || daysToSubtract
                }, 'messageformat');
            }
        }

        vm.hideTimePeriod = () => {
            if (vm.selectedRecentDaysFilter) {
                updateFilters('recentDays', null);
            }
            if (vm.selectedStartDateFilter) {
                updateFilters('startDate', null);
            }
            vm.recentDaysOrStartDateInQueryString = false;
        };

        vm.onParticipantFilterChange = function(selectedParticipant) {
            const model = selectedParticipant;
            const selectedFilter = participants.find(obj => obj.displayName === model[0]);

            updateFilters('participant', selectedFilter ? selectedFilter.queryText : null);
        };

        vm.onRecognitionFilterChange = function(selectedRecognition) {
            const model = selectedRecognition;
            const selectedFilters = [];
            model.forEach(filter => {
                recognition.filter(obj => {
                    if (obj.displayName === filter) {
                        selectedFilters.push(obj.queryText);
                        return obj.displayName === filter;
                    }
                });
            });

            const reconitionQueryText = selectedFilters.join('|');
            updateFilters('recognition', reconitionQueryText);
        };

        vm.onSentFilterChange = function(selectedSent) {
            const model = selectedSent;
            const selectedFilter = sent.find(obj => obj.displayName === model[0]);
            updateFilters('sent', selectedFilter ? selectedFilter.queryText : null);
        };

        $(document).ready(function(e) {
            $timeout(function() {
                $('.selectpicker').selectpicker('refresh');

                // Initial Selections
                if (!vm.hasRecognitionQueryString) {
                    $('.recognition-select').find('option').each(function () {
                        $(this).attr('selected', 'selected');
                        $('.recognition-select').addClass('default-filter');
                    });
                }

                if (!vm.hasParticipantQueryString) {
                    $('.participant-select > option').each(function () { // finding the case of default option
                        participants.forEach(filter => {
                            if (this.text === filter.displayName) {
                                if (filter.default) {
                                    $(this).attr('selected', 'selected');
                                }
                            }
                        });
                    });
                    $('.participant-select').selectpicker('render');
                }

                if (isDefaultParticipantFilter) {
                    $('.participant-select').addClass('default-filter');
                } else if (!vm.contact && isViewAllParticipants) {
                    vm.isAllParticipantSelected = true;
                }

                $('.sent-select > option').each(function () {
                    sent.forEach(filter => {
                        if (this.text === filter.displayName) {
                            // finding the case of default option
                            if (filter.default) {
                                if (!vm.hasSentQueryString) {
                                    $(this).attr('selected', 'selected');
                                    $('.sent-select').selectpicker('render');
                                }
                            }
                        }
                    });
                });

                if (isDefaultSentFilter) {
                    $('.sent-select').addClass('default-filter');
                }

                // Selection Using Query Strings, unset the default value to make sure this select remains radio
                $('.participant-select > option').each(function () {
                    if (this.text === selectedParticipantText) {
                        $('.participant-select option').prop('selected', false);
                        $('.participant-select').selectpicker('render');
                        this.selected = 'selected';
                        $('.participant-select').selectpicker('render');
                    }
                });

                // Selection Using Query Strings
                $('.recognition-select > option').each(function () {
                    selectedRecognitionText.forEach(filter => {
                        if (this.text === filter) {
                            this.selected = 'selected';
                            $('.recognition-select').selectpicker('render');
                        }
                    });
                });

                // Selection Using Query Strings, unset the default value to make sure this select remains radio
                $('.sent-select > option').each(function () {
                    if (this.text === selectedSentSelectText) {
                        if (this.text !== 'Sent & Received') {
                            $('.sent-select  option').prop('selected', false);
                            $('.sent-select').selectpicker('render');
                            this.selected = 'selected';
                            $('.sent-select').selectpicker('render');
                        } else {
                            $('.sent-select  option').prop('selected', false);
                            $('.sent-select').selectpicker('render');
                            this.selected = 'selected';
                            $('.sent-select').selectpicker('render');
                        }
                    }
                });
            }, 50);
        });

        function updateFilters(filterToChange, filterValue) {
            const params = $stateParams;
            params[filterToChange] = filterValue;

            $timeout(function() {
                $state.go('spotlightItems', params);
            });
        }
    };
}
