import deleteWarningTpl from './deleteWarning/deleteWarning.tpl.html';

Prompt.$inject = ['$uibModal'];

export default function Prompt($uibModal) {
    const openModalByType = {
        warning: openDeleteWarning,
    };

    return {
        open: (details) => openModalByType[details.type](details)
    };

    function openDeleteWarning(details) {
        return $uibModal.open({
            animation: true,
            templateUrl: deleteWarningTpl,
            controller: 'deleteWarningCtrl as vm',
            size: 'sm',
            resolve: {
                details: () => details
            }
        });
    }
}
