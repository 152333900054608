import _ from 'lodash';
import multiCheckboxTpl from './multiCheckbox.tpl.html';

customMultiCheckboxRun.$inject = ['formlyConfig'];

export default function customMultiCheckboxRun(formlyConfig) {

    formlyConfig.setType({
        name: 'customMultiCheckbox',
        templateUrl: multiCheckboxTpl,
        wrapper: ['validation', 'formControl', 'fieldset', 'hasError'],
        apiCheck: check => ({
            templateOptions: {
                options: check.arrayOf(check.object),
                labelProp: check.string.optional,
                labelTranslateKeyProp: check.string.optional,
                valueProp: check.string.optional
            }
        }),
        defaultOptions: {
            noFormControl: false,
            ngModelAttrs: {
                required: {
                    attribute: '',
                    bound: ''
                }
            },
            templateOptions: {
                options: [],
                labelProp: 'name',
                labelTranslateKeyProp: 'translateKey',
                labelTranslateValuesProp: 'translateValues',
                valueProp: 'value',
                checkedCssClass: 'checkbox-pillbox-active'
            }
        },
        controller: ['$scope', function($scope) {
            const to = $scope.to;
            const options = $scope.options;

            if (to.options.some(option => option.isSelected)) {
                $scope.model[options.key] = to.options
                    .filter(option => option.isSelected)
                    .map(option => option[to.valueProp]);
            }

            $scope.multiCheckbox = {
                checked: to.options.map(option => option.isSelected),
                change: setModel,
                isInvalid: isInvalid
            };

            // initialize the checkboxes check property
            $scope.$watch('model', function modelWatcher(newModelValue) {
                if (!Object.keys(newModelValue).length) {
                    return;
                }
                const modelValue = newModelValue[options.key];
                $scope.$watch('to.options', function optionsWatcher(newOptionsValues) {
                    if (_.isArray(newOptionsValues) && _.isArray(modelValue)) {
                        const valueProp = to.valueProp;
                        for (let index = 0; index < newOptionsValues.length; index++) {
                            $scope.multiCheckbox.checked[index] = modelValue.includes(newOptionsValues[index][valueProp]);
                        }
                    }
                });
            }, true);

            if (options.expressionProperties && options.expressionProperties['templateOptions.required']) {
                $scope.$watch(function() {
                    return $scope.to.required;
                }, function(newValue) {
                    checkValidity(newValue);
                });
            }

            if ($scope.to.required) {
                const unwatchFormControl = $scope.$watch('fc', function(newValue) {
                    if (!newValue) {
                        return;
                    }
                    checkValidity(true);
                    unwatchFormControl();
                });
            }

            function checkValidity(expressionValue) {
                if ($scope.to.required) {
                    const valid = !_.isEmpty($scope.model[options.key]) && expressionValue;
                    $scope.fc.$setValidity('required', valid);
                }
            }

            function setModel(index) {
                const someIsChecked = $scope.multiCheckbox.checked.some(isChecked => isChecked);
                if (!someIsChecked) {
                    delete $scope.model[options.key];
                } else {
                    $scope.model[options.key] = $scope.model[options.key] || [];
                    const selectedValue = to.options[index][to.valueProp];
                    if ($scope.multiCheckbox.checked[index]) {
                        $scope.model[options.key].push(selectedValue);
                    } else {
                        const modelIndex = $scope.model[options.key].findIndex(value => _.isEqual(value, selectedValue));
                        $scope.model[options.key].splice(modelIndex, 1);
                    }
                }
                checkValidity(true);

                // Must make sure we mark as touched because only the last checkbox due to a bug in angular.
                $scope.fc.$setTouched();

                if (to.maxNumOfStr) {
                    const values = $scope.model[options.key] || [];
                    $scope.options.data.listOfInvalidValues = values.slice(to.maxNumOfStr);
                    $scope.fc.$setValidity('maxNumOfStrengths', (values.length <= to.maxNumOfStr));
                }
            }

            function isInvalid(index) {
                const listOfInvalidValues = options.data.listOfInvalidValues || [];
                return listOfInvalidValues.includes(to.options[index][to.valueProp]);
            }
        }]
    });
}
