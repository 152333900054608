promoQuizAddNoteCtrl.$inject = ['$translate', 'PromoQuizService', 'QuizResultStatuses'];

export default function promoQuizAddNoteCtrl($translate, PromoQuizService, QuizResultStatuses) {
    this.$onInit = () => {
        const vm = this;

        // TODO: find way to un-duplicate the code below with promoQuizSection.controller.js

        const sectionID = vm.section.sectionID;
        const quizResultID = vm.promoDetails.results.quizResultID;

        vm.noteFields = [{
            key: 'note',
            type: 'customTextarea',
            templateOptions: {
                required: true,
                maxlength: 500,
                placeholderTranslateKey: vm.isApprover
                    ? 'app_PROMO_QUIZ_SECTION_ADD_NOTE_PLACEHOLDER_ADMIN' // 'Add a note for the participant regarding this submission'
                    : 'app_PROMO_QUIZ_SECTION_ADD_NOTE_PLACEHOLDER', // 'Add a note for a program administrator'
                controlClass: 'promo-submission-note-field',
                resetAfterSubmit: true
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_ADD_NOTE_SUBMIT_BUTTON' // Submit note
                }
            }
        };

        vm.submitNote = function() {
            return PromoQuizService.addNote({
                sectionID,
                quizResultID,
                note: vm.noteModel.note,
                isBackToClient: false,
                isApprover: vm.isApprover
            });
        };
    };
}
