import pointExpiryTableTpl from './pointExpiryTable.tpl.html';
import pointExpiryTableCtrl from './pointExpiryTable.controller';

export default {
    templateUrl: pointExpiryTableTpl,
    controller: pointExpiryTableCtrl,
    controllerAs: 'vm',
    bindings: {
        pointExpirationDetails: '<'
    }
};
