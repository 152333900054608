import promoQuizValidationTpl from './validation.tpl.html';
import promoQuizValidationCtrl from './validation.controller';

export default {
    templateUrl: promoQuizValidationTpl,
    controller: promoQuizValidationCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        formModel: '<',
        isApprover: '<',
        sectionResults: '<',
        section: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<',
        hideToolsByValidation: '=',
    }
};
