import angular from 'angular';

import promoQuizSection from './promoQuizSection.component';
import promoQuizSectionService from './promoQuizSectionService.factory';

export default angular.module('app.promo.promoQuiz.section', [

])
    .factory('PromoQuizSectionService', promoQuizSectionService)
    .component('promoQuizSection', promoQuizSection);
