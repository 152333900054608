gameSSOConfig.$inject = ['$stateProvider'];

export default function gameSSOConfig($stateProvider) {
    $stateProvider.state('gameSSO', {
        parent: 'auth',
        url: '/gameSSO?redirectUrl&subview',
        views: {
            'main@': {
                controller: 'gameSSOCtrl as vm',
            }
        },
        data: {
            pageTitle: 'GameSSO'
        },
        resolve: {
            ssoToken: ['$http', 'Session', async ($http, Session) => {
                const user = Session.getUser();
                try {
                    const { data } = await $http.get(`api/iziigameSSO/get/${user.userID}`);
                    return data.token;
                } catch (err) {
                    console.error(err);
                    return null;
                }
            }]
        }
    });
}
