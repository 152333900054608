import angular from 'angular';

import registrationForm from './registrationForm.component';
import registrationFormCtrl from './registrationForm.controller';
import registrationPageCtrl from './registrationPage.controller';
import RegistrationService from './registrationService.factory';
import registrationConfig from './registration.config';

import '../../core/core.module';
import '../../../common/formly/formly.module';

export default angular.module('app.registration', [
    'app.core',
    'common.formly'
])
.config(registrationConfig)
.component('registrationForm', registrationForm)
.controller('registrationFormCtrl', registrationFormCtrl)
.controller('registrationPageCtrl', registrationPageCtrl)
.factory('RegistrationService', RegistrationService);
