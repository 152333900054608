import angular from 'angular';

import '../../../../core/core.module';

import promoLeaderboardWidget from './promoLeaderboardWidget.component';

export default angular.module('app.promo.promoLeaderboardWidget', [
    'app.core'
])
.component('promoLeaderboardWidget', promoLeaderboardWidget);
