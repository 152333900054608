const applauseCultureStoryRTEWrapperTpl = require('./applauseCultureStoryRTEWrapper.tpl.html');

applauseCultureStoryTextareaRun.$inject = ['formlyConfig'];

function applauseCultureStoryTextareaRun(formlyConfig) {
    formlyConfig.setWrapper({
        name: 'applauseCultureStoryRTEWrapper',
        templateUrl: applauseCultureStoryRTEWrapperTpl
    });

    formlyConfig.setType({
        name: 'applauseCultureStoryRTE',
        extends: 'customRTE',
        wrapper: ['applauseCultureStoryRTEWrapper'],
        defaultOptions: {
            modelOptions: {
                debounce: {
                    default: 1000,
                    blur: 0
                },
                updateOn: 'default blur'
            },
            templateOptions: {
                labelSrOnly: true,
                tinymceOptions: {
                    contextmenu:'',
                    label: 'cultureStory',
                    inline: false,
                    elementpath: false,
                    height: 150,
                    wordcount_cleanregex: /[0-9.(),;:!?%#$?\x27\x22_+=\\/\-]*/g,
                    wordcount_countregex: /[\w\u2019\x27\-\u00C0-\u1FFF]+/g,
                    menubar: false,
                    toolbar1: 'undo redo | bold italic underline | fontselect fontsizeselect',
                }
            }
        }
    });
}

export default applauseCultureStoryTextareaRun;
