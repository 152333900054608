import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class AdminTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$state = $injector.get('$state');

        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_ADMIN_TITLE', // 'Pending Tasks'
            acceptButtonText: 'app_SIGN_ON_TASKS_ADMIN_ACCEPT_BUTTON_TEXT', // 'Go to Control Panel'
            taskModalOptions: {
                animateFooter: false
            }
        });
    }

    get componentHtml() {
        return `
            <p translate="app_SIGN_ON_TASKS_ADMIN_CONTENT">
                You have administrative tasks to complete and information to review.
            </p>`;
    }

    submit() {
        // Admin task is a special case, updated only upon leaving the control panel.
        // So on submit, don't remove from SignOnTasks list yet.
        return this.goToControlPanel();
    }

    onTaskbarItemClicked() {
        return this.goToControlPanel();
    }

    goToControlPanel() {
        // "reload: true" so that if user is already on control panel, the state change will be forced to happen again
        return this.$state.go('controlPanel', { tab: 'todo' }, { reload: true });
    }
}
