import navLinkTpl from './navLink.tpl.html';

navLink.$inject = [];

export default function navLink() {
    return {
        templateUrl: navLinkTpl,
        controller: 'navLinkCtrl as vm',
        restrict: 'EA',
        scope: {},
        bindToController: {
            navItem: '<',
            parentClass: '@',
        }
    };
}
