

const constantUtils = require('./constantUtils');

const SpotlightFilterTypes = {
    PARTICIPANT: {
        allParticipant: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_ALL_PARTICIPANTS`,
            queryText: 'allParticipants',
            default: false,
        },
        personal: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_PERSONAL_MINE`,
            queryText: 'personal',
            default: false,
        },
        colleagues: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_COLLEAGUES`,
            queryText: 'colleagues',
            default: false,
        },
        team: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_TEAM`,
            queryText: 'team',
            default: false,
        },
        extendedTeam: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_EXTENDED_TEAM`,
            queryText: 'extendedTeam',
            default: false,
        },
        circle: {
            displayName: `spotlight_PARTICIPANT_DROPDOWN_OPTION_CIRCLE`,
            queryText: 'circle',
            default: true,
        },
    },
    RECOGNITION: {
        applause: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_APPLAUSE`,
            queryText: 'applause',
        },
        cultureStory: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_CULTURE_STORY`,
            queryText: 'cultureStory',
        },
        certificate: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_CERTIFICATE`,
            queryText: 'certificate',
        },
        birthday: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_BIRTHDAY`,
            queryText: 'birthday',
        },
        anniversary: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_ANNIVERSARY`,
            queryText: 'anniversary',
        },
        socialPost: {
            displayName: `spotlight_RECOGNITION_DROPDOWN_OPTION_SOCIAL_POST`,
            queryText: 'socialPost',
        },
    },
    SENT: {
        sent_received: {
            displayName: `spotlight_SENT_DROPDOWN_OPTION_SENT_AND_RECEIVED`,
            queryText: 'sentAndReceived',
            default: true,
        },
        sent: {
            displayName: `spotlight_SENT_DROPDOWN_OPTION_SENT`,
            queryText: 'sent',
            default: false,
        },
        received: {
            displayName: `spotlight_SENT_DROPDOWN_OPTION_RECEIVED`,
            queryText: 'received',
            default: false,
        },
    },
};

const properties = {};

constantUtils.configure({ constants: SpotlightFilterTypes, properties });

module.exports = SpotlightFilterTypes;
