datepickerCtrl.$inject = ['DatepickerService'];

// dt = actual Date object
// prettydate = just for display
// selectedDate = to send to db

export default function datepickerCtrl(DatepickerService) {

    const datepickerVm = this;

    datepickerVm.today = function() {
        datepickerVm.dt = new Date();
        setSelectedDate();
    };
    datepickerVm.today();

    datepickerVm.clear = function() {
        datepickerVm.dt = null;
    };

    setSelectedDate();

    datepickerVm.options = {
        customClass: getDayClass,
        minDate: new Date(),
        showWeeks: true
    };

    // Disable weekend selection
    // function disabled(data) {
    //     const date = data.date;
    //     const mode = data.mode;
    //     return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    // }

    datepickerVm.toggleMin = function() {
        datepickerVm.options.minDate = datepickerVm.options.minDate ? null : new Date();
    };
    datepickerVm.toggleMin();

    datepickerVm.dateChanged = function() {
        setSelectedDate();
        console.log('Selected Date: ' + datepickerVm.selectedDate);
    };

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const afterTomorrow = new Date(tomorrow);
    afterTomorrow.setDate(tomorrow.getDate() + 1);

    datepickerVm.events = [
        {
            date: tomorrow,
            status: 'full'
        }, {
            date: afterTomorrow,
            status: 'partially'
        }
    ];

    function getDayClass(data) {
        const date = data.date;
        const mode = data.mode;

        if (mode === 'day') {
            const dayToCheck = new Date(date).setHours(0, 0, 0, 0);

            for (let i = 0; i < datepickerVm.events.length; i++) {
                const currentDay = new Date(datepickerVm.events[i].date).setHours(0, 0, 0, 0);

                if (dayToCheck === currentDay) {
                    return datepickerVm.events[i].status;
                }
            }
        }

        return '';
    }

    // For the template
    datepickerVm.setDate = function(year, month, day) {
        datepickerVm.dt = new Date(year, month, day);
    };

    // For the service
    function setSelectedDate() {
        datepickerVm.prettydate = datepickerVm.dt.toDateString().substring(4);
        datepickerVm.selectedDate = datepickerVm.dt.toISOString().substring(0, 10);
        DatepickerService.setValue(datepickerVm.selectedDate);
    }
}
