import _ from 'lodash';

applauseCtrl.$inject = [
    '$scope', '$state', '$window', '$translate', '$timeout', 'PreviewModalService',
    'ApplauseService', 'Session', 'applause', 'contact', 'SuggestsTypes'
];

export default function applauseCtrl(
    $scope, $state, $window, $translate, $timeout, PreviewModalService,
    ApplauseService, Session, applause, contact, SuggestsTypes
) {
    const vm = this;

    if (!applause.applauseHeadersByCategories || applause.applauseHeadersByCategories.length <= 0) {
        vm.errorMessage = 'applauseForm_ERROR_UNAVAILABLE_NO_HEADERS';
        return;
    }

    const strengths = applause.strengths || [];

    // Shuffling the categories and headers
    const applauseHeadersByCategories = applause.applauseHeadersByCategories.map(category => {
        const applauseHeaders = category.applauseHeaders ? _.shuffle(category.applauseHeaders) : undefined;
        return Object.assign({}, category, {
            applauseHeaders
        });
    });

    const definedCategoryID = applauseHeadersByCategories
                                .findIndex((category) => category.ID.toString() === $state.params.messageCategoryID);
    let categoryID;
    if (definedCategoryID !== -1) {
        categoryID = definedCategoryID;
    } else {
        categoryID = Math.floor(Math.random() * applauseHeadersByCategories.length);
    }

    // if no pointGuidelines exist, pointGuidelines will be equal to { errorID: 1 }
    if (applause.pointGuidelines.pointGuidelineOptions) {
        if (!applause.pointGuidelines.isGuidelineEnforced) {
            // when no pointguidelines enforced , add custom pointguidelines
            applause.pointGuidelines.pointGuidelineOptions.push({
                ID: -1,
                name: 'Custom',
                points: undefined
            });
        }
    }

    const pointGuidelines = applause.pointGuidelines;

    const user = Session.getUser();
    const company = Session.getCompany();

    const cultureStory = Object.assign({}, company.cultureStory);
    const initialRTEValue = ApplauseService.getInitialApplauseRTEValue();

    vm.userCanApplaudPoints = user.hasAnyPermission(['ApplaudPoints', 'ApplauseOutsideAccessPoints']);
    vm.userCanTeamApplaudPoints = user.hasPermission('TeamApplaudPoints');
    vm.userCanViewGroup = user.hasPermission('ViewGroups');

    vm.companyHasGlobalRecognition = company.hasOption('IsGlobalRecognition');
    vm.companyHasApplauseStrength = company.hasOption('ApplauseStrengths');

    vm.isCultureStory = $state.current.data.isCultureStory;

    vm.model = {};
    vm.model.recipients = []; // selected recipient will be pushed to this array
    // part of the model so that teamName formly field will know when the value changes
    vm.model.showGroupField = !!$state.params.group;
    vm.model.totalPoints = null;
    vm.model.pointGuidelines = pointGuidelines;
    vm.model.tinymceModel = $state.params.message;

    if (contact) {
        vm.model.recipients = [{
            ID: contact.userID,
            phrase: `${contact.firstName} ${contact.lastName}`
        }];
    } else if ($state.params.group) {
        vm.model.group = [$state.params.group];
        vm.model.teamName = $state.params.teamName;
    } else if ($state.params.recipients) {
        vm.model.recipients = $state.params.recipients;
        vm.model.teamName = $state.params.teamName;
    }

    vm.selectedCategory = applauseHeadersByCategories[categoryID];

    vm.changeHeader = changeHeader;
    vm.previewForm = previewForm;

    // Moving from Applause form to Culture Story Form
    vm.switchType = () => {
        // 'Your progress will be lost. Continue?'
        const ok = $window.confirm($translate.instant('applauseForm_SWITCH_CONFIRM_CONTINUE'));
        if (!ok) {
            return;
        }
        const nextState = vm.isCultureStory ? 'applauseForm' : 'cultureStoryForm';
        return $state.go(nextState);
    };

    // used to toggle showing colleague vs group field
    vm.toggleShowGroupField = function () {
        vm.model.showGroupField = !vm.model.showGroupField;

        // TODO: why do we need to manually run the hideExpressions? might be a simpler solution available
        vm.fields
            .filter(field => field.hideExpression)
            .forEach(field => field.hide = field.hideExpression);
    };

    const applauseRteField = {
        key: 'tinymceModel',
        type: 'applauseRTE',
        templateOptions: {
            required: true,
            tinymceOptions: {
                setup: function (editor) {
                    editor.on('click', function (e) {
                        if (e.target.innerHTML === initialRTEValue) {
                            editor.selection.select(e.target);
                        } else if (e.target.children[0] && e.target.children[0].innerHTML === initialRTEValue) {
                            editor.selection.select(e.target.children[0]);
                        }
                    });
                }
            }
        }
    };

    const cultureStoryRteField = {
        wrapper: 'multiControlFormWrapper',
        fieldGroup: [{
            className: 'applause-animate-if',
            type: 'applauseCultureStory',
            key: 'cultureStory',
            hideExpression: 'formState.cultureStoryStage !== 1',
            templateOptions: {
                fields: [{
                    key: 'story',
                    type: 'applauseCultureStoryRTE'
                }]
            }
        }, {
            className: 'applause-animate-if',
            type: 'customRTE',
            key: 'concatenatedContent',
            wrapper: ['applauseCSConcatRTEWrapper'],
            hideExpression: 'formState.cultureStoryStage !== 2',
            templateOptions: {},
            controller: ['$scope', function($scope) {
                $scope.$watch('model[options.key]', function(newContent) {

                    let i; let content; let subContent; let
                        subContentStripedNewline;

                    if (newContent) {
                        // The concatenated content of individual story fields comes full of tags and newline character ;
                        // This code strips off these tags for  final RTE content, separated by a newline

                        content = newContent.split('<div id="CSRTE_');
                        for (i = 1; i < content.length; i++) {
                            subContentStripedNewline = '';
                            subContent = content[i].split('_section">');
                            // strip all the newline characters
                            subContent[1].split('\n').forEach(function(entry) {
                                subContentStripedNewline += entry;
                            });
                            // strip out last </div> tag
                            $scope.model.cultureStory[subContent[0]].story = subContentStripedNewline.slice(0, -6);
                        }
                    }
                });
            }]
        }]
    };

    // Formstate - used by culture story formly fields
    vm.options = {
        formState: {
            CSBuilder: cultureStory,
            cultureStoryStage: 1,
            // Fill in at least one section please
            cultureStoryBuilderError: 'applauseForm_ERROR_NO_CULTURE_STORY_SECTIONS_FILLED',
        }
    };

    vm.fields = [{
        wrapper: 'multiControlFormWrapper',
        fieldGroup: [{
            key: 'recipient',
            type: 'customMultiSuggest',
            templateOptions: {
                type: vm.companyHasGlobalRecognition
                    ? SuggestsTypes.ANY_COLLEAGUE
                    : SuggestsTypes.USER_ACCESSIBLE_EXCLUDING_ME,
                placeholder: $translate.instant('app_CUSTOM_MULTI_SUGGEST_RECIPIENTS_PLACEHOLDER'),
                labelClass: 'text-left col-sm-3 col-md-2 col-lg-2 ',
                controlClass: 'col-sm-9 col-md-10 col-lg-10 text-left',
                labelTranslateKey: 'applauseForm_RAVE_ABOUT_LABEL',
                required: !vm.model.showGroupField
            },
            expressionProperties: {
                'data.hideValidation': '!fc.$submitted'
            },
            data: {
                hideValidation: true,
                multiKey: 'recipients'
            },
            hideExpression: () => vm.model.showGroupField
        }, {
            key: 'group',
            type: 'customSuggest',
            templateOptions: {
                type: SuggestsTypes.MY_GROUPS,
                placeholder: 'Enter Group Name', // need to change existing phrase id:13356 'applauseForm_SUGGEST_GROUP_PLACEHOLDER'
                label: 'Group Name', // New to add a new Phrase 'applauseForm_SUGGEST_GROUP_LABEL'
                labelClass: 'text-left col-sm-3 col-md-2 col-lg-2',
                controlClass: 'col-sm-9 col-md-10 col-lg-10',
                resetAfterSubmit: true,
                totalCountKey: 'totalCount',
            },
            expressionProperties: {
                'templateOptions.required': function() {
                    return vm.model.showGroupField;
                }
            },
            hideExpression: () => !vm.model.showGroupField
        }, {
            template: `
                    <button ng-click="vm.toggleShowGroupField()"
                            translate="{{ vm.model.showGroupField ? 'app_CUSTOM_MULTI_SUGGEST_NO_GROUPS' : 'app_CUSTOM_MULTI_SUGGEST_GROUPS'}}"
                            class="btn btn-link btn-padding "
                            type="button">
                    </button>`,
            controller: ['$scope', function ($scope) {
                $scope.vm = vm;
            }],
            hideExpression: function () {
                return !vm.userCanViewGroup;
            }
        }, {
            key: 'teamName',
            type: 'customTeamName',
            templateOptions: {
                labelTranslateKey: 'applauseForm_PREVIEW_TEAM_NAME_LABEL', // Team Name
                placeholderTranslateKey: 'applauseForm_PREVIEW_TEAM_NAME_PLACEHOLDER', // Give your team a name
                labelClass: 'text-left col-sm-3 col-md-2 col-lg-2',
                controlClass: 'col-sm-9 col-md-10 col-lg-10',
                required: true
            },
            data: {
                groupKey: 'group',
                multiKey: 'recipients',
                showGroupTeamNameKey: 'showGroupField'
            },
            hideExpression: function () {
                if (vm.model.showGroupField && _.isEmpty(vm.model.group)) {
                    return true;
                } else if (!vm.model.showGroupField && vm.model.recipients.length < 2) {
                    return true;
                }
            }
        }, {
            key: 'categoryID',
            type: 'customSelect',
            defaultValue: vm.selectedCategory.ID,
            templateOptions: {
                options: applauseHeadersByCategories,
                onChange: function updateSelectedCategory(selectedCategoryID) {
                    vm.selectedCategory = applauseHeadersByCategories.find(category => selectedCategoryID === category.ID);
                },
                labelTranslateKey: 'applauseForm_PREVIEW_RECOGNIZED_FOR_LABEL', // Recognized For
                labelClass: 'text-left col-sm-3 col-md-2 col-lg-2',
                controlClass: 'col-sm-9 col-md-10 col-lg-10',
                required: true,
                valueProp: 'ID'
            }
        }, {
            template:
                `<div ng-if="vm.selectedCategory.description" ng-bind-html="vm.selectedCategory.description"></div>`,
            controller: ['$scope', function ($scope) {
                $scope.vm = vm;
            }]
        }]
    }, {
        wrapper: 'multiControlFormWrapper',
        className: 'culture-story-rte-field',
        fieldGroup: [{
            template: `
                <div class="form-group">
                    <div class="col-sm-12">
                        <h4 translate="applauseForm_CHOOSE_STYLE_HEADER">
                            Style and Message 
                        </h4>
                        <div class="carousel-animate-fade">
                            <uib-carousel class="carousel carousel-responsive-height" no-wrap="true" active="vm.headerIndex"
                                          on-carousel-change="vm.changeHeader(nextSlide, direction, nextIndex)">
                                <uib-slide ng-repeat="header in vm.selectedCategory.applauseHeaders" index="$index">
                                    <img ng-src="{{ header.URL }}" class="carousel_scale">
                                </uib-slide>
                            </uib-carousel>  
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>`,
            controller: ['$scope', function ($scope) {
                $scope.vm = vm;
            }]
        },
        // use correct RTE based on what form the user is looking at
        vm.isCultureStory ? cultureStoryRteField : applauseRteField
        ]
    }, {
        key: 'strengths',
        type: 'applauseMultiCheckbox',
        hide: !vm.companyHasApplauseStrength,
        templateOptions: {
            labelClass: 'col-sm-3 col-md-2 col-lg-2',
            controlClass: 'col-sm-9 col-md-10 col-lg-10',
            options: strengths,
            labelProp: 'name',
            valueProp: 'ID'
        }
    }, {
        key: 'isAddingPoints',
        type: 'customCheckbox',
        hideExpression: function () {
            let applauseType;
            if (vm.model.showGroupField && vm.model.group && vm.model.group.totalCount) {
                applauseType = 'group';
            } else if (!vm.model.showGroupField && vm.model.recipients.length > 1) {
                applauseType = 'recipients';
            }
            const isNominatingTeam = ['recipients', 'group'].includes(applauseType);
            if (pointGuidelines) {
                const canGivePoints = isNominatingTeam ? vm.userCanTeamApplaudPoints : vm.userCanApplaudPoints;
                return !canGivePoints || (
                    !!(pointGuidelines.isPayoutMandatory && pointGuidelines.pointGuidelineOptions)
                );
            }
        },
        templateOptions: {
            labelTranslateKey: 'applauseForm_INCLUDE_POINTS_LABEL',
            labelClass: 'col-sm-12'
        }
    }, {
        wrapper: 'multiControlFormWrapper',
        hideExpression: function () {
            return !(pointGuidelines.isPayoutMandatory || vm.model.isAddingPoints);
        },
        fieldGroup: [{
            className: 'col-sm-12',
            key: 'budget',
            type: 'applauseBudgetSuggest',
            templateOptions: {
                labelClass: 'col-sm-2',
                controlClass: 'col-sm-10 input-container',
                labelTranslateKey: 'applauseForm_PREVIEW_CHOOSE_BUDGET_LABEL', // Budget
                placeholderDataKey: 'PREVIEW_CHOOSE_BUDGET_PLACEHOLDER' // Pick your budget here
            },
            expressionProperties: {
                'data.hideValidation': '!fc.$submitted',
                'templateOptions.required': () => pointGuidelines.isPayoutMandatory || vm.model.isAddingPoints
            },
            data: {
                hideValidation: true
            }
        }, {
            className: 'col-sm-12',
            type: 'applauseGuidelineTable',
            key: 'selectedGuideline',
            templateOptions: {
                fields: [{
                    key: 'points',
                    type: 'applausePointsInput',
                    hideExpression: function () {
                        return !(vm.model.pointGuidelines.isPayoutMandatory || vm.model.isAddingPoints);
                    },
                    templateOptions: {
                        type: 'text',
                        filterType: 'number',
                        labelTranslateKey: 'applauseForm_POINT_BUDGET_LABEL',
                        placeholderTranslateKey: 'applauseForm_POINT_BUDGET_PLACEHOLDER'
                    },
                    data: {
                        noFormGroupClass: true
                    },
                    expressionProperties: {
                        'templateOptions.required': () => {
                            const noGuidelinesOptions = !pointGuidelines.pointGuidelineOptions;
                            const customGuidelineSelected = vm.model.selectedGuideline
                                && vm.model.selectedGuideline.ID === -1;
                            return noGuidelinesOptions || customGuidelineSelected;
                        }
                    },
                }]
            }
        }]
    }];

    function previewForm() {
        vm.form.$submitted = true;
        if (!vm.form.$valid) {
            return;
        }

        if (vm.model.group && vm.model.showGroupField === true && vm.model.group.totalCount >= 1) {
            vm.applauseType = 'group';
        } else if (vm.model.recipients && vm.model.showGroupField === false && vm.model.recipients.length >= 1) {
            vm.applauseType = 'recipients';
        }

        PreviewModalService.openPreview(vm.model)
            .result
            .then(async function () {
                await ApplauseService.sendApplause({
                    MessageTemplateID: vm.model.selectedHeader.ID,
                    budgetID: vm.model.budget && vm.model.budget.ID ? vm.model.budget.ID : null,
                    group: vm.model.group,
                    recipients: vm.model.recipients,
                    points: vm.model.points,
                    selectedGuideline: vm.model.selectedGuideline,
                    strengths: vm.model.strengths,
                    teamName: vm.model.teamName,
                    tinymceModel: vm.model.tinymceModel,
                    concatenatedContent: vm.model.concatenatedContent,
                    applauseType: vm.applauseType
                });
                return $state.go('spotlightPostSubmitPage');
            })
            .catch(function () {
                vm.form.$submitted = false;
            })
            .finally(function () {
                PreviewModalService.modalInstance = undefined;
            });
    }

    // For Carousel
    function changeHeader(newHeaders, direction, nextIndex) {
        vm.model.selectedHeader = vm.selectedCategory.applauseHeaders[nextIndex];
        vm.model.selectedCategory = vm.selectedCategory;
    }
}
