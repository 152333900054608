import bowser from 'bowser';
import $ from 'jquery';

debugCtrl.$inject = [];

export default function debugCtrl() {
    const vm = this;

    // Browser Debugging
    vm.browser = {
        name: bowser.name,
        version: bowser.version,
        mobile: bowser.mobile,
        tablet: bowser.tablet,
        osName: bowser.osname,
        osVersion: bowser.osversion
    };

    vm.classList = $('html').attr('class').split(/\s+/);
}
