import taxInfoFormTpl from './taxInfoForm.tpl.html';

export default {
    templateUrl: taxInfoFormTpl,
    controller: 'taxInfoFormCtrl',
    controllerAs: 'vm',
    bindings: {
        editing: '<',
        onSuccess: '<',
        onFail: '<',
        submitButtonOptions: '<'
    }
};
