import _ from 'lodash';

GridUrlSort.$inject = ['$location', 'StateUtils'];

export default function GridUrlSort($location, StateUtils) {

    /*
        This service is for sorting the columns of a grid using a special string format in the URL.
        The URL is also updated as the user sorts/clears columns.
     */

    const DEFAULT_PARAM_NAME = 'sort';

    const SORT_SEPARATOR = '__';
    const ASCENDING_SYMBOL = '--asc';
    const DESCENDING_SYMBOL = '--desc';

    const sortStringRegex = new RegExp(`(\\w+)((?:${_.escapeRegExp(ASCENDING_SYMBOL)})|(?:${_.escapeRegExp(DESCENDING_SYMBOL)}))`);

    return {
        updateUrl,
        parseUrl
    };

    /**
     * @param {Object[]} columns
     * @param {string} [paramName = "sort"]
     * @param {{ field: string, [desc]: boolean }[]} sortSettings
     */
    function updateUrl(columns, paramName = DEFAULT_PARAM_NAME, sortSettings) {
        if (_.isEmpty(sortSettings)) {
            StateUtils.updateUrlParams({
                [paramName]: null
            });
            return;
        }
        const changedSortSettingsString = sortSettings
            .filter((sortSetting, sortIndex) => {
                const column = columns.find(column => column.dataField.toLowerCase() === sortSetting.field.toLowerCase());
                return column.sortIndex !== sortIndex;
            })
            .map(({ field, desc }) => field + (desc ? DESCENDING_SYMBOL : ASCENDING_SYMBOL))
            .join(SORT_SEPARATOR);
        StateUtils.updateUrlParams({
            [paramName]: changedSortSettingsString
        });
    }

    /**
     * Calling this function should be wrapped in a try-catch, errors could occur with invalid URL.
     * @param {string} [paramName = "sort"]
     * @param {Object[]} columns
     * @returns {Object[]} - Array of sorting settings { field, desc }
     */
    function parseUrl(paramName = DEFAULT_PARAM_NAME, columns) {
        const urlParams = $location.search();
        const allSortSettingsString = urlParams[paramName];
        if (!allSortSettingsString) {
            return [];
        }
        const sortSettingsStrings = allSortSettingsString.split(SORT_SEPARATOR);
        const sortSettings = [];
        sortSettingsStrings.forEach(sortSettingString => {
            const regExpResults = sortStringRegex.exec(sortSettingString);
            const field = regExpResults[1];
            const desc = regExpResults[2] === DESCENDING_SYMBOL;
            const matchingColumn = columns.find(column => column.dataField.toLowerCase() === field.toLowerCase());
            if (!matchingColumn) {
                return;
            }
            sortSettings.push({
                field: matchingColumn.dataField,
                desc
            });
        });
        return sortSettings;
    }
}
