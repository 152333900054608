import controlPanelTpl from './controlPanel.tpl.html';

controlPanelConfig.$inject = ['$stateProvider'];

export default function controlPanelConfig($stateProvider) {
    $stateProvider.state('controlPanel', {
        parent: 'auth',
        url: '/controlPanel?tab',
        views: {
            'main@': {
                controller: 'controlPanelCtrl as vm',
                templateUrl: controlPanelTpl
            }
        },
        data: {
            pageTitle: 'Control Panel',
            navLinkOptions: {
                reload: true
            }
        }
    });
}
