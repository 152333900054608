export default function hideIfNoChildren() {
    return {
        restrict: 'A',
        link: function(scope, element) {
            scope.$watchCollection(() => element.children(), function(children) {
                if (children.length > 0) {
                    element.css('display', '');
                    element.attr('aria-hidden', false);
                } else {
                    element.css('display', 'none');
                    element.attr('aria-hidden', true);
                }
            });
        }
    };
}
