import _ from 'lodash';

WidgetService.$inject = [
    '$filter', 'StringInterpolationService',
    'WidgetDataDisplayTypes', '$http', 'PdfService', 'LangUtils'
];

export default function WidgetService(
    $filter, StringInterpolationService,
    WidgetDataDisplayTypes, $http, PdfService, LangUtils
) {
    return {
        getSummaryLinkHref,
        formatTooltipValue,
        getWidgetData,
        getOverrideConfig,
    };

    /**
     * @param {Object} widget
     * @param {Contact} contact
     * @returns {string|undefined}
     */
    function getSummaryLinkHref(widget, contact) {
        const href = _.get(widget.data, 'extraJSON.summaryLink.href');
        if (!href) {
            return;
        }
        return StringInterpolationService.interpolate(href, {
            contactID: contact.userID,
            branchID: contact.userID
        });
    }

    /**
     * @param {Object} params
     * @param {String} [params.displayType] - From WidgetDataDisplayTypes
     * @param {string|number} params.value
     * @returns {string}
     */
    function formatTooltipValue({ displayType, value }) {
        switch (displayType) {
            case WidgetDataDisplayTypes.PERCENT:
                return $filter('number')(value) + '%';
            case WidgetDataDisplayTypes.CURRENCY:
                return $filter('currency')(value);
            case WidgetDataDisplayTypes.CURRENCY_NO_CENT:
                return $filter('currency')(value, undefined, 0);
            default:
                return $filter('formatIfNumber')(value);
        }
    }


    /**
     * Get the additional data for a specific widget.
     * @param {Object} params
     * @param {int} params.contactID
     * @param {string} params.widgetTypeCode
     * @param {int} params.widgetsAvailableID
     */
    async function getWidgetData({ contactID, widgetTypeCode, widgetsAvailableID, parameterValuesXML, isRefreshCache }) {
        const res = await $http.post(`/api/snapshot/${contactID}/widgets/${widgetTypeCode}/${widgetsAvailableID}`, {
            parameterValuesXML,
            isRefreshCache,
        }, { disableLoginAsConfirmModal: true });
        return res.data;
    }

    /**
     * @param {Object} widget
     * @returns {object}
     */
    function getOverrideConfig(widgetData) {
        const overrideConfig = _.get(widgetData, 'extraJSON.overrideConfig');
        if (!overrideConfig) {
            return {};
        }
        return overrideConfig;
    }
}
