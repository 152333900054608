

const moment = require('moment');

// The default max age for "persistent" cookies - cookies that we want to remain after logging out or closing a browser.
const PERSISTENT_COOKIE_MAX_AGE = moment.duration(1, 'y').asMilliseconds();

module.exports = {
    PERSISTENT_COOKIE_MAX_AGE
};
