promoFilterConfig.$inject = ['$stateProvider'];

export default function promoFilterConfig($stateProvider) {

    $stateProvider.state('incentive.filter', {
        url: '?promoGroupID&isDefaultView'
    });
    $stateProvider.state('award.filter', {
        url: '?promoGroupID&isDefaultView'
    });
}
