import datafeedPerformanceWidgetTpl from './datafeedPerformanceWidget.tpl.html';
import datafeedPerformanceWidgetCtrl from './datafeedPerformanceWidget.controller';

export default {
    templateUrl: datafeedPerformanceWidgetTpl,
    controller: datafeedPerformanceWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
