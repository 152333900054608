

const _ = require('lodash');
const constantUtils = require('../constantUtils');

const GridFilterOperators = {
    EQUALS: 'eq',
    NOT_EQUAL: 'neq',
    LESS_THAN: 'lt',
    GREATER_THAN: 'gt',
    LESS_THAN_OR_EQUAL_TO: 'lte',
    GREATER_THAN_OR_EQUAL_TO: 'gte',
    IN: 'in',
    CONTAINS: 'contains',
    NOT_CONTAIN: 'notcontains',
    STARTS_WITH: 'startswith',
    ENDS_WITH: 'endswith',
    BEFORE: 'before',
    AFTER: 'after',
    LAST_30_DAYS: 'l30d',
    LAST_60_DAYS: 'l60d',
    LAST_90_DAYS: 'l90d',
    THIS_MONTH: 'mtd',
    THIS_YEAR: 'ytd',
    THIS_FISCAL_YEAR: 'fytd',
    PREVIOUS_MONTH: 'pmth',
    PREVIOUS_YEAR: 'pyr',
    PREVIOUS_FISCAL_YEAR: 'pfyr'
};

const properties = {
    [GridFilterOperators.EQUALS]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_EQUALS',
        displaySymbol: '='
    },
    [GridFilterOperators.NOT_EQUAL]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_DOES_NOT_EQUAL',
        displaySymbol: '≠'
    },
    [GridFilterOperators.LESS_THAN]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_LESS_THAN',
        displaySymbol: '<'
    },
    [GridFilterOperators.GREATER_THAN]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_GREATER_THAN',
        displaySymbol: '>'
    },
    [GridFilterOperators.LESS_THAN_OR_EQUAL_TO]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_LESS_THAN_OR_EQUAL_TO',
        displaySymbol: '≤'
    },
    [GridFilterOperators.GREATER_THAN_OR_EQUAL_TO]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_GREATER_THAN_OR_EQUAL_TO',
        displaySymbol: '≥'
    },
    [GridFilterOperators.IN]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_IN',
    },
    [GridFilterOperators.CONTAINS]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_CONTAINS',
    },
    [GridFilterOperators.NOT_CONTAIN]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_DOES_NOT_CONTAIN',
    },
    [GridFilterOperators.STARTS_WITH]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_STARTS_WITH',
    },
    [GridFilterOperators.ENDS_WITH]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_ENDS_WITH',
    },
    [GridFilterOperators.BEFORE]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_BEFORE_INCLUSIVE',
    },
    [GridFilterOperators.AFTER]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_AFTER_INCLUSIVE',
    },
    [GridFilterOperators.LAST_30_DAYS]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_LAST_30_DAYS',
        noValueRequired: true
    },
    [GridFilterOperators.LAST_60_DAYS]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_LAST_60_DAYS',
        noValueRequired: true
    },
    [GridFilterOperators.LAST_90_DAYS]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_LAST_90_DAYS',
        noValueRequired: true
    },
    [GridFilterOperators.THIS_MONTH]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_THIS_MONTH',
        noValueRequired: true
    },
    [GridFilterOperators.THIS_YEAR]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_THIS_YEAR',
        noValueRequired: true
    },
    [GridFilterOperators.THIS_FISCAL_YEAR]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_THIS_FISCAL_YEAR',
        noValueRequired: true
    },
    [GridFilterOperators.PREVIOUS_MONTH]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_PREVIOUS_MONTH',
        noValueRequired: true
    },
    [GridFilterOperators.PREVIOUS_YEAR]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_PREVIOUS_YEAR',
        noValueRequired: true
    },
    [GridFilterOperators.PREVIOUS_FISCAL_YEAR]: {
        translateKey: 'app_GRID_FILTER_OPERATORS_PREVIOUS_FISCAL_YEAR',
        noValueRequired: true
    },
};

constantUtils.configure({
    constants: GridFilterOperators,
    properties,
    setPropertyIds: false
});

GridFilterOperators.noValueOperators = _(GridFilterOperators)
    .pickBy((operator) => properties[operator].noValueRequired)
    .toArray()
    .value();

Object.defineProperty(GridFilterOperators, 'noValueOperators', {
    enumerable: false
});

module.exports = GridFilterOperators;
