import reportFormTpl from './reportForm.tpl.html';
import reportFormCtrl from './reportForm.controller';

export default {
    templateUrl: reportFormTpl,
    controller: reportFormCtrl,
    controllerAs: 'vm',
    bindings: {
        defaultReportName: '@',
        gridQueryOptions: '<',
        gridColumns: '<',
        submit: '<',
        onSubmitSuccess: '<',
        onEditFilters: '<',
    }
};
