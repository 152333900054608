import _ from 'lodash';

/**
 * @param {MultiGridPageOptions} options
 * @returns {Array | Function}
 */
export default function createController({
    tabs,
    state: {
        tabUrlParam,
        name: stateName
    },
    controller: customController,
}) {
    const customInjectNames = customController ? customController.$inject || customController.slice(0, -1) : [];

    multiGridPageCtrl.$inject = ['$state', 'StateUtils', '$injector', ...customInjectNames];

    function multiGridPageCtrl($state, StateUtils, $injector, ...customInjectFunctions) {
        const vm = this;

        let customInjects;
        if (customController) {
            customInjects = customInjectNames.reduce((obj, name, i) => Object.assign(obj, {
                [name]: customInjectFunctions[i]
            }), {});
            $injector.invoke(customController, vm, customInjects);
        }

        vm.tabs = _.cloneDeep(tabs).reduce((obj, tab) => Object.assign(obj, {
            [tab.code]: {
                ...tab,
                grid: tab.grid && {
                    ...tab.grid,
                    initialFilters: $state.params[tab.code + 'FiltersArray'],
                    initialColumns: $state.params[tab.code + 'ColumnsObject'],
                    initialSort: $state.params[tab.code + 'SortArray'],
                }
            }
        }), {});

        let initialTab;
        const urlTab = $state.params[tabUrlParam];
        if (_.isString(urlTab)) {
            const tabCode = Object.keys(vm.tabs).find(tabCode => tabCode.toLowerCase() === urlTab.toLowerCase());
            initialTab = tabCode && vm.tabs[tabCode];
        }
        if (!initialTab) {
            initialTab = vm.tabs[tabs[0].code];
        }

        initialTab.wasSelected = true;

        vm.activeTab = initialTab;

        vm.changeActiveTab = async (tab) => {
            if (!tab.wasSelected) {
                if (tab.onFirstLoad) {
                    await $injector.invoke(tab.onFirstLoad, vm, customInjects);
                }
                tab.wasSelected = true;
            }
            vm.activeTab = tab;
            $state.go(stateName, {
                [tabUrlParam]: tab.code,
                filters: undefined,
                columns: undefined,
                sort: undefined
            });
        };
    }

    return multiGridPageCtrl;
}
