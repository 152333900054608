import _ from 'lodash';

promoEditCtrl.$inject = [
    'Session', 'promoDetails', 'PromoStatuses', 'PromoActions', 'PromoTypes', '$state',
    'GridUrlFilters', 'GridFilterOperators', '$translate',
];

export default function promoEditCtrl(
    Session, promoDetails, PromoStatuses, PromoActions, PromoTypes, $state,
    GridUrlFilters, GridFilterOperators, $translate,
) {
    const vm = this;

    if (!promoDetails) {
        return;
    }

    vm.user = Session.getUser();
    vm.screen = 'edit';
    vm.promoDetails = promoDetails;
    vm.pageTitle = $translate.instant('promo_DROPDOWN_MANAGE_TEXT');
    vm.legacyFramePath = `/CreatePromo.asp?PromoID=${promoDetails.ID}`;
    vm.hasManagePromosPermission = vm.user.hasPermission('ManagePromos');

    // If subPage exist then go to the subPage otherwise it goes to default edit page
    if ($state.params.subPage) {
        const subPage = $state.params.subPage;
        vm.pageTitle = _.startCase(subPage); // Capital case
        if (subPage.toUpperCase() === PromoActions.CLONE) {
            vm.legacyFramePath = `/PromoClone.asp?PromoID=${vm.promoDetails.ID}`;
        } else if (subPage.toUpperCase() === PromoActions.UPLOAD) {
            vm.legacyFramePath = `/PromoUploader.asp?PromoID=${vm.promoDetails.ID}`;
        }
    }

    // action toolbar links with permissions
    const allToolbarLinks = {
        clone: {
            show: (function showCloneLink() {
                return vm.hasManagePromosPermission;
            }()),
            uiSref: `incentiveEdit({ id: '${vm.promoDetails.ID}',
                    subPage: 'clone' })`,
            cssClass: 'clone-link',
            iconClass: 'fa fa-files-o',
            text: $translate.instant('promo_ACTION_CLONE'),
        },
        upload: {
            show: (function showUploadLink() {
                return vm.user.hasPermission('UploadIncentiveWinners')
                    && vm.promoDetails.promoStatusID !== PromoStatuses.DRAFT;
            }()),
            uiSref: `incentiveEdit({ id: '${vm.promoDetails.ID}',
                    subPage: 'upload' })`,
            uiSrefOpts: '{reload: false}',
            cssClass: 'upload-link',
            iconClass: 'fa fa-cloud-upload',
            text: $translate.instant('promo_ACTION_UPLOAD'),
        },
        delete: {
            show: (function showDeleteLink() {
                return vm.hasManagePromosPermission
                    && [PromoStatuses.DRAFT, PromoStatuses.PENDING].includes(vm.promoDetails.promoStatusID);
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.DELETE),
            cssClass: 'delete-link',
            iconClass: 'fa fa-trash-o',
            text: $translate.instant('promo_ACTION_DELETE'),
        },
        pause: {
            show: (function showPauseLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.ACTIVE;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.PAUSE),
            cssClass: 'pause-link',
            iconClass: 'fa fa-pause-circle-o',
            text: $translate.instant('promo_ACTION_PAUSE'),
        },
        unpause: {
            show: (function showUnPauseLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.PAUSED;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.UNPAUSE),
            cssClass: 'unpause-link',
            iconClass: 'fa fa-play-circle-o',
            text: $translate.instant('promo_ACTION_UNPAUSE'),
        },
        archive: {
            show: (function showArchiveLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.COMPLETE;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.ARCHIVE),
            cssClass: 'archive-link',
            iconClass: 'fa fa-file-archive-o',
            text: $translate.instant('promo_ACTION_ARCHIVE'),
        },
        unarchived: {
            show: (function showUnArchiveLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.ARCHIVED;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.UNARCHIVE),
            cssClass: 'unarchive-link',
            iconClass: 'fa fa-file-archive-o',
            text: $translate.instant('promo_ACTION_UNARCHIVE'),
        },
        activate: {
            show: (function showActivateLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.DRAFT;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.ACTIVATE),
            cssClass: 'activate-link',
            iconClass: 'fa fa-check-circle-o',
            text: $translate.instant('promo_ACTION_ACTIVATE'),
        },
        backToDraft: {
            show: (function showBackToDraftLink() {
                return vm.hasManagePromosPermission && vm.promoDetails.promoStatusID === PromoStatuses.PENDING;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.BACKTODRAFT),
            cssClass: 'back-to-draft-link',
            iconClass: 'fa fa-file-o',
            text: $translate.instant('promo_ACTION_BACK_TO_DRAFT'),
        },
        submissions: {
            show: (function showSubmissionsLink() {
                return vm.hasManagePromosPermission
                    && ![PromoStatuses.DRAFT, PromoStatuses.PENDING].includes(vm.promoDetails.promoStatusID)
                    && vm.promoDetails.promoTypeID === PromoTypes.CLAIM_BASED;
            }()),
            uiSref: `submissionsHierarchy({ filters: '${GridUrlFilters.stringifyFilters([
                { field: 'incentiveID', operator: GridFilterOperators.EQUALS, value: vm.promoDetails.ID },
            ])}' })`,
            uiSrefOpts: '{reload: false}',
            cssClass: 'submissions-link',
            iconClass: 'fa fa-thumbs-o-up',
            text: $translate.instant('promo_ACTION_SUBMISSIONS'),
        },
        approve: {
            show: (function showApproveLink() {
                return vm.hasManagePromosPermission
                    && ![PromoStatuses.DRAFT, PromoStatuses.PENDING].includes(vm.promoDetails.promoStatusID)
                    && vm.promoDetails.promoTypeID === PromoTypes.DATA_BASED;
            }()),
            uiSref: `incentiveResults({ dashboardID: ${vm.promoDetails.ID} })`,
            uiSrefOpts: '{reload: false}',
            cssClass: 'submissions-link',
            iconClass: 'fa fa-thumbs-o-up',
            text: $translate.instant('app_PROMO_QUIZ_SECTION_TOOL_BUTTON_APPROVE'),
        },
        addToLibrary: {
            show: (function showAddToLibraryLink() {
                return vm.hasManagePromosPermission
                    && vm.promoDetails.promoStatusID === PromoStatuses.DRAFT && !vm.promoDetails.isTemplate;
            }()),
            onClick: () => toggleConfirmationTool(PromoActions.ADD_TO_LIBRARY),
            cssClass: 'add-to-library-link',
            iconClass: 'fa fa-rocket',
            text: $translate.instant('promo_ACTION_ADD_TO_LIBRARY'),
        },
    };

    // Showing only required links by permissions and statuses
    vm.links = _(allToolbarLinks).pickBy(link => link.show).values().value();

    // Toggle confirmation window
    vm.closeTool = () => toggleConfirmationTool(null);

    vm.confirmationAction = null;
    function toggleConfirmationTool(action) {
        if (vm.confirmationAction === action) {
            vm.confirmationAction = null;
            return vm.confirmationAction;
        }
        vm.confirmationAction = action;
        return vm.confirmationAction;
    }
}
