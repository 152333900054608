import headerTpl from './header/header.tpl.html';
import taskbarTpl from './taskbar/taskbar.tpl.html';
import sidebarTpl from './sidebar/sidebar.tpl.html';
import footerTpl from './footer/footer.tpl.html';
import loginPanelTpl from './loginPanel/loginPanel.tpl.html';

navConfig.$inject = ['$stateProvider'];

export default function navConfig($stateProvider) {
    // TODO: use only one instance of navCtrl for header, footer, and sidebar
    // May be helpful: https://github.com/angular-ui/ui-router/wiki/Multiple-Named-Views

    $stateProvider.state('nav', {
        url: '',
        abstract: true,
        views: {
            // Whenever a state change happens with options { reload: true }, all of these controllers run again.
            'loginPanel@': {
                templateUrl: loginPanelTpl,
                controller: 'loginPanelCtrl as vm',
            },
            'header@': {
                templateUrl: headerTpl,
                controller: 'navCtrl as vm',
            },
            'taskbar@': {
                templateUrl: taskbarTpl,
                controller: 'taskbarCtrl as vm'
            },
            'sidebar@nav': {
                templateUrl: sidebarTpl
            },
            'footer@': {
                templateUrl: footerTpl,
                controller: 'navCtrl as vm',
            },
        },
        resolve: {
            navLinks: ['NavService', function(NavService) {
                return NavService.getNavLinks();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                // Since nav seems to be the parent of all other states, it's probably a good place to load app-wide phrases
                return RnTranslationService.loadPhrases('app');
            }],
        }
    });
}
