import angular from 'angular';

import pointExpiryTable from './pointExpiryTable.component';
import PointExpiryTableService from './pointExpiryTableService.factory';

export default angular.module('app.core.directives.pointExpiryTable', [
    'app.core'
])
.component('pointExpiryTable', pointExpiryTable)
.factory('PointExpiryTableService', PointExpiryTableService);
