import _ from 'lodash';

inboxGridCtrl.$inject = [
    '$scope', '$state', '$stateParams', 'GridColumnTypes', 'GridColumnFilterTypes', 'MessageService', 'Prompt', 'Session'
];

export default function inboxGridCtrl(
    $scope, $state, $stateParams, GridColumnTypes, GridColumnFilterTypes, MessageService, Prompt, Session
) {
    this.$onInit = () => {
        const vm = this;

        let gridInstance;

        vm.gridID = vm.gridID || 'grid-inbox';

        const company = Session.getCompany();
        vm.isShowNotifications = company.hasOption('NotificationManagement');

        vm.columns = [{
            dataField: 'message',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'inbox_MESSAGE_COLUMN_HEADING',
                cellTemplate: `
                    <button ng-click="vm.openDetailsModal(cell)"
                            ng-bind="cell.data.message"
                            class="no-button-styling message-title"
                            type="button">
                    </button>`
            }
        }, {
            dataField: 'from',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'inbox_FROM_COLUMN_HEADING'
            }
        }, {
            dataField: 'date',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'inbox_DATE_COLUMN_HEADING'
            }
        }, {
            dataField: 'isRead',
            visible: false,
            showInColumnChooser: false,
            nonDevExOptions: {
                type: GridColumnTypes.BOOLEAN,
                filterType: GridColumnFilterTypes.BOOLEAN,
                captionTranslateKey: 'inbox_READ_COLUMN_HEADING'
            }
        }, {
            nonDevExOptions: {
                isOptionsColumn: true,
                captionTranslateKey: 'inbox_OPTIONS_COLUMN_HEADING',
                cellTemplate: `
                    <grid-option-button on-click="vm.updateIsRead({ row: cell.row })"
                                        text-translate-key="{{ cell.data.isRead ?
                                            'inbox_MARK_AS_UNREAD_ICON_TITLE' : 'inbox_MARK_AS_READ_ICON_TITLE' }}"
                                        button-class="mark-as-read-button"
                                        icon-class="fa {{ cell.data.isRead ? 'fa-envelope-open-o' : 'fa-envelope' }}"
                                        one-time-binding="false">
                    </grid-option-button>
                    <grid-option-button on-click="vm.deleteFromGrid(cell)"
                                        text-translate-key="inbox_DELETE_ICON_TITLE"
                                        button-class="delete-button"
                                        icon-class="fa fa-trash">
                    </grid-option-button>`
            },
        }];

        vm.extraDataGridOptions = {
            onInitialized(e) {
                gridInstance = e.component;
            },
            onRowPrepared(e) {
                if (e.rowType === 'data' && !e.data.isRead) {
                    e.rowElement.addClass('unread-message');
                }
            }
        };

        $scope.$on(MessageService.Events.MESSAGE_OPENED, function updateIsReadOnMessageOpened(event, { messageDetails }) {
            if (messageDetails.isRead) {
                return;
            }
            const isInitiallyLoading = _.isEmpty(gridInstance.state());
            if (isInitiallyLoading) {
                // Can only access visible rows after contentReady
                gridInstance.on('contentReady', function markAsReadWhenLoaded() {
                    markAsRead();
                    gridInstance.off('contentReady', markAsReadWhenLoaded);
                });
            } else {
                markAsRead();
            }

            function markAsRead() {
                const { messageRecipientID } = messageDetails;
                const row = gridInstance.getVisibleRows().find(row => row.data.messageRecipientID === messageRecipientID);
                return vm.updateIsRead({ row, isRead: true });
            }
        });

        vm.openDetailsModal = async function(cell) {
            const params = $stateParams;
            params.messageRecipientID = cell.data.messageRecipientID;
            $state.go('inbox', params);
            const { modalInstance } = await MessageService.openModal({ messageRecipientID: cell.data.messageRecipientID });
            // Can't use .finally because the result param is needed
            return modalInstance.result.then(onModalClosed).catch(onModalClosed);

            async function onModalClosed(result) {
                // It seems that the SP that deletes Applauses and Certificates doesn't delete the MessageRecipients entry.
                if (result === 'deleted') {
                    await deleteMessage(cell.data.messageRecipientID);
                }
            }
        };

        vm.deleteFromGrid = async function(cell) {
            await Prompt.open({ type: 'warning' }).result;
            await deleteMessage(cell.data.messageRecipientID);
        };

        vm.updateIsRead = async function({ row, isRead = !row.data.isRead }) {
            await MessageService.updateIsRead({ messageRecipientID: row.data.messageRecipientID, isRead });
            row.data.isRead = isRead;
            const rowElement = gridInstance.getRowElement(row.rowIndex);
            if (isRead) {
                rowElement.removeClass('unread-message');
            } else {
                rowElement.addClass('unread-message');
            }
        };

        async function deleteMessage(messageRecipientID) {
            await MessageService.deleteMessage(messageRecipientID);
            gridInstance.refresh(); // TODO: figure out how to not have to reload all inbox data
        }
    };
}
