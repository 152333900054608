import angular from 'angular';

import datepickerCtrl from './datepicker.controller';
import DatepickerService from './datepickerService.factory';

// TODO: share the same datepicker used by formly-forms (customCalendar) to unduplicate code

export default angular.module('app.core.directives.rnDxGrid.filterModal.datepicker', [

])
.factory('DatepickerService', DatepickerService)
.controller('datepickerCtrl', datepickerCtrl);
