import widgetNoDataBodyTpl from './widgetNoDataBody.tpl.html';
import widgetNoDataBodyCtrl from './widgetNoDataBody.controller';

export default function widgetNoDataBody() {
    return {
        restrict: 'E',
        templateUrl: widgetNoDataBodyTpl,
        controller: widgetNoDataBodyCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            contact: '<'
        }
    };
}
