import bigNumberWidgetTpl from './bigNumberWidget.tpl.html';
import bigNumberWidgetCtrl from './bigNumberWidget.controller';

export default {
    templateUrl: bigNumberWidgetTpl,
    controller: bigNumberWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
