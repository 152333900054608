import $ from 'jquery';

taskbarCtrl.$inject = ['$rootScope', '$scope', '$state', 'AuthStatus', 'AUTH_EVENTS', 'SignOnTasks'];

export default function taskbarCtrl($rootScope, $scope, $state, AuthStatus, AUTH_EVENTS, SignOnTasks) {
    const vm = this;
    const $root = $('#root');

    vm.showPanel = false;
    vm.signOnTasks = SignOnTasks.getTasks();
    vm.showTaskbar = isShowTaskbar();

    if (vm.showTaskbar) {
        // The .has-taskbar CSS class is to push the main content below the taskbar on mobile.
        // Otherwise, taskbar would be hidden by fixed header.
        // TODO: directly modifying the root tag here may not be the best way, consider a service?
        $root.addClass('has-taskbar');
    }

    vm.onItemClicked = function(signOnTask) {
        vm.showPanel = false;
        return signOnTask.onTaskbarItemClicked($scope);
    };

    $scope.$on(SignOnTasks.Events.TASKS_UPDATED, function(event, newTasks) {
        vm.signOnTasks = newTasks;
        if (vm.signOnTasks.length <= 0) {
            vm.showTaskbar = false;
            $root.removeClass('has-taskbar');
        } else {
            vm.showTaskbar = true;
            $root.addClass('has-taskbar');
        }
    });

    function isShowTaskbar() {
        return AuthStatus.isAuthenticated() && vm.signOnTasks.length >= 1;
    }
}
