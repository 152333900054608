gamesFramePageCtrl.$inject = ['$stateParams', 'LangUtils'];

export default function gamesFramePageCtrl($stateParams, LangUtils) {
    const vm = this;

    const gameCode = $stateParams.gameCode;
    const guid = $stateParams.guid;
    const langCode = LangUtils.getLang().toUpperCase();

    if ($stateParams.displayCode) {
        vm.displayCodeClass = `display-code-${_.kebabCase($stateParams.displayCode)}`;
    }

    if (['ticTacKnow'].includes(gameCode)) {
        vm.gamesIframeURL =
        `https://games.rewardsnation.com/${gameCode}?guid=${guid}&languageCode=${langCode}`;
    } else {
        vm.gamesIframeURL =
        `https://${process.env.GAMES_SERVER_URL}/${gameCode}/live/${guid}?languageCode=${langCode}&displayCode=${$stateParams.displayCode}&version=${Date.now()}`;
    }
}
