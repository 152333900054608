// TODO: rename everything to "milestones" because it really includes anniversaries and birthdays
anniversaryBannerCtrl.$inject = ['$uibModal', 'MilestonesModal'];

export default function anniversaryBannerCtrl($uibModal, MilestonesModal) {
    this.$onInit = () => {
        const vm = this;
        vm.showMilestones = vm.isShowBirthdays || vm.isShowAnniversary;

        vm.showMilestoneBirthdays = vm.isShowBirthdays;
        vm.showMilestoneAnniversary = vm.isShowAnniversary;

        if (vm.anniversary.birthdays && vm.anniversary.birthdays.length) {
            vm.birthdayIsToday = vm.anniversary.birthdays[0].isToday;
            vm.birthdayDate = vm.anniversary.birthdays[0].date;
            vm.birthdayPeople = vm.anniversary.birthdays[0].people;
            vm.birthdays = vm.anniversary.birthdays;
        }

        if (vm.anniversary.anniversaries && vm.anniversary.anniversaries.length) {
            vm.anniversaryIsToday = vm.anniversary.anniversaries[0].isToday;
            vm.anniversaryDate = vm.anniversary.anniversaries[0].date;
            vm.anniversaryPeople = vm.anniversary.anniversaries[0].people;
            vm.anniversaries = vm.anniversary.anniversaries;
        }

        vm.openBirthdays = () => MilestonesModal.openBirthdays(vm.birthdays);

        vm.openAnniversaries = () => MilestonesModal.openAnniversaries(vm.anniversaries);
    };
}
