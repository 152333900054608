import debugTpl from './debug.tpl.html';

debugConfig.$inject = ['$stateProvider'];

export default function debugConfig($stateProvider) {
    $stateProvider.state('debug', {
        parent: 'auth',
        url: '/debug',
        views: {
            'main@': {
                controller: 'debugCtrl as vm',
                templateUrl: debugTpl
            }
        },
        data: {
            pageTitle: 'Debug'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('debug');
            }]
        }
    });
}
