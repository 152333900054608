faqCtrl.$inject = ['faqs', 'contactUsSetup', '$rootScope', '$timeout', 'FaqService', 'Session'];

export default function faqCtrl(faqs, contactUsSetup, $rootScope, $timeout, FaqService, Session) {
    const vm = this;
    vm.user = Session.getUser();
    vm.company = Session.getCompany();

    if (faqs) {
        vm.faqs = faqs;
    }

    vm.contactUsSetup = contactUsSetup;

    vm.toggleAccordion = function(question) {
        question.isOpen = !question.isOpen;
    };
}
