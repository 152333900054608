profilePictureDetailsCtrl.$inject = ['$rootScope', '$window', '$translate', '$localStorage', 'Upload', 'Session', 'ProfilePictureService'];

export default function profilePictureDetailsCtrl($rootScope, $window, $translate, $localStorage, Upload, Session, ProfilePictureService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.contactID
        // vm.hasPicture
        // vm.pictureUrl
        // vm.isEditable
        // vm.onUpdateSuccess
        // vm.onUpdateFail
        // vm.onDeleteSuccess
        // vm.onDeleteFail

        vm.user = Session.getUser();

        vm.isEditing = false;
        vm.showInvalidPictureMessage = false;

        let upload;

        vm.onNewPictureSelected = function($files, $file, $newFiles, $duplicateFiles, $invalidFiles) {
            vm.showInvalidPictureMessage = $invalidFiles.length > 0;
            if ($file) {
                vm.isEditing = true;
            }
        };

        // TODO: replace $window.alerts with something better/nicer

        vm.updatePicture = function() {
            if (vm.uploading) {
                return;
            }
            vm.uploading = true;
            upload = ProfilePictureService.updateProfilePicture(vm.newPictureFile, vm.contactID);

            return upload
                .then(function() {
                    $window.alert($translate.instant('app_EDIT_PROFILE_PICTURE_ALERT_SUCCESS_MESSAGE')); // Picture successfully updated
                    vm.hasPicture = true;
                    vm.newPictureFile = null;
                    vm.isEditing = false;
                    vm.pictureUrl = ProfilePictureService.determinePictureUrl(vm.contactID);
                    if (vm.contactID === vm.user.userID) {
                        $rootScope.$broadcast(ProfilePictureService.Events.USER_PROFILE_PICTURE_UPDATED, vm.newPictureFile);
                    }
                    if (vm.onUpdateSuccess) {
                        return vm.onUpdateSuccess(vm.newPictureFile);
                    }
                })
                .catch(function(err) {
                    if (vm.onUpdateFail) {
                        return vm.onUpdateFail(err);
                    }
                    throw err;
                })
                .finally(function() {
                    vm.uploading = false;
                });
        };

        vm.cancelNewPicture = function() {
            vm.newPictureFile = null;
            vm.isEditing = false;
        };

        vm.abortUpload = function() {
            if (!upload) {
                return;
            }
            return upload.abort();
        };

        vm.deletePicture = function() {
            const ok = $window.confirm($translate.instant('app_EDIT_PROFILE_PICTURE_ALERT_DELETE_MESSAGE_QUESTION'));
            // Are you sure you want to remove this picture? Note: changes may take up to 24 hours to be replaced throughout the site.
            if (!ok) {
                return;
            }
            return ProfilePictureService.deleteProfilePicture(vm.contactID)
                .then(function() {
                    $window.alert($translate.instant('app_EDIT_PROFILE_PICTURE_ALERT_DELETE_MESSAGE_SUCCESS')); // Picture successfully deleted
                    if (vm.contactID === vm.user.userID) {
                        $rootScope.$broadcast(ProfilePictureService.Events.USER_PROFILE_PICTURE_DELETED);
                    }
                    if (vm.onDeleteSuccess) {
                        return vm.onDeleteSuccess();
                    }
                })
                .catch(function(err) {
                    if (err.status === 404) {
                        $window.alert($translate.instant('app_EDIT_PROFILE_PICTURE_ALERT_DELETE_MESSAGE_ERROR_ALREADY_DELETED')); // Error: picture has already been deleted
                    } else {
                        if (vm.onDeleteFail) {
                            return vm.onDeleteFail(err);
                        }
                        throw err;
                    }
                });
        };
    };
}
