markAsReadOnClick.$inject = ["$timeout", "$window"];

export default function markAsReadOnClick($timeout, $window) {
    return {
        restrict: 'A',
        scope: {
            markAsReadOnClick: "<",
        },
        link: function(scope, element) {
            element.on('click', function(event) {
                const linkElem = getLinkElem_BubbleUp(event.target, event);
                let redirectLink;

                if(linkElem && linkElem.href) {
                    redirectLink = linkElem.href;

                    //stop link from working, will resume when task is marked as complete
                    event.preventDefault();
                    scope.markAsReadOnClick().then((res) => {
                        console.log('proceeding');

                        //mark as complete is done, resuming
                        $window.location = redirectLink;
                    }, (err) => {
                        console.error("canceled");
                    });
                }
            });

            function getLinkElem_BubbleUp(elem) {
                if(elem.href) {
                    return elem;
                } else if(elem && elem.attributes && !elem.attributes['mark-read-on-click'])  {
                    return getLinkElem_BubbleUp(elem.parentNode);
                } else {
                    return null;
                }
            }
        }
    }
}
