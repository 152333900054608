import columnChooserTpl from './columnChooser.tpl.html';

ColumnChooserService.$inject = ['$uibModal'];

export default function ColumnChooserService($uibModal) {
    return {
        openColumnChooserModal
    };

    function openColumnChooserModal(columns, gridInstance) {
        return $uibModal.open({
            animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: columnChooserTpl,
            controller: 'columnChooserCtrl',
            controllerAs: 'vm',
            size: 'sm',
            bindToController: true,
            resolve: {
                columns: () => columns,
                gridInstance: () => gridInstance
            }
        });
    }
}
