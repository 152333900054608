import angular from 'angular';

import wrapperConfig from './wrapper.config';

import '../../../../core/core.module';

export default angular.module('app.applause.formly.wrapper', [
    'app.core'
])
.config(wrapperConfig);
