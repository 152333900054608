import promiseToggleTpl from './promiseToggle.tpl.html';
import promiseToggleCtrl from './promiseToggle.controller';

export default function promiseToggle() {
    return {
        templateUrl: promiseToggleTpl,
        controller: promiseToggleCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            translateKey: '@',
            parentClass: '@',
            delaySpinnerTime: '<',
            checked: '<',
            promise: '<',
            onToggle: '<'
        }
    };
}
