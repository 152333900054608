import savedReportsGridTpl from './savedReportsGrid.tpl.html';
import savedReportsGridCtrl from './savedReportsGrid.controller';

export default {
    templateUrl: savedReportsGridTpl,
    controller: savedReportsGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<'
    }
};
