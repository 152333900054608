customContent.$inject = [];

export default function customContent() {
    return {
        restrict: 'E',
        scope: {},
        bindToController: {
            code: '@',
            html: '<',
        },
        template: `
            <div ng-if="vm.html"
                 ng-bind-html="vm.formattedHtml"
                 class="flex-fill-height custom-content-container">
            </div>`,
        controller: customContentCtrl,
        controllerAs: 'vm',
    };
}

customContentCtrl.$inject = ['$scope', '$sce', 'ContentService'];

function customContentCtrl($scope, $sce, ContentService) {
    this.$onInit = () => {
        const vm = this;

        $scope.$watch(() => vm.html, (html) => {
            if (!html) {
                return;
            }
            const formattedHtml = ContentService.formatContentHtmlForDisplay(html, vm.code);
            vm.formattedHtml = $sce.trustAsHtml(formattedHtml);
        });
    };
}
