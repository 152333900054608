import tilesWidgetTpl from './tilesWidget.tpl.html';
import tilesWidgetCtrl from './tilesWidget.controller';

export default {
    templateUrl: tilesWidgetTpl,
    controller: tilesWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
