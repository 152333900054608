import suggestInputTpl from './suggestInput.tpl.html';
import suggestInputCtrl from './suggestInput.controller';

export default function suggestInput() {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: suggestInputTpl,
        controller: suggestInputCtrl,
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            required: '=',
            type: '@',
            label: '@',
            labelSrOnly: '<',
            useSpectator: '<',
            onSelect: '<',
            placeholder: '@',
            maxlength: '@',
            minlength: '@',
            parentClass: '@',
            extraParameters: '<',
            otherContactId: '@'
        }
    };
}
