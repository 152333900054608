import statementTpl from './statement.tpl.html';
import statementCtrl from './statement.controller';

statementConfig.$inject = ['$stateProvider'];

export default function statementConfig($stateProvider) {
    $stateProvider.state('reloadableCardStatement', {
        parent: 'auth',
        url: '/reloadable-card/statement',
        views: {
            'main@': {
                controller: statementCtrl,
                controllerAs: 'vm',
                templateUrl: statementTpl
            }
        },
        data: {
            pageTitle: 'Reloadable Card Statement'
        },
        resolve: {
            statement: ['ReloadableCardService', function (ReloadableCardService) {
                return ReloadableCardService.getStatement();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('reloadableCard');
            }]
        }
    });
}
