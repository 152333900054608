snapshotRun.$inject = ['$transitions', '$state', 'AuthStatus', 'Session'];

export default function snapshotRun($transitions, $state, AuthStatus, Session) {

    $transitions.onBefore({ to: 'snapshot' }, function(transition) {
        const params = Object.assign({}, transition.params());
        if (!params.contactID && AuthStatus.isAuthenticated()) {
            const user = Session.getUser();
            params.contactID = user.userID;
            return $state.target('snapshot', params);
        }
    });
}
