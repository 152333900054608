import angular from 'angular';

submittedFilesList.$inject = ['$scope', '$element', '$timeout', 'FileUtils', 'CoreDirectivesService'];

export default function submittedFilesList($scope, $element, $timeout, FileUtils, CoreDirectivesService) {
    this.$onInit = () => {
        const vm = this;

        // The purpose of this component is to look as close as possible to how Uppy displays files when uploading.
        // Same HTML, same CSS classes, same SVGs, etc.

        vm.$onChanges = (changes) => {
            if (changes.files) {
                vm.files = angular.copy(changes.files.currentValue);
                vm.files.forEach(file => {
                    file.extension = FileUtils.getFileExtension(file.filename);
                    file.itemType = getFileItemType(file.extension);
                });
            }
        };

        $timeout(updateIsWide);

        $scope.$on('RESIZE_SUBMITTED_FILES_LIST', updateIsWide);

        CoreDirectivesService.onWindowResize($scope, updateIsWide);

        function getFileItemType(extension) {
            // Based on uppy/src/plugins/Dashboard/getFileTypeIcon.js
            switch (extension) {
                case 'txt':
                case 'doc':
                case 'docx':
                    return 'text';
                case 'mp3':
                    return 'audio';
                case 'mp4':
                case 'mov':
                case 'mpg':
                    return 'video';
                case 'pdf':
                    return 'pdf';
                case 'jpg':
                case 'gif':
                case 'png':
                case 'jpeg':
                    return 'image';
            }
        }

        function updateIsWide() {
            // No longer "wide" when element can't fit more than 2 items per line, each 180px wide.
            vm.isWide = $element.innerWidth() >= 2 * 180;
        }
    };
}
