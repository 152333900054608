import customCaptchaTpl from './customCaptcha.tpl.html';

customCaptchaRun.$inject = ['formlyConfig'];

export default function customCaptchaRun(formlyConfig) {
    formlyConfig.setType({
        name: 'customCaptcha',
        templateUrl: customCaptchaTpl,
        wrapper: ['validation', 'formControl', 'hasError'],
        defaultOptions: {
            templateOptions: {
                required: true
            }
        },
        controller: ['$scope', 'LangUtils', function ($scope, LangUtils) {
            if (!process.env.GOOGLE_RECAPTCHA_SITE_KEY) {
                console.warn('no google recaptcha site key');
            }
            $scope.googleRecaptchaSiteKey = process.env.GOOGLE_RECAPTCHA_SITE_KEY;
            // we currently use different language codes than Google
            // works now because en translates to English(US) and fr translates to French
            // TODO: when we use more than just French and English this may not work
            // https://developers.google.com/recaptcha/docs/language
            $scope.langCode = LangUtils.getLang();
        }]
    });
}
