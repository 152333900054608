import angular from 'angular';

import reportForm from './reportForm.component';
import customReportForm from './customReportForm.component';
import ReportFormModal from './reportFormModal.factory';
import CustomReportFormModal from './customReportFormModal.factory';

export default angular.module('app.reports.reportForm', [

])
.component('reportForm', reportForm)
.component('customReportForm', customReportForm)
.factory('ReportFormModal', ReportFormModal)
.factory('CustomReportFormModal', CustomReportFormModal);
