import promoEditCtrl from './promoEdit.controller';
import promoEditTpl from './promoEdit.tpl.html';

promoEditConfig.$inject = ['$stateProvider'];

export default function promoEditConfig($stateProvider) {
    $stateProvider.state('incentiveEdit', {
        parent: 'auth',
        url: '/incentive/:id/edit?subPage',
        views: {
            'main@': {
                controller: promoEditCtrl,
                controllerAs: 'vm',
                templateUrl: promoEditTpl
            }
        },
        params: {
            legacyFramePath: null
        },
        data: {
            pageTitle: 'Incentive Edit',
            permission: 'ManagePromos',
            isIncentive: true
        },
        resolve: {
            promoDetails: ['PromoService', '$transition$', function(PromoService, $transition$) {
                const params = $transition$.params();
                return PromoService.getIncentiveDisplay(params.id);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }]
        }
    });
}
