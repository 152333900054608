import $ from 'jquery';

promiseRun.$inject = ['$window', '$exceptionHandler'];

export default function promiseRun($window, $exceptionHandler) {
    // This file configures how Promises / $q works.
    // Angular's native $q is replaced in our app with Bluebird via the angular-bluebird-promise library.

    $($window).on('unhandledrejection', function(event) {
        event.preventDefault();
        const reason = (event.detail && event.detail.reason) || event.originalEvent.reason;
        if (isUnrelatedError(reason)) {
            return;
        }
        $exceptionHandler(reason);
    });

    function isUnrelatedError(reason) {
        // Avoid unrelated ui-router errors
        // From https://github.com/mattlewis92/angular-bluebird-promises#unhandled-rejections
        return reason instanceof Error === false
            || reason.message.match(/transition (superseded|prevented|aborted|failed)/);
    }
}
