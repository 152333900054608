import multiChartWidgetTpl from './multiChartWidget.tpl.html';
import multiChartWidgetCtrl from './multiChartWidget.controller';

multiChartWidget.$inject = ['CoreDirectivesService'];

export default function multiChartWidget(CoreDirectivesService) {
    return {
        templateUrl: multiChartWidgetTpl,
        controller: multiChartWidgetCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            contact: '<',
            hasData: '<',
            stats: '<',
            subStats: '<',
            chartConfig: '<',
            chartTitle: '@',
            chartContainerHeight: '@',
        },
        transclude: {
            aboveChart: '?aboveChart',
            belowChart: '?belowChart'
        },
        link: function(scope, element, attrs, vm, transcludeFn) {
            if (!vm.hasData) {
                return;
            }

            CoreDirectivesService.removeExtraTranscludeTags(element, transcludeFn, 'aboveChart', 'above-chart');
            CoreDirectivesService.removeExtraTranscludeTags(element, transcludeFn, 'belowChart', 'below-chart');
        }
    };
}
