SharedModuleService.$inject = ['$rootScope', 'Session', 'AUTH_EVENTS'];

export default function SharedModuleService($rootScope, Session, AUTH_EVENTS) {
    return {
        createFactory
    };

    /**
     * @param {SharedModule} SharedModuleClass
     *      A module from the "shared" folder (in the project root directory next to the "client" and "server" folders).
     * @return {Object}
     *      An object that can be used for creating an Angular Factory based on the `SharedModule`.
     */
    function createFactory(SharedModuleClass) {
        const company = Session.getCompany();
        const user = Session.getUser();

        const factory = new SharedModuleClass({ company, user });

        // Sets static functions of the class as properties of the instance, for convenient access.
        Object.getOwnPropertyNames(SharedModuleClass)
            .filter(propName => !['length', 'name', 'prototype'].includes(propName))
            .forEach(functionName => {
                // Can't directly set to SharedModule[functionName] because IE11 will crash
                // "Accessing the 'caller' property of a function or arguments object is not allowed in strict mode"
                factory[functionName] = (...args) => SharedModuleClass[functionName](...args);
            });

        $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, function(event, { company, user }) {
            factory.company = company;
            factory.user = user;
        });

        $rootScope.$on(Session.Events.SESSION_DESTROYED, function() {
            factory.company = Session.getCompany();
            factory.user = null;
        });

        return factory;
    }
}
