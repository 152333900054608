import claimTpl from './claim.tpl.html';

claimConfig.$inject = ['$stateProvider'];

export default function claimConfig($stateProvider) {
    $stateProvider.state('claim', {
        parent: 'auth',
        url: '/claim/:companyMetricCode/:reference',
        views: {
            'main@': {
                controller: 'claimCtrl as vm',
                templateUrl: claimTpl
            }
        },
        data: {
        },
        resolve: {
            ClaimDetails: ['ClaimService', '$stateParams', function(ClaimService, $stateParams) {
                const reference = $stateParams.reference || null;
                return ClaimService.getClaimDetails($stateParams.companyMetricCode, reference);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('claim');
            }]
        }
    });
}
