import spotlightItemToolsTpl from './spotlightItemTools.tpl.html';
import spotlightItemToolsCtrl from './spotlightItemTools.controller';

export default {
    templateUrl: spotlightItemToolsTpl,
    controller: spotlightItemToolsCtrl,
    controllerAs: 'vm',
    bindings: {
        item: '=',
        onToggleLike: '<',
        onToggleComment: '<',
        onDelete: '<',
        showSpotlightButtons: '<',
        showDeleteButton: '<',
        showPrintButton: '<',
        showCommentForm: '=',
        date: '<',
        isMasonryPage: '<'
    }
};
