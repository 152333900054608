export default function snapshotSetCtrl() {
    this.$onInit = () => {
        const vm = this;
        vm.$onInit = function () {
            vm.activeSetIndex = 0;
            vm.selectedSetTitle = '';
        };

        // function bindings to VM
        // -----------------------------------------------------------------------------------------------------------------
        vm.onSelectedSetChange = onSelectedSetChange;

        function onSelectedSetChange(page) {
            vm.activeSetIndex = page.sets.findIndex(set => set.title === page.selectedSetTitle);
        }
    };
}
