import leaderboardGridTpl from './leaderboardGrid.tpl.html';
import leaderboardGridCtrl from './leaderboardGrid.controller';

export default {
    templateUrl: leaderboardGridTpl,
    controller: leaderboardGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        updateUrlParams: '<',
        promoID: '<promoId'
    }
};
