import angular from 'angular';

import '../../core/core.module';
import '../communication/communication.module';
import './spotlightItemsContainer/spotlightItemsContainer.module';
import './spotlightComponents/spotlightComponents.module';

import spotlightConfig from './spotlight.config';
import spotlightCtrl from './spotlight.controller';
import SpotlightService from './spotlightService.factory';
import spotlightRun from './spotlight.run';

export default angular.module('app.spotlight', [
    'app.core',
    'app.communication',
    'app.spotlight.spotlightItemsContainer',
    'app.spotlight.spotlightComponents',
])
.config(spotlightConfig)
.controller('spotlightCtrl', spotlightCtrl)
.factory('SpotlightService', SpotlightService)
.run(spotlightRun);
