import promoQuizToolsTpl from './promoQuizTools.tpl.html';
import promoQuizToolsCtrl from './promoQuizTools.controller';

export default {
    templateUrl: promoQuizToolsTpl,
    controller: promoQuizToolsCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        formModel: '<',
        section: '<',
        showApproverTools: '<',
        sectionResults: '<',
        notes: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<',
    },
};
