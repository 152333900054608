import angular from 'angular';

import '../../core/core.module';
import '../authentication/auth.module';
import './loginAsConfirmActionModal/loginAsConfirmActionModal.module';

import loginAsConfig from './loginAs.config';
import loginAsPageConfig from './loginAsPage.config';
import loginAsRun from './loginAs.run';
import LoginAsServerSyncInterceptor from './loginAsServerSyncInterceptor.factory';
import LoginAsConfirmActionInterceptor from './loginAsConfirmActionInterceptor.factory';
import LoginAsService from './loginAsService.factory';
import LoginAsConfirmActionModalService from './loginAsConfirmActionModal/loginAsConfirmActionModalService.factory';
import loginAsPageCtrl from './loginAsPage.controller';

export default angular.module('app.loginAs', [
    'app.core',
    'app.auth',
    'app.loginAs.confirmAction'
])
.config(loginAsConfig)
.config(loginAsPageConfig)
.run(loginAsRun)
.factory('LoginAsServerSyncInterceptor', LoginAsServerSyncInterceptor)
.factory('LoginAsConfirmActionInterceptor', LoginAsConfirmActionInterceptor)
.factory('LoginAsService', LoginAsService)
.factory('LoginAsConfirmActionModalService', LoginAsConfirmActionModalService)
.controller('loginAsPageCtrl', loginAsPageCtrl);
