import moment from 'moment';
import _ from 'lodash';

answerDetailsCtrl.$inject = ['GameStatuses'];

export default function answerDetailsCtrl(GameStatuses) {
    const vm = this;

    vm.$onInit = async () => {
        // Passed from component bindings:
        // vm.results

        const resultDetails = vm.results;
        
        vm.gameTitle = resultDetails.gameName;
        vm.promoTitle = resultDetails.promoName;
        vm.earnedScore = resultDetails.earnedScore;
        vm.totalScore = resultDetails.totalScore;
        vm.isSubmitted = ![null, undefined].includes(resultDetails.submittedOn);
        vm.submittedOn = vm.isSubmitted ? moment(resultDetails.submittedOn).format('LLL') : undefined;
        vm.isPointPaid = resultDetails.pointPaid || vm.isSubmitted;
        vm.pointPaid = resultDetails.pointPaid;

        switch (resultDetails.gameplayStatusID) {
            case GameStatuses.NEW_GAME:
                vm.isNewGame = true;
                break;
            case GameStatuses.DELIVERED:
                vm.isGameDelivered = true;
                break;
            case GameStatuses.IN_PROGRESS:
                vm.isInProgress = true;
                break;
            case GameStatuses.PLAYED:
                vm.isPlayed = true;
                break;
            case GameStatuses.CANCELLED:
                vm.isCancelled = true;
                break;
            default:
                break;
        }

        vm.isPassed = resultDetails.passingScore < resultDetails.earnedScore || vm.isSubmitted;
        vm.quizResultID = null;
    }
}
