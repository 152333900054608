datafeedRun.$inject = ['$transitions', '$state', 'AuthStatus', 'Session'];

export default function datafeedRun($transitions, $state, AuthStatus, Session) {

    $transitions.onBefore({ to: 'datafeedContact' }, function(transition) {
        const params = Object.assign({}, transition.params());
        const contactIdParam = params.contactID;
        if (!contactIdParam && AuthStatus.isAuthenticated()) {
            const user = Session.getUser();
            if (!params.contactID) {
                params.contactID = user.userID;
                return $state.target('datafeedContact', params);
            }
        }
    });

    $transitions.onBefore({ to: 'datafeedHierarchy' }, function(transition) {
        const params = Object.assign({}, transition.params());
        const contactIdParam = params.contactID;
        if (!contactIdParam && AuthStatus.isAuthenticated()) {
            const user = Session.getUser();
            if (!params.contactID) {
                params.contactID = user.userID;
                return $state.target('datafeedHierarchy', params);
            }
        }
    });
}
