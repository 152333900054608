import socialPostPreviewTpl from './socialPostPreview.tpl.html';

SocialPostPreviewService.$inject = ['$uibModal'];

export default function SocialPostPreviewService($uibModal) {

    return {
        openSocialPostPreview,
    };

    /**
     * @param {Object} params
     * @param {Object} params.socialPostModel - The rn-form model of the social post form.
     * @param {File} params.mediaFile
     * @param {Function} params.submitSocialPost - Function to call when the user is ready and wants to submit.
     */
    function openSocialPostPreview({ socialPostModel, mediaFile, submitSocialPost }) {
        return $uibModal.open({
            controller: 'socialPostPreviewCtrl as vm',
            templateUrl: socialPostPreviewTpl,
            size: 'md',
            resolve: {
                socialPostModel: () => socialPostModel,
                mediaFile: () => mediaFile,
                submitSocialPost: () => submitSocialPost
            }
        });
    }
}
