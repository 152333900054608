import _ from 'lodash';

gridPageContainerCtrl.$inject = [];

export default function gridPageContainerCtrl() {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            translateHeader: true
        });
    };
}
