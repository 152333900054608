import angular from 'angular';

import '../../../../core/core.module';

import './deactivateUser/deactivateUser.module';
import './reactivateUser/reactivateUser.module';
import './resendInvite/resendInvite.module';
import './resetPassword/resetPassword.module';

export default angular.module('app.contacts.contactPanel.contactPanelTools', [
    'app.core',
    'app.contacts.contactPanel.contactPanelTools.deactivateUser',
    'app.contacts.contactPanel.contactPanelTools.reactivateUser',
    'app.contacts.contactPanel.contactPanelTools.resendInvite',
    'app.contacts.contactPanel.contactPanelTools.resetPassword'
]);
