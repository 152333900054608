import _ from 'lodash';
import moment from 'moment';

snapshotContentCtrl.$inject = ['SnapshotService', '$rootScope', '$scope', '$timeout'];

export default function snapshotContentCtrl(SnapshotService, $rootScope, $scope, $timeout) {
    const vm = this;
    vm.$onInit = () => {
        vm.WIDGETS_PER_ROW_MAX = 3;
        vm.widgets = [];
        vm.page = {};
        vm.previousFilters = [];

        updateWidgetsOnDisplay();
    };

    //record when WIDGET_FILTER event is firing to record user filter selection
    const filterListener = $rootScope.$on("WIDGET_FILTER", (event, filters) => {
        vm.previousFilters = filters;
   });


   
   // clean up listener
   $scope.$on('$destroy', filterListener);
   
   // $onChanges fires when component bindings change (indicating page change)
   vm.$onChanges = function (changes) {
       if (changes.activePageIndex && changes.activeSetIndex) {
           return updateWidgetsOnDisplay(changes.activePageIndex.currentValue, changes.activeSetIndex.currentValue);
       }
       if (changes.activePageIndex) {
           return updateWidgetsOnDisplay(changes.activePageIndex.currentValue);
       }
       if (changes.activeSetIndex) {
           return updateWidgetsOnDisplay(changes.activeSetIndex.currentValue);
       }
   };
   // function bindings to VM
   vm.dropWidgetById = dropWidgetById;

   // function definitions
   // -----------------------------------------------------------------------------------------------------------------

   function updateWidgetsOnDisplay(pageIndex = vm.activePageIndex, setIndex = vm.activeSetIndex) {
       if (_.isNumber(pageIndex) && _.isNumber(setIndex) && vm.widgetPages.length > 0) {
           //change default to user selected filter when switching pages
           if(vm.previousFilters 
            && _.isArray(vm.previousFilters)
            && _.isArray(vm.widgetPages[pageIndex].sets[setIndex].filters)) {
               vm.widgetPages[pageIndex].sets[setIndex].filters.forEach(currentFilter => {
                   const matchingPreviousFilterIndex = vm.previousFilters
                   .findIndex( previousFilter => previousFilter.displayName === currentFilter.displayName);
                   if (matchingPreviousFilterIndex !== -1 
                    && _.isObject(vm.previousFilters[matchingPreviousFilterIndex].currentValue)) {
                       currentFilter.displayValue = vm.previousFilters[matchingPreviousFilterIndex].displayValue;
                       currentFilter.currentValue = _.clone(vm.previousFilters[matchingPreviousFilterIndex].currentValue);

                        if(currentFilter.filterType === 'suggests') {
                            currentFilter.showSuggest = true;
                        }
                   }
               });
           }

           vm.page = vm.widgetPages[pageIndex];
           vm.widgets = vm.widgetPages[pageIndex].sets[setIndex].widgets;
           
           
           $timeout(()=> {
               const filters = vm.widgetPages[vm.activePageIndex].sets[vm.activeSetIndex].filters;
               vm.previousFilters = _.cloneDeep(filters);
               $rootScope.$emit('WIDGET_FILTER', filters);
           });
       }
   }

   function dropWidgetById(widgetsAvailableID, widgetCode) {
       console.info(`${widgetCode} dropped`);
       vm.widgetPages[vm.activePageIndex].sets[vm.activeSetIndex].widgets
           .splice(vm.widgetPages[vm.activePageIndex].sets[vm.activeSetIndex].widgets
               .findIndex((widget) => widget.widgetsAvailableID === widgetsAvailableID), 1);
   }
}
