

const constantUtils = require('./constantUtils');

const QuizQuestionTypes = {
    SHORT_ANSWER: 1,
    LONG_ANSWER: 2,
    RADIO: 3,
    CHECKBOX: 4,
    RATING: 5,
    PARTICIPANT: 6,
    DATE: 7,
    WINNER_SELECTOR: 8,
    RECOMMENDED_POINTS: 9,
    CERTIFICATE_DESCRIPTION: 10,
    CERTIFICATE_EXTRA_INFO: 11,
    ATTACH_FILE: 12,
    TRANSITION_QUESTION: 13,
    REFERENCE: 14
};

const properties = {
    [QuizQuestionTypes.SHORT_ANSWER]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_SHORT_ANSWER' //Short Answer
    },
    [QuizQuestionTypes.LONG_ANSWER]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_LONG_ANSWER' //Long Answer
    },
    [QuizQuestionTypes.RADIO]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_RADIO' //Radio
    },
    [QuizQuestionTypes.CHECKBOX]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_CHECKBOX' //Checkboxes
    },
    [QuizQuestionTypes.RATING]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_STAR_RATING' //Star Rating
    },
    [QuizQuestionTypes.PARTICIPANT]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_PARTICIPANT' //Participant
    },
    [QuizQuestionTypes.DATE]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_DATE' //Date
    },
    [QuizQuestionTypes.WINNER_SELECTOR]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_WINNER_SELECTOR' //Winner Selector
    },
    [QuizQuestionTypes.RECOMMENDED_POINTS]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_RECOMMENDED_POINTS' //Recommended Points
    },
    [QuizQuestionTypes.CERTIFICATE_DESCRIPTION]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_CERTIFICATE_DESCRIPTION' //Certificate Description
    },
    [QuizQuestionTypes.CERTIFICATE_EXTRA_INFO]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_CERTIFICATE_EXTRA_INFO' //Certificate Additional Info
    },
    [QuizQuestionTypes.ATTACH_FILE]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_ATTACH_FILE' //Attach File
    },
    [QuizQuestionTypes.TRANSITION_QUESTION]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_TRANSITION_QUESTION' //Transition Question
    },
    [QuizQuestionTypes.REFERENCE]: {
        translateKey: 'app_QUIZ_QUESTION_TYPES_REFERENCE' //Reference
    }
};

constantUtils.configure({ constants: QuizQuestionTypes, properties });

module.exports = QuizQuestionTypes;
