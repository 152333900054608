import angular from 'angular';

import '../../core.module';

import toFromHeader from './toFromHeader.component';

export default angular.module('app.core.directives.toFromHeader', [
    'app.core'
])
.component('toFromHeader', toFromHeader);
