

const GridFilterOperators = require('./GridFilterOperators');
const constantUtils = require('../constantUtils');

const GridColumnFilterTypes = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    BOOLEAN: 'boolean',
    CHECKBOX: 'checkbox',
    RADIO: 'radio'
};

const properties = {
    [GridColumnFilterTypes.TEXT]: {
        filterOperators: [
            GridFilterOperators.CONTAINS,
            GridFilterOperators.EQUALS,
            GridFilterOperators.NOT_CONTAIN,
            GridFilterOperators.NOT_EQUAL,
            GridFilterOperators.STARTS_WITH,
            GridFilterOperators.ENDS_WITH,
            // GridFilterOperators.IN - no interface yet
        ]
    },
    [GridColumnFilterTypes.NUMBER]: {
        filterOperators: [
            GridFilterOperators.EQUALS,
            GridFilterOperators.NOT_EQUAL,
            GridFilterOperators.LESS_THAN,
            GridFilterOperators.GREATER_THAN,
            GridFilterOperators.LESS_THAN_OR_EQUAL_TO,
            GridFilterOperators.GREATER_THAN_OR_EQUAL_TO,
            // GridFilterOperators.IN - no interface yet
        ]
    },
    [GridColumnFilterTypes.DATE]: {
        filterOperators: [
            GridFilterOperators.LAST_30_DAYS,
            GridFilterOperators.LAST_60_DAYS,
            GridFilterOperators.LAST_90_DAYS,
            GridFilterOperators.THIS_MONTH,
            GridFilterOperators.THIS_YEAR,
            GridFilterOperators.THIS_FISCAL_YEAR,
            GridFilterOperators.PREVIOUS_MONTH,
            GridFilterOperators.PREVIOUS_YEAR,
            GridFilterOperators.PREVIOUS_FISCAL_YEAR,
            GridFilterOperators.EQUALS,
            GridFilterOperators.BEFORE,
            GridFilterOperators.AFTER,
        ]
    },
    [GridColumnFilterTypes.BOOLEAN]: {
        filterOperators: [
            GridFilterOperators.EQUALS
        ]
    },
    [GridColumnFilterTypes.CHECKBOX]: {
        filterOperators: [
            GridFilterOperators.IN
        ]
    },
    [GridColumnFilterTypes.RADIO]: {
        filterOperators: [
            GridFilterOperators.EQUALS
        ]
    }
};

constantUtils.configure({
    constants: GridColumnFilterTypes,
    properties,
    setPropertyIds: false
});

module.exports = GridColumnFilterTypes;
