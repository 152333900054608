import _ from 'lodash';


dashboardCtrl.$inject = ['$stateParams', 'Session', '$state'];

export default function dashboardCtrl($stateParams, Session, $state) {
    this.$onInit = () => {
        const vm = this;

        if (_.get(vm, 'promoDetails.gameID')) {
            vm.gameID = vm.promoDetails.gameID;
            vm.defaultXmlValues = `<ParameterValue ParameterName="GameID" value="${vm.gameID}" />`;
            if ($stateParams.dashboardID) {
                vm.defaultXmlValues += `<ParameterValue ParameterName="PromoID" value="${$stateParams.dashboardID}" />`;
            }
        } else if (!$stateParams.dashboardCode && _.get(vm, 'promoDetails') && !_.get(vm, 'promoDetails.gameID')) {
            // TODO: Improve the condition and pass in the dashboardCode always
            vm.defaultXmlValues = `<ParameterValue ParameterName="PromoID" value="${$stateParams.dashboardID}" />`;
        }


        if (['incentiveResults', 'dashboard'].includes($state.current.name)) {
            if (_.get(vm, 'dashboard.reports') && vm.dashboard.reports.length) {
                showReport({ reportCode: vm.dashboard.reports[0].code, gameID: vm.gameID });
            }
        }

        vm.contact = Session.getUser();
        vm.showReport = showReport;
        vm.isActiveTab = isActiveTab;


        if ($stateParams.dashboardCode === 'incentive') {
            vm.defaultXmlValues = `<ParameterValue ParameterName="PromoID" value="${$stateParams.dashboardID}" />`;
        }

        if (_.get(vm, 'defaultXmlValues')) {
            vm.defaultXml = `<ParameterValues>${vm.defaultXmlValues}</ParameterValues>`;
        }

        function showReport(params) {
            if (_.get(vm, 'promoDetails')) {
                $state.transitionTo('incentiveResultsReport', {
                    ...params,
                    dashboardCode: $stateParams.dashboardCode,
                    dashboardID: $stateParams.dashboardID,
                }, {
                    location: true, inherit: false, relative: $state.$current, notify: true
                });
            } else {
                $state.transitionTo('report', {
                    ...params,
                    dashboardCode: $stateParams.dashboardCode,
                    dashboardID: $stateParams.dashboardID,
                }, {
                    location: true, inherit: false, relative: $state.$current, notify: true
                });
            }
        }

        function isActiveTab(reportCode) {
            if ($stateParams.reportCode) {
                return reportCode === $stateParams.reportCode;
            }
        }
    };
}
