

const constantUtils = require('./constantUtils');

const FileTypeGroups = {
    ALL: 1,
    PICTURE: 2,
    DATA: 3,
    VIDEO: 4,
    AUDIO: 5,
    PDF: 6,
    DOCUMENT: 7
};

const properties = {

};

constantUtils.configure({ constants: FileTypeGroups, properties });

module.exports = FileTypeGroups;
