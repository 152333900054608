

const constantUtils = require('./constantUtils');

const PointTrackingTypes = {
    APPLAUSE: 1,
    AWARD: 3,
    MILESTONE: 5,
    BENEFIT: 6,
    INITIAL: 8,
    CERTIFICATE: 9,
    REDEMPTION: 12,
    INCENTIVE: 13,
    PAYROLL_POINT_REDEMPTION: 14,
    EXPIRED: 16,
};

const properties = {
    [PointTrackingTypes.APPLAUSE]: {
        translateKey: 'app_POINT_TRACKING_TYPES_APPLAUSE'
    },
    [PointTrackingTypes.AWARD]: {
        translateKey: 'app_POINT_TRACKING_TYPES_AWARD'
    },
    [PointTrackingTypes.MILESTONE]: {
        translateKey: 'app_POINT_TRACKING_TYPES_MILESTONE'
    },
    [PointTrackingTypes.BENEFIT]: {
        translateKey: 'app_POINT_TRACKING_TYPES_BENEFIT'
    },
    [PointTrackingTypes.INITIAL]: {
        translateKey: 'app_POINT_TRACKING_TYPES_INITIAL'
    },
    [PointTrackingTypes.CERTIFICATE]: {
        translateKey: 'app_POINT_TRACKING_TYPES_CERTIFICATE'
    },
    [PointTrackingTypes.REDEMPTION]: {
        translateKey: 'app_POINT_TRACKING_TYPES_REDEMPTION'
    },
    [PointTrackingTypes.INCENTIVE]: {
        translateKey: 'app_POINT_TRACKING_TYPES_INCENTIVE'
    },
    [PointTrackingTypes.PAYROLL_POINT_REDEMPTION]: {
        translateKey: 'app_POINT_TRACKING_TYPES_PAYROLL_POINT_REDEMPTION'
    },
    [PointTrackingTypes.EXPIRED]: {
        translateKey: 'app_POINT_TRACKING_TYPES_EXPIRED'
    }
};

constantUtils.configure({ constants: PointTrackingTypes, properties });

module.exports = PointTrackingTypes;
