import _ from 'lodash';
import $ from 'jquery';
import WidgetVisualsToComponents, { legacyWidgetComponent } from './WidgetVisualsToComponents';

widgetContent.$inject = ['$compile'];

export default function widgetContent($compile) {
    return {
        restrict: 'E',
        scope: {
            widget: '<',
            contact: '<'
        },
        link: function(scope, element, attrs) {
            const widget = scope.widget;
            const component = getWidgetComponent(widget);
            if (!component) {
                throw new Error('Invalid widget name or component does not exist');
            }

            const template = `<${component} widget="widget"
                                            contact="contact"
                                            hide-if-no-children>
                              </${component}>`;

            // hide-if-no-children directive is used so that if the component decides to render nothing (ng-if false),
            // the outer tag will be hidden too and won't take up space in the flexbox layout of the widget page.

            // Merge html attributes and directives put on the <widget-creator> on to the template of the widget type
            const $template = $(template);
            _.forEach(attrs.$attr, (attrKebabCased, attr) => {
                if (this.scope.hasOwnProperty(attr)) {
                    return;
                }
                const value = attrs[attr];
                $template.attr(attrKebabCased, value);
            });

            const componentElement = $compile($template)(scope);
            element.replaceWith(componentElement);
        }
    };

    /**
     * @param {Object} widget - Minimal JSON for a single widget from the Pages SP
     * @returns {string} Name of the component
     */
    function getWidgetComponent(widget) {
        if (widget.isLegacy) {
            return legacyWidgetComponent;
        }
        return WidgetVisualsToComponents[widget.widgetVisualCode];
    }
}
