articleCtrl.$inject = [
    'SuggestsTypes', 'Session', 'SkinUtils', 'LangUtils', '$stateParams', 'ContentService', '$translate'
];

export default function articleCtrl(
    SuggestsTypes, Session, SkinUtils, LangUtils, $stateParams, ContentService, $translate
) {
    this.$onInit = () => {
        const vm = this;
        vm.SuggestsTypes = SuggestsTypes;
        vm.company = Session.getCompany();
        vm.subDomain = vm.company.subDomain;
        vm.langCode = LangUtils.getLang().toLowerCase();
        vm.skinCode = SkinUtils.getSkinCode();
        vm.placeholder = $translate.instant('content_SEARCH_BAR_PLACEHOLDER');
        vm.hideCrumbtrail = true;

        if (vm.content && vm.content.topicCrumbtrail) {
            vm.isTopicArticle = vm.content.topicCrumbtrail.slice(-1)[0].code === vm.content.code;
            if (vm.isTopicArticle) {
                vm.content.topicCrumbtrail.pop();
            }
        }

        ContentService.getContentByCode('KnowledgeBaseFooter', $stateParams.perm)
            .then((data) => {
                vm.footer = data;
            })
            .catch((err) => {
                if (err.data.returnCode === 6031) {
                    // When a content page exists but isn't public and the user isn't logged in,
                    // we want to redirect them to the login page, then try going to the page again
                    // after they've logged in.
                    err.redirectTo = 'login';
                    err.redirectParams = {
                        redirectTo: 'content',
                        redirectParams: $stateParams
                    };
                    err.disableDefaultHandling = true;
                }
                throw err;
            });
    };
}
