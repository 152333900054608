reportFilterCtrl.$inject = ["data", "GridColumnFilterTypes", "GridFilterOperators", "$uibModalInstance"];
import moment from 'moment';

export default function reportFilterCtrl(data, GridColumnFilterTypes, GridFilterOperators, $uibModalInstance) {
    const vm = this;
    
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const afterTomorrow = new Date(tomorrow);
    afterTomorrow.setDate(tomorrow.getDate() + 1);

    vm.isEditing = data.isEditing;
    vm.columns = data.columns.filter(column => column.dataField && column.filterType);
    vm.GridColumnFilterTypes = GridColumnFilterTypes;
    vm.GridFilterOperators = GridFilterOperators;
    vm.datePickerOptions = {
        customClass: getDayClass,
        minDate: new Date(),
        showWeeks: true
    };
    vm.submit = submit;
    vm.cancel = cancel;
    vm.onColumnChange = onColumnChange;
    vm.onOperatorChange = onOperatorChange;
    vm.onListOptionChanged = onListOptionChanged;
    vm.setDateToday = setDateToday;
    vm.dateChanged = dateChanged;


    vm.datePickerEvents = [
        {
            date: tomorrow,
            status: 'full'
        }, {
            date: afterTomorrow,
            status: 'partially'
        }
    ];

    if (data.filter) {
        vm.selectedColumnDataField = data.filter.field;
        onColumnChange();

        // overwrite default operator
        if(data.filter.operator) {
            vm.selectedOperator = data.filter.operator;
        }

        // overwrite value
        if(data.filter.value) {
            switch(vm.selectedColumn.filterType) {
                case GridColumnFilterTypes.CHECKBOX:
                    data.filter.value = data.filter.value ? data.filter.value : [];
                    vm.selectedColumn.reportListOptions = vm.selectedColumn.reportListOptions.map(option => {
                        return {
                            ...option,
                            isSelected: data.filter.value.some(item => (String(item) === String(option.value))),
                        }
                    });
                    break;
                case GridColumnFilterTypes.NUMBER:
                    vm.filterValueInput = Number(data.filter.value);
                    break;
                default:
                    vm.filterValueInput = data.filter.value;
                    break;
            }
        }
    } else {
        vm.selectedColumnDataField = vm.columns[0].dataField;
        onColumnChange();
    }

    function getDayClass(data) {
        const date = data.date;
        const mode = data.mode;

        if (mode === 'day') {
            const dayToCheck = new Date(date).setHours(0, 0, 0, 0);

            for (let i = 0; i < vm.datePickerEvents.length; i++) {
                const currentDay = new Date(vm.datePickerEvents[i].date).setHours(0, 0, 0, 0);

                if (dayToCheck === currentDay) {
                    return vm.datePickerEvents[i].status;
                }
            }
        }

        return '';
    }


    function setDateToday() {
        vm.dt = new Date();
        vm.filterValueInput = moment().format('YYYY-MM-DD');
    }

    function dateChanged() {
        vm.filterValueInput = moment(vm.dt).format('YYYY-MM-DD');
    }

    function onOperatorChange() {
        toggleDropdownFields();
    }

    function onColumnChange() {
        vm.selectedColumn = vm.columns.find(column => column.dataField === vm.selectedColumnDataField);
        vm.selectedOperator = GridColumnFilterTypes.properties[vm.selectedColumn.filterType].filterOperators[0];
        vm.filterValueInput = (vm.selectedColumn.filterType === GridColumnFilterTypes.BOOLEAN) ? true : null;

        if (vm.selectedColumn.filterType === GridColumnFilterTypes.TEXT) {
            vm.valueInputType = 'text';
        } else if (vm.selectedColumn.filterType === GridColumnFilterTypes.NUMBER) {
            vm.valueInputType = 'number';
        }

        toggleDropdownFields();
    }

    function onListOptionChanged(option) {
        if (vm.selectedColumn.filterType === GridColumnFilterTypes.RADIO) {
            vm.filterValueInput = option.value
        } else {
            vm.filterValueInput = vm.selectedColumn.reportListOptions
                .filter(option => option.isSelected)
                .map(option => option.value);
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
    
    function submit() {
        return $uibModalInstance.close({
            field: vm.selectedColumn.dataField,
            operator: vm.selectedOperator,
            value: vm.filterValueInput
        });
    };

    function toggleDropdownFields() {
        switch (vm.selectedColumn.filterType) {
            case GridColumnFilterTypes.RADIO:
            case GridColumnFilterTypes.CHECKBOX:
                vm.showOperatorDropdown = false;
                vm.showTextInput = false;
                vm.showListOptions = true;
                vm.showBooleanToggle = false;
                vm.showDatePicker = false;
                break;
            case GridColumnFilterTypes.DATE:
                vm.showOperatorDropdown = true;
                vm.showTextInput = false;
                vm.showListOptions = false;
                vm.showBooleanToggle = false;
                vm.showDatePicker = [
                    GridFilterOperators.EQUALS,
                    GridFilterOperators.BEFORE,
                    GridFilterOperators.AFTER
                ].includes(vm.selectedOperator);
                break;
            case GridColumnFilterTypes.BOOLEAN:
                vm.showOperatorDropdown = false;
                vm.showTextInput = false;
                vm.showListOptions = false;
                vm.showBooleanToggle = true;
                vm.showDatePicker = false;
                break;
            case GridColumnFilterTypes.TEXT:
            case GridColumnFilterTypes.NUMBER:
            default:
                vm.showOperatorDropdown = true;
                vm.showTextInput = true;
                vm.showListOptions = false;
                vm.showBooleanToggle = false;
                vm.showDatePicker = false;
        }
    }
}
