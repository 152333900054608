import _ from 'lodash';

reactivateUserCtrl.$inject = ['$state', 'ContactService','$http', 'Session'];

export default function reactivateUserCtrl($state, ContactService, $http, Session) {
    this.$onInit = () => {
        const vm = this;

        // Passed from parent template via component bindings:
        // vm.contact
        // vm.onCancel
        // vm.onSuccess

        _.defaults(vm, {
            onSuccess: function() {
                return $state.reload();
            },
            onCancel: _.noop
        });

        const user = Session.getUser();

        if (user.hasPermission('UndeleteContact')) {
            vm.hasEntity = 0;
            if (user.entityID !== undefined) {
                vm.hasEntity = 1;
            }
            $http.get(`/api/permissionGroups/${vm.hasEntity}?user=${user.userID}`).then(function(res) {
                vm.permissionGroups = res.data;
            });
        }

        vm.submitButton = {
            text: 'app_CONTACT_PANEL_ADMIN_TOOLS_REACTIVATE_BUTTON_SUBMIT', // Reactivate
            class: 'primary',
            isDisabled: true
        };
        vm.permissionGroupSelect = function() {
            vm.submitButton.isDisabled = false;
        };

        vm.contactName = `${vm.contact.firstName} ${vm.contact.lastName}`;
        vm.contactManagerName = vm.contact.manager ? `${vm.contact.manager.firstName} ${vm.contact.manager.lastName}` : 'N/A';

        vm.submit = function() {
            return ContactService.reactivateContact(vm.contact.userID, vm.myPermissionGroup.id)
                .then(function() {
                    vm.submitButton = {
                        text: 'app_CONTACT_PANEL_ADMIN_TOOLS_REACTIVATE_BUTTON_SUCCESS', // Success!
                        class: 'success',
                        isDisabled: true
                    };
                    return vm.onSuccess();
                })
                .catch(function(err) {
                    vm.errorMessage = err.data.phrase;
                });
        };
    };
}
