import angular from 'angular';

import contentManagementModule from './contentManagement/contentManagement.module';

import adminConfig from './admin.config';

export default angular.module('app.admin', [
    contentManagementModule.name,
])
.config(adminConfig);
