import angular from 'angular';

import 'angular-sanitize';
import 'angular-ui-bootstrap';
import 'ngstorage';

import '../../core/core.module';
import '../changePassword/changePassword.module';
import '../communication/communication.module';

import signOnTasksRun from './signOnTasks.run';

import markReadAsOnClick from './markAsReadOnClick.directive';
import SignOnTaskCreator from './signOnTaskCreator.factory';
import SignOnTasks from './signOnTasks.factory';

export default angular.module('app.signOnTasks', [
    'ngSanitize',
    'ui.bootstrap',
    'ngStorage',

    'app.core',
    'app.changePassword',
    'app.communication'
])
.run(signOnTasksRun)
.directive('markAsReadOnClick', markReadAsOnClick)
.factory('SignOnTaskCreator', SignOnTaskCreator)
.factory('SignOnTasks', SignOnTasks);
