import _ from 'lodash';

contactUsFormCtrl.$inject = ['$timeout', '$translate', 'Session', 'AuthStatus', 'ContactUsService'];

export default function contactUsFormCtrl($timeout, $translate, Session, AuthStatus, ContactUsService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.setup - includes "Concerning" field dropdown options

        vm.company = Session.getCompany();

        const isAuthenticated = AuthStatus.isAuthenticated();

        if (isAuthenticated) {
            vm.user = Session.getUser();
        }

        if (_.isEmpty(vm.company.contactUsSetup)) {
            vm.noContactUsSetup = true;
            return;
        }

        vm.model = {};

        vm.fields = [{
            type: 'customInput',
            key: 'contactName',
            defaultValue: isAuthenticated ? `${vm.user.firstName} ${vm.user.lastName}` : '',
            templateOptions: {
                placeholderTranslateKey: 'faq_CONTACT_US_NAME_PLACEHOLDER', // Your Name
                labelTranslateKey: 'faq_CONTACT_US_NAME', // Name
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9',
                required: true
            }
        }, {
            type: 'customInput',
            key: 'contactEmail',
            defaultValue: isAuthenticated ? vm.user.email : '',
            templateOptions: {
                type: 'email',
                placeholderTranslateKey: 'faq_CONTACT_US_EMAIL_PLACEHOLDER', // Your Email
                labelTranslateKey: 'faq_CONTACT_US_EMAIL', // Email
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9',
                required: true
            },
            validators: {
                hasTLD: {
                    expression: function(viewValue, modelValue) {
                        const email = viewValue || modelValue;
                        return /^.+@.+\..{2,}$/.test(email);
                    },
                    message: () => $translate.instant('faq_VALIDATOR_MESSAGE_EMAIL_INVALID') // 'Email invalid'
                }
            }
        }, {
            type: 'customInput',
            key: 'contactPhone',
            defaultValue: isAuthenticated ? vm.user.phone : '',
            templateOptions: {
                type: 'tel',
                placeholderTranslateKey: 'faq_CONTACT_US_PHONE_PLACEHOLDER', // Phone
                labelTranslateKey: 'faq_CONTACT_US_PHONE', // Your phone number
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9'
            },
        }, !_.isEmpty(vm.setup) && {
            type: 'customSelect',
            key: 'emailConcerningID',
            defaultValue: isAuthenticated ? null : vm.setup[0],
            templateOptions: {
                labelTranslateKey: 'faq_CONTACT_US_CONCERNING', // Concerning
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9',
                resetAfterSubmit: true,
                required: true,
                options: vm.setup,
                labelProp: 'subject',
                valueProp: 'ID'
            }
        }, {
            type: 'customInput',
            key: 'emailSubject',
            templateOptions: {
                placeholderTranslateKey: 'faq_CONTACT_US_SUBJECT_PLACEHOLDER', // Subject
                labelTranslateKey: 'faq_CONTACT_US_SUBJECT', // Provide an email subject
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9',
                resetAfterSubmit: true,
                required: true
            }
        }, {
            type: 'customTextarea',
            key: 'emailMessage',
            templateOptions: {
                placeholderTranslateKey: 'faq_CONTACT_US_MESSAGE_PLACEHOLDER', // Message
                labelTranslateKey: 'faq_CONTACT_US_MESSAGE', // Fill in your message
                labelClass: 'col-sm-3',
                controlClass: 'col-sm-9',
                resetAfterSubmit: true,
                rows: 4,
                required: true
            }
        }, {
            type: 'customCheckbox',
            key: 'isSendToMe',
            templateOptions: {
                labelTranslateKey: 'faq_CONTACT_US_SEND_COPY', // Send me a copy
                labelClass: 'col-sm-offset-3 col-sm-9'
            }
        }];

        if (!isAuthenticated && vm.company.hasOption('CaptchaPreLogin')) {
            vm.fields.push({
                key: 'captchaToken',
                type: 'customCaptcha',
                templateOptions: {
                    controlClass: 'col-sm-offset-3 col-sm-9'
                }
            });
        }

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'faq_CONTACT_US_BUTTON_SUBMIT' // Submit
                }
            }
        };

        vm.submit = () => ContactUsService.sendMail(vm.model);
    };
}
