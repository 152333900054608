SocialPostService.$inject = ['$http'];

export default function SocialPostService($http) {
    return {
        submitSocialPost
    };

    function submitSocialPost({ fileGUID, subject, message }) {
        return $http.post('/api/social-posts', {
            fileGUID,
            subject,
            message
        });
    }
}
