import angular from 'angular';

import '../../../core/core.module';
import './spotlightBanner/spotlightBanner.module';
import './spotlightItem/spotlightItem.module';
import './chooseRecognition/chooseRecognition.module';
import './anniversaryBanner/anniversaryBanner.module';
import './spotlightFilter/spotlightFilter.module';


export default angular.module('app.spotlight.spotlightComponents', [
    'app.core',
    'app.spotlight.spotlightComponents.spotlightBanner',
    'app.spotlight.spotlightComponents.spotlightItem',
    'app.spotlight.spotlightComponents.chooseRecognition',
    'app.spotlight.spotlightComponents.anniversaryBanner',
    'app.spotlight.spotlightComponents.spotlightFilter'
]);
