import incentiveDetailsTpl from './incentiveResults.tpl.html';
import incentiveDetailsCtrl from './incentiveResults.controller';

export default {
    templateUrl: incentiveDetailsTpl,
    controller: incentiveDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        incentive: '<'
    }
};
