import loginAsPageTpl from './loginAsPage.tpl.html';

loginAsPageConfig.$inject = ['$stateProvider'];

export default function loginAsPageConfig($stateProvider) {

    // The purpose of this page is mainly for the legacy sites to have an RN2 link for logging in as a contact.
    // Everything in RN2 / angular should be using the LoginAsService instead.

    $stateProvider.state('loginAs', {
        parent: 'auth',
        url: '/login-as?contactID',
        views: {
            'main@': {
                templateUrl: loginAsPageTpl,
                controller: 'loginAsPageCtrl as vm'
            }
        },
        data: {
            pageTitle: 'Login As',
            // Important -  don't specify the 'LoginAs' permission here.
            // AuthStateService.checkStateAuthorization() checks if the USER has the permission.
            // So when the spectator wants to log in as someone else but the user they're currently logged in as doesn't
            // have the LoginAs permission, the spectator won't be able to switch contacts.
            // TODO: consider how to check the spectator's LoginAs permission instead for this particular page
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('loginAs');
            }]
        }
    });
}
