
gameSSOCtrl.$inject = ['Session', '$stateParams', '$window', '$timeout', 'ssoToken'];

export default function gameSSOCtrl(Session, $stateParams, $window, $timeout, ssoToken) {
    this.$onInit = () => {
        const vm = this;
        const company = Session.getCompany();
        const user = Session.getUser();
        let redirectUrl;
        let subview = false;
        if ($stateParams) {
            if ($stateParams.redirectUrl) {
                redirectUrl = $stateParams.redirectUrl;
            }
            if ($stateParams.subview) {
                subview = $stateParams.subview;
            }
        }

        let adminURL;
        if (company.environmentInstance && ['live', 'uat', 'dr'].includes(company.environmentInstance.toLowerCase())) {
            adminURL = `${company.subDomain}-admin.iziigame.com`;
        } else {
            adminURL = `${company.subDomain}-admin.vibe.smg`;
        }

        if (ssoToken && user.hasPermission('IsGameAdmin')) {
            $window.location.href = `https://${adminURL}/sso-sign-in?token=${ssoToken}&redirectURL=${redirectUrl}&subview=${subview}`;
        } else {
            $window.location.href = `https://${adminURL}/sign-in?redirectURL=${redirectUrl}&subview=${subview}`;
        }
    };
}
