import registrationModalTpl from './registrationModal.tpl.html';

RegistrationModalService.$inject = ['$uibModal'];

export default function RegistrationModalService($uibModal) {
    return {
        openRegistrationModal
    };

    function openRegistrationModal() {
        return $uibModal.open({
            controller: 'registrationModalCtrl',
            controllerAs: 'vm',
            templateUrl: registrationModalTpl,
            size: 'lg',
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('registration');
                }],
                permissionGroupOptions: ['$http','Session', async function($http, Session) {
                    const showFriendlyPermissionGroups = 1;
                    const company = Session.getCompany();
                    if (!company.hasOption('SelfRegisterEntities')) {
                        return;
                    }
                    const res = await $http.get(`/api/permissionGroups/${showFriendlyPermissionGroups}`);
                    return res.data;
                }]
            }
        });
    }
}
