PointCalculatorModalService.$inject = ['$uibModal'];

export default function PointCalculatorModalService($uibModal) {
    return {
        open
    };

    function open() {
        return $uibModal.open({
            template: `<point-calculator></point-calculator>`,
            size: 'md',
        });
    }
}
