leaderboardGridCtrl.$inject = ['GridColumnTypes', 'GridColumnFilterTypes', 'Session', 'LeaderboardService'];

export default function leaderboardGridCtrl(GridColumnTypes, GridColumnFilterTypes, Session, LeaderboardService) {
    this.$onInit = () => {
        const vm = this;

        vm.company = Session.getCompany();

        vm.gridID = vm.gridID || 'grid-leaderboard';

        const FALLBACK_VALUE_COLUMN_CAPTION = 'Value';

        vm.columns = [{
            dataField: 'rank',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'leaderboard_RANK_COLUMN_HEADING'
            }
        }, {
            dataField: 'contactName',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'leaderboard_PARTICIPANT_COLUMN_HEADING',
                cellTemplate: `
                    <a ui-sref="snapshot({ contactID: cell.data.contactID })"
                    class="participant-link">
                        <contact-icon contact="{ name: cell.data.contactName, userID: cell.data.contactID }"></contact-icon>
                        <span ng-bind="cell.data.contactName"></span>
                    </a>`
            }
        }, {
            dataField: 'value',
            caption: FALLBACK_VALUE_COLUMN_CAPTION,
            // TODO: review right changes for leaderboard percent type
            // calculateCellValue: function(rowData) {
            //     let newData = rowData.value;
            //     if (rowData.leaderboardTypeID === 6) {
            //         newData = `${rowData.value}%`;
            //     }
            //     return newData;
            // },
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
            }
        }];

        vm.showGrid = false;

        // Get the phrase for the Value column first, then show the grid
        // TODO: look into how we can get the value column phrase and the data at the same time

        LeaderboardService.getLeaderboardValuePhrase(vm.promoID)
            .then(function({ leaderboardHeader } = {}) {
                return leaderboardHeader || FALLBACK_VALUE_COLUMN_CAPTION;
            })
            .catch(function(err) {
                console.error(err);
                return FALLBACK_VALUE_COLUMN_CAPTION;
            })
            .then(function(leaderboardHeader) {
                const valueColumn = vm.columns.find(column => column.dataField === 'value');
                valueColumn.caption = leaderboardHeader;
                vm.showGrid = true;
            });
    };
}
