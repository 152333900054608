import _ from 'lodash';
import $ from 'jquery';
import Uppy from 'uppy/lib/core';
import Dashboard from 'uppy/lib/plugins/Dashboard';
import XHRUpload from 'uppy/lib/plugins/XHRUpload';

import UploadCancelledError from './UploadCancelledError';
import uppyPhrases from './uppyPhrases';

import customFileUploadTpl from './customFileUpload.tpl.html';

customFileUploadRun.$inject = [
    '$q', '$rootScope', '$transitions', '$cookies', '$translate', '$timeout', '$window', 'formlyConfig', 'FileUtils',
    'FileTypeGroups', 'Session'
];

export default function customFileUploadRun(
    $q, $rootScope, $transitions, $cookies, $translate, $timeout, $window, formlyConfig, FileUtils,
    FileTypeGroups, Session
) {

    // See customFileUpload.md for more documentation.

    formlyConfig.setType({
        name: 'customFileUpload',
        templateUrl: customFileUploadTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        apiCheck: (apiCheck) => ({
            templateOptions: {
                xhrUploadOptions: apiCheck.object.optional,
                dashboardOptions: apiCheck.object.optional,
                validExtensions: apiCheck.arrayOf(apiCheck.string).optional,
                maxFileSize: apiCheck.number.optional,
                maxNumberOfFiles: apiCheck.number.optional,
                initialFiles: apiCheck.arrayOf(apiCheck.shape({
                    URL: apiCheck.string,
                    GUID: apiCheck.string,
                    filename: apiCheck.string
                })).optional,
                showSubmittedView: apiCheck.bool.optional
            }
        }),
        defaultOptions: function(options) {
            const defaultOptions = {
                templateOptions: {
                    xhrUploadOptions: {},
                    dashboardOptions: {},
                    validExtensions: options.templateOptions.validExtensions
                        || FileUtils.getFileTypeGroupExtensions(FileTypeGroups.ALL),
                    initialFiles: [], // For promos, initialFiles set by promoQuizService addSectionCandidateAnswers().
                    showSubmittedView: false
                },
                extras: {
                    validateOnModelChange: false // Need to manually decide when to validate via controller
                },
                modelOptions: {
                    allowInvalid: true
                },
                validators: {
                    // Important - Formly validator functions need to return a boolean or else all hell breaks loose
                    required: {
                        expression(viewValue, modelValue, $scope) {
                            return !_.isEmpty($scope.uploads);
                        },
                        message() {
                            // 'File(s) need to be uploaded'
                            return $translate.instant('app_CUSTOM_FILE_UPLOAD_VALIDATION_REQUIRED');
                        }
                    },
                    mustRemoveOrUpload: {
                        expression(viewValue, modelValue, $scope) {
                            return $scope.files.every(file => $scope.uploads[file.id]);
                        },
                        message() {
                            // 'Please upload these files if you want to submit them. Otherwise, remove them.'
                            return $translate.instant('app_CUSTOM_FILE_UPLOAD_VALIDATION_MUST_REMOVE_OR_UPLOAD');
                        }
                    }
                }
            };

            if (!options.templateOptions.required) {
                delete defaultOptions.validators.required;
            }

            return defaultOptions;
        },
        controller: customFileUploadCtrl
    });

    customFileUploadCtrl.$inject = ['$scope', '$timeout'];

    function customFileUploadCtrl($scope, $timeout) {
        // Unfortunately, cannot put the File instance selected into model[options.key].
        // angular.copy doesn't work when File is involved, and angular.copy seems to be used by formly on form models internally.

        // https://github.com/formly-js/angular-formly/issues/315#issuecomment-161054180
        // https://github.com/angular/angular.js/issues/14352
        // http://stackoverflow.com/questions/31569413/angular-js-illegal-invocation-on-copied-file-object

        /**
         * @typedef {Object} RnFormFileQuestionTracker
         * @property {File[]} [files] - Will contain the files selected by the user.
         * @property {Object<String, Promise>} [uploads] - As file uploads start/succeed/fail, the Promises will update.
         */

        if (!$scope.form.fileQuestions) {
            /**
             * @type {Object<String, RnFormFileQuestionTracker>} - Keys are formly field keys
             */
            $scope.form.fileQuestions = {};
        }

        $scope.form.fileQuestions[$scope.options.key] = Object.defineProperties({}, {
            files: {
                get: () => $scope.files
            },
            uploads: {
                get: () => $scope.uploads
            }
        });

        if (!_.isEmpty($scope.to.initialFiles)) {
            $scope.submittedFiles = _.cloneDeep($scope.to.initialFiles);
        }

        $scope.files = createInitialFiles();
        $scope.uploads = createInitialUploads();
        $scope.model[$scope.options.key] = createInitialModelValue();

        let uppy;

        if ($scope.to.disabled) {
            const detachDisabledListener = $scope.$watch(() => $scope.to.disabled, (isDisabled) => {
                if (isDisabled) {
                    return;
                }
                initializeUppy();
                detachDisabledListener();
                // Bug in uppy, the .uppy-Dashboard--wide class isn't applied for some reason until window resize.
                $timeout(() => $($window).trigger('resize'));
            });
        } else {
            // Timeout needed because Dashboard target selector not in DOM at this point.
            $timeout(initializeUppy);
        }

        $scope.options.onSubmit = () => {
            $scope.formIsSubmitting = true;
            $('.uppy-Dashboard-browse').attr('disabled', true);
        };

        $scope.options.onSubmitFinish = () => {
            $scope.formIsSubmitting = false;
            $('.uppy-Dashboard-browse').attr('disabled', null);
        };

        $scope.options.resetModel = function resetFileUpload() {
            if (!_.isEmpty($scope.to.initialFiles)) {
                $scope.submittedFiles = _.cloneDeep($scope.to.initialFiles);
            }
            if (uppy) {
                uppy.reset();
                return;
            }
            $scope.files = createInitialFiles();
            $scope.uploads = createInitialUploads();
            $scope.model[$scope.options.key] = createInitialModelValue();
        };

        $scope.removeSubmittedFile = (fileToRemove) => {
            $scope.files = $scope.files.filter(file => file.id !== fileToRemove.GUID);
            delete $scope.uploads[fileToRemove.GUID];
            $scope.model[$scope.options.key] = $scope.model[$scope.options.key]
                .filter(GUID => GUID !== fileToRemove.GUID);
            $scope.submittedFiles = $scope.submittedFiles
                .filter(file => file.GUID !== fileToRemove.GUID);
        };

        function createInitialFiles() {
            return $scope.to.initialFiles.map(file => ({
                // Try to match the non-pre-populated object formats.
                id: file.GUID,
                name: file.filename
            }));
        }

        function createInitialUploads() {
            return $scope.to.initialFiles.reduce((obj, file) => Object.assign(obj, {
                // TODO: shouldn't assume every file upload endpoint will return json in this format, { guid: 1234 }
                [file.GUID]: $q.resolve({ guid: file.GUID })
            }), {});
        }

        function createInitialModelValue() {
            return $scope.to.initialFiles.map(file => file.GUID);
        }

        function initializeUppy() {
            if (!$('#' + $scope.id + '-uppy').length) {
                return;
            }
            const spectator = Session.getSpectator();
            const user = Session.getUser();

            uppy = new Uppy({
                autoProceed: true,
                restrictions: {
                    maxFileSize: $scope.to.maxFileSize * 1000, // Uppy accepts bytes, templateOptions accepts KB
                    maxNumberOfFiles: $scope.to.maxNumberOfFiles,
                },
                locale: {
                    strings: translateUppyStrings(uppyPhrases.core)
                },
                onBeforeFileAdded(file) {
                    const state = uppy.getState();
                    const fileAlreadyPresent = Object.values(state.files).some(presentFile => presentFile.name === file.name
                        && presentFile.type === file.type
                        && presentFile.data.size === file.data.size
                        && presentFile.data.lastModified === file.data.lastModified
                    );
                    if (fileAlreadyPresent) {
                        // Otherwise file will be uploaded again.
                        return $q.reject($translate.instant('app_CUSTOM_FILE_UPLOAD_FILE_ALREADY_PRESENT')); // 'File already present'
                    }
                    const fileExtension = FileUtils.getFileExtension(file.name);
                    const isValidExtension = $scope.to.validExtensions.includes(fileExtension);
                    if (!isValidExtension) {
                        // 'Invalid file. Accepted file types: {{ extensions }}'
                        const errorPhrase = $translate.instant('app_CUSTOM_FILE_UPLOAD_VALIDATION_EXTENSION', {
                            extensions: $scope.to.validExtensions.join(', ')
                        });
                        return $q.reject(errorPhrase);
                    }
                    if ($scope.to.maxNumberOfFiles === 1 && !_.isEmpty(state.files)) {
                        // If only 1 upload allowed, do a replace instead of showing an error message.
                        const presentFileID = Object.keys(state.files).pop();
                        uppy.removeFile(presentFileID);
                        $scope.files = [];
                    }
                    return $q.resolve();
                }
            });

            uppy.use(Dashboard, _.merge({
                target: '#' + $scope.id + '-uppy',
                inline: true,
                showProgressDetails: false,
                hideProgressAfterFinish: true,
                maxWidth: '100%',
                maxHeight: 300,
                locale: {
                    strings: translateUppyStrings(uppyPhrases.dashboard)
                }
            }, $scope.to.dashboardOptions));

            uppy.use(XHRUpload, _.merge({
                method: 'POST',
                formData: true,
                bundle: false,
                fieldName: 'file',
                timeout: 0,
                headers: {
                    'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
                },
                getResponseData(responseText) {
                    return JSON.parse(responseText);
                },
                getResponseError(responseText) {
                    let message = responseText;
                    try {
                        message = JSON.parse(responseText).message;
                    } catch (err) {
                        console.error(err);
                    }
                    const err = new Error(message);
                    err.disableDefaultHandling = true;
                    return err;
                }
            }, spectator ? {
                headers: {
                    'Logged-In-As-UserID': user.userID
                }
            } : {}, $scope.to.xhrUploadOptions));

            onUppyEvent('upload', function onUploadStart({ fileIDs }) {
                fileIDs.forEach(fileID => $scope.uploads[fileID] = createUploadPromise(fileID));
            });

            onUppyEvent('file-added', function onFileAdded(file) {
                $scope.files.push(file);
            });

            onUppyEvent('file-removed', function onFileAdded(removedFile) {
                $scope.files = $scope.files.filter(file => file.id !== removedFile.id);
                delete $scope.uploads[removedFile.id];
            });

            onUppyEvent('cancel-all', function onCancelAll() {
                $scope.files = createInitialFiles();
                $scope.uploads = createInitialUploads();
                $scope.model[$scope.options.key] = createInitialModelValue();
            });

            activeUppyInstances.add(uppy);
            $scope.$on('$destroy', () => {
                activeUppyInstances.delete(uppy);
                uppy.close();
            });

            uppy.run();
        }

        /**
         * @param {string} fileID - Uppy file ID
         * @returns {Promise}
         */
        const createUploadPromise = (fileID) => $q((resolve, reject) => {
            const callbacks = {
                // These listeners get called for all files.
                // Each listener needs to check if the fileID matches.
                'upload-success': (successfulFile, resData = {}) => {
                    if (successfulFile.id !== fileID) {
                        return;
                    }
                    const fileIndex = $scope.files.findIndex(file => file.id === fileID);
                    // TODO: shouldn't assume every file upload endpoint will return json in this format, { guid: 1234 }
                    $scope.model[$scope.options.key][fileIndex] = resData.guid;
                    clearListeners();
                    resolve(resData);
                },
                'upload-error': (errorFile, err) => {
                    if (errorFile.id !== fileID) {
                        return;
                    }
                    addRemoveButton(fileID);
                    clearListeners();
                    reject(err);
                },
                'upload-cancel': (cancelledFileID) => {
                    // TODO: a near future version of uppy will likely change the argument to a File instead of an ID
                    if (cancelledFileID !== fileID) {
                        return;
                    }
                    clearListeners();
                    reject(new UploadCancelledError());
                },
                'cancel-all': () => {
                    clearListeners();
                    reject(new UploadCancelledError());
                }
            };
            const wrappedCallbacks = _.mapValues(callbacks, (callback, eventName) => onUppyEvent(eventName, callback));
            const clearListeners = () => _.forEach(wrappedCallbacks, (wrappedCallback, eventName) => uppy.off(eventName, wrappedCallback));
        });

        /**
         * @param {string} eventName
         * @param {Function} callback
         * @returns {Function} Wrapped callback
         */
        function onUppyEvent(eventName, callback) {
            const wrappedCallback = (...args) => {
                callback.call(uppy, ...args);
                // This makes uppy play nicely with angular and formly.
                $timeout(() => {
                    $scope.fc.$validate();
                    $scope.$apply();
                });
            };
            uppy.on(eventName, wrappedCallback);
            return wrappedCallback;
        }

        /**
         * Uppy always doesn't show a "remove" button on files (it's a concept specific to our forms).
         * @param {string} fileID
         */
        function addRemoveButton(fileID) {
            // Need to use attribute selector instead of # because fileID could contains slashes.
            const $fileItem = $(`[id="uppy_${fileID}"]`);
            const $removeButton = $fileItem.find('.uppy-DashboardItem-remove');
            $removeButton.show();
        }
    }

    function translateUppyStrings(strings) {
        return _.mapValues(strings, (phraseKeyOrObject) => {
            if (_.isString(phraseKeyOrObject)) {
                return phraseKeyOrObject === '' ? '' : $translate.instant(phraseKeyOrObject);
            }
            return _.mapValues(phraseKeyOrObject, $translate.instant);
        });
    }

    const activeUppyInstances = new Set();

    $($window).on('beforeunload', function confirmWindowExit(event) {
        if (!isUploadInProgress()) {
            return;
        }
        // Note - a lot of browsers prevent custom dialog text, different text may show.
        // 'File upload is in progress. Are you sure?'
        return event.returnValue = $translate.instant('app_UPLOAD_IN_PROGRESS_EXIT_WARNING');
    });

    $transitions.onStart({}, function(transition) {
        if (!isUploadInProgress()) {
            return;
        }
        // 'File upload is in progress. Are you sure?'
        const ok = $window.confirm($translate.instant('app_UPLOAD_IN_PROGRESS_EXIT_WARNING'));
        if (ok) {
            cancelAllUploads();
        } else {
            transition.abort();
        }
    });

    function isUploadInProgress() {
        return Array.from(activeUppyInstances).some(uppy => {
            const state = uppy.getState();
            return state.totalProgress > 0 && state.totalProgress < 100;
        });
    }

    function cancelAllUploads() {
        Array.from(activeUppyInstances).forEach(uppy => uppy.cancelAll());
    }
}
