import snapshotFilterTpl from './snapshotFilter.tpl.html';
import snapshotFilterCtrl from './snapshotFilter.controller';

export default {
    templateUrl: snapshotFilterTpl,
    controller: snapshotFilterCtrl,
    controllerAs: 'vm',
    bindings: {
        widgetPages: '<',
        contact: '<',
        activePageIndex: '<',
        activeSetIndex: '<'
    }
};
