import angular from 'angular';

import '../../core/core.module';

import ssoRun from './sso.run';
import SsoService from './ssoService.factory';

export default angular.module('app.sso', [
    'app.core'
])
.run(ssoRun)
.factory('SsoService', SsoService);
