import angular from 'angular';
import $ from 'jquery';

import applauseCultureStoryTpl from './applauseCultureStory.tpl.html';
import applauseCSConcatRTEWrapperTpl from './applauseCSConcatRTEWrapper.tpl.html';

applauseCultureStoryRun.$inject = ['$timeout', 'formlyConfig', '$window'];

function applauseCultureStoryRun($timeout, formlyConfig, $window) {
    formlyConfig.setWrapper({
        name: 'applauseCSConcatRTEWrapper',
        templateUrl: applauseCSConcatRTEWrapperTpl
    });

    formlyConfig.setType({
        name: 'applauseCultureStory',
        templateUrl: applauseCultureStoryTpl,
        defaultOptions: {
            expressionProperties: {
                'data.concatenatedContent': function(viewValue, modelValue, scope) {
                    const cultureStorykeys = Object.keys(scope.model.cultureStory);

                    if (scope.model.cultureStory && cultureStorykeys.length && scope.formState.cultureStoryStage === 1) {
                        scope.model.concatenatedContent = '';
                        cultureStorykeys.forEach(function (key, index) {
                            if (scope.model.cultureStory[key].story) {
                                scope.model.concatenatedContent += [
                                    '<div id="CSRTE_', index, '_section">',
                                    scope.model.cultureStory[key].story,
                                    '</div>'
                                ].join('');
                            }
                        });
                    }
                }
            }
        },
        controller: ['$scope', function($scope) {
            let unique = 0;

            $scope.cultureStorySection = 0;
            $scope.copyFields = copyFields;
            $scope.setSection = setSection;
            $scope.previousSection = previousSection;
            $scope.nextSection = nextSection;
            $scope.isActiveSection = isActiveSection;
            $scope.submitBuilder = submitBuilder;
            $scope.cultureStoryLength = Object.keys($scope.formState.CSBuilder).length;

            setSection(0);

            function submitBuilder() {
                if ($scope.model.concatenatedContent !== '') {
                    $scope.formState.cultureStoryStage = 2;
                    $scope.formState.CSBuilder[$scope.cultureStoryLength - 1].active = false;
                } else {
                    $scope.formState.cultureStoryBuilderShowError = true;
                    $timeout(function() {
                        $scope.formState.cultureStoryBuilderShowError = false;
                    }, 2000);
                }
            }

            function isActiveSection(index) {
                return $scope.cultureStorySection === index;
            }

            function copyFields(fields) {
                fields = angular.copy(fields);
                addIds(fields);
                return fields;
            }

            function addIds(fields) {
                angular.forEach(fields, function(field, index) {
                    if (field.fieldGroup) {
                        addIds(field.fieldGroup);
                        return; // fieldGroups don't need an ID
                    }

                    if (field.templateOptions && field.templateOptions.fields) {
                        addIds(field.templateOptions.fields);
                    }

                    field.id = field.id || (field.key + '_' + index + '_' + unique);
                    field.data = {};
                    field.data.index = unique;
                });
                unique++;
            }

            function setSection(value) {
                $scope.cultureStorySection = value;
            }

            function previousSection() {
                setSection($scope.cultureStorySection - 1);
            }

            function nextSection() {
                setSection($scope.cultureStorySection + 1);
            }
        }],
        link: function(scope, element) {
            scope.getHeight = getHeight;
            scope.getTopPosition = getTopPosition;

            // solution double timeout to $scope.apply error (not sure what this is doing)
            // replaced $scope.apply which shouldn't be used
            // TODO: what is this doing? Find a way to not need to do this. This may not even be needed
            $timeout(() => $timeout(() => $($window).bind('resize'), 500));

            function getHeight() {
                const sectionIndex = scope.cultureStorySection;
                if (element.find('div.formly-field-applauseCultureStoryRTE')[sectionIndex]) {
                    return element.find('div.formly-field-applauseCultureStoryRTE')[sectionIndex].offsetHeight + 15;
                }
            }

            function getTopPosition() {
                const sectionIndex = scope.cultureStorySection;
                let i;
                let top = 0;
                for (i = 0; i < sectionIndex; i++) {
                    top = top - element.find('div.formly-field-applauseCultureStoryRTE')[i].offsetHeight - 15;
                }
                return top + 'px';
            }
        }
    });
}

export default applauseCultureStoryRun;
