import resendInviteTpl from './resendInvite.tpl.html';
import resendInviteCtrl from './resendInvite.controller';

export default {
    templateUrl: resendInviteTpl,
    controller: resendInviteCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        onCancel: '<',
        onSuccess: '<'
    }
};
