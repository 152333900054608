import angular from 'angular';

import sharedModule from './shared/shared.module';
import legacyWidgetModule from './legacyWidget/legacyWidget.module';
import multiChartWidgetModule from './multiChartWidget/multiChartWidget.module';
import chartWidgetModule from './chartWidget/chartWidget.module';
import tableWidgetModule from './tableWidget/tableWidget.module';
import anniversaryWidgetModule from './anniversaryWidget/anniversaryWidget.module';
import applausesReceivedWidgetModule from './applausesReceivedWidget/applausesReceivedWidget.module';
import applausesSentWidgetModule from './applausesSentWidget/applausesSentWidget.module';
import bigNumberWidgetModule from './bigNumberWidget/bigNumberWidget.module';
import topThreeStrengthsWidgetModule from './topThreeStrengthsWidget/topThreeStrengthsWidget.module';
import datafeedSummaryCountWidgetModule from './datafeedSummaryCountWidget/datafeedSummaryCountWidget.module';
import datafeedPerformanceWidgetModule from './datafeedPerformanceWidget/datafeedPerformanceWidget.module';
import incentivePayoutGaugeWidgetModule from './incentivePayoutGaugeWidget/incentivePayoutGaugeWidget.module';
import participantEngagementWidgetModule from './participantEngagementWidget/participantEngagementWidget.module';
import expectedPerformanceWithTargetsWidgetModule from
    './expectedPerformanceWithTargetsWidget/expectedPerformanceWithTargetsWidget.module';
import metricSnapshotWidgetModule from './metricSnapshotWidget/metricSnapshotWidget.module';
import slideshowWidgetModule from './slideshowWidget/slideshowWidget.module';
import tilesWidgetModule from './tilesWidget/tilesWidget.module';
import progressBarsWidgetModule from './progressBarsWidget/progressBarsWidget.module';
import calculateInfoWidgetModule from './calculateInfoWidget/calculateInfoWidget.module';
import leaderboardWidgetModule from './leaderboardWidget/leaderboardWidget.module';
import applauseLeaderboardWidgetModule from './applauseLeaderboardWidget/applauseLeaderboardWidget.module';
import targetProgressWidget from './targetProgressWidget/targetProgressWidget.module';

import widgetCreator from './widgetCreator.component';
import widgetContent from './widgetContent.directive';
import WidgetService from './widgetService.factory';
import widgetRun from './widget.run';

export default angular.module('app.core.widgets', [
    sharedModule.name,
    legacyWidgetModule.name,
    multiChartWidgetModule.name,
    chartWidgetModule.name,
    tableWidgetModule.name,
    anniversaryWidgetModule.name,
    applausesReceivedWidgetModule.name,
    applausesSentWidgetModule.name,
    bigNumberWidgetModule.name,
    topThreeStrengthsWidgetModule.name,
    datafeedSummaryCountWidgetModule.name,
    datafeedPerformanceWidgetModule.name,
    incentivePayoutGaugeWidgetModule.name,
    participantEngagementWidgetModule.name,
    expectedPerformanceWithTargetsWidgetModule.name,
    metricSnapshotWidgetModule.name,
    slideshowWidgetModule.name,
    tilesWidgetModule.name,
    progressBarsWidgetModule.name,
    calculateInfoWidgetModule.name,
    leaderboardWidgetModule.name,
    applauseLeaderboardWidgetModule.name,
    targetProgressWidget.name,
])
.run(widgetRun)
.component('widgetCreator', widgetCreator)
.directive('widgetContent', widgetContent)
.factory('WidgetService', WidgetService);
