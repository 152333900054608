import angular from 'angular';

import '../../../../blocks/storage/storage.module'; // Session

import defaultPointTrackingDetailsCtrl from './defaultPointTrackingDetails.controller';
import defaultPointTrackingDetails from './defaultPointTrackingDetails.component';

export default angular.module('app.communication.defaultPointTracking.defaultPointTrackingDetails', [
    'app.blocks.storage'
])
.controller('defaultPointTrackingDetailsCtrl', defaultPointTrackingDetailsCtrl)
.component('defaultPointTrackingDetails', defaultPointTrackingDetails);
