import widgetStatsTpl from './widgetStats.tpl.html';
import widgetStatsCtrl from './widgetStats.controller';

export default function widgetStats() {
    return {
        restrict: 'E',
        templateUrl: widgetStatsTpl,
        controller: widgetStatsCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            stats: '<',
            isSubStats: '<',
            extraJson: '<',
        }
    };
}
