import moment from 'moment';
import _ from 'lodash';

snapshotPreviewCtrl.$inject = [
    '$uibModalInstance', 'widgetPages', 'activePageIndex', 'activeSetIndex', 'contact', 'Session', 'LangUtils',
    'SkinUtils', 'SnapshotPreviewService','SnapshotService', 'ChartWidgetService', 'WidgetVisuals', '$timeout'
];

export default function snapshotPreviewCtrl(
    $uibModalInstance, widgetPages, activePageIndex, activeSetIndex, contact, Session, LangUtils,
    SkinUtils, SnapshotPreviewService, SnapshotService, ChartWidgetService, WidgetVisuals, $timeout
) {
    const vm = this;

    // In the future if pdf print feature does not fit into page use custom.css to target  
    // .preview-print-area .widgets-page .widget-container to change the height of the widgets
    vm.WIDGETS_PER_ROW_MAX = 2;
    vm.company = Session.getCompany();
    vm.subDomain = vm.company.subDomain;
    vm.langCode = LangUtils.getLang().toLowerCase();
    vm.skinCode = SkinUtils.getSkinCode();
    vm.widgetPages = widgetPages;
    vm.activePageIndex = activePageIndex;
    vm.activeSetIndex = activeSetIndex;
    vm.contact = contact;
    vm.defaultXml = getDefaultValues(vm.widgetPages[activePageIndex].sets[activeSetIndex]);
    vm.page = vm.widgetPages[activePageIndex];
    vm.widgets = vm.widgetPages[activePageIndex].sets[activeSetIndex].widgets;
    vm.title = vm.page.title;
    vm.currentDate = new Date();

    vm.widgets.forEach(widget => {
        widget.isShow = true;
    });

    vm.formatPreviewPages = formatPreviewPages;
    vm.widgetsFixCSS = widgetsFixCSS;

    formatPreviewPages();


    function formatPreviewPages () {
        vm.previewPages = [];
        const widgets = _.cloneDeep(vm.widgets.filter(widget => widget.isShow === true));
        while(widgets.length) {
            vm.previewPages.push(widgets.splice(0,4));
        }
        widgetsFixCSS(100);
    }

    function widgetsFixCSS (delay = 200) {
        $timeout(() => {
            vm.widgets.forEach(widget => {
                const chartCssID = ChartWidgetService.createChartCssID(widget.widgetsAvailableID);
                ChartWidgetService.fixChartSize({
                    chartCssID,
                    widgetVisualCode: widget.widgetVisualCode
                });

                const hasRangeSelector = _.get(widget, "data.extraJSON.chartOptions.hasRangeSelector");
                if(hasRangeSelector) {
                    const rangeSelectorChartCssID = `range-selector-${widget.widgetsAvailableID}`;
                    ChartWidgetService.fixChartSize({
                        chartCssID: rangeSelectorChartCssID,
                        widgetVisualCode: WidgetVisuals.RANGE_SELECTOR
                    });
                }
            })
        }, delay);
    }

    function getDefaultValues(set) {
        if (set.filters) {
            const defaultFilters = set.filters.map(filter => {
                if(filter.default) {
                    switch(filter.filterType) {
                        case "customDate":
                            switch(filter.default.code) {
                                case "Custom":
                                    filter.currentValue[filter.parameters.startDate] = moment(filter.default.startDate).toDate();
                                    filter.currentValue[filter.parameters.endDate] = moment(filter.default.endDate).toDate();
                                    break;
                            }
                            break;
                        case "shortList":
                            filter.currentValue.parameterValue = filter.default.parameterValue;
                            break;
                        case "suggests":
                            filter.currentValue.parameterValue = filter.default.parameterValue;
                            break;
                    }
                }
                return filter;
            });
            return SnapshotService.getParameterValuesXML(defaultFilters);
        }
    }

    vm.print = async function() {
        await SnapshotPreviewService.print();
        return $uibModalInstance.close('printing');
    };

    vm.cancel = function() {
        return $uibModalInstance.dismiss('cancel');
    };
}
