import angular from 'angular';

import '../../core/core.module';

import contactUsConfig from './contactUs.config';
import contactUsForm from './contactUsForm/contactUsForm.component';
import contactUsCtrl from './contactUs.controller';
import ContactUsService from './contactUsService.factory';

export default angular.module('app.contactUs', [
    'app.core'
])
.config(contactUsConfig)
.component('contactUsForm', contactUsForm)
.controller('contactUsCtrl', contactUsCtrl)
.factory('ContactUsService', ContactUsService);
