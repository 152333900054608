errorPopupRun.$inject = ['$window', 'ErrorModal'];

export default function errorPopupRun($window, ErrorModal) {
    const errorBeforeIndexRedirect = $window.rnSession.error;
    if (errorBeforeIndexRedirect) {
        ErrorModal.open({
            data: errorBeforeIndexRedirect
        });
    }
}
