import targetProgressWidgetTpl from './targetProgressWidget.tpl.html';
import targetProgressWidgetCtrl from './targetProgressWidget.controller';

export default {
    templateUrl: targetProgressWidgetTpl,
    controller: targetProgressWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
