import promoQuizTpl from './promoQuiz.tpl.html';
import promoQuizCtrl from './promoQuiz.controller';

export default {
    templateUrl: promoQuizTpl,
    controller: promoQuizCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        showResetButton: '<'
    }
};
