import _ from 'lodash';
import $ from 'jquery';

import reportsListTpl from './reportsList.tpl.html';
import reportsListCtrl from './reportsList.controller';

reportList.$inject = ['$timeout', 'CoreDirectivesService'];

export default function reportList($timeout, CoreDirectivesService) {

    // Need to keep widths in decreasing order.
    // Make sure to update reportList.less when changing these.
    const BREAKPOINTS = [{
        width: 1000,
        columns: 4
    }, {
        width: 750,
        columns: 3,
    }, {
        width: 500,
        columns: 2
    }, {
        width: 0,
        columns: 1
    }];

    return {
        templateUrl: reportsListTpl,
        controller: reportsListCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            customReportsList: '<',
            companyMetricsList: '<',
        },
        link($scope, $element) {

            CoreDirectivesService.onWindowResize($scope, updateHeight);

            $scope.$watch(() => $element.parent().width(), () => $timeout(updateHeight));

            function updateHeight() {
                // Why is this algorithm needed?
                // - "flex-direction: column" doesn't wrap columns without specifying a container height and we don't
                //   know what the height of the container should be.
                // - we don't want one really long column, we want a height such that the categories are wrapped nicely
                //   into columns while still being ordered alphabetically vertically.
                //
                // Algorithm explanation:
                //
                // We state how many columns should be displayed depending on screen width in the BREAKPOINTS variable
                // (the widths in BREAKPOINTS match media queries in reportsList.less).
                // We start by dividing the total height of all categories by the number of columns desired to get
                // the averageColumnHeight.
                // However, the categories won't always fit perfectly.
                // Sometimes a single category height is larger than the averageColumnHeight so the firstAttemptHeight
                // is the largest number between the tallestCategory and the averageColumnHeight.
                // Using firstAttemptHeight, categories still may not fit perfectly into the desired number of columns,
                // and will overflow into additional columns.
                // Therefore, if that happens, we add each overflowing height one by one (in order from shortest to
                // tallest) to see if that will then fit all the categories.
                // When all categories fit, that gives us the correct height to set the reports-list to.

                const containerWidth = $element.parent().width();
                if (!containerWidth) {
                    return;
                }
                const breakpoint = BREAKPOINTS.find(breakpoint => breakpoint.width < containerWidth);
                if (breakpoint.columns === 1) {
                    $element.find('.reports-list').css('height', '');
                    return;
                }
                const $categories = $element.find('.category').toArray().map($);
                const categoryHeights = $categories.map($category => $category.outerHeight(true));
                const fittingHeight = calculateFittingHeight();
                $element.find('.reports-list').css('height', fittingHeight);

                /**
                 * @param {number} [attemptHeight] - The potential height of each column in the reports list
                 * @returns {number}
                 *      The height of the reports list that will fit every category into the number of columns
                 *      desired for the breakpoint.
                 */
                function calculateFittingHeight(attemptHeight = calculateFirstAttemptHeight()) {
                    const columnStartIndexes = calculateColumnStartIndexes(attemptHeight);
                    if (columnStartIndexes.length <= breakpoint.columns) {
                        return attemptHeight;
                    }
                    const lastColumnStartIndex = columnStartIndexes[breakpoint.columns];
                    const overflowingCategoryHeights = categoryHeights.slice(lastColumnStartIndex);
                    const shortestOverflowingCategoryHeight = _.min(overflowingCategoryHeights);
                    const nextAttemptHeight = attemptHeight + shortestOverflowingCategoryHeight;
                    return calculateFittingHeight(nextAttemptHeight);
                }

                /**
                 * @returns {number}
                 */
                function calculateFirstAttemptHeight() {
                    const totalCategoryHeights = _.sum(categoryHeights);
                    const averageColumnHeight = Math.ceil(totalCategoryHeights / breakpoint.columns);
                    const tallestCategory = _.max(categoryHeights);
                    return _.max([averageColumnHeight, tallestCategory]);
                }

                /**
                 * @param {number} attemptHeight - The potential height of each column in the reports list
                 * @returns {number[]}
                 *      The index of each category where a column would start.
                 *      Ex. [0, 3, 5] means column 1 would start at category index 0,
                 *      column 2 would start at category index 3, column 3 would start at category index 5.
                 */
                function calculateColumnStartIndexes(attemptHeight) {
                    const columnStartIndexes = [0];
                    for (let i = 0; i < $categories.length; i++) {
                        const columnStartIndex = _.last(columnStartIndexes);
                        const potentialColumnHeight = _.sum(categoryHeights.slice(columnStartIndex, i + 1));
                        if (potentialColumnHeight >= attemptHeight) {
                            columnStartIndexes.push(i);
                        }
                    }
                    return columnStartIndexes;
                }
            }
        }
    };
}
