ContentManagementDetailService.$inject = ['$http'];

export default function ContentManagementDetailService($http) {
    return {
        saveContent,
        getContent,
    };

    function saveContent(content) {
        if (!content.isPublic) {
            delete content.isLoginPageLink;
            delete content.loginPageLinkOrder;
        }
        if (content.isAllPerms) {
            // by not passing any roles, all roles will have access
            content.roles = [];
        }
        return $http.post('/api/content', content)
            .then(function(res) {
                return res.data;
            });
    }

    function getContent(id) {
        return $http.get(`/api/content/${id}`)
            .then(function(res) {
                return res.data[0];
            });
    }

}
