accountPendingCtrl.$inject = ['GridColumnTypes', 'GridColumnFilterTypes'];

export default function accountPendingCtrl(GridColumnTypes, GridColumnFilterTypes) {
    this.$onInit = () => {
        const vm = this;

        vm.gridID = vm.gridID || 'grid-account-pending';

        vm.columns = [{
            dataField: 'transactionName',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'account_TRANSACTION_NAME_COLUMN_HEADING',
            }
        }, {
            dataField: 'points',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'app_POINTS',
            }
        }, {
            dataField: 'reference',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'account_REFERENCE_COLUMN_HEADING',
            }
        }, {
            dataField: 'date',
            nonDevExOptions: {
                type: GridColumnTypes.DATE,
                filterType: GridColumnFilterTypes.DATE,
                captionTranslateKey: 'account_DATE_COLUMN_HEADING',
            }
        }];
    };
}
