import dashboardTpl from './dashboard.tpl.html';
import dashboardCtrl from './dashboard.controller';

export default {
    templateUrl: dashboardTpl,
    controller: dashboardCtrl,
    controllerAs: 'vm',
    bindings: {
        dashboard: '<',
        promoDetails: '<',
    }
};
