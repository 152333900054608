import _ from 'lodash';

likedUsersCtrl.$inject = ['SpotlightService', 'Prompt', 'Session', 'MessageService'];

export default function likedUsersCtrl(SpotlightService, Prompt, Session, MessageService) {
    this.$onInit = () => {
        const vm = this;

        /* Assume vm.item has:
            - isLikedByUser
            - totalLikes
            - socialComments
            - totalComments */

        _.defaults(vm, {
            onToggleLike: _.noop,
            onToggleComment: _.noop,
            onDelete: _.noop,
            showSpotlightButtons: true,
            showDeleteButton: true,
            showPrintButton: true
        });

        vm.user = Session.getUser();
        vm.company = Session.getCompany();
    };
}
