import angular from 'angular';

import '../../../core/core.module';

import previewCtrl from './preview.controller';
import PreviewModalService from './previewModalService.factory';

export default angular.module('app.applause.preview', [
    'app.core'
])
.controller('previewCtrl', previewCtrl)
.factory('PreviewModalService', PreviewModalService);
