import tableWidgetTpl from './tableWidget.tpl.html';
import tableWidgetCtrl from './tableWidget.controller';

tableWidget.$inject = ['CoreDirectivesService', '$compile', '$timeout'];

export default function tableWidget(CoreDirectivesService, $compile, $timeout) {
    return {
        templateUrl: tableWidgetTpl,
        controller: tableWidgetCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            contact: '<',
            hasData: '<',
            stats: '<',
            subStats: '<',
            columnHeadings: '<',
            columnValues: '<',
            cellTemplates: '<',
            showFooter: '<',
        },
        transclude: {
            aboveTable: '?aboveTable',
            belowTable: '?belowTable',
        },
        link: function(scope, element, attrs, vm, transcludeFn) {
            if (!vm.hasData) {
                return;
            }

            CoreDirectivesService.removeExtraTranscludeTags(element, transcludeFn, 'aboveTable', 'above-table');
            CoreDirectivesService.removeExtraTranscludeTags(element, transcludeFn, 'belowTable', 'below-table');

            // Need timeout because the element selectors don't return anything before it
            $timeout(() => {
                const contactCellTemplate = vm.cellTemplates.contact;
                if (contactCellTemplate) {
                    vm.columnValues.forEach((columnValue, index) => {
                        const placeholderID = `widgets-available-id-${vm.widget.widgetsAvailableID}-row-${index + 1}-column-contact-cell-template`;
                        replaceCellTemplate({
                            cellTemplate: contactCellTemplate,
                            placeholderID,
                            columnValue
                        });
                    });
                }
                for (let i = 1; i <= vm.maxColumnValues; i++) {
                    const cellTemplate = vm.cellTemplates['column' + i];
                    if (cellTemplate) {
                        vm.columnValues.forEach((columnValue, index) => {
                            const placeholderID = `widgets-available-id-${vm.widget.widgetsAvailableID}-row-${index + 1}-column-${i}-cell-template`;
                            replaceCellTemplate({
                                cellTemplate,
                                placeholderID,
                                columnValue
                            });
                        });
                    }
                }
            });

            function replaceCellTemplate({ cellTemplate, placeholderID, columnValue }) {
                const cellScope = scope.$parent.$new(false);
                cellScope.columnValue = columnValue;
                const cellTemplatePlaceholder = element.find('#' + placeholderID);
                const compiledCellTemplate = $compile(cellTemplate)(cellScope);
                cellTemplatePlaceholder.replaceWith(compiledCellTemplate);
            }
        }
    };
}
