promoQuizApproveCtrl.$inject = ['PromoQuizService', 'SuggestsTypes'];

export default function promoQuizApproveCtrl(PromoQuizService, SuggestsTypes) {
    this.$onInit = () => {
        const vm = this;

        // TODO: find way to un-duplicate the code below with promoQuizSection.controller.js

        const sectionID = vm.section.sectionID;
        const quizResultID = vm.promoDetails.results.quizResultID;
        const defaultPoints = vm.promoDetails.results.defaultPoints;
        const defaultBudgetID = vm.promoDetails.results.defaultBudgetID;

        vm.approveModel = {};

        if (defaultPoints) {
            // set default points if provided
            vm.approveModel.points = defaultPoints;
        }

        if (defaultBudgetID) {
            // set default budget if one is provided
            const defaultBudgetName = vm.promoDetails.results.defaultBudgetName;
            vm.approveModel.budget = { ID: defaultBudgetID, extraInfo: 0, phrase: defaultBudgetName };
        }

        vm.approveFields = [{
            key: 'note',
            type: 'customTextarea',
            templateOptions: {
                maxlength: 500,
                placeholderTranslateKey: 'app_PROMO_QUIZ_SECTION_APPROVE_ADD_NOTE_PLACEHOLDER', // 'Optional: Add note'
                controlClass: 'promo-submission-note-field',
                resetAfterSubmit: true
            }
        },
        {
            key: 'points',
            type: 'customIntInput',
            templateOptions: {
                placeholderTranslateKey: 'app_PROMO_QUIZ_SECTION_APPROVE_POINTS_PLACEHOLDER', // 'Points'
                controlClass: 'col-sm-4 promo-submission-points-field',
                resetAfterSubmit: true
            },
        },
        {
            key: 'budget',
            type: 'customSuggest',
            templateOptions: {
                type: SuggestsTypes.MY_BUDGETS,
                placeholderTranslateKey: 'app_PROMO_QUIZ_SECTION_APPROVE_BUDGET_PLACEHOLDER', // 'Budget'
                controlClass: 'col-sm-4 promo-submission-budget-field',
                resetAfterSubmit: true
            },
            expressionProperties: {
                'templateOptions.required': function(viewValue, modelValue, scope) {
                    return scope.model.points > 0;
                }
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_APPROVE', // Approve
                    class: 'btn btn-primary',
                    iconClass: '',
                    isDisabled: false
                },
                send: {
                    translateKey: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_APPROVE', // Approve
                    iconClass: 'fa-spinner fa-pulse',
                    isDisabled: true,
                    debounce: 300
                },
                success: {
                    noReset: true,
                },
            },
        };

        vm.submitApproval = function() {
            return PromoQuizService.approveSubmission({
                sectionID,
                quizResultID,
                note: vm.approveModel.note,
                points: vm.approveModel.points,
                budgetID: vm.approveModel.budget ? vm.approveModel.budget.ID : null
            });
        };
    };
}
