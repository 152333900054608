import spotlightItemTpl from './spotlightItem.tpl.html';
import spotlightItemCtrl from './spotlightItem.controller';

export default {
    templateUrl: spotlightItemTpl,
    controller: spotlightItemCtrl,
    controllerAs: 'vm',
    bindings: {
        item: '=',
        onModalOpened: '<',
        onDeleteSuccess: '<',
        displayedOn: '<',
    }
};
