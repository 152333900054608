import postNominationTpl from './postNomination.tpl.html';
import postNominationCtrl from './postNomination.controller';

export default {
    templateUrl: postNominationTpl,
    controller: postNominationCtrl,
    controllerAs: 'vm',
    bindings: {
        applauseForm: '<',
        onCancel: '<',
    }
};
