ReloadableCardService.$inject = ['$http'];

export default function ReloadableCardService($http) {

    async function getStatement({ skip } = {}) {
        // right now tutuka is the only reloadable card we make a webservice call to
        const res = await $http.get('/api/tutuka/statement', {
            params: {
                skip
            }
        });
        return res.data;
    }

    return {
        getStatement
    };
}
