import contentManagementTpl from './contentManagement.tpl.html';

contentManagementConfig.$inject = ['$stateProvider'];

export default function contentManagementConfig($stateProvider) {

    $stateProvider.state('contentManagement', {
        parent: 'admin',
        url: '/content-management',
        views: {
            'main@': 'contentManagementComponent'
        },
        data: {
            pageTitle: 'Content Management',
            permission: 'EditCustomContent'
        },
        resolve: {
            contentList: ['ContentManagementService', function(ContentManagementService) {
                return ContentManagementService.getContentList();
            }],
            roles: ['ContentManagementService', function(ContentManagementService) {
                return ContentManagementService.getRoles();
            }],
            contentTopicsList: ['ContentManagementService', function(ContentManagementService) {
                return ContentManagementService.getContentTopicsList();
            }],
        }
    });
}
