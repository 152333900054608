

const constantUtils = require('./constantUtils');

const GridReportFileStatuses = {
    INITIALIZING: 0,
    PENDING: 1,
    FILE_CREATED: 2,
    EXPIRED: 3,
    ERROR: 4,
    CLOUD: 5,
    COMPLETE: 6,
};

const properties = {};

constantUtils.configure({ constants: GridReportFileStatuses, properties });

module.exports = GridReportFileStatuses;
