import _ from 'lodash';

socialPostFormCtrl.$inject = [
    '$scope', '$state', '$q', 'SocialPostPreviewService', 'SocialPostService', 'FileUtils', 'FileTypeGroups', '$translate'
];

export default function socialPostFormCtrl(
    $scope, $state, $q, SocialPostPreviewService, SocialPostService, FileUtils, FileTypeGroups, $translate
) {
    this.$onInit = () => {
        // Passed from component bindings
        // vm.onSubmitSuccess
        // vm.onSubmitFail

        const vm = this;

        const imageExtensions = FileUtils.getFileTypeGroupExtensions(FileTypeGroups.PICTURE);
        const videoExtensions = FileUtils.getFileTypeGroupExtensions(FileTypeGroups.VIDEO);
        const imageAndVideoExtensions = imageExtensions.concat(videoExtensions);

        vm.socialPostModel = {};

        vm.socialPostFields = [{
            key: 'subject',
            type: 'customInput',
            templateOptions: {
                type: 'text',
                placeholderTranslateKey: 'socialPostForm_SUBJECT_PLACEHOLDER', // Add your subject
                required: true,
                controlClass: 'social-post-form-subject',
                maxlength: 128,
            },
            validation: {
                messages: {
                    maxlength: function (viewValue, modelValue, scope) {
                        // {{ number }} character(s) over the limit
                        if (viewValue) {
                            return $translate.instant('app_CUSTOM_TEXTAREA_VALIDATION_MAX_LENGTH', {
                                number: viewValue.length - scope.to.maxlength
                            });
                        }

                    }
                }
            }
        }, {
            key: 'messageBody',
            type: 'customTextarea',
            templateOptions: {
                placeholderTranslateKey: 'socialPostForm_MESSAGE_BODY_PLACEHOLDER', // Write your comment here...
                required: true,
                controlClass: 'social-post-form-message-body'
            }
        }, {
            key: 'media',
            type: 'customFileUpload',
            templateOptions: {
                labelTranslateKey: 'socialPostForm_FILE_UPLOAD_LABEL', // Upload a file
                validExtensions: imageAndVideoExtensions,
                maxNumberOfFiles: 1,
                maxFileSize: 200000, // 200MB
                xhrUploadOptions: {
                    endpoint: '/api/social-posts/files'
                }
            }
        }];

        vm.submitSocialPost = function() {
            return SocialPostService.submitSocialPost({
                fileGUID: vm.socialPostModel.media && vm.socialPostModel.media[0],
                subject: vm.socialPostModel.subject,
                message: vm.socialPostModel.messageBody
            });
        };

        vm.openSocialPostPreview = function() {
            const isFormValid = vm.rnFormCtrl.checkFormValidity();
            if (!isFormValid) {
                return;
            }
            return SocialPostPreviewService.openSocialPostPreview({
                socialPostModel: vm.socialPostModel,
                mediaFile: _.get(vm.form.fileQuestions.media, 'files[0].data'),
                submitSocialPost: vm.rnFormCtrl.onSubmit
            });
        };
    };
}
