submissionsSummaryGridCtrl.$inject = [
    'SubmissionsGridService', 'GridColumnTypes', 'GridColumnFilterTypes', 'GridUrlFilters', 'GridFilterOperators'
];

export default function submissionsSummaryGridCtrl(
    SubmissionsGridService, GridColumnTypes, GridColumnFilterTypes, GridUrlFilters, GridFilterOperators
) {
    this.$onInit = () => {
        const vm = this;

        vm.gridID = vm.gridID || 'grid-submissions-summary';

        vm.columns = [{
            dataField: 'incentiveName',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'submissions_INCENTIVE_NAME_COLUMN_HEADING'
            }
        }, {
            dataField: 'sectionName',
            nonDevExOptions: {
                type: GridColumnTypes.TEXT,
                filterType: GridColumnFilterTypes.TEXT,
                captionTranslateKey: 'submissions_SECTION_NAME_COLUMN_HEADING'
            }
        },
        SubmissionsGridService.commonColumns.quizResultStatusColumn,
        {
            dataField: 'totalSubmissions',
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'submissions_TOTAL_SUBMISSIONS_COLUMN_HEADING',
                cellTemplate: `
                    <a ng-bind="cell.data.totalSubmissions"
                    ui-sref="{{ ::vm.getAdminGridSref(cell) }}">
                    </a>`
            }
        }, {
            dataField: 'incentiveID',
            visible: false,
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'submissions_INCENTIVE_ID_COLUMN_HEADING'
            }
        }, {
            dataField: 'sectionID',
            visible: false,
            nonDevExOptions: {
                type: GridColumnTypes.NUMBER,
                filterType: GridColumnFilterTypes.NUMBER,
                captionTranslateKey: 'submissions_SECTION_ID_COLUMN_HEADING'
            }
        }];

        vm.getAdminGridSref = function(cell) {
            if (!cell) {
                // For some odd reason, "cell" is undefined sometimes
                return `submissionsHierarchy`;
            }
            const { incentiveID, sectionID, statusID } = cell.data;
            const filters = GridUrlFilters.stringifyFilters([
                { field: 'incentiveID', operator: GridFilterOperators.EQUALS, value: incentiveID },
                { field: 'sectionID', operator: GridFilterOperators.EQUALS, value: sectionID },
                { field: 'statusID', operator: GridFilterOperators.IN, value: [statusID] }
            ]);
            return `submissionsHierarchy({ filters: '${filters}' })`;
        };
    };
}
