appcuesRun.$inject = ['$rootScope', '$window', 'AuthStatus', 'Session', 'LangUtils', 'AUTH_EVENTS', 'SkinUtils'];

export default function appcuesRun($rootScope, $window, AuthStatus, Session, LangUtils, AUTH_EVENTS, SkinUtils) {
    const Appcues = $window.Appcues;
    if (!Appcues) {
        // Appcues is disabled or their CDN is down.
        return;
    }

    if (AuthStatus.isAuthenticated()) {
        const user = Session.getUser();
        if (user.hasPermission('EnableAppCues')) {
            identifyUser(user);
        }
    }

    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, (event, { user }) => {
        if (user.hasPermission('EnableAppCues')) {
            identifyUser(user);
        }
    });

    $rootScope.$on(AUTH_EVENTS.LOGOUT_SUCCESS, () => {
        Appcues.reset();
    });

    $rootScope.$on(AUTH_EVENTS.LOCK_APP, () => {
        Appcues.reset();
    });

    $rootScope.$on('$locationChangeSuccess', function() {
        Appcues.page();
    });

    function identifyUser(user) {
        const company = Session.getCompany();
        Appcues.identify(user.userID, {
            languageCode: LangUtils.getLang().toUpperCase(),
            companyID: company.companyID,
            subDomain: company.subDomain,
            permissionGroupCode: user.permissionGroupCode,
            skinCode: SkinUtils.getSkinCode(),
        });
    }
}
