

const constantUtils = require('./constantUtils');

const OcrStatuses = {
    PENDING: 1,
    RUNNING: 2,
    PASS: 3,
    FAIL: 4,
};

const properties = {
    [OcrStatuses.PENDING]: {
        translateKey: '',
        phrase: 'Validation Pending...'
    },
    [OcrStatuses.RUNNING]: {
        translateKey: '',
        phrase: 'Validation Running...'
    },
    [OcrStatuses.PASS]: {
        translateKey: '',
        phrase: 'Validation Passed'
    },
    [OcrStatuses.FAIL]: {
        translateKey: '',
        phrase: 'Validation Failed'
    },

};

constantUtils.configure({ constants: OcrStatuses, properties });

module.exports = OcrStatuses;
