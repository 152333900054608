import angular from 'angular';

import './postNomination/postNomination.module';
import './promoQuizSection/promoQuizSection.module';
import './promoQuizTools/promoQuizTools.module';

import PromoQuizService from './promoQuizService.factory';
import promoQuiz from './promoQuiz.component';

export default angular.module('app.promo.promoQuiz', [
    'app.promo.promoQuiz.tools',
    'app.promo.promoQuiz.section',
    'app.promo.promoQuiz.postNomination',
])
.factory('PromoQuizService', PromoQuizService)
.component('promoQuiz', promoQuiz);
