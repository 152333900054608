import angular from 'angular';

import '../submissionsGrid/submissionsGrid.module';

import submissionsContactPageConfig from './submissionsContactPage.config';

export default angular.module('app.submissions.contactPage', [
    'app.submissions.submissionsGrid'
])
.config(submissionsContactPageConfig);
