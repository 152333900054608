import _ from 'lodash';

contactPanelLinksCtrl.$inject = ['Session', 'LoginAsService', 'ContactService'];

export default function contactPanelLinksCtrl(Session, LoginAsService, ContactService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from parent template via component bindings:
        // vm.contact

        vm.user = Session.getUser();
        vm.isSelf = vm.user.userID === vm.contact.userID;
        vm.isMyEntity = vm.user.entityID === vm.contact.userID;
        const company = Session.getCompany();

        vm.contactIsDeactivated = vm.contact.isDeactivated();

        const recognitionParticipantParam = 'personal';

        vm.TOOLS = {
            DEACTIVATE_USER: 'DEACTIVATE_USER',
            REACTIVATE_USER: 'REACTIVATE_USER',
            RESET_PASSWORD: 'RESET_PASSWORD',
            RESEND_INVITE: 'RESEND_INVITE',
        };

        const allLinks = {
            snapshot: {
                show: (function showSnapshotLink() {
                    if (vm.isSelf) {
                        return false;
                    }
                    if (vm.contact.isEntity) {
                        return vm.isMyEntity
                            && vm.user.hasAnyPermission(['ViewMyEntitySnapshot', 'ViewMyEntityPrivateSnapshot']);
                    }
                    return vm.user.hasPermission('ViewSnapshot');
                }()),
                uiSref: `snapshot({ contactID: ${vm.contact.userID} })`,
                cssClass: 'snapshot-link',
                iconClass: 'fa fa-user-o',
                text: 'app_CONTACT_PANEL_LINKS_SNAPSHOT',
            },
            account: {
                show: (function showAccountLink() {
                    if (vm.isSelf) {
                        return vm.user.hasPermission('ViewAccounts');
                    }
                    if (vm.contact.isEntity) {
                        return vm.user.hasPermission('ViewAccountsEntities') && (vm.isMyEntity || vm.contact.isDownstream);
                    }
                    return vm.contact.isDownstream && vm.user.hasPermission('ViewAccountsUsers');
                }()),
                uiSref: `account({ contactID: ${vm.contact.userID} })`,
                cssClass: 'account-link',
                iconClass: 'fa fa-briefcase',
                text: 'app_CONTACT_PANEL_LINKS_ACCOUNT',
            },
            recognition: {
                show: (function showRecognitionLink() {
                    if (company.hasOption('IsGlobalRecognition')) {
                        return true;
                    }
                }()),
                uiSref: `spotlightItems({ 
                    code: 'contact',
                    contactID: ${vm.contact.userID},
                    participant: ${recognitionParticipantParam}
                })`,
                cssClass: 'spotlight-link',
                iconClass: 'fa fa-star-o',
                text: 'app_CONTACT_PANEL_LINKS_RECOGNITION',
            },
            redeemFor: {
                show: ContactService.canUserRedeemFor(vm.contact),
                uiSref: `rewards`,
                cssClass: 'redeem-for-link',
                iconClass: 'fa fa-gift',
                text: 'app_CONTACT_PANEL_LINKS_REDEEM_FOR',
            },
            editProfile: {
                show: (function showEditProfileLink() {
                    if (vm.contactIsDeactivated) {
                        return false;
                    }
                    if (vm.contact.isEntity) {
                        return vm.user.hasPermission('EditEntities');
                    }
                    return vm.user.hasPermission('EditUsers');
                }()),
                uiSref: `legacyFramePage({ urlPath: 'ProfileEdit.asp?ContactId=${vm.contact.userID}&IsFrame=1' })`,
                cssClass: 'edit-profile-link',
                iconClass: 'fa fa-pencil-square-o',
                text: 'app_CONTACT_PANEL_LINKS_EDIT_PROFILE',
            },
            deactivate: {
                show: (function showDeactivateLink() {
                    if (vm.contactIsDeactivated || vm.isSelf || !vm.contact.manager) {
                        return false;
                    }
                    if (vm.contact.isEntity) {
                        return vm.user.hasPermission('DeleteEntities');
                    }
                    return vm.user.hasPermission('DeleteUsers');
                }()),
                onClick: () => toggleActiveTool(vm.TOOLS.DEACTIVATE_USER),
                cssClass: 'deactivate-link',
                iconClass: 'fa fa-trash-o',
                text: 'app_CONTACT_PANEL_LINKS_DEACTIVATE',
            },
            resetPassword: {
                show: (function showResetPasswordLink() {
                    if (vm.contactIsDeactivated || vm.isSelf || vm.contact.isEntity) {
                        return false;
                    }
                    return vm.user.hasPermission('ResetPassword');
                }()),
                onClick: () => toggleActiveTool(vm.TOOLS.RESET_PASSWORD),
                cssClass: 'reset-password-link',
                iconClass: 'fa fa-lock',
                text: 'app_CONTACT_PANEL_LINKS_MANAGE_PASSWORD',
            },
            resendInvite: {
                show: (function showResendInviteLink() {
                    if (vm.contactIsDeactivated || vm.isSelf || vm.contact.isEntity) {
                        return false;
                    }
                    return vm.user.hasPermission('SendInvitationUsers');
                }()),
                onClick: () => toggleActiveTool(vm.TOOLS.RESEND_INVITE),
                cssClass: 'resend-invite-link',
                iconClass: 'fa fa-paper-plane-o',
                text: 'app_CONTACT_PANEL_LINKS_RESEND_INVITE',
            },
            loginAs: {
                show: (function showLoginAsLink() {
                    if (vm.contactIsDeactivated || vm.isSelf || vm.contact.isEntity) {
                        return false;
                    }
                    return vm.user.hasPermission('LoginAs');
                }()),
                onClick: () => LoginAsService.loginAsContact({ contactID: vm.contact.userID }),
                cssClass: 'login-as-link',
                iconClass: 'fa fa-key',
                text: 'app_CONTACT_PANEL_LINKS_LOGIN_AS',
            },
            reactivate: {
                show: (function showReactivateLink() {
                    if (!vm.contactIsDeactivated || vm.isSelf || !vm.contact.manager) {
                        return false;
                    }
                    return vm.user.hasPermission('UndeleteContact');
                }()),
                onClick: () => toggleActiveTool(vm.TOOLS.REACTIVATE_USER),
                cssClass: 'reactivate-link',
                iconClass: 'fa fa-user-plus',
                text: 'app_CONTACT_PANEL_LINKS_REACTIVATE',
            },
            LoginAsPreviousUser: {
                show: (function showLoginAsPreviousUser() {
                    return (vm.isSelf === true && vm.contact.previousContactID !== undefined);
                }()),
                onClick: () => LoginAsService.previousUserLogin({ contactID: vm.contact.userID }),
                cssClass: 'login-as-link',
                iconClass: 'fa fa-key',
                text: 'app_CONTACT_PANEL_LINKS_PREVIOUS_ACC',
            },
        };

        vm.links = _(allLinks).pickBy(link => link.show).values().value();

        vm.isShowLinks = vm.links.length >= 1 || (vm.contactIsDeactivated && vm.user.hasPermission('UndeleteContact'));

        vm.closeTool = () => toggleActiveTool(null);

        function toggleActiveTool(tool) {
            if (vm.toolOpen === tool) {
                return vm.toolOpen = null;
            }
            return vm.toolOpen = tool;
        }
    };
}
