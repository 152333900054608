import { TRANSACTIONS_PER_PAGE } from '../../../../../../shared/ReloadableCardConstants';

reloadableCardStatementCtrl.$inject = ['statement', 'ReloadableCardService'];

export default function reloadableCardStatementCtrl(statement, ReloadableCardService) {

    const vm = this;

    const { totalTransactions, transactions } = statement;

    vm.totalTransactions = totalTransactions;
    vm.transactions = transactions;

    vm.currentPage = 1;
    vm.totalPages = Math.ceil(vm.totalTransactions / TRANSACTIONS_PER_PAGE);

    vm.nextPage = async () => {
        const skip = vm.currentPage * TRANSACTIONS_PER_PAGE;
        await updatePage(skip);
        vm.currentPage++;
    };

    vm.prevPage = async () => {
        // Ex: if on page 2, when going to page 1 skip will be 0
        const skip = (vm.currentPage - 2) * TRANSACTIONS_PER_PAGE;
        await updatePage(skip);
        vm.currentPage--;
    };

    async function updatePage(skip) {
        const { totalTransactions, transactions } = await ReloadableCardService.getStatement({ skip });
        vm.totalTransactions = totalTransactions;
        vm.transactions = transactions;
    }
}
