import promoQuizDeclineTpl from './decline.tpl.html';
import promoQuizDeclineCtrl from './decline.controller';

export default {
    templateUrl: promoQuizDeclineTpl,
    controller: promoQuizDeclineCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        section: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<'
    }
};
