import _ from 'lodash';

spotlightRun.$inject = ['$transitions', '$state', 'AuthStatus', 'Session', 'StateUtils'];

export default function spotlightRun($transitions, $state, AuthStatus, Session, StateUtils) {

    $transitions.onBefore({ to: 'spotlight' }, function(transition) {

        const params = Object.assign({}, transition.params());

        let defaultSpotlightState;

        if (AuthStatus.isAuthenticated()) {
            const user = Session.getUser();
            const company = Session.getCompany();

            const userPermissionGroupCode = user.permissionGroup.code;
            const defaultSpotlightUrl = company.permissionGroups[userPermissionGroupCode].defaultSpotlightPage;
            defaultSpotlightState = StateUtils.getStateFromUrl(defaultSpotlightUrl);
        }
        const newParams = defaultSpotlightState ? _.merge({}, defaultSpotlightState.params, params) : params;

        // In case the defaultSpotlightPage doesn't include the code param, set a default.
        if (!newParams.code) {
            newParams.code = 'items';
        }

        return $state.target('spotlightItems', newParams, { reload: true });
    });
}
