import promoListTpl from './promoList.tpl.html';
import promoListCtrl from './promoList.controller';

export default {
    templateUrl: promoListTpl,
    controller: promoListCtrl,
    controllerAs: 'vm',
    bindings: {
        promoList: '<',
        promoGroups: '<',
        isDefaultView: '<',
        promoGroupIndex: '<',
        isPromoExist: '<',
        activeGroupId: '<',
        isAward: '<'
    }
};
