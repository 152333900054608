import socialPostDetailsTpl from './socialPostDetails.tpl.html';
import socialPostDetailsCtrl from './socialPostDetails.controller';

export default {
    templateUrl: socialPostDetailsTpl,
    controller: socialPostDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        socialPost: '<',
        mediaFile: '<',
        onDelete: '<'
    }
};
