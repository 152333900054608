import _ from 'lodash';
import angular from 'angular';

dropdownCtrl.$inject = ['$scope', 'DropdownSelectionService', 'GridColumnFilterTypes', 'GridFilterOperators'];

export default function dropdownCtrl($scope, DropdownSelectionService, GridColumnFilterTypes, GridFilterOperators) {

    const dropdownVm = this;

    // TODO: this shouldn't be a separate controller, combine everything into the filterModal controller

    dropdownVm.GridColumnFilterTypes = GridColumnFilterTypes;
    dropdownVm.GridFilterOperators = GridFilterOperators;

    // To prevent angular modifying the original column and operators objects
    dropdownVm.columns = angular.copy(DropdownSelectionService.getColumns());

    if (DropdownSelectionService.isEditing()) {
        const filter = DropdownSelectionService.getFilter();
        dropdownVm.selectedColumn = dropdownVm.columns.find(column => column.dataField === filter.field);
        dropdownVm.selectedColumnDataField = dropdownVm.selectedColumn.dataField;
        dropdownVm.selectedOperator = filter.operator;
        dropdownVm.selectedValue = filter.value;
    } else {
        dropdownVm.selectedColumn = dropdownVm.columns[0];
        dropdownVm.selectedColumnDataField = dropdownVm.selectedColumn.dataField;
        dropdownVm.selectedOperator = GridColumnFilterTypes.properties[dropdownVm.selectedColumn.filterType].filterOperators[0];
    }

    initializeListOptions();

    updateDropdown();

    dropdownVm.onColumnChange = function() {
        dropdownVm.selectedColumn = dropdownVm.columns.find(column => column.dataField === dropdownVm.selectedColumnDataField);
        dropdownVm.selectedOperator = GridColumnFilterTypes.properties[dropdownVm.selectedColumn.filterType].filterOperators[0];

        if (dropdownVm.selectedColumn.filterType === GridColumnFilterTypes.BOOLEAN) {
            $scope.vm.filterValueInput = true;
        } else {
            // Clears input field when column is changed
            // TODO: this is too coupled to the fact that dropdownCtrl is a child scope of filterModalCtrl
            $scope.vm.filterValueInput = null;
        }

        if (dropdownVm.selectedColumn.filterType === GridColumnFilterTypes.TEXT) {
            dropdownVm.valueInputType = 'text';
        } else if (dropdownVm.selectedColumn.filterType === GridColumnFilterTypes.NUMBER) {
            dropdownVm.valueInputType = 'number';
        }

        updateDropdown();
    };

    dropdownVm.onOperatorChange = function() {
        updateDropdown();
    };

    dropdownVm.onListOptionChanged = function(option) {
        if (dropdownVm.selectedColumn.filterType === GridColumnFilterTypes.RADIO) {
            DropdownSelectionService.setListValues(option.value);
        } else {
            const selectedValues = dropdownVm.selectedListOptions[dropdownVm.selectedColumn.dataField] = dropdownVm.selectedColumn.listOptions
                .filter(option => option.isSelected)
                .map(option => option.value);
            DropdownSelectionService.setListValues(selectedValues);
        }
    };

    function initializeListOptions() {
        dropdownVm.selectedListOptions = {};

        dropdownVm.columns
            .filter(column => column.filterType === GridColumnFilterTypes.RADIO)
            .forEach(function(column) {
                // Will be undefined when adding new filter
                dropdownVm.selectedListOptions[column.dataField] = dropdownVm.selectedValue;
            });

        dropdownVm.columns
            .filter(column => column.filterType === GridColumnFilterTypes.CHECKBOX)
            .forEach(function(column) {
                dropdownVm.selectedListOptions[column.dataField] = [];
                if (column === dropdownVm.selectedColumn && _.isArray(dropdownVm.selectedValue)) {
                    column.listOptions
                        .filter(option => dropdownVm.selectedValue.some(value => String(value) === String(option.value) || angular.equals(value, option.value)
                        ))
                        .forEach(option => option.isSelected = true);
                }
            });
    }

    function updateDropdown() {
        toggleDropdownFields();
        sendDropdownSettings();
    }

    /**
     * Shows/hides certain elements on the filter modal depending on the selected column
     */
    function toggleDropdownFields() {
        switch (dropdownVm.selectedColumn.filterType) {
            case GridColumnFilterTypes.RADIO:
            case GridColumnFilterTypes.CHECKBOX:
                dropdownVm.showOperatorDropdown = false;
                dropdownVm.showTextInput = false;
                dropdownVm.showListOptions = true;
                dropdownVm.showBooleanToggle = false;
                dropdownVm.showDatePicker = false;
                break;
            case GridColumnFilterTypes.DATE:
                dropdownVm.showOperatorDropdown = true;
                dropdownVm.showTextInput = false;
                dropdownVm.showListOptions = false;
                dropdownVm.showBooleanToggle = false;
                dropdownVm.showDatePicker = [
                    GridFilterOperators.EQUALS,
                    GridFilterOperators.BEFORE,
                    GridFilterOperators.AFTER
                ].includes(dropdownVm.selectedOperator);
                break;
            case GridColumnFilterTypes.BOOLEAN:
                dropdownVm.showOperatorDropdown = false;
                dropdownVm.showTextInput = false;
                dropdownVm.showListOptions = false;
                dropdownVm.showBooleanToggle = true;
                dropdownVm.showDatePicker = false;
                break;
            case GridColumnFilterTypes.TEXT:
            case GridColumnFilterTypes.NUMBER:
            default:
                dropdownVm.showOperatorDropdown = true;
                dropdownVm.showTextInput = true;
                dropdownVm.showListOptions = false;
                dropdownVm.showBooleanToggle = false;
                dropdownVm.showDatePicker = false;
        }
    }

    /**
     * Passes selectedColumn and selectedOperator to DropdownSelectionService
     */
    function sendDropdownSettings() {
        const filter = {
            field: dropdownVm.selectedColumn.dataField,
            type: dropdownVm.selectedColumn.filterType, // TODO: refactor to not have to pass this here
        };
        // TODO: this isn't the best place to do this. is there a better way?
        const filterType = dropdownVm.selectedColumn.filterType;
        if (filterType === GridColumnFilterTypes.CHECKBOX) {
            filter.operator = GridFilterOperators.IN;
        } else if ([GridColumnFilterTypes.RADIO, GridColumnFilterTypes.BOOLEAN].includes(filterType)) {
            filter.operator = GridFilterOperators.EQUALS;
        } else {
            filter.operator = dropdownVm.selectedOperator;
        }
        DropdownSelectionService.setFilter(filter);
    }
}
