import angular from 'angular';

import '@uirouter/angularjs';

import '../../blocks/storage/storage.module';
import '../constant/coreConstants.module';

import stateUtilsRun from './stateUtils.run';

import SharedModuleService from './sharedModuleService.factory';
import StringInterpolationService from './stringInterpolationService.factory';
import StateUtils from './stateUtils.factory';
import ContentService from './contentService.factory';
import GeoService from './geoService.factory';
import ModalService from './modalService.factory';
import PageTitle from './pageTitle.factory';
import PointTrackingService from './PointTrackingService.factory';
import PdfService from './PdfService.factory';
import MessageService from './messageService.factory';
import UrlUtils from './urlUtils.factory';
import StrengthsService from './strengthsService.factory';
import FileUtils from './fileUtils.factory';
import SkinUtils from './skinUtils.factory';
import TabRefreshService from './tabRefreshService.factory';
import Company from './company.factory';
import Contact from './contact.factory';
import Socket from './socket.factory';


export default angular.module('app.core.utils', [
    'ui.router',

    'app.blocks.storage',
    'app.core.constants',
])
.run(stateUtilsRun)
.factory('SharedModuleService', SharedModuleService)
.factory('StringInterpolationService', StringInterpolationService)
.factory('StateUtils', StateUtils)
.factory('ContentService', ContentService)
.factory('GeoService', GeoService)
.factory('ModalService', ModalService)
.factory('PageTitle', PageTitle)
.factory('PointTrackingService', PointTrackingService)
.factory('PdfService', PdfService)
.factory('MessageService', MessageService)
.factory('UrlUtils', UrlUtils)
.factory('StrengthsService', StrengthsService)
.factory('FileUtils', FileUtils)
.factory('SkinUtils', SkinUtils)
.factory('TabRefreshService', TabRefreshService)
.factory('Company', Company)
.factory('Contact', Contact)
.factory('Socket', Socket);
