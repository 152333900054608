import angular from 'angular';

import '../../../core/core.module';

import loginAsConfirmActionModalCtrl from './loginAsConfirmActionModal.controller';
import LoginAsConfirmActionModalService from './loginAsConfirmActionModalService.factory';

export default angular.module('app.loginAs.confirmAction', [
    'app.core'
])
.controller('loginAsConfirmActionModalCtrl', loginAsConfirmActionModalCtrl)
.factory('LoginAsConfirmActionModalService', LoginAsConfirmActionModalService);
