import textareaTpl from './textarea.tpl.html';

customTextareaRun.$inject = ['$translate', 'formlyConfig'];

export default function customTextareaRun($translate, formlyConfig) {
    formlyConfig.setType({
        name: 'customTextarea',
        templateUrl: textareaTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        controller: ['$scope', function($scope) {
            const to = $scope.to;
            if (to.placeholderTranslateKey) {
                to.placeholder = $translate.instant(to.placeholderTranslateKey);
            }
        }],
        defaultOptions: {
            expressionProperties: {
                'data.lengthStatus': function(viewValue, modelValue, scope) {
                    const value = viewValue || modelValue;
                    const to = scope.to;
                    if (!value || !value.length || !to.maxlength) {
                        return;
                    }
                    if (value.length > to.maxlength) {
                        // maxlength validation message will show at this point, no need for lengthStatus to show
                        return;
                    }
                    // When the input length reaches this percentage of the maxlength, the status will show.
                    // Otherwise, it would be annoying if it was showing the whole time when maxlength was 1000 for example.
                    const LENGTH_STATUS_MINIMUM_FACTOR = 0.8;
                    if (value.length < to.maxlength * LENGTH_STATUS_MINIMUM_FACTOR) {
                        return;
                    }
                    // {{ number }} character(s) remaining
                    return $translate.instant('app_CUSTOM_TEXTAREA_LENGTH_STATUS_CHARACTERS_REMAINING', {
                        number: to.maxlength - value.length
                    });
                }
            },
            ngModelElAttrs: {
                rows: '{{ to.rows }}'
            },
            modelOptions: {
                debounce: {
                    default: 200,
                    blur: 0
                },
                updateOn: 'default blur'
            },
            validation: {
                messages: {
                    minlength: function (viewValue, modelValue, scope) {
                        // {{ number }} more character(s) needed
                        if (viewValue) {
                            return $translate.instant('app_CUSTOM_TEXTAREA_VALIDATION_MIN_LENGTH', {
                                number: scope.to.minlength - viewValue.length
                            });
                        }
                    },
                    maxlength: function (viewValue, modelValue, scope) {
                        // {{ number }} character(s) over the limit
                        if (viewValue) {
                            return $translate.instant('app_CUSTOM_TEXTAREA_VALIDATION_MAX_LENGTH', {
                                number: viewValue.length - scope.to.maxlength
                            });
                        }

                    }
                }
            }
        }
    });
}
