import angular from 'angular';

import '../../../../core/core.module';

import promoTargetsTable from './promoTargetsTable.component';

export default angular.module('app.promo.promoTargetsTable', [
    'app.core'
])
.component('promoTargetsTable', promoTargetsTable);
