import trainingQuizRadioTpl from './trainingQuizRadio.tpl.html';

trainingQuizRun.$inject = ['formlyConfig'];

export default function trainingQuizRun(formlyConfig) {
    formlyConfig.setType({
        name: 'trainingQuizRadio',
        extends: 'customRadioInput',
        templateUrl: trainingQuizRadioTpl
    });
}
