import _ from 'lodash';

snapshotCtrl.$inject = ['contact', 'widgetPages', 'MessageService', 'SnapshotPreviewService', 'SnapshotService'];

export default function snapshotCtrl(contact, widgetPages, MessageService, SnapshotPreviewService, SnapshotService) {
    const vm = this;

    MessageService.openFromUrl();
    vm.contact = contact;
    vm.previewPrintPDF = previewPrintPDF;

    if (_.isEmpty(widgetPages)) {
        vm.noWidgets = true;
        return;
    }

    
    // formatting the widgetPages with some default values;
    vm.widgetPages = widgetPages.map((page, index) => formatPage({ page, index }));

    // function bindings to VM
    // -----------------------------------------------------------------------------------------------------------------
    vm.updateActiveSet = updateActiveSet;

    // function definitions
    // -----------------------------------------------------------------------------------------------------------------
    function formatPage({ page, index }) {
        const formattedPage = page;
        formattedPage.index = index;
        formattedPage.setOptions = page.sets.map(set => set.title);
        formattedPage.sets = page.sets.map(set => {
            const formattedSet = set;
            if (set.filters) {
                formattedSet.filters = set.filters.map(filter => {
                    const formattedFilter = filter;
                    switch(filter.filterType) {
                        case "customDate":
                            if(filter.default && _.isObject(filter.default)) {
                                if(filter.default.code === 'Custom') {
                                    formattedFilter.displayValue = $translate.instant('snapshot_FILTER_DATE_OPTION_CUSTOM_DATE', {
                                        startDate: moment(filter.default.startDate).format('YYYY-MM-DD'),
                                        endDate: moment(filter.default.endDate).format('YYYY-MM-DD'),
                                    });
                                    formattedFilter[filter.parameters.startDate] = filter.default.startDate;
                                    formattedFilter[filter.parameters.endDate] = filter.default.endDate;
                                } else {
                                    formattedFilter.currentValue = _.clone(SnapshotService.getDateFilterValueByCode({
                                        option: filter.default,
                                        filter,
                                    }));
                                    formattedFilter.displayValue = SnapshotService.getDateFilterOptions()
                                            .find(option => option.code === filter.default.code).phrase;
                                    
                                }
                            } else {
                                formattedFilter.displayValue = filter.displayName;
                                formattedFilter.currentValue = {};
                            }
                            break;
                        case 'shortList':
                            if(filter.default && _.isObject(filter.default) && filter.default.parameterValue) {
                                formattedFilter.currentValue = filter.shortList
                                    .find(option => option.parameterValue === filter.default.parameterValue);
                            } else {
                                filter.currentValue = { name: filter.displayName }
                            }
                            break;
                        case 'suggests':
                            if(filter.default && _.isObject(filter.default) && filter.default.parameterValue) {
                                filter.showSuggest = true;
                                formattedFilter.currentValue = {
                                    phrase: filter.default.parameterValue,
                                    parameterValue: filter.default.parameterValuem,
                                };
                            }
                    }
                    return formattedFilter;
                });
            }
            return formattedSet;
        });
        return formattedPage;
    }

    function updateActiveSet(page) {
        vm.activeSetIndex = 0;
        page.selectedSetTitle = page.setOptions[0];
    }

    function previewPrintPDF(widgtePages, activePageIndex, activeSetIndex, contact) {
        SnapshotPreviewService.preview(widgtePages, activePageIndex, activeSetIndex, contact)
    }
}
