DatafeedService.$inject = ['$http'];

export default function DatafeedService($http) {
    /**
     * @param {Object} obj
     * @param {string} obj.codeName - CodeName of the datafeed (CompanyMetrics.CodeName)
     * @param {boolean} obj.isHierarchy
     */
    async function getGridSetup({ codeName, isHierarchy }) {
        const url = `/api/datafeed/${codeName}/setup/${isHierarchy ? 'hierarchy' : 'contact'}`;
        const res = await $http.get(url);
        return res.data;
    }

    /**
     * @param {Object} params
     * @param {string} params.codeName - CodeName of the datafeed (CompanyMetrics.CodeName)
     * @param {boolean} params.isHierarchy
     * @param {int} params.contactID - The contact to be viewed
     * @returns {string}
     */
    function getGridQueryUrl({ codeName, isHierarchy, contactID }) {
        return `/api/datafeed/${codeName}/${isHierarchy ? 'hierarchy' : `contact`}/${contactID}`;
    }

    return {
        getGridSetup,
        getGridQueryUrl,
    };
}
