import rnFormSubmitButtonTpl from './rnFormSubmitButton.tpl.html';
import rnFormSubmitButtonCtrl from './rnFormSubmitButton.controller';

RnFormSubmitButton.$inject = [];

export default function RnFormSubmitButton() {
    return {
        restrict: 'E',
        templateUrl: rnFormSubmitButtonTpl,
        require: '^^rnForm',
        scope: {},
        bindToController: {
            options: '<',
            disableWhileInvalid: '<',
            parentClass: '@',
        },
        controller: rnFormSubmitButtonCtrl,
        controllerAs: 'vm',
        link: function(scope, element, attrs, rnFormCtrl) {
            scope.vm.rnFormCtrl = rnFormCtrl;
            rnFormCtrl.addButton(scope.vm);
        },
    };
}
