import legacyMessageTpl from './legacyMessage.tpl.html';
import legacyMessageCtrl from './legacyMessage.controller';

export default function legacyMessage() {
    return {
        templateUrl: legacyMessageTpl,
        controller: legacyMessageCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            messageDetails: '<',
            isInModal: '<',
        }
    };
}
