
reportConfig.$inject = ['$stateProvider', '$urlMatcherFactoryProvider'];

export default function reportConfig($stateProvider) {
    $stateProvider.state('report', {
        parent: 'dashboard',
        url: '/:reportCode?filter&column&sort',
        params: {
            filter: {
                type: 'string',
                value: null,
                dynamic: true,
            },
            column: {
                type: 'string',
                value: null,
                dynamic: true,
            },
            sort: {
                type: 'string',
                value: null,
                dynamic: true,
            }
        },
        views: {
            'report': 'reportComponent',
        },
        data: {
            permission: 'UserReports',
        },
        resolve:{
            report: ['DashboardService', '$stateParams', function (DashboardService, $stateParams) {
                return DashboardService.getReport($stateParams);
            }],
            defaultReportName: ['$stateParams', function($stateParams) {
                return [$stateParams.dashboardCode, $stateParams.dashboardID, $stateParams.reportCode].join('_');
            }],
        }
    });
}
