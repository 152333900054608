import rnFormTpl from './rnForm.tpl.html';
import rnFormCtrl from './rnForm.controller';

rnForm.$inject = [];

export default function rnForm() {
    return {
        restrict: 'E',
        templateUrl: rnFormTpl,
        controller: rnFormCtrl,
        controllerAs: 'vm',
        transclude: true,
        bindToController: true,
        scope: {
            model: '=',
            fields: '=',
            options: '=',

            // Gives parent controller access to the FormController underneath the formly-form - https://docs.angularjs.org/api/ng/type/form.FormController
            form: '=',

            // Gives parent controller access to rnFormCtrl
            rnFormCtrl: '=',

            submit: '<',
            onValidating: '<',
            onValidationDone: '<',
            onSending: '<',
            onSubmitSuccess: '<',
            onSubmitFail: '<',
            successDisplayTime: '<',
            disableFieldsDuringSubmit: '<',
            disableFieldsAfterSuccess: '<',
        }
    };
}
