import postSubmitTpl from './postSubmit.tpl.html';

postSubmitConfig.$inject = ['$stateProvider'];

export default function postSubmitConfig($stateProvider) {
    $stateProvider.state('spotlightPostSubmitPage', {
        url: '/spotlight/post-submit?from',
        parent: 'auth',
        views: {
            'main@': {
                controller: 'postSubmitCtrl as vm',
                templateUrl: postSubmitTpl
            }
        },
        data: {
            pageTitle: 'Spotlight Post-Submit',
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('spotlight');
            }]
        }
    });
}
