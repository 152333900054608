export default function ngEnter() {
    return {
        restrict: 'A',
        link
    };

    function link(scope, element, attrs) {
        element.bind('keydown keypress', function(event) {
            if (event.which === 13 && !event.ctrlKey && !event.shiftKey && !event.altKey) {
                scope.$apply(function() {
                    scope.$eval(attrs.ngEnter);
                });

                event.preventDefault();
            }
        });
    }
}
