

const ContactStatuses = require('./ContactStatuses');

class SharedContactModel {

    /**
     * @param {Company} company - Company the contact belongs to
     * @param {Object} contactData - JSON from Contacts_Get or Contacts_GetOther SP
     */
    constructor(company, contactData) {
        Object.assign(this, contactData);

        this.permissionGroup = company.permissionGroups[this.permissionGroupCode];
        if (!this.permissionGroup) {
            throw new Error(`Contact's permission group does not exist`);
        }
    }

    /**
     * @param {string} permission
     * @returns {boolean}
     */
    hasPermission(permission) {
        return this.permissionGroup.permissions[permission] != null;
    }

    /**
     * @param {string[]} permissions
     * @returns {boolean}
     */
    hasAnyPermission(permissions) {
        return permissions.some(permission => this.hasPermission(permission));
    }

    /**
     * @param {string[]} permissions
     * @returns {boolean}
     */
    hasAllPermissions(permissions) {
        return permissions.every(permission => this.hasPermission(permission));
    }

    /**
     * @returns {boolean}
     */
    isInvited() {
        return this.status.ID === ContactStatuses.INVITED;
    }

    /**
     * @returns {boolean}
     */
    isActive() {
        return this.status.ID === ContactStatuses.ACTIVE;
    }

    /**
     * @returns {boolean}
     */
    isPassive() {
        return this.status.ID === ContactStatuses.PASSIVE;
    }

    /**
     * @returns {boolean}
     */
    isDeactivated() {
        return this.status.ID === ContactStatuses.DELETED;
    }

    /**
     * @returns {boolean}
     */
    canApplaud() {
        return this.canApplaudIndividual() || this.canApplaudTeam();
    }

    /**
     * @returns {boolean}
     */
    canApplaudIndividual() {
        return this.hasAnyPermission([
            'Applaud',
            'ApplaudPoints',
            'ApplauseOutsideAccessPoints',
        ]);
    }

    /**
     * @returns {boolean}
     */
    canApplaudTeam() {
        return this.hasAnyPermission([
            'TeamApplaud',
            'TeamApplaudPoints',
        ]);
    }
}

module.exports = SharedContactModel;
