import customRTETpl from './customRTE.tpl.html';

customRTERun.$inject = ['formlyConfig'];

// TODO: removed debounce because it was casuing cursor jumping issues, add back into modelOptions later with testing
// debounce: {
//     default: 1000,
//     blur: 1000
// },
// updateOn: 'default blur'

export default function customRTERun(formlyConfig) {
    formlyConfig.setType({
        name: 'customRTE',
        templateUrl: customRTETpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            modelOptions: {},
            templateOptions: {
                controlClass: 'col-sm-12',
                tinymceOptions: {
                    contextmenu:'',
                    inline: false,
                    height: 300,
                    menubar: false,
                    elementpath: false,
                    wordcount_cleanregex: /[0-9.(),;:!?%#$?\x27\x22_+=\\/\-]*/g,
                    wordcount_countregex: /[\w\u2019\x27\-\u00C0-\u1FFF]+/g,
                    plugins:
                        `advlist autolink lists link image charmap print preview hr anchor pagebreak 
                            searchreplace wordcount visualblocks visualchars code fullscreen 
                            insertdatetime media nonbreaking save table directionality 
                            emoticons template paste textcolor colorpicker textpattern imagetools noneditable`,
                    toolbar1: 'fontselect fontsizeselect |hr pasteword | backcolor forecolor | undo redo '
                    + '| bold italic underline | alignleft aligncenter alignright alignjustify | bullist | outdent indent ',
                    content_style: '',
                    browser_spellcheck : true,
                }
            }
        },
        controller: customRTECtrl
    });

    customRTECtrl.$inject = ['$scope', 'LangUtils'];

    function customRTECtrl($scope, LangUtils) {
        const to = $scope.to;

        const langCode = LangUtils.getLang();
        // new language packs need to be renamed to match our langCode convention
        to.tinymceOptions.language_url = `/assets/tinymce-language-packs/${langCode}.js`;
    }
}
