import updateModalTpl from './updateModal/updateModal.tpl.html';
import updateModalCtrl from './updateModal/updateModal.controller';

AuthTimeoutService.$inject = [
    '$rootScope', '$state', '$timeout', '$localStorage', '$uibModalStack', '$uibModal', 'SessionTimeout', 'AUTH_EVENTS',
    'AuthDialogHandler', 'AuthStatus', '$http', '$interval', 'UrlUtils'
];

export default function AuthTimeoutService(
    $rootScope, $state, $timeout, $localStorage, $uibModalStack, $uibModal, SessionTimeout, AUTH_EVENTS,
    AuthDialogHandler, AuthStatus, $http, $interval, UrlUtils
) {
    let sessionTimeoutMinutes;
    const companySessionTimeout = {};
    $rootScope.$watch(() => $rootScope.company, (changes) => {
        if ($rootScope.company && $rootScope.company.sessionTimeoutMinutes && changes.sessionTimeoutMinutes) {
            sessionTimeoutMinutes = $rootScope.company.sessionTimeoutMinutes;
            companySessionTimeout.EOS = sessionTimeoutMinutes * (60 * 1000); // Converting minutes into milliseconds
            companySessionTimeout.warning = (sessionTimeoutMinutes - 1) * (60 * 1000); // Converting (minutes - 1) into milliseconds
        }
    });

    let warningTimeout;
    let warningDialog;

    let eosTimeout;
    let eosDialog;

    let stopStorageListener;
    let stopUpdateChecker;

    setAppLocked(false);

    function isAppLocked() {
        return $rootScope.isAppLocked;
    }

    function setAppLocked(isAppLocked) {
        $rootScope.isAppLocked = isAppLocked;
    }

    // local timeout --> for the current tab
    // storage timeout --> for syncing multiple browser tabs

    function resetTimeout() {
        resetLocalTimeout();
        resetStorageTimeout();
    }

    function resetLocalTimeout() {
        stopLocalTimeout();
        startLocalTimeout();
        setAppLocked(false);
    }

    function resetStorageTimeout() {
        $localStorage.authTimeoutOn = companySessionTimeout.EOS
            ? Date.now() + companySessionTimeout.EOS : Date.now() + SessionTimeout.EOS;
    }

    function startTimeout() {
        startLocalTimeout();
        startStorageTimeout();
    }

    function startLocalTimeout() {
        if (warningTimeout || eosTimeout) {
            console.log('Auth timeout local listener already counting down');
            return;
        }
        setAppLocked(false);
        if (stopUpdateChecker) {
            console.log('stopping update checker.');
            $interval.cancel(stopUpdateChecker);
            stopUpdateChecker = undefined;
        }

        const eosSessionTimeout = companySessionTimeout.EOS || SessionTimeout.EOS;
        const warningSessionTimeout = companySessionTimeout.warning || SessionTimeout.warning;

        warningTimeout = $timeout(
            async function() {
                if ($state.current.name !== 'login') {
                    try {
                        const { data: { expiresOn } } = await $http.get('/api/session/expiryDate');
                        const expireOnTime = new Date(expiresOn).getTime();
                        console.info(expireOnTime - Date.now());
                        if (expireOnTime - Date.now() > eosSessionTimeout - warningSessionTimeout) {
                            console.info('extending timeout...');
                            $localStorage.authTimeoutOn = expireOnTime;
                        } else {
                            if ($rootScope.tabRefreshModal) {
                                // if already locked for tab refresh
                                return;
                            }

                            warningDialog = AuthDialogHandler.openWarning();
                            $rootScope.$broadcast(AUTH_EVENTS.WARNING_OPENED);
                        }
                        return expireOnTime;
                    } catch (err) {
                        console.error(err);
                    }
                }
            },
            warningSessionTimeout
        );

        warningTimeout.then(function(expireOnTime) {
            if (!expireOnTime) {
                return;
            }

            if ((expireOnTime - Date.now()) < (eosSessionTimeout - warningSessionTimeout)) {
                eosTimeout = $timeout(
                    lockApp,
                    eosSessionTimeout - warningSessionTimeout
                );
                return;
            }
            console.info('resetting timeout...');
            resetLocalTimeout();
        });
    }

    function startStorageTimeout() {
        $localStorage.authTimeoutOn = companySessionTimeout.EOS
            ? Date.now() + companySessionTimeout.EOS : Date.now() + SessionTimeout.EOS;
        if (!stopStorageListener) {
            stopStorageListener = registerStorageListener();
        }
    }

    function stopTimeout() {
        stopLocalTimeout();
        stopStorageTimeout();
    }

    function stopLocalTimeout() {
        if (warningTimeout) {
            $timeout.cancel(warningTimeout);
        }
        if (warningDialog) {
            warningDialog.close();
        }
        if (eosTimeout) {
            $timeout.cancel(eosTimeout);
        }
        if (eosDialog) {
            eosDialog.close();
        }
        warningTimeout = false;
        warningDialog = false;
        eosTimeout = false;
        eosDialog = false;
    }

    function stopStorageTimeout() {
        if (stopStorageListener) {
            stopStorageListener();
        }
        stopStorageListener = false;
        $localStorage.authTimeoutOn = Date.now();
    }

    function lockApp() {
        if (isAppLocked()) {
            return;
        }

        if ($rootScope.tabRefreshModal) {
            // if already locked for tab refresh
            return;
        }

        stopLocalTimeout();
        $uibModalStack.dismissAll(); // close all open modals

        if ($state.current.name !== 'login') {
            eosDialog = AuthDialogHandler.openLogin();
        }
        $localStorage.authTimeoutOn = Date.now();
        setAppLocked(true);
        AuthStatus.setIsAuthenticated(false);
        stopUpdateChecker = $interval(checkUpdate, 3600000);
        UrlUtils.loadLegacyPage('/logOff.asp'); // log user out of legacy site
    }

    function registerStorageListener() {
        // Ensures that activity in one tab will prevent timeout in other open tabs
        return $rootScope.$watch(() => $localStorage.authTimeoutOn, function(authTimeoutOn) {
            // If undefined, means localStorage was cleared due to Session.destroy() on logout
            const isTimedOut = !authTimeoutOn || Date.now() >= authTimeoutOn;
            if (!isTimedOut) {
                resetLocalTimeout();
            } else if (isTimedOut && !isAppLocked()) {
                lockApp();
            }
        });
    }

    async function checkUpdate() {
        const { data : html } = await $http.get('/');
        const indexOfHash = html.indexOf('/vendors.') + 9;
        const lastestHash = html.substring(indexOfHash, indexOfHash + 20);
        // console.log(__webpack_hash__);
        // console.log(lastestHash);

        if (__webpack_hash__ === lastestHash) {
            console.log('same Hash, no updates!');
            return; 
        }
        
        console.log('new hash found! updating...');
        $uibModalStack.dismissAll();
        $uibModal.open({
            templateUrl: updateModalTpl,
            controller: updateModalCtrl,
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard: false,
            size: 'md',
        });
        if (stopUpdateChecker) {
            console.log('stopping update checker.');
            $interval.cancel(stopUpdateChecker);
            stopUpdateChecker = undefined;
        }
    }

    return {
        isAppLocked,
        setAppLocked,
        resetTimeout,
        resetStorageTimeout,
        startTimeout,
        stopTimeout,
        lockApp
    };
}
