promoFilterCtrl.$inject = ['$transitions', '$stateParams'];

export default function promoFilterCtrl($transitions, $stateParams) {
    this.$onInit = () => {
        const vm = this;

        updateParams($stateParams);

        $transitions.onSuccess({}, transition => {
            updateParams(transition.params());
        });

        function updateParams(params) {
            if (params.promoGroupID === 'all' || undefined) {
                vm.activeGroupId = undefined;
            } else {
                vm.activeGroupId = params.promoGroupID;
            }

            const promoGroupFilter = vm.promoList
                                            .filter(promo => promo.promoGroupID === parseInt(vm.activeGroupId, 10));
            vm.promoGroupLength = vm.activeGroupId ? promoGroupFilter.length : vm.promoList.length;
            vm.promoGroupIndex = vm.activeGroupId ? vm.promoGroups
                                        .findIndex(group => group.id === parseInt(vm.activeGroupId, 10)) : 0;

            // Check for promo exist inside a group or not.
            vm.isPromoExist = !!((vm.promoGroupLength > 0 || !vm.activeGroupId));

            // Setting display view as per params.
            if (params.isDefaultView === 'list') {
                vm.isDefaultView = false;
            } else if (params.isDefaultView === 'visual') {
                vm.isDefaultView = true;
            } else {
                // If list has 20 or more promos change view to the list
                vm.isDefaultView = vm.promoGroupLength <= 20;
            }
        }
    };
}
