import _ from 'lodash';
import FormTask from './FormTask';

export default class ShippingAddressValidateTask extends FormTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);
        this.$http = $injector.get('$http');

        // TODO: phrases
        this.title = 'app_SIGN_ON_TASKS_VALIDATE_SHIPPING_ADDRESS_TITLE'; // Validate Preferred Contact Information
        this.acceptButtonText = 'app_SIGN_ON_TASKS_VALIDATE_SHIPPING_ADDRESS_SUBMIT_BUTTON'; // Confirm Preferred Contact Information
        this.formCssClass = 'form-horizontal';
        this.taskModalOptions.showCancelButton = false;
    }

    getAdditionalData() {
        // TODO: separate service
        return this.$http.get('/api/shipping-address')
            .then(res => {
                this.shippingAddress = res.data;
            });
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;
        vm.taskFormModel = this.shippingAddress;
    }

    get formFields() {
        // TODO: phrases
        const fields = [{
            key: 'firstName',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_FIRST_NAME', // Preferred First Name
                required: true
            }
        }, {
            key: 'lastName',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_LAST_NAME', // Preferred Last Name
                required: true
            }
        }, {
            key: 'companyName',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_COMPANY_NAME' // Company Name
            }
        }, {
            key: 'address1',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_ADDRESS_1', // Address 1
                required: true
            }
        }, {
            key: 'address2',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_ADDRESS_2' // Address 2
            }
        }, {
            key: 'city',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_CITY', // City
                required: true
            }
        }, {
            key: 'provinceCode',
            type: 'customSelect',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_STATE', // State
                required: true,
                options: [], // options populated by country dropdown
                valueProp: 'code'
            }
        }, {
            key: 'postalCode',
            type: 'customInput',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_ZIP_CODE', // Zip Code
                required: true,
            }
        }, {
            key: 'countryID', // TODO: re-usable customCountrySelect, customStateSelect
            type: 'customSelect',
            templateOptions: {
                required: true,
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_COUNTRY', // Country
                options: [],
                ngChange: 'changeCountry(model[options.key], true)',
                valueProp: 'ID'
            },
            controller: ['$scope', 'GeoService', async function($scope, GeoService) {
                const provinceDropdownField = $scope.fields.find(field => field.key === 'provinceCode');
                provinceDropdownField.hide = true;
                // load list of provinces/states
                $scope.changeCountry = async function(countryID, isResetProvinceInput) {
                    const country = $scope.to.options.find(country => country.ID === countryID);
                    const provinceList = await GeoService.getProvinceList(country.code);
                    if (isResetProvinceInput) {
                        delete $scope.model.province;
                    }
                    if (!_.isEmpty(provinceList)) {
                        provinceDropdownField.templateOptions.options = provinceList;
                        provinceDropdownField.hide = false;
                    } else {
                        provinceDropdownField.hide = true;
                    }
                };
                // load list of countries
                $scope.to.options = await GeoService.getCountryList();
                if ($scope.model.countryID) {
                    $scope.changeCountry($scope.model.countryID, false);
                }
            }]
        }, {
            key: 'phone',
            type: 'customInput',
            templateOptions: {
                type: 'tel',
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_PHONE' // Phone
            },
        }, {
            key: 'email',
            type: 'customInput',
            templateOptions: {
                type: 'email',
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_EMAIL' // Email
            },
        }, {
            key: 'confirm',
            type: 'customCheckbox',
            templateOptions: {
                labelTranslateKey: 'app_VALIDATE_SHIPPING_ADDRESS_LABEL_CONFIRM', // I confirm that this information is correct
                required: true
            }
        }];

        fields
            .filter(field => field.type !== 'customCheckbox')
            .forEach(field => _.merge(field, {
                templateOptions: {
                    labelClass: 'col-sm-4',
                    controlClass: 'col-sm-8',
                }
            }));

        return fields;
    }

    onSubmit($scope) {
        // TODO: separate service
        return this.$http.put(`/api/shipping-address`, $scope.vm.taskFormModel);
    }
}
