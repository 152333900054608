import _ from 'lodash';
import $ from 'jquery';
import angular from 'angular';

import CustomStore from 'devextreme/data/custom_store';
import formatForCode from '../../../../../../shared/formatForCode';

rnDxGridCtrl.$inject = [
    '$scope', '$q', '$localStorage', '$state', '$transitions', '$translate', '$timeout', '$compile', '$http',
    'StateUtils', 'FilterModalService', 'DropdownSelectionService', 'Session', 'RnDxGridService', 'ReportFormModal',
    'GridUrlFilters', 'GridUrlColumns', 'GridColumnTypes', 'GridFilterOperators', 'GridColumnFilterTypes',
    'GridUrlSort', 'ColumnChooserService'
];

export default function rnDxGridCtrl(
    $scope, $q, $localStorage, $state, $transitions, $translate, $timeout, $compile, $http,
    StateUtils, FilterModalService, DropdownSelectionService, Session, RnDxGridService, ReportFormModal,
    GridUrlFilters, GridUrlColumns, GridColumnTypes, GridFilterOperators, GridColumnFilterTypes,
    GridUrlSort, ColumnChooserService
) {
    this.$onInit = () => {
        const vm = this;

        if (!vm.apiUrl) {
            throw new Error(`Must specify a URL for the grid's routes`);
        }
        if (!vm.gridID) {
            throw new Error('No grid ID specified');
        }
        if (!vm.columns) {
            console.warn('No columns array specified');
        }

        _.defaults(vm, {
            filterHeaderTranslateKey: 'app_GRID_FILTERS_HEADER', // 'Filters:'
            noDataTranslateKey: 'app_GRID_NO_DATA_MESSAGE', // 'Nothing found'
            columns: [],
            extraDataGridOptions: {},
            extraCustomStoreOptions: {}
        });

        vm.user = Session.getUser();

        vm.columns.forEach((column, i) => RnDxGridService.formatColumn($scope, column, i));

        const apiUrl = _.endsWith(vm.apiUrl, '/') ? vm.apiUrl.substring(0, vm.apiUrl.length - 1) : vm.apiUrl;

        // -----------------------------------------------------------------------------------------------------------------
        // Settings storage
        // -----------------------------------------------------------------------------------------------------------------

        // Putting the userID as part of the storage key allows a single browser to saving settings
        // for multiple users for the same grid.
        // If browser storage is cleared, every user's settings gets wiped.
        // The settingsSlot allows multiple settings for a single grid for a single user.
        // So in summary:
        // - storage divided into grids
        // - grids divided into users
        // - users divided into settings slots

        // TODO: implement saved filters

        const unsavedSettingsStorageKey = `${vm.user.userID}-${vm.gridID}-unsaved-settings`
            + (vm.settingsSlot ? '-' + formatForCode(vm.settingsSlot) : '');

        if (!$localStorage[unsavedSettingsStorageKey]) {
            $localStorage[unsavedSettingsStorageKey] = {};
            Session.preventLocalStorageReset(unsavedSettingsStorageKey);
        }

        vm.isFullWidth = $localStorage[unsavedSettingsStorageKey].isFullWidth || false;

        $timeout(() => toggleFullWidth(vm.isFullWidth));

        // -----------------------------------------------------------------------------------------------------------------
        // URL params setup
        // -----------------------------------------------------------------------------------------------------------------

        vm.urlParamKeys = {
            filters: 'filters',
            columns: 'columns',
            sort: 'sort',
        };

        if (_.isObject(vm.updateUrlParams)) {
            // Allow for different url param keys, Ex. instead of ?filters=..., ?myGridFilters=...
            vm.urlParamKeys = Object.assign(vm.urlParamKeys, vm.updateUrlParams);
        }

        // -----------------------------------------------------------------------------------------------------------------
        // Setting up dxDataGrid options and event handlers
        // -----------------------------------------------------------------------------------------------------------------

        let gridInstance;

        const defaultOptionsWithoutEventHandlers = {
            columns: vm.columns,
            remoteOperations: true,
            dataSource: {
                store: new CustomStore(_.merge({
                    load,
                }, vm.extraCustomStoreOptions))
            },
            paging: {
                pageSize: 200
            },
            sorting: {
                mode: 'multiple'
            },
            scrolling: {
                mode: 'virtual',
                rowRenderingMode: 'virtual',
                showScrollbar: 'always'
            },
            width: '100%',
            height: '500px',
            noDataText: $translate.instant(vm.noDataTranslateKey),
            showBorders: true,
            showRowLines: false,
            showColumnLines: true,
            hoverStateEnabled: true,
            focusStateEnabled: true,
            activeStateEnabled: true,
            allowColumnResizing: true,
            allowColumnReordering: true,
            columnAutoWidth: true,
            columnResizingMode: 'widget',
            summary: {
                totalItems: [{
                    // TODO: When number column is fully implemented then we will display it on number column.
                    column: 'participant',
                    summaryType: 'custom',
                    customizeText: function(cellInfo) {
                        return `${$translate.instant('app_GRID_TOTAL_ROWS')} ${gridInstance.totalCount()}`;
                    }
                }]
            }
        };

        // Event handler functions that rnDxGrid needs to run before any custom event handlers passed through vm.extraDataGridOptions.
        const baseEventHandlers = {
            onInitialized(e) {
                gridInstance = e.component;
            },
            onContentReady(e) {
                const gridState = gridInstance.state();

                const visibleColumns = gridInstance.getVisibleColumns();
                GridUrlColumns.updateUrl(vm.columns, vm.urlParamKeys.columns, visibleColumns);

                const sortSettings = getSortSettings(gridState);
                GridUrlSort.updateUrl(vm.columns, vm.urlParamKeys.sort, sortSettings);

                if ($localStorage[unsavedSettingsStorageKey] && gridState.columns) {
                    updateUnsavedSettings();
                }

                const gridElement = e.element;

                // Needed because otherwise buttons inside grid cells can't be focused using tab key.
                gridElement.find('[ng-click]').attr('tabindex', 0);
            }
        };

        const extraDataGridOptionsWithoutWrappedEventHandlers = _.omitBy(vm.extraDataGridOptions, (value, key) => baseEventHandlers.hasOwnProperty(key));

        const wrappedEventHandlers = _.mapValues(baseEventHandlers, (baseEventHandler, methodName) => {
            if (!_.isFunction(vm.extraDataGridOptions[methodName])) {
                return baseEventHandler;
            }
            return function wrappedEventHandler(...args) {
                baseEventHandler(...args);
                vm.extraDataGridOptions[methodName](...args);
            };
        });

        const defaultDataGridOptions = _.merge(
            defaultOptionsWithoutEventHandlers,
            extraDataGridOptionsWithoutWrappedEventHandlers,
            wrappedEventHandlers
        );

        vm.columnChooserEnabled = defaultDataGridOptions.columnChooser && defaultDataGridOptions.columnChooser.enabled;

        // Difference in purpose between defaultDataGridOptions and vm.dataGridOptions:
        // defaultDataGridOptions = merging of default settings for all grids plus vm.extraDataGridOptions and vm.columns.
        // vm.dataGridOptions = defaultDataGridOptions plus more settings merged on top:
        //      - settings from vm.initialFilters, vm.initialColumns, vm.initialSort (likely from $state.go() params)
        //      - settings from URL querystring params (Ex. ?filters=points--eq--500&sort=date--desc)
        //      - settings from localStorage
        //      These changed are made below to vm.dataGridOptions and will be passed to the dxDataGrid directive.
        //      (see the "Initial columns shown/hidden setup" section and "Initial sorting" section)

        // For the rest of this function, defaultDataGridOptions needs to be left as-is.
        // Also note that for code below this, vm.columns is the same array (===) as defaultDataGridOptions.columns.

        vm.dataGridOptions = _.cloneDeep(defaultDataGridOptions);

        // -----------------------------------------------------------------------------------------------------------------
        // Initializing filters
        // -----------------------------------------------------------------------------------------------------------------

        let urlFilters;
        if (_.isString($state.params[vm.urlParamKeys.filters])) {
            try {
                urlFilters = GridUrlFilters.parseUrl(vm.urlParamKeys.filters, vm.columns);
            } catch (err) {
                console.error('Invalid filters in url');
            }
        }

        if (urlFilters) {
            vm.filters = urlFilters;
        } else if (filtersAreInStorage()) {
            // Clone to prevent modifications from updating saved state (ngStorage syncs by default)
            vm.filters = _.cloneDeep($localStorage[unsavedSettingsStorageKey].filters);
        } else if (vm.initialFilters && vm.initialFilters.length > 0) {
            vm.filters = vm.initialFilters;
        } else {
            vm.filters = [];
        }

        if (vm.updateUrlParams) {
            GridUrlFilters.updateUrl(vm.columns, vm.urlParamKeys.filters, vm.filters);
        }

        // -----------------------------------------------------------------------------------------------------------------
        // Initializing columns shown/hidden
        // -----------------------------------------------------------------------------------------------------------------

        if (_.isString($state.params[vm.urlParamKeys.columns])) {
            try {
                const initialColumns = GridUrlColumns.parseUrl(vm.urlParamKeys.columns, vm.columns);
                setColumnVisibilities(vm.dataGridOptions.columns, initialColumns);
            } catch (err) {
                console.error('Invalid column visibility settings in url');
            }
        } else {
            let initialColumns;
            if (columnsAreInStorage()) {
                // Clone to prevent modifications from updating saved state (ngStorage syncs by default)
                initialColumns = _.cloneDeep($localStorage[unsavedSettingsStorageKey].columns);
            } else if (vm.initialColumns && !_.isEmpty(vm.initialColumns)) {
                initialColumns = vm.initialColumns;
            }
            if (initialColumns) {
                const initialColumnsValidated = validateColumnVisibilities(vm.columns, initialColumns);
                setColumnVisibilities(vm.dataGridOptions.columns, initialColumnsValidated);
                if (vm.updateUrlParams) {
                    const visibleColumns = vm.columns.filter(column => column.visible || initialColumnsValidated[column.dataField]);
                    GridUrlColumns.updateUrl(vm.columns, vm.urlParamKeys.columns, visibleColumns);
                }
            }
        }

        // -----------------------------------------------------------------------------------------------------------------
        // Initial sorting
        // -----------------------------------------------------------------------------------------------------------------

        if (_.isString($state.params[vm.urlParamKeys.sort])) {
            try {
                const initialSort = GridUrlSort.parseUrl(vm.urlParamKeys.sort, vm.columns);
                setColumnSorting(vm.dataGridOptions.columns, initialSort);
            } catch (err) {
                console.error('Invalid column sorting settings in url');
            }
        } else {
            let initialSort;
            if (sortsAreInStorage()) {
                // Clone to prevent modifications from updating saved state (ngStorage syncs by default)
                initialSort = _.cloneDeep($localStorage[unsavedSettingsStorageKey].sort);
            } else if (vm.initialSort && !_.isEmpty(vm.initialSort)) {
                initialSort = vm.initialSort;
            }
            if (initialSort) {
                const initialSortValidated = validateSortSettings(initialSort);
                setColumnSorting(vm.dataGridOptions.columns, initialSortValidated);
                if (vm.updateUrlParams) {
                    GridUrlSort.updateUrl(vm.columns, vm.urlParamKeys.sort, initialSortValidated);
                }
            }
        }

        // -----------------------------------------------------------------------------------------------------------------
        // vm functions
        // -----------------------------------------------------------------------------------------------------------------

        // Filtering
        // -----------------------------------------------------------------------------------------------------------------

        vm.openAddFilterModal = function() {
            // Need to setFilterDropdownColumns because it's a shared service for every grid (currently)
            setFilterDropdownColumns(vm.columns);
            FilterModalService.openFilterModal(null)
                .result
                .then(function(newFilter) {
                    addFilterToList(newFilter);
                    if (vm.updateUrlParams) {
                        updateUnsavedSettings();
                        GridUrlFilters.updateUrl(vm.columns, vm.urlParamKeys.filters, vm.filters);
                    }
                    return reloadGrid();
                });
            // .then means "OK" was clicked in the modal
            // .catch means "Cancel" was clicked in the modal - no need to do anything
        };

        vm.openEditFilterModal = function(filter) {
            // Need to setFilterDropdownColumns because it's a shared service for every grid (currently)
            setFilterDropdownColumns(vm.columns);
            FilterModalService.openFilterModal(filter)
                .result
                .then(function(newFilter) {
                    if (angular.equals(filter, newFilter)) {
                        return;
                    }
                    const index = removeFilterFromList(filter);
                    addFilterToList(newFilter, index);
                    if (vm.updateUrlParams) {
                        updateUnsavedSettings();
                        GridUrlFilters.updateUrl(vm.columns, vm.urlParamKeys.filters, vm.filters);
                    }
                    return reloadGrid();
                });
            // .then means "OK" was clicked in the modal
            // .catch means "Cancel" was clicked in the modal - no need to do anything
        };

        vm.removeFilter = function(filter) {
            removeFilterFromList(filter);

            const gridState = gridInstance.state();

            const columnVisibilities = gridState.columns
                .filter((column, i) => column.visible !== vm.columns[i].visible)
                .reduce((obj, column) => Object.assign(obj, { [column.dataField]: column.visible }), {});

            const sortSettings = getSortSettings(gridState);

            $localStorage[unsavedSettingsStorageKey] = {
                filters: removeAngularHashKeys(vm.filters),
                columns: columnVisibilities,
                sort: sortSettings,
                isFullWidth: vm.isFullWidth
            };

            if (vm.updateUrlParams) {
                updateUnsavedSettings();
                GridUrlFilters.updateUrl(vm.columns, vm.urlParamKeys.filters, vm.filters);
            }
            return reloadGrid();
        };

        vm.getFilterDisplayText = function(filter) {
            try {
                return RnDxGridService.getFilterDisplayText(vm.columns, filter);
            } catch (err) {
                console.error('Invalid grid filter', filter);
                return 'Invalid filter';
            }
        };

        // Settings
        // -----------------------------------------------------------------------------------------------------------------

        vm.openColumnChooser = () => {
            ColumnChooserService.openColumnChooserModal(vm.columns, gridInstance)
                .result.then(({ visibleColumns }) => {
                    if (vm.updateUrlParams) {
                        updateUnsavedSettings();
                        GridUrlColumns.updateUrl(vm.columns, vm.urlParamKeys.columns, visibleColumns);
                    }
                    return reloadGrid();
                }).catch(msg => {
                    console.warn(msg);
                });
        };

        vm.applyDefaultSettings = function() {

            gridInstance.state(null);

            vm.columns.forEach(column => {
                ['visible', 'sortIndex', 'sortOrder'].forEach(property => {
                    gridInstance.columnOption(column.dataField, property, column[property]);
                });
            });

            delete $localStorage[unsavedSettingsStorageKey];

            StateUtils.updateUrlParams({
                [vm.urlParamKeys.filters]: null,
                [vm.urlParamKeys.columns]: null,
                [vm.urlParamKeys.sort]: null
            });
        };

        vm.saveGridSettings = function({ showSuccessIcon = true } = {}) {
            // TODO: this is not being used - re-implement in saved filters Phase 2
            const gridState = gridInstance.state();

            const columnVisibilities = gridState.columns
                .filter((column, i) => column.visible !== vm.columns[i].visible)
                .reduce((obj, column) => Object.assign(obj, { [column.dataField]: column.visible }), {});

            const sortSettings = getSortSettings(gridState);

            // TODO: consider trying to save the column widths too (user can drag around column widths through the UI)

            $localStorage[unsavedSettingsStorageKey] = {
                filters: removeAngularHashKeys(vm.filters),
                columns: columnVisibilities,
                sort: sortSettings,
                isFullWidth: vm.isFullWidth
            };

            if (showSuccessIcon && !vm.showSaveSettingsSuccess) {
                vm.showSaveSettingsSuccess = true;
                $timeout(() => vm.showSaveSettingsSuccess = false, 3000);
            }
        };

        vm.clearSettings = function() {
            vm.applyDefaultSettings();
            vm.saveGridSettings({ showSuccessIcon: false });
            vm.showClearSettingsSuccess = true;
            vm.showClearSettingsMenuButton = true;
            $timeout(() => {
                vm.showClearSettingsSuccess = false;
                vm.showClearSettingsMenuButton = false;
            }, 3000);
        };

        vm.createReport = async function() {
            const defaultReportName = (vm.defaultReportNameTranslateKey && $translate.instant(vm.defaultReportNameTranslateKey))
                || vm.defaultReportName
                || vm.gridID;

            const loadOptions = gridInstance.getDataSource().loadOptions();
            const gridQueryOptions = convertDevExOptions(loadOptions);

            const filtersText = vm.filters
                .map(filter => RnDxGridService.getFilterDisplayText(vm.columns, filter))
                .join(', ');

            const columns = gridInstance.getVisibleColumns()
                .filter(column => !column.nonDevExOptions.isOptionsColumn)
                .map(column => {
                    if (column.nonDevExOptions.showValueOf) {
                        return column.nonDevExOptions.showValueOf;
                    }
                    if (_.isString(column.customizeText)) {
                        return column.customizeText;
                    }
                    return column.dataField;
                });

            ReportFormModal.open({
                defaultReportName,
                gridQueryOptions,
                gridColumns: vm.columns,
                submit: function({ periodID, startDate, reportName, isEmail }) {
                    return $http.post(`${apiUrl}/reports`, {
                        gridQueryOptions, filtersText, columns, periodID, startDate, reportName, isEmail
                    });
                }
            });
        };

        vm.toggleFullWidth = toggleFullWidth;

        vm.reloadGrid = reloadGrid;

        $scope.$on(RnDxGridService.Events.RELOAD_ROWS, (event, params) => {
            if (params.gridID !== vm.gridID) {
                return;
            }
            return reloadRows(params);
        });

        // -----------------------------------------------------------------------------------------------------------------
        // Helper functions
        // TODO: try to move more functions from here to RnDxGridService where applicable
        // -----------------------------------------------------------------------------------------------------------------

        /**
         * Removes $$hashkey property that angular adds to objects.
         * @param {*} json
         */
        function removeAngularHashKeys(json) {
            return JSON.parse(angular.toJson(json));
        }

        /**
         * @param {boolean} isFullWidth
         */
        function toggleFullWidth(isFullWidth = !vm.isFullWidth) {
            // TODO: modifying the closest .container isn't be the most flexible; makes unnecessary assumptions.
            // consider a containerSelector component binding?

            const $container = $('#' + vm.gridID).closest('.container');
            if ($container.length) {
                $container.css('width', isFullWidth ? '100%' : '');
                vm.isFullWidth = isFullWidth;
                if ($localStorage[unsavedSettingsStorageKey]) {
                    $localStorage[unsavedSettingsStorageKey].isFullWidth = isFullWidth;
                }
            }
        }

        // Column visibility
        // ----------------------------------------------------------------------------------------------------------------

        /**
         * Assumes columns went through formatColumn() first.
         * @param {Object[]} columns
         * @param {Object} columnVisibilities
         * @returns {Object}
         */
        function validateColumnVisibilities(columns, columnVisibilities) {
            const visibilities = {};
            _.forEach(columnVisibilities, (visibility, columnDataField) => {
                const column = columns.find(column => column.dataField.toLowerCase() === columnDataField.toLowerCase());
                if (!column) {
                    console.warn(`Invalid column name passed in initial-columns binding: "${columnDataField}"`);
                    return;
                }
                const defaultVisibility = column.visible;
                // If same as default, leave out
                if (defaultVisibility !== visibility) {
                    visibilities[column.dataField] = visibility;
                }
            });
            return visibilities;
        }

        /**
         * @param {Object[]} columns
         * @param {Object} columnVisibilities - Assumed to be already valid
         */
        function setColumnVisibilities(columns, columnVisibilities) {
            columns.forEach(column => {
                if (columnVisibilities[column.dataField] == null) {
                    return;
                }
                column.visible = columnVisibilities[column.dataField];
            });
        }

        // Filtering
        // -----------------------------------------------------------------------------------------------------------------

        /**
         * Checks if it already exists before adding.
         * @param filter {Object}
         * @param index {int=} Array index at which to insert
         */
        function addFilterToList(filter, index = vm.filters.length) {
            const isAlreadyApplied = filterIsAlreadyApplied(filter);
            if (!isAlreadyApplied) {
                vm.filters.splice(index, 0, filter);
            }
        }

        /**
         * @param {GridFilter} filter
         * @returns {number} - index of the removed filter
         */
        function removeFilterFromList(filter) {
            const index = vm.filters.findIndex(f => filtersAreEqual(f, filter));
            vm.filters.splice(index, 1);
            return index;
        }

        /**
         * @param {Object[]} columns
         */
        function setFilterDropdownColumns(columns) {
            const filterableColumns = columns.filter(column => !column.nonDevExOptions.isNotFilterable);
            DropdownSelectionService.setColumns(filterableColumns);
        }

        /**
         * @param {GridFilter} filter
         * @returns {boolean}
         */
        function filterIsAlreadyApplied(filter) {
            return vm.filters.some(f => filtersAreEqual(f, filter));
        }

        /**
         * @param filterA {GridFilter}
         * @param filterB {GridFilter}
         * @returns {boolean}
         */
        function filtersAreEqual(filterA, filterB) {
            return filterA.field === filterB.field
                && filterA.operator === filterB.operator
                && _.isEqual(filterA.value, filterB.value); // Need deep equality check because value could be array (Ex. point type filter)
        }

        // Sorting
        // -----------------------------------------------------------------------------------------------------------------

        /**
         * @param {*} sortSettings
         * @returns {Array}
         */
        function validateSortSettings(sortSettings) {
            if (!_.isArray(sortSettings)) {
                return [];
            }
            return sortSettings.filter(sort => sort.field);
        }

        /**
         * @param {Object[]} columns
         * @param {Object[]} sortSettings - Assumed to be already valid
         */
        function setColumnSorting(columns, sortSettings) {
            columns.forEach((column) => {
                const initialColumnSort = sortSettings.find(sort => sort.field.toLowerCase() === column.dataField.toLowerCase());
                if (!initialColumnSort) {
                    return;
                }
                column.sortIndex = sortSettings.indexOf(initialColumnSort);
                column.sortOrder = initialColumnSort.desc ? 'desc' : 'asc';
            });
        }

        /**
        * @param {Object} gridState
        * @return {{ field, desc }[]}
        */
        function getSortSettings(gridState) {
            if (!gridState.columns) {
                return [];
            }
            return gridState.columns
                .filter(column => column.sortIndex != null)
                .map(column => ({
                    field: column.dataField,
                    desc: column.sortOrder === 'desc'
                }));
        }

        // Loading
        // ----------------------------------------------------------------------------------------------------------------

        /**
        * Refreshes the grid instance and loads new data.
        * @returns {Promise}
        */
        function reloadGrid() {
            return gridInstance.refresh();
        }

        /**
        * https://js.devexpress.com/Documentation/16_2/ApiReference/Data_Layer/CustomStore/Configuration/#load
        * @param loadOptions
        * @returns {Promise.<{ data, totalCount }>}
        */
        async function load(loadOptions) {
            const gridQueryOptions = convertDevExOptions(loadOptions);
            try {
                return await getData(gridQueryOptions);
            } catch (err) {
                let errorMessage = $translate.instant('app_ERROR_PROMPT_MESSAGE'); // 'Woops. Sorry, an error happened.'
                if (err.data && err.data.errorID) {
                    errorMessage += '\n' + $translate.instant('app_ERROR_PROMPT_ERROR_ID', { errorID: err.data.errorID });
                }
                // To change the error message, devEx expects $q.reject
                return $q.reject(errorMessage);
            }
        }

        /**
        * @param {GridQueryOptions} gridQueryOptions
        * @returns {Promise.<{ data, totalCount }>}
        */
        async function getData(gridQueryOptions) {
            const res = await $http.get(apiUrl, { params: gridQueryOptions });
            if (_.isFunction(vm.formatQueryResponse)) {
                return vm.formatQueryResponse(res);
            } else {
                return res.data;
            }
        }

        /**
        * Formats the loadOptions from devEx into the format our API expects.
        * Changes and adds properties to the object to be sent as query params.
        * When making changes to the object format, be sure to consider Grid.js in the server code.
        * @returns {GridQueryOptions}
        */
        function convertDevExOptions(loadOptions) {
            return {
                filters: removeAngularHashKeys(vm.filters),
                sort: loadOptions.sort ? loadOptions.sort.map(sort => ({
                    field: sort.selector,
                    desc: sort.desc
                })) : [],
                skip: loadOptions.skip,
                take: loadOptions.take,
                requireTotalCount: loadOptions.requireTotalCount || false,
            };
        }

        /**
        * @see RnDxGridService.reloadRows
        */
        async function reloadRows({ dataField, dataFieldValue, findUpdatedRowData }) {
            const rows = gridInstance.getVisibleRows();
            const rowsToUpdate = rows.filter(row => row.data[dataField] === dataFieldValue);
            if (_.isEmpty(rowsToUpdate)) {
                return;
            }
            const $rows = rowsToUpdate.map(({ rowIndex }) => gridInstance.getRowElement(rowIndex));
            const updatingCssTimeout = $timeout(() => $rows.forEach($row => $row.addClass('row-updating')), 300);
            try {
                const { data: updatedRowsData } = await getData({
                    filters: [{
                        field: dataField,
                        operator: GridFilterOperators.EQUALS,
                        value: dataFieldValue
                    }]
                });
                if (_.isEmpty(updatedRowsData)) {
                    return;
                }
                if (!_.isFunction(findUpdatedRowData)) {
                    // If findUpdatedRowData unspecified, assume only 1 row needs to be updated in the grid.
                    findUpdatedRowData = (row, updatedRowsData) => updatedRowsData[0];
                }
                const updateFunctions = [];
                rowsToUpdate.forEach(row => {
                    const updatedRowData = findUpdatedRowData(row, updatedRowsData);
                    _.forEach(updatedRowData, (newValue, dataField) => {
                        if (row.data[dataField] === newValue) {
                            return;
                        }
                        const column = vm.columns.find(column => column.dataField === dataField);
                        if (!column) {
                            return;
                        }
                        if (column.nonDevExOptions.type === GridColumnTypes.DATE) {
                            newValue = new Date(newValue);
                        }
                        updateFunctions.push(() => gridInstance.cellValue(row.rowIndex, dataField, newValue));
                    });
                });
                // Calling gridInstance.beginUpdate() and gridInstance.endUpdate() caused performance issues and made the
                // UI freeze while devEx was upgrading the grid rows. Instead, splitting up the updates with $timeouts in
                // between seem to prevent the freezing.
                await $q.all(updateFunctions.map($timeout));
            } finally {
                $timeout.cancel(updatingCssTimeout);
                $rows.forEach($row => $row.removeClass('row-updating'));
            }
        }

        // Settings storage
        // ----------------------------------------------------------------------------------------------------------------

        function filtersAreInStorage() {
            return isSettingInStorage('filters');
        }

        function columnsAreInStorage() {
            return isSettingInStorage('columns');
        }

        function sortsAreInStorage() {
            return isSettingInStorage('sort');
        }

        function isSettingInStorage(settingKey) {
            // unsavedSettingsStorageKey is for the all settings object, settingKey is a specific key under unsavedSettingsStorageKey
            return _.has($localStorage, `${unsavedSettingsStorageKey}.${settingKey}`);
        }

        function updateUnsavedSettings() {
            const gridState = gridInstance.state();

            const columnVisibilities = gridState.columns
                .filter((column, i) => column.visible !== vm.columns[i].visible)
                .reduce((obj, column) => Object.assign(obj, { [column.dataField]: column.visible }), {});

            const sortSettings = getSortSettings(gridState);

            $localStorage[unsavedSettingsStorageKey] = {
                filters: removeAngularHashKeys(vm.filters),
                columns: columnVisibilities,
                sort: sortSettings,
                isFullWidth: vm.isFullWidth
            };
        }
    };
}
