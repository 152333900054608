import $ from 'jquery';
import sharedGetLegacyUrl from '../../../../../shared/getLegacyUrl';
import isUrlAbsolute from '../../../../../shared/isUrlAbsolute';
import formatAngularUrl from '../../../../../shared/formatAngularUrl';

UrlUtils.$inject = ['$q', '$location', '$timeout'];

export default function UrlUtils($q, $location, $timeout) {

    const LOADING_SPINNER_HTML = `
        <div id="loading-bar-spinner">
            <div class="spinner-icon"></div>
        </div>`;

    const $body = $('body');

    /**
     * @param {Object} res - $http response
     * @returns {boolean}
     */
    function isApiRequest(res) {
        const url = res.config ? res.config.url : '';
        return url.charAt(0) === '/';
    }

    function getLegacyUrl() {
        return sharedGetLegacyUrl({
            protocol: $location.protocol(),
            host: $location.host()
        });
    }

    function getEverythingAfterPath(path) {
        return decodeURIComponent($location.url()).split(path)[1];
    }

    /**
     * Sometimes we need to make a change on the legacy server as well as RN2.
     * This is so that if the user does something in RN2 they should see its effect when they look at an iframe too.
     * On the legacy site this would usually be done by simply going to a page at a URL.
     * To achieve this in RN2, this function will add to the DOM a hidden iframe to the legacy site page,
     * then removes the iframe after it loads or errors.
     * @param {string} url - URL to the legacy ASP page.
     *      Ex. if "/SwitchLanguage.asp?Lang=123" is passed,
     *      iframe's url will become "http://incentives-legacy.rewardsnation.com/SwitchLanguage.asp?Lang=123"
     * @returns {Promise} Resolves when iframe loads successfully
     */
    function loadLegacyPage(url) {
        const legacyUrl = getLegacyUrl();
        const legacyPageUrl = legacyUrl + url;
        return loadIframe(legacyPageUrl);
    }

    /**
     * Adds to the DOM a hidden iframe, then removes the iframe after it loads or errors.
     * @param {string} src
     * @returns {Promise} Resolves when iframe loads successfully
     */
    function loadIframe(src) {
        const $iframe = $(`<iframe src="${src}" style="display: none;"></iframe>`);
        $body.append($iframe);
        const $newLoadingSpinner = manuallyAddLoadingSpinner();
        const promise = $q((resolve, reject) => {
            $iframe.on('load', resolve);
            $iframe.on('error', reject);
        });
        promise.finally(() => {
            $newLoadingSpinner.remove();
            // 1 second timeout makes downloading Certificate PDFs work on Firefox
            // (I guess if the iframe is removed too soon any downloads the iframe triggers causes an error?)
            $timeout(() => $iframe.remove(), 1000);
        });
        return promise;
    }

    /**
     * Need to manually add a loading spinner when loading a legacy iframe because angular-loading-bar
     * only intercepts HTTP requests. It doesn't track iframes and it doesn't expose any functions for adding
     * the loading bar at any time.
     * @returns {jQuery} New loading spinner
     */
    function manuallyAddLoadingSpinner() {
        const $loadingSpinner = $('#loading-bar-spinner');
        const spinnerIsShowing = $loadingSpinner.length > 0;
        const $newLoadingSpinner = $(LOADING_SPINNER_HTML);
        if (spinnerIsShowing) {
            $loadingSpinner.replaceWith($newLoadingSpinner);
        } else {
            $body.append($newLoadingSpinner);
        }
        return $newLoadingSpinner;
    }

    return {
        isApiRequest,
        getLegacyUrl,
        getEverythingAfterPath,
        isUrlAbsolute,
        formatAngularUrl,
        loadLegacyPage,
        loadIframe,
    };
}
