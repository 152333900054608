import angular from 'angular';

import postNomination from './postNomination.component';
import PostNominationModal from './postNominationModal.factory';

export default angular.module('app.promo.promoQuiz.postNomination', [

])
.component('postNomination', postNomination)
.factory('PostNominationModal', PostNominationModal);
