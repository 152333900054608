import angular from 'angular';

import '../../core/core.module';

import exceptionDecorator from './exceptionDecorator.config';
import errorHandlingConfig from './errorHandling.config';
import errorPopupRun from './errorPopup.run';
import ErrorInterceptor from './errorInterceptor.factory';
import ErrorService from './errorService.factory';
import ErrorModal from './errorModal/errorModal.factory';

export default angular.module('app.blocks.errorHandling', [
    'app.core'
])
.config(errorHandlingConfig)
.config(exceptionDecorator)
.run(errorPopupRun)
.factory('ErrorInterceptor', ErrorInterceptor)
.factory('ErrorService', ErrorService)
.factory('ErrorModal', ErrorModal);
