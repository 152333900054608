import WidgetVisuals from '../../../../../../shared/WidgetVisuals';

export const legacyWidgetComponent = `legacy-widget`;

export default {
    [WidgetVisuals.TABLE]: 'table-widget',
    [WidgetVisuals.LINE_CHART_MONTH]: 'chart-widget',
    [WidgetVisuals.LINE_CHART_DATE]: 'chart-widget',
    [WidgetVisuals.PIE_CHART]: 'chart-widget',
    [WidgetVisuals.MULTI_PIE_CHART]: 'multi-chart-widget',
    [WidgetVisuals.BAR_CHART]: 'chart-widget',
    [WidgetVisuals.FUNNEL]: 'chart-widget',
    [WidgetVisuals.MULTI_FUNNEL]: 'multi-chart-widget',
    [WidgetVisuals.EXPECTED_PERFORMANCE_WITH_TARGETS]: 'expected-performance-with-targets-widget',
    [WidgetVisuals.DATAFEED_PERFORMANCE]: 'datafeed-performance-widget',
    [WidgetVisuals.METRIC_SNAPSHOT]: 'metric-snapshot-widget',
    [WidgetVisuals.PARTICIPANT_ENGAGEMENT]: 'participant-engagement-widget',
    [WidgetVisuals.TOP_THREE_STRENGTHS]: 'top-three-strengths-widget',
    [WidgetVisuals.APPLAUSES_RECEIVED]: 'applauses-received-widget',
    [WidgetVisuals.APPLAUSES_SENT]: 'applauses-sent-widget',
    [WidgetVisuals.INCENTIVE_PAYOUT_GAUGE]: 'incentive-payout-gauge-widget',
    [WidgetVisuals.DATAFEED_SUMMARY_COUNT]: 'datafeed-summary-count-widget',
    [WidgetVisuals.TILES]: 'tiles-widget',
    [WidgetVisuals.SLIDESHOW]: 'slideshow-widget',
    [WidgetVisuals.ANNIVERSARY]: 'anniversary-widget',
    [WidgetVisuals.BIG_NUMBER]: 'big-number-widget',
    [WidgetVisuals.PROGRESS_BARS]: 'progress-bars-widget',
    [WidgetVisuals.PROMO_CALCULATIONS_INFO]: 'calculate-info-widget',
    [WidgetVisuals.LEADERBOARD]: 'applause-leaderboard-widget',
    [WidgetVisuals.TARGET_PROGRESS]: 'target-progress-widget',
};
