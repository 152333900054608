accountRun.$inject = ['$transitions', '$state', 'AuthStatus', 'Session'];

export default function accountRun($transitions, $state, AuthStatus, Session) {
    $transitions.onBefore({ to: 'account' }, function(transition) {
        const params = Object.assign({}, transition.params());

        const contactIdParam = params.contactID;
        if (contactIdParam && ['confirmed', 'pending', 'declined'].includes(params.tab)) {
            return;
        }

        if (!params.tab) {
            params.tab = 'confirmed';
        }
        if (!contactIdParam && AuthStatus.isAuthenticated()) {
            params.contactID = Session.getUser().userID;
        }
        return $state.target('account', params);
    });
}
