applausePointsInputRun.$inject = ['$translate', 'formlyConfig'];

export default function applausePointsInputRun($translate, formlyConfig) {
    formlyConfig.setType({
        name: 'applausePointsInput',
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        extends: 'customInput',
        defaultOptions: {
            expressionProperties: {
                'templateOptions.labelSrOnly': 'model.pointGuidelines.pointGuidelineOptions',
                'templateOptions.labelClass': `model.pointGuidelines.pointGuidelineOptions ? '' : 'col-sm-2'`,
                'templateOptions.controlClass': `model.pointGuidelines.pointGuidelineOptions ? 'col-sm-12' : 'col-sm-10'`,
                'templateOptions.disabled': function(viewValue, modelValue, scope) {
                    return !scope.model.budget || (scope.model.budget && !scope.model.budget.extra);
                },
                'data.hideValidation': 'model.selectedGuideline.ID !== -1'
            },
            validators: {
                number: {
                    expression: function(viewValue, modelValue, scope) {
                        const value = modelValue || viewValue;
                        const to = scope.to;
                        return !to.required || !isNaN(value);
                    },
                    message: function() {
                        return $translate.instant('applauseForm_POINTS_INPUT_VALIDATION_ENTER_NUMBER'); // Please enter a number
                    }
                },
                nonNegative: {
                    expression: function (viewValue, modelValue, scope) {
                        const value = modelValue || viewValue;
                        const to = scope.to;
                        return !to.required || (value >= 0);
                    },
                    message: function() {
                        return $translate.instant('applauseForm_POINTS_INPUT_VALIDATION_NO_NEGATIVE_NUMBER'); // Can't send negative points
                    }
                },
                nonZero: {
                    expression: function (viewValue, modelValue, scope) {
                        const value = modelValue || viewValue;
                        const to = scope.to;
                        return !to.required || (value != 0);
                    },
                    message: function() {
                        return $translate.instant('applauseForm_POINTS_INPUT_VALIDATION_SEND_POINTS'); // Please send some points
                    }
                },
                overLimit: {
                    expression: function (viewValue, modelValue, scope) {
                        const value = modelValue || viewValue;
                        const to = scope.to;
                        if (!to.required) {
                            return true;
                        }
                        if ((scope.model.recipients || scope.model.group) && scope.model.budget) {
                            if (scope.model.recipients && scope.model.recipients.length) {
                                return scope.model.budget.extra >= (value * scope.model.recipients.length);
                            } else if (scope.model.group && scope.model.group.totalCount) {
                                return scope.model.budget.extra >= (value * scope.model.group.totalCount);
                            }
                        }
                        return true;
                    },
                    message: function() {
                        return $translate.instant('applauseForm_POINTS_INPUT_VALIDATION_OVER_BUDGET_LIMIT'); // This is over the budget limit
                    }
                }
            }
        }
    });
}
