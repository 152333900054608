import _ from 'lodash';

multiChartWidgetCtrl.$inject = [
    '$interval', '$scope', '$timeout', 'ChartWidgetService', 'SnapshotService', 'WidgetService', 'WidgetVisuals'
];

export default function multiChartWidgetCtrl(
    $interval, $scope, $timeout, ChartWidgetService, SnapshotService, WidgetService, WidgetVisuals
) {
    this.$onInit = () => {
        const vm = this;

        vm.WidgetVisuals = WidgetVisuals;

        const charts = formatCharts(vm.widget.data.charts);

        // default to first chart with dataPoints, if none of the charts have dataPoints, default to first chart
        let selectedChart = charts[0];

        _.defaults(vm, {
            stats: selectedChart.stats || [],
            subStats: selectedChart.subStats || [],
            hasData: selectedChart.hasData
        });

        vm.chartNames = vm.widget.data.charts.map(chart => chart.name);

        vm.selectedChartName = vm.chartNames[0];
        vm.isPieChart = [vm.WidgetVisuals.MULTI_PIE_CHART].includes(vm.widget.widgetVisualCode);
        vm.isFunnelChart = [vm.WidgetVisuals.MULTI_FUNNEL].includes(vm.widget.widgetVisualCode);
        vm.chartCssID = ChartWidgetService.createChartCssID(vm.widget.widgetsAvailableID);

        vm.chartConfig = vm.chartConfig
            || ChartWidgetService.createChartConfigByVisual(vm.widget.widgetVisualCode, Object.assign({}, selectedChart, {
                extraJSON: vm.widget.data.extraJSON
            }));
        _.merge(vm.chartConfig, WidgetService.getOverrideConfig(vm.widget.data));

        vm.chartContainerHeight = ChartWidgetService.getChartContainerHeight({
            widgetVisual: vm.widget.widgetVisualCode,
            hasStats: vm.stats.length >= 1
        });

        vm.onSelectedChartChange = function() {
            selectedChart = vm.widget.data.charts.find(chart => chart.name === vm.selectedChartName);
            vm.hasData = selectedChart.hasData;
            if (!vm.hasData) {
                return;
            }
            vm.chartConfig = ChartWidgetService.createChartConfigByVisual(vm.widget.widgetVisualCode, Object.assign({}, selectedChart, {
                extraJSON: vm.widget.data.extraJSON
            }));
            _.merge(vm.chartConfig, WidgetService.getOverrideConfig(vm.widget.data));
            vm.stats = selectedChart.stats || [];
            vm.SubStats = selectedChart.subStats || [];
            const chartInstance = ChartWidgetService.getChartInstance({
                chartCssID: vm.chartCssID,
                widgetsAvailableID: vm.widget.widgetsAvailableID,
                widgetVisualCode: vm.widget.widgetVisualCode
            });
            // when switching from a chart with no data to a chart with data, chart instance will be undefined
            if (chartInstance) {
                chartInstance.option({
                    dataSource: vm.chartConfig.dataSource
                });
            }
            $timeout(fixChartSize);
        };

        const fixChartSize = () => {
            if (!vm.hasData) {
                return null;
            }
            return ChartWidgetService.fixChartSize({
                chartCssID: vm.chartCssID,
                widgetVisualCode: vm.widget.widgetVisualCode
            });
        };

        // Workaround for bug where the dx-chart overflows out of the container element on initial render.
        $timeout(fixChartSize);

        // sets chart.hasData if the charts have dataPoints and adds a custom tooltip for each dataPoint
        function formatCharts(charts) {
            return charts.map(chart => {
                chart.dataSets = chart.dataSets.map(dataSet => {
                    if (dataSet.dataPoints) {
                        chart.hasData = true;
                        dataSet.dataPoints = dataSet.dataPoints.map(dataPoint => {
                            const formattedDataPoint = Object.assign({}, dataPoint);
                            const toolTipValue = WidgetService.formatTooltipValue({
                                displayType: dataSet.displayType,
                                value: dataPoint.value
                            });
                            formattedDataPoint.tooltip = `<div style="font-size: 14px;">
                            <p style="font-weight: bold; border-bottom: 4px solid #6b6b6b; margin-bottom: 0.3em;">
                                ${dataPoint.name}: ${toolTipValue}
                            </p>
                            ${getBottomTooltipSection({ charts, dataPointName: dataPoint.name })}
                        </div>`;
                            return formattedDataPoint;
                        });
                    }
                    return dataSet;
                });
                return chart;
            });
        }

        // returns html used to display values from all charts for a specific dataPoint
        function getBottomTooltipSection({ charts, dataPointName }) {
            let tooltip;
            charts.forEach(chart => {
                if (tooltip) {
                    tooltip += `<p style="margin: 0.1em 0; color: #6b6b6b">${chart.name}:`;
                } else {
                    tooltip = `<p style="margin: 0.1em 0; color: #6b6b6b">${chart.name}:`;
                }
                chart.dataSets.forEach(dataSet => {
                    const hasMatchingDataPointName = dataSet.dataPoints && dataSet.dataPoints.find(dataPoint => dataPoint.name === dataPointName);
                    if (!hasMatchingDataPointName) {
                        tooltip += getBottomToolipDisplayValue({
                            displayType: dataSet.displayType,
                            value: 0
                        });
                    } else {
                        dataSet.dataPoints.forEach(dataPoint => {
                            if (dataPoint.name === dataPointName) {
                                tooltip += getBottomToolipDisplayValue({
                                    displayType: dataSet.displayType, value: dataPoint.value
                                });
                            }
                        });
                    }
                });
            });
            return tooltip;
        }

        function getBottomToolipDisplayValue({ displayType, value }) {
            const toolTipValue = WidgetService.formatTooltipValue({
                displayType,
                value
            });
            return `<span style="margin-left: 0.2em; font-weight: bold;">
                        ${toolTipValue}
                    </span></p>`;
        }
    };
}
