import angular from 'angular';

import '../../core/core.module';

import claimConfig from './claim.config';
import claimCtrl from './claim.controller';
import claimService from './claimService.factory';

export default angular.module('app.claim', [
    'app.core'
])
.config(claimConfig)
.controller('claimCtrl', claimCtrl)
.factory('ClaimService', claimService);
