import angular from 'angular';

import i18nConfig from './i18n.config';
import translateConfig from './translate.config';
import localeRun from './locale.run';
import LangUtils from './langUtils.factory';
import RnTranslationService from './rnTranslationService.factory';

export default angular.module('app.i18n', [
    'pascalprecht.translate',
    'tmh.dynamicLocale',
])
.config(i18nConfig)
.config(translateConfig)
.run(localeRun)
.factory('LangUtils', LangUtils)
.factory('RnTranslationService', RnTranslationService);
