import forgotPasswordTpl from './forgotPassword.tpl.html';

forgotPasswordDirective.$inject = [];

export default function forgotPasswordDirective() {
    return {
        restrict: 'E',
        templateUrl: forgotPasswordTpl,
        controller: 'forgotPasswordCtrl as vm',
        scope: {},
        bindToController: {
            username: '=',
        }
    };
}
