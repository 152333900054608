import contentManagementTpl from './contentManagement.tpl.html';
import contentManagementCtrl from './contentManagement.controller';

export default {
    templateUrl: contentManagementTpl,
    controller: contentManagementCtrl,
    controllerAs: 'vm',
    bindings: {
        contentList: '<',
        roles: '<',
        contentTopicsList:'<',
    },
};
