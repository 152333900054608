import angular from 'angular';

import appCoreModule from '../../../core/core.module';
import promptModule from '../../../blocks/modal/prompt/prompt.module';
import detailModule from './detail/detail.module';

import contentManagementConfig from './contentManagement.config';
import contentManagementComponent from './contentManagement.component';
import ContentManagementService from './contentManagementService.factory';

export default angular.module('app.admin.contentManagement', [
    appCoreModule.name,
    promptModule.name,

    detailModule.name,
])
    .config(contentManagementConfig)
    .component('contentManagementComponent', contentManagementComponent)
    .factory('ContentManagementService', ContentManagementService);
