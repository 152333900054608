import angular from 'angular';

import '../../../core/core.module';
import '../../signOnTasks/signOnTasks.module';
import './forgotPassword/forgotPassword.module';
import './registrationModal/registrationModal.module';

import loginPanelCtrl from './loginPanel.controller';
import browserSupport from './browserSupport/browserSupport.component';

export default angular.module('app.nav.loginPanel', [
    'app.core',
    'app.signOnTasks',
    'app.nav.loginPanel.forgotPassword',
    'app.nav.loginPanel.registrationModal',
])
.controller('loginPanelCtrl', loginPanelCtrl)
.component('browserSupport', browserSupport);
