import snapshotPreviewTpl from './snapshotPreview.tpl.html';

SnapshotPreviewService.$inject = ['$uibModal', 'LangUtils', 'PdfService'];

export default function SnapshotPreviewService($uibModal, LangUtils, PdfService) {
    return  {
        preview,
        print,
    };

    function preview(widgetPages, activePageIndex, activeSetIndex, contact) {
        $uibModal.open({
            templateUrl: snapshotPreviewTpl,
            controller: 'snapshotPreviewCtrl',
            controllerAs: 'vm',
            size: 'lg',
            resolve: {
                widgetPages: () => widgetPages,
                activePageIndex: () => activePageIndex,
                activeSetIndex: () => activeSetIndex,
                contact: () => contact,
            },
            windowClass: 'snapshot-preview'
        });
    }

    async function print() {
        const $snapshotPreview = $(`.preview-print-area`);
        if (!$snapshotPreview.length) {
            return;
        }
        const languageID = LangUtils.getLanguageID();
        return PdfService.downloadElement($snapshotPreview, {
            filename: `Snapshot_${languageID}.pdf`, // Match legacy server's filename format
            html2canvas: {
                scale: 2,
            },
            jsPDF: {
                orientation: 'landscape',
                format: 'letter',
            },
            pagebreak: {
                mode: ['avoid-all', 'legacy'],
                after: '.page-break',
            },
            noPadding: true,
        });
    }
}
