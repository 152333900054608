import _ from 'lodash';

resendInviteCtrl.$inject = ['ContactService'];

export default function resendInviteCtrl(ContactService) {
    this.$onInit = () => {
        const vm = this;

        // Passed from parent template via component bindings:
        // vm.contact
        // vm.onCancel
        // vm.onSuccess

        _.defaults(vm, {
            onSuccess: _.noop,
            onCancel: _.noop
        });

        vm.submitButton = {
            text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESEND_INVITE_BUTTON_SUBMIT', // Resend Invite
            class: 'primary',
            isDisabled: false
        };

        vm.contactName = `${vm.contact.firstName} ${vm.contact.lastName}`;

        vm.submit = function() {
            return ContactService.resendContactInvite(vm.contact.userID)
                .then(function() {
                    vm.submitButton = {
                        text: 'app_CONTACT_PANEL_ADMIN_TOOLS_RESEND_INVITE_BUTTON_SUCCESS', // Sent!
                        class: 'success',
                        isDisabled: true
                    };
                    return vm.onSuccess();
                })
                .catch(function(err) {
                    vm.errorMessage = err.data.phrase;
                });
        };
    };
}
