export default {
    ACCOUNT: 'ACCOUNT',
    SNAPSHOT: 'SNAPSHOT',
    QUIZ_RESULT_POPUP: 'QUIZ_RESULT_POPUP',
};

/*
    DatafeedGridSetupOptionTypes are codes that Angular receives from the db telling it what the "type" of an option
    or link is.

    The type determines what should happen when a user clicks on the link or the option button.

    Example of an options column config json:
        {
            isOptionsColumn: 1,
            caption: 'Options',
            options: [{
                optionType: 'ACCOUNT',
                valueColumns: {
                    contactID: 'contactID'
                }
            }, {
                optionType: 'SNAPSHOT',
                valueColumns: {
                    contactID: 'contactID'
                }
            }, {
                optionType: 'QUIZ_RESULT_POPUP',
                valueColumns: {
                    incentiveID: 'incentiveID',
                    quizResultID: 'quizResultID'
                }
            }]
        }
        --> when clicking on the "Account" button in the options column, it will link to /#/account/{{ contactID }}
        --> when clicking on the "Snapshot" button in the options column, it will link to /#/snapshot{{ contactID }}
        --> when clicking on the "Open details" button in the options column, a popup will open


    Example of link columns config json:
        linkColumns: [{
            column: 'participant',
            optionType: 'SNAPSHOT',
            valueColumns: {
                contactID: 'contactID'
            }
        }, {
            column: 'incentiveName',
            optionType: 'INCENTIVE_DETAILS',
            valueColumns: {
                incentiveID: 'incentiveID'
            }
        }]
       --> when clicking on the "participant" cell, it will link to /#/snapshot/{{ contactID }}
       --> when clicking on the "incentiveName" cell, it will link to /#/incentive/details{{ incentive ID }}

   valueColumns tells Angular the values the other columns of the row to use in creating the URL.

   Related SPs:
        RN2_TableViewSetup_Angular
        RN2_Tool_CompanyMetrics_LinkColumnsJSON_SetDefault
        RN2_Tool_CompanyMetrics_OptionFiltersJSON_SetDefault
        RN2_TableViewSetup_Regenerate

   Related db table columns:
        CompanyMetrics.OptionFiltersJSON
        CompanyMetrics.LinkColumnsJSON
        TableViewSetup.AngularJSON
 */
