promoResultsCtrl.$inject = ['promoDetails', '$translate', 'Session'];

export default function promoResultsCtrl(promoDetails, $translate, Session) {
    const vm = this;

    if (!promoDetails) {
        return;
    }

    vm.screen = 'results';
    vm.user = Session.getUser();
    vm.pageTitle = $translate.instant('promo_DROPDOWN_RESULTS_TEXT');
    vm.promoDetails = promoDetails;
    vm.hasManagePromosPermission = vm.user.hasPermission('ManagePromos');
}
