import quickLinksTpl from './quickLinks.tpl.html';

quickLinksConfig.$inject = ['$stateProvider'];

export default function quickLinksConfig($stateProvider) {

    $stateProvider.state('quickLinks', {
        parent: 'auth',
        url: '/quick-links',
        data: {
            pageTitle: 'Quick Links',
        },
        views: {
            'main@': {
                templateUrl: quickLinksTpl,
                controller: 'quickLinksCtrl as vm',
            },
        },
        resolve: {
            quickLinks: ['NavService', function(NavService) {
                return NavService.getNavLinks();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                // Since nav seems to be the parent of all other states, it's probably a good place to load app-wide phrases
                return RnTranslationService.loadPhrases('app');
            }],
        }
    });
}