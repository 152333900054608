import _ from 'lodash';

participantEngagementWidgetCtrl.$inject = ['$timeout', '$translate', 'ChartWidgetService', 'WidgetService'];

export default function participantEngagementWidgetCtrl($timeout, $translate, ChartWidgetService, WidgetService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.hasData = ChartWidgetService.defaultHasDataCheck(vm.widget);
        if (!vm.hasData) {
            return;
        }

        vm.showParticipantBreakdown = false;

        const participantsByStatus = vm.widget.data.participantsByStatus;
        vm.deletedParticipants = participantsByStatus.deleted;
        vm.totalParticipants = _.sum(Object.values(participantsByStatus)) - vm.deletedParticipants;

        vm.participantBreakdown = [{
            translateKey: 'snapshot_PARTICIPANT_ENGAGEMENT_WIDGET_PARTICIPANT_BREAKDOWN_INVITED',
            cssClass: 'participant-breakdown-invited',
            count: participantsByStatus.invited
        }, {
            translateKey: 'snapshot_PARTICIPANT_ENGAGEMENT_WIDGET_PARTICIPANT_BREAKDOWN_ACTIVE',
            cssClass: 'participant-breakdown-active',
            count: participantsByStatus.active
        }, {
            translateKey: 'snapshot_PARTICIPANT_ENGAGEMENT_WIDGET_PARTICIPANT_BREAKDOWN_PASSIVE',
            cssClass: 'participant-breakdown-passive',
            count: participantsByStatus.passive,
        }, {
            translateKey: 'snapshot_PARTICIPANT_ENGAGEMENT_WIDGET_PARTICIPANT_BREAKDOWN_TOTAL',
            cssClass: 'participant-breakdown-total',
            count: vm.totalParticipants
        }];

        const dateLineChartConfig = ChartWidgetService.createDateLineChartConfig(vm.widget.data);

        vm.chartConfig = _.merge(dateLineChartConfig, {
            palette: 'Harmony Light'
        }, WidgetService.getOverrideConfig(vm.widget.data));

        vm.chartContainerHeight = ChartWidgetService.getChartContainerHeight({
            widgetVisual: vm.widget.widgetVisualCode,
            hasStats: vm.widget.data.stats,
            hasTitle: true,
            additionalSubtractions: ['2.5em'] // Need to subtract the height of the "Totals" footer
        });

        vm.toggleParticipantBreakdown = function(showParticipantBreakdown = !vm.showParticipantBreakdown) {
            vm.showParticipantBreakdown = showParticipantBreakdown;
        };
    };
}
