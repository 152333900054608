PointExpiryTableService.$inject = ['$http'];

export default function PointExpiryTableService($http) {
    async function getExpiringPointsForecast(contactID) {
        const res = await $http.get('/api/point-tracking/expiring-forecast', {
            params: {
                contactID
            }
        });
        return res.data;
    }
    return {
        getExpiringPointsForecast
    };
}
