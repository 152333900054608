import _ from 'lodash';
import suggestTpl from './suggest.tpl.html';
import typeAheadTpl from '../../../app/features/suggest/typeAhead.tpl.html';

customSuggestRun.$inject = ['$translate', 'SuggestService', 'formlyConfig'];

export default function customSuggestRun($translate, SuggestService, formlyConfig) {

    // TODO: use <suggest-input> component instead of a separate template and controller

    formlyConfig.setType({
        name: 'customSuggest',
        templateUrl: suggestTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            modelOptions: {
                debounce: {
                    default: 1000,
                    blur: 0
                },
                updateOn: 'default blur'
            },
            expressionProperties: {
                // totalCountKey is the keyname of the totalCount in the return value of the SP
                // ex: SuggestsType.MY_GROUPS in applause.controller.js S
                'templateOptions.totalCount': function(viewValue, modelValue, scope) {
                    if (viewValue) {
                        return scope.to.totalCountKey && viewValue[scope.to.totalCountKey];
                    }
                }
            },
            validation: {
                messages: {
                    garbageValue: () => $translate.instant('app_CUSTOM_MULTI_SUGGEST_VALIDATION_INVALID_SELECTION')
                }
            },
            validators: {
                typeAhead(viewValue, modelValue, scope) {
                    const value = modelValue || viewValue;
                    return !scope.to.required || value === '' || _.isObject(value);
                }
            }
        },
        controller: ['$scope', function($scope) {
            const options = $scope.options;
            const to = $scope.to;

            $scope.typeAheadTemplateUrl = typeAheadTpl;

            $scope.getValue = SuggestService.getFunctionByType({
                type: to.type,
                contactID: options.data.contactID,
                limiterOrder: options.data.limiterOrder
            });

            if (to.placeholderTranslateKey) {
                to.placeholder = $translate.instant(to.placeholderTranslateKey);
            }

            // new code for input validation
            $scope.$watch('model[options.key]', function(newValue) {
                if ($scope.fc && newValue) {
                    if (newValue.ID) {
                        $scope.fc.$setValidity('typeAhead', true);
                    } else {
                        $scope.fc.$setValidity('typeAhead', false);
                    }

                    if (newValue.ID == -1) {
                        $scope.fc.$setValidity('garbageValue', false);
                    } else {
                        $scope.fc.$setValidity('garbageValue', true);
                    }
                }

            });
        }]
    });
}
