import answerDetailsModalTpl from './answerDetailsModal.tpl.html';
import answerDetailsModalCtrl from './answerDetailsModal.controller';

export default {
    templateUrl: answerDetailsModalTpl,
    controller: answerDetailsModalCtrl,
    controllerAs: 'vm',
    bindings: {
        results: '<',
        isAll: '<',
    }
};
