RedemptionService.$inject = ['$http', 'SignOnTasks'];

export default function RedemptionService($http, SignOnTasks) {
    return {
        updateIsAttention,
        addNote,
        updateAddress
    };

    async function updateIsAttention(redemptionID, isAttention) {
        const res = await $http.post(`/api/redemption/${redemptionID}/attention`, {
            isAttention
        });
        return res.data;
    }

    async function addNote(redemptionID, newNote, isPublic, statusID) {
        const res = await $http.post(`/api/redemption/${redemptionID}/notes`, {
            newNote,
            isPublic,
            statusID,
        });
        return res.data;
    }

    async function updateAddress({
        redemptionID,
        companyName,
        address1,
        address2,
        city,
        province,
        country,
        postalcode,
        phone,
        statusID,
        isPublic,
        note
    }) {
        const res = await $http.put(`/api/redemption/${redemptionID}/address`, {
            companyName,
            address1,
            address2,
            city,
            province,
            country,
            postalcode,
            phone,
            statusID,
            isPublic,
            note
        });
        await SignOnTasks.removeTask({ type: 'UpdateAddress', redemptionID });
        return res.data;
    }
}
