topThreeStrengthsWidgetCtrl.$inject = ['StrengthsService'];

export default function topThreeStrengthsWidgetCtrl(StrengthsService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.strengths = vm.widget.data;

        if (!vm.strengths) {
            return;
        }

        vm.strengths.forEach(function(strength) {
            strength.imgSrc = StrengthsService.getStrengthBadgeImageSrc(strength.ID);
        });

        switch (vm.strengths.length) {
            case 1:
                vm.middleStrength = vm.strengths[0];
                break;
            case 2:
                vm.leftStrength = vm.strengths[0];
                vm.rightStrength = vm.strengths[1];
                break;
            case 3:
                vm.leftStrength = vm.strengths[1];
                vm.middleStrength = vm.strengths[0];
                vm.rightStrength = vm.strengths[2];
                break;
        }

        if (vm.leftStrength) {
            vm.leftStrength.position = 'left';
        }
        if (vm.middleStrength) {
            vm.middleStrength.position = 'middle';
        }
        if (vm.rightStrength) {
            vm.rightStrength.position = 'right';
        }
    };
}
