import promoQuizCloseTpl from './close.tpl.html';
import promoQuizCloseCtrl from './close.controller';

export default {
    templateUrl: promoQuizCloseTpl,
    controller: promoQuizCloseCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        section: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<'
    }
};
