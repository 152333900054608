import angular from 'angular';

import columnChooserCtrl from './columnChooser.controller';
import ColumnChooserService from './columnChooserService.factory';

import 'angular-ui-bootstrap';

export default angular.module('app.core.directives.rnDxGrid.columnChooser', [
    'ui.bootstrap'
])
.factory('ColumnChooserService', ColumnChooserService)
.controller('columnChooserCtrl', columnChooserCtrl);
