quizResultStatusAlertMessageCtrl.$inject = ['QuizResultStatuses', 'QuizResultStatusLabelService'];

export default function quizResultStatusAlertMessageCtrl(QuizResultStatuses, QuizResultStatusLabelService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.quizResultStatusID
        // vm.isApprover
        // vm.quizTakerID
        // vm.points

        vm.phrase = QuizResultStatuses.properties[vm.quizResultStatusID].translateKey;

        vm.alertMessageSettings = QuizResultStatusLabelService.getAlertMessageSettings({
            quizResultStatusID: vm.quizResultStatusID,
            isApprover: vm.isApprover,
            quizTakerID: vm.quizTakerID
        });
    };
}
