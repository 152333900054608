import angular from 'angular';

import detailConfig from './detail.config';
import detailComponent from './detail.component';
import detailService from './detailService.factory';

export default angular.module('app.admin.contentManagement.details', [
])
    .config(detailConfig)
    .component('contentManagementDetailComponent', detailComponent)
    .factory('ContentManagementDetailService', detailService);
