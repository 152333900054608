import _ from 'lodash';

translateConfig.$inject = ['$translateProvider', '$translatePartialLoaderProvider'];

/**
 * Initializes angular-translate.
 * Helpful to understand: http://angular-translate.github.io/docs/#/guide/12_asynchronous-loading
 */
export default function translateConfig($translateProvider, $translatePartialLoaderProvider) {
    // Angular uses lowercase language code because a lot of our front end libraries expect lowercase.
    // Node uses uppercase language code because our db expects uppercase.
    const availableLanguageKeys = Object.keys(window.rnSession.company.languageOptions).map(_.lowerCase);

    // Set by Node, guaranteed to be a valid company language.
    const languageCode = _.lowerCase(window.rnSession.languageCode);

    $translateProvider.registerAvailableLanguageKeys(availableLanguageKeys);
    $translateProvider.fallbackLanguage([languageCode]);
    $translateProvider.preferredLanguage(languageCode);

    // Will log when translations are missing.
    $translateProvider.useMissingTranslationHandlerLog();

    // Use MessageFormat for pluralization and variable replacement.
    // https://angular-translate.github.io/docs/#/guide/14_pluralization
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

    // Bug with "sanitize" mode - UTF-8 characters or special characters get double-encoded.
    // Can't use "escape" mode - we want to allow HTML in phrases.
    // Can't use "sce" mode - it would cause the return value of $translate.instant() to be a TrustedValueHolderType
    // object but we rely on it being a string in too many places.
    $translateProvider.useSanitizeValueStrategy(null);

    // Tells angular-translate the URL from which to load Phrase Caches.
    $translateProvider.useLoader('$translatePartialLoader', {
        // Currently assuming that the user will only want phrases for their currently set language.
        urlTemplate: '/api/i18n/phrases/{part}'
    });

    // Register pre-loaded phrases.
    _.forEach(window.rnSession.phrases, (phraseCache, prefix) => {
        $translatePartialLoaderProvider.setPart(languageCode, prefix, phraseCache);
    });
}
