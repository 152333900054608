
import _ from 'lodash';

const budgetPointsTpl = require('./budgetPoints.tpl.html');

applauseBudgetSuggestRun.$inject = ['formlyConfig', '$translate', 'SuggestsTypes'];

export default function applauseBudgetSuggestRun(formlyConfig, $translate, SuggestsTypes) {
    formlyConfig.setWrapper({
        name: 'budgetPoints',
        templateUrl: budgetPointsTpl
    });

    formlyConfig.setType({
        name: 'applauseBudgetSuggest',
        extends: 'customSuggest',
        wrapper: ['budgetPoints', 'validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            templateOptions: {
                type: SuggestsTypes.MY_BUDGETS
            },
            expressionProperties: {
                'data.totalPoints': function (viewValue, modelValue, scope) {
                    const fc = scope.fc;
                    const value = modelValue || viewValue;
                    if (_.isObject(value) && fc.$valid) {
                        scope.options.data.calcWidth = 'budget-calc-width inline-block';
                        return value.extra;
                    } else {
                        scope.options.data.calcWidth = null;
                        return false;
                    }
                }
            },
            validators: {
                noZeroPoints: {
                    expression: function(viewValue, modelValue) {
                        const value = modelValue || viewValue;
                        if (value) {
                            return value.extra > 0;
                        }
                        return true;
                    },
                    // 'This budget has no points'
                    message: () => $translate.instant('applauseForm_BUDGET_SUGGEST_NO_POINTS_MESSAGE')
                }
            }
        }
    });
}
