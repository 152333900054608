export default function PromoQuizToolsService() {
    const approveTool = {
        index: 1,
        button: {
            text: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_APPROVE', // Approve
            class: 'promo-quiz-tool-approve-button',
        },
    };

    const declineTool = {
        index: 2,
        button: {
            text: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_DECLINE', // Decline
            class: 'promo-quiz-tool-decline-button',
        },
    };

    const assignBackTool = {
        index: 3,
        button: {
            text: 'app_PROMO_QUIZ_SECTION_ADD_NOTE_ASSIGN_BACK', // Assign Back
            class: 'promo-quiz-tool-add-note-button',
        },
    };

    const closeTool = {
        index: 4,
        button: {
            text: 'app_PROMO_QUIZ_SECTION_TOOL_BUTTON_CLOSE', // Close
            class: 'promo-quiz-tool-close-button',
        },
    };

    return {
        getActiveQuizTools,
        getQuizToolsSetup
    };

    function getActiveQuizTools(showApproverTools, section) {
        if (showApproverTools) {
            if (section.isFinalSection) {
                return {
                    quizTools: [approveTool, declineTool, assignBackTool, closeTool],
                    activeQuizToolIndex: approveTool.index,
                };
            } else {
                return {
                    quizTools: [approveTool, declineTool, assignBackTool],
                    activeQuizToolIndex: approveTool.index,
                };
            }
        } else {
            return {
                quizTools: [],
                activeQuizToolIndex: null,
            };
        }
    }

    function getQuizToolsSetup(showApproverTools) {
        if (showApproverTools) {
            return {
                approveTool, declineTool, assignBackTool, closeTool
            };
        }
        return {};
    }
}
