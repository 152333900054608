import _ from 'lodash';
import datafeedTpl from './datafeed.tpl.html';

datafeedConfig.$inject = ['$stateProvider'];

export default function datafeedConfig($stateProvider) {
    const commonStateConfig = {
        parent: 'auth',
        params: {
            // For use with $state.go, since the "filters", "columns",
            // and "sort" params needs to be a specially formatted string
            filtersArray: null,
            columnsObject: null,
            sortArray: null,
            contactID: null,
            code: null,
            columns: {
                dynamic: true
            }
        },
        views: {
            'main@': {
                controller: 'datafeedCtrl as vm',
                templateUrl: datafeedTpl
            }
        },
        data: {
            pageTitle: 'Data Feed', // Will get replaced when the gridSetup is received
            navLinkOptions: {
                // Prevents not being able to go to own datafeed
                // when clicking on the link while on another contact's datafeed
                inherit: false
            }
        }
    };

    const urlParams = '?filters&columns&sort&settingsSlot';

    $stateProvider.state('datafeedContact', _.merge({}, commonStateConfig, {
        url: '/datafeed/:codeName/contact/:contactID' + urlParams,
        data: {
            isHierarchy: false,
        },
        resolve: {
            gridSetup: [
                '$state', '$transition$', '$stateParams', 'DatafeedService',
                ($state, $transition$, $stateParams, DatafeedService) => DatafeedService.getGridSetup({
                    codeName: $stateParams.codeName,
                    isHierarchy: false })],
            contact: ['$stateParams', 'ContactService',
                ($stateParams, ContactService) => ContactService.getContact($stateParams.contactID)],
            datafeedTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('datafeed')],
            promoTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('promo')],
        }
    }));

    $stateProvider.state('datafeedHierarchy', _.merge({}, commonStateConfig, {
        url: '/datafeed/:codeName/hierarchy/:contactID' + urlParams,
        data: { 
            isHierarchy: true,
            isAll: false
        },
        resolve: {
            gridSetup: ['$state', '$transition$', '$stateParams', 'DatafeedService',
                ($state, $transition$, $stateParams, DatafeedService) => DatafeedService.getGridSetup({
                    codeName: $stateParams.codeName,
                    isHierarchy: true,
                    isAll: false
                })],
            contact: ['$stateParams', 'ContactService',
                ($stateParams, ContactService) => ContactService.getContact($stateParams.contactID)],
            datafeedTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('datafeed')],
            promoTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('promo')],
        }
    }));


    $stateProvider.state('datafeedAll', _.merge({}, commonStateConfig, {
        url: '/datafeed/:codeName/all/:contactID' + urlParams,
        data: { isHierarchy: true
        },
        resolve: {
            gridSetup: ['$state', '$transition$', '$stateParams', 'DatafeedService',
                ($state, $transition$, $stateParams, DatafeedService) => DatafeedService.getGridSetup({
                    codeName: $stateParams.codeName,
                    isHierarchy: true,
                    isAll: true
                })],
            contact: ['$stateParams', 'ContactService',
                ($stateParams, ContactService) => ContactService.getContact($stateParams.contactID)],
            datafeedTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('datafeed')],
            promoTranslation: ['RnTranslationService',
                (RnTranslationService) => RnTranslationService.loadPhrases('promo')],
        }
    }));    
}
