import moment from 'moment';
import { PERSISTENT_COOKIE_MAX_AGE } from '../../../../../shared/cookieAge';

SsoService.$inject = ['$cookies', 'Session', 'SsoTypes'];

export default function SsoService($cookies, Session, SsoTypes) {

    /**
     * @param {Object} params
     * @param {boolean} [params.ignoreDirect = false]
     *      If true then if selected endpoint is type DIRECT_SIGN_IN, it won't be returned.
     *      Useful if you need to know if the user needs to log in with credentials.
     * @returns {string | undefined}
     */
    function getSelectedEndpoint({ ignoreDirect = false } = {}) {
        const ssoEndpointCodeSelected = $cookies.get('ssoEndpointCodeSelected');
        if (!ssoEndpointCodeSelected) {
            return;
        }
        const company = Session.getCompany();
        const ssoEndpoint = company.findSsoEndpointByCode(ssoEndpointCodeSelected);
        if (!ssoEndpoint) {
            return;
        }
        if (ignoreDirect && ssoEndpoint.ssoTypeID === SsoTypes.DIRECT_SIGN_IN) {
            return;
        }
        return ssoEndpoint;
    }

    function setSelectedEndpoint(ssoEndpoint) {
        $cookies.put('ssoEndpointCodeSelected', ssoEndpoint.code, {
            expires: moment().add(PERSISTENT_COOKIE_MAX_AGE, 'ms').toDate()
        });
    }

    return {
        getSelectedEndpoint,
        setSelectedEndpoint,
    };
}
