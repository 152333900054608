/**
 * Created by Jun on 2/9/2016.
 */

selectOnClick.$inject = ['$window'];

export default function selectOnClick($window) {
    return {
        restrict: 'AC',
        scope: {
            isSelectingOnClick: '=selectOnClick'
        },
        link: function (scope, element, attrs) {
            element.on('click', function () {
                if (!$window.getSelection().toString() && scope.isSelectingOnClick) {
                    // Required for mobile Safari
                    this.setSelectionRange(0, this.value.length);
                }
            });
        }
    };
}
