// Hide these eslint warnings to nicely show what arguments overriding functions will receive
/* eslint no-empty-function: 0, no-unused-vars: 0 */

import angular from 'angular';
import _ from 'lodash';

import taskModalTpl from '../taskModal.tpl.html';
import taskModalCtrl from '../taskModal.controller';

/**
 * An object representing a sign on task.
 * Returned from the signOnTasks_get SP.
 *
 * @typedef {Object} TaskObject
 * @property {String} type
 * @property {boolean} isRequired
 * @property {String} [contentHtml]
 * @property {String} [contentURL]
 * @property {String} [title] - can be a phrase key name
 * @property {String} [acceptButtonText] - can be a phrase key name
 * @property {String} [cancelButtonText] - can be a phrase key name
 * @property {String} [successMessage] - can be a phrase key name
 * @property {int} [messageRecipientID]
 * @property {String} [messageTypeCode]
 * @property {int} [redemptionID]
 * @property {int} [pointTrackingID]
 */

export default class SignOnTask {

    /**
     * @param {TaskObject} taskObject
     * @param $injector
     */
    constructor(taskObject, $injector) {
        this.$http = $injector.get('$http');
        this.$q = $injector.get('$q');
        this.$uibModal = $injector.get('$uibModal');
        this.$translate = $injector.get('$translate');
        this.SignOnTasks = $injector.get('SignOnTasks');
        this.ContentService = $injector.get('ContentService');

        this.type = taskObject.type;
        this.title = taskObject.title;
        this.isRequired = taskObject.isRequired;
        this.contentHtml = taskObject.contentHtml;
        this.contentURL = taskObject.contentURL;
        this.acceptButtonText = taskObject.acceptButtonText;
        this.cancelButtonText = taskObject.cancelButtonText;
        this.successMessage = taskObject.successMessage;

        _.defaults(this, {
            title: 'app_SIGN_ON_TASKS_DEFAULT_TITLE', // 'Notification'
            acceptButtonText: 'app_SIGN_ON_TASKS_DEFAULT_ACCEPT_BUTTON_TEXT', // 'OK'
            cancelButtonText: 'app_SIGN_ON_TASKS_DEFAULT_CANCEL_BUTTON_TEXT', // 'Cancel'
            successMessage: 'app_SIGN_ON_TASKS_DEFAULT_SUCCESS_MESSAGE', // 'Success!'
            removeOnSubmit: true
        });

        /**
         * Use this to set some configuration options for the taskModal instance.
         * See taskModal.tpl.html for what each option does.
         */
        this.taskModalOptions = {
            animateFooter: true,
            showProgressHeader: !this.isRequired,
            showProgressHeaderSubmitButton: false,
            showTitleCloseButton: this.isRequired,
            showFooterButtons: true,
            showCancelButton: true,
            successMessageTimeout: 600,
        };
    }

    /**
     * Opens the task in a popup modal (ui-bootstrap).
     * @param previousTask {SignOnTask}
     * @param taskNumber {int}
     * @param totalNumberOfTasks {int}
     * @param options {Object=} - Additional options to pass to $uibModal.open (will take highest precedence)
     * @returns {Promise}
     */
    openModal(previousTask, taskNumber, totalNumberOfTasks, options = {}) {
        return this.$q.all({
            contentHtml: this.getContentHtml(),
            additionalData: this.getAdditionalData()
        })
        .then(({ contentHtml }) => {
            this.contentHtml = contentHtml || this.contentHtml;
        })
        .catch((err) => {
            this.disableCompletion(err);
        })
        .then(() => this.$uibModal.open(angular.merge({
            templateUrl: taskModalTpl,
            controller: taskModalCtrl,
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                signOnTask: () => this,
                previousTask: () => previousTask,
                taskNumber: () => taskNumber,
                totalNumberOfTasks: () => totalNumberOfTasks
            }
        }, this.extraModalOptions, options)));
    }

    /**
     * If request for additional data fails, completing the task will be disabled and an error message in the modal instead.
     */
    disableCompletion(err) {
        this.error = err;
        this.submit = this.$q.reject;
        this.onModalSubmitFail = (err, $scope) => $scope.$dismiss();
        this.contentHtml = this.$translate.instant('app_SIGN_ON_TASKS_CONTENT_NOT_AVAILABLE_MESSAGE'); // 'Sorry! Notification content not available'
        this.taskModalOptions.showFooterButtons = false;
    }

    /**
     * Will be called before displaying modal.
     * @returns {Promise}
     */
    getContentHtml() {
        if (!this.contentURL) {
            return this.$q.resolve();
        }
        return this.ContentService.getContentByCode(this.contentURL)
            .then(contentData => {
                if (!contentData) {
                    throw new Error(`User does not have permission to view content with URL "${this.contentURL}" or content doesn't exist`);
                }
                return contentData.content;
            });
    }

    // ----------------------------------------------------------------------------------------------------------------
    // Main override methods
    // ----------------------------------------------------------------------------------------------------------------

    /**
     * Will be called before displaying modal.
     * @returns {Promise}
     */
    getAdditionalData() {
        return this.$q.resolve();
    }

    /**
     * Additional options to pass to $uibModal.open
     * @returns {Object}
     */
    get extraModalOptions() {
        return {};
    }

    /**
     * Override to modify the taskModalCtrl instance for the task when it's loaded. Make sure to call super.
     * Can use this function to set new vm variables to data retrieved by getAdditionalData().
     * @param $scope - $scope of the taskModalCtrl instance (ui-bootstrap modal). Can access the controller vm with $scope.vm.
     */
    onModalControllerLoaded($scope) {

    }

    /**
     * Override this method to define custom components / directives that need to appear in the task modal.
     * Can use onModalControllerLoaded() to set the vm variables necessary to pass to the component bindings.
     * @returns {String}
     */
    get componentHtml() {
        return '';
    }

    /**
     * Called by the task modal controller when user clicks the "submit" or "OK" or "Mark as read" button.
     * Task classes shouldn't override this, but override onSubmit instead.
     * @param $scope - $scope of the calling controller. Can access the controller vm with $scope.vm.
     * @returns {Promise}
     */
    async submit($scope) {
        await this.onSubmit($scope);
        if (this.removeOnSubmit) {
            return this.SignOnTasks.removeTask(this);
        }
    }

    /**
     * Override this method to define how to "complete" a task (after user clicks the "submit" or "OK" button on the modal).
     * Make POST requests, broadcast events, etc.
     * @param $scope - $scope of the calling controller. Can access the controller vm with $scope.vm.
     * @returns {Promise}
     */
    onSubmit($scope) {
        return this.$q.resolve();
    }

    /**
     * Will be called after submit() if it succeeds. Modal will immediately close after this function.
     * Override to modify the modal, call another service, etc. (anything really).
     * @param $scope - $scope of the taskModalCtrl instance (ui-bootstrap modal). Can access the controller vm with $scope.vm.
     */
    onModalSubmitSuccess($scope) {
        return this.$q.resolve();
    }

    /**
     * Will be called if error occurs during submit(). Modal will remain open after this function by default.
     * Override to modify the modal, broadcast extra events, etc. (anything really).
     * @param err {Error}
     * @param $scope - $scope of the taskModalCtrl instance (ui-bootstrap modal). Can access the controller vm with $scope.vm.
     */
    onModalSubmitFail(err, $scope) {
        console.error(`Error submitting "${this.title}"`);
        throw err;
    }

    /**
     * @param $scope - $scope of the taskbarCtrl. Can access the controller vm with $scope.vm.
     * @returns {Promise}
     */
    onTaskbarItemClicked($scope) {
        const startingTask = this;
        return this.SignOnTasks.openAllTasks(startingTask);
    }
}
