urlConfig.$inject = ['$urlMatcherFactoryProvider'];

export default function urlConfig($urlMatcherFactoryProvider) {
    // This configures ui-router to allow case-insensitive URLs and to match trailing slashes.
    // These lines need to run BEFORE all the $stateProvider.state declarations.
    // So since app.core is the first angular module dependency declared in app.module.js,
    // this config was put in app.core so it could run before all the feature .config.js functions.

    // TODO: consider some kind of initialization module that comes before app.core

    $urlMatcherFactoryProvider.caseInsensitive(true);
    $urlMatcherFactoryProvider.strictMode(false);
}
