import milestonesTpl from './milestones.tpl.html';
import milestonesCtrl from './milestones.controller';

export default {
    templateUrl: milestonesTpl,
    controller: milestonesCtrl,
    controllerAs: 'vm',
    bindings: {
        type: '<',
        dates: '<'
    }
};
