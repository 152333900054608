import toolsTpl from './tools.tpl.html';

toolsConfig.$inject = ['$stateProvider'];

export default function toolsConfig($stateProvider) {
    if (process.env.NODE_ENV === 'production') {
        return;
    }
    $stateProvider.state('tools', {
        parent: 'auth',
        url: '/tools',
        views: {
            'main@': {
                controller: 'toolsCtrl as vm',
                templateUrl: toolsTpl
            }
        },
        data: {
            pageTitle: 'Tools'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('tools');
            }]
        }
    });
}
