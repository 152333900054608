import createGridPageConfig from '../../core/directive/rnDxGrid/createGridPageConfig';

export default createGridPageConfig({
    grid: {
        componentName: 'leaderboard-grid'
    },
    state: {
        name: 'leaderboard',
        config: {
            parent: 'auth',
            url: '/leaderboard/:promoID',
            data: {
                pageTitle: 'Leaderboard'
            },
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('leaderboard');
                }]
            }
        }
    },
    controller: ['$state', function submissionsPageCtrl($state) {
        const vm = this;
        vm.promoID = $state.params.promoID;
    }],
    template: {
        header: 'leaderboard_HEADER',
        containerClass: 'leaderboard-page-container',
        gridComponentBindings: `promo-id="vm.promoID"`
    }
});
