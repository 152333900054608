import angular from 'angular';

import './simple/simpleModal.module';
import './likes/likesModal.module';
import './prompt/prompt.module';

export default angular.module('app.blocks.modal', [
    'app.blocks.modal.simple',
    'app.blocks.modal.likes',
    'app.blocks.modal.prompt'
]);