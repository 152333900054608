import promoGameCtrl from './promoGame.controller';
import promoGameTpl from './promoGame.tpl.html';

promoGameConfig.$inject = ['$stateProvider'];

export default function promoGameConfig($stateProvider) {
    $stateProvider.state('incentiveGame', {
        parent: 'auth',
        url: '/incentive/:id/game',
        views: {
            'main@': {
                controller: promoGameCtrl,
                controllerAs: 'vm',
                templateUrl: promoGameTpl
            },
        },
        data: {
            pageTitle: 'Incentive Game',
            permission: 'IsGameAdmin',
            isIncentive: true
        },
        resolve: {
            promoDetails: ['PromoService', '$stateParams', function(PromoService, $stateParams) {
                return PromoService.getIncentiveDisplay($stateParams.id);
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }],
            snapshotTranslation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('snapshot');
            }],
        }
    });
}
