promoCtrl.$inject = [
    'promos', '$state', 'PromoService', 'QuizResultStatuses', 'GridUrlFilters', 'GridFilterOperators',
    'PromoRibbon', 'Session', '$translate',
];

export default function promoCtrl(
    promos, $state, PromoService, QuizResultStatuses, GridUrlFilters, GridFilterOperators,
    PromoRibbon, Session, $translate,
) {
    const vm = this;

    vm.isIncentive = $state.current.data.isIncentive;
    vm.isAward = $state.current.data.isAward;
    vm.promoList = promos.List || [];
    vm.promoGroups = Session.getCompany().incentiveGroups || [];

    let defaultPromoGroupName = `${$translate.instant('promo_ALL_TXT')} ${$translate.instant('promo_LIST_INCENTIVES_HEADER')}`;
    if (vm.isAward) {
        defaultPromoGroupName = `${$translate.instant('promo_ALL_TXT')} ${$translate.instant('promo_LIST_AWARD_HEADER')}`;
    }

    const allPromoGroup = {
        id: 'all',
        name: defaultPromoGroupName,
        promoCount: vm.promoList.length
    };

    vm.promoList.forEach(promo => {
        promo.showGauge = PromoService.isShowGauge(promo);
        promo.showPoints = PromoService.isShowPoints(promo);
        promo.showLeaderboard = PromoService.isShowLeaderBoard(promo);
        promo.promoGroupID = vm.isAward ? promo.awardGroupID : promo.incentiveGroupID;
        PromoRibbon.getRibbon(promo).then(ribbons => {
            promo.ribbons = ribbons;
        }).catch(err => {
            console.warn(err);
        });
    });

    vm.promoGroups.map(group => {
        group.promoCount = vm.promoList.filter(promo => promo.promoGroupID === group.id).length;
        return group;
    });

    const isAllPromoExist = vm.promoGroups.filter(group => group.id === allPromoGroup.id).length;
    if (vm.promoGroups.length && !isAllPromoExist) {
        vm.promoGroups.unshift(allPromoGroup);
    }

    if (promos.pendingClientCount) {
        vm.promosRequiringActionsCount = promos.pendingClientCount.count;
    }

    const submissionsFilterString = GridUrlFilters.stringifyFilters([
        { field: 'statusID', operator: GridFilterOperators.IN, value: [QuizResultStatuses.REQUIRES_ACTION] },
        { field: 'isSnooze', operator: GridFilterOperators.EQUALS, value: false }
    ]);

    vm.submissionsSref = `submissionsContact({ filters: '${submissionsFilterString}' })`;
    vm.getPromoDetailsSref = PromoService.getPromoDetailsSref;
}
