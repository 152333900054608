import _ from 'lodash';

import SignOnTask from './SignOnTask';

export default class TaxInfoTask extends SignOnTask {

    constructor(taskObject, $injector) {
        super(taskObject, $injector);

        _.merge(this, {
            title: 'app_SIGN_ON_TASKS_TAX_INFO_TITLE', // 'Please provide SIN/SSN info'
            taskModalOptions: {
                showFooterButtons: false,
                successMessageTimeout: 2000
            }
        });
    }

    onModalControllerLoaded($scope) {
        super.onModalControllerLoaded($scope);
        const vm = $scope.vm;

        vm.submitButtonOptions = {
            onSuccess() {
                this.hidden = true;
            }
        };
    }

    get componentHtml() {
        return `
            <tax-info-form on-success="vm.onSuccess"
                           on-fail="vm.onFail"
                           submit-button-options="vm.submitButtonOptions">
            </tax-info-form>`;
    }

}
