import _ from 'lodash';

contentManagementCtrl.$inject = ['Prompt', 'ContentManagementService'];

export default function contentManagementCtrl(Prompt, ContentManagementService) {
    this.$onInit = () => {
        const vm = this;

        vm.getRoleName = getRoleName;
        vm.deleteContent = deleteContent;
        vm.searchType = 'code';
        vm.search = {};

        vm.contentItems = vm.contentList.map(contentItem => {
            const topic = vm.contentTopicsList ? vm.contentTopicsList.find(topic => topic.id === contentItem.topicId): null;
            return Object.assign(contentItem, {
                // sort roleIds and remove duplicates
                roles: contentItem.roles.sort().filter((role, i) => role !== contentItem.roles[i + 1]),
                topic: topic ? topic.name : ''
            });
        });

        function getRoleName(roleId) {
            const role = vm.roles.find(role => role.ID === roleId);
            return role ? role.name : '';
        }

        function deleteContent(contentId) {
            Prompt.open({ type: 'warning' }).result.then(()=> {
                ContentManagementService.deleteContent(contentId).then(() => {
                    vm.contentItems
                        .splice(vm.contentItems.findIndex((contentItem) => contentItem.contentID === contentId), 1);
                });
            });
        }
    };
}
