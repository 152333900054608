toFromHeaderCtrl.$inject = [];

export default function toFromHeaderCtrl() {
    this.$onInit = () => {
        const vm = this;

        vm.recipients = vm.recipients || [];
        vm.showRecipientsList = false;

        vm.toggleShowRecipientsList = function () {
            vm.showRecipientsList = !vm.showRecipientsList;
        };
    };
}
