import contactIconTpl from './contactIcon.tpl.html';
import contactIconCtrl from './contactIcon.controller';

export default {
    templateUrl: contactIconTpl,
    controller: contactIconCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        loadingWidth: '<',
        loadingHeight: '<',
        isEditable: '<',
        isExpandable: '<'
    }
};
