import questionOverviewTpl from './questionOverview.tpl.html';
import questionOverviewCtrl from './questionOverview.controller';

export default {
    templateUrl: questionOverviewTpl,
    controller: questionOverviewCtrl,
    controllerAs: 'vm',
    bindings: {
        questions: '<',
    }
};
