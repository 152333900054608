chooseRecognitionRun.$inject = ['ModalService', 'ChooseRecognitionModal'];

export default function chooseRecognitionRun(ModalService, ChooseRecognitionModal) {

    // Opening the recognition chooser modal via the URL is used by:
    // - some custom content landing pages
    // - Appcues

    ModalService.openWhenUrlParamAdded({
        paramName: 'openRecognitionChooser',
        openFunction: () => ChooseRecognitionModal.open()
    });
}
