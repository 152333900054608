percentKeyframeCss.$inject = [];

export default function percentKeyframeCss() {
    function buildAnimationCss(answerIndex, questionIndex, percent) {
        return `<style type="text/css">
                    @keyframes percent_question_${questionIndex}_answer_${answerIndex} {
                        0% { background-position-x: ${percent}% }
                        100% { background-position-x: 0% }
                    }
                </style>`
    }
    return {
        restrict: 'E',
        template: function(elem, attr) {
            return buildAnimationCss(attr.answerIndex, attr.questionIndex, attr.percent);
        }
    }
}
