

const constantUtils = require('./constantUtils');

const QuizNoteTypes = {
    SUBMISSION: 1,
    ADMIN: 2,
    CUSTOMER: 3,
    SNOOZE: 4,
    MODIFY_BY_ADMIN: 5
};

const properties = {
    [QuizNoteTypes.SNOOZE]: {
        cssClass: 'note-type-snooze'
    },
    [QuizNoteTypes.MODIFY_BY_ADMIN]: {
        cssClass: 'note-type-modify-by-admin'
    }
};

constantUtils.configure({ constants: QuizNoteTypes, properties });

module.exports = QuizNoteTypes;
