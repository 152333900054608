import promoResultsCtrl from './promoResults.controller';
import promoResultsTpl from './promoResults.tpl.html';

promoResultConfig.$inject = ['$stateProvider'];

export default function promoResultConfig($stateProvider) {
    $stateProvider.state('incentiveResults', {
        parent: 'auth',
        url: '/incentive/:dashboardID/results',
        views: {
            'main@': {
                controller: promoResultsCtrl,
                controllerAs: 'vm',
                templateUrl: promoResultsTpl
            },
            'dashboard@incentiveResults': 'dashboardComponent',
        },
        data: {
            pageTitle: 'Incentive Results',
            permission: 'IncentiveReports',
            isIncentive: true
        },
        resolve: {
            promoDetails: ['PromoService', '$stateParams', function(PromoService, $stateParams) {
                return PromoService.getIncentiveDisplay($stateParams.dashboardID);
            }],
            dashboard: ['DashboardService', '$stateParams', function (DashboardService, $stateParams) {
                return DashboardService.getIncentiveDashboard({
                    incentiveID: $stateParams.dashboardID
                });
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }],
            snapshotTranslation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('snapshot');
            }],
            dashboardTranslation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('dashboard');
            }],
        }
    });

    $stateProvider.state('incentiveResultsReport', {
        parent: 'incentiveResults',
        url: '/:reportCode?filter&column&sort',
        params: {
            filter: {
                type: 'string',
                value: null,
                dynamic: true,
            },
            column: {
                type: 'string',
                value: null,
                dynamic: true,
            },
            sort: {
                type: 'string',
                value: null,
                dynamic: true,
            }
        },
        views: {
            'report': 'reportComponent',
        },
        data: {
            dashboardCode: 'incentive',
            pageTitle: 'Incentive Results',
            permission: 'IncentiveReports',
            isIncentive: true
        },
        resolve: {
            gameID: ['PromoService', function(PromoService) {
                return PromoService.getGameID();
            }],
            defaultReportName: ['PromoService', '$stateParams', 'DashboardService', function(PromoService, $stateParams) {
                return [PromoService.getPromoName(), $stateParams.dashboardID, $stateParams.reportCode].join('_');
            }],
            report: ['DashboardService', '$stateParams', function (DashboardService, $stateParams) {
                return DashboardService.getReport($stateParams);
            }],
            nonReport: ['PromoService', '$stateParams', function(PromoService, $stateParams) {
                if ($stateParams.reportCode === 'game-survey-question-overview') {
                    const gameID = PromoService.getGameID();
                    return PromoService.getAllAnswerDetails({ gameID });
                }
                if ($stateParams.reportCode === 'claims-overview') {
                    return PromoService.getAllAnswerDetails({});
                }
            }],
            snapshotTranslation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('snapshot');
            }]
        }
    });

    $stateProvider.state('incentiveResultsModal', {
        parent: 'incentiveResultsReport',
        url: '/modal/:modalType/:modalID',
        views: {
            'modal': 'reportModalComponent',
        },
        data: {
            pageTitle: 'Incentive Results',
            permission: 'IncentiveReports',
            isIncentive: true
        },
        params: {
            fallbackState: 'incentiveResultsReport'
        },
        resolve: {
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }],
            snapshotTranslation: ['RnTranslationService', (RnTranslationService) => {
                return RnTranslationService.loadPhrases('snapshot');
            }],
            model: ["$stateParams","ModalTypes", "PromoService", async function($stateParams, ModalTypes, PromoService) {
                if (!$stateParams.modalType) {
                    return null;
                }
                let model;
        
                // TODO: Move getRowData into resolve function.
                switch ($stateParams.modalType) {
                    case ModalTypes.QUESTION_OVERVIEW.Type:
                        model = await PromoService.getAllAnswerDetails();
                        model.selectedQuestionID = ModalTypes.QUESTION_OVERVIEW.rowKeyName === 'QuestionID' ? 
                                                    Number($stateParams.modalID) : undefined;
                        return model;
                        break;
                    case ModalTypes.GAMEPLAY_RESULTS.Type:
                        return await PromoService.getAnswerDetails({ gameplayGUID: $stateParams.modalID });
                        break;
                    case ModalTypes.TRAINING_QUIZ_RESULTS.Type:
                    case ModalTypes.CLAIM_QUIZ_RESULTS.Type:
                        return await PromoService.getAnswerDetails({ quizResultID: $stateParams.modalID });
                }
            }]
        },
    });
}
