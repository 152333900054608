const constantUtils = require('./constantUtils');

const PromoTypes = {
    DRAFT: 1,
    PENDING: 2,
    ACTIVE: 3,
    COMPLETE: 4,
    PAUSED: 5,
    ARCHIVED: 6,
    DELETED: 7
};

const properties = {};

constantUtils.configure({ constants: PromoTypes, properties });

module.exports = PromoTypes;
