import angular from 'angular';

import legacyWidgetCtrl from './legacyWidget.controller';
import legacyWidget from './legacyWidget.component';

export default angular.module('app.core.widgets.legacyWidget', [

])
.controller('legacyWidgetCtrl', legacyWidgetCtrl)
.component('legacyWidget', legacyWidget);
