import alertMessageTpl from './alertMessage.tpl.html';
import alertMessageCtrl from './alertMessage.controller';

alertMessage.$inject = ['CoreDirectivesService'];

export default function alertMessage(CoreDirectivesService) {
    return {
        templateUrl: alertMessageTpl,
        controller: alertMessageCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            type: '@',
            hideIcon: '<'
        },
        transclude: true,
        link: function(scope, element, attrs, vm, transcludeFn) {
            CoreDirectivesService.removeExtraTranscludeTags(element, transcludeFn);
        }
    };
}
