import angular from 'angular';
import '../../core/core.module';
import reportModule from './report/report.module';

import dashboardConfig from './dashboard.config';
import dashboardComponent from './dashboard.component';
import DashboardService from './dashboardService.factory';

export default angular.module('app.dashboard', [
    'app.core',
    reportModule.name,
])
.config(dashboardConfig)
.factory('DashboardService', DashboardService)
.component('dashboardComponent', dashboardComponent);
