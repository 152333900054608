LandingService.$inject = ['$http', 'Session', 'SpotlightService'];

export default function LandingService($http, Session, SpotlightService) {

    async function getLandingSetup(programType, focus) {
        const user = Session.getUser();
        const { data } = await $http.get(`/api/landing/${programType}/${focus}/setup`);

        let landingSetup = {};

        if (programType === 'recognition') {
            const recentApplauses = await SpotlightService.getSpotlightItems({
                participant: 'allParticipants',
                recognition: 'applause|cultureStory',
                sent: undefined,
                recentDays: undefined,
                startDate: undefined,
                contactID: user.userID,
                spotlightItemsToShow: 10
            });
            landingSetup = {
                dashboard: {
                    currentEarningsToDate: data.pointsEarned,
                    currentBalance: user.points,
                    lastMonthAppauses: {
                        received: data.applausesReceived,
                        sent: data.applausesSent
                    },
                    lastMonthNominations: {
                        received: data.nominationsReceived,
                        sent: data.nominationsMade
                    },
                    lastMonthPointsAwarded: data.pointsAwarded,
                    teamApplauses: {
                        received: data.teamApplausesReceived,
                        sent: data.teamApplausesSent
                    },
                    teamNominations: {
                        received: data.teamNominationsReceived,
                        sent: data.teamNominationsMade
                    },
                },
                recentApplauses
            };
        }

        return landingSetup;
    }

    return {
        getLandingSetup
    };
}
