import _ from 'lodash';

/**
 * @param {MultiGridPageConfigOptions} options
 * @returns {Array}
 */
export default ({
    state: {
        name: stateName,
        config: customStateConfig,
    },
    controller,
    template,
}) => (['$stateProvider', function multiGridPageConfig($stateProvider) {

    // For use with ui-sref
    const gridUrlParamsString = ['filters', 'columns', 'sort'].map(param => param).join('&');

    // For use with $state.go
    const paramsObject = ['FiltersArray', 'ColumnsObject', 'SortArray'].reduce((obj, param) => {
        obj[param] = null;
        return obj;
    }, {});
    paramsObject.columns = {
        dynamic: true
    };

    const finalStateConfig = _.merge({}, customStateConfig, {
        url: customStateConfig.url
            + (customStateConfig.url.includes('?') ? '&' : '?')
            + gridUrlParamsString,
        params: paramsObject,
        views: {
            'main@': {
                template,
                controller,
                controllerAs: 'vm'
            }
        },
        data: {
            navLinkOptions: {
                inherit: false
            }
        },
    });

    $stateProvider.state(stateName, finalStateConfig);
}]);

/**
 * @typedef {MultiGridPageOptions} MultiGridPageConfigOptions
 * @property {string} template - The final template of the state
 * @property {Array | Function} controller - The final controller of the state
 */
