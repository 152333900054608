import gamesTpl from './games.tpl.html';
import gamesCtrl from './games.controller';

export default {
    templateUrl: gamesTpl,
    controller: gamesCtrl,
    controllerAs: 'vm',
    bindings: {
        playerToken: '<'
    },
};
