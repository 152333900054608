import sisterPromoDropdownTpl from './sisterPromoDropdown.tpl.html';
import sisterPromoDropdownCtrl from './sisterPromoDropdown.controller';

export default {
    templateUrl: sisterPromoDropdownTpl,
    controller: sisterPromoDropdownCtrl,
    controllerAs: 'vm',
    bindings: {
        promo: '<',
        screen: '<',
    }
};
