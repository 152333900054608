import _ from 'lodash';

progressBarsWidgetCtrl.$inject = ['WidgetService', 'StringInterpolationService'];

export default function progressBarsWidgetCtrl(WidgetService, StringInterpolationService) {
    this.$onInit = () => {
        const vm = this;

        const dataPoints = _.get(vm.widget.data, 'dataSets[0].dataPoints');

        vm.hasData = !_.isEmpty(dataPoints);
        if (!vm.hasData) {
            return;
        }

        vm.progressBars = dataPoints.map(dataPoint => ({
            ...dataPoint,
            href: dataPoint.href && StringInterpolationService.interpolate(dataPoint.href, {
                contactID: vm.contact.userID,
                branchID: vm.contact.userID
            })
        }));

        vm.total = _.sumBy(dataPoints, dataPoint => dataPoint.value);

        vm.summary = _.get(vm.widget.data, 'stats[0]');
        vm.summaryLink = WidgetService.getSummaryLinkHref(vm.widget, vm.contact);
    };
}
