applausesSentWidgetCtrl.$inject = ['Session'];

export default function applausesSentWidgetCtrl(Session) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.user = Session.getUser();

        vm.isSelf = vm.contact.userID === vm.user.userID;
    };
}
