import angular from 'angular';

import '../../core/core.module';
import './socialPostForm/socialPostForm.module';

import socialPostFormPageConfig from './socialPostFormPage/socialPostFormPage.config';
import socialPostFormPageCtrl from './socialPostFormPage/socialPostFormPage.controller';
import SocialPostService from './socialPostService.factory';

export default angular.module('app.socialPost', [
    'app.core',
    'app.socialPost.socialPostForm'
])
.config(socialPostFormPageConfig)
.controller('socialPostFormPageCtrl', socialPostFormPageCtrl)
.factory('SocialPostService', SocialPostService);
