import promoTpl from './promo.tpl.html';

promoConfig.$inject = ['$stateProvider'];

export default function promoConfig($stateProvider) {
    $stateProvider.state('award', {
        parent: 'auth',
        url: '/award',
        views: {
            'main@': {
                controller: 'promoCtrl as vm',
                templateUrl: promoTpl
            }
        },
        data: {
            pageTitle: 'Awards',
            permission: 'NominateAward',
            isAward: true
        },
        resolve: {
            promos: ['PromoService', function(PromoService) {
                return PromoService.getAwardsList();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }]
        }
    });

    $stateProvider.state('incentive', {
        parent: 'auth',
        url: '/incentive',
        views: {
            'main@': {
                controller: 'promoCtrl as vm',
                templateUrl: promoTpl
            }
        },
        data: {
            pageTitle: 'Incentives',
            permission: 'ParticipateIncentive',
            isIncentive: true
        },
        resolve: {
            promos: ['PromoService', function(PromoService) {
                return PromoService.getIncentivesList();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('promo');
            }]
        }
    });
}
