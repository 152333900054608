import _ from 'lodash';
import flatten from 'flat';

StringInterpolationService.$inject = [];

export default function StringInterpolationService() {

    const PLACEHOLDER_WRAPPER_LEFT = '{{';
    const PLACEHOLDER_WRAPPER_RIGHT = '}}';

    return {
        interpolate
    };

    /**
     * Sometimes strings will come from the DB and Angular will need to be responsible for replacing any placeholders
     * with variable values (interpolation). Use this function for doing that.
     * @example
     * interpolate('Hello {{ name }}, how is {{ person.nickname }}?', { name: 'Bob', person: { nickname: 'Tom' } })
     * // 'Hello Bob, how is Tom?'
     * @param {string} string
     * @param {Object} [variables]
     */
    function interpolate(string, variables = {}) {
        const flatVariables = flatten(variables);
        return _.reduce(flatVariables, (result, value, key) => {
            const regExpString = [PLACEHOLDER_WRAPPER_LEFT, key, PLACEHOLDER_WRAPPER_RIGHT]
                .map(_.escapeRegExp)
                .join('\\s*');
            const placeholderRegexp = new RegExp(regExpString, 'ig');
            return result.replace(placeholderRegexp, value);
        }, string);
    }
}
