import _ from 'lodash';

customReportFormCtrl.$inject = ['$rootScope', 'ReportsService'];

export default function customReportFormCtrl($rootScope, ReportsService) {
    this.$onInit = () => {
        const vm = this;

        const hasTimePeriods = !_.isEmpty(vm.timePeriods);

        vm.model = {};

        vm.fields = [{
            key: 'report', // Not submitted, just for display
            type: 'customInput',
            defaultValue: vm.report.name,
            templateOptions: {
                static: true,
                labelTranslateKey: 'reports_REPORT_FORM_REPORT', // 'Report'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8'
            }
        }, hasTimePeriods && {
            key: 'timePeriodID',
            type: 'customSelect',
            defaultValue: vm.timePeriods[0].ID,
            templateOptions: {
                required: true,
                labelTranslateKey: 'reports_REPORT_FORM_TIME_PERIOD', // 'Time Period'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8',
                options: vm.timePeriods,
                valueProp: 'ID',
                onChange: function updateDefaultName(newTimePeriodID) {
                    const nameField = vm.fields.find(field => field.key === 'reportName');
                    if (nameField.formControl.$dirty) {
                        return;
                    }
                    const timePeriod = vm.timePeriods.find(timePeriod => timePeriod.ID === newTimePeriodID);
                    vm.model.reportName = getDefaultReportName({ timePeriod });
                }
            }
        }, {
            key: 'reportName',
            type: 'customInput',
            defaultValue: getDefaultReportName({ timePeriod: hasTimePeriods && vm.timePeriods[0] }),
            templateOptions: {
                required: true,
                labelTranslateKey: 'reports_REPORT_FORM_NAME', // 'Name'
                labelClass: 'col-sm-4',
                controlClass: 'col-sm-8'
            }
        }, {
            key: 'isEmail',
            type: 'customCheckbox',
            templateOptions: {
                labelTranslateKey: 'reports_REPORT_FORM_EMAIL_ONCE_GENERATED' // 'Email me once generated'
            }
        }];

        vm.submitButtonOptions = {
            settings: {
                initial: {
                    translateKey: 'reports_REPORT_FORM_SUBMIT' // 'Generate Report'
                },
                success: {
                    hidden: true
                }
            }
        };

        vm.submit = () => ReportsService.createCustomReportRequest({
            ...vm.model,
            gridReportID: vm.report.ID
        });

        function getDefaultReportName({ timePeriod }) {
            if (timePeriod) {
                return vm.report.name.replace(/\s/g, '-') + '-' + timePeriod.name.replace(/\s/g, '-');
            } else {
                return vm.report.name.replace(/\s/g, '-');
            }
        }
    };
}
