reportModalCtrl.$inject = [
    '$state', '$stateParams', 'Communication', 'ReportService', 'ModalTypes',
    'PromoService', 'PromoQuizService', '$uibModalStack'
];

export default function reportModalCtrl(
    $state, $stateParams, Communication, ReportService, ModalTypes,
    PromoService, PromoQuizService, $uibModalStack
) {
    const vm = this;

    vm.$onInit = async () => {
        const fallbackState = $stateParams.fallbackState || 'report';

        if (!vm.model) {
            console.error("no model, using fallback");
            return $state.go(fallbackState);
        }

        $uibModalStack.dismissAll();
        Communication.openByModalType(vm.model, $stateParams.modalType)
            .result.then(() => $state.go(fallbackState), (res) => {
                if (!res) {
                    return;
                }
                return $state.go(fallbackState);
            });
    };
}
