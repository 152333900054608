import SignOnTask from './taskClasses/SignOnTask';

import AcceptTermsTask from './taskClasses/AcceptTermsTask';
import MessageTask from './taskClasses/MessageTask';
import UpdatePasswordTask from './taskClasses/UpdatePasswordTask';
import NewsletterSignUpTask from './taskClasses/NewsletterSignUpTask';
import LinkTask from './taskClasses/LinkTask';
import AdminTask from './taskClasses/AdminTask';
import TaxInfoTask from './taskClasses/TaxInfoTask';
import ConfirmTaxInfoTask from './taskClasses/ConfirmTaxInfoTask';
import UpdateAddressTask from './taskClasses/UpdateAddressTask';
import ShippingAddressValidateTask from './taskClasses/ShippingAddressValidateTask';
import PointExpirationTask from './taskClasses/PointExpirationTask';

const taskClasses = {
    AcceptTerms: AcceptTermsTask,
    Message: MessageTask,
    UpdatePassword: UpdatePasswordTask,
    NewsletterSignUp: NewsletterSignUpTask,
    GoToLink: LinkTask,
    Admin: AdminTask,
    TaxInfo: TaxInfoTask,
    ConfirmTaxInfo: ConfirmTaxInfoTask,
    UpdateAddress: UpdateAddressTask,
    ShippingAddressValidate: ShippingAddressValidateTask,
    PointExpiration: PointExpirationTask
};

SignOnTaskCreator.$inject = ['$injector'];

export default function SignOnTaskCreator($injector) {

    // Factory method design pattern
    // (didn't name this service SignOnTaskFactory because 'factory' has a different meaning in the context of Angular)

    return {
        createSignOnTask
    };

    /**
     * @param taskObject {Object} - From API
     * @returns {SignOnTask} Corresponding SignOnTask subclass instance
     */
    function createSignOnTask(taskObject) {
        const TaskClass = taskClasses[taskObject.type];
        if (!TaskClass) {
            return new SignOnTask(taskObject, $injector);
        }
        return new TaskClass(taskObject, $injector);
    }
}
