// TODO: import suggstTypes and handle suggest reports

// NOTE: legacy frame pages with multiple params/querystrings are using href instead of url
// this is because there's an issue with ui-router and an unlimited number of dynamic path and
// query params

export default {
    applause: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_APPLAUSE', // 'Applause'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_APPLAUSE_REPORT_CATEGORY_RESULTS', // 'Category Results'
            url: '/#/legacy-frame/Report_ApplausesOverview.asp',
            // 'Shows applauses by category.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_APPLAUSE_REPORT_DESCRIPTION_CATEGORY_RESULTS',
            isShow: ({ user }) => user.hasPermission('ApplauseReports'),
            id: 'report-applause-overview'
        }, {
            translateKey: 'reports_LIST_CATEGORY_APPLAUSE_REPORT_ALL_APPLAUSES', // 'All Applauses'
            url: '/#/legacy-frame/Report_Applauses.asp',
            // 'Shows all applauses.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_APPLAUSE_REPORT_DESCRIPTION_ALL_APPLAUSES',
            isShow: ({ user }) => user.hasPermission('ApplauseReports'),
            id: 'report-applause-overview'
        }],
    },
    awards: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_AWARDS', // 'Awards'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_AWARDS_REPORT_ALL_AWARDS', // 'All Awards'
            href: '/#/legacy-frame/report_promos.asp?PromoTypeID=1',
            // 'Shows a list of all awards.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_AWARDS_REPORT_DESCRIPTION_ALL_AWARDS',
            isShow: ({ user }) => user.hasPermission('ManageNominations'),
            id: 'report-all-awards'
        }]
    },
    points: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_POINTS', // 'Points'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_BUDGET_HIERARCHY', // 'Budget Hierarchy'
            url: '/#/legacy-frame/Budgets.asp',
            // 'Expandable tree of all budgets showing open and close balances for current month.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_DESCRIPTION_BUDGET_HIERARCHY',
            isShow: ({ user }) => user.hasPermission('ModifyBudgets'),
            id: 'report-budgets'
        }, {
            translateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_PARTICIPANT_BALANCES', // 'Participant Balances'
            url: '/#/legacy-frame/Report_ContactAccounting.asp',
            // 'List of open and close balances for all participants'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_DESCRIPTION_PARTICIPANT_BALANCES',
            id: 'report-contact-accounting'
        },{
            translateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_POINTS_OVERVIEW', // 'Points Overview'
            url: '/#/legacy-frame/Admin/ControlPanel/DashboardPointOverview.asp',
            // 'List of points purchases and points spending'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_POINTS_REPORT_DESCRIPTION_POINTS_OVERVIEW',
            isShow: ({ user }) => user.hasPermission('ControlPanelDashboard'),
            id: 'report-point-overview'
        }]
    },
    certificates: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_CERTIFICATES', // 'Certificates'
        reports: [{
            // 'Stand-alone Certificates'
            translateKey: 'reports_LIST_CATEGORY_CERTIFICATES_REPORT_STAND_ALONE_CERTIFICATES',
            href: '/#/legacy-frame/Report_Certificates.asp?IsDirect=1',
            // 'A list of certificates given directly (not attached to an award).'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_CERTIFICATES_REPORT_DESCRIPTION_STAND_ALONE_CERTIFICATES',
            isShow: ({ user }) => user.hasPermission('CertificateReports'),
            id: 'report-certificates'
        }, {
            translateKey: 'reports_LIST_CATEGORY_CERTIFICATES_REPORT_PENDING_CERTIFICATES', // 'Pending Certificates'
            url: '/#/legacy-frame/Report_PendingCertificates.asp',
            // 'A list of certificates which are scheduled to send in the future.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_CERTIFICATES_REPORT_DESCRIPTION_PENDING_CERTIFICATES',
            isShow: ({ user }) => user.hasAllPermissions(['CertificateReports', 'PendingCertificatesReport']),
            id: 'report-pending-certificates'
        }]
    },
    communications: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_COMMUNICATIONS', // 'Communications'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_GENERAL', // 'General'
            href: '/#/legacy-frame/Communications_List.asp?SelectedTab=5',
            // 'A list of all communications, drafts, scheduled and sent.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_DESCRIPTION_GENERAL',
            isShow: ({ user }) => user.hasPermission('Communications'),
            id: 'report-communications-general'
        }, {
            translateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_WELCOME', // 'Welcome'
            href: '/#/legacy-frame/Communications_List.asp?SelectedTab=6',
            // 'A list of welcome messages (pop-up only) including triggered and batches.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_DESCRIPTION_WELCOME',
            isShow: ({ user }) => user.hasPermission('Communications'),
            id: 'report-communications-welcome'
        }, {
            translateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_INVITATIONS', // 'Invitations'
            href: '/#/legacy-frame/Communications_List.asp?SelectedTab=30',
            // 'A list of invite messages (email only) including triggered and batches.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_COMMUNICATIONS_REPORT_DESCRIPTION_INVITATIONS',
            isShow: ({ user }) => user.hasPermission('Communications'),
            id: 'report-communications-invitations'
        }]
    },
    groups: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_GROUPS', // 'Groups'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_MY_GROUPS', // 'My Groups'
            href: '/#/legacy-frame/Groups.asp?tab=my',
            // 'All groups you created.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_DESCRIPTIONS_MY_GROUPS',
            isShow: ({ user }) => user.hasPermission('ViewGroups'),
            id: 'report-groups'
        }, {
            translateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_SHARED_GROUPS', // 'Shared Groups'
            href: '/#/legacy-frame/Groups.asp?tab=shared',
            // 'All shared groups, including your own shared groups.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_DESCRIPTION_SHARED_GROUPS',
            isShow: ({ user }) => user.hasPermission('ViewGroups'),
            id: 'report-groups-shared'
        }, {
            translateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_MY_SHARED_GROUPS', // 'My Team Groups'
            href: '/#/legacy-frame/Groups.asp?tab=team',
            // 'All groups shared or otherwise created by someone in your access limit.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_GROUPS_REPORT_DESCRIPTION_MY_SHARED_GROUPS',
            isShow: ({ user }) => user.hasPermission('ViewGroups'),
            id: 'report-groups-team'
        }]
    },
    incentives: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_INCENTIVES', // 'Incentives'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_INCENTIVES_REPORT_ALL_INCENTIVES', // 'All Incentives'
            href: '/#/legacy-frame/Report_Promos.asp?PromoType=Incentives',
            // 'A list of all incentives.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_INCENTIVES_REPORT_DESCRIPTION_ALL_INCENTIVES',
            isShow: ({ user }) => user.hasPermission('IncentiveReports'),
            id: 'report-incentives'
        }, {
            translateKey: 'reports_LIST_CATEGORY_INCENTIVES_REPORT_ALL_CLAIM_FORMS', // 'All Claim Forms'
            url: '/#/submissions/hierarchy',
            // 'A high level list of the numbers of submissions and their statuses for all incentives'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_INCENTIVES_REPORT_DESCRIPTION_ALL_CLAIM_FORMS',
            id: 'report-submissions'
        }]
    },
    unusualActivity: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_UNUSUAL_ACTIVITY', // 'Unusual Activity'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_UNUSUAL_ACTIVITY_REPORT', // 'Unusual Activity'
            href: '/#/legacy-frame/unusualactivity.asp',
            // 'A list of all incentives.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_UNUSUAL_ACTIVITY_DESCRIPTION',
            isShow: ({ user }) => user.hasPermission('UnusualActivity'),
            id: 'report-unusual-activity'
        }]
    },    
    participants: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_PARTICIPANTS', // 'Participants'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_PARTICIPANTS', // 'Participants'
            href: '/#/legacy-frame/Report_Users.asp?IsEntity=0',
            // 'A list of all users with profile data, later will be mini-profile for faster loading'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_PARTICIPANTS',
            id: 'report-participants'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_ENTITIES', // 'Entities'
            href: '/#/legacy-frame/Report_Users.asp?IsEntity=1',
            // 'A list of entities with profile data.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_ENTITIES',
            isShow: ({ company }) => company.hasOption('AllowEntities'),
            id: 'report-entities'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_POINT_ACTIVITY', // 'Point Activity'
            url: '/#/legacy-frame/Report_ContactAccounting.asp',
            // 'A list of users and point activity.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_POINT_ACTIVITY',
            isShow: ({ user }) => user.hasAnyPermission(['ViewAccountsUsers', 'ViewAwardEntities']),
            id: 'report-point-activity'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_LOGIN_ACTIVITY', // 'Login Activity'
            url: '/#/legacy-frame/Report_Logins.asp',
            // 'A list of users and login activity.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_LOGIN_ACTIVITY',
            isShow: ({ user }) => user.hasPermission('UserReports'),
            id: 'report-login-activity'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_FULL_EXPORT', // 'Full Export'
            url: '/#/legacy-frame/Report_Users.asp',
            // 'A list of users with complete profile.'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_FULL_EXPORT',
            isShow: ({ user }) => user.hasPermission('UserReports'),
            id: 'report-all-users'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_ACTIVITY_DASHBOARD', // 'Activity Dashboard'
            url: '/#/legacy-frame/Admin/ControlPanel/DashboardActivityOverview.asp',
            // 'Participation statistics'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_ACTIVITY_DASHBOARD',
            isShow: ({ user }) => user.hasPermission('ControlPanelDashboard'),
            id: 'report-dashboarc-activity'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DUPLICATE_PARTICIPANTS', // 'Duplicate Participants'
            url: '/#/legacy-frame/Report_DuplicateParticipants.asp',
            // 'List of duplicate participants'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_DUPLICATE_PARTICIPANTS',
            isShow: ({ user }) => user.hasPermission('DuplicateParticipants'),
            id: 'report-duplicate-participants'
        }, {
            translateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_INVALID_EMAILS', // 'Invalid Emails'
            url: '/#/dashboard/User-Reports/no-id/all-users?filter=UserStatusID--in--1..2..3__EmailStatusID--in--3&column=show--EmailStatusID',
            // 'List of duplicate participants'
            descriptionTranslateKey: 'reports_LIST_CATEGORY_PARTICIPANTS_REPORT_DESCRIPTION_INVALID_EMAILS',
            isShow: ({ user }) => user.hasPermission('UserReports'),
            id: 'report-bounced-emails'
        }]
    },
    redemptions: {
        headerTranslateKey: 'reports_LIST_CATEGORY_HEADER_REDEMPTIONS', // 'Redemptions'
        reports: [{
            translateKey: 'reports_LIST_CATEGORY_REDEMPTIONS_ALL_REDEMPTIONS', // 'All Redemptions'
            url: '/#/legacy-frame/allRedemptions.asp',
            isShow: ({ user }) => user.hasPermission('ViewRedemptions'),
            id: 'report-all-redemptions'
        }, {
            translateKey: 'reports_LIST_CATEGORY_REDEMPTIONS_RECENT_REDEMPTIONS', // 'Recent Redemptions'
            url: '/#/legacy-frame/allRedemptions.asp?IsTrailingYear=1',
            isShow: ({ user }) => user.hasPermission('ViewRedemptions'),
            id: 'report-recent-redemptions'
        }, {
            translateKey: 'reports_LIST_CATEGORY_REDEMPTIONS_TAXABLE_BENEFITS', // 'Taxable Benefits'
            href: '/#/legacy-frame/SIN/Report_TaxableBenefits.asp',
            isShow: ({ user }) => user.hasPermission('SINManagement'),
            id: 'report-taxable-benefits'
        }]
    }
};
