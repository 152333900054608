LangUtils.$inject = [
    '$rootScope', '$translate', '$q', '$window', '$http', 'Session', 'UrlUtils', 'tmhDynamicLocale', 'LANGUAGE_EVENTS',
    'TabRefreshService'
];

export default function LangUtils(
    $rootScope, $translate, $q, $window, $http, Session, UrlUtils, tmhDynamicLocale, LANGUAGE_EVENTS,
    TabRefreshService
) {

    function getLang() {
        return $translate.use() || $translate.proposedLanguage();
    }

    function getLanguageID() {
        const languageCode = getLang();
        const company = Session.getCompany();
        return company.getLanguageIdFromCode(languageCode);
    }

    /**
     * @param languageCode {String} Ex. en, fr, es, de
     * @returns Promise
     */
    async function changeLanguage(languageCode) {
        languageCode = languageCode.toLowerCase();
        if (languageCode === getLang()) {
            return $q.resolve();
        }
        $rootScope.$broadcast(LANGUAGE_EVENTS.LANGUAGE_CHANGE_START, languageCode);
        await $q.all([
            $http.put('/api/user/language', { languageCode }),
            legacyChangeLanguage(languageCode)
        ]);
        return TabRefreshService.refresh();
    }

    function legacyChangeLanguage(languageCode) {
        const company = Session.getCompany();
        const languageOption = company.languageOptions[languageCode.toUpperCase()];
        const languageID = languageOption.ID;
        return UrlUtils.loadLegacyPage(`/SwitchLanguage.asp?Lang=${languageID}`);
    }

    return {
        getLang,
        getLanguageID,
        changeLanguage,
        legacyChangeLanguage
    };
}
