import notificationManagementTpl from './notificationManagement.tpl.html';

notificationManagementConfig.$inject = ['$stateProvider'];

export default function notificationManagementConfig($stateProvider) {
    $stateProvider.state('notificationManagement', {
        parent: 'auth',
        url: '/notification-management?highlightMessageTypeID',
        views: {
            'main@': {
                controller: 'notificationManagementCtrl as vm',
                templateUrl: notificationManagementTpl
            }
        },
        data: {
            pageTitle: 'Notification Management',
            options: ['NotificationManagement']
        }
    });
}
