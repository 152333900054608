import gridPageContainerTpl from './gridPageContainer.tpl.html';
import gridPageContainerCtrl from './gridPageContainer.controller';

gridPageContainer.$inject = ['CoreDirectivesService'];

export default function gridPageContainer(CoreDirectivesService) {
    return {
        templateUrl: gridPageContainerTpl,
        controller: gridPageContainerCtrl,
        controllerAs: 'vm',
        transclude: {
            headerTransclude: '?headerTransclude'
        },
        scope: {},
        bindToController: {
            header: '@',
            translateHeader: '<',
        },
        link: function(scope, element, attrs, vm, transcludeFn) {
            CoreDirectivesService
                .removeExtraTranscludeTags(element, transcludeFn, 'headerTransclude', 'header-transclude');
        }
    };
}
