

/**
 * @example
 *
 * getLegacyUrl({ host: 'incentives.rewardsnation.com', protocol: 'http' });
 * // 'http://incentives-legacy.rewardsnation.com'
 *
 * getLegacyUrl({ host: 'alpha.adam.vibe.smg', protocol: 'https' });
 * // 'https://alpha.adam-legacy.vibe.smg'
 *
 * getLegacyUrl({ host: 'rewardsnation.com', protocol: 'https' });
 * // 'https://rewardsnation-legacy.com'
 *
 * getLegacyUrl({ host: 'localhost', protocol: 'https' });
 * // 'https://localhost-legacy'
 *
 * @param {Object} params
 * @param {string} params.host - Ex 'incentives.rewardsnation.com', 'alpha.adam.vibe.smg'
 * @param {string} params.protocol - Ex. 'https'
 * @returns {string}
 */
function getLegacyUrl({ host, protocol }) {
    const hostSplit = host.split('.');
    const subDomainToChange = hostSplit[hostSplit.length - 3] || hostSplit[0];
    const subDomainToChangeIndex = hostSplit.indexOf(subDomainToChange);
    const hostWithLegacy = [
        ...hostSplit.slice(0, subDomainToChangeIndex),
        `${subDomainToChange}-legacy`,
        ...hostSplit.slice(subDomainToChangeIndex + 1)
    ];
    return `${protocol}://${hostWithLegacy.join('.')}`;
}

module.exports = getLegacyUrl;
