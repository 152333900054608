import moment from 'moment';

changePasswordPageCtrl.$inject = ['$rootScope', 'Session', 'passwordPolicy', '$state', 'SimpleModal', 'StateUtils'];

export default function changePasswordPageCtrl($rootScope, Session, passwordPolicy, $state, SimpleModal, StateUtils) {
    const vm = this;
    const user = Session.getUser();
    // updatedPasswordOnInHours 'null' value means never changed password before.
    const updatedPasswordOnInHours = user.passwordUpdatedOn
        ? moment().diff(moment(new Date(user.passwordUpdatedOn)), 'hours') : null;

    vm.passwordPolicy = passwordPolicy;
    vm.hasToken = Number($state.params.hasToken) === 1 || $state.params.hasToken === 'true';

    if (updatedPasswordOnInHours !== null && updatedPasswordOnInHours <= vm.passwordPolicy.minPasswordAgeHours) {
        const waitForPasswordChange = moment(new Date(user.passwordUpdatedOn))
                            .add(vm.passwordPolicy.minPasswordAgeHours, 'h').calendar();
        vm.waitTime = `<b>${waitForPasswordChange}</b>`;
    } else {
        vm.canChangePassword = true;
    }

    vm.onSuccess = function() {
        const defaultState = StateUtils.getDefaultState();
        const fallbackState = StateUtils.getFallbackState();
        SimpleModal.open({
            type: 'success',
            title: 'changePassword_SUCCESS_TITLE', // 'Success!'
            body: 'changePassword_SUCCESS_MESSAGE', // 'Your password has been changed.'
        }).result.then(() => {
            const nextState = defaultState || fallbackState;
            $rootScope.user.passwordUpdatedOn = new Date();
            return $state.go(nextState.name, nextState.params, { reload: true });
        });
    };
}
