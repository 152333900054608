import moment from 'moment';

import calendarTpl from './calendar.tpl.html';
import popupTpl from './popup.tpl.html';
import datepickerTpl from './datepicker.tpl.html';

import './day.tpl.html';
import './month.tpl.html';
import './year.tpl.html';

customCalendarRun.$inject = ['$filter', '$translate', 'formlyConfig'];

export default function customCalendarRun($filter, $translate, formlyConfig) {
    formlyConfig.setType({
        name: 'customCalendar',
        templateUrl: calendarTpl,
        wrapper: ['validation', 'formControl', 'label', 'hasError'],
        defaultOptions: {
            templateOptions: {
                placeholderTranslateKey: 'app_CUSTOM_CALENDAR_PLACEHOLDER_SELECT_A_DATE', // 'Select a date'
                format: 'MMMM dd, yyyy',
                hideResetButton: false
            },
        },
        controller: ['$scope', '$filter', function($scope) {
            const to = $scope.to;
            const options = $scope.options;
            const model = $scope.model;

            to.placeholder = to.placeholder || $translate.instant(to.placeholderTranslateKey);

            to.uibDatepickerOptions = to.uibDatepickerOptions || {};

            let initDate = moment().toDate();
            let minDate;
            let maxDate;

            // initDate = the date that's highlighted by default when you open the datepicker
            // model[options.key] = determines what's sent when the form is submitted and determines what's displayed in the input field
            // options.defaultValue = what model[options.key] gets initialized to

            // TODO: allow specifying a date source format, Ex. "MM/DD/YYYY", "YYYY/MM"

            if (to.uibDatepickerOptions.maxDate) {
                maxDate = to.uibDatepickerOptions.maxDate = moment(to.uibDatepickerOptions.maxDate).startOf('day').toDate();
            }

            if (to.uibDatepickerOptions.minDate) {
                minDate = to.uibDatepickerOptions.minDate = moment(to.uibDatepickerOptions.minDate).startOf('day').toDate();
            }

            const firstValue = model[options.key] || options.defaultValue || to.uibDatepickerOptions.initDate;
            if (firstValue) {
                initDate = to.uibDatepickerOptions.initDate = moment(firstValue).startOf('day').toDate();

                if (options.defaultValue) {
                    model[options.key] = options.defaultValue = initDate;
                }
            }

            options.resetModel = () => {
                // The default resetModel doesn't work as intended, an unformatted date gets displayed in the input.
                if (options.defaultValue && to.required) {
                    model[options.key] = options.defaultValue;
                } else {
                    model[options.key] = null;
                }
            };

            $scope.templateUrl = datepickerTpl; // what's the point of this?
            $scope.datepickerPopupTemplateUrl = popupTpl;

            $scope.dateOptions = Object.assign({
                templateUrl: datepickerTpl,
                formatYear: 'y',
                startingDay: 0,
                showWeeks: false,
            }, to.uibDatepickerOptions, {
                minDate,
                maxDate,
                initDate
            });

            $scope.popup = {
                opened: false
            };

            $scope.open = function() {
                $scope.popup.opened = true;
            };
        }]
    });
}
