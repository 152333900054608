import createGridPageConfig from '../../../core/directive/rnDxGrid/createGridPageConfig';

export default createGridPageConfig({
    grid: {
        componentName: 'submissions-grid'
    },
    state: {
        name: 'submissionsHierarchy',
        config: {
            parent: 'auth',
            url: '/submissions/hierarchy',
            data: {
                pageTitle: 'Submissions'
            },
            resolve: {
                submissionsTranslation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('submissions');
                }],
                promoTranslation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('promo');
                }]
            }
        }
    },
    controller: [function submissionsPageCtrl() {
        const vm = this;
        vm.isAdminView = true;
    }],
    template: {
        header: 'submissions_PAGE_HEADER_ADMIN',
        containerClass: 'submissions-page-container submissions-hierarchy-page-container',
        gridComponentBindings: `is-admin-view="vm.isAdminView"`
    }
});
