PromoQuizValidationService.$inject = ["$http"];

export default function PromoQuizValidationService($http) {
    return {
        save,
        getAspectList,
        submitFeedback,
    };

    async function save({
        promoID, quizID, sectionID, quizResultID, quizAnswers,
        assignBackNote, ocrResultsToIgnore, ocrResultsToFix, ocrResultsToAssignBack
    }) {
        const { data: result } = await $http.post(`/api/quiz-results/${quizResultID}/validation`, {
            promoID,
            quizID,
            sectionID,
            quizAnswers,
            assignBackNote,
            ocrResultsToIgnore,
            ocrResultsToFix,
            ocrResultsToAssignBack,
        });
        return result;
    }

    function getAspectList() {
        return [
            {
                ID: 0, 
                label: "Handwriting not recognized",
                translateKey: "promo_OCR_FEEDBACK_HANDWRITING",
                isPass: false,
            },
            {
                ID: 1, 
                label: "Partial match in middle should be accepted",
                translateKey:"promo_OCR_FEEDBACK_PARTIAL",
                isPass: false,
            },
            {
                ID: 2, 
                label: "Date format not recognized", 
                translateKey: "promo_OCR_FEEDBACK_BAD_DATE_FORMAT",
                isPass: false,
                quizQuestionTypeID: 7,
            },
            {
                ID: 3, 
                label: "File and form are exactly the same (should not fail)",
                translateKey:"promo_OCR_FEEDBACK_SHOULD_NOT_FAIL",
                isPass: false,
            },
            {
                ID: 4, 
                label: "Number formatting not recognized", 
                translateKey: "promo_OCR_FEEDBACK_BAD_NUMBER_FORMAT",
                isPass: false,
            },
            {
                ID: 5, 
                label: "Wrong Spelling", 
                translateKey: "promo_OCR_FEEDBACK_BAD_SPELLING",
                isPass: false,
            },
            {
                ID: 6,
                label: "Number was off by cents",
                translateKey: "promo_OCR_FEEDBACK_OFF_BY_CENTS",
                isPass: false,
            },
            {
                ID: 7,
                label: "Punctuation Issue",
                translateKey: "promo_OCR_FEEDBACK_BAD_PUNCTUATION",
                isPass: false,
            },
            { 
                ID: 8, 
                label: "Padded Zeros should be accepted", 
                translateKey: "promo_OCR_FEEDBACK_PADDED_ZERO",
                isPass: false,
            },
            {
                ID: 9, 
                label: "Issue with particular file type", 
                translateKey: "promo_OCR_FEEDBACK_BAD_FILE_TYPE",
                isPass: false,
            },
            { 
                ID: 10, 
                label: "Partial match at beginning should be accepted", 
                translateKey: "promo_OCR_FEEDBACK_PARTIAL_BEGINNING",
                isPass: false,
            },
            { 
                ID: 11, 
                label: "Value is not in the scan or in another file",
                translateKey: "promo_OCR_FEEDBACK_VALUE_IN_ANOTHER_FILE",
                isPass: false,
            },
            { 
                ID: 12, 
                label: "Partial match at end should be accepted",
                translateKey: "promo_OCR_FEEDBACK_PARTIAL_ENDING",
                isPass: false,
            },
            {
                ID: 13,
                label: "Content was not found in file",
                translateKey: "promo_OCR_FEEDBACK_NOT_FOUND_IN_FILE",
                isPass: true,
            },
            {
                ID: 14,
                label: "Date Format should not be accepted",
                translateKey: "promo_OCR_FEEDBACK_DATE_NOT_ACCEPTED",
                isPass: true,
                quizQuestionTypeID: 7,
            },
            {
                ID: 15,
                label: "Partial Match at beginning should NOT be accepted",
                translateKey: "promo_OCR_FEEDBACK_NO_PARTIAL_BEGINNING",
                isPass: true,
            },
            {
                ID: 16,
                label: "Partial Match at end should NOT be accepted",
                translateKey: "promo_OCR_FEEDBACK_NO_PARTIAL_ENDING",
                isPass: true,
            },
            {
                ID: 17,
                label: "Partial Match in middle should NOT be accepted",
                translateKey: "promo_OCR_FEEDBACK_NO_PARTIAL",
                isPass: true,
            },
            {
                ID: 18,
                label: "Padded Zeros should NOT be accepted",
                translateKey: "promo_OCR_FEEDBACK_NO_PADDED_ZERO",
                isPass: true,
            },
            
        ];
    }

    async function submitFeedback(feedback) {
        const { data: result } = await $http.post(`/api/quiz-results/validation/feedback/`, feedback);
        return result;
    }
}
