import promoLeaderboardWidgetTpl from './promoLeaderboardWidget.tpl.html';
import promoLeaderboardWidgetCtrl from './promoLeaderboardWidget.controller';


export default {
    templateUrl: promoLeaderboardWidgetTpl,
    controller: promoLeaderboardWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        size: '@'
    }
};
