import moment from 'moment';
import _ from 'lodash';
import { filter } from '@uirouter/angularjs';

SnapshotService.$inject = ['$http', '$translate'];

export default function SnapshotService($http, $translate) {

    const dateFilterOptions = [{
        name: 'Last 30 Days',
        code: 'Last30Days',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_LAST_30_DAYS',
    }, {
        name: 'Last 90 Days',
        code: 'Last90Days',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_LAST_90_DAYS',
    }, {
        name: 'Previous Month',
        code: 'PreviousMonth',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_PREVIOUS_MONTH',
    }, {
        name: 'Current Month To Date',
        code: 'CurrentMonthToDate',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_CURRENT_MONTH_TO_DATE'
    }, {
        name: 'Previous Year',
        code: 'PreviousYear',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_PREVIOUS_YEAR',
    }, {
        name: 'Current Year To Date',
        code: 'CurrentYearToDate',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_CURRENT_YEAR_TO_DATE',
    }, {
        name: 'Pick Between Two Dates',
        code: 'Custom',
        translateKeyName: 'snapshot_FILTER_DATE_OPTION_PICK_BETWEEN_TWO_DATES',
    }, {
        class: 'divider',
    }];

    /**
     * Get the list of widgets and widget pages for a contact.
     * @param {Object} params
     * @param {int} params.contactID
     */
    async function getWidgets({ contactID }) {
        const res = await $http.get(`/api/snapshot/${contactID}/widgets`);
        return res.data;
    }

    function getParameterValuesXML(filters) {
        const xmlDoc  = document.implementation.createDocument(null, "ParameterValues", null);
        
        filters.forEach(filter => {
            if(!filter.currentValue || filter.currentValue.name === filter.displayName) {
                return;
            }
            switch(filter.filterType) {
                case "customDate":
                    if(filter.currentValue[filter.parameters.startDate] && filter.currentValue[filter.parameters.endDate]) {
                        const startDateElement = xmlDoc.createElement("ParameterValue");
                        startDateElement.setAttribute("ParameterName", filter.parameters.startDate);
                        startDateElement.setAttribute("value", moment(filter.currentValue[filter.parameters.startDate])
                                                                    .format("YYYY-MM-DD"));
    
                        const endDateElement = xmlDoc.createElement("ParameterValue");
                        endDateElement.setAttribute("ParameterName", filter.parameters.endDate);
                        endDateElement.setAttribute("value", moment(filter.currentValue[filter.parameters.endDate])
                                                                    .format("YYYY-MM-DD"));
                        
                        xmlDoc.getElementsByTagName("ParameterValues")[0].appendChild(startDateElement);
                        xmlDoc.getElementsByTagName("ParameterValues")[0].appendChild(endDateElement);
                    }
                    break;
                case "shortList":
                    const shortListElement = xmlDoc.createElement("ParameterValue");
                    shortListElement.setAttribute("ParameterName", filter.parameterName);
                    shortListElement.setAttribute("value", filter.currentValue.parameterValue);
                    xmlDoc.getElementsByTagName("ParameterValues")[0].appendChild(shortListElement);
                    break;
                case "suggests":
                    const suggestElement = xmlDoc.createElement("ParameterValue");
                    suggestElement.setAttribute("ParameterName", filter.parameterName);
                    suggestElement.setAttribute("value", filter.currentValue.parameterValue);
                    xmlDoc.getElementsByTagName("ParameterValues")[0].appendChild(suggestElement);
                    break;
            }
        });
        return new XMLSerializer().serializeToString(xmlDoc);
    }

    function getDateFilterValueByCode({ option, filter }) {
        let year, month, startDate;
        let currentValue = {};
        currentValue.code = option.code;
        switch (option.code) {
            case 'Last30Days':
                currentValue[filter.parameters.startDate] = moment().subtract(30, 'days').toDate();
                currentValue[filter.parameters.endDate] = moment().toDate();
                break;
            case 'Last90Days':
                currentValue[filter.parameters.startDate] = moment().subtract(90, 'days').toDate();
                currentValue[filter.parameters.endDate] = moment().toDate();
                break;
            case 'PreviousMonth':
                year = moment().subtract(1, 'months').year();
                month = moment().subtract(1, 'months').month() + 1;
                startDate = moment(`${year}-${month}-1`, 'YYYY-M-D');
                currentValue[filter.parameters.startDate] = startDate.toDate();
                currentValue[filter.parameters.endDate] = startDate.add(1, 'months').subtract(1, 'days').toDate();
                break;
            case 'CurrentMonthToDate':
                currentValue[filter.parameters.startDate] = moment().startOf('month').toDate();
                currentValue[filter.parameters.endDate] = moment().toDate();
                break;
            case 'PreviousYear':
                year = moment().subtract(1, 'years').year();
                startDate = moment(`${year}-1-1`, 'YYYY-M-D');
                currentValue[filter.parameters.startDate] = startDate.toDate();
                currentValue[filter.parameters.endDate] = startDate.add(1, 'years').subtract(1, 'days').toDate();
                break;
            case 'CurrentYearToDate':
                currentValue[filter.parameters.startDate] = moment().startOf('year').toDate();
                currentValue[filter.parameters.endDate] = moment().toDate();
                break;
            case 'year-option':
                currentValue[filter.parameters.startDate] = moment(option.phrase, 'YYYY').toDate();
                currentValue[filter.parameters.endDate] = moment(option.phrase, 'YYYY').endOf('year').toDate();
                break;
            case 'quarter-option':
                currentValue[filter.parameters.startDate] = moment([option.year, option.quarterNumber * 3 - 2]
                    .join('-'), 'YYYY-M').startOf('month').toDate();
                currentValue[filter.parameters.endDate] = moment([option.year, option.quarterNumber * 3]
                    .join('-'), 'YYYY-M').endOf('month').toDate();
                break;
            case 'month-option':
                currentValue[filter.parameters.startDate] = moment([option.year, option.month]
                    .join('-'), 'YYYY-M').startOf('month').toDate();
                currentValue[filter.parameters.endDate] = moment([option.year, option.month]
                    .join('-'), 'YYYY-M').endOf('month').toDate();
                break;
        }
        return _.clone(currentValue);
    }

    function getDateFilterOptions() {
        return dateFilterOptions.map(item => {
            if (item.translateKeyName) {
                const phrase = $translate.instant(item.translateKeyName);
                return {
                    ...item,
                    phrase
                };
            }
            return item;
        });
    }

    return {
        getWidgets,
        getParameterValuesXML,
        getDateFilterValueByCode,
        getDateFilterOptions
    };
}
