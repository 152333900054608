import _ from 'lodash';
import $ from 'jquery';

widgetFooterCtrl.$inject = ['WidgetService', 'ChartWidgetService'];

export default function widgetFooterCtrl(WidgetService, ChartWidgetService) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact
        // vm.summaryLink

        if (!vm.summaryLink) {
            vm.summaryLink = WidgetService.getSummaryLinkHref(vm.widget, vm.contact);
        }

        // when showing only 1 item chart, range selector is moot

        if (vm.chartConfig && vm.chartConfig.hasRangeSelector) {
            vm.chartCssID = ChartWidgetService.createChartCssID(vm.widget.widgetsAvailableID);

            vm.rangeOptions = {
                size: {
                    height: 60
                },
                margin: {
                    left: 10,
                    right: 10
                },
                scale: {
                    minorTickCount: 1
                },
                dataSource: vm.chartConfig.dataSource,
                chart: {
                    commonSeriesSettings: {
                        type: vm.chartConfig.commonSeriesSettings.type,
                        ignoreEmptyPoints: true
                    },
                    series: vm.chartConfig.series,
                },
                behavior: {
                    callValueChanged: 'onMoving'
                },
                onValueChanged: _.debounce(function (e) {
                    const zoomedChart = $(`#${vm.chartCssID}`);
                    const instance = zoomedChart.dxChart('instance');
                    instance.zoomArgument(e.value[0], e.value[1]);
                }, 100)
            };
        }
    };
}
