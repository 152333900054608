import angular from 'angular';

import '../../core/core.module';
import '../contactUs/contactUs.module';

import faqConfig from './faq.config';
import faqCtrl from './faq.controller';
import FaqService from './faqService.factory';

export default angular.module('app.faq', [
    'app.core',
    'app.contactUs'
])
.config(faqConfig)
.controller('faqCtrl', faqCtrl)
.factory('FaqService', FaqService);
