import progressBarsWidgetTpl from './progressBarsWidget.tpl.html';
import progressBarsWidgetCtrl from './progressBarsWidget.controller';

export default {
    templateUrl: progressBarsWidgetTpl,
    controller: progressBarsWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        widget: '<',
        contact: '<'
    }
};
