LeaderboardService.$inject = ['$http'];

export default function LeaderboardService($http) {

    async function getLeaderboardValuePhrase(promoID) {
        // TODO: fix inconsistent terminology - getLeaderboardValuePhrase, performance-title, performanceBasedOnName
        const res = await $http.get(`/api/leaderboard/${promoID}/performance-title`);
        return res.data;
    }

    return {
        getLeaderboardValuePhrase
    };
}
