import AdminFrameTpl from './adminFrame.tpl.html';

AdminFrameConfig.$inject = ['$stateProvider'];

export default function AdminFrameConfig($stateProvider) {
    $stateProvider.state('gamesAdmin', {
        parent: 'auth',
        url: '/games-admin?adminFrameUrl&adminFrameClass',
        views: {
            'main@': {
                controller: 'AdminFrameCtrl as vm',
                templateUrl: AdminFrameTpl
            }
        },
        data: {
            pageTitle: 'Games Admin',
            permission: 'gamesAdmin'
        }
    });
}
