import angular from 'angular';

import promoQuizTools from './promoQuizTools.component';
import PromoQuizToolsService from './promoQuizToolsService.factory';
import promoQuizAddNote from './addNote/addNote.component';
import promoQuizAssignBack from './assignBack/assignBack.component';
import promoQuizClose from './close/close.component';
import promoQuizDecline from './decline/decline.component';
import promoQuizApprove from './approve/approve.component';

import './validation/validation.module';

export default angular.module('app.promo.promoQuiz.tools', [
    'app.promo.promoQuiz.tools.validation'
])
.component('promoQuizTools', promoQuizTools)
.factory('PromoQuizToolsService', PromoQuizToolsService)
.component('promoQuizAddNote', promoQuizAddNote)
.component('promoQuizAssignBack', promoQuizAssignBack)
.component('promoQuizClose', promoQuizClose)
.component('promoQuizDecline', promoQuizDecline)
.component('promoQuizApprove', promoQuizApprove);
