import widgetFooterTpl from './widgetFooter.tpl.html';
import widgetFooterCtrl from './widgetFooter.controller';

export default function widgetFooter() {
    return {
        restrict: 'E',
        templateUrl: widgetFooterTpl,
        controller: widgetFooterCtrl,
        controllerAs: 'vm',
        scope: {},
        bindToController: {
            widget: '<',
            contact: '<',
            chartConfig: '<',
            summaryLink: '@',
        }
    };
}
