import applauseDetailsTpl from './applauseDetails.tpl.html';
import applauseDetailsCtrl from './applauseDetails.controller';

export default {
    templateUrl: applauseDetailsTpl,
    controller: applauseDetailsCtrl,
    controllerAs: 'vm',
    bindings: {
        applause: '<',
        showSpotlightButtons: '<',
        showCommentSection: '<',
        onDelete: '<',
    }
};
