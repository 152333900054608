import _ from 'lodash';
import moment from 'moment';

widgetCacheFooterCtrl.$inject = ['WidgetService', '$rootScope'];

export default function widgetCacheFooterCtrl(WidgetService, $rootScope) {
    this.$onInit = () => {
        const vm = this;
        // Passed from component bindings:
        // vm.widget
        // vm.contact

        vm.hasData = _.get(vm.widget, 'data');

        if (!vm.hasData) {
            return;
        }

        vm.lastCachedOn = '';
        if (vm.widget.data.lastCachedOn) {
            vm.lastCachedOn = moment(vm.widget.data.lastCachedOn).format('LLL');
        }

        vm.refresh = refresh;

        async function refresh() {
            $rootScope.$emit('WIDGET_REFRESH', vm.widget.widgetsAvailableID);
        }
    };
}
