ContactUsService.$inject = ['$http', 'AuthStatus', 'Session'];

export default function ContactUsService($http, AuthStatus, Session) {
    return {
        getContactUsSetup,
        sendMail
    };

    function getContactUsSetup() {
        if (AuthStatus.isAuthenticated()) {
            const company = Session.getCompany();
            return company.contactUsSetup;
        }
        return [];
    }

    /**
     * @param fields
     * @param {string} fields.contactName
     * @param {string} fields.contactEmail
     * @param {string} [fields.contactPhone]
     * @param {int} [fields.emailConcerningID]
     * @param {string} fields.emailSubject
     * @param {string} fields.emailMessage
     * @param {boolean} [fields.isSendToMe]
     * @param {string} [fields.captchaToken]
     */
    function sendMail(fields) {
        return $http.post('/api/contact-us', fields);
    }
}
