import _ from 'lodash';
import $ from 'jquery';

trainingQuizModalCtrl.$inject = [
    '$scope', '$compile', 'promoDetails', 'quizResultID', 'initialQuizQuestionID', 'currentScore', 'PromoQuizService'
];

export default function trainingQuizModalCtrl(
    $scope, $compile, promoDetails, quizResultID, initialQuizQuestionID, currentScore, PromoQuizService
) {
    const vm = this;

    vm.promoDetails = promoDetails = _.cloneDeep(promoDetails);
    vm.promoID = promoDetails.ID;
    vm.quizResultID = quizResultID;

    // Training quizzes are one-section.
    const quiz = promoDetails.quiz.sections[0];

    vm.question = quiz.questions.find(question => question.ID === initialQuizQuestionID);
    vm.questionIndex = quiz.questions.indexOf(vm.question);
    vm.totalQuestions = quiz.questions.length;
    vm.score = currentScore;

    vm.showResults = false;
    vm.questionAnswered = false;
    vm.showHeader = true;
    vm.showQuestion = true;
    vm.showNextQuestionButton = false;
    vm.showViewResultsButton = false;

    // For starting to load the results before the user clicks the "View results" button.
    let getResultsPromoDetails;

    vm.onQuestionSubmitSuccess = ({ answerScore, nextQuizQuestionID, isQuizFinished }) => {
        vm.score += answerScore || 0;
        if (!isQuizFinished) {
            vm.nextQuizQuestionID = nextQuizQuestionID;
            vm.questionAnswered = true;
            vm.showNextQuestionButton = true;
            return;
        }
        getResultsPromoDetails = PromoQuizService.getQuizResults({ quizResultID: vm.quizResultID });
        vm.showNextQuestionButton = false;
        vm.showViewResultsButton = true;
    };

    vm.nextQuestion = () => {
        const $currentQuestion = $(`#training-quiz-${vm.quizResultID}-question-${vm.question.ID}`);
        vm.question = quiz.questions.find(question => question.ID === vm.nextQuizQuestionID);
        vm.questionIndex = quiz.questions.indexOf(vm.question);

        const newQuestionCompiled = $compile(questionTemplate)($scope);
        $currentQuestion.replaceWith(newQuestionCompiled);

        vm.showNextQuestionButton = false;
        vm.questionAnswered = false;
    };

    vm.viewResults = () => {
        getResultsPromoDetails
            .then(resultsPromoDetails => {
                vm.resultsPromoDetails = resultsPromoDetails;
                vm.showResults = true;
                vm.questionAnswered = false;
                vm.showHeader = false;
                vm.showQuestion = false;
                vm.showNextQuestionButton = false;
                vm.showViewResultsButton = false;
            });
    };
}

const questionTemplate = `
    <training-quiz-question ng-if="vm.showQuestion"
                            promo-details="vm.promoDetails"
                            quiz-result-id="vm.quizResultID"
                            question="vm.question"
                            on-submit-success="vm.onQuestionSubmitSuccess"
                            id="training-quiz-{{ vm.quizResultID }}-question-{{ vm.question.ID }}">
    </training-quiz-question>`;
