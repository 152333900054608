import snapshotTpl from './snapshot.tpl.html';

snapshotConfig.$inject = ['$stateProvider'];

export default function snapshotConfig($stateProvider) {
    $stateProvider.state('snapshot', {
        parent: 'auth',
        url: '/snapshot/:contactID?page&messageRecipientID',
        reloadOnSearch: false,
        views: {
            'main@': {
                controller: 'snapshotCtrl as vm',
                templateUrl: snapshotTpl
            },
        },
        data: {
            pageTitle: 'Snapshot',
            navLinkOptions: {
                // Prevents not being able to go to self when clicking on the snapshot link while on another
                // contact's snapshot
                inherit: false
            }
        },
        params: {
            contactID: null
        },
        resolve: {
            contact: ['$stateParams', 'ContactService', function($stateParams, ContactService) {
                return ContactService.getContact($stateParams.contactID);
            }],
            widgetPages: ['$stateParams', 'SnapshotService', function($stateParams, SnapshotService) {
                return SnapshotService.getWidgets({ contactID: $stateParams.contactID });
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('snapshot');
            }]
        }
    });
}
