navLinkCtrl.$inject = ['$state', 'StateUtils', 'UrlUtils', 'NavBarTypes', 'NavService'];

export default function navLinkCtrl($state, StateUtils, UrlUtils, NavBarTypes, NavService) {
    this.$onInit = () => {
        const vm = this;

        if (vm.navItem.navBarTypeID && vm.navItem.navBarTypeID !== NavBarTypes.URL) {
            vm.isNotLink = true;
            vm.onClick = NavService.getNavBarTypeFunction(vm.navItem.navBarTypeID);
            return;
        }

        if (UrlUtils.isUrlAbsolute(vm.navItem.url)) {
            vm.externalLink = vm.navItem.url;
            return;
        }

        const state = StateUtils.getStateFromUrl(vm.navItem.url);
        if (state) {
            const stateConfig = $state.get(state.name);
            vm.uiSref = StateUtils.getUiSrefFromUrl(vm.navItem.url);
            if (stateConfig.data.navLinkOptions && !vm.navItem.isReload) {
                vm.uiSrefOpts = stateConfig.data.navLinkOptions;
            } else if (vm.navItem.isReload) {
                vm.uiSrefOpts = {
                    reload: true,
                    inherit: false // don't keep previous query strings
                };
            } else {
                vm.uiSrefOpts = {};
            }
        } else {
            console.warn(`Invalid navlink URL - ${vm.navItem.url}`);
            vm.invalidLink = true;
        }
    };
}
