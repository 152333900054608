

const constantUtils = require('./constantUtils');

const SsoTypes = {
    PING_IDENTITY: 1,
    WEB_SERVICE: 2,
    WORDPRESS: 3,
    PRESHARED_TOKEN: 4,
    DIRECT_SIGN_IN: 5,
    SAML2: 6,
};

const properties = {};

constantUtils.configure({ constants: SsoTypes, properties });

module.exports = SsoTypes;
