import angular from 'angular';

import 'devextreme/ui/data_grid';

import '../../core.module';
import './filterModal/filterModal.module';
import './columnChooser/columnChooser.module';

import rnDxGrid from './rnDxGrid.directive';
import gridOptionButton from './gridOptionButton/gridOptionButton.directive';
import GridColumnTypes from './gridColumnTypes.factory';
import GridFilterOperators from './gridFilterOperators.factory';
import GridColumnFilterTypes from './gridColumnFilterTypes.factory';
import RnDxGridService from './rnDxGridService.factory';
import GridUrlFilters from './gridUrlFilters.factory';
import GridUrlColumns from './gridUrlColumns.factory';
import GridUrlSort from './gridUrlSort.factory';

export default angular.module('app.core.directives.rnDxGrid', [
    'app.core',
    'app.core.directives.rnDxGrid.filterModal',
    'app.core.directives.rnDxGrid.columnChooser'
])
.directive('rnDxGrid', rnDxGrid)
.directive('gridOptionButton', gridOptionButton)
.factory('GridColumnTypes', GridColumnTypes)
.factory('GridFilterOperators', GridFilterOperators)
.factory('GridColumnFilterTypes', GridColumnFilterTypes)
.factory('RnDxGridService', RnDxGridService)
.factory('GridUrlFilters', GridUrlFilters)
.factory('GridUrlColumns', GridUrlColumns)
.factory('GridUrlSort', GridUrlSort);
