import resetPasswordTpl from './resetPassword.tpl.html';
import resetPasswordCtrl from './resetPassword.controller';

export default {
    templateUrl: resetPasswordTpl,
    controller: resetPasswordCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        onCancel: '<',
        onSuccess: '<'
    }
};
