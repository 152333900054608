ssoRun.$inject = ['$rootScope', 'AUTH_EVENTS', 'SsoService', 'SsoTypes'];

export default function ssoRun($rootScope, AUTH_EVENTS, SsoService, SsoTypes) {
    $rootScope.$on(AUTH_EVENTS.LOGIN_SUCCESS, (event, { company }) => {
        // TODO: review code and possiblly remove if unnecessary
        // FYI: Setting cookie in ssoService.factory.js SsoService.setSelectedEndpoint function
        // Assume companies will only have at most one DIRECT_SIGN_IN endpoint.
        const directSignInEndpoint = company.ssoEndpoints
            .find(ssoEndpoint => ssoEndpoint.ssoTypeID === SsoTypes.DIRECT_SIGN_IN);
        if (directSignInEndpoint) {
            SsoService.setSelectedEndpoint(directSignInEndpoint);
        }
    });
}
