import angular from 'angular';

import 'angular-formly';

import customRTERun from './customRTE.run';

export default angular.module('common.formly.customRTE', [
    'formly'
])
.run(customRTERun);
