applauseRTERun.$inject = ['$filter', '$translate', 'formlyConfig', 'ApplauseService'];

export default function applauseRTERun($filter, $translate, formlyConfig, ApplauseService) {

    formlyConfig.setType({
        name: 'applauseRTE',
        extends: 'customRTE',
        defaultOptions: {
            validators: {
                RTERequired: {
                    expression: function(viewValue, modelValue) {
                        const value = viewValue || modelValue;
                        const initialRTEValue = ApplauseService.getInitialApplauseRTEValue();
                        // stripTags is needed because the RTE surrounds the value with HTML tags
                        const strippedValue = $filter('stripTags')(value);
                        return new RegExp('^' + initialRTEValue + '$').test(strippedValue) === false;
                    },
                    message: function() {
                        // 'Please fill in a custom message'
                        return $translate.instant('applauseForm_RTE_MESSAGE_VALIDATION');
                    }
                }
            }
        },
        controller: applauseRTECtrl
    });

    applauseRTECtrl.$inject = ['$scope'];

    function applauseRTECtrl($scope) {
        const options = $scope.options;
        if (!$scope.model[options.key]) {
            $scope.model[options.key] = ApplauseService.getInitialApplauseRTEValue();
        }
    }
}
