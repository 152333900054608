import promoQuizApproveTpl from './approve.tpl.html';
import promoQuizApproveCtrl from './approve.controller';

export default {
    templateUrl: promoQuizApproveTpl,
    controller: promoQuizApproveCtrl,
    controllerAs: 'vm',
    bindings: {
        promoDetails: '<',
        section: '<',
        onSubmitSuccess: '<',
        onSubmitFail: '<'
    }
};
