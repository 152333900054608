import commentSectionTpl from './commentSection.tpl.html';
import commentSectionCtrl from './commentSection.controller';

export default {
    templateUrl: commentSectionTpl,
    controller: commentSectionCtrl,
    controllerAs: 'vm',
    bindings: {
        comments: '=',
        totalComments: '=',
        itemIDs: '<itemIds',
        showInput: '=',
        onViewMore: '<',
        onCommentAdded: '<',
        onCommentDeleted: '<'
    }
};
