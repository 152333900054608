import customRadioInputTpl from './customRadioInput.tpl.html';

customRadioInputRun.$inject = ['formlyConfig'];

export default function customRadioInputRun(formlyConfig) {

    formlyConfig.setType({
        name: 'customRadioInput',
        templateUrl: customRadioInputTpl,
        wrapper: ['validation', 'formControl', 'fieldset', 'hasError'],
        apiCheck: (apiCheck) => ({
            templateOptions: {
                options: apiCheck.arrayOf(apiCheck.object),
                labelProp: apiCheck.string.optional,
                valueProp: apiCheck.string.optional
            }
        }),
        defaultOptions: {
            templateOptions: {
                labelProp: 'name',
                labelTranslateKeyProp: 'translateKey',
                labelTranslateValuesProp: 'translateValues',
                valueProp: 'value',
                checkedCssClass: 'checkbox-pillbox-active'
            }
        },
        controller: customRadioInputCtrl,
    });

    customRadioInputCtrl.$inject = ['$scope'];

    function customRadioInputCtrl($scope) {
        const to = $scope.to;
        const options = $scope.options;
        const defaultSelectedOption = to.options.find(option => option.isSelected);
        if (defaultSelectedOption) {
            $scope.model[options.key] = defaultSelectedOption[to.valueProp];
        }
    }
}
