import _ from 'lodash';
import angular from 'angular';

promoQuizToolsCtrl.$inject = [
    'QuizResultStatuses', 'PromoQuizService', 'PromoQuizToolsService'
];

export default function promoQuizToolsCtrl(
    QuizResultStatuses, PromoQuizService, PromoQuizToolsService
) {
    this.$onInit = () => {
        const vm = this;
        vm.isApprover = PromoQuizService.isUserApprover(vm.promoDetails);
        vm.showAssignBack = vm.isApprover && vm.sectionResults.sectionStatusID === QuizResultStatuses.PENDING_APPROVAL;
        vm.showValidation = !!vm.sectionResults.ocrJobs;
        vm.hideToolsByValidation = false;
        vm.toggleQuizToolView = toggleQuizToolView;
        vm.toggleNoteFlag = toggleNoteFlag;

        _.merge(vm, PromoQuizToolsService.getActiveQuizTools(vm.showApproverTools, vm.section));
        _.merge(vm, PromoQuizToolsService.getQuizToolsSetup(vm.showApproverTools));

        if (vm.promoDetails && vm.promoDetails.results) {
            vm.quizResultID = vm.promoDetails.results.quizResultID;
            vm.quizTakerID = vm.promoDetails.results.quizTakerID;
        }

        function toggleNoteFlag(note) {
            return PromoQuizService.toggleNoteFlag({
                quizResultNoteID: note.ID,
                isFlagged: !note.isCommentFlag
            })
                .then(function() {
                    note.isCommentFlag = !note.isCommentFlag;
                });
        }

        function toggleQuizToolView(index) {
            vm.activeQuizToolIndex = index;
        }
    };
}
