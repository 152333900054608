import inboxGridTpl from './inboxGrid.tpl.html';
import inboxGridCtrl from './inboxGrid.controller';

export default {
    templateUrl: inboxGridTpl,
    controller: inboxGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<',
    }
};
