import faqTpl from './faq.tpl.html';
import faqCategoriesTpl from './faqCategories.tpl.html';

faqConfig.$inject = ['$stateProvider'];

export default function faqConfig($stateProvider) {
    $stateProvider.state('faq', {
        parent: 'auth',
        url: '/faq',
        views: {
            'main@': {
                controller: 'faqCtrl as vm',
                templateUrl: faqTpl
            },
            'faqCategories@faq': {
                templateUrl: faqCategoriesTpl
            }
        },
        data: {
            pageTitle: 'FAQ'
        },
        resolve: {
            faqs: ['FaqService', function(FaqService) {
                return FaqService.getFaqs();
            }],
            contactUsSetup: ['ContactUsService', function(ContactUsService) {
                return ContactUsService.getContactUsSetup();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('faq');
            }]
        }
    });
}
