import angular from 'angular';

import '../dialog/authDialog.module';

import authTimeoutConfig from './authTimeout.config';
import authTimeoutRun from './authTimeout.run';
import AuthTimeoutInterceptor from './authTimeoutInterceptor.factory';
import AuthTimeoutService from './authTimeoutService.factory';

export default angular.module('app.auth.timeout', [
    'app.core',
    'app.auth.dialog',
])
.config(authTimeoutConfig)
.run(authTimeoutRun)
.factory('AuthTimeoutInterceptor', AuthTimeoutInterceptor)
.factory('AuthTimeoutService', AuthTimeoutService);
