import angular from 'angular';

import '../../core/core.module';
import '../../blocks/blocks.module';

import changePasswordConfig from './changePassword.config';
import ChangePasswordService from './ChangePasswordService.factory';
import changePasswordForm from './changePasswordForm/changePasswordForm.component';

export default angular.module('app.changePassword', [
    'app.core',
    'app.blocks.modal'
])
.config(changePasswordConfig)
.factory('ChangePasswordService', ChangePasswordService)
.component('changePasswordForm', changePasswordForm);
