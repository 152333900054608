gamesConfig.$inject = ['$stateProvider'];

export default function gamesConfig($stateProvider) {
    $stateProvider.state('games', {
        parent: 'auth',
        url: '/games?loadCode',
        views: {
            'main@': 'gamesComponent'
        },
        data: {
            pageTitle: 'Games',
            options: ['GamesEnabled']
        },
        resolve: {
            playerToken: ['$http', '$window', 'Session', async ($http, $window, Session) => {
                const user = Session.getUser();
                try {
                    const gameServerURL = $window.hostURL || `https://${process.env.GAMES_SERVER_URL}`;
                    const { data } = await $http({
                        method: 'GET',
                        url: `${gameServerURL}/api/v1/validatePlayerToken`,
                        headers: {
                            'player-token': user.playerToken
                        }
                    });
                    return user.playerToken;
                } catch (err) {
                    console.error(err);
                    const { data } = await $http({
                        method: 'POST',
                        url: '/api/games/player-token'
                    });
                    Session.setUser({ ...user, playerToken: data });
                    return data;
                }
            }]
        }
    });
}
