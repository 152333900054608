import angular from 'angular';

import '../../../core/utils/coreUtils.module';

import navLinkCtrl from './navLink.controller';
import navLink from './navLink.directive';

export default angular.module('app.nav.navLink', [
    'app.core.utils'
])
.controller('navLinkCtrl', navLinkCtrl)
.directive('navLink', navLink);
