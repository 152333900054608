

const constantUtils = require('./constantUtils');

const WidgetTypes = {
    APPLAUSE_TEAM_PERFORMANCE: 'ApplausesTeamPerformance',
    DATAFEED_NUMBER_AND_POINTS_EARNED: 'DataFeedNumberAndPointsEarned'
};

const properties = {};

constantUtils.configure({ constants: WidgetTypes, properties });

module.exports = WidgetTypes;
