import likedUsersCtrlTpl from './likedUsers.tpl.html';
import likedUsersCtrlCtrl from './likedUsers.controller';

export default {
    templateUrl: likedUsersCtrlTpl,
    controller: likedUsersCtrlCtrl,
    controllerAs: 'vm',
    bindings: {
        item: '='
    }
};
