import previewTpl from './preview.tpl.html';

PreviewModalService.$inject = ['$uibModal'];

export default function PreviewModalService($uibModal) {
    return {
        openPreview
    };

    function openPreview(model) {
        return $uibModal.open({
            templateUrl: previewTpl,
            controller: 'previewCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
                model: () => model
            }
        });
    }
}
