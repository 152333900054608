controlPanelCtrl.$inject = ['$scope', '$transitions', '$state', '$cookies', 'AuthStatus', 'SignOnTasks', 'Session'];

export default function controlPanelCtrl($scope, $transitions, $state, $cookies, AuthStatus, SignOnTasks, Session) {
    const vm = this;

    const companyOptions = Session.getCompany().options;
    let isBypassTokenExist;

    if (companyOptions.UpgradeInProgress) {
        const bypassKey = companyOptions.UpgradeInProgress.value || 'VibeUpdate';
        isBypassTokenExist = $cookies.get('BYPASS-TOKEN') === bypassKey;
    }

    if (isBypassTokenExist) {
        vm.path = '/tools/vibe.asp';
    } else {
        vm.path = '/admin/controlPanel/controlPanel.asp';
        if ($state.params.tab) {
            vm.path += '?tab=' + $state.params.tab;
        }
    }

    $transitions.onStart({}, function() {
        // Need auth check because user could have logged out while on the controlPanel page
        if (AuthStatus.isAuthenticated()) {
            SignOnTasks.updateAdminTask();
        }
    });
}
