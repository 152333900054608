import angular from 'angular';
import EventEmitter from 'eventemitter3';

import SignOnTask from './taskClasses/SignOnTask';
import ApiRequestError from '../../blocks/errorHandling/ApiRequestError';

SignOnTasks.$inject = [
    '$rootScope', '$q', '$http', '$state', '$localStorage', 'AuthService', 'AUTH_EVENTS', 'SignOnTaskCreator'
];

export default function SignOnTasks(
    $rootScope, $q, $http, $state, $localStorage, AuthService, AUTH_EVENTS, SignOnTaskCreator
) {

    let signOnTasks = [];

    // For syncing tasks across browser tabs
    delete $localStorage.lastRemovedTask;
    delete $localStorage.lastAddedTask;

    $rootScope.$watch(() => $localStorage.lastRemovedTask, (taskObject) => {
        if (!taskObject) {
            return;
        }
        // If the removing originally happened in the current tab, index won't be found since it's already removed
        const taskIndex = findMatchingTaskIndex(taskObject);
        if (taskIndex >= 0) {
            removeTaskFromBrowser(taskObject);
        }
    }, true);

    $rootScope.$watch(() => $localStorage.lastAddedTask, (taskObject) => {
        if (!taskObject) {
            return;
        }
        // If the adding originally happened in the current tab, index will be found since it already exists
        const taskIndex = findMatchingTaskIndex(taskObject);
        if (taskIndex <= -1) {
            addTaskToBrowser(taskObject);
        }
    }, true);

    const Events = {
        TASKS_UPDATED: 'SIGN_ON_TASKS_UPDATED'
    };

    /**
     * Creates a list of SignOnTask class instances to be used in functions throughout the app session.
     * @param {Object[]} taskObjects - Received from API
     */
    function setTasks(taskObjects = []) {
        signOnTasks = taskObjects.map(createSignOnTask);
        broadcastUpdated();
    }

    /**
     * Creates a SignOnTask instance but also adds extra properties for convenient / efficient use within this service
     * @param {Object} taskObject - See hardCodedTasks.js for examples; should match output object from signOnTasks_get SP
     * @param {int} index
     * @returns {SignOnTask}
     */
    function createSignOnTask(taskObject, index) {
        Object.freeze(taskObject);

        const signOnTask = SignOnTaskCreator.createSignOnTask(taskObject);
        signOnTask.taskIndex = index;

        Object.defineProperty(signOnTask, 'taskObject', {
            get: () => taskObject
        });

        return signOnTask;
    }

    /**
     * @returns {SignOnTask[]}
     */
    function getTasks() {
        return signOnTasks;
    }

    /**
     * Fetches updated list of Admin type tasks and updates it locally.
     * @returns {Promise}
     */
    function updateAdminTask() {
        return $http.get('/api/tasks/admin')
            .then(function(res) {
                if (res.data.noChange) {
                    return;
                }
                if (res.data.hasAdminTask) {
                    // API adds task to server-side list already, just need to add client-side
                    return addTaskToBrowser(res.data.adminTask);
                } else {
                    return removeTask({ type: 'Admin' });
                }
            });
    }

    /**
     * @param {Object} taskObject - Received from API
     * @param {int} index
     */
    function addTaskToBrowser(taskObject, index = signOnTasks.length) {
        const signOnTask = createSignOnTask(taskObject, index);

        signOnTasks.splice(index, 0, signOnTask);

        // Update taskIndex properties
        signOnTasks.slice(index + 1).forEach(signOnTask => signOnTask.taskIndex += 1);

        $localStorage.lastAddedTask = taskObject;
        broadcastUpdated();
        return signOnTask;
    }

    /**
     * Remove from the local list of SignOnTasks as well as the server-side list of tasks in the user's session.
     * @param {SignOnTask | Object} obj
     *      A SignOnTask instance OR an object with desired properties to use in finding the matching SignOnTask.
     *      - Ex. `{ type: 'Message', messageRecipientID: 1234 }`
     *      - Ex. `{ type: 'UpdatePassword' }`
     * @returns {Promise.<boolean>}
     *      Resolves to true if task was found and removed successfully.
     *      Resolves to false if task was not found.
     *      Rejects if error occurred trying to remove.
     */
    async function removeTask(obj) {
        const taskIndex = findTaskIndex(obj);
        if (taskIndex <= -1) {
            console.log(`${obj.type} sign on task not found, did not remove`);
            return false;
        }
        const signOnTask = signOnTasks[taskIndex];
        await removeTaskFromApi(signOnTask);
        removeTaskFromBrowser(signOnTask);
        return true;
    }

    /**
     * Removes a task from the server-side list of tasks in the user's session
     * @param {SignOnTask} signOnTask
     */
    function removeTaskFromApi(signOnTask) {
        return $http.delete(`/api/tasks/${signOnTask.taskIndex}`, {
            data: signOnTask.taskObject,
            disableLoginAsConfirmModal: signOnTask.type === 'Message' // Prevent double confirmation modal
        });
    }

    /**
     * @param {SignOnTask | Object} obj
     */
    function removeTaskFromBrowser(obj) {
        const taskIndex = findTaskIndex(obj);
        const signOnTask = signOnTasks[taskIndex];

        signOnTasks.splice(taskIndex, 1);

        // Update taskIndex properties
        signOnTasks.slice(taskIndex).forEach(signOnTask => signOnTask.taskIndex -= 1);

        $localStorage.lastRemovedTask = signOnTask.taskObject;
        broadcastUpdated();
        return taskIndex;
    }


    function broadcastUpdated() {
        $rootScope.$broadcast(Events.TASKS_UPDATED, signOnTasks);
    }

    /**
     * @returns {SignOnTask[]}
     */
    function getRequiredTasks() {
        return signOnTasks.filter(signOnTask => signOnTask.isRequired);
    }

    /**
     * @returns {SignOnTask[]}
     */
    function getOptionalTasks() {
        return signOnTasks.filter(signOnTask => !signOnTask.isRequired);
    }

    /**
     * Opens the user's required sign on tasks and logs the user out if they fail to complete them all.
     * @param {SignOnTask} [startingTask]
     * @param {Object} [options]
     * @returns {EventEmitter}
     *      An EventEmitter representing the series of tasks. Fires these events:
     *      "complete" - When the user completes all their required sign on tasks.
     *      "dismissed" - When the user dismisses one of the required sign on tasks (they click the close button).
     *      "error" - When an error occurred during a sign on task.
     */
    function lockAppWithRequiredTasks(startingTask, options) {
        const taskSeries = new EventEmitter();
        (async () => {
            try {
                await openRequiredTasks(startingTask, options);
                taskSeries.emit('complete');
            } catch (err) {
                AuthService.logout();
                if (err instanceof Error) {
                    taskSeries.emit('error', err);
                    if (err instanceof ApiRequestError === false) {
                        throw err;
                    }
                } else {
                    taskSeries.emit('dismissed');
                }
            } finally {
                taskSeries.removeAllListeners();
            }
        })();
        return taskSeries;
    }

    /**
     * @param {SignOnTask} [startingTask]
     * @param {Object} [options]
     * @returns {Promise}
     *      Resolves when all required tasks are complete.
     *      Rejects when any required task is dismissed or an error occurred.
     */
    function openRequiredTasks(startingTask, options) {
        const requiredTasks = getRequiredTasks();
        return openTaskSeries(requiredTasks, startingTask, options);
    }

    /**
     * @param {SignOnTask} [startingTask]
     * @returns {Promise}
     *      Resolves when all optional tasks are complete.
     *      Rejects when any optional task is dismissed or an error occurred.
     */
    function openOptionalTasks(startingTask) {
        const optionalTasks = getOptionalTasks();
        return openTaskSeries(optionalTasks, startingTask);
    }

    /**
     * Some states (snapshot, spotlight, account) might open a modal as soon as they're entered.
     * If that's the case, we don't want to open the sign on tasks. Use this function to do that check.
     * @param {SignOnTask} [startingTask]
     */
    async function openOptionalTasksAfterLogin(startingTask) {
        if ($localStorage.cancelledOptionalTasksAfterLogin) {
            return;
        }
        return openOptionalTasks(startingTask);
    }

    function cancelOpeningOptionalTasksAfterLogin() {
        // Use local storage so that it gets cleared on logout (so that sign on tasks will open on next login).
        $localStorage.cancelledOptionalTasksAfterLogin = true;
    }

    /**
     * @param {SignOnTask} [startingTask]
     * @returns {Promise}
     */
    function openAllTasks(startingTask) {
        return openTaskSeries(signOnTasks, startingTask);
    }

    /**
     * Opens a modal for each SignOnTask object passed.
     * The modals will be opened as a series - only when the current modal closes will the next one show.
     * @param {SignOnTask[]} tasks
     * @param {SignOnTask} [startingTask]
     *      The task from which the series of modals should start at. After completing or skipping the startingTask,
     *      the series will proceed in the order it was returned from in the DB.
     *      Ex. completing one at a time starting at 4/6 --> 4/5 --> 4/4 --> 1/3 --> 2/2 --> 1/1 --> done
     * @param {Object} [options] - Additional options to pass to $uibModal.open
     * @returns {Promise} A chain of promises that resolve when all the modals are closed
     */
    function openTaskSeries(tasks, startingTask, options) {
        if (startingTask) {
            const taskIndex = findTaskIndex(startingTask);
            if (taskIndex < 0) {
                console.warn('startingTask not found in tasks array');
            } else {
                // Re-order the list of tasks
                tasks = tasks.slice(taskIndex).concat(tasks.slice(0, taskIndex));
            }
        }
        return tasks.reduce(
            (modalSeries, task) => modalSeries.then(() => openTask(task, options).then(modalInstance => modalInstance.result)),
            $q.resolve()
        );
    }

    /**
     * Opens the specified task in a popup modal.
     * @param {SignOnTask} signOnTask
     * @param {Object} [options] - Additional options to pass to $uibModal.open
     */
    function openTask(signOnTask, options) {
        const taskIndex = findTaskIndex(signOnTask);

        const previousTask = signOnTasks[taskIndex - 1];
        const taskNumber = taskIndex + 1;
        const totalNumberOfTasks = signOnTasks.length;

        return signOnTask.openModal(previousTask, taskNumber, totalNumberOfTasks, options);
    }

    /**
     * @param {SignOnTask|Object} obj
     *      A SignOnTask instance OR an object with desired properties to use in finding the matching SignOnTask.
     * @see findMatchingTaskIndex
     */
    function findTaskIndex(obj) {
        if (obj instanceof SignOnTask === false) {
            return findMatchingTaskIndex(obj);
        }
        const signOnTask = obj;
        if (signOnTask.hasOwnProperty('taskIndex')) {
            return signOnTask.taskIndex;
        }
        // signOnTasks.indexOf(signOnTask) doesn't work sometimes for some reason
        return signOnTasks.findIndex(t => angular.equals(t.taskObject, signOnTask.taskObject));
    }

    /**
     * @param {Object} obj
     *      Desired fields to use in finding the matching SignOnTask.
     *      Ex. { type: 'Message', messageRecipientID: 1234 }
     *      Ex. { type: 'UpdatePassword' }
     */
    function findMatchingTaskIndex(obj) {
        return signOnTasks.findIndex(
            signOnTask => Object.keys(obj).every(prop => obj[prop] === signOnTask.taskObject[prop])
        );
    }

    return {
        Events,
        setTasks,
        getTasks,
        updateAdminTask,
        removeTask,
        getRequiredTasks,
        getOptionalTasks,
        lockAppWithRequiredTasks,
        openRequiredTasks,
        openOptionalTasks,
        openOptionalTasksAfterLogin,
        cancelOpeningOptionalTasksAfterLogin,
        openAllTasks,
        openTask
    };
}
