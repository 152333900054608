import createMultiGridPageConfig from '../../core/directive/rnDxGrid/createMultiGridPageConfig';

export default createMultiGridPageConfig({
    tabs: [{
        code: 'all',
        tabLabelTranslateKey: 'reports_REPORTS_LIST', // 'Reports'
        template: `
            <reports-list custom-reports-list="vm.customReportsList"
                          company-metrics-list="vm.companyMetricsList">  
            </reports-list>`
    }, {
        code: 'saved',
        tabLabelTranslateKey: 'reports_SAVED_REPORTS', // 'Saved Reports'
        grid: {
            componentName: 'saved-reports-grid'
        }
    }, {
        code: 'scheduled',
        tabLabelTranslateKey: 'reports_SCHEDULED_REPORTS', // 'Scheduled Reports'
        grid: {
            componentName: 'scheduled-reports-grid'
        }
    }],

    state: {
        name: 'reports',
        config: {
            parent: 'auth',
            url: '/reports/:tab',
            params: {
                tab: 'all' // default to the first tab
            },
            data: {
                pageTitle: 'Reports'
            },
            resolve: {
                translation: ['RnTranslationService', function(RnTranslationService) {
                    return RnTranslationService.loadPhrases('reports');
                }],
                customReportsList: ['$stateParams', 'ReportsService', function($stateParams, ReportsService) {
                    return $stateParams.tab === 'all' ? ReportsService.getCustomReportsList() : null;
                }],
                companyMetricsList: ['$stateParams', 'ReportsService', 'Session',
                    function($stateParams, ReportsService, Session) {
                        const user = Session.getUser();
                        return $stateParams.tab === 'all'
                            ? user.hasPermission('UploadMetrics') ? ReportsService.getCompanyMetricsList() : null
                            : null;
                    }],
            }
        },
        tabUrlParam: 'tab',
    },
    controller: ['customReportsList', 'companyMetricsList', function reportsCtrl(
        customReportsList, companyMetricsList
    ) {
        const vm = this;

        vm.customReportsList = customReportsList || [];
        vm.companyMetricsList = companyMetricsList || [];
    }],
    template: {
        containerClass: 'reports-page-container',
    },
});
