ProfilePictureService.$inject = ['$http', '$rootScope', '$localStorage', 'Upload', 'Session'];

export default function ProfilePictureService($http, $rootScope, $localStorage, Upload, Session) {

    const PROFILE_PICS_CLOUD_CONTAINER_TYPE_ID = 9;
    const PROFILE_PICS_CLOUD_TTL = 86400000; // Assume same TTL for all companies' public cloud containers

    const Events = {
        USER_PROFILE_PICTURE_UPDATED: 'USER_PROFILE_PICTURE_UPDATED',
        USER_PROFILE_PICTURE_DELETED: 'USER_PROFILE_PICTURE_DELETED'
    };

    return {
        Events,
        determinePictureUrl,
        updateProfilePicture,
        deleteProfilePicture
    };

    /*
        Explanation of why localStorage is used when getting own picture URL / updating own profile picture / deleting own profile picture:
        - Rackspace Cloud Files is the platform currently used to store contacts profile pictures.
        - When trying to update/delete a file, it still gets cached according to the TTL of the cloud container (minimum of 15 minutes).
        - Right now we always name the profile picture file according to the contactID.
        - We don't want to involve the database at all to manage profile pictures (again, for now) so using different file names isn't an option yet
        - Therefore, we're assuming that it won't be so bad if other users see the old version of the current user's profile picture,
            but it's more important for the current user to see their own updated profile picture when they change/delete it
        - Ex. Joe from Acme Inc. updates or deletes his profile picture today at 5PM
            - Immediately after he'll see his profile picture / contact icon change throughout the site
            - The TTL for acme-public (rackspace cloud container) is 24 hours
            - Other people at Acme Inc. won't see his new profile picture until tomorrow at 5PM at the latest
            - If Joe clears his browser storage, he'll unfortunately see his old profile picture until tomorrow as well
            - If he doesn't, then the code below will know when the TTL has been exceeded,
                will clear the localStorage data related to Joe's new profile picture, and will use the normal URL (contactID.jpg)
        - Future / long term solution would probably be to use another service for managing profile pictures
     */

    /**
     * @param {int} contactID
     * @returns {string | undefined}
     *      If string, either a URL to the picture or the base64 data url of the stringified picture (from local storage).
     *      If undefined, means company has no ProfilePics cloud container or picture was recently deleted on current browser.
     */
    function determinePictureUrl(contactID) {
        const deletedOn = $localStorage[`${contactID}-profilePictureDeletedOn`];
        const updatedOn = $localStorage[`${contactID}-profilePictureUpdatedOn`];
        const dataUrl = $localStorage[`${contactID}-profilePictureDataUrl`];
        if (deletedOn || updatedOn) {
            if (deletedOn != null && Date.now() - deletedOn < PROFILE_PICS_CLOUD_TTL) {
                return;
            } else if (updatedOn != null && dataUrl != null && Date.now() - updatedOn < PROFILE_PICS_CLOUD_TTL) {
                return dataUrl;
            } else {
                // TTL exceeded
                delete $localStorage[`${contactID}-profilePictureDeletedOn`];
                delete $localStorage[`${contactID}-profilePictureUpdatedOn`];
                delete $localStorage[`${contactID}-profilePictureDataUrl`];
            }
        }
        const company = Session.getCompany();
        if (!company.cloudContainers || !company.cloudRootURLs) {
            return;
        }
        const profilePicsContainer = company.cloudContainers
            .find(cloudContainer => cloudContainer.typeID == PROFILE_PICS_CLOUD_CONTAINER_TYPE_ID);
        if (!profilePicsContainer) {
            console.warn('Tried to get a profile picture URL but company has no ProfilePics cloud container available');
            return;
        }
        return `${company.cloudRootURLs.public}/${profilePicsContainer.name}/${contactID}.jpg`;
    }

    function updateProfilePicture(file, contactID) {
        return Upload.upload({
            url: `/api/contacts/${contactID}/profile-picture`,
            method: 'PUT',
            data: {
                file: file
            },
        })
        .then(function(res) {
            const user = Session.getUser();
            if (contactID === user.userID) {
                return Upload.base64DataUrl(file)
                    .then(function(dataUrl) {
                        $localStorage[`${contactID}-profilePictureDataUrl`] = dataUrl;
                        $localStorage[`${contactID}-profilePictureUpdatedOn`] = Date.now();
                        Session.preventLocalStorageReset(`${contactID}-profilePictureDataUrl`);
                        Session.preventLocalStorageReset(`${contactID}-profilePictureUpdatedOn`);
                        delete $localStorage[`${contactID}-profilePictureDeletedOn`];
                        return res;
                    });
            }
            return res;
        });
    }

    function deleteProfilePicture(contactID) {
        return $http.delete(`/api/contacts/${contactID}/profile-picture`)
            .then(function(res) {
                const user = Session.getUser();
                if (contactID === user.userID) {
                    $localStorage[`${contactID}-profilePictureDeletedOn`] = Date.now();
                    Session.preventLocalStorageReset(`${contactID}-profilePictureDeletedOn`);
                    delete $localStorage[`${contactID}-profilePictureUpdatedOn`];
                    delete $localStorage[`${contactID}-profilePictureDataUrl`];
                }
                return res;
            });
    }
}
