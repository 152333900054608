ReportSortService.$inject = [];

export default function ReportSortService() {
    const SORT_SEPARATOR = '__';
    const ASCENDING_SYMBOL = 'asc';
    const DESCENDING_SYMBOL = 'desc';

    return {
        encode,
        decode,
    };

    function encode(sortSettings) {
        return sortSettings
            .map(({ field, desc }) => field + '--' + (desc ? DESCENDING_SYMBOL : ASCENDING_SYMBOL))
            .join(SORT_SEPARATOR);
    }
    
    function decode(sortStr) {
        if (!sortStr) {
            return [];
        }
        return sortStr.split(SORT_SEPARATOR)
            .map(sort => sort.split('--'))
            .filter(filter => filter.length === 2)
            .map(sort => {
                return  {
                    field: sort[0],
                    desc: sort[1] === DESCENDING_SYMBOL,
                }
            });
    }
}
