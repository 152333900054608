import scheduledReportsGridTpl from './scheduledReportsGrid.tpl.html';
import scheduledReportsGridCtrl from './scheduledReportsGrid.controller';

export default {
    templateUrl: scheduledReportsGridTpl,
    controller: scheduledReportsGridCtrl,
    controllerAs: 'vm',
    bindings: {
        gridID: '@gridId',
        initialFilters: '<',
        initialColumns: '<',
        initialSort: '<',
        updateUrlParams: '<'
    }
};
