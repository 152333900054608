// For reference:
// https://uppy.io/docs/uppy/#locale
// https://uppy.io/docs/plugins/#locale
// https://uppy.io/docs/dashboard/#Options

// TODO: translate all strings? see uppy/src/locales/en_US.js and uppy/src/core/Core.js
const core = {
    exceedsSize: 'app_CUSTOM_FILE_UPLOAD_VALIDATION_MAX_FILE_SIZE', // 'Max file size: '
    youCanOnlyUploadX: {
        1: 'app_CUSTOM_FILE_UPLOAD_VALIDATION_MAX_FILES' // 'You can only upload %{smart_count} files'
    },
};

// TODO: translate all strings? see uppy/src/plugins/Dashboard/index.js and uppy/src/plugins/StatusBar/index.js
const dashboard = {
    dropPaste: '',
    browse: 'app_CUSTOM_FILE_UPLOAD_DROPBOX', // 'Select or Drop File'
    removeFile: 'app_CUSTOM_FILE_UPLOAD_REMOVE_FILE', // 'Remove file'
    uploading: 'app_CUSTOM_FILE_UPLOAD_UPLOADING', // 'Uploading...'
    uploadComplete: 'app_CUSTOM_FILE_UPLOAD_COMPLETE', // 'Upload complete'
    uploadFailed: 'app_CUSTOM_FILE_UPLOAD_FAILED', // 'Upload failed'
    pleasePressRetry: '',
    retry: 'app_CUSTOM_FILE_UPLOAD_RETRY', // 'Retry',
    cancelUpload: 'app_CUSTOM_FILE_UPLOAD_CANCEL', // 'Cancel upload'
};

export default {
    core,
    dashboard
};
