import _ from 'lodash';
import moment from 'moment';
import confirmationToolTpl from './confirmationTool.tpl.html';

calculateInfoWidgetCtrl.$inject = ['$http', 'PromoStatuses', '$translate', '$uibModal', '$uibModalStack'];

export default function calculateInfoWidgetCtrl($http, PromoStatuses, $translate, $uibModal, $uibModalStack) {
    const vm = this;

    vm.$onInit = () => {
        const warnings = [];

        vm.warnings = warnings;
        vm.calculateInfo = vm.widget.data;
        vm.hasData = _.get(vm.widget.data, 'lastCalculatedOn');

        if (!vm.hasData) {
            return;
        }

        vm.lastCalculatedOn = vm.calculateInfo.lastCalculatedOn
            ? moment(vm.calculateInfo.lastCalculatedOn).format('YYYY-MM-DD') : '';
        vm.stopCalculatingOn = vm.calculateInfo.stopCalculatingOn
            ? moment(vm.calculateInfo.stopCalculatingOn).format('YYYY-MM-DD') : '';
        vm.metricLastUpdatedOn = vm.calculateInfo.metricLastUpdatedOn
            ? moment(vm.calculateInfo.metricLastUpdatedOn).format('YYYY-MM-DD') : '';

        // Warning 1: latest data update is not included
        if (vm.calculateInfo.lastCalculatedOn < vm.calculateInfo.metricLastUpdatedOn && Date.parse(vm.calculateInfo.stopCalculatingOn) < Date.now()) {
            warnings.push({
                message: $translate.instant('snapshot_CALCULATION_WIDGET_LATEST_DATA_WARNING_TEXT'),
                cssClass: 'no-latest-data'
            });
        }

        // Warning 2: incentive is paused or archived
        if ([PromoStatuses.PAUSED, PromoStatuses.ARCHIVED].includes(vm.calculateInfo.promoStatusID)) {
            warnings.push({
                message: $translate.instant('snapshot_CALCULATION_WIDGET_RECALC_PAYOUT_WARNING_TEXT'),
                cssClass: 'not-recalculating'
            });
        }

        // Warning 3: incentive is not yet started
        if ([PromoStatuses.DRAFT, PromoStatuses.PENDING].includes(vm.calculateInfo.promoStatusID)) {
            warnings.push({
                message: $translate.instant('snapshot_CALCULATION_WIDGET_NOT_YET_STARTED_WARNING_TEXT'),
                cssClass: 'not-started'
            });
        }

        function calclulateInfo() {
            warnings.forEach((warning, index) => {
                if (warning.isRemove) {
                    warnings.splice(index, 1);
                }
            });
            warnings.push({
                message: $translate.instant('snapshot_RECALCULATING_NOTIFICATION_TXT'),
                cssClass: 'calculating-time',
                isRemove: true
            });
            $http({
                method: vm.calculateInfo.apiMethod,
                url: vm.calculateInfo.buttonApi,
            });
            return $uibModalStack.dismissAll();
        }

        // Recalculate widget Information
        vm.recalculateInfo = () => {
            if (Date.parse(vm.calculateInfo.stopCalculatingOn) < Date.now()) {
                return $uibModal.open({
                    templateUrl: confirmationToolTpl,
                    size: 'md',
                    controller: function() {
                        const vm = this;
                        vm.confirmationText = $translate.instant('app_CONFIRMATION_RECALC_TEXT');
                        vm.onConfirm = () => calclulateInfo();
                    },
                    controllerAs: 'vm'
                });
            } else {
                return calclulateInfo();
            }
        };
    };
}
