import _ from 'lodash';
import $ from 'jquery';

ModalService.$inject = ['$location', '$window', '$state', '$timeout', 'AuthStatus', 'StateUtils'];

export default function ModalService($location, $window, $state, $timeout, AuthStatus, StateUtils) {

    /**
     * Adds querystring params to the URL when the modalInstance is opened.
     * Removes the params from the URL when the modalInstance is closed or dismissed.
     * @param modalInstance - uib-modal instance
     * @param urlParamsToAdd
     */
    function updateUrlParamsWithModal(modalInstance, urlParamsToAdd) {
        let paramsAdded;
        modalInstance.opened.then(() => {
            const currentUrlParams = $location.search();
            paramsAdded = _.pickBy(urlParamsToAdd, paramName => currentUrlParams[paramName] == null);
            StateUtils.updateUrlParams(paramsAdded);
        });
        modalInstance.result.finally(() => {
            const nullParams = _.mapValues(paramsAdded, () => null);
            if (!$state.transition) {
                StateUtils.updateUrlParams(nullParams);
            }
        });
    }

    /**
     * Watches the browser URL for a querystring parameter.
     * When that parameter is detected (=1 or =true), the modal will be opened.
     * @param {Object} params
     * @param {string} params.paramName
     *      The URL param name to check for. Make sure this is unique throughout all the code.
     * @param {Function} params.openFunction
     *      The function that opens the modal. Should return a uib-modal instance.
     * @param {boolean} [params.onlyWhenAuthenticated = true]
     *      If true, opens the modal only if the user logged in already.
     */
    function openWhenUrlParamAdded({
        paramName,
        openFunction,
        onlyWhenAuthenticated = true,
    }) {
        let modalInstance = null;

        async function checkUrl() {
            if (onlyWhenAuthenticated && !AuthStatus.isAuthenticated()) {
                // The modal won't open even after logging in. TODO: should an option be added to open after logging in?
                return;
            }
            const urlParams = $location.search();
            const value = urlParams[paramName];
            const urlParamAdded = ['1', 'true'].includes(value);
            if (modalInstance) {
                if (!urlParamAdded) {
                    modalInstance.dismiss(); // paramName was removed, so dismiss the modal.
                }
                return; // An unrelated url param must have changed while the modal was opened, so do nothing.
            }
            if (!urlParamAdded) {
                return;
            }
            const ongoingStateTransition = $state.transition;
            if (ongoingStateTransition) {
                await ongoingStateTransition;
            }
            modalInstance = await openFunction();
            updateUrlParamsWithModal(modalInstance, { [paramName]: value });
            modalInstance.result.finally(() => modalInstance = null);
        }

        // Check on initial page load, Ex. user goes directly to /#/spotlight?openRecognitionChooser=1
        // (timeout is to wait until after page looks nice and everything loaded).
        $timeout(checkUrl);

        // The hashchange event is used because:
        // - $stateChangeSuccess wouldn't trigger the function if the querystring changes without the path changing.
        //   Ex. going from /#/content/landing to /#/content/landing?openModal=1 wouldn't trigger.
        // - $rootScope.$watch would be inefficient, would be called too many times when not needed.
        $($window).on('hashchange', checkUrl);
    }

    return {
        updateUrlParamsWithModal,
        openWhenUrlParamAdded,
    };
}
