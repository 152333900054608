const constantUtils = require('./constantUtils');

const PromoActions = {
    UPLOAD: 'UPLOAD',
    CLONE: 'CLONE',
    DELETE: 'DELETE',
    PAUSE: 'PAUSE',
    UNPAUSE: 'UNPAUSE',
    ARCHIVE: 'ARCHIVE',
    UNARCHIVE: 'UNARCHIVE',
    ACTIVATE: 'ACTIVATE',
    ADD_TO_LIBRARY: 'ADD_TO_LIBRARY',
    BACKTODRAFT: 'BACKTODRAFT',
};

const properties = {};

constantUtils.configure({ constants: PromoActions, properties });

module.exports = PromoActions;
