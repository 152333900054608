import snapshotSetTpl from './snapshotSet.tpl.html';
import snapshotSetCtrl from './snapshotSet.controller';

export default {
    templateUrl: snapshotSetTpl,
    controller: snapshotSetCtrl,
    controllerAs: 'vm',
    bindings: {
        activeSetIndex: '=',
        widgetPages: '<',
        activePageIndex: '<',
        updateActiveSet: '<'
    }
};
