/**
 * Created by Jun on 1/27/2016.
 */
function carouselResponsiveHeight() {
    const directive = {
        restrict: 'C',
        link: link
    };
    return directive;

    function link(scope, element) {
        scope.getWidth = function () {
            return element[0].offsetWidth;
        };
        scope.$watch(scope.getWidth, function (width) {
            // Do your work with the element width.
            // Be careful not to change the width of the element or you will create an infinite loop.
            // Set the height of the element.
            if (width === 1140) {
                element.css('min-height','313px');
            }
            if (width === 1066) {
                element.css('min-height', '313px');
            }
            if (width === 940) {
                element.css('min-height', '259px');
            }
            if (width === 866) {
                element.css('min-height', '254px');
            }
            if (width === 720) {
                element.css('min-height', '282px');
            }
            if (width < 720) {
                element.css('min-height', `${width * 0.3885918}px`);
            }

        });
    }
}

export default carouselResponsiveHeight;
