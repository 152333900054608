import angular from 'angular';

import '../../../core/core.module';
import '../promoHeader/promoHeader.module';
import '../../dashboard/dashboard.module';

import promoResultsCofig from './promoResults.config';
import promoResultsCtrl from './promoResults.controller';

export default angular.module('app.promo.results', [
    'app.core',
    'app.promo.promoHeader',
    'app.dashboard'
])
.config(promoResultsCofig)
.controller('promoResultsCtrl', promoResultsCtrl);
