/* global html2pdf */

import _ from 'lodash';
import $ from 'jquery';

PdfService.$inject = ['$http', '$q', '$timeout'];

export default function PdfService($http, $q, $timeout,) {

    /**
     * Converts an HTML element to a PDF (completely browser-side, does not contact server).
     * Then begins downloading on the browser.
     * @see https://github.com/eKoopmans/html2pdf
     * @param {jQuery} $element
     * @param {Object} options - html2pdf options
     */
    async function downloadElement($element, options) {
        await loadLibrary();
        $element = $element.clone();
        if (!options.noPadding) {
            $element.css('padding', '50px');
        }

        const src = $element[0];

        return html2pdf(src, _.merge({
            // Do NOT pass a "margin" setting. If passed, there's a bug that causes html2pdf to freeze the page
            // on Firefox and IE. Strangely, it only happens when in production. CSS padding is used above instead.
            html2canvas: {
                scale: 2,
                useCORS: true,
                logging: process.env.NODE_ENV === 'development'
            }
        }, options));
    }

    const loadLibrary = _.once(async () => {
        // html2pdf is fairly large and printing doesn't happen that often, so only load the library when needed.
        // TODO: use webpack dynamic imports instead
        const deferred = $.ajax({
            url: '/assets/shared/js/html2pdf.bundle.min.js', // v0.9.0
            dataType: 'script',
            cache: true,
        });
        await $q.resolve(deferred); // Resolves when script loaded
        await $timeout(0); // Wait for script to finish executing
    });

    return {
        downloadElement
    };
}
