

const constantUtils = require('./constantUtils');

const ApplauseCategoryTypes = {
    APPLAUSE: 'Applause',
    CULTURE_STORY: 'CultureStory',
    BIRTHDAY: 'Birthday',
    ANNIVERSARY: 'Anniversary'
};

const properties = {};

constantUtils.configure({ constants: ApplauseCategoryTypes, properties });

module.exports = ApplauseCategoryTypes;
