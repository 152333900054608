columnChooserCtrl.$inject = ['$translate', '$uibModalInstance', 'columns', 'gridInstance'];

export default function columnChooserCtrl($translate, $uibModalInstance, columns, gridInstance) {
    const vm = this;
    const gridState = gridInstance.state();

    vm.columns = angular.copy(columns);
    vm.selectedColumns = angular.copy(columns);

    vm.placeHolderTxt = $translate.instant('app_GRID_COLUMN_CHOOSER_SEARCH_PLACEHOLDER');

    vm.selectedColumns.forEach((column, i) => {
        if (column.showInColumnChooser !== false) {
            column.visible = gridState.columns[i].visible;
        }
    });

    updateStatus();

    vm.selectAll = selectAll;
    vm.defaultSelection = defaultSelection;
    vm.onColumnChange = onColumnChange;
    vm.saveChanges = saveChanges;
    vm.cancel = cancel;

    function updateStatus() {
        vm.isSelectAll = vm.selectedColumns
                        .every(column => (column.visible === true || column.showInColumnChooser === false));
        vm.isDefaults = vm.selectedColumns
                        .every((column, i) => (column.showInColumnChooser === false || columns[i].visible === column.visible));
    }

    function selectAll() {
        if (vm.isSelectAll) {
            vm.selectedColumns = angular.copy(vm.columns);
        } else {
            vm.selectedColumns.forEach(column => {
                column.visible = true;
            });
        }
        updateStatus();
    }

    function defaultSelection() {
        vm.selectedColumns = angular.copy(columns);
        updateStatus();
    }

    function onColumnChange({ isVisible, dataField }) {
        vm.columns.filter(column => column.dataField === dataField).forEach(column => {
            column.visible = isVisible;
        });
        updateStatus();
    }

    function saveChanges() {
        gridInstance.beginUpdate();
        vm.selectedColumns.filter(column => column.showInColumnChooser !== false).forEach(column => {
            gridInstance.columnOption(column.dataField, 'visible', column.visible);
        });
        gridInstance.endUpdate();
        return $uibModalInstance.close({ visibleColumns: gridInstance.getVisibleColumns() });
    }

    function cancel() {
        return $uibModalInstance.dismiss();
    }
}
