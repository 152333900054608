ContentManagementService.$inject = ['$http', 'Session'];

export default function ContentManagementService($http, Session) {
    return {
        deleteContent,
        getContentList,
        getRoles,
        getContentTopicsList,
    };

    function deleteContent(contentID) {
        return $http.delete(`/api/content/id/${contentID}`);
    }

    function getContentList() {
        return $http.get('/api/content')
            .then(function(res) {
                return res.data;
            });
    }

    function getRoles() {
        // TODO: refactor controller and template to use an object of permission groups instead of needing an array
        const permissionGroups = Session.getCompany().permissionGroups;
        return Object.keys(permissionGroups).map(code => permissionGroups[code]);
    }

    function getContentTopicsList() {
        return $http.get('/api/content-topics')
            .then((res)=> {
                return res.data;
            });
    }
}
