import spotlightFilterTpl from './spotlightFilter.tpl.html';
import spotlightFilterCtrl from './spotlightFilter.controller';

export default {
    templateUrl: spotlightFilterTpl,
    controller: spotlightFilterCtrl,
    controllerAs: 'vm',
    bindings: {
        selectedParticipantFilter: '<',
        selectedRecognitionFilter: '<',
        selectedSentFilter: '<',
        selectedRecentDaysFilter: '<',
        selectedStartDateFilter: '<',
        contact: '<'
    }
};
