import deactivateUserTpl from './deactivateUser.tpl.html';
import deactivateUserCtrl from './deactivateUser.controller';

export default {
    templateUrl: deactivateUserTpl,
    controller: deactivateUserCtrl,
    controllerAs: 'vm',
    bindings: {
        contact: '<',
        onCancel: '<',
        onSuccess: '<'
    }
};
