import angular from 'angular';

import 'angular-pageslide-directive';

import '../../core/core.module';
import './navLink/navLink.module';
import './taskbar/taskbar.module';
import './loginPanel/loginPanel.module';
import './loginPanel/loginPanel.module';
import './quickLinks/quickLinks.module';

import navConfig from './nav.config';
import navCtrl from './nav.controller';
import NavService from './navService.factory';

export default angular.module('app.nav', [
    'pageslide-directive',
    'app.core',
    'app.nav.navLink',
    'app.nav.taskbar',
    'app.nav.loginPanel',
    'app.nav.quickLinks',
])
.config(navConfig)
.controller('navCtrl', navCtrl)
.factory('NavService', NavService);
