browserSupportCtrl.$inject = [];

export default function browserSupportCtrl() {
    this.$onInit = () => {
        const vm = this;
        // const loginOptionsContainer = document.getElementById('login-options-container');
        vm.siteUrl = window.location.href;

        if (vm.isInvalidBrowser) {
            vm.browserClass = 'unsupported-browser';
        } else if (vm.isSunsetBrowser) {
            vm.browserClass = 'sunset-browser';
        }

        vm.useOtherBrowser = () => {
            vm.isUseOtherBrowser = true;
        };

        vm.continue = () => {
            vm.useThisBrowser();
            vm.continueInSameBrowser = true;
        };

        vm.useNonEdge = () => {
            vm.isNonEdge = true;
        };

        vm.copySiteUrl = () => {
            /* Get the text field */
            const copyText = document.getElementById('use-non-edge-link');
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
            /* Copy the text inside the text field */
            document.execCommand('copy');
        };

        vm.learnMore = () => {
            vm.learnMoreAboutBrowser();
            vm.continueInSameBrowser = false;
        };

        vm.back = () => {
            vm.isNonEdge = false;
            vm.isUseOtherBrowser = false;
        };
    };
}
