import contactUsFormTpl from './contactUsForm.tpl.html';
import contactUsFormCtrl from './contactUsForm.controller';

export default {
    templateUrl: contactUsFormTpl,
    controller: contactUsFormCtrl,
    controllerAs: 'vm',
    bindings: {
        setup: '<' // includes "Concerning" field dropdown options
    }
};
