import angular from 'angular';

promoQuizCtrl.$inject = ['PromoQuizService', 'PromoTypes'];

export default function promoQuizCtrl(PromoQuizService, PromoTypes) {
    this.$onInit = () => {
        const vm = this;

        vm.isAward = vm.promoDetails.promoTypeID === PromoTypes.AWARD;

        vm.isNonSectionQuiz = PromoQuizService.isNonSectionQuiz(vm.promoDetails);

        updateView();

        vm.onSectionFormSubmitSuccess = (res) => {
        if (!vm.isAward) {
                return reloadPromoDetails(res.data.quizResultID);
            }
        };

        vm.onSectionToolSubmitSuccess = () => reloadPromoDetails(vm.quizResultID);

        vm.reset = function() {
            vm.promoDetails = angular.copy(vm.promoDetails);
            delete vm.promoDetails.results;
            delete vm.quizResultID;
            delete vm.quizTakerID;
            delete vm.isApprover;
            delete vm.isCandidate;
            updateView();
        };

        vm.isSectionVisible = (section) => vm.visibleSectionIDs.includes(section.sectionID);

        async function reloadPromoDetails(quizResultID) {
            const promoDetails = await PromoQuizService.getQuizResults({ quizResultID });
            vm.promoDetails = promoDetails;
            updateView();
            return promoDetails;
        }

        function updateView() {
            const quizResults = vm.promoDetails.results;
            vm.isApprover = PromoQuizService.isUserApprover(vm.promoDetails);
            vm.isCandidate = PromoQuizService.isUserCandidate(vm.promoDetails);
            if (quizResults) {
                vm.quizResultID = quizResults.quizResultID;
                vm.quizTakerID = quizResults.quizTakerID;
            }
            updateSectionVisibilities();
            updateSectionApproverTools();
        }

        function updateSectionApproverTools() {
            vm.approverToolsSectionIndex = PromoQuizService.determineApproverToolsSectionIndex(vm.promoDetails);
        }

        function updateSectionVisibilities() {
            vm.visibleSectionIDs = PromoQuizService.determineVisibleSectionIDs(vm.promoDetails);
        }
    };
}
