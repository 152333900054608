import forumTpl from './forum.tpl.html';
import forumCtrl from './forum.controller';

export default {
    templateUrl: forumTpl,
    controller: forumCtrl,
    controllerAs: 'vm',
    bindings: {
        localUrl: '@'
    }
};
