import angular from 'angular';

import '../../core/core.module';

import statementConfig from './statement/statement.config';
import ReloadableCardService from './reloadableCardService.factory';

export default angular.module('app.reloadableCard', [
    'app.core',
    'common.formly'
])
.config(statementConfig)
.factory('ReloadableCardService', ReloadableCardService);
