import angular from 'angular';

import '../../../core/core.module';

import postSubmitConfig from './postSubmit.config';
import postSubmitRun from './postSubmit.run';
import postSubmitCtrl from './postSubmit.controller';

export default angular.module('app.applause.postSubmit', [
    'app.core'
])
.config(postSubmitConfig)
.run(postSubmitRun)
.controller('postSubmitCtrl', postSubmitCtrl);
