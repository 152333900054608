/**
 * Created by Jun on 2/11/2016.
 */

import multiControlFormWrapperTpl from './multiControlFormWrapper.tpl.html';
import applauseSectionBorderTpl from './applauseSectionBorder.tpl.html';

wrapperConfig.$inject = ['formlyConfigProvider'];

function wrapperConfig(formlyConfigProvider) {
    formlyConfigProvider.setWrapper([
        {
            name: 'multiControlFormWrapper',
            templateUrl: multiControlFormWrapperTpl
        },
        {
            name: 'applauseSectionBorder',
            templateUrl: applauseSectionBorderTpl
        }
    ]);
}

export default wrapperConfig;
