import changePasswordPageTpl from './changePasswordPage/changePasswordPage.tpl.html';
import changePasswordPageCtrl from './changePasswordPage/changePasswordPage.controller';

changePasswordConfig.$inject = ['$stateProvider'];

export default function changePasswordConfig($stateProvider) {
    $stateProvider.state('changePassword', {
        parent: 'auth',
        url: '/change-password?hasToken',
        params: {
            hasToken: '0'
        },
        views: {
            'main@': {
                templateUrl: changePasswordPageTpl,
                controller: changePasswordPageCtrl,
                controllerAs: 'vm'
            }
        },
        data: {
            pageTitle: 'Change Password'
        },
        resolve: {
            passwordPolicy: ['ChangePasswordService', function(ChangePasswordService) {
                return ChangePasswordService.getPasswordPolicy();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('changePassword');
            }]
        }
    });
}
