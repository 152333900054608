voucherClaimCtrl.$inject = ['VoucherClaimService', '$state'];

export default function voucherClaimCtrl(VoucherClaimService, $state) {
    const vm = this;

    if ($state.params.code) {
        vm.voucherCode = $state.params.code;
    }

    vm.sendVoucherCode = function() {
        vm.disableSubmit = true;
        VoucherClaimService.sendVoucherCode({
            voucherCode: vm.voucherCode,
            skillTestingUserAnswer: vm.skillTestingUserAnswer
        })
        .then(function(res) {
            return setVoucherSuccessMessage(res.points);
        })
        .catch(function(err) {
            if (err.data.returnCode) {
                setVoucherErrorMessage(err.data.returnCode, err.data.returnMessage);
                vm.skillTestingUserAnswer = null;
                return;
            }
            throw err;
        })
        .finally(function() {
            vm.disableSubmit = false;
        });
    };

    function setVoucherSuccessMessage(points) {
        vm.showAlertMessage = true;
        vm.alertMessageLink = 'account';
        vm.alertMessageType = 'success';
        vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_SUCCESS_TITLE'; // Congratulations!
        vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_SUCCESS_DESCRIPTION'; // {{ points }} points have been added to your account
        vm.pointsClaimed = points;
        vm.skillTestingUserAnswer = null;
    }

    function setVoucherErrorMessage(returnCode, returnMessage) {
        switch (returnCode) {
            case 6010:
                vm.showAlertMessage = true;
                vm.alertMessageLink = null;
                vm.showSkillTestingQuestion = true;
                vm.skillTestingQuestion = returnMessage;
                vm.alertMessageType = 'warning';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_SKILL_TESTING_QUESTION_TITLE'; // Answer the skill testing question.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_SKILL_TESTING_QUESTION_DESCRIPTION'; // Please answer this skill testing question before the points can be deposited into your account:
                break;
            case 6011:
                vm.showAlertMessage = true;
                vm.alertMessageLink = null;
                vm.showSkillTestingQuestion = true;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_INCORRECT_SKILL_TESTING_QUESTION_TITLE'; // The skill testing question is incorrect.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_INCORRECT_SKILL_TESTING_QUESTION_DESCRIPTION'; // This voucher is now invalid and can't be claimed.
                break;
            case 6012:
                vm.showAlertMessage = true;
                vm.alertMessageLink = null;
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_EXPIRED_TITLE'; // Expired.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_EXPIRED_DESCRIPTION'; // The voucher is no longer valid.
                break;
            case 6013:
                vm.showAlertMessage = true;
                vm.alertMessageLink = 'faq';
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_NOT_ACTIVATED_TITLE'; // Not Activated.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_NOT_ACTIVATED_DESCRIPTION'; // Please Contact us to investigate this further.
                break;
            case 6014:
                vm.showAlertMessage = true;
                vm.alertMessageLink = 'account';
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_ALREADY_CLAIMED_TITLE'; // Already Claimed.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_ALREADY_CLAIMED_DESCRIPTION'; // You can track your previous voucher claims through the my account page.
                break;
            case 6015:
                vm.showAlertMessage = true;
                vm.alertMessageLink = 'faq';
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_CLAIMED_BY_OTHER_USER_TITLE'; // Claimed by another participant.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_CLAIMED_BY_OTHER_USER_DESCRIPTION'; // If you believe this was done in error, Contact us and we will investigate it.
                break;
            case 6016:
                vm.showAlertMessage = true;
                vm.alertMessageLink = null;
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_INVALID_TITLE'; // Invalid.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_INVALID_DESCRIPTION'; // Check your voucher code and try claiming again.
                break;
            case 6017:
                vm.showAlertMessage = true;
                vm.alertMessageLink = null;
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_DOES_NOT_EXIST_TITLE'; // Not Activated.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_DOES_NOT_EXIST_DESCRIPTION'; // Incentive is inactive or doesn't exist.
                break;
            case 6018:
                vm.showAlertMessage = true;
                vm.alertMessageLink = 'faq';
                vm.showSkillTestingQuestion = false;
                vm.alertMessageType = 'error';
                vm.alertMessageTitle = 'voucherClaim_ALERT_MESSAGE_NOT_ACTIVATED_TITLE'; // Not Activated.
                vm.alertMessageDescription = 'voucherClaim_ALERT_MESSAGE_NOT_ACTIVATED_DESCRIPTION'; // Please Contact us to investigate this further.
                break;
        }
    }
}
