import _ from 'lodash';

PointTrackingService.$inject = [
    '$state', '$stateParams', '$http', '$location', '$rootScope', 'Communication', 'PointTrackingTypes', 'SignOnTasks',
    'ModalService'
];

export default function PointTrackingService(
    $state, $stateParams, $http, $location, $rootScope, Communication, PointTrackingTypes, SignOnTasks,
    ModalService
) {

    /**
     * @param {Object} params
     * @param {int} params.pointTrackingID
     */
    async function getPointTrackingDetails({ pointTrackingID }) {
        const res = await $http.get(`/api/point-tracking/details/${pointTrackingID}`);
        return res.data;
    }

    /**
     * Opens a pointTrackingDetails modal by looking for params in the URL.
     * @param {Object} params - Same params as `openModal`
     * @returns {Promise}
     */
    async function openFromUrl(params = {}) {
        const { pointTrackingIdParamName = 'pointTrackingID' } = params;
        const urlParams = $location.search();
        const pointTrackingID = Number(urlParams[pointTrackingIdParamName]);
        if (!pointTrackingID) {
            return;
        }
        if ($rootScope.isRedirectingAfterLogin) {
            SignOnTasks.cancelOpeningOptionalTasksAfterLogin();
        }
        return openModal({ ...params, pointTrackingID });
    }

    /**
     * @param {Object} params
     * @param {int} [params.pointTrackingID]
     * @param {Object} [params.pointTrackingDetails]
     * @param {string} [params.pointTrackingIdParamName]
     * @returns {Promise}
     */
    async function openModal({
        pointTrackingID, pointTrackingDetails, pointTrackingIdParamName = 'pointTrackingID'
    }) {
        if (!pointTrackingDetails) {
            pointTrackingDetails = await getPointTrackingDetails({ pointTrackingID });
        }
        const modalInstance = Communication.openByPointTrackingType(pointTrackingDetails);
        ModalService.updateUrlParamsWithModal(modalInstance, { [pointTrackingIdParamName]: pointTrackingID });
        return { pointTrackingDetails, modalInstance };
    }

    /**
     * @param {Object} params
     * @param {int} [params.pointTrackingID]
     * @param {int} [params.contactID]
     * @returns {Promise}
     */
    async function openCorrectionModal({ pointTrackingID, contactID }) {
        const correctionList = await getPointTrackingCorrectionDetails({ pointTrackingID, contactID });
        return Communication.openPointTrackingCorrection(correctionList);
    }


    /**
     * @returns {{ name: string, value: int }[]}
     *      All PointTrackingTypes formatted as an array of { name, value } objects.
     *      Also sorted alphabetically by name.
     */
    function getPointTrackingTypeListOptions() {
        return _(PointTrackingTypes.properties)
            .toArray()
            .map(pointTrackingType => ({
                translateKey: pointTrackingType.translateKey,
                value: pointTrackingType.ID
            }))
            .value();
    }

    async function getPointTrackingCorrectionDetails({ pointTrackingID, contactID }) {
        const res = await $http.get(`/api/point-tracking/correction/details/${pointTrackingID}`, {
            params: {
                contactID
            }
        });
        return res.data;
    }

    async function updateIsPointExpirationWarning({ isPointExpirationWarning }) {
        const res = await $http.put(`/api/point-tracking/update-warning`, {
            isPointExpirationWarning
        });
        await SignOnTasks.removeTask({ type: 'PointExpirationTask' });
        return res.data;
    }

    return {
        getPointTrackingDetails,
        openFromUrl,
        openModal,
        openCorrectionModal,
        getPointTrackingTypeListOptions,
        updateIsPointExpirationWarning
    };
}
