import bowser from 'bowser';

ErrorService.$inject = ['$http', '$state', '$location'];

export default function ErrorService($http, $state, $location) {
    return {
        recordBrowserError
    };

    function recordBrowserError(error, cause) {
        return $http.post('/api/errors', {
            error: {
                message: error.message,
                stack: error.stack,
                cause: error.cause,
            },
            element: cause,
            state: {
                name: $state.current.name,
                params: $state.params,
                browserUrl: $location.url(),
                browser: {
                    name: bowser.name,
                    version: bowser.version,
                    osname: bowser.osname,
                    osversion: bowser.osversion,
                },
            }
        }, {
            disableErrorModal: true,
            disableLoginAsConfirmModal: true
        });
    }
}
