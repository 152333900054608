import certificateDetailsTpl from './certificateDetails.tpl.html';

export default {
    templateUrl: certificateDetailsTpl,
    controller: 'certificateDetailsCtrl as vm',
    bindings: {
        certificate: '<',
        onDelete: '<'
    },
    transclude: {
        aboveSpotlightButtons: '?aboveSpotlightButtons',
    }
};
