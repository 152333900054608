import _ from 'lodash';

postNominationCtrl.$inject = ['$state'];

export default function postNominationCtrl($state) {
    this.$onInit = () => {
        const vm = this;

        _.defaults(vm, {
            onCancel: _.noop,
        });

        const recipients = _.get(vm.applauseForm, 'recipients');
        const group = _.get(vm.applauseForm, 'group');
        vm.isTeamNomination = !_.isEmpty(recipients) || !_.isEmpty(group);

        vm.goToApplauseForm = () => {
            if (vm.isTeamNomination) {
                return $state.go('applauseForm', {
                    recipients,
                    group,
                    teamName: vm.applauseForm.teamName,
                    message: vm.applauseForm.message
                });
            } else {
                return $state.go('applauseForm', {
                    contactID: vm.applauseForm.recipient.ID,
                    message: vm.applauseForm.message
                });
            }
        };

        vm.cancel = () => vm.onCancel();
    };
}
