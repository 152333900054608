import { screens } from '../../nav/loginPanel/loginPanel.controller';

forgotPasswordConfig.$inject = ['$stateProvider'];

export default function forgotPasswordConfig($stateProvider) {
    $stateProvider.state('forgotPassword', {
        parent: 'nav',
        url: '/forgot-password',
        data: {
            pageTitle: 'Login',
            isPublicPage: true
        },
        onEnter: ['$state', '$timeout', function($state, $timeout) {
            $timeout(() => {
                $state.go('login', {
                    screen: screens.FORGOT_PASSWORD
                }, {
                    reload: true
                });
            });
        }]
    });
}
